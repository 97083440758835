import {IATSStyleApp, MRF, C3, C3Selection} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {observable} from "mobx";

export class ProductFamiliesFVStore {
    appStore: AppStore
    pageContext
    filterForm: MRF

    @observable.shallow
    prodFamilyRes: any[]

    prodFamilySel: C3Selection

    async init() {
        this.appStore = IATSStyleApp.instance.appStore

        this.prodFamilySel = new C3Selection({
            items: this.prodFamilyRes,
        })
        await this.fetch()
    }

    async fetch () {
        this.prodFamilyRes = await C3.instance.client.fetcher.fetch(`/product-family`, {})
        this.prodFamilySel.itemsRef.current = this.prodFamilyRes;
        return this.prodFamilyRes;
    }

    async createProductFamily () {
        await C3.instance.client.fetcher.fetch('/product-family',{
            method: 'POST',
            body: {
                name: 'Nova familia de proves'
                , isActive: true
            }
        })
        await this.fetch()
    }

    async deleteSelectedProductFamily () {
        let selectedId = this.prodFamilySel.selectedId;
        if (selectedId == null) return
        await this.deleteProductFamily(selectedId)
    }

    async deleteProductFamily(productFamilyId) {
        await C3.instance.client.fetcher.fetch(`/product-family/${productFamilyId}`, {
            method: `DELETE`
        })
        await this.fetch()
    }

    async saveProductFamily(productFamily){
        await C3.instance.client.fetcher.fetch('/product-family',{
            method: `PATCH`,
            body: productFamily
        })
    }
}
