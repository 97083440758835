import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {FilePageGroupContainer} from "./components/FilePageGroupContainer";
import {Page, C4Application} from "react-c4";
import {FilesInfo, filesInfo, FileViewInfo} from "../../application/utils/filesInfo";
import {useAppStore} from "../../application/utils/utils";

const Container = styled.div`
    display: grid;
    padding: 16px;
    grid-gap: 16px;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fit, minmax(400px, auto));
    grid-template-rows: repeat(auto-fill, auto);
    
`
const FileGroupContainer = styled.div`
    
`

export interface FilesPageProps {

}

export const FilesPage = page(observer((props: FilesPageProps) => {
    let appStore = useAppStore()
    let featuresForUser = appStore.auth.featureFilter.featuresForUser;
    let liu = appStore.auth.loggedInUser;
    return <Page
        title={'Fitxers'}
        subtitle={'Administració del sistema'}
        renderBody={({pageContext}) => {
            let navigate = (info: FileViewInfo) => {
                if (info.page)
                    pageContext.navigate({to: info.page, inNewTab: false});
                return null;
                if (info.view == null) return
                C4Application.setPageParams(info.view, {})
                return pageContext.navigate({to: 'C4ViewPage', inNewTab: false});
            }
            let filesInfo1 = filesInfo();
            return <Container>
                {Object.keys(filesInfo1).map(k => {
                    let group = filesInfo1[k]
                    return <FilePageGroupContainer key={k} group={group} navigate={navigate}/>
                })}
            </Container>
        }}
    />
}))
