import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {action, autorun, computed, observable} from "mobx";
import MobxReactForm from "mobx-react-form";
import moment, {now} from "moment";
import {nextAnimationFrame, nextTimeout} from "../../application/utils/utils";
import {delay} from "rxjs/operators";

export class MIVStore {
    appStore: AppStore
    pageContext

    filterForm: MRF
    invoiceForm: MRF

    invoicesSel: C3Selection


    @observable.shallow
    insCompaniesRes = []

    @observable
    invoicesRes

    @observable
    invoiceLinesRes

    @observable
    editingNotes


    invoiceLinesSel

    @observable
    invoiceRes

    @observable
    invoicePreviewZoom = 85

    @observable
    closingInvoicePrompt = false

    @observable
    byPatientInvoicing = false

    @observable
    loading = {
        refreshing: false,
        fetchingInvoice: false,
        fetchingLines: false,
        creatingDraft: false,
        creatingByPatientInvoices: false,
        closingInvoice: false,

    }

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection

    @observable
    isPrinting: boolean = false

    printHandle

    @observable
    paymentMethods = [
        {id: 1, name: 'Chipcard'},
        {id: 2, name: 'Manual'}
    ]



    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.invoicesSel = new C3Selection({
            items: [],
            onSelectionChanged: () => {
                this.fetchInvoiceWithLines(this.invoicesSel.selectedId)
            }
        })
        this.orgsSel = new C3Selection({})
        this.orgsRes = this.appStore.orgs
        this.invoiceLinesSel = new C3Selection({
            items: [],
        })
        this.invoiceForm = new MobxReactForm({
            fields: [
                'id',
                'notes',
                'comment',
                'invoiceDate',
            ],
            values: {
                notes: "",
                comment: "",
                invoiceDate: new Date()
            }
        })

        this.filterForm = new MobxReactForm({
            fields: [
                'startDate',
                'endDate',
                'type',
                'insCompany',
                'invoicingCode',
                'paymentMethod',
                'orgs',
            ],
            // initials,
            // defaults,
            values: {
                orgs: [],
                startDate: new Date(),
                endDate: new Date(),
                type: 'all',
                insCompany: {id: -1, name: 'Tria una mútua'},
                invoicingCode: '',
                paymentMethod: {id: -1, name: 'Selecciona chipcard / manual'},
                invoiceDate: new Date()
            },
            // labels,
            // placeholders,
            // rules,
            // extra,
            hooks: {}
        })


        await this.fetchInsCompanies()
        autorun(async () => {
            if (this.orgsSel.selectedId == null || this.filterForm.$('insCompany').value?.id == null) return
            await this.fetchAllInvoices(this.filterForm.$('insCompany').value?.id)
        }, {delay: 10})

        autorun(async () => {
            this.orgsSel.selectedId
            this.invoicesRes = []
            this.invoiceLinesRes = []
            await this.fetchInsCompanies()
            this.filterForm.$('insCompany').set({id: -1, name: 'Tria una mútua'})
        })

        autorun(async () => {
            this.filterForm.$('orgs').value;
            this.orgsRes = this.appStore.orgs;
            this.orgsSel.itemsRef.current = this.orgsRes;
            if (this.orgsRes == null) return
            this.orgsSel.selectedIds = [this.orgsRes[0].id];
        })
        autorun(async () => {
            this.filterForm.$('insCompany').value?.id
            this.invoicesRes = []
            this.invoiceLinesRes = []
        })
    }


    async fetchAllInvoices(insCompanyId) {
        let startDate = moment(moment(now()).subtract(1, 'month')).toDate();
        let endDate = moment(now()).toDate();
        let counterId = 2;
        let type = 'all';
        if (counterId == null) return
        if (insCompanyId == null) return
        if (this.orgsSel.selectedId == null) return
        let res = await C3.instance.client.fetcher.fetch(`/ins-company-invoicing/ins-companies/${insCompanyId}/invoices`, {
            query: [
                `start=${startDate.toISOString()}`,
                `end=${endDate.toISOString()}`,
                `type=${type}`,
                `org=${this.orgsSel.selectedId}`
            ].join('&'),
        })
        this.invoicesRes = res
        this.invoicesSel.itemsRef.current = this.invoicesRes
    }


    async fetchInvoiceWithLines(id) {
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${id}/full`, {
            query: ['fullLines=true']
        });
        this.invoiceRes = result;
        console.log(`result`, result);
        this.invoiceLinesRes = this.invoiceRes.invoiceLines
        this.invoiceLinesSel.itemsRef.current = this.invoiceRes.invoiceLines
        this.invoiceForm.clear()
        this.invoiceForm.set(result)
    }


    async fetchInsCompanies() {
        const orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (orgs === '') return
        let result = await C3.instance.client.fetcher.fetch(`/contact/ins-company`, {
            query: [orgs]
        });
        this.insCompaniesRes = result.map((c) => ({...c, name: c.shortName}));
    }

    async deleteSelectedInvoice() {
        let invoiceId = this.invoicesSel.selectedId
        if (invoiceId == null) return
        this.deleteInvoice(invoiceId)
    }

    async deleteInvoice(invoiceId) {
        await C3.instance.client.fetcher.fetch(`/invoice/${invoiceId}`, {
            method: 'DELETE'
        })
        await this.fetchAllInvoices(this.filterForm.$('insCompany').value?.id)
    }

    async closeInvoice() { //TODO BACK

    }


    async closeInsCompanyInvoice() {
        this.loading.closingInvoice = true

        if (this.invoiceStatus != 1) {
            this.loading.closingInvoice = false
            return
        }
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.invoiceRes.id}/close`, {
            method: 'POST', body: {}
        });
        await Promise.all([this.fetchAllInvoices(this.invoiceRes.id), this.refresh()])
        await nextAnimationFrame()
        this.invoicesSel.selectId(result.id)
        this.loading.closingInvoice = false
    }

    async refresh() {
        this.loading.refreshing = true
        // await this.fetchInsCompanies()
        await this.fetchAllInvoices(this.filterForm.$('insCompany').value?.id)
        this.loading.refreshing = false

    }


    async printInvoice() {
        this.isPrinting = true
        await nextTimeout(500)
        this.printHandle()
        await nextTimeout(2000)
        //
        this.isPrinting = false
    }


    // async runByPatientInvoicing() {
    //     this.loading.creatingByPatientInvoices = true
    //     let insCompanyCounter = 2;
    //     let startingLineIds = this.visitLinesSel.selectedItems.map(vl => vl.ilId);
    //     if (startingLineIds.length == 0) return
    //     let result = await C3.instance.client.fetcher.fetch(`/invoice/by-patient-invoicing`, {
    //         method: 'POST',
    //         body: {
    //             orgId: this.appStore.orgsSel.selectedId,
    //             counterCode: insCompanyCounter,
    //             startingLineIds: startingLineIds,
    //             insCompanyId: this.insCompaniesSel.selectedId,
    //             isChipcard: this.type == 'chipcard',
    //             periodStartDate: this.filterForm.$('startDate').value,
    //             periodEndDate: this.filterForm.$('endDate').value,
    //         }
    //     });
    //     await this.fetchSelectedInsCompanyInvoices()
    //     await nextTimeout(500)
    //     this.invoicesSel.selectIds(result.ids) // CHECK
    //     await this.fetchInsCompanies()
    //     console.log(`result`, result);
    //     // await this.refetch()
    //     // await this.fetchAllInvoices()
    //     // this.privateInvoicesSel.selectId(result.id)
    //     // if (!fromSelected) {
    //     //     this.visitLinesSel.clearSelection()
    //     // }
    //     // await this.refetch()
    //     if (true) this.viewMode = "preview"
    //     this.loading.creatingByPatientInvoices = false
    //     return result
    // }


    async createInvoice() {
        let insCompanyCounter = 2
        let result = await C3.instance.client.fetcher.fetch(`/invoice/new-draft-invoice`, {
            method: 'POST',
            body: {
                orgId: this.orgsSel.selectedId,
                counterCode: insCompanyCounter,
                startingLineIds: [],
                insCompanyId: this.filterForm.$('insCompany').value?.id,
                isChipcard: null,
                modality: 'manual',
                invoiceDate: this.filterForm.$('invoiceDate').value,
                // periodStartDate: this.filterForm.$('startDate').value,
                // periodEndDate: this.filterForm.$('endDate').value,
            }
        });
        await this.fetchAllInvoices(this.filterForm.$('insCompany').value?.id)
        this.invoicesSel.selectId(result.id, true, true, true)
    }

    async saveInvoiceLine(body) {
        await C3.instance.client.fetcher.fetch(`/invoice-line/${body.id}`, {
            method: 'PATCH',
            body,
        })
        await this.fetchInvoiceWithLines(this.invoicesSel.selectedId)
    }

    async deleteSelectedInvoiceLine() {
        let invoiceLineId = this.invoiceLinesSel.selectedId
        if (invoiceLineId == null) return
        this.deleteInvoiceLine(invoiceLineId)
    }

    async deleteInvoiceLine(invoiceLineId) {
        await C3.instance.client.fetcher.fetch(`/invoice-line/${invoiceLineId}`, {
            method: 'DELETE'
        })
        await this.fetchInvoiceWithLines(this.invoicesSel.selectedId)
    }

    async createInvoiceLine(body?) {
        if (!this.invoicesSel.selectedId) return
        await C3.instance.client.fetcher.fetch(`/invoice/${this.invoicesSel.selectedId}/new-invoice-line`, {
            method: 'POST',
            body: {
                price: 50,
                text: 'Nova línia de factura',
                ...body,
            },
        })
        await this.fetchInvoiceWithLines(this.invoicesSel.selectedId)
    }

    async addIVALine() {
        await this.createInvoiceLine({
            price: Number(this.invoiceRes.total) * 0.21,
            text: 'IVA 21%',
        })
    }

    @observable
    showIva = false

    @action
    ivaHandler() {
        this.showIva = !this.showIva
    }

    @observable
    viewMode: 'edit' | 'preview' = "edit"

    @computed
    get invoiceStatus(): 0 | 1 | 2 {
        let res = this.invoiceRes;
        return res?.draftInvoiceNumber ? (res?.invoiceNumber ? 2 : 1) : 0
    }


    @computed
    get allLinesSelected() {
        return this.invoiceLinesSel.allSelected
    }


    @computed
    get canPreview() {
        // if (this.invoiceStatus == 0) return false
        return true
    }

    @computed
    get canEdit() {
        return true
    }

    @computed
    get canCreateDraftInvoice() {
        return true
    }

    @computed
    get effectiveViewMode(): "edit" | "preview" {
        if (!this.canEdit) return "preview"
        if (!this.canPreview) return "edit"
        return this.viewMode
    }

    @computed
    get isPreview() {
        return this.effectiveViewMode == "preview"
    }

    @computed
    get canEditInvoiceLine() {
        let actualInvoice = this.invoicesSel.selectedItem
        if (actualInvoice == null) return false
        return !actualInvoice.invoiceNumber
    }

    @computed
    get canCreateInvoice() {
        return this.filterForm.$('insCompany').value.id >= 0
    }


    @computed
    get isInvoicableType() {
        return ['chipcard', 'manual'].includes(this.invoicesSel.selectedId as string);
    }

    getTotal(subtotal, iva) {
        return subtotal * (iva / 100 + 1)
    }

    async savePrivateInvoice(body, refetch) {
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.invoicesSel.selectedId}`, {
            method: 'PATCH', body: body
        });
        if (refetch) await this.refreshButton()
    }

    async refreshButton() {
        this.fetchAllInvoices(this.filterForm.$('insCompany').value?.id);
        this.fetchInsCompanies()
        this.fetchInvoiceWithLines(this.invoicesSel.selectedId)
    }


}
