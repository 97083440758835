import React from 'react';
import styled from 'styled-components';
import InvoiceLogo from '../components/InvoiceLogo';
import {InvoiceProps} from "./Invoice";
import {InvoiceGrid} from "./InvoiceGrid";
import {formatFormalName, stdCurrencyFormat} from "../../application/utils/utils";
import {observer} from 'mobx-react'
import moment from "moment";
import {MRF} from 'react-c4';
import * as _ from 'lodash'
import {CellValueChangedEvent} from "ag-grid-community";
import {useSVVStore} from "../../components/StandardVisitView/StandardVisitView";
import {SVVStore} from "../../components/StandardVisitView/SVVStore";
import {NonIdealState} from "@blueprintjs/core";


const Container = styled.div`
  display: grid;
  justify-content: stretch;
  align-items: stretch;
  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    width: 210mm;
    height: 297mm;
  }
   
   @media screen {
   box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.36);
  }
  width: 210mm;
  height: calc(297mm - 20mm);
  //padding: 15mm;
  padding: 10mm;
  //margin-bottom: 20mm;
  page-break-after: always;
  background-color: white;
    font-family: Arial;

`
const MainLayout = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  //font-size: 2px;
  //grid-template-rows: 108px 185px 400px 240px 12px;
  grid-template-rows: 108px auto 1fr auto 12px;
  grid-gap: 4px;
  grid-template-areas: 
      "header"
      "InvoiceHeaderContainer"
      "InvoiceGrid"  
      "Footer"
      "Paging"
`;

const Header = styled.div`
  //border: solid 1px #626262;
  grid-area: header;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-template-areas: 
  "logo  centre centre"
  "logo  address contact";

`

const Logo = styled.div`
  display: grid;
  place-items: center;
  grid-area: logo;
`;

const CenterDetails = styled.div`
  grid-area: centre;
  padding-top: 10px;
  font-size: 12pt;
  font-family: Arial;


`
const Address = styled.div`
  grid-area: address;
  font-size: 10pt;
  font-family: Arial;
`

const Contact = styled.div`
  grid-area: contact;
  font-size: 10pt;
  font-family: Arial;
`

const ClientInfo = styled.div`
  padding: 2px;
  padding-left: 4px;
  border: dotted 1px black;
  font-size: 10pt;
`

const InvoiceData = styled.div`
  padding: 2px;
  padding-right: 4px;
  border: dotted 1px black;


  text-align: right;
  font-size: 10pt;
`


const PatientData = styled.div`
  display: grid;
  align-content: start;
  padding: 2px;
  padding-left: 4px;
  border: dotted 1px black;

  font-size: 10pt;
`

const ExtraInfo = styled.div`
  grid-column: span 2;
  padding: 2px;
  padding-left: 4px;
  border: dotted 1px black;
  font-size: 10pt;
`


const Footer = styled.div`
  grid-area: Footer;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 40px 1fr;
  grid-template-areas: 
  ". InvoiceTotal "
  "FooterData FooterData ";
`;

const InvoiceTotal = styled.div`
  grid-area: InvoiceTotal;
  padding: 10px;
  padding-bottom: 5px;
  border: dotted 1px black;
  display: grid;
  grid-template-columns: 230px 139px;
  font-size: 12pt;
  font-weight: bold;
`;

const TotalText = styled.div`
  padding-left: 30px;
`

const FooterData = styled.div`
  grid-area: FooterData;
  font-size: 9pt;
  font-family: Verdana;
  text-align: justify;
  text-justify: inter-word;
  color: rgb(57, 135, 135);
  margin-top: 20px;
`;

const PaginationContainer = styled.div`
  grid-area: Paging;

`;

const Title = styled.span`
  font-size: 11px;
  font-weight: bold;
  font-family: Verdana;
  text-transform: uppercase;
`;


const PageNum = styled.div`
  text-align: right;

  margin-right: 10px;
  font-size: 9pt;
  //font-style: italic;
  font-family: Verdana;
`;

const Mercantil = styled.div`
  font-size: 8pt;

`

const FooterLineContainer = styled.div`
  font-size: 8pt;
  display: grid;
  justify-content: center;
  width: 100%;
`


const Arial10 = styled.div`
  font-size: 10pt;
  font-family: Arial;
`

const Times8 = styled.div`
  font-size: 9pt;
`

const Times10Bold = styled.div`
  font-size: 9pt;
  font-weight: bold;
`

const GreenText = styled.span`
  font-size: 10pt;
  font-weight: bold;
  color: rgb(0, 100, 100);
`
const InvoiceHeaderContainer = styled.span`
  display: grid;
  grid-template-columns: 55% 45%;
  //grid-template-rows: auto auto;
  grid-auto-flow: row;
  grid-gap: 4px;
`
const FullWidthLeftContainer = styled.div`
    width: 100%;
   text-align: left;
`

export interface InvoicePageProps {
    page,
    lastpage,
    invoice,
    // data: InvoiceProps['data']
    lines: InvoiceProps['invoice']['lines']
    columnDefs: InvoiceProps['columnDefs']
    invoiceForm?: MRF
    pageCount
    type: 'private' | 'ins-company'
}


export const InvoicePage = observer((props: InvoicePageProps) => {
    let store: SVVStore = useSVVStore();
    let invoiceForm = props.invoiceForm;
    let invoice = props.invoice;
    let isPrivate = props.type == "private"
    let isInsCompany = props.type == "ins-company"
    let field = (field, def = '') => invoiceForm?.$(field)?.value || _.get(invoice, field) || def
    let client = invoice.receiver;
    let clientInfo = <ClientInfo>
        <Title>{formatFormalName(client)}</Title>
        <div>{client?.address}</div>
        <div>{client?.cp || '[CP]' + " " + client?.city || '[Població]'}</div>
        <div>{client?.cif || '[CIF]'}</div>
        <div>{`[Codi del client: ${invoice.receiverClientCode}] ${invoice.receiverMail} ${invoice.receiverPhone}`}</div>
    </ClientInfo>;
    let invoiceNumber = invoice.invoiceNumber;
    let invoiceNumberLabel = invoiceNumber ? 'Factura' : 'Pre-factura';
    let periodStart = invoice.periodStartDate && moment(invoice.periodStartDate).format(`DD/MM/YYYY`);
    let periodEnd = invoice.periodEndDate && moment(invoice.periodEndDate).format(`DD/MM/YYYY`);
    let isManual = props.invoice.modality != 'manual';
    let shouldDisplayCCM = isInsCompany && isManual;
    let invoiceData = <InvoiceData>
        <Times10Bold><GreenText>{invoiceNumberLabel}:</GreenText>{" "}<span
            style={{color: !invoiceNumber ? 'red' : null}}>{invoice.invoiceNumberText}</span></Times10Bold>
        <Times10Bold><GreenText>Data:</GreenText>{" " + moment(field('invoiceDate')).format('DD/MM/YYYY')}</Times10Bold>
        {/*<Times10Bold><GreenText>Període:</GreenText>{" " + invoice.invoicePeriod}</Times10Bold>*/}
        <Times8>{"Moneda: " + 'EUR'}</Times8>
        {shouldDisplayCCM && <FullWidthLeftContainer><span
            style={{fontWeight: 'bold'}}>{invoice.isChipcard ? 'Chipcard' : 'Manual'}</span>{periodStart && ` - Període: del ${periodStart} al ${periodEnd}`}
        </FullWidthLeftContainer>}
    </InvoiceData>
    let contactOrPatient = isInsCompany ? invoice.contact : invoice.patient;
    // First header
    let patientData = <PatientData>
        {/*<div>{JSON.stringify(field('usingPatientAltInvoicing'))}</div>*/}
        {<React.Fragment>
            {isPrivate && <div>{"Pacient: "}<Title>{formatFormalName(invoice.patient)}</Title></div>}
            {isInsCompany && <div>{"Client: "}<Title>{contactOrPatient?.name}</Title></div>}
            <div>{contactOrPatient?.address || ''}</div>
            <div>{`${contactOrPatient?.postalCode || '[CP]'} ${contactOrPatient?.city || '[Població]'} ${isPrivate ? 'NIF' : 'CIF'}: ${contactOrPatient?.nif || '[NIF/CIF]'}`}</div>
            <div>{`${isPrivate ? `[Codi del pacient: ${contactOrPatient?.historyNumber || ''}]` : ''} ${contactOrPatient?.email || ''} ${contactOrPatient?.phone1 || ''}`}</div>
        </React.Fragment>}
        {[true, "true"].includes(field('usingPatientAltInvoicing')) && <React.Fragment>
          <div>{"Facturant a: "}<Title>{formatFormalName(field('patientAltInvoicing'))}</Title></div>
            {field('patientAltInvoicing.nif') &&
            <div>{['NIF / CIF:', field('patientAltInvoicing.nif')].join(' ')}</div>}
          <div>{[field('patientAltInvoicing.address')].join(' ')}</div>
          <div>{[field('patientAltInvoicing.postalCode'), field('patientAltInvoicing.city')].join(' ')}</div>
        </React.Fragment>}
    </PatientData>
    let extraInfo = (s) => {
        // if (isInsCompany) return null
        return <ExtraInfo style={s}>
            <div><GreenText>Notes:</GreenText>{" " + field('notes')}</div>
            {/*<div>{"ECASE " + invoice.case}</div>*/}
        </ExtraInfo>;
    };
    let isReceiver = invoice.patient != null;
    let showPatientName = isReceiver;

    if (!invoice.org) return <NonIdealState title={'Factura sense organització'}/>
    return <Container>
        <MainLayout>
            {invoice.org ? <Header>
                <Logo>
                    <InvoiceLogo org={invoice.org}/>
                </Logo>
                <CenterDetails>
                    <div>{invoice.org.name}</div>
                    <Arial10>{!isManual ? invoice.org.cif : ''}</Arial10>
                </CenterDetails>
                <Address>
                    <div>
                        {invoice.org.address}
                    </div>
                    <div>
                        {`${invoice.org.postalCode} ${invoice.org.city || ''}`}
                    </div>
                </Address>
                <Contact>
                    <div>
                        {`Tel. ${invoice.org.mobile}`}
                    </div>
                    <div>
                        {invoice.org.email}
                    </div>
                </Contact>
            </Header> : <div>Escull una organització!</div>}
            {!isInsCompany ? <InvoiceHeaderContainer style={{gridTemplateRows: 'auto auto'}}>
                {/* // [Private] */}
                {patientData}
                {/*{clientInfo}*/}
                {invoiceData}
                {extraInfo({})}
            </InvoiceHeaderContainer> : <InvoiceHeaderContainer style={{gridTemplateRows: 'auto'}}>
                {/* // [InsCompany] */}
                {/*{clientInfo}*/}
                {patientData}
                {invoiceData}
                {field('notes') && extraInfo({})}
            </InvoiceHeaderContainer>}
            {/*{isReceiver ? <InvoiceHeaderContainer style={{gridTemplateRows: 'auto auto'}}>*/}
            {/*        {clientInfo}*/}
            {/*        {invoiceData}*/}
            {/*        {patientData}*/}
            {/*        {extraInfo({})}*/}
            {/*    </InvoiceHeaderContainer> :*/}
            {/*    <InvoiceHeaderContainer>*/}
            {/*        {patientData}*/}
            {/*        {invoiceData}*/}
            {/*        {extraInfo({gridColumn: 'span 2'})}*/}
            {/*    </InvoiceHeaderContainer>*/}
            {/*}*/}
            <InvoiceGrid props={props}
                         cellEditorSubmitterAS={async (e: CellValueChangedEvent) => {
                             if (!store) return;
                             // await new Promise((resolve, reject) => setTimeout(resolve, 1000))
                             // await new Promise(r => setTimeout(r, 1000));
                             // On update
                             let fieldName = e.colDef.field;
                             if (fieldName != 'text') return
                             let newVar = await store.savePrivateInvoiceLine({id: e.data?.id, [fieldName]: e.newValue});
                             return newVar
                         }}
            />
            <Footer>
                {props.lastpage && <InvoiceTotal>
                  <TotalText>Total a pagar</TotalText>
                  <div>{stdCurrencyFormat(invoice.total) + "    " + (invoice.invoiceCoin || 'EUR')}</div>
                </InvoiceTotal>}
                <FooterData>
                    <div style={{fontWeight: 'bold'}}>{"Número de compte: " + invoice.org.bankAccount}</div>
                    <br/>
                    <Mercantil>{"Factura exempta d'IVA segons la llei 37/1992"}</Mercantil>
                    {props.lastpage && <>
                      <br/>
                      <Mercantil>{invoice.org.rgpdFooterNote}</Mercantil>
                    </>}
                    <br/>
                    <FooterLineContainer>{invoice.org.lastFooterLine}</FooterLineContainer>
                    <FooterLineContainer>{invoice.org.mercantileRegistry}{' - '}CIF: {invoice.org.cif}</FooterLineContainer>
                </FooterData>

            </Footer>
            <PaginationContainer>
                <PageNum>{"Pàgina " + props.page + " de " + props.pageCount}</PageNum>
            </PaginationContainer>
        </MainLayout>
    </Container>;
})


export default InvoicePage;
