import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {C3AgGrid, getMomentFormatter, MasterLayout} from "react-c4";
import {
    CellValueChangedEvent, ICellEditorParams,
    ICellRendererParams,
    IRichCellEditorParams,
    ValueFormatterParams,
    ValueGetterParams
} from "ag-grid-community";
import {Checkbox, Icon} from "@blueprintjs/core";
import {useSVVStore} from "../../../../StandardVisitView";
import {
    latMap,
    nextAnimationFrame, nextTimeout,
    paymentFormMap,
    stdCurrencyFormat, stdDateColumn,
    stdNumericColumn
} from "../../../../../../application/utils/utils";
import {DateTimeCE} from "../../../../../../application/utils/components/DateTimeCE";

const Container = styled.div`
    display: grid;
    min-height: 112px
`

export interface SVVPIPPaymentGridProps {

}

export const SVVPIPPaymentGrid = observer((props: SVVPIPPaymentGridProps) => {
    let store = useSVVStore()
    let momentFormatter = getMomentFormatter('DD/MM/YYYY');

    return <Container>
        <MasterLayout
            header={false}
            actions={[
                {
                    name: 'new',
                    text: 'Nou pagament',
                    iconName: 'document',
                    callback: () => {
                        store.createPayment()
                    }
                },
                // {
                //     name: 'delete',
                //     iconName: 'trash',
                //     text: 'Esborra',
                //     confirmation: true,
                //     confirmationText: 'Estàs segur que vols eliminar el pagament?',
                //     enabled: true,
                //     callback: () => null
                // },
            ]}
            body={<C3AgGrid
                // selection={store.privateInvoicesSel}
                rowData={store.privateInvoiceRes?.payments}
                defaultColDef={{
                    editable: true
                }}
                floatingFilter={false}
                columnDefs={[
                    {
                        field: 'paymentForm',
                        headerName: 'F. Pagament',
                        cellEditor: 'agSelectCellEditor',
                        cellEditorParams: (params: IRichCellEditorParams) => {
                            return {
                                values: [
                                    'cash',
                                    'cc',
                                    'transfer'
                                ],
                                // values: [
                                //     {id: null, name: '-'},
                                //     {id: 'l', name: 'Esq'},
                                //     {id: 'r', name: 'Drt'},
                                // ],
                                formatValue: (params) => {
                                    if (_.isEmpty(params)) return '-'
                                    // console.log(`params, params?.name`, params, params?.name);
                                    return paymentFormMap[params]
                                },
                            }
                        },
                        valueFormatter: (params: ValueFormatterParams) => {
                            if (_.isEmpty(params.value)) return '-'
                            return paymentFormMap[params.value]
                        },
                        width: 90

                    },
                    {
                        field: 'amount',
                        headerName: '€',
                        width: 60,
                        ...stdNumericColumn()
                    },
                    {
                        field: 'paymentObject',
                        headerName: 'CaixaP / Dat / CC',
                        // cellEditor: 'agSelectCellEditor',
                        // cellEditorParams: (params: IRichCellEditorParams) => {
                        //     return {
                        //         values: [
                        //             {id: 1, name: 'DT-01'}
                        //         ],
                        //         // values: [
                        //         //     {id: null, name: '-'},
                        //         //     {id: 'l', name: 'Esq'},
                        //         //     {id: 'r', name: 'Drt'},
                        //         // ],
                        //         formatValue: (params) => {
                        //             if (_.isEmpty(params)) return '-'
                        //             // console.log(`params, params?.name`, params, params?.name);
                        //             return paymentFormMap[params]
                        //         },
                        //     }
                        // },
                        // valueFormatter: (params: ValueFormatterParams) => {
                        //     if (_.isEmpty(params.value)) return '-'
                        //     return paymentFormMap[params.value]
                        // },
                        valueGetter: ({data}) => {
                            let pf = data.paymentForm;
                            let text = ''
                            if (pf == 'cash') {
                                text = 'CaixaPetita01'
                            } else if (pf == 'cc') {
                                text = 'Datàfon01'
                            } else if (pf == 'transfer') {
                                text = 'CompteBanc01'

                            }
                            return text
                        },
                        editable: false
                    }, {
                        field: 'paid',
                        headerName: 'Pagat',
                        // cellRenderer: function (params) {
                        //     var input = document.createElement('input');
                        //     input.type = "checkbox";
                        //     input.checked = params.value;
                        //     input.addEventListener('click', function (event) {
                        //         params.value = !params.value;
                        //         params.node.data.fieldName = params.value;
                        //         params.node.setDataValue('paymentDate', new Date())
                        //     });
                        //     return input;
                        // },
                        cellRendererFramework: (params: ICellRendererParams) => {
                            return <Checkbox defaultChecked={params.data.paid} onChange={(e) => {
                                let value = e.currentTarget.checked;
                                params.node.setDataValue('paid', value === true)
                                // params.node.setDataValue('paymentDate', value ? new Date() : null)

                            }}/>
                        },
                        editable: false,
                        width: 40
                    }, {
                        field: 'paymentDate',
                        headerName: 'Data Cobrat / Transf',
                        // valueFormatter: ({value}) => {
                        //     return momentFormatter.formatDate(value)
                        // },
                        // valueParser: ({newValue}) => {
                        //     return momentFormatter.parseDate(newValue)
                        // },
                        editable: true,
                        ...stdDateColumn(),
                        cellEditor: 'dateTimeCE',
                        cellEditorParams: (params: ICellEditorParams) => {
                            return {
                                defaultValue: params.data.paymentDate || new Date()
                            }
                        },
                    },
                    // {
                    //     field: 'extractDate',
                    //     headerName: 'Data extracte',
                    // },
                    {
                        field: 'subaccount',
                        headerName: 'Subcompte',
                        editable: false,
                        valueGetter: ({data}) => {
                            let pf = data.paymentForm;
                            let text = ''
                            if (pf == 'cash') {
                                text = '57000002'
                            } else if (pf == 'cc') {
                                text = '57000016'
                            } else if (pf == 'transfer') {
                                text = '57000002'

                            }
                            return text
                        }
                    },
                ]}
                cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    // await new Promise((resolve, reject) => setTimeout(resolve, 1000))
                    // await new Promise(r => setTimeout(r, 1000));
                    // On update
                    console.log(`AAA`, e);
                    let paymentId = e.data.id;
                    let fieldName = e.colDef.field;
                    // if (fieldName == 'refExtCenter') e.node.setDataValue('refMedical', null)
                    // if (fieldName == 'paid' && e.newValue ) e.node.setDataValue('paymentDate', new Date())

                    let base = {id: paymentId};
                    _.set(base, fieldName, e.newValue)
                    let newVar: any;
                    // let refetchFields = ['paid']
                    // let refetch = refetchFields.includes(fieldName);
                    // if(store.isPrivateInvoicingMode){
                    //     setTimeout(() => store.fetchInvoiceIntoForm(), 1000)
                    // }
                    if (e.newValue != e.oldValue) {
                        newVar = await store.savePayment(base);
                        await store.fetchInvoiceIntoForm()

                        // await nextTimeout(500)
                        if (['paid', 'amount'].includes(fieldName)) {
                            await Promise.all([
                                await store.fetchAllInvoices(),
                                ...store.visitLinesSel.selectedIds.map(vlId => store.refetchVisitLine(vlId))
                            ])
                            // TODO Find cause and avoid double fetch
                            await Promise.all([
                                ...store.visitLinesSel.selectedIds.map(vlId => store.refetchVisitLine(vlId))
                            ])
                            // await Promise.all([
                            //     store.fetchAllInvoices(),
                            //     ...store.visitLinesSel.selectedIds.map(vlId => store.refetchVisitLine(vlId))
                            // ])
                        }
                    }
                    // store.refetchVisitLinesRateOptions(paymentId)
                    return newVar

                }}
                frameworkComponents={{
                    dateTimeCE: DateTimeCE,
                }}
            />}
        />
    </Container>
})
