import {C3, C3DataSource, C3Form, C3MasterDetail, C3Selection, IATSStyleApp, MRF} from "react-c4"
import {action, autorun, computed, observable, reaction, toJS} from "mobx";
import MobxReactForm from "mobx-react-form";
import * as _ from 'lodash'
import {doc} from "prettier";
import moment from "moment";
import {GridApi, RefreshCellsParams} from "ag-grid-community";
import {filesLocalSchema} from "../../application/schema/local-entities/files-local.schema";
import {useNamespace} from "react-namespaces";
import {AppStore} from "../../application/AppStore";
import {MRFField} from "react-c4/src/forms/MRF";
import {RSCStore} from "../ReferrerSelector/RSCStore";
import {documentTypesMap, nextAnimationFrame, nextTimeout} from "../../application/utils/utils";
import {SCVStore} from "../ScheduleView/SCVStore";
import {PatientOverviewStore} from "../PatientOverview/PatientOverviewStore";
import {DFEStore} from "../DeliveryFloatingEditor/DFEStore";
import {APVStore} from "../AppointmentsView/APVStore";

export class SVVStore {
    appStore: AppStore

    pageContext

    @observable.shallow
    scvStore: SCVStore

    visitIds: number[]
    episodeIds: number[]
    genMD: C3MasterDetail

    dtfStore: DateTimeFilterStore = new DateTimeFilterStore()

    // visitLinesMD: C3MasterDetail // Deprecated
    @observable
    showAssistant: boolean = false

    @observable
    showHistorical: boolean = true

    @observable
    initialized: boolean = false


    @observable
    switchingVisitSelection: boolean = false

    @observable
    invoicesCollapsed: boolean = false

    @observable
    calendarCollapsed: boolean = true

    patientOverviewStore: PatientOverviewStore

    /**
     * Contains the patient and is passed to sidebar
     */
    patientForm: C3Form
    /**
     * Form containing all the data in the screen
     * Including: Visit Lines with nested fields and patient
     */
    form: MRF

    @observable
    visitLinesSelKey: number = null


    visitLinesSel: C3Selection
    visitsSel: C3Selection


    @observable
    mainGridCollapsed = false

    @observable
    patientSidebarCollapsed: boolean = true

    @observable
    visitViewMode: 'form' | 'calendar' | 'calendarScv' | 'invoicing' | 'none' = "form"

    @computed
    get effectiveVisitViewMode() {
        if (this.isAttachDocumentsMode) return "none"
        if (!this.isMainMode) return 'form'
        // if (this.visitsSel.selectedIds.length > 1) return 'calendar'
        return this.visitViewMode
    }

    @observable
    isCalendarExpanded = false

    @observable
    allRatesMode = true

    @observable
    featNewRefererEntry = true

    @observable
    d1 = true


    @observable
    historyViewMode: 'all' | 'lastMonth' | 'lastYear' | 'lastWeek' | 'today' | 'todayOnly' = "all"


    @observable
    formCollapsed: boolean = true


    @observable
    idCounter: number = 1000

    visitTmpIdCounter: number = -1


    visitsMD: C3MasterDetail

    visitLinesMD: C3MasterDetail

    resourcesDS: C3DataSource

    businessLinesDS: C3DataSource

    vlGridApi: GridApi

    allExtCenters: any[]
    resourceProductMap: any
    refExtCenterToRefMedicalMap: any
    allTechnicians: any[]


    @observable
    resourceScheduleCache: { resourceId: any[] } = {} as any

    @observable
    visitLinesRateOptionsMap: { [visitLineId: number]: any }

    @observable.shallow
    allRateSheetsMap: { [rateSheetId: number]: any[] } = {}

    @observable
    paymentMethodsInsCompaniesMap: { [paymentMethod: string]: any }

    @observable
    allOrgs: any[]


    @observable
    patientId

    initialSelections: {
        selectedVisitIds: number[],
        selectedVisitLineIds: number[]
        selectedInvoiceIds: number[]
    }
    newAppointments: { resourceId: number, date: Date }[]

    rscStore: RSCStore
    dfeStore: DFEStore
    apvStore: APVStore

    constructor(patientId, selectedVisitIds = [], selectedVisitLineIds = [], selectedInvoiceIds = [], newAppointments = null) {
        this.patientId = patientId
        this.initialSelections = {
            selectedVisitIds,
            selectedVisitLineIds,
            selectedInvoiceIds
        }
        this.newAppointments = newAppointments
        if (this.patientId == null) throw new Error('patientId cannot be null')
    }

    getRSCStore() {
        if (this.rscStore == null) {
            this.rscStore = new RSCStore(this)
            this.rscStore.init()
        }
        return this.rscStore
    }

    getDFEStore() {
        if (this.dfeStore == null) {
            this.dfeStore = new DFEStore(this)
            this.dfeStore.init()
        }
        return this.dfeStore
    }

    setPatientOverviewStore(patientOverviewStore) {
        this.patientOverviewStore = patientOverviewStore
        this.patientOverviewStore.onSavePatient = async (res) => {
            console.log(`Saved patient`, res);
            await this.refetch(true)
        }
    }

    @computed
    get patientValid() {
        let f = this.form.$('patient');
        // console.log(`f.$('sex').value`, f.$('sex').value);
        // return f.$('name').value?.length == 3
        return f.$('name').value?.length > 1
            && f.$('surname1').value?.length > 1 && (f.$('mobile').value?.length > 1 || f.$('phone2').value?.length > 1)
            && ['M', 'F'].includes(f.$('sex').value)
            && moment(f.$('birthDate').value).isValid()
    }

    @computed
    get isSelectedAppointmentToday(): boolean {
        let today = moment(new Date).format('YYYY-MM-DD')
        if (!this.visitsSel.selField) return false
        let appointmentDate = moment(this.visitsSel.selField.$('appointmentDate').value).format('YYYY-MM-DD');
        return today === appointmentDate
    }

    goToMainMode() {
        this.isPrivateInvoicingMode = false
        this.isAttachDocumentsMode = false
    }

    @action
    async addVisits(initialValues?: { resourceId?, appointmentDate? }[]) {
        let result = await this.post('/visits/many', {
            initialValues: initialValues.map(iv => {
                return {
                    resource: {id: iv.resourceId},
                    appointmentDate: iv.appointmentDate
                }
            })
        }, false)
        await this.afterAddingVisits(result)
    }

    @action
    async addVisit(initialValues?: { resourceId?, appointmentDate? }) {
        let result = await this.post('/visits', {}, false)
        // await this.refetch();

        // await this.partialRefetch();
        // this.goToMainMode();
        // this.visitViewMode = 'form'
        // let visitField: MRFField = this.visitsSel.getFieldById(result.id)
        // if (!initialValues) this.selectVisitOnly(result?.id, false)
        // // if (initialValues.resourceId) {
        // //     visitField.$('resource').onChange(this.resourcesDS.items[initialValues.resourceId])
        // // }
        // // if (initialValues.appointmentDate) {
        // //     visitField.$('appointmentDate').onChange(initialValues.appointmentDate)
        // // }
        // if (initialValues)
        //     await this.saveVisit({
        //         id: result.id,
        //         resource: {id: initialValues.resourceId},
        //         appointmentDate: initialValues.appointmentDate
        //     });
        // visitField.$('tmpProducts').set([])
        // await this.fetchPatientStats();
        // // let res = await this.visitsMD.dataSource.createItem({isDraft: true})
        // // await this.visitsMD.fetchItems()
        // // await this.visitLinesMD.fetchItems()
        // // console.log(`create res`, res);
        // return result.id()
        await this.afterAddingVisits([result])
    }

    async afterAddingVisits(newVisits) {
        let ids = newVisits.map(v => v.id)
        await Promise.all([this.partialRefetch(), this.fetchPatientStats()]);
        this.goToMainMode();
        this.visitViewMode = 'form'
        let visitsFields: MRFField[] = this.visitsSel.getFieldsByIds(ids)
        this.selectVisitsOnly([_.first(ids)], false)
        // if (initialValues.resourceId) {
        //     visitField.$('resource').onChange(this.resourcesDS.items[initialValues.resourceId])
        // }
        // if (initialValues.appointmentDate) {
        //     visitField.$('appointmentDate').onChange(initialValues.appointmentDate)
        // }
        // if (initialValues)
        //     await this.saveVisit({
        //         id: result.id,
        //         resource: {id: initialValues.resourceId},
        //         appointmentDate: initialValues.appointmentDate
        //     });
        // visitField.$('tmpProducts').set([])

        // let res = await this.visitsMD.dataSource.createItem({isDraft: true})
        // await this.visitsMD.fetchItems()
        // await this.visitLinesMD.fetchItems()
        // console.log(`create res`, res);
        return ids
    }

    @computed
    get isMainMode() {
        return !(this.isPrivateInvoicingMode || this.isAttachDocumentsMode || this.isDeliveryMode)
    }

    @computed
    get canRemoveVisits() {
        return false
    }

    @observable
    isDeleteVisitPopoverOpen = false

    @action
    closingDeleteVisitPromptHandler(state) {
        this.isDeleteVisitPopoverOpen = state
    }

    async removeVisit(visitId) {
        await this.delete(`/visits/${visitId}`)
        await this.fetchPatientStats();
    }

    async removeSelVisit() {
        await this.removeVisit(this.singleSelVisitId())
    }

    @action
    /**
     * Add line to selected visit
     */
    async addVisitLineForProducts() {
        let visitField = this.visitsSel.selField;
        let visitLines = await this.post(`/visits/${visitField.value?.id}/visit-lines/many`, {
            productIds: visitField.value?.tmpProducts?.map((tmpProd) => tmpProd.id)
        });
        visitField.$('tmpProducts').set([])
        visitLines.forEach((vl) => this.visitLinesSel.selectId(vl.id, true, false))
        await Promise.all(visitLines.map((vl) => this.refetchVisitLinesRateOptions(vl.id)))
    }

    async addVisitLine(productId?) {
        let selectedVisitId = _.last(this.selectedVisitIds);
        let visitLine = await this.post(`/visits/${selectedVisitId}/visit-lines`, {
            ...(productId && {product: {id: productId}})
        });
        await this.refetch()
        // Select new visit line
        this.visitLinesSel.selectId(visitLine.id)


        // let selectedLineId = this.visitLinesMD.selection.selectedItem?.visit?.id;
        // if (!selectedLineId) return
        // let res = await this.visitLinesMD.dataSource.createItem({visit: {id: selectedLineId}, isDraft: true})
        // await this.refetch()
        // console.log(`create res`, res);
        // this.lines.push({machine: 'A'})
        // this.visitLinesMD.itemsRef.current.push({id: 0, machine: 'A'})
        // this.visitLinesMD.gridRef.current.setRowData(this.lines)
        // this.form.$('visitLines').add([{
        //     id: (this.idCounter++).toString(),
        //     visit: null,
        //     resource: {id: 1, name: 'RM-1'},
        //     test: 'TAC Abdominal',
        //     duration: '40',
        //     totalDuration: '--',
        //     quantity: '1',
        //
        //     date: null,//new date(2020, 11, 22),
        //     payments: [
        //         {
        //             id: (this.idCounter++).toString(),
        //             paymentMethod: '(PRIVAT)',
        //             paymentLines: []
        //         }
        //     ]
        // }])
    }

    async removeVisitLine(visitLineId) {
        await this.delete(`/visit-lines/${visitLineId}`)
        await this.fetchPatientStats();
    }

    async duplicateVisitLine(visitLineId) {
        await this.delete(`/visit-lines/duplicate/${visitLineId}`)
        await this.fetchPatientStats();
    }


    @action
    async removeSelVisitLine() {
        let visitLineId = this.visitLinesSel.selectedId;
        if (visitLineId) await this.removeVisitLine(visitLineId)

        // let res = await this.visitLinesMD.dataSource.deleteItem(this.visitLinesMD.selection.selectedId)
        // await this.visitLinesMD.fetchItems()
        // await this.refetch()
        // console.log(`delete res`, res);
    }

    @action
    async duplicateSelVisitLine() {
        let visitLineId = this.visitLinesSel.selectedId;
        if (visitLineId) await this.removeVisitLine(visitLineId)

        // let res = await this.visitLinesMD.dataSource.deleteItem(this.visitLinesMD.selection.selectedId)
        // await this.visitLinesMD.fetchItems()
        // await this.refetch()
        // console.log(`delete res`, res);
    }

    getLocalVisit = id => {
        // console.log(`this.visitsMD.itemsRef.current`, this.visitsMD.itemsRef.current);
        // console.log(`this.visitsSel.items`, this.visitsSel.items);
        let visit = this.form.$('visits').value?.find(v => v.id == id);
        // console.log(`visit ${id}`, visit);
        return visit
    };

    getVisitLineIdsForVisit(visitIds: any[]) {
        return _.flatMap(visitIds, visitId => this.getLocalVisit(visitId).visitLines?.map(vl => vl.id))
    }

    getVisitLinesForVisit(visitIds: any[]) {
        return this.getVisitLineIdsForVisit(visitIds).map(vlId => this.svvRes.visitLines.find(vl => vl.id == vlId))
    }

    getVisitLinesFields(visitId) {
        if (!this.getLocalVisit) return []
        return this.visitLinesSel.getFieldsByIds(this.getLocalVisit(visitId).visitLines?.map(vl => vl.id))
    }

    getVisitLineField(visitLineId) {
        return this.visitLinesSel.getFieldById(visitLineId)
    }

    get selectedVisitIds() {
        return _.uniq(this.visitLinesSel.selectedItems.map(vl => vl.visit?.id))
    }


    singleSelVisitId() {
        // return null
        if (!this.exactlyOneVisit) return null
        return _.last(this.selectedVisitIds)
    }

    selectedVisitFields() {
        return this.visitsSel.getFieldsByIds(this.selectedVisitIds)
    }

    @computed
    get exactlyOneVisit() {
        return this.visitsSel.selectedIds.length == 1
    }

    @computed
    get selectedDocuments(): {
        [visitId: number]: {
            visit: any[],
            visitLine: { [visitLineId: number]: any[] }
        }
    } {

        return {
            34: {
                visit: [],
                visitLine: {
                    3401: [{id: 550}, {id: 550}]
                },
            }
        }
    }


    @computed
    get selectedResources(): { [resid: string]: number[] } {
        return _.mapValues(_.groupBy(
            this.selectedVisitIds.map(id => this.getLocalVisit(id)), v => v.resource?.id || null),
            g => g.map(v => v.id))
    }

    orderedSelectedResources() {
        let resources: any[] = this.selectedVisitIds.map(id => this.getLocalVisit(id)).map((visit) => visit.resource).filter(r => !_.isEmpty(r))
        return _.uniqBy(resources, r => r.id)
    }

    getVisitDisplayName(visit) {
        return (visit.isDraft ? 'T' : visit.isVisit ? 'V' : 'C') + visit.visitNumber
    }

    @observable.shallow
    svvRes: { visits, visitLines, patient }

    init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.visitTypesSel.selectIds(['V', 'T', 'C'], true, true)
        // this.form = new MobxReactForm({})
        this.resourceProductMap = this.fetch('/resource-product-map').then((obj) => {
            // console.log(`obj`, obj);
            return this.resourceProductMap = obj;
        })
        this.refExtCenterToRefMedicalMap = this.fetch('/ref-ext-center-to-ref-medical').then((obj) => {
            // console.log(`obj`, obj);
            return this.refExtCenterToRefMedicalMap = obj;
        })
        this.fetch('/ref-ext-centers').then((obj) => {
            this.allExtCenters = obj;
        })

        let lastTimeout = null
        reaction(() => this.refetching, (r) => {
            if (r) {
                lastTimeout = setTimeout(() => {
                    this.loadingIndicator = true
                    console.log(`this.loadingIndicator = true`);
                }, 1500)

            } else {
                clearTimeout(lastTimeout)
                console.log(`this.loadingIndicator = false`);
                this.loadingIndicator = false
            }
        })

        this.patientForm = new C3Form({
            entityType: 'patient',
            autofetch: true,
            options: {
                // mutua: true
            }
        })
        this.privateInvoiceForm = new MobxReactForm({
            fields: [
                'id',
                'notes',
                'comment',
                'usingPatientAltInvoicing',
                'invoiceDate',
                ...[
                    'id',
                    'name',
                    'surname1',
                    'surname2',
                    'nif',
                    'address',
                    'city',
                    'postalCode',
                ].map(p => `patientAltInvoicing.${p}`),
                'payments[]'
            ],
            values: {
                notes: "",
                comment: "",
                usingPatientAltInvoicing: 'false',
                invoiceDate: new Date()
            }
        })

        let a1 = autorun(() => {
            /**
             * Patient ID, can be passed as parameter
             */
            // let patientId = 3;
            this.patientForm.setEntityId(this.patientId)
        })

        this.resourcesDS = new C3DataSource({
            entityType: 'resource',
            // TODO Add filter type id: 1
            autoFetch: true
        })

        this.businessLinesDS = new C3DataSource({
            entityType: 'business-line',
            autoFetch: true
        })
        this.businessLinesDS.fetch();


        const fields = [
            'visits[]',
            ...[
                'id',
                'appointmentDate',
                'resource',
                'resource.id',
                'resource.name',
                'cancelledAt',
                'remindedAt',
                'tmpProducts[]',
                'visitLines[]',

            ].map(p => `visits[].${p}`),
            // visitLines[]
            ...[
                // VisitLine
                'id',
                'name',
                'product',
                'product.id',
                'product.name',
                'businessLine',
                'businessLine.id',
                'businessLine.name',
                'deliveryDate',
                'deliveredDate',
                'reportDate',
                'teleraStatus',
                'comment',
                'checkedBy',
                'checked',
                'rate',
                'rate.id',
                'rate.code',
                'rate.name',
                'rate.price',

                ...[
                    'id',
                    'authorizationNumber',
                    'date',
                    'insCompany',
                    'insCompanyPaymentForm',
                    // paymentLines[]
                    ...[
                        'id',
                        'name',
                        'mutuaNumber',
                        'isInsCompany',
                    ].map(p => `paymentMethod.${p}`),
                    'paymentType',
                    'policyNumber',
                    'price',
                    'quantity',
                    'rate',
                    'text',
                    'textSource',
                    'totalPrice',
                    'volantNumber',
                ].map(p => `payment.active.${p}`),

                // ['isDraft', {}],
                // payments[]
                ...[
                    'id',
                    'paymentMethod',
                    // paymentLines[]
                    ...[
                        'id',
                        'amount'

                    ].map(p => `paymentLines[].${p}`),
                ].map(p => `payments[].${p}`),
            ].map(p => `visitLines[].${p}`),
            // payments

        ];

        const initials = {
            // club: {
            //   name: 'Jazz Club (initials)',
            //   city: 'New York (initials)',
            // },
        };

        const defaults = {
            // visitLines: {
            //     id: -100,
            //     name: 'Jazz Club (default)',
            //     payments: [{paymentLines: []}]
            // },
        };


        const extra = {}
        //
        const values = {
            "visits": [],
            "visitLines": [],
            "invoices": [],
            "patient": {
                "id": 1,
                "historyNumber": "324234",
                "isFromWebsite": false,
                "name": "Biel",
                "surname1": "Simon",
                "surname2": null,
                "mobile": null,
                "phone2": null,
                "email": null,
                "birthDate": null,
                "nif": null,
                "sex": null,
                "language": null,
                "status": null,
                "address": null,
                "postalCode": null,
                "city": null,
                "state": null,
                "countryCode": null
            }
        }

        const labels = {
            'visitLines': 'Club',
            // 'club.name': 'Club Name',
            // 'club.city': 'Club City',
            // 'members': 'All Members',
            // 'members[].firstname': 'Member First Name',
            // 'members[].lastname': 'Member Last Name',
            // 'members[].hobbies': 'Hobbies',
        };

        const placeholders = {
            // 'club': 'Insert Club',
            // 'club.name': 'Insert Club Name',
            // 'club.city': 'Insert Club City',
            // 'members': 'Insert All Members',
            // 'members[].firstname': 'Insert FirstName',
            // 'members[].lastname': 'Insert LastName',
            // 'members[].hobbies[]': 'Insert Hobbies',
        };

        const rules = {
            // 'club.name': 'string|required|min:3',
            // 'club.city': 'string|required|min:3',
            // 'members[].lastname': 'string|required|min:3',
            // 'members[].firstname': 'string|required|min:3',
            // 'members[].hobbies[]': 'string|required|min:3',
        };
        const hooks = {
            'visits[].resource': {
                onChange: (f) => {
                    // console.log(`visits[].resource CHANGE`, f.value, f.path,  f);
                    // this.saveVisit({})
                }
            }
        }

        this.form = new MobxReactForm({
            fields,
            initials,
            defaults,
            values,
            labels,
            placeholders,
            rules,
            extra,
            hooks
        })
        this.visitsSel = new C3Selection({
            form: this.form,
            formPath: ['visits'],
            incomplete: true,
            onBeforeSelectionChanged: () => {
                // await this.saveVisitFields()
                this.switchingVisitSelection = true
            },
            onSelectionChanged: (selectedIds: any[], unSelectedIds: any[], isUser) => {
                setTimeout(() => this.switchingVisitSelection = false, 200)
            }
        })
        // this.form.$('vis')
        this.visitLinesSel = new C3Selection({
            form: this.form,
            formPath: ['visitLines'],
            onSelectionChanged: (selectedIds: any[], unSelectedIds: any[], isUser) => {
                let equal = _.isEqual((this.visitsSel.selectedIds as any).toJS(), this.selectedVisitIds);
                if (!equal) {
                    this.visitsSel.selectIds(this.selectedVisitIds, true, true, false, isUser)
                    // console.log(`Selected visitLinesSel -> visitsSel`, this.visitsSel.selectedIds.toJS(),
                    // this.selectedVisitIds);
                } else {
                    // console.log(`Avoided equal`);
                }
                let promise = this.addToInvoiceHandler();
                // console.log(`SEL this.selectedVisits`, this.selectedVisitIds);
                // console.log(`visitLinesSel onSelectionChanged`, selectedIds, unSelectedIds, isUser);

                this.handleLinesGridSelectionChanged(selectedIds, unSelectedIds, isUser)
            },
            canItemBeSelected: (item) => {
                if (this.isPrivateInvoicingMode) {
                    return this.canLineBeAddedToInvoice(item)
                }
                return true
            }
        })
        // this.paymentsSel = new C3Selection({
        //     form: this.form,
        //     formPath: ['visitLines', this.visitLinesSel, 'payment', 'all']
        // })

        let applyInitialSelection = () => {
            let initialSelections = this.initialSelections;
            let visitIds = initialSelections.selectedVisitIds;
            let visitLineIds = initialSelections.selectedVisitLineIds
            let selectedInvoiceIds = initialSelections.selectedInvoiceIds
            if (visitIds.length > 0) this.selectVisitsOnly(visitIds, false)
            if (visitLineIds.length > 0) this.visitLinesSel.selectIds(visitIds, true, false, true)
            if (selectedInvoiceIds.length == 1) {
                let invoiceId = selectedInvoiceIds[0];
                let p1 = this.goToInvoice(invoiceId)
            }

        }
        let createNewAppointmentFromParams = async () => {
            if (!this.newAppointments) return;
            // await Promise.all(this.newAppointments.map(na => {
            //     return this.addVisit(na)
            // }))
            await this.addVisits(this.newAppointments)
        }
        let r1 = reaction(r => {
            let a = this.historyViewMode
            return a
        }, async () => {
            await this.refetch()
            let promise1 = this.refetchAllVisitLinesRateOptions();
            let promise2 = this.refetchPaymentMethodsInsCompaniesMap();
            let promise4 = this.fetchPatientVtfStats();

        })
        let r2 = reaction(r => {
            let a = this.visitTypeFilter
            return a
        }, async () => {
            await this.refetch()
            let promise1 = this.refetchAllVisitLinesRateOptions();
            let promise2 = this.refetchPaymentMethodsInsCompaniesMap();

        })

        let p1 = this.refetch()
            .then(() => this.initialized = true)
            .then(() => applyInitialSelection())
            // .then(() => this.resourcesDS.fetch())
            .then(() => createNewAppointmentFromParams())
            .then(() => {
                // Non blocking requests
                this.resourcesDS.fetch()
                C3.instance.client.fetcher.fetch('/organization', {}).then(orgs => this.allOrgs = orgs)
                C3.instance.client.fetcher.fetch('/contact/all-technicians', {}).then(allTechnicians => this.allTechnicians = allTechnicians)
                let promise1 = this.refetchAllVisitLinesRateOptions();
                let promise2 = this.refetchPaymentMethodsInsCompaniesMap();
                let promise3 = this.fetchPatientStats();
                //Test
                // this.initAttachDocuments()

                // let node = this.vlGridApi.getRenderedNodes()[0];
                // if (node) {
                //     this.vlGridApi.startEditingCell({
                //         colKey: 'refExtCenter',
                //         rowIndex: node.rowIndex,
                //     })
                // }
            })


        let a3 = autorun(async () => {
            if (this.allRatesMode) {
                await this.prefetchAllRateSheets()
            }
        }, {delay: 1})


        let r3 = reaction(() => {
            return this.isAttachDocumentsModeShowHistory;
        }, (arg, r) => {
            this.fetchDocumentsList()
        })
        let r4 = reaction(() => {
            return [this.isPrivateInvoicingMode, this.isAttachDocumentsMode];
        }, (arg, r) => {
            this.vlGridApi.redrawRows()
        })

        let s1 = this.appStore.events.obs.subscribe((e) => {
            let [uId, sId] = e.uId
            if (sId == this.appStore.auth.sessionToken) {
                // console.log(`same session sId`, sId);
                return
            }
            let pIds = _.intersection(e.pIds, [this.svvRes?.patient?.id]);
            if (pIds.length > 0) {
                this.refetch(true)
            } else {
                if (['v/del', "vl/new"].includes(e.src)) {
                    this.refetch()
                    this.fetchPatientStats()
                } else {
                    let vlIds = _.intersection(e.vlIds, this.visitLinesSel.items.map(vl => vl.id));
                    let vIds = _.intersection(e.vIds, this.visitsSel.items.map(v => v.id));
                    vlIds.forEach((vlId) => this.refetchVisitLine(vlId))
                    vIds.forEach((vId) => this.refetchVisit(vId))
                    if (vIds.length > 0) {
                        this.fetchPatientStats()
                    }
                }


            }


        })

        // setInterval(() => {
        //     this.refetch()
        //     console.log(`Refetching automatically...`);
        // }, 60 * 5 * 1000)

        window['form'] = this.form
        window['store'] = this
        window['st'] = this

        return () => {
            a1();
            // a2()
            a3()
            r1()
            r2()
            r3()
            r4()
            s1.unsubscribe()
        }

    }

    selectVisitOnly(visitId, isUser) {
        let visit = this.getLocalVisit(visitId);
        this.visitLinesSel.selectIds(visit.visitLines.map(vl => vl.id), true, true, true, isUser)
    }

    selectVisit(visitId, select = true, overwrite?, update?, isUser?) {
        let visit = this.getLocalVisit(visitId);
        this.visitLinesSel.selectIds(visit.visitLines.map(vl => vl.id), select, overwrite, update, isUser)
    }

    selectVisitsOnly(visitIds: number[], isUser) {
        let visits = visitIds.map(vId => this.getLocalVisit(vId));
        let allVisitIds = _.flatMap(visits, v => (v?.visitLines || []).map(vl => vl.id));
        this.visitLinesSel.selectIds(allVisitIds, true, true, true, isUser)
    }

    isVisitHalfSelected(visitId) {
        return this.selectedVisitIds.includes(visitId)
    }

    isVisitFullySelected(visitId) {
        // Not working
        // throw new Error('Not implemented')
        let visit = this.getLocalVisit(visitId);
        let visitLinesIds: number[] = visit.visitLines.map(vl => vl.id);
        let fullySelected = visitLinesIds.every(vlId => this.visitLinesSel.selectedIds.includes(vlId))
        return fullySelected
    }


    /**
     * Checks whether a visit can be confirmed or is missing some data
     * @param visitId
     */
    errorsToConfirmVisit(visitId) {
        if (visitId == null) return []
        let visit = this.getLocalVisit(visitId)

        let errors = [];

        if (visit?.resource == "") errors.push('Selecciona una màquina')
        if (visit?.appointmentDate == "") errors.push('Selecciona una data')
        return errors
    }

    canConfirmVisit(visitId) {
        return this.errorsToConfirmVisit(visitId).length == 0
    }

    @computed
    get canConfirmSelectedVisit() {
        let id = this.visitsSel.selectedId;
        if (id == null) return false
        let visit = this.getLocalVisit(id)

        return !(id && this.canConfirmVisit(id) && visit?.isDraft == false)
    }

    async confirmVisit(visitId) {
        await this.saveVisit({id: visitId, isDraft: false, confirmationDate: new Date()})
        await this.fetchPatientVtfStats()
    }

    @computed
    get isDurationDirty() {
        let visitField = this.visitsSel.selField;
        let visitLinesFields = this.getVisitLinesFields(visitField.$('id').value);
        let durations = visitLinesFields.map(vlF => Number(vlF.$('duration').value));
        return _.sum(durations) != Number(visitField.$('duration').value)

    }

    @computed
    get canPrintTurn() {
        let id = this.visitsSel.selectedId;
        let visit = this.getLocalVisit(id);
        let visitDate = moment(visit.appointmentDate).format('DD/MM/YYYY');
        let todayDate = moment(new Date).format('DD/MM/YYYY');
        return visitDate == todayDate
    }

    confirmVisitLine(visitLineId) {
        // await this.saveVisit({id: visitId, isDraft: false})
    }

    async clearSelectedVisit() {
        let selectVisitId = this.visitsSel.selectedId
        if (selectVisitId == null) return
        this.clearVisit(selectVisitId)
    }

    async clearVisit(visitId) { // TODO falta funcionalment del end point a back
        await C3.instance.client.fetcher.fetch(`/svv/visits/${visitId}/clear`, {
            method: 'POST',
        })
        await this.refetch();
        await this.refetchVisit(visitId);
    }

    async remindVisit(visitId, reminded: boolean = true) {
        await this.saveVisit({id: visitId, remindedAt: reminded ? new Date : null}, true)
    }

    async cancelVisit(visitId, cancel: boolean = true) {
        await this.saveVisit({id: visitId, cancelledAt: cancel ? new Date() : null}, true)
        await this.refetchVisitLinesForVisits([visitId])
    }

    async noShowVisit(visitId, noShow: boolean = true) {
        await this.saveVisit({id: visitId, noShow: noShow}, true)
    }

    getVisitField(visitId) {
        return this.visitsSel.getFieldById(visitId)
    }

    @computed
    get $visitLines() {
        return this.form.$('visitLines')
    }

    @computed
    get $selVisitLine() {
        return this.visitLinesSel.selField
        // let visitLinesSelIdx = this.visitLinesSelKey?.toString();
        // if (!this.$visitLines.has(visitLinesSelIdx)) return null
        // return this.$visitLines.$(visitLinesSelIdx)
    }

    @computed
    get $payments() {
        return this.$selVisitLine?.has('payments') && this.$selVisitLine?.$('payments')
    }

    // @computed
    // get $selPayment() {
    //     return this.paymentsSel.selField
    //     // let paymentSelIdx = this.paymentsSelKey?.toString();
    //     // if (!this.$payments.has(paymentSelIdx)) return null
    //     // return this.$payments.$(paymentSelIdx)
    // }


    // @action
    // addPayment() {
    //     this.paymentsSel.formField.add([{
    //         id: (this.idCounter++).toString(),
    //         paymentMethod: '(NEW)',
    //         paymentLines: [
    //             {
    //                 id: (this.idCounter++).toString(),
    //                 amount: 100
    //             }
    //         ]
    //     }])
    // }


    ensureTmpProductsInVisit(visitId) {
        let visitField = this.getVisitField(visitId);
        if (visitField.has('tmpProducts')) {
            return
        }
        // visitField.('tmpProducts', [])
    }

    @action
    /**
     * @deprecated
     */
    recalcVisits() {
        // Get lines with temporal visits
        let lines = this.form.$('visitLines').value
        let machineGroups = _.groupBy(lines, l => l.resource?.id);
        // console.log(`machineGroups`, machineGroups);
        for (let machineId in machineGroups) {
            let group = machineGroups[machineId]
            // 1. Get current visits for group
            let groupVisit = group.map(g => g.visit).find(v => v != null && v.id != null)
            if (!groupVisit) {
                let newVisit = {id: this.visitTmpIdCounter--};
                groupVisit = newVisit;
                this.form.$('visits').add([newVisit])
            }
            this.visitLinesSel.getFieldsByIds(group.map(vl => vl.id)).forEach((f) => f.$('visit').set(groupVisit))

        }


        let visits = this.form.$('visits').value
        for (let i = 0; i < lines; i++) {
            let line = lines[i]
            line.visit.id
        }
        let tmpCounter = 1;
        // _.groupBy(lines, () => )
    }

    getHistorySinceDate() {
        let m1: moment.Moment;
        if (this.historyViewMode == "lastYear")
            m1 = moment().subtract(1, 'year')


        if (this.historyViewMode == "lastMonth")
            m1 = moment().subtract(1, 'month')

        if (this.historyViewMode == "today")
            m1 = moment().subtract(1, 'day')

        if (this.historyViewMode == "lastWeek")
            m1 = moment().subtract(1, 'week')

        return (m1 && m1.toDate());
    }

    svvInvalidated = false

    async fetch(endpoint = '', body = null, method = 'GET', mode = 'full') {
        let historySinceDate = this.getHistorySinceDate();
        let historySince = historySinceDate ? `&historySince=${historySinceDate.toISOString()}` : '';
        return await C3.instance.client.fetcher.fetch('/svv' + endpoint, {
            method: method,
            query: [`patientId=${this.patientId}${historySince}`,
                `invMode=${this.isPrivateInvoicingMode}`,
                `vtf=${this.visitTypeFilter}`,
                `mode=${mode}`,
            ].join('&'),
            body
        })
    }

    async post(endpoint, body, refetch = true) {
        let res = await this.fetch(endpoint, body, 'POST');
        if (refetch) await this.refetch()
        return res
    }

    async delete(endpoint, refetch = true) {
        let res = await this.fetch(endpoint, null, 'DELETE');
        if (refetch) await this.refetch()
        return res
    }

    async patch(endpoint, body, refetch = true) {
        if (refetch) this.svvInvalidated = true
        let res = await this.fetch(endpoint, body, 'PATCH');
        if (refetch) await this.refetch()

        return res
    }

    async saveVisit(visit, refetch = true) {
        let res = await C3.instance.client.fetcher.fetch('/svv' + `/visits/${visit.id}`, {
            method: 'PATCH',
            query: [refetch ? `f=true` : ''].join('&'),
            body: visit
        })
        if (refetch) await this.loadVisit(res)
        // if(visit.appointmentDate && refetch) await this.refetchVisitLinesForVisits(visit.id)
        return res
    }

    async saveVisitLine(visitLine, refetch = false) {
        let res = await C3.instance.client.fetcher.fetch('/svv' + `/visit-lines/${visitLine.id}`, {
            method: 'PATCH',
            query: [refetch ? `f=true` : ''].join('&'),
            body: visitLine
        })
        if (refetch) await this.loadVisitLine(res)
        return res
    }

    async refetchAllVisitLinesRateOptions() {
        this.visitLinesRateOptionsMap = await this.fetch('/all-rate-options')
    }

    async refetchVisitLinesRateOptions(visitLineId) {
        this.visitLinesRateOptionsMap[visitLineId] = await C3.instance.client.fetcher.fetch(`/visit-line/${visitLineId}/rate-options`, {})
    }

    async updateVisitDuration(visitId, value) {
        await this.saveVisit({id: visitId, duration: value})
        await this.refetchVisitLinesForVisits([visitId], true)
    }

    getRateSheet(rateSheetId): { id, name, rates: any[] } {
        if (rateSheetId == null) return {rates: []} as any
        let rateSheet = this.allRateSheetsMap[rateSheetId];
        return rateSheet as any || {rates: []}
    }

    async getOrFetchRateSheet(rateSheetId) {
        let rateSheet = this.allRateSheetsMap[rateSheetId];
        if (rateSheet) return rateSheet
        let res = await C3.instance.client.fetcher.fetch(`/rate-sheet/${rateSheetId}/full`, {});
        console.log(`res`, res);
        this.allRateSheetsMap[rateSheetId] = res
        return res
    }

    async prefetchAllRateSheets() {
        let rateSheetIds = _.uniq(_.entries(this.visitLinesRateOptionsMap).map(([k, v]) => v.rateSheetId));
        await Promise.all(rateSheetIds.filter(id => id != null).map(rsId => this.getOrFetchRateSheet(rsId)))
    }

    async refetchPaymentMethodsInsCompaniesMap() {
        this.paymentMethodsInsCompaniesMap = await C3.instance.client.fetcher.fetch(`/patient/${this.patientId}/payment-methods-ins-companies-map`, {})
    }


    async refetchResourceScheduleCache(resourceId) {
        this.resourceScheduleCache[resourceId] = await C3.instance.client.fetcher.fetch(`/resource/${resourceId}/visits-schedule`, {})
    }

    // async saveVisitFields() {
    //     let fields = [
    //         'administrativeComments',
    //         'referrerComments',
    //         'technicianComments',
    //         'deliveryComments',
    //     ]
    //     let payload = {
    //         id: this.visitsSel
    //     }
    //     let visitField = this.visitsSel.selField;
    //     if (!visitField) return
    //     fields.forEach(f => {
    //         let field = visitField.$(f);
    //         if (field.touched) {
    //             payload[f] = field.value
    //         }
    //     })
    //     // console.log(`saving visit payload`, payload);
    //     await this.saveVisit(payload)
    // }

    /**
     * @deprecated
     */
    async submit() {
        let values = this.form.values();
        this.svvRes = await C3.instance.client.fetcher.fetch('/svv', {
            method: 'POST',
            query: `patientId=${this.patientId}`,
            body: values
        })
        this.form.set(this.svvRes)
    }

    difference(object, base) {
        function changes(object, base) {
            return _.transform(object, function (result, value, key) {
                if (!_.isEqual(value, base[key])) {
                    result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                }
            });
        }

        return changes(object, base);
    }

    lastRefetched = null
    @observable
    refetching = false
    @observable
    loadingIndicator = false

    visitsItemsRef = {current: []}

    @action
    async refetch(full = true) {
        if (this.refetching) return
        let diff = new Date().getTime() - this.lastRefetched
        // let oldValues = this.form.values();
        // if (diff < 200) return

        this.refetching = true
        await nextAnimationFrame()
        this.svvRes = await this.fetch('')
        this.visitsItemsRef.current = this.svvRes?.visits || []
        this.svvInvalidated = false
        // let valuesMap = (values) => {
        //     return values.visitLines.map(vl => vl.product)
        // }
        // console.log(`[refetch] Updating form`, toJS(this.svvRes));

        // let oldValues = this.form.values();
        // let oldVLs = oldValues.visitLines;
        // let newValues = toJS(this.svvRes);
        // let newVLs = newValues.visitLines;
        // let checkVLIds = () => {
        //     for (let i = 0; i < Math.max(oldVLs.length, newVLs.length); i++) {
        //         let oldVL = oldVLs[i]
        //         let newVL = newVLs[i]
        //         console.log(`[refetch] oldVL, newVL`, oldVL, newVL);
        //         if(oldVL?.id != newVL?.id){
        //             let $visitLines = this.form.$('visitLines');
        //             if($visitLines.has(i.toString())) {
        //                 console.log(`[refetch] Clearing vl i`, i, oldVL, newVL);
        //                 $visitLines.$(i.toString())?.set({product: {id: null, name: ''}})
        //             }
        //
        //         }
        //     }
        // }
        // checkVLIds()
        // console.log(`[refetch] oldValues visitLines`, oldVLs);
        //  this.form.$('visitLines').has('2').$('2')?.set(null)
        this.form.update(this.svvRes)

        // this.form.set('value', this.svvRes)


        // console.log(`[refetch] newValues visitLines`, newVLs);
        // let valuesMap1 = valuesMap(oldValues);
        // let valuesMap2 = valuesMap(newValues);
        // let diff = this.difference(valuesMap2, valuesMap1)
        // console.log(`diff, valuesMap(oldValues), valuesMap(newValues)`, diff, valuesMap1, valuesMap2);
        // this.vlGridApi.refreshCells({force: true, suppressFlash: true})


        // if(oldValues.length != newValues.length)
        this.vlGridApi?.redrawRows?.()

        if (this.isPrivateInvoicingMode) {
            await this.fetchInvoiceIntoForm()
        }
        this.lastRefetched = new Date().getTime()
        //if (full) await this.prefetchAllRateSheets()
        await this.fetchTurnNumber(false)
        this.refetching = false
    }

    @action
    async partialRefetch() {
        if (this.refetching) return
        let ids = await this.fetch('', null, 'GET', 'ids')
        // [3  4  5 ]
        // [n  ok ok]
        let visitsPromises = ids.visitIds.map(visitId => {
            let oldVisit = _.find(this.svvRes.visits, (v) => v.id == visitId);
            if (oldVisit)
                return oldVisit
            else
                return C3.instance.client.fetcher.fetch(`/visit/${visitId}/full`, {})
        })

        let visitLinesPromises = ids.visitLineIds.map(visitLineId => {
            let oldVisitLine = _.find(this.svvRes.visitLines, (v) => v.id == visitLineId);
            if (oldVisitLine)
                return oldVisitLine
            else
                return C3.instance.client.fetcher.fetch(`/visit-line/${visitLineId}/full`, {})
        })
        this.refetching = true
        let [visits, visitLines] = await Promise.all([Promise.all(visitsPromises), Promise.all(visitLinesPromises)])
        this.svvRes.visits = visits
        this.svvRes.visitLines = visitLines
        this.visitsItemsRef.current = this.svvRes?.visits || []
        this.form.update({visits, visitLines})
        // this.form.set('value', {...this.form.values(), visits, visitLines})
        this.vlGridApi?.redrawRows?.({
            rowNodes: this.getVisitLineIdsForVisit(visits.map((v: any) => v.id))
        })
        this.refetching = false

    }

    async refetchVisit(visitId) {
        if (visitId == null) {
            return
        }
        let res = await C3.instance.client.fetcher.fetch(`/visit/${visitId}/full`, {})
        this.loadVisit(res)
        // console.log(`refetchVisit ${visitId}`, res);
    }

    async refetchVisitLinesForVisits(visitIds: any[], redraw = true) {
        return await Promise.all(_.flattenDeep(this.getVisitLinesForVisit(visitIds).map(vl => this.refetchVisitLine(vl.id, redraw))))
    }

    async refetchVisitLine(visitLineId, redraw = true) {
        let res = await C3.instance.client.fetcher.fetch(`/visit-line/${visitLineId}/full`, {})
        this.loadVisitLine(res, redraw)
        // console.log(`refetchVisitLine ${visitLineId}`, res);
    }

    @action
    loadVisit(res) {
        let index = _.findIndex(this.svvRes.visits, (v: any) => v.id == res.id);
        this.svvRes.visits[index] = res
        let visitField = this.getVisitField(res.id);
        // console.log(`visitField`, visitField, res);
        visitField?.set(null)
        visitField?.update(res)
    }

    @action
    loadVisitLine(res, redraw = true) {
        if (!res.id) {
            console.log(`Could not loadVisitLine with res`, res);
            return
        }
        let index = _.findIndex(this.svvRes.visitLines, (vl: any) => vl.id == res.id);
        this.svvRes.visitLines[index] = res
        // console.log(`vl ${res.id} res`, res);
        let visitLineField = this.getVisitLineField(res.id);
        let oldValue = visitLineField.value;
        // console.log(`vlf old values`, oldValue);
        // visitLineField.set('value', res)
        visitLineField.set(null)
        // console.log(`visitLineField.value`, visitLineField.value);
        visitLineField.update(res)
        let newValue = visitLineField.value;
        // console.log(`vlf new values`, newValue);
        // console.table([oldValue.payment.active, newValue.payment.active])

        if (redraw) {
            this.vlGridApi.refreshCells({
                rowNodes: [this.vlGridApi.getRowNode(res.id)]
            })
            this.vlGridApi.redrawRows({
                rowNodes: [this.vlGridApi.getRowNode(res.id)]
            })
        }
    }

    // [privinv] Private Invoicing

    @observable
    isAttachDocumentsMode = false

    @observable
    isAttachDocumentsModeReadonly = false

    // Invoicing globals
    @observable
    isPrivateInvoicingMode = false
    @observable
    isPrivateInvoicingModeMasterVisible = true

    allInvoicesForm: MRF

    @observable
    privateInvoicesRes = []


    @observable
    isAttachDocumentsModeLoading = false

    @observable
    isPrivateInvoicePreviewCollapsed = false

    @observable
    invoicePreviewZoom = 100

    privateInvoicesSel: C3Selection
    privateInvoiceForm: MRF
    @observable
    privateInvoiceRes


    initializingPrivateInvoiceMode = false

    paymentsSel: C3Selection


    @action
    async initPrivateInvoicingMode(creating = false, autoselect = true) {
        this.goToMainMode()
        this.initializingPrivateInvoiceMode = true
        if (!this.privateInvoicesSel) {
            // Init
            this.allInvoicesForm = new MobxReactForm({
                // fields {invoices: []}
            })
            this.allInvoicesForm.set({invoices: []})

            this.privateInvoicesSel = new C3Selection({
                // form: this.allInvoicesForm,
                // formPath: ['invoices'],
                onSelectionChanged: async (selectedIds, unSelectedIds) => {
                    await this.fetchInvoiceIntoForm()
                    await this.handleInvoicesSelectionChanged(selectedIds, unSelectedIds)

                }
            })
            this.paymentsSel = new C3Selection({
                form: this.privateInvoiceForm,
                formPath: ['payments'],
                onSelectionChanged: (selectedIds, unSelectedIds) => {
                }
            })
        }
        await this.fetchAllInvoices();

        if (autoselect) {
            if (this.visitLinesSel.selectedIds.length == 0) {
                this.selectAllAvailableToAddToInvoice()
            } else {
                // Deselect all non invoicable
                this.deselectNonInvoicable()
            }
        }

        // if (activePrivateInvoice?.id) await this.fetchInvoiceIntoForm()
        // if (!false) {
        //     // When there is no temporal invoice created
        //     // Deselect already invoiced lines
        //
        //     // Use this selection to create the new invoice
        //     if (creating) await this.createPrivateInvoice(true)
        // } else {
        //     // When there is such an invoice
        //     // Use the invoice lines to select visits
        //     let visitLineIds = this.svvRes.activePrivateInvoice?.lines?.map(line => line.visitLine?.id)
        //     // console.log(`visitLineIds`, visitLineIds);
        //     this.visitLinesSel.selectIds(visitLineIds, true, true, true, false)
        // }
        await new Promise<void>((resolve, reject) => setTimeout(() => {
            this.initializingPrivateInvoiceMode = false
            this.isPrivateInvoicingMode = true;
            this.refetch()
            resolve()
        }))

    }

    @action
    async closePrivateInvoicingMode() {
        // (window as any).requestIdleCallback(() => )
        // await this.deletePrivateInvoice()
        this.isPrivateInvoicingMode = false
        this.visitLinesSel.select({where: it => true, select: false, isUser: true})


    }

    async fetchAllInvoices() {
        this.privateInvoicesRes = await C3.instance.client.fetcher.fetch(`/patient/${this.patientId}/invoices`, {})
        this.allInvoicesForm.set({invoices: this.privateInvoicesRes})
        this.privateInvoicesSel.itemsRef.current = this.privateInvoicesRes
    }

    deselectNonInvoicable() {
        this.visitLinesSel.select({where: it => !this.canLineBeAddedToInvoice(it), select: false})
    }


    @action
    async fetchInvoiceIntoForm() {
        let invoiceId = this.privateInvoicesSel.selectedId
        if (invoiceId == null) return
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${invoiceId}/full`, {})
        this.privateInvoiceRes = _.merge({notes: '', comment: '', usingPatientAltInvoicing: false}, result)
        this.privateInvoiceRes.usingPatientAltInvoicing = (this.privateInvoiceRes.usingPatientAltInvoicing || false).toString()
        this.privateInvoiceForm.clear()
        this.privateInvoiceForm.set(result)
        console.log(`privateInvoiceRes`, JSON.parse(JSON.stringify(this.privateInvoiceRes)));
        console.log(`privateInvoiceForm`, this.privateInvoiceForm.values());
    }

    async addToInvoiceHandler() {
        let selectedIds = this.visitLinesSel.selectedIds;
        // Send selected ids and attach to active invoice
    }


    @computed
    get invoicesData() {
        return []
    }

    @computed
    get tmpSourceLines() {
        return this.visitLinesSel.selectedItems.filter(l => this.canLineBeAddedToInvoice(l))
    }

    // @computed
    // get invoiceData() {
    //     // Tmp invoice mode
    //     // let sourceLines = this.tmpSourceLines;
    //     // // console.log(`tmpSourceLines`, tmpSourceLines);
    //     // let lines = sourceLines.map((l) => {
    //     //     let visit = this.getLocalVisit(l.visit.id)
    //     //     let price = parseInt(l.payment?.active?.price);
    //     //     if (_.isNaN(price)) price = 0;
    //     //     return {
    //     //         date: moment(visit?.appointmentDate).toDate(),
    //     //         text: l.product?.name,
    //     //         unitPrice: price,
    //     //         quantity: 1,
    //     //         price: price,
    //     //     }
    //     // });
    //     let res = this.privateInvoiceRes;
    //     // if(res == null) return null;
    //     return {
    //         ...res,
    //         // lines: res.,
    //         // total: res.total//_.sum(lines.map(l => l.price))
    //
    //     }
    // }

    testing = true

    canLineBeAddedToInvoice(visitLine) {
        // Check if it already has an invoice
        // console.log(`canLineBeAddedToInvoice line`, line);
        let visit = this.getLocalVisit(visitLine.visit.id);

        if (this.testing) {
            return visitLine.invoiceLine?.paymentType == 'private' && !visitLine.invoiceNumber
        }

        return visit.isVisit && visitLine.invoiceLine?.paymentType == 'private' && visitLine.invoiceLine?.invoice?.id == null
    }


    @computed
    get potentialTotalLinesToAddToInvoice() {
        return this.visitLinesSel.items.filter(it => this.canLineBeAddedToInvoice(it)).length;
    }

    @computed
    get potentialLinesToAddToInvoice() {
        return this.visitLinesSel.selectedItems.filter(it => this.canLineBeAddedToInvoice(it)).length;
    }

    @computed
    get canEnterPrivateInvoicing() {
        if (this.selectedOrgIds.length > 1) return false
        return this.potentialLinesToAddToInvoice > 0
    }


    selectAllAvailableToAddToInvoice() {
        this.visitLinesSel.select({
            where: it => this.canLineBeAddedToInvoice(it),
            select: true,
            overwrite: true
        })
    }

    @computed
    get selectedOrgIds() {
        let array = this.visitsSel.selectedItems.map(v => v.resource?.org?.id);
        console.log(`array`, array);
        return _.uniq(array)
    }

    /**
     * Create an invoice with selected invoice lines to start with
     */
    async createPrivateInvoice(fromSelected?: boolean) {
        let orgId = _.first(this.selectedOrgIds);
        // console.log(`orgId`, orgId);
        let result = await C3.instance.client.fetcher.fetch(`/invoice/new-draft-invoice`, {
            method: 'POST', body: {
                patientId: this.patientId,
                orgId: orgId,
                counterCode: 1,
                startingLineIds: fromSelected ? this.tmpSourceLines.map(l => l.invoiceLine?.id) : []
            }
        });
        // await this.refetch()
        await this.fetchAllInvoices()
        this.privateInvoicesSel.selectId(result.id)
        if (!fromSelected) {
            this.visitLinesSel.clearSelection()
        }
        await this.refetch()
        return result
    }

    async goToInvoice(invoiceId) {
        if (!this.isPrivateInvoicingMode)
            await this.initPrivateInvoicingMode(false, false)
        setTimeout(() => this.privateInvoicesSel.selectId(invoiceId))
    }

    canCreatePrivateInvoice() {
        return this.potentialLinesToAddToInvoice > 0
    }

    @computed
    get isInvoiceOpen() {
        return this.privateInvoiceRes?.draftInvoiceNumber != null
            && this.privateInvoiceRes?.invoiceNumber == null
    }

    @computed
    get canInvoiceBeClosed() {
        return this.isInvoiceOpen && this.privateInvoiceRes?.invoiceLines?.length > 0 && this.privateInvoiceRes?.total != 0
    }

    async closePrivateInvoice() {
        if (this.privateInvoiceRes.invoiceNumber != null) return
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.privateInvoicesSel.selectedId}/close`, {
            method: 'POST', body: {}
        });
        await Promise.all([this.fetchAllInvoices(), this.fetchInvoiceIntoForm(), this.refetch()])
        this.privateInvoicesSel.selectId(result.id)
    }

    async savePrivateInvoice(body, refetch) {
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.privateInvoicesSel.selectedId}`, {
            method: 'PATCH', body: body
        });
        if (refetch) await this.refetch()
    }

    async savePrivateInvoiceLine(body) {
        let result = await C3.instance.client.fetcher.fetch(`/invoice-line/${body.id}`, {
            method: 'PATCH', body: body
        });
        return result
    }

    async savePrivateInvoiceAltFields(body, refetch) {
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.privateInvoicesSel.selectedId}/alt-invoicing`, {
            method: 'PATCH', body: body
        });
        if (refetch) await this.refetch()
        return result
    }

    async deletePrivateInvoice() {
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.privateInvoicesSel.selectedId}`, {
            method: 'DELETE'
        });
        await this.fetchAllInvoices()
        await this.refetch(false)
    }

    // [adoc] Attach documents
    @observable
    isDocAttachingToVisit = false


    @action
    setDocAttachingToVisit(value) {
        this.isDocAttachingToVisit = value
        this.handleDocumentsSelectionChanged([], [])
    }

    @observable
    documentsRes: AttachedDocument[] = []

    documentsSel: C3Selection

    documentsForm: MRF

    documentTypesMap = documentTypesMap

    @observable
    isAttachDocumentsModeLargePreview = true

    @observable
    isAttachDocumentsModeShowHistory = false

    @observable
    documentsStateMap: {
        [docId: string]: Partial<{ page, numPages, downloadUrl, loading, error, retries, pdfError }>
    } = {}

    @action
    getDocumentState(documentId): SVVStore['documentsStateMap']['docId'] {
        let s = this.documentsStateMap[documentId];
        if (s == null) this.documentsStateMap[documentId] = {
            page: 1,
            error: false,
            loading: true
        }
        return this.documentsStateMap[documentId]
    }

    @action
    setDocumentState(documentId, state: SVVStore['documentsStateMap']['docId']) {
        return this.documentsStateMap[documentId] = _.merge(this.documentsStateMap[documentId], state)
    }

    @action
    async fetchDocumentsList() {
        let documents = await C3.instance.client.fetcher.fetch(`/patient/${this.patientId}/documents?showHistory=${!this.isAttachDocumentsModeShowHistory}`, {})
        this.documentsRes = documents
        this.documentsSel.itemsRef.current = this.documentsRes
        this.documentsForm.update({documents: documents})
    }


    @action
    async updateDocument(body) {
        // opt. update
        let document = this.documentsList.find(d => d.id == body.id);
        _.merge(document, body)
        // fetch to server
        if (body.id > 0) {
            let documents = await C3.instance.client.fetcher.fetch(`/document/${body.id}`, {method: 'PATCH', body})
        } else {
            //
        }
        await this.fetchDocumentsList();
    }

    @action
    async updateInvoice(body, fetching = true) {
        // opt. update optimistically invoice lines
        // this.svvRes.visitLines[0].invoiceNumber = 'AAA'
        this.vlGridApi.refreshCells({suppressFlash: true})
        // FIXME
        // fetch to server
        let invoice = await C3.instance.client.fetcher.fetch(`/invoice/${body.id}`, {method: 'PATCH', body})
        if (fetching) await this.refetch()
        if (body.id > 0) {
        } else {
            //
        }
    }

    @computed
    get documentsList() {
        return (this.documentsRes || [])
    }

    @computed
    get canAttachDocuments() {
        return true
        return this.visitsSel.selectedItems.every(v => v.visitNumberPrefix == 'V')
    }

    // nextDocumentId = -1000
    isAttachingDocuments = false

    @action
    async attachDocuments(files: FileList) {
        this.isAttachingDocuments = true
        let docs = []
        let filesArray = []

        for (let i = 0; i < files.length; i++) {
            let file: any = files.item(i);
            // let id = this.nextDocumentId--;
            docs.push({name: file.name} as any)
            filesArray.push(file)
        }
        // let newBody = _.clone(body)
        let documents = await C3.instance.client.fetcher.fetch(`/document/many`, {
            method: 'POST', body: docs.map(ld => {
                return {
                    ...ld,
                    patient: {id: this.patientId}
                }
            })
        })

        let process = (fieldName, file, metadata, load, error, progress, abort, url, transfer, options) => {
            // From MinIO
            async function retrieveNewURL() {
                // let res = await client(`/files/upload-url?filename=${file.name}-${myForm.$('random').value}`, {
                //     auth: false
                // });
                // return res.uploadUrl
                return url
            }

            // console.log(`Using UL URL`, url);
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);
            // console.log(`fieldName, file, file.name`, fieldName, file, file.name);
            const request = new XMLHttpRequest();

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
                // console.log(`progress e.lengthComputable, e.loaded, e.total`, e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText);
                } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no');
                }
            };

            retrieveNewURL().then(url => {
                console.log(`posting to url`, url);
                request.open('PUT', url);
                request.send(formData);
            })


            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                }
            };
        }
        documents.forEach((d, i) => {
            let file = filesArray[i];
            let load = (...p) => {
                console.log(`load`, ...p);
            };
            let error = (...p) => {
                console.log(`error`, ...p);

            };
            let progress = (...p) => {
                console.log(`progress`, ...p);

            };
            let abort = (...p) => {
                console.log(`abort`, ...p);

            };
            let bucket = 'dimat-documents';
            bucket = 'guirado-docs-2';
            let proc = process(bucket, file, file.metadata, load, error, progress, abort, d.uploadUrl, null, null)
        })

        await this.fetchDocumentsList()
        // delete newBody.id
        this.isAttachingDocuments = false

    }

    downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link['_target'] = 'blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async getDlDoc(documentId) {
        let dlDoc = await C3.instance.client.fetcher.fetch(`/document/${documentId}/dl`, {});
        return dlDoc
    }

    async dlDocument(documentId) {
        let dlDoc = await this.getDlDoc(documentId)

        this.downloadURI(dlDoc.downloadUrl, dlDoc.name)
    }

    async deleteSelectedDocuments() {
        let res = await C3.instance.client.fetcher.fetch(`/document/many`, {
            method: 'DELETE',
            body: this.documentsSel.selectedIds
        });
        await this.fetchDocumentsList()
        return res
    }

    @computed
    get canDeleteDocuments() {
        return this.documentsSel.selectedIds.length > 0
    }

    documentsVisitsSel: C3Selection

    // documentVisitLinesSel: C3Selection

    @action
    async initAttachDocuments() {
        this.goToMainMode()
        this.isAttachDocumentsModeLoading = true
        if (!this.documentsForm) {
            this.documentsForm = new MobxReactForm({
                fields: [
                    'documents[]',
                    ...[
                        'id',
                        'name',
                        'description',
                        'date',
                        'createdAt'
                    ].map(p => `documents[].${p}`)
                ]
            })
        }
        if (!this.documentsVisitsSel) {
            this.documentsVisitsSel = new C3Selection({
                itemsRef: this.visitsItemsRef,
                onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                    let selectedDocument = this.documentsSel.selectedItem;
                    let ids = this.documentsVisitsSel.selectedIds;
                    if (selectedDocument)
                        await this.updateDocument({id: selectedDocument.id, visits: ids.map(id => ({id}))})
                }
            })
        }
        this.visitLinesSel.clearSelection()
        this.documentsVisitsSel.itemsRef = this.visitsItemsRef
        // if (!this.documentVisitLinesSel) {
        //     this.documentVisitLinesSel = new C3Selection({
        //         itemsRef: this.visitLinesSel.itemsRef
        //
        //     })
        // }
        if (!this.documentsSel) {
            this.documentsSel = new C3Selection({
                form: this.documentsForm,
                items: this.documentsRes,
                formPath: ['documents'],
                onSelectionChanged: (selectedIds: any[], unSelectedIds: any[]) => {
                    this.handleDocumentsSelectionChanged(selectedIds, unSelectedIds)
                }
            })
        }
        // Fetch documents list
        await this.fetchDocumentsList()

        this.isAttachDocumentsModeLoading = false
        this.isAttachDocumentsMode = true
    }

    async exitAttachDocuments() {
        this.isAttachDocumentsMode = false
        this.visitLinesSel.clearSelection()
        await this.refetch()
    }

    handleDocumentsSelectionChanged(selectedIds: any[], unSelectedIds: any[]) {
        if (!this.documentsSel) return
        if (this.documentsSel.selectedItems.length == 1) {
            let selectedItem = this.documentsSel.selectedItem;
            // console.log(`hdsc selectedItem`, selectedItem);

            this.documentsVisitsSel.selectIds((selectedItem.visits || []).map(v => v.id), true, true, true, false)
            this.visitLinesSel.selectIds((selectedItem.visitLines || []).map(vl => vl.id), true, true, true, false)
            //
            // if (!selectedItem.visits || !selectedItem.visitLines) {
            //     this.visitLinesSel.clearSelection()
            //     this.documentsVisitsSel.clearSelection()
            // }else {
            //
            // }

            // if (this.isDocAttachingToVisit) {
            //     // If attaching to visit
            //     this.selectVisitsOnly(selectedItem.visits.map(v => v.id), true)
            // } else {
            // }
            // If attaching to visit line
        } else {
            this.visitLinesSel.clearSelection()
        }
    }

    handleInvoicesSelectionChanged(selectedIds: any[], unSelectedIds: any[]) {
        if (!this.privateInvoicesSel) return
        if (this.privateInvoicesSel.selectedItems.length == 1) {
            // let selectedInvoice = this.privateInvoicesSel.selectedItem;
            console.log(`this.privateInvoiceRes.invoiceLines`, this.privateInvoiceRes.invoiceLines);
            let visitLineIds = this.privateInvoiceRes.invoiceLines.map(il => il.visitLine.id);
            this.visitLinesSel.selectIds(visitLineIds, true, true, true, false)
        } else {
            this.visitLinesSel.clearSelection()
        }
    }

    async handleLinesGridSelectionChanged(selectedIds: any[], unSelectedIds: any[], isUser) {
        await this.handleLinesGridSelectionChangedForDocuments(selectedIds, unSelectedIds, isUser)
        await this.handleLinesGridSelectionChangedForInvoices(selectedIds, unSelectedIds, isUser)
    }

    async handleLinesGridSelectionChangedForDocuments(selectedIds: any[], unSelectedIds: any[], isUser) {
        // console.log(`handleLinesGridSelectionChanged`, this.visitLinesSel.selectedIds);
        if (!this.documentsSel || !this.isAttachDocumentsMode) return
        if (this.documentsSel.selectedItems.length == 1) {
            let selectedDocument = this.documentsSel.selectedItem;
            if (this.isDocAttachingToVisit) {
                // If attaching to visit
                let exisitngIds = this.visitsSel.selectedIds;
                let newIds = [...exisitngIds, selectedIds].filter(id => !unSelectedIds.includes(id))
                await this.updateDocument({id: selectedDocument.id, visits: exisitngIds.map(id => ({id}))})
            } else {
                // If attaching to visit line
                await this.updateDocument({
                    id: selectedDocument.id,
                    visitLines: this.visitLinesSel.selectedIds.map(id => ({id}))
                })
            }

        }

    }

    refetchDebounced = _.debounce(this.refetch, 50)

    async handleLinesGridSelectionChangedForInvoices(selectedIds: any[], unSelectedIds: any[], isUser) {
        if (!this.privateInvoicesSel || !this.isPrivateInvoicingMode) return
        if (this.privateInvoicesSel.selectedItems.length == 1) {
            let selectedInvoice = this.privateInvoicesSel.selectedItem;
            let useableLines = this.visitLinesSel.selectedItems
                .filter(vl => vl.invoiceLine != null)
            // .filter(vl => this.canLineBeAddedToInvoice(vl))
            let lines = useableLines
                .map(vl => ({id: vl.invoiceLine.id}));
            // this.visitLinesSel.selectIds(useableLines.map(vl => vl.id), true, false)
            await this.updateInvoice({
                id: selectedInvoice.id,
                lines: lines
            }, false)
            console.log(`setting lines`, lines);
            if (!this.refetching && isUser)
                await this.refetchDebounced()

        }

    }


    // [print]
    printHandle: any = null;

    isPrinting = false
    // async printInvoice() {
    //     this.printHandle()
    //     let div = document.getElementById('invoice_content');
    // }


    async printInvoice() {
        this.isPrinting = true
        await nextTimeout(500)
        this.printHandle()
        await nextTimeout(2000)
        //
        this.isPrinting = false

    }

    @observable
    isPrintCanvasMode = false

    @observable
    printItemsPending = ['visitLabel', 'fitxaBlanca', 'yourTurn', 'medicalReceipt']

    @observable
    currentlyPrinting: string


    async printQueue(queue: string[], showOnly?) {
        if (queue.includes('yourTurn')) {
            await this.allVisitsForTodayToWR()
            // await this.fetchTurnNumber(true)
        }
        await nextAnimationFrame();
        if (showOnly && this.isPrintCanvasMode) {
            this.isPrintCanvasMode = false
            return
        }
        if (this.isPrintCanvasMode) return
        this.printHandle = null
        this.isPrintCanvasMode = true

        await this.sleep(1)
        for (let i = 0; i < queue.length; i++) {
            this.currentlyPrinting = queue[i]
            await this.sleep(500)
            if (showOnly) {
                return
            }
            // Use handle
            let success = false
            for (let j = 0; j < 20; j++) {
                if (this.printHandle != null) {
                    this.printHandle()
                    success = true
                    break;
                } else {
                    await this.sleep(150)
                }
            }

            await this.sleep(800)
            if (success) {
                this.printItemsPending = _.difference(this.printItemsPending, [this.currentlyPrinting])
            }
            this.printHandle = null
        }
        this.isPrintCanvasMode = false
    }

    isPrintPending(keys) {
        return _.difference(this.printItemsPending, keys).length != this.printItemsPending.length
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    @observable
    patientTurnNumber
    @observable
    patientTurnRes

    async fetchTurnNumber(creating = false) {
        let res = await C3.instance.client.fetcher.fetch(`/patient/${this.patientId}/turn-number`, {
            query: [`creating=${creating}`]
        })
        console.log('res', res)
        this.patientTurnNumber = res?.turnNumber
        this.patientTurnRes = res
    }

    async allVisitsForTodayToWR() {
        let res = await C3.instance.client.fetcher.fetch(`/patient/${this.patientId}/all-visits-to-waiting-room`, {})
        this.patientTurnNumber = res?.turnNumber
        this.patientTurnRes = res
        await this.refetch()
        console.log('res  ', res)
    }

    // [delivery] Delivery mode

    @observable
    isDeliveryMode: boolean = false


    // [payment]

    paymentsRes: any

    @computed
    get payments() {
        return this.privateInvoicesSel.selectedItem?.payments
    }

    async createPayment() {
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.privateInvoicesSel.selectedId}/payments`, {
            method: 'POST', body: {
                paymentForm: 'cc'
            }
        });
        await this.fetchInvoiceIntoForm()
    }

    async savePayment(body) {
        let result = await C3.instance.client.fetcher.fetch(`/payment/${body.id}`, {
            method: 'PATCH', body: {
                ...body
            }
        });
        return result
    }


    //
    @observable.shallow
    patientStats


    async fetchPatientStats() {
        let [res, res2] = await Promise.all([await C3.instance.client.fetcher.fetch(`/patient/${this.patientId}/statistics`, {}), this.fetchPatientVtfStats()])
        this.patientStats = res
    }

    async fetchPatientVtfStats() {
        let historySinceDate = this.getHistorySinceDate();
        let res = await C3.instance.client.fetcher.fetch(`/patient/${this.patientId}/vtf-statistics`, {
            query: [historySinceDate != null ? `historySince=${historySinceDate.toISOString()}` : null].filter(v => v != null).join('&'),
        })
        this.vtfStatsMap[this.historyViewMode] = res
    }

    @computed
    get patientVtfStats() {
        return this.vtfStatsMap[this.historyViewMode] || {}
    }


    // Type filter
    visitTypesSel: C3Selection = new C3Selection({
        items: [
            {id: 'T', name: 'Temporals'},
            {id: 'C', name: 'Cites'},
            {id: 'V', name: 'Visites'},
        ],
    })
    @observable
    vtfStatsMap = {}

    @computed
    get visitTypeFilter() {
        return this.visitTypesSel.selectedIds.join('')
    }

    // [wkl] worklist
    async sendVisitLineToWorklist(visitLineId, add = true) {
        let res = await C3.instance.client.fetcher.fetch(`/telera/worklist-sync/${visitLineId}`, {
            query: [`add=${add}`].filter(v => v != null).join('&'),
        })
        await this.refetchVisitLine(visitLineId)
    }

    async getDocumentFromWorklist(visitLineId, add = true) {
        let res = await C3.instance.client.fetcher.fetch(`/telera/test-report/${visitLineId}`, {})
    }

    // [test]

    async test1() {
        await this.addVisit({
            appointmentDate: new Date(),
            resourceId: 18
        })

    }


}

interface AttachedDocument {
    id
    name
    attachedTo?: {
        visitLines: any[],
        visits: any[],
    }

    [other: string]: any
}

export class DateTimeFilterStore {

    constructor() {
        this.reset()
    }

    @observable
    morning = true

    @observable
    afternoon = true

    @observable
    enabledWeekdays: boolean[]

    @observable
    enabledMonths: boolean[]

    @observable
    startDate: Date

    @observable
    endDate: Date


    @action
    reset() {
        this.morning = true
        this.enabledWeekdays = [false, ..._.range(5).map(() => true), false]
        this.startDate = new Date();
        this.endDate = moment(this.startDate).add(1, 'week').toDate()
        this.enabledMonths = _.range(12).map(() => true)
    }

    @computed
    get startMonth() {
        return moment(this.startDate).get('month')
    }

    @action
    setMorningOrAfternoonEnabled(key: 'morning' | 'afternoon', enabled = true) {
        this[key] = enabled;
        if (!this.morning && !this.afternoon) {
            this[{morning: 'afternoon', afternoon: 'morning'}[key]] = true
        }
    }

    @action
    setWeekDayEnabled(weekday: number, enabled = true) {
        this.enabledWeekdays[weekday] = enabled;
    }

    @action
    setMonthEnabled(month, enabled = true) {
        this.enabledMonths[month] = enabled
    }


}
