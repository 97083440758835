import React, {useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3Form, C3MasterDetail, DetailLayout, Form, MRF, useC3Store} from "react-c4";
import {PatientOverviewStore} from "./PatientOverviewStore";
import {PatientInfo} from "./sections/1-PatientInfo/PatientInfo";
import {TabsArea} from "./sections/3-TabsArea/TabsArea";
import {doc} from "prettier";
import {PatientOverviewHeader} from "./PatientOverviewHeader";
import {AVVStore} from "../AllVisitsView/AVVStore";

const Container = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
    //overflow: hidden;
`


export interface PatientOverviewProps {
    form?: C3Form
    md?: C3MasterDetail
    pageContext
    featureMode: 'booking' | 'technician' | 'reception' | 'patient'
    externalStore?: any
    onStoreReady?: (s: PatientOverviewStore) => any

}

export const PatientOverview = observer((props: PatientOverviewProps) => {
    let store = useC3Store(() => {
        let storeInst = new PatientOverviewStore(props.md, props.form, props.featureMode, props.externalStore);
        return storeInst
    }, (store) => {

    })
    useEffect(() => {
        if (store && props.onStoreReady) props.onStoreReady(store)
    }, [store])
    if (store == null) return null;

    const locked = store.form.isLocked
    const creating = store.form.isCreating
    let form = store.form;
    let patientForm: MRF = form.form;
    let externalCreating = props.externalStore?.patientsMD?.form?.creating;

    return <DetailLayout
        md={props.md}
        renderHeader={() => {
            return <PatientOverviewHeader store={store} pageContext={props.pageContext} externalStore={props.externalStore}/>
        }}
    >
        <Container>
            <PatientInfo store={store} pageContext={props.pageContext} showAlerts={!externalCreating}/>
            {!(creating || externalCreating)  && <React.Fragment>
                {/*{store.hasFeature('testsInfo') && <TestsInfo store={store}/>}*/}
              <Form value={store.visitsMD.form}>
                <TabsArea store={store} pageContext={props.pageContext}/>
              </Form>
            </React.Fragment>}

        </Container>
    </DetailLayout>
})
