import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Form, Field, SelectControl, DateInputControl, getMomentFormatter, TimeInputControl} from "react-c4";
import {useSVVStore} from "../../../StandardVisitView";
import {Button, Tooltip} from "@blueprintjs/core";
import moment from "moment";
import {Select} from "@blueprintjs/select";
import {MRFField} from "react-c4/src/forms/MRF";
import {bindWithAS, formatFullName, isDev, useAS} from "../../../../../application/utils/utils";
import {DateInput, TimePicker} from "@blueprintjs/datetime";
import {toJS} from "mobx";

const Container = styled.div`
  display: grid;
  //background-color: #a1dbff;
  padding: 0 0px 0;
  grid-gap: 8px;
  grid-template-columns: 0.5fr 0.4fr 1fr auto 2fr;
`
const ResourceSelectorContainer = styled.div`

`
const DateTimeAreaContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: 4px;
  

  > input {
    width: 70px;
  }
`
const UserInfoContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;
  font-weight: bold;
  align-self: center;
  width: 100%;
`
const TestsOverviewContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-column: span 2;
`

export interface SVHFirstRowProps {

}

export const SVHFirstRow = observer((props: SVHFirstRowProps) => {
    let store = useSVVStore()
    let visit = store.visitsSel.selectedItem
    let visitField: MRFField;
    if (visit?.id) visitField = store.visitsSel.getFieldById(visit.id);
    let as = useAS((field, e) => {
        // if (!visitField) return
        let fieldName = field.name;
        let visitField = store.visitsSel.getFieldById(store.visitsSel.selectedId);
        let body = {id: visitField.$('id').value};
        console.log(`store.visitsSel.selectedId`, store.visitsSel.selectedId);
        // console.log(`visitField`, toJS(visitField));
        _.set(body, fieldName, visitField.$(fieldName).value)
        let promise = store.saveVisit(body, true).then(() => {
            if (fieldName == 'appointmentDate') store.refetchVisitLinesForVisits([visitField.$('id').value], true)
        })
    })

    if (visit == null) return null
    let visitSelField = store.visitsSel.selField;
    // console.log(`visitSelField.value`, visitSelField, visitSelField.value);


    // let visitField = visitSelField;
    visitSelField = visitField
    if (visitField == null) return null
    let moment1 = moment(visitField.select('appointmentDate', null, false)?.value);
    let $duration = visitField.$('duration');
    let duration = $duration.value;
    let moment2 = moment1.clone().add(duration, 'minutes');
    let resourceId = visitSelField.select('resource', null, false)?.value?.id;
    let resourceProductMapElement = store.resourceProductMap[resourceId];
    store.ensureTmpProductsInVisit(visit.id)
    let visitLinesFields = store.getVisitLinesFields(visit.id);
    let fixedOptions = visitLinesFields
        .map(vlf => vlf.value.product)
        .filter(v => v?.id != null);
    let groups: any = _.groupBy(fixedOptions, p => p.id)
    let groupedFixedOptions = _.keys(groups).map(gk => {
        let groupLength = groups[gk].length;
        let counter = groupLength > 1 ? ` (${groupLength})` : '';
        return ({id: gk + 10000, name: `${groups[gk][0]?.name}${counter}`});
    });
    if (!visitSelField.has('tmpProducts')) return null;
    let $tmpProducts = visitSelField.$('tmpProducts');

    let disabled = store.getVisitLinesFields(visit.id).map(vlf => vlf.$('product').value?.id).filter(v => v != null).length > 0;
    return <Container>
        <Field
            key={visit?.id}
            field={visitSelField.select('resource', null, false)}
            c={SelectControl}
            options={store.resourcesDS.items}
            props={{style: {zIndex: 120}, disabled: disabled, width: 200}}
            afterChange={(e, field) => {
                // console.log(`ON CHANGE`, field.value);
                return store.saveVisit({id: visit.id, resource: {id: field.value?.id}});
            }}
        />
        {/*<select*/}
        {/*    // onChange={async (e) => {*/}
        {/*    //     // visit.resource =*/}
        {/*    //     await store.saveVisit({id: visit.id, resource: {id: e.target.value}})*/}
        {/*    //     // await store.refetch();*/}
        {/*    // }}*/}
        {/*    value={$resource.value?.id}*/}
        {/*    onChange={e => $resource.onChange(e.target.id)}*/}
        {/*>*/}
        {/*    <option value={null}>--</option>*/}
        {/*    {store.resourcesDS.items?.map((res) => {*/}
        {/*        return <option value={res.id}>{res.name}</option>*/}
        {/*    })}*/}
        {/*</select>*/}

        {/*<ResourceSelectorContainer>*/}
        {/*    <select value={visit.resource?.id} onChange={async (e) => {*/}
        {/*        // visit.resource =*/}
        {/*        await store.saveVisit({id: visit.id, resource: {id: e.target.value}})*/}
        {/*        // await store.refetch();*/}
        {/*    }}>*/}
        {/*        <option value={null}>--</option>*/}
        {/*        {store.resourcesDS.items?.map((res) => {*/}
        {/*            return <option value={res.id}>{res.name}</option>*/}

        {/*        })}*/}
        {/*    </select>*/}
        {/*</ResourceSelectorContainer>*/}
        {/*<TestsOverviewContainer>*/}
        {/*    <span>Total: {store.visitLinesSel.selectedItems.length} proves</span>*/}
        {/*    <Button icon={'plus'} onClick={() => store.addVisitLine()}>Afegir prova</Button>*/}
        {/*</TestsOverviewContainer> */}
        <TestsOverviewContainer>
            <Field
                key={visit?.id}
                field={$tmpProducts}

                c={SelectControl}
                options={resourceProductMapElement || []}
                props={{
                    disabled: !store.isMainMode,
                    style: {zIndex: 120}, multi: true, fixedOptions: groupedFixedOptions,
                    onKeyDown: (e) => {
                        if (e.keyCode === 13 && e.ctrlKey) {
                            store.addVisitLineForProducts()
                        }
                    }
                }}
                afterChange={(e, field) => {
                    // console.log(`ON CHANGE`, field.value);
                    // return store.saveVisit({id: visit.id, resource: {id: field.value?.id}});
                }}
            />
            <Button icon={'plus'} disabled={$tmpProducts?.value?.length <= 0}
                    onClick={() => store.addVisitLineForProducts()}>Afegir</Button>
        </TestsOverviewContainer>
        <DateTimeAreaContainer>
            {<div style={{width: 93}}>
                <DateInputControl
                    field={visitField.$('appointmentDate')}
                    as={as}
                    locked={!store.isMainMode}

                />
            </div>}
            {visitField.$('appointmentDate').value && <TimeInputControl
                style={{border: moment1.hour() < 8 ? '1px solid red' : '1px solid gray', padding: 4, borderRadius: 3}}
                field={visitField.$('appointmentDate')}
                as={as}
                locked={!store.isMainMode}
            />}

            {/*{visitField.$('appointmentDate').value && <TimePicker*/}
            {/*    {...bindWithAS(visitField.$('appointmentDate'), as)}*/}
            {/*    {...getMomentFormatter('DD/MM/YYYY')}*/}

            {/*    disabled={!store.isMainMode}*/}
            {/*/>}*/}
            {/*<input type={'text'} value={moment1.isValid() ? moment1.format('DD/MM/YY') : '--'} disabled={true}*/}
            {/*       width={'12/10/20'.length}*/}
            {/*       onClick={() => store.visitViewMode = "calendar"}*/}
            {/*       onChange={() => null}*/}
            {/*/>*/}
            {/*<input value={moment1.isValid() ? moment1.format('HH:mm') : '--'} disabled={true} width={'11:30'.length}*/}
            {/*       style={{width: 42}}*/}
            {/*       onClick={() => store.visitViewMode = "calendar"}*/}
            {/*       onChange={() => null}*/}
            {/*/>*/}
            <input value={moment2.isValid() ? moment2.format('HH:mm') : '--'} disabled={true} width={'11:30'.length}
                   style={{width: 42}}
                   onClick={() => store.visitViewMode = "calendar"}
                   onChange={() => null}
            />
            <input {...$duration.bind()} placeholder={'(30)'} width={'999'.length}
                   style={{width: 42}}
                   disabled={!store.isMainMode}
                // type="number"
                   onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {

                       // if("0123456789".indexOf(e.key) == -1) e.preventDefault()
                       if (e.key == 'Enter') {
                           await store.updateVisitDuration(visit.id, $duration.value)
                       }
                   }}
            />
            <label>min</label>
            {store.isDurationDirty && <>
                <Tooltip content={'Actualitza duració de la visita propocionalment (enter)'}
                         hoverOpenDelay={2000}
                >
                    <Button icon={'key-enter'}
                            intent={'primary'}
                            onClick={async () => {
                                await store.updateVisitDuration(visit.id, $duration.value)
                            }}
                    />
                </Tooltip>
                <Button icon={'cross'}
                        onClick={async () => {
                            await store.refetchVisit(visitSelField.$('id').value)
                        }}
                />
            </>}
        </DateTimeAreaContainer>
        <UserInfoContainer>
            <div>
                {visit.createdBy &&
                <Tooltip
                    content={formatFullName(visit.createdBy)}
                >
                    <span>(Agendada per: {visit.createdBy.name})</span>
                </Tooltip>
                }
            </div>
            {visit.createdBy && visit.admittedBy && <span> | </span>}
            {(store.patientTurnNumber || visit.admittedBy) &&
            <div>
                Torn:
                <span>
                    {store.patientTurnNumber} {store.patientTurnRes?.displayString}
                </span>
                {store.patientTurnNumber && visit.admittedBy && <span> - </span>}
                <Tooltip
                    content={formatFullName(visit.admittedBy)}
                >
                    {visit.admittedBy && <div>
                        {visit.admittedBy?.name}
                    </div>}
                </Tooltip>
            </div>}
        </UserInfoContainer>
    </Container>
})
