import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {CellClickedEvent, CellValueChangedEvent, RowNode} from "ag-grid-community";
import {useRFVStore} from "../ResourceFileView";
import {observer} from "mobx-react";
import {SVVLinesGridVisitCR} from "../../../components/StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridVisitCR";
import {SVVLinesGridReferrerCE} from "../../../components/StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridReferrerCE";
import * as _ from 'lodash'
import {formatFullName, stdBoolColumn, stdIntNumericColumn} from "../../../application/utils/utils";
import {Popover, ButtonGroup, Button} from "@blueprintjs/core";
import moment from "moment";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`
const GridTitle = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
`



export interface ResourceEditorProps {

}

export const ResourceEditor = observer((props: ResourceEditorProps) => {
        let store = useRFVStore()

        let res = store.resourcesRes;
        const editableCheck:boolean = store.resourceLocked

        return <Container>
            <GridTitle>(1) Selecioni una màquina:</GridTitle>
            <MasterLayout
                renderHeader={() => {
                    return null
                }}
                body={
                    <C3AgGrid
                        floatingFilter={false}
                        defaultColDef={{
                            editable: false,
                            // filter: 'agTextColumnFilter'
                            resizable: true,
                            sortable: true,
                            suppressMenu: true,
                            width: 100
                        }}

                        columnDefs={[
                            {
                                field: '',
                                headerName: '',
                                editable: false,
                                width: 30,
                                checkboxSelection: true,
                                // suppressFilter: true,
                            },
                            {
                                field: 'name',
                                headerName: 'Nom',
                                editable: editableCheck,
                                floatingFilter: true,
                                headerTooltip: 'Nom',
                                filter: 'agTextColumnFilter',

                            },
                            {
                                field: 'code',
                                headerName: 'C. Màquina',
                                editable: editableCheck,
                                floatingFilter: true,
                                width: 60,
                                headerTooltip: 'Codi Màquina',
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'name',
                                headerName: 'Nom Tècnics',
                                editable: editableCheck,
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'title',
                                headerName: 'Nom Agenda Recepció',
                                editable: editableCheck,
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'shortName',
                                headerName: 'Nom TV Sala Espera',
                                editable: editableCheck,
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'altName',
                                headerName: 'Nom Altres',
                                editable: editableCheck,
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'tvIcon',
                                headerName: 'Títol Icona',
                                editable: editableCheck,
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'isActive',
                                headerName: 'Activa',
                                editable: false,
                                ...stdBoolColumn(()=>store.resourceLocked),
                                width: 55,
                            },
                            {
                                field: 'modality',
                                headerName: 'Modality',
                                editable: editableCheck,
                                width: 55,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'stationName',
                                headerName: 'Station Name',
                                editable: editableCheck,
                                width: 55,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'aet',
                                headerName: 'AET',
                                editable: editableCheck,
                                width: 55,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'oldCode',
                                headerName: 'Codi Antic',
                                editable: editableCheck,
                                floatingFilter: true,
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'extra',
                                headerName: 'Comentaris',
                                editable: true,
                                floatingFilter: true,
                                width: 110,
                                filter: 'agTextColumnFilter',
                            },
                            {
                                field: 'id',
                                headerName: 'Id',
                                editable: false,
                                width: 60,
                                ...stdIntNumericColumn(),
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },

                        ]}
                        processCellCallback={(params) => {
                            let data = (params.node as RowNode).data
                            return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                        }}
                        getContextMenuItems={(params) => {
                            // let fileName = store.fr
                            var result = [
                                {
                                    // custom item
                                    name: 'Exporta a Excel',

                                    action: function () {
                                        let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
                                        params.api.exportDataAsExcel({
                                            fileName: `llistat-màquines-${orgName}`,
                                            processCellCallback: (params) => {
                                                let data = (params.node as RowNode).data
                                                if (params.column.getColId() == 'isActive') {
                                                    return data.isActive == true ? 'X' : '';
                                                }
                                                return params.value
                                            }
                                        })
                                    },
                                }
                            ]
                            return result
                        }}
                        rowData={store.resourcesRes}
                        selection={store.resourcesSel}
                        cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                            await store.saveResource({
                                id: e.data.id,
                                [e.colDef.field]: e.newValue
                            })
                        }}

                    />
                }
            />
        </Container>
    }
)
