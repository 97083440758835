import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MIVStore} from "./MIVStore";
import {useC3Store} from "react-c4";
import {MIVMain} from "./MIVMain/MIVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const MIVContext = React.createContext(null as MIVStore)
export const useMIVStore = () => useContext(MIVContext)

export interface ManualInvoicingViewProps {
    pageContext?
    onStoreReady: (s: MIVStore) => any
}

export const ManualInvoicingView = observer((props: ManualInvoicingViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new MIVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <MIVContext.Provider value={store}>
        <Container>
            <MIVMain/>
        </Container>
    </MIVContext.Provider>
})
