import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useICIStore} from "../../InsCompanyInvoicingView";
import {Button, ButtonGroup, Checkbox, Tag, Tooltip} from "@blueprintjs/core";
import {stopPropagationForAgGrid} from "ag-grid-community/dist/lib/utils/event";
import {colors} from "../../../../application/utils/colors";
import {DateInputControl} from "react-c4";
import {isDev, useAS} from "../../../../application/utils/utils";
import * as _ from "lodash";
import moment from "moment";
import {ICIInsCompanyBodySecondRow} from "./ICIInsCompanyBodySecondRow";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0 4px;
  row-gap: 8px;
`
const ModeSwitchContainer = styled.div`

`
const LeftContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
`
const TitleContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`
const RightContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
`


export interface ICIInsCompanyBodyHeaderProps {

}

export const ICIInsCompanyBodyHeader = observer((props: ICIInsCompanyBodyHeaderProps) => {
    let store = useICIStore();
    let intent: any = 'none';
    if (store.isInvoicableType) intent = 'primary'
    let invoice = store.invoiceRes;
    let invoiceForm = store.invoiceForm
    let as = useAS((field, e) => {
        let fieldName = field.path;
        let body = {id: invoiceForm.$('id').value};
        _.set(body, fieldName, invoiceForm.$(fieldName).value)
        let promise = store.savePrivateInvoice(body, true);
    })

    return <Container>

        <LeftContainer>
            {store.isInvoicableType &&
            <Tag large intent={intent} icon={store.typeSel.selectedId == 'chipcard' ? 'sim-card' : 'hand'}>
                {store.typeSel.selectedItem?.name}
            </Tag>}
            <TitleContainer>
                <span style={{fontWeight: 'bold'}}>
                    {store?.insCompaniesSel?.selectedItem?.name} {store.visitLinesSel.items?.length > 0 && `(${store.visitLinesSel.items?.length})`}</span>
                <span style={{fontWeight: 'bold', fontSize: 13}}>
                    {store.invoiceStatus == 0 &&
                    <span>Línies no facturades {store.typeSel.selectedItem.name.toUpperCase()} del {moment(store.startDate).format('DD/MM/YYYY')} al {moment(store.endDate).format('DD/MM/YYYY')}</span>}
                    {store.invoiceStatus == 1 &&
                    <span style={{color: colors.chromeRed}}>Pre-Factura {invoice.invoiceNumberText}</span>}
                    {store.invoiceStatus == 2 && <span>Factura {invoice.invoiceNumberText} </span>}
                    {/*{invoice?.draftInvoiceNumber}*/}

                </span>
            </TitleContainer>


        </LeftContainer>
        <div/>
        <RightContainer>
            {store.isPreview && <>
              <Button
                icon={'zoom-in'}
                disabled={store.invoicePreviewZoom > 200}
                onClick={() => {
                    store.invoicePreviewZoom += 15
                }}/>
              <Button
                icon={'zoom-out'}
                disabled={store.invoicePreviewZoom < 20}
                onClick={() => {
                    store.invoicePreviewZoom -= 15
                }}/>
            </>}

            <div/>
            {(!store.assistantMode && store.invoiceStatus == 1) && <Button
              onClick={() => store.deleteInvoice()}

              icon={'trash'}>
              Esborra {invoice?.invoiceNumberText}
            </Button>}
            Canvi data:
            {<Button
                onClick={() => store.headerExpanded = !store.headerExpanded}
                icon={store.headerExpanded ? 'double-chevron-up' : 'double-chevron-down'}/>}
            <ButtonGroup>
                <Button
                    onClick={() => requestAnimationFrame(() => store.viewMode = 'edit')}
                    active={store.effectiveViewMode == "edit"}
                    icon={'edit'}
                    disabled={!store.canEdit}
                >
                    Edita
                </Button>
                <Button
                    icon={'eye-open'}
                    onClick={() => requestAnimationFrame(() => store.viewMode = 'preview')}
                    active={store.isPreview}
                    disabled={!store.canPreview}
                >
                    Previsualitza
                </Button>
            </ButtonGroup>
        </RightContainer>
        {store.headerExpanded && <ICIInsCompanyBodySecondRow as={as}/>}
    </Container>
})
