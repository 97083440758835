import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {CFVStore} from "./CFVStore";
import {useC3Store} from "react-c4";
import {CFVMain} from "./CFVMain/CFVMain";
import {ExcelImportDialog} from "../../application/utils/excel-import/ExcelImportDialog";

const Container = styled.div`
    display: grid;
    
`


export const CFVContext = React.createContext(null as CFVStore)
export const useCFVStore = () => useContext(CFVContext)

export interface ContactFileViewProps {
    pageContext?
    onStoreReady: (s: CFVStore) => any
}

export const ContactFileView = observer((props: ContactFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new CFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null

    return <CFVContext.Provider value={store}>
        <CFVMain pageContext={props.pageContext}/>
        <ExcelImportDialog isOpen={store.excelDialogOpen} onClose={() => store.excelDialogOpen = false}
                           processResult={async (name, json) => {
                               await store.processExcelImport(name, json)
                           }}
                           formatString={`Id | Subcompte | Descripció | NIF | Domicili | Població | Provincia | C.P.`}
        />
    </CFVContext.Provider>
})
