import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useC3Store} from "react-c4";
import {ADVStore} from "./ADVStore";
import {ADVMain} from "./ADVMain/ADVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const Context = React.createContext(null as ADVStore)
export const useADVStore = () => useContext(Context)

export interface AllDeltasViewProps {
    pageContext?
    onStoreReady: (s: ADVStore) => any
}

export const AllDeltasView = observer((props: AllDeltasViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new ADVStore()
        storeInst.init();
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <Context.Provider value={store}>
        <Container>
            <ADVMain pageContext={props.pageContext}/>
        </Container>
    </Context.Provider>
})
