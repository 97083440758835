import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MRF, InputControl, DateInputControl, SelectControl, C3Form, Form, Field} from "react-c4";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {computed} from "mobx";
import {languages} from "../../../../../application/utils/languages";
import {sexOptions} from "../../../../../application/utils/sexOptions";

const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    align-content: start;
    grid-gap: 8px;
    grid-row-gap: 6px;
    //width: 250px;
`

export interface PatientInfoBasicInfoProps {
    store?: PatientOverviewStore
    form?
    // view: 'technician' | 'reception'
}

export const PatientInfoBasicInfo = observer((props: PatientInfoBasicInfoProps) => {


    // let patientMutua = patientForm.$('mutua').value;
    // let patientPaymentMethods = props.store.patientPaymentMethods;
    // let paymentMethodOptions = (patientPaymentMethods as any)?.options
    //     [
    //     {id: 'private', name: 'PRIVADA'},
    //     {id: 'socialSecurity', name: 'Seguretat Social'},
    //     {id: 'insCompany', name: 'Mutua'}
    // ]
    let form = props.form || props.store?.form;
    let hasMobile = form.$('mobile').value?.length > 1 || form.$('phone2').value?.length > 1
    return <Form value={form}>
        <BodyContainer>
            <Field name={'historyNumber'} c={InputControl} props={{disabled: true}}/>
            <Field name={'nif'} c={InputControl}/>
            <Field name={'cip'} c={InputControl}/>


            {<React.Fragment>
                <Field name={'name'} c={InputControl} props={{intent: form.$('name').error ? 'warning' : null}}/>
                <Field name={'surname1'} c={InputControl} props={{intent: form.$('surname1').error ? 'warning' : null}}/>
                <Field name={'surname2'} c={InputControl}/>

                <Field name={'mobile'} c={InputControl} props={{intent: !hasMobile ? 'warning' : null}}/>
                <Field name={'phone2'} c={InputControl} props={{intent: !hasMobile ? 'warning' : null}}/>
                <Field name={'email'} c={InputControl}/>

                <Field name={'address'} c={InputControl}/>
                <Field name={'postalCode'} c={InputControl}/>
                <Field name={'city'} c={InputControl}/>
            </React.Fragment>}


            <Field name={'birthDate'} c={DateInputControl} props={{intent: !form.$('birthDate').value ? 'warning' : null}}/>
            <Field name={'language'} c={SelectControl} options={languages}/>

            {/*<Field name={'defaultPaymentMethod'} c={SelectControl} options={paymentMethodOptions}/>*/}
            <Field name={'sex'} c={SelectControl} options={sexOptions} props={{intent: !form.$('sex').value ? 'warning' : null}}/>
            {/*<Field name={'insCompanies'} c={SelectControl} options={props.store.insCompanies}/>*/}

            {/*<Field name={'mutua'} c={SelectControl}/>*/}
            {/*<Field name={'mutuaNumber'} c={InputControl}/>*/}


            {/*{patientForm.$('mutua').value?.label == 'Seguretat Social' ?*/}
            {/*    <InputControl field={patientForm.$('mutuaNumber')} locked={true} value={patientForm.$('cip').value}/> :*/}
            {/*    <InputControl field={patientForm.$('mutuaNumber')} locked={locked}/>}*/}
            {/*<div/>*/}


            {/*<SelectControl field={patientForm.$('referral')} locked={locked} optionsData={props.store.referralsData}*/}
            {/*               dataKey={'referrals'}/>*/}
            {/*<SelectControl field={patientForm.$('referralCenter')} locked={locked}*/}
            {/*               optionsData={props.store.externalCentersData} dataKey={'externalCenters'}/>*/}
            {/*<div/>*/}

        </BodyContainer>
    </Form>
})
