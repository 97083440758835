import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useICIStore} from "../InsCompanyInvoicingView";
import {ICIInsCompaniesGrid} from "./ICIInsCompaniesGrid";
import {ICIInsCompanyBody} from "./ICIInsCompanyBody/ICIInsCompanyBody";
import {ICIInvoicesGrid} from "./ICIInvoicesGrid";
import * as _ from 'lodash'

import Splitter from 'm-react-splitters';
import 'm-react-splitters/lib/splitters.css';
import {NonIdealState} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    //grid-template-columns: 1fr 1fr 3fr;
    grid-gap: 8px;
    height: 100%;
`

export interface ICIMainProps {
    pageContext
}

export const ICIMain = observer((props: ICIMainProps) => {
    let store = useICIStore();
    let gridCount = [!store.allInsCompanies, !store.pendingLinesOnly]
        .filter(v => v === true).length
    let gridTemplateColumns = `${_.range(gridCount).map(v => gridCount > 1 ? '1fr' : '1.2fr').join(' ')} 3fr`;

    let panel1W = "400px";
    let panel2W = "400px";
    if (store.assistantMode) {
        if (store.assistantStep >= 1) {
            panel1W = '0px';
            panel2W = '0px';
        }
    }
    return <Splitter
        position="vertical"
        primaryPaneMaxWidth="80%"
        primaryPaneMinWidth={0}
        primaryPaneWidth={panel1W}
        postPoned={false}
    >
        <ICIInsCompaniesGrid/>
        <Splitter
            position="vertical"
            primaryPaneMaxWidth="80%"
            primaryPaneMinWidth={0}
            primaryPaneWidth={panel2W}
            postPoned={false}
        >
            <ICIInvoicesGrid/>
            <ICIInsCompanyBody/>
        </Splitter>
    </Splitter>
    return <Container style={{gridTemplateColumns: gridTemplateColumns}}>
        {!store.allInsCompanies && <ICIInsCompaniesGrid/>}
        {!store.pendingLinesOnly && <ICIInvoicesGrid/>}
        <ICIInsCompanyBody/>
    </Container>
})
