/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, {useEffect, useRef} from "react";
import styled from 'styled-components'
import {observer} from "mobx-react";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import {formatFormalName, formatFullName, logoMap, logoName} from "../application/utils/utils";
import {visitLineSchema} from "../application/schema/entities/packages/visits/visit-line.schema";
//import {C3Form} from "react-c4";
//import {Tag} from "@blueprintjs";
import * as _ from 'lodash'


const img = require('./img/' + logoName);


// const Page = styled.div`
//   margin-right: 10px;
//   @page {
//     size: A4;
//     margin: 0;
//   }
//   @media print {
//     width: 210mm;
//     height: 297mm;
//     page-break-inside: avoid
//   }
//   width: 210mm;
//   height: 297mm;
//   padding: 5mm;
// `;

const Page = styled.div`
  margin-right: 10px;
  @page {
    size: A4;
    margin: 0;
  }

  padding-bottom: 0cm;
  padding-top: 0cm;
`;

const Container = styled.div`
  padding: 0.4cm;
  padding-top: 0.65cm;
  padding-right: 0.45cm;
  display: grid;
  height: 100%;
  font-size: 12px;
  grid-template-columns: 100%;
  grid-template-rows: 120px 345px 600px;
  grid-template-areas: "TopColumnsContainer " 
  "Info "
  "SecondBlock"
`
const TopColumnsContainer = styled.div`
  height: 100%;

  display: grid;
  grid-area: TopColumnsContainer;
  grid-template-rows: 30px 30px 30px 30px;
  grid-template-columns: 250px 170px 1fr 100px 1fr 152px;
  grid-template-areas: "R1C1 R1C2 R1C3 R1C4 R1C5 R1C6" 
    "R2C1 . . R2C4 . R1C6"
    ". . . R3C4 . R1C6"
    "R4C1 R4C2 . . . R1C6";
`

const R1C1 = styled.div`
  grid-area: R1C1
`
const R1C2 = styled.div`
  grid-area: R1C2;
  padding-top: 15px;
`
const R1C3 = styled.div`
  grid-area: R1C3
`
const R1C4 = styled.div`
  grid-area: R1C4
`
const R1C5 = styled.div`
  grid-area: R1C5
`
const R1C6 = styled.div`
  grid-area: R1C6;
  height: 120px;
  border: solid 1px black;
`

const R2C4 = styled.div`
  grid-area: R2C4
`
const R2C1 = styled.div`
  grid-area: R2C1
`
const R3C4 = styled.div`
  grid-area: R3C4
`
const R4C1 = styled.div`
  grid-area: R4C1
`

const R4C2 = styled.div`
  grid-area: R4C2
`

const Info = styled.div`
  //height: 150px;
  grid-area: Info;
  display: grid;
  grid-template-rows: auto auto;
  //align-self: center;
  //justify-self: center;
  grid-auto-flow: row;
  //grid-gap: 12px;

`

const Mutua = styled.div`
  grid-area: Mutua;
  margin-top: 3px;
`

const TestDate = styled.div`
  grid-area: TestDate
`

const Text = styled.div`
  grid-area: text;
  padding: 4px;
  text-align: justify;
  border: solid 1px black;
`


const SecondBlock = styled.div`
  grid-area: SecondBlock;
  border-top: solid 1px black;
  display: grid;
  grid-template-rows: 90px 60px auto auto auto auto;
  grid-template-areas: "Header"
  "PatientData"
  "DeliveryDate"
  "DeliveryConditions"
  "Signatures"
  "Footer"
`
/*   "PickUPDate"
 "SignatureBlock"  */
const Header = styled.div`
  grid-area: Header;
  display: grid;
  grid-template-rows: 30px 30px;
  grid-template-columns: 210px 555px;
  grid-template-areas: "Logo HQ"
  "Logo Address";
  font-size: 16px;

`

const Logo = styled.div`
  grid-area: Logo;

`

const HQ = styled.div`
  grid-area: HQ;
  margin-top: 20px;
`

const Address = styled.div`
  grid-area: Address;

  margin-top: 10px;
`

const PatientData = styled.div`
  grid-area: PatientData;
  border: solid 1px black;
  padding-top: 5px;
  padding-left: 10px;
  display: grid;
  grid-template-rows: 30px 30px;
  grid-template-columns: 100px 250px 250px 156px;
  grid-template-areas: " PatientName PatientName PatientEpisode PatientVisit "
" PatientText ProfessionalName . . ";

`

const PatientName = styled.div`
  grid-area: PatientName;

`

const PatientEpisode = styled.div`
  grid-area: PatientEpisode;
`

const PatientVisit = styled.div`
  grid-area: PatientVisit;
`

const PatientText = styled.div`
  width: 300px;
  font-size: 8px;
  //font-weight: bold;

`

const ProfessionalName = styled.div`
  grid-area: ProfessionalName;
  margin-top: 5px;
`

const DeliveryDate = styled.div`
  border: solid 1px black;
  padding-top: 5px;
  padding-left: 10px;
  grid-area: DeliveryDate;
  display: grid;
  grid-template-rows: 30px 30px 30px;
  grid-template-columns: 250px 120px 250px 156px;
  grid-template-areas: " DeliveryText DeliveryText DeliveryText DeliveryText "
  " . DeliveryRealDate DeliveryAnotation . "
  " DeliveryPickup DeliveryPickup . ."
`;

const DeliveryText = styled.div`
  grid-area: DeliveryText;
  text-align: center;
  font-weight: bold;
`;

const DeliveryRealDate = styled.div`
  grid-area: DeliveryRealDate;
  border: solid 1px black;
  padding-top: 5px;
  padding-left: 15px;
  font-size: 16px;
`;

const DeliveryAnotation = styled.div`
  grid-area: DeliveryAnotation;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
`;

const DeliveryPickup = styled.div`
  grid-area: DeliveryPickup;
`;

const DeliveryConditions = styled.div`
  grid-area: DeliveryConditions;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  border: solid 1px black;
  padding-top: 5px;

`;

const Signatures = styled.div`
  grid-area: Signatures;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas: " SignatureLeft SignatureRight "
`

const SignatureLeft = styled.div`
  border: solid 1px black;
  padding-left: 15px;
  grid-area: SignatureLeft;
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-areas: " ScanDeliverText "
  " ScanDeliverSignature "
`;

const ScanDeliverText = styled.div`
  grid-area: ScanDeliverText;
`;
const ScanDeliverSignature = styled.div`
  grid-area: ScanDeliverSignature;
  text-align: center;
`;

const SignatureRight = styled.div`
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  padding-left: 15px;
  grid-area: SignatureRight;
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  grid-template-areas: " AuthorizationText "
  " AuthorizationSignature "
  " AuthorizedName "
  " AuthorizedNIF "
  " AuthorizedSignature "
`;


const AuthorizationText = styled.div`
  grid-area: AuthorizationText;
`;
const AuthorizationSignature = styled.div`
  grid-area: AuthorizationSignature;
  text-align: center;
  margin-top: 15px;
`;
const AuthorizedName = styled.div`
  grid-area: AuthorizedName;
`;
const AuthorizedNIF = styled.div`
  grid-area: AuthorizedNIF;
`;
const AuthorizedSignature = styled.div`
  grid-area: AuthorizedSignature;
  text-align: center;
  margin-top: 15px;
`;


const Footer = styled.div`
  grid-area: Footer;
  font-size: 8px;
  padding-top: 5px;
  padding-left: 15px;
  border-right: solid 1px black;
  border-left: solid 1px black;
  border-bottom: solid 1px black;

`

const AttributeContainer = styled.div`
  margin-top: 4px;
`
const PatientNameContainer = styled.div`
  display: grid;
  font-size: 19px;
`
const LargeContainer = styled.div`
  height: 75px;
  margin-top: 4px;

`

const Center = styled.div`
  text-align: center;
`

const RightAll = styled.div`
  display: block;
  text-align: right;
`
const BorderSolid = styled.div`

  text-align: justify;
  height: 20px;
  padding: 3px;

  border: solid 1px black;
`
const StrongContainer = styled.strong`
  font-size: 14px;
`
const ReferrerContainer = styled.div`
  font-size: 16px;
`

interface FitxaBlancaProps {
    onPrintReady?: (handle) => null
    data?
}


export const FitxaBlanca = observer((props: FitxaBlancaProps) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (props.onPrintReady) props.onPrintReady(handlePrint)
    }, [])
    // let data = {
    //     episode: "71934",
    //     machineName: "GENERAL ELECTRIC RM",
    //     name: "JOSE LUIS",
    //     surname1: "GONZALEZ",
    //     surname2: "ALMODOVAR",
    //     age: "53",
    //     birthDate: "02/01/1967",
    //     reportDate: "10/03/2016",
    //     mobile: "617 70 06 53",
    //     informantName: "ALICIA",
    //     informantSurname1: "GARGANTILLA",
    //     informantSurname2: "VAZQUEZ",
    //     informantCenter: "IC",
    //     test: "RM RODILLA",
    //     mutuaName: "HOSPITAL C. DEL ALT PENEDES",
    //     testDate: "10/03/2016",
    //     testHour: "15:00:00",
    //     headquarters: "DIMAT DIAGNOSIS PER LA IMATGE 2017 SL",
    //     address: "Carrer Pintor Domenech Farrer 19-33 local 10",
    //     postalCode: "08320",
    //     city: "El Masnou",
    //     phone: "937378258",
    //     deliveryDate: moment().format('DD/MM/YYYY'),
    // };
    let data = props.data

    let visit = props.data.visit
    let {turn} = props.data
    let orgDirection = `${props.data.org.selectedItem.address}, ${props.data.org.selectedItem.postalCode} ${props.data.org.selectedItem.city}`
    let orgTel = props.data.org.selectedItem.mobile
    let getAge = (birthDate) => {
        return Math.floor(moment().diff(moment(birthDate), 'years'))

        // let age
        // let today = new Date;
        // let a = moment([moment(birthDate).format('YYYY'), moment(birthDate).format('MM'), moment(birthDate).format('DD')]);
        // let b = moment([moment(today).format('YYYY'), moment(today).format('MM'), moment(today).format('DD')])
        // age = moment.duration(b.diff(a)).as('years');
        // return Math.floor(age)
    }
    if (visit == null) return <div>No s'ha trobat la visita</div>
    console.log(`visit`, visit);
    let usingVisitLines = props.data.visitLines.filter(vl => vl.product?.name != null);
    console.log(`visitLines`, usingVisitLines);
    let vlNumbers = usingVisitLines.map(vl => vl.visitLineNumber).join(', ');
    // return <div/>
    let deliveryDate = usingVisitLines.map(vl => vl.deliveryDate).find(a => a) || visit?.deliveryDate;
    let pickMedicalReferrerMap = usingVisitLines.map((vl) => {
        if (!!vl.refMedical == true)
            return vl.refMedical
    })
    let pickMedicalReferrer = pickMedicalReferrerMap.find((vl) => {
        return !!vl?.id == true
    })
    let medicalReferrerNameFormatted = pickMedicalReferrer ? formatFormalName(pickMedicalReferrer) : '';


    let pickCenterReferrerMap = usingVisitLines.map((vl) => {
        if (!!vl.refExtCenter == true)
            return vl.refExtCenter
    })
    let pickCenterReferrer = pickCenterReferrerMap.find((vl) => {
        return !!vl?.id == true
    })
    debugger
    let centerReferrerNameFormatted = pickCenterReferrer ? formatFormalName(pickCenterReferrer) : '';
    let visitDate = moment(visit.appointmentDate).format('DD/MM/YYYY')
    let id = props.data.org.selectedId;
    let orgImg = logoMap[id]
    console.log(`orgImg`, orgImg);
    return <Page ref={componentRef}>
        <Container>
            <TopColumnsContainer>
                <R1C1>
                    <AttributeContainer>
                        Visita: {visit.visitNumberText}
                    </AttributeContainer>
                    <div>
                        ID(s) Telera: <span style={{fontWeight: "bold"}}>
                    {usingVisitLines[0]?.isOld ? visit.visitNumber : vlNumbers}
                </span>
                    </div>
                </R1C1>
                <R1C2>
                    <BorderSolid>
                        Màquina: {visit?.resource?.name}
                    </BorderSolid>
                </R1C2>
                <R1C3>
                    <BorderSolid>
                        {//vacio
                            " "
                        }
                    </BorderSolid>
                </R1C3>
                <R1C4>
                    <BorderSolid>
                        {//vacio largo
                            " "
                        }
                    </BorderSolid>
                </R1C4>
                <R1C5>
                    <BorderSolid>
                        {//vacio
                            " "
                        }
                    </BorderSolid>
                </R1C5>
                <R1C6>

                    <AttributeContainer>
                        <Center>Visita</Center>
                    </AttributeContainer>
                    <AttributeContainer>
                        <RightAll>
                            <StrongContainer>{
                                visitDate
                            }</StrongContainer>
                        </RightAll>
                        <RightAll>
                            {moment(visit.appointmentDate).format('HH:mm')} -
                            {moment(visit.appointmentDate).add(visit.duration, 'minutes').format('HH:mm')}
                        </RightAll>
                    </AttributeContainer>
                    <AttributeContainer>
                        <RightAll>
                            {visit.visitNumberText}
                        </RightAll>
                    </AttributeContainer>
                    <AttributeContainer>
                        <RightAll>
                            <StrongContainer>Torn: {turn ? turn : `--`}</StrongContainer>
                        </RightAll>
                    </AttributeContainer>

                </R1C6>
                <R2C1>
                    <AttributeContainer>
                        <PatientNameContainer>
                            {formatFormalName(props.data.patient)}
                        </PatientNameContainer>
                        <ReferrerContainer>(R: {medicalReferrerNameFormatted} {!!centerReferrerNameFormatted && !!medicalReferrerNameFormatted && ' - '} {centerReferrerNameFormatted})</ReferrerContainer>
                    </AttributeContainer>
                </R2C1>
                <R2C4>
                    <AttributeContainer>
                        Data naixement: {
                        moment(data.patient?.birthDate).format('DD/MM/YYYY')
                    } ({getAge(data.patient?.birthDate)})
                    </AttributeContainer>
                </R2C4>
                <R3C4>
                    <AttributeContainer>{data.patient?.mobile} {data.patient?.phone2} </AttributeContainer>
                    <AttributeContainer> {data.patient?.nif} </AttributeContainer>
                </R3C4>
                <R4C1>
                    <AttributeContainer>
                        {/*{data.informantSurname1 + " " + data.informantSurname2 + ", " + data.informantName + "  " + data.informantCenter //Informante*/}
                        {/*}*/}
                    </AttributeContainer>
                </R4C1>
                <R4C2>
                    {/*<AttributeContainer>{data.test}</AttributeContainer>*/}
                </R4C2>
            </TopColumnsContainer>

            {/*<Text><b>Aviso:</b>La presente información contiene datos de carácter personal. De acuerdo con el Reglamento*/}
            {/*    (UE) 2016/679 (RGPD), la presente documentación en donde se registran datos de carácter personal*/}
            {/*    sometidos a*/}
            {/*    medidas de seguridad de nivel alto no deberá ponerse al alcance de personas que no tengan la*/}
            {/*    correspondiente*/}
            {/*    autorización para su tratamiento.</Text>*/}
            <Info>

                {visit.referrerComments && visit.referrerComments !== '' &&
                <div style={{
                    display: "grid",
                    gridTemplateColumns: '90px 1fr',
                    gridAutoFlow: 'row',
                    justifyItems: 'start',
                    gridGap: 12,
                    alignContent: "start",
                    justifyContent: 'start'
                }}>
                    <div>Comentari fitxa:</div>
                    <div>{visit.referrerComments}</div>
                    {/*<div>{visit.administrativeComments ? visit.administrativeComments.length > 1000 ? visit.administrativeComments.substr(0, 1000) : visit.administrativeComments : null}</div>*/}
                </div>
                }

                {usingVisitLines.map(vl => {
                    return <div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: 'repeat(5, auto) 1fr',
                            gridAutoFlow: 'row',
                            justifyItems: 'start',
                            gridGap: 12,
                            alignContent: "start",
                            justifyContent: 'start'
                        }}>
                            <div style={{display: "contents"}}>
                                <div>{visit.visitNumberText}<span
                                    style={{color: 'black', fontWeight: 'bold'}}>{_.padStart(vl.index, 2, '0')}</span>
                                </div>
                                <div>{vl.product?.name}</div>
                                <div>{vl.payment?.active?.paymentMethod?.name}</div>
                                <div>{vl.duration}'</div>
                                <div/>
                            </div>
                        </div>
                        <div style={{color: 'gray'}}> - {
                            vl.refMedical && `(PROTOCOL: ${vl.refMedical.protocol ? vl.refMedical.protocol : 'No hi ha protocol establert'})`
                        } </div>
                    </div>
                })}

            </Info>

            <SecondBlock>
                <Header>
                    <Logo><img src={orgImg || img} alt="logo" width="200px"/></Logo>
                    <HQ>{data.headquarters}</HQ>
                    {<Address>{orgDirection + " Tel. " + orgTel}</Address>}
                </Header>
                <PatientData>
                    <PatientName>{formatFormalName(props.data.patient)}
                        {usingVisitLines.map((vl) => {
                            let paymentMethod = vl.payment?.active?.paymentMethod
                            return <div>{paymentMethod?.isInsCompany == true ? paymentMethod?.name : ''}</div>
                        })
                        }</PatientName>
                    <PatientEpisode>{data.episode}</PatientEpisode>
                    <PatientVisit>{data.reportDate}</PatientVisit>
                    <PatientText>
                        <div>{`Exploración solicitada por: ${medicalReferrerNameFormatted} ${(!!centerReferrerNameFormatted == true && !!medicalReferrerNameFormatted  == true)? ' - ': ''} ${centerReferrerNameFormatted}`}</div>
                        <div>{`Exploració sol·licitada per: ${medicalReferrerNameFormatted} ${(!!centerReferrerNameFormatted == true && !!medicalReferrerNameFormatted == true) ? ' - ' : ''} ${centerReferrerNameFormatted}`}</div>
                    </PatientText>
                    <ProfessionalName>
                        {/*{data.informantSurname1 + " " + data.informantSurname2 + ", " + data.informantName + "  " + data.informantCenter}*/}
                    </ProfessionalName>
                    <AttributeContainer>(Visita: {visit.visitNumberText} realitzada el {visitDate})</AttributeContainer>
                </PatientData>
                <DeliveryDate>
                    <DeliveryText>
                        <div>{"El resultado de la prueba realizada puede recogerlo a partir del día:"}</div>
                        <div>{"El resultat de la prova realitzada pot recollir-lo a partir del dia:"}</div>
                    </DeliveryText>
                    <DeliveryRealDate>
                        {moment(deliveryDate).format('DD/MM/YYYY')}
                    </DeliveryRealDate>
                    <DeliveryAnotation>{"* A partir de las 12:00h"}</DeliveryAnotation>
                    <DeliveryPickup>
                        <div>{"Horario: De lunes a viernes de 7h a 21 h."}</div>
                        <div>{"Horari: De dilluns a divendres de 7h a 21 h."}</div>
                    </DeliveryPickup>
                </DeliveryDate>
                <DeliveryConditions>
                    <p>
                        <div>{"Es necesario presentar un Documento Oficial de Identificación y firmar,"}</div>
                        <div>{"para recoger los resultados (por ejemplo el D.N.I.)"}</div>
                    </p>
                    <p>
                        <div>{"És necessari presentar un Document Oficial d'Identificació i signar,"}</div>
                        <div>{"per recollir els resultats (per exemple el D.N.I.)"}</div>
                    </p>
                </DeliveryConditions>
                <Signatures>
                    <SignatureLeft>
                        <ScanDeliverText>
                            <p>
                                <div>{"Exploración entregada a:"}</div>
                                <div>{"Exploració lliurada a:"}</div>
                            </p>
                        </ScanDeliverText>
                        <ScanDeliverSignature>
                            <p>
                                <div>{"Firma del titular"}</div>
                                <div>{"Signatura del titular"}</div>
                            </p>
                        </ScanDeliverSignature>
                    </SignatureLeft>
                    <SignatureRight>
                        <AuthorizationText>
                            <p>
                                {/*<div>{"Autorizo la entrega documental, via fax ó internet, al médico ó persona autorizada:"}</div>*/}
                                <div>{"Autoritzo el lliurament documental, via fax o internet, al metge sol·licitant o persona autoritzada:"}</div>
                            </p>
                        </AuthorizationText>
                        <AuthorizedName>
                            <p>
                                <div>{"Nombre y apellidos de la persona autorizada:"}</div>
                                <div>{"Nom i cognoms de la persona autoritzada:"}</div>
                            </p>
                        </AuthorizedName>
                        <AuthorizedNIF>
                            <p>
                                <div>{"D.N.I. NÚM: ................................."}</div>
                            </p>
                        </AuthorizedNIF>
                        <AuthorizedSignature>
                            <p>
                                <div>{"Firma del titular"}</div>
                                <div>{"Signatura del titular"}</div>
                            </p>
                        </AuthorizedSignature>
                    </SignatureRight>
                </Signatures>
                <Footer>
                    <div>
                        {"D'acord amb el Reglament(UE) 2016/679 (RGPD), l'informem que IRM Dra. Guirado , és el responsable del tractament de les seves dades personals que puguin constar en aquest missatge, les quals seran tractades amb la finalitat de poder gestionar la relació que ens vincula. Vostè podrà exercir els seus drets d'accés, rectificació, supressió, limitació, oposició i portabilitat, a través del correu: dpo@institutsguirado.com. Per a més informació sobre la nostra Política de Protecció de dades pot accedir a la nostre WEB: http://www.institutsguirado.com. Aquest missatge inclou informació reservada i confidencial destinada exclusivament al seu destinatari. Si vostè no és el destinatari no està autoritzat a copiar, reproduir o distribuir aquest missatge ni el seu contingut. Si ha rebut aquest missatge per error, li preguem que ens ho notifiqui immediatament. De acuerdo con el Reglamento(UE) 2016/679 (RGPD), le informamos que IRM Dra. Guirado, es el responsable del tratamiento de sus datos personales que puedan constar en este mensaje, los cuales serán tratados con la finalidad de poder gestionar la relación que nos vincula. Usted podrá ejercer sus derechos de acceso, rectificación, supresión, limitación, oposición y portabilidad, a través del correo electrónico dpo@institutsguirado.com. Para más información sobre nuestra Política de Protección de Datos puede consultar la WEB: http://www.institutsguirado.com. Este mensaje contiene información reservada y confidencial destinada exclusivamente al destinatario. Si usted no es el destinatario no está autorizador a copiar, reproducir o distribuir este mensaje ni su contenido. Si ha recibido este mensaje por error, le rogamos que nos lo notifique inmediatamente."}
                    </div>
                </Footer>
            </SecondBlock>
        </Container>
    </Page>

})
