import {QueryCache} from "react-query";
import {autorun, computed, observable, toJS} from "mobx";
import {AuthStore} from "./auth/AuthStore";
import {C3, C3Selection, IATSStyleApp} from "react-c4";
import {EventsStore} from "./events/EventsStore";
import * as _ from 'lodash'
import {isDev, nextAnimationFrame} from "./utils/utils";

export class AppStore {
    qc: QueryCache

    auth: AuthStore = new AuthStore(this)
    events: EventsStore = new EventsStore(this)

    @observable
    var1 = 3

    @observable.shallow
    resources

    @observable.shallow
    orgs: any[]

    @observable.shallow
    productSubfamily: any[]

    @observable.shallow
    businessLine: any[]

    @observable.shallow
    deliveryItemType: any[]

    @observable.shallow
    specialty: any[]

    @observable.shallow
    features: any[]

    @observable
    bounds

    @observable
    network

    @observable
    networkStatus = {
        online: true,
        serverConnected: false,
        realtimeConnected: true
    }

    orgsSel: C3Selection
    resourceSel: C3Selection
    resourceGroupSel: C3Selection
    resourceOrgGroupSel: C3Selection

    @observable
    resourceGroups = null


    @observable.shallow
    contrastAlertsRes = []

    isWRDView = false

    navContext

    async init() {
        this.orgsSel = new C3Selection({
            items: []
        })
        this.resourceSel = new C3Selection({
            items: [],
            onSelectionChanged: async () => {
                await this.saveResourceGroups({
                    selectedResources: this.resourceSel.selectedIds
                })
            }
        })
        this.resourceGroupSel = new C3Selection({
            items: [],
            onSelectionChanged: async () => {
                await this.saveResourceGroups({
                    expandedGroups: this.resourceGroupSel.selectedIds
                })
            }
        })
        this.resourceOrgGroupSel = new C3Selection({
            items: [],
            onSelectionChanged: async () => {
                await this.saveResourceGroups({
                    expandedOrgGroups: this.resourceOrgGroupSel.selectedIds
                })
            }
        })
        this.qc = new QueryCache({})
        await this.refetchResources();
        await this.refetchFeatures();
        // let results = await Promise.all([this.refetchOrgs(),
        //     this.refetchProductSubfamily(),
        //     this.refechBusinessLine(),
        //     this.refechDeliveryItemType(),
        //     this.refetchSpecialty(),
        //     this.fetchContrastAlerts(),
        //     this.fetchBounds()])
        window['appStore'] = this
        await this.events.init()
        await this.fetchResourceGroups()
        autorun(() => {
            console.log(`this.network changed`, toJS(this.network));
        })


        autorun(() => {
            if (!this.resourceGroups) return null
            this.resourceOrgGroupSel.itemsRef.current = this.resourceGroups.orgGroups
            this.resourceGroupSel.itemsRef.current = this.resourceGroups.orgGroups.flatMap(og => og.groups)
        })
        autorun(() => {
            this.orgsSel.itemsRef.current = this.orgs
        })


    }

    async refetchResources() {
        let res = await C3.instance.client.fetcher.fetch('/resource', {})
        this.resources = res
        this.resourceSel.itemsRef.current = res
        return res
    }

    async refetchOrgs() {
        let orgs = await C3.instance.client.fetcher.fetch('/organization', {})
        this.orgs = orgs
        return orgs
    }


    async refetchProductSubfamily() {
        let productSubfamily = await C3.instance.client.fetcher.fetch('/product-subfamily', {})
        this.productSubfamily = productSubfamily
        return productSubfamily
    }

    async refechBusinessLine() {
        let businessLine = await C3.instance.client.fetcher.fetch('/business-line', {})
        this.businessLine = businessLine
        return businessLine
    }

    async refechDeliveryItemType() {
        let deliveryItemType = await C3.instance.client.fetcher.fetch('/delivery-item-type', {})
        this.deliveryItemType = deliveryItemType
        return deliveryItemType
    }


    async refetchSpecialty() {
        let specialty = await C3.instance.client.fetcher.fetch('/specialty', {})
        this.specialty = specialty
        return specialty
    }

    async refetchFeatures() {
        let features = await C3.instance.client.fetcher.fetch('/event-feature/all', {})
        this.features = features
        return features
    }


    async fetchResourceGroups() {
        // let res = await C3.instance.client.fetcher.fetch('/auth/me', {})
        let res: any = await this.auth.fetchMe()
        if (res == null) return
        this.resourceGroups = res.customResourceGroups;
        this.resourceSel.selectedIds = this.resourceGroups?.selectedResources
        this.resourceGroupSel.selectedIds = this.resourceGroups?.expandedGroups
        this.resourceOrgGroupSel.selectedIds = this.resourceGroups?.expandedOrgGroups
    }

    async fetchContrastAlerts() {
        let res = await C3.instance.client.fetcher.fetch('/visit/alerts/contrast', {})
        this.contrastAlertsRes = res
    }

    async rescheduleContrastAlerts() {
        IATSStyleApp.instance.toaster.show({intent: "warning", icon: 'notifications', message: 'Avís de contrast'})
    }

    async saveResourceGroups(resourceGroupsBody) {
        await C3.instance.client.fetcher.fetch('/auth/me/update-res-groups', {
            method: 'POST',
            body: {customResourceGroups: resourceGroupsBody},
        })
        await this.fetchResourceGroups()
    }

    @computed
    get orderedResourceIds(): any {
        if (this.resourceGroups == null) return []
        let selectedIds = this.resourceSel.selectedIds;
        let orderedIds = this.resourceGroups.orgGroups.flatMap(g => g.groups).flatMap(g => g.resources)
        let ids = _.intersection(selectedIds, orderedIds);
        return ids
        // return this.enabledResourcesSel.selectedItems
    }

    @computed
    get effectiveResources(): any {
        if (this.resourceGroups == null) return []
        let selectedIds = this.resourceSel.selectedIds;
        let orderedIds = this.resourceGroups.orgGroups.flatMap(g => g.groups).flatMap(g => g.resources)
        let ids = _.intersection(orderedIds, selectedIds);
        console.log(`ids`, ids);
        return ids.map((id, index) => {
            return {
                ..._.find(this.resourceSel.items, it => it.id == id),
                order: index
            };
        })
        // return this.enabledResourcesSel.selectedItems
    }

    async fetchBounds() {
        this.bounds = await C3.instance.client.fetcher.fetch(`/schedule-view/calendar-bounds`, {
            method: 'GET',
        })
        console.log(`this.bounds`, this.bounds);
    }

    async saveVisit(visit) {
        let res = await C3.instance.client.fetcher.fetch('/svv' + `/visits/${visit.id}`, {
            method: 'PATCH',
            body: visit
        })
        return res
    }

    //
    // @computed
    // get resourceGroups(): any | { id, name, org, resources: number[] }[] {
    //     return     }

}
