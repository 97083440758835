import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const patientInsCompanySchema: C3SchemaInfo = {
    'patient-ins-company': {
        fields: {
            id: {},
            patient: {
                type: 'patient'
            },
            insCompany: {
                type: 'ins-company'
            },
            mutuaNumber: {},
            default: {},
        }
    }
}
