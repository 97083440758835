import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSVVStore} from "../../../../StandardVisitView";
import {stdCurrencyFormat} from "../../../../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-items: end;
    //border: 1px solid black;
    padding: 4px;
    justify-self: start;
    grid-column-gap: 8px;
`
const GrandTotalContainer = styled.div`
    font-size: 17px;
    font-weight: bold;
`
const TotalPaidContainer = styled.div`
    font-size: 15px;
    font-weight: bold;

`
const RemainingToPayContainer = styled.div`
    color: #C23030;
    font-size: 15px;
    font-weight: bold;

`


export interface SVVPIPPaymentTotalsProps {

}

export const SVVPIPPaymentTotals = observer((props: SVVPIPPaymentTotalsProps) => {
    let store = useSVVStore()
    if (!store.privateInvoiceRes) return null;
    let overpaid = store.privateInvoiceRes.remainingToPay < 0
    let exact = Math.round(store.privateInvoiceRes.remainingToPay) == 0
    let remainingLabel = overpaid ? 'Pagat de més' :  'Falta pagar:';
    return <Container>
        <GrandTotalContainer>Total a pagar:</GrandTotalContainer>
        <GrandTotalContainer>{stdCurrencyFormat(store.privateInvoiceRes.total)} €</GrandTotalContainer>
        <TotalPaidContainer>Total pagat:</TotalPaidContainer>
        <TotalPaidContainer>{stdCurrencyFormat(store.privateInvoiceRes.paidAmount)} €</TotalPaidContainer>
        <RemainingToPayContainer>{remainingLabel}</RemainingToPayContainer>
        <RemainingToPayContainer>{stdCurrencyFormat(store.privateInvoiceRes.remainingToPay)} €</RemainingToPayContainer>
    </Container>
})
