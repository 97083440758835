import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {CellValueChangedEvent, IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {useRFVStore} from "../../../ResourceFileView";
import {
    formatFullName,
    stdBoolColumn,
    stdIntNumericColumn,
    stdNumericColumn
} from "../../../../../application/utils/utils";
import {observer} from "mobx-react";
import * as _ from "lodash";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`
const GridTitle = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
`

export interface PREAllProductsGridProps {

}

export const REEAllProductsGrid = observer((props: PREAllProductsGridProps) => {
        let store = useRFVStore()
        let buttonText = ''
        return <Container>
            <GridTitle>(2) Seleccioni les proves que vulgui assignar a la màquina i premi el botó <Button disabled={true} icon={'double-chevron-left'} /> {buttonText}:</GridTitle>
            <MasterLayout
                renderHeader={() => null}
                actions={[
                    {
                        name: 'create',
                        iconName: 'document',
                        enabled: true,
                        callback: () => {
                            store.createProduct()
                        }
                    },
                    {
                        name: 'delete',
                        iconName: 'trash',
                        enabled: true,
                        confirmation: true,
                        confirmationText: 'Estàs segur que vols esborrar la prova?',
                        callback: () => {
                            store.deleteProduct()
                        }
                    },
                    {
                        name: 'showActives',
                        iconName: 'strikethrough',
                        intent: store.showInactiveProducts ? "success" : "none",
                        text: store.showInactiveProducts? 'Mostra només proves actives' : 'Mostra proves inactives',
                        callback: () => {
                            store.showInactiveProducts = !store.showInactiveProducts
                        }
                    }
                ]}
                body={
                    <C3AgGrid

                        columnDefs={[
                            {
                                field: '',
                                headerName: '',
                                width: 30,
                                checkboxSelection: true,
                                // suppressFilter: true,
                            },
                            {
                                field: 'code',
                                headerName: 'Codi',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                width: 60,
                            },
                            {
                                field: 'name',
                                headerName: 'Nom Prova',
                                editable: true,
                                width: 270,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },

                            {
                                field: 'defDeliveryHours',
                                headerName: 'T.Entrega (h)',
                                editable: true,
                                width: 60,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,

                            },
                            {
                                field: 'duration',
                                headerName: 'Duració per defecte',
                                editable: true,
                                width: 60,
                                headerTooltip: 'Duració',
                                floatingFilter: true,
                            },
                            {
                                field: 'description',
                                headerName: 'Descripició',
                                editable: true,
                                width: 150,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'preparation',
                                headerName: 'Preparació',
                                editable: true,
                                width: 150,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'isActive',
                                headerName: 'Activa',
                                editable: true,
                                ...stdBoolColumn(),
                                width: 40,
                                headerTooltip: 'Activa',
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'productSubfamily',
                                headerName: 'Familia',
                                editable: true,
                                cellEditor: 'agRichSelectCellEditor',
                                cellEditorParams: (params: IRichCellEditorParams) => {
                                    return {
                                        values: store.subFamilyRes,
                                        formatValue: (params) => {
                                            return params?.name
                                        },
                                    }
                                },
                                valueFormatter: (params: ValueFormatterParams) => {
                                    if (_.isEmpty(params.value)) return '-'
                                    return params?.value.name
                                },
                            },
                            {
                                field: 'businessLine',
                                headerName: 'L.Negoci',
                                editable: true,
                                cellEditor: 'agRichSelectCellEditor',
                                cellEditorParams: (params: IRichCellEditorParams) => {
                                    return {
                                        values: store.businessLineRes,
                                        formatValue: (params) => {
                                            return params?.name
                                        },
                                    }
                                },
                                valueFormatter: (params: ValueFormatterParams) => {
                                    if (_.isEmpty(params.value)) return '-'
                                    return params?.value.name
                                },
                            },
                            {
                                field: 'id',
                                headerName: 'Id',
                                editable: false,
                                width: 60,
                                ...stdIntNumericColumn(),
                            },

                        ]}
                        processCellCallback={(params) => {
                            let data = (params.node as RowNode).data
                            return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                        }}
                        getContextMenuItems={(params) => {
                            // let fileName = store.fr
                            var result = [
                                {
                                    // custom item
                                    name: 'Exporta a Excel',

                                    action: function () {
                                        let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
                                        params.api.exportDataAsExcel({
                                            fileName: `llistat-proves-disponibles-${orgName}`,
                                            processCellCallback: (params) => {
                                                let data = (params.node as RowNode).data
                                                if (params.column.getColId() == 'isActive') {
                                                    return data.isActive == true ? 'X' : '';
                                                }
                                                if (params.column.getColId() == 'businessLine'){
                                                    return !!data.businessLine == true ? data.businessLine.name : '';
                                                }
                                                return params.value
                                            }
                                        })
                                    },
                                }
                            ]
                            return result
                        }}
                        rowData={store.allProductsRes}
                        selection={store.allProductsSel}
                        cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                            await store.saveProduct({
                                id: e.data.id,
                                [e.colDef.field]: e.newValue
                            })
                        }}
                    />
                }
            />
        </Container>
    }
)
