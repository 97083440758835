import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useICIStore} from "../../InsCompanyInvoicingView";
import {formatFormalName, formatFullName, stdDateColumn, stdNumericColumn} from "../../../../application/utils/utils";
import {Button} from "@blueprintjs/core";
import {ICIInsCompanyBodyHeader} from "./ICIInsCompanyBodyHeader";
import {ICIInsCompanyBodyGrid} from "./ICIInsCompanyBodyGrid";
import {ICIInsCompanyBodyPreview} from "./ICIInsCompanyBodyPreview";
import {ICIInsCompanyBodyFooter} from "./ICIInsCompanyBodyFooter";

const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;
    grid-gap: 8px;
   
`

const FooterContainer = styled.div`
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-gap: 8px;
`
const ChooserContainer = styled.div`
    display: grid;
    grid-gap: 16px;
    place-content: center;
`

export interface ICIInsCompanyBodyProps {

}

export const ICIInsCompanyBody = observer((props: ICIInsCompanyBodyProps) => {
    let store = useICIStore();
    let body = store.effectiveViewMode == "edit" ? <ICIInsCompanyBodyGrid/> : <ICIInsCompanyBodyPreview/>;
    if(store.assistantMode && store.assistantStep == 0) body = <div/>
    if(store.assistantMode && store.assistantStep == 1) body = <ChooserContainer>
        <Button icon={'arrow-right'} intent={'primary'} onClick={() => store.typeSel.selectId('chipcard')}>Chipcard</Button>
        <Button icon={'arrow-right'} intent={'primary'} onClick={() => store.typeSel.selectId('manual')}>Manual</Button>
        <Button icon={'arrow-right'}>Per pacient</Button>
    </ChooserContainer>
    return <Container>
        <ICIInsCompanyBodyHeader/>
        {body}
        <ICIInsCompanyBodyFooter/>
    </Container>
})
