import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {AVVStore} from "../../components/AllVisitsView/AVVStore";
import {Button, ButtonGroup, Tooltip} from "@blueprintjs/core";
import {useSVVStore} from "../../components/StandardVisitView/StandardVisitView";
import {DateInputControl} from "react-c4";
import {useAVVStore} from "../../components/AllVisitsView/AllVisitsView";
import {MicroBarChart} from "../../components/AllVisitsView/comoponents/MicroBarChart";
import {isDev} from "../../application/utils/utils";

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  grid-template-columns: repeat(5, auto) 1fr auto;
`
const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  padding: 1px;
  width: 200px;
`
const SpanContainer = styled.span`
  align-self: center;
  font-weight: bold;
`

export interface AllVisitsPageHeaderProps {
    store: AVVStore
}

export const AllVisitsPageHeader = observer((props: AllVisitsPageHeaderProps) => {
    let store = props.store
    if (store == null) return null;
    let filterForm = store.filterForm
    return <Container
        onWheel={(e: WheelEvent) => {
            let deltaY = Math.round(Math.abs(e.deltaY) / e.deltaY)
            props.store.moveRange(deltaY)
        }}
    >
        {/*<ButtonGroup>*/}
        {/*    {store.orgsSel.items.map(it => {*/}
        {/*        let selected = store.orgsSel.isSelected((it.id));*/}
        {/*        return <Button*/}
        {/*            active={selected}*/}
        {/*            onClick={()=>store.orgsSel.selectId(it.id)}*/}
        {/*        >*/}
        {/*            {it.shortName}*/}
        {/*        </Button>*/}
        {/*    })}*/}
        {/*</ButtonGroup>*/}
        {false && <SpanContainer>
            Filtrar per data:
        </SpanContainer>}
        <Tooltip content={'Filtrar per data:'}>
            <ButtonGroup>
                {store.dateTypeSel.items.map(it => {
                    let selected = store.dateTypeSel.isSelected(it.id);
                    return <Button
                        // intent={'success'}
                        active={selected}
                        icon={selected ? 'tick' : null}
                        onClick={() => store.dateTypeSel.selectId(it.id)}
                    >
                        {it.name}
                    </Button>
                })}
            </ButtonGroup>
        </Tooltip>
        <DateContainer>
            <DateInputControl field={filterForm.$('startDate')} fill={true} placeholder={'Data document'}/>
            <DateInputControl field={filterForm.$('endDate')} fill={true} placeholder={'Data document'}/>
        </DateContainer>
        <ButtonGroup>
            <Button icon={'arrow-left'}
                    onClick={(e) => {
                        store.moveRange(e.shiftKey ? -7 : -1)
                    }}
            />
            <Button
                onClick={(e) => {
                    store.goToDate(new Date())
                }}
            >Avui</Button>
            <Button icon={'arrow-right'}
                    onClick={(e) => {
                        store.moveRange(e.shiftKey ? 7 : 1)
                    }}
            />
        </ButtonGroup>
        <ButtonGroup>
            {/*{store.typesOptions.map(to => {*/}
            {/*    let selected = store.filterForm.$('type').value == to.id;*/}
            {/*    return <Button*/}
            {/*        active={selected}*/}
            {/*        icon={selected ? 'tick' : undefined}*/}
            {/*        onClick={() => {*/}
            {/*            store.filterForm.$('type').onChange(to.id)*/}
            {/*        }}>{to.name}</Button>*/}
            {/*})}*/}
        </ButtonGroup>

        <Button icon={'print'} onClick={() => {
            store.print()
        }}>Imprimir</Button>

        <ButtonGroup>
            {store.typeSel.items.map(it => {
                let selected = store.typeSel.isSelected(it.id);
                return <Button
                    // intent={'success'}
                    active={selected}
                    icon={selected ? 'tick' : null}
                    onClick={() => store.typeSel.selectId(it.id)}
                >
                    {it.name}
                </Button>
            })}
        </ButtonGroup>
        {<Button active={store.filtersEnabled} icon={'filter'} onClick={() => {
            store.filtersEnabled = !store.filtersEnabled
        }}>Filtres</Button>}
        <Button icon={'refresh'} onClick={() => {
            store.fetch()
            store.fetchStats()
        }}/>
        <MicroBarChart sel={store.chartSel} store={store}/>
        <div style={{color: 'gray'}}>
            Botó dret sobre la llista per exportar a Excel
        </div>
    </Container>
})
