import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Button, InputGroup, Tab, Tabs, TextArea} from "@blueprintjs/core";
import {C3AgGrid, DateInputControl} from "react-c4/src/index";
import {ICellRendererParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import {formatFullName, stdDateColumn} from "../../../../application/utils/utils";
import {useSCVStore} from "../../ScheduleView";

const Container = styled.div`
    display: grid;
    
`
const GeneralPanelContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`
const MeetupEventDetailNameInputContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto auto;
`
const InputsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`
const DescriptionContainer = styled.div`
  display: grid;
  min-height: 400px;
`
const DateRangeContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: start;
  align-items: center;

`
const GridContainer = styled.div`
  display: grid;
  height: 300px;
`

export interface MeetupEventDetailTabsProps {

}

export const MeetupEventDetailTabs = observer((props: MeetupEventDetailTabsProps) => {
    let store = useSCVStore()

    let $meName = store.meetupEventForm.$('name');
    let $meDescription = store.meetupEventForm.$('description');
    let $meLocation = store.meetupEventForm.$('localized_location');
    let $meHowToFindUs = store.meetupEventForm.$('howToFindUs');
    let $start = store.meetupEventForm.$('start');
    let $end = store.meetupEventForm.$('end');

    return <Container>
        <Tabs id="TabsExample" onChange={(id) => store.tabsSel.selectId(id)} selectedTabId={store.tabsSel?.selectedId || 'general'}>
            <Tab id="general" title={`General`} panel={<GeneralPanelContainer>
                <div>{store.eventSel.selectedItem?.type} Id: {store.eventSel.selectedId}, MeetupId: {store.eventSel.selectedItem?.meetupId}, UrlName: {store.eventSel.selectedItem?.urlName}</div>
                <InputsContainer>
                    <MeetupEventDetailNameInputContainer>
                        <InputGroup large {...$meName.bind()}
                                    placeholder={'Títol'} leftIcon={'edit'}
                        />
                        <Button onClick={(e) => {
                            // store.pushMeetupEvent(store.meetupEventForm.$('id').value, e.shiftKey || false)
                            store.pageContext.navigate({to: 'meetupPushEventsPage', inNewTab: true, focusNewTab: true})
                        }} icon={'export'}/>
                        <Button onClick={() => {
                            console.log(`store.meetupEventsSelSelectedId.itemsRef.current`, store.meetupEventSel.itemsRef.current);
                            let meetupEventId = store.meetupEventsSelSelectedId
                            if (store.eventSel.selectedItem.type == 'meetup-event') meetupEventId = store.calendarEventsSelSelectedId
                            store.pullMeetupEvent(meetupEventId)
                        }} icon={'import'}/>
                    </MeetupEventDetailNameInputContainer>
                    <DescriptionContainer>
                        <TextArea large {...$meDescription.bind()}
                                  placeholder={'Descripció'}
                        />
                    </DescriptionContainer>
                    <InputGroup large {...$meLocation.bind()}
                                placeholder={'location'} leftIcon={'map-marker'}
                    />
                    <InputGroup large {...$meHowToFindUs.bind()}
                                placeholder={'How to find us'} leftIcon={'comment'}
                    />
                    <DateRangeContainer>
                        <DateInputControl
                            style={{border: '1px solid gray', padding: 4, borderRadius: 3}}
                            field={$start}
                            // as={as}
                            // locked={!store.isMainMode}
                        /> -
                        <DateInputControl
                            style={{border: '1px solid gray', padding: 4, borderRadius: 3}}
                            field={$end}
                            // as={as}
                            // locked={!store.isMainMode}
                        />
                    </DateRangeContainer>
                </InputsContainer>
                <div style={{display: 'grid', justifyItems: 'end'}}>
                    <Button loading={store.loading.pullMeetupEvent} onClick={() => store.saveMeetupEvent(store.meetupEventForm.values())} intent={'primary'}>Save</Button>
                </div>
            </GeneralPanelContainer>}/>
            <Tab id="attendees" title="Attendees" panel={<GridContainer>
                <C3AgGrid
                    selection={store.meetupEventAttendeesSel}
                    // rowData={store.eventAttendees}
                    // onGridReady={({api}) => store.gridApi = api}
                    defaultColDef={{
                        editable: false,
                        // filter: 'agTextColumnFilter'
                        filter: false,
                        floatingFilter: false,
                        resizable: true,
                        sortable: true,
                        suppressMenu: true,
                        width: 100
                    }}
                    columnDefs={[
                        {
                            field: 'thumb',
                            headerName: 'Photo',
                            width: 40,
                            cellRendererFramework: (params: ICellRendererParams) => {
                                if (params.value == null) return null

                                return <div style={{
                                    display: 'grid',
                                    justifyContent: 'left',
                                    width: '100%',
                                }}>
                                    <img style={{
                                        height: '28px',
                                    }} src={params.value}/>
                                </div>
                            },
                        },
                        {
                            field: 'name',
                            headerName: 'Meetup Event Name',
                            width: 120,
                        },
                        {
                            field: 'guests',
                            headerName: 'Attendee Count',
                            width: 110,
                        },
                        {
                            field: 'attending',
                            headerName: 'Going',
                            width: 110,
                            valueFormatter: (params: ValueFormatterParams) => {
                                return params.value ? 'Yes' : 'No'
                            }
                        },
                        {
                            field: 'meetupCreatedAt',
                            headerName: 'Created',
                            width: 110,
                            ...stdDateColumn()
                        },
                        {
                            field: 'meetupUpdatedAt',
                            headerName: 'Updated',
                            width: 110,
                            ...stdDateColumn()
                        },
                    ]}
                    onRowDoubleClicked={({data, event}) => {
                        setTimeout(() => {
                            if (data.patientId == null) return
                            if (store.pageContext) {
                                console.log('data.invoiceId', data.invoiceId)
                                store.pageContext.navigate({
                                    to: 'bookVisitPage', args: {
                                        patientId: data.patientId,
                                        selectedInvoiceIds: [data.invoiceId],
                                        //selectedVisitLineIds: [data.vId]
                                    },
                                    inNewTab: (event as any).ctrlKey, focusNewTab: true
                                })
                            }
                        })
                    }}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({
                            name: data.medName,
                            surname1: data.medSurname1,
                            surname2: data.medSurname1
                        })
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        let result = []
                        return result
                    }}
                />
            </GridContainer>}/>
            <Tab id="plots" title="Plots" panel={<div/>}/>
            <Tab id="competència" title="Competència" panel={<div/>}/>
        </Tabs>
    </Container>
})