import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button} from "@blueprintjs/core";
import {MRF, InputControl, DateInputControl, SelectControl, C3Form, C3} from "react-c4";
import {PatientOverviewStore} from "../../PatientOverviewStore";
import {PatientInfoBasicInfo} from "./components/PatientInfoBasicInfo";
import {PatientInfoAlerts} from "./components/PatientInfoAlerts";

const Container = styled.div`
    display: grid;
    grid-gap: 8px;
`

const FooterContainer = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
`
const FooterButtonsContainer = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-auto-flow: column;
`

export interface PatientInfoProps {
    store: PatientOverviewStore
    showAlerts: boolean
    pageContext
}

export const PatientInfo = observer((props: PatientInfoProps) => {
    let store = props.store;
    let patientForm = store.form.form
    let locked = store.form.isLocked
    let patientValid = store.patientValid
    let disableButtons = locked || !patientForm || !patientValid;
    return <Container>
        {!store.basicInfoCollapsed && <React.Fragment>
          <PatientInfoBasicInfo store={store}/>
          <FooterContainer>
            <span
              style={{color: 'red'}}>*: Camps obligatoris {store.externalStore?.isSelectedAppointmentToday && 'T'}</span>
            <FooterButtonsContainer>
                {/*<Button icon={'print'} onClick={patientForm.onSubmit}>*/}
                {/*  Imprimir etiqueta*/}
                {/*</Button>   */}
                {/*<Button onClick={patientForm.onSubmit} disabled={locked || !patientForm}>*/}
                {/*  Més informació*/}
                {/*</Button>*/}
                {<Button disabled={locked || !patientForm} onClick={(e) => {
                    if (store.form.isCreating)
                        store.form.cancelCreating()
                    else {
                        store.form.fetch()
                        store.form.isLocked = true
                    }
                }}>
                    Cancelar
                </Button>}
              <Button icon={'floppy-disk'}
                      intent={disableButtons ? 'none' : 'warning'}
                      disabled={disableButtons} onClick={(e) => {
                  store.form.save().then((res) => {
                      if (store.onSavePatient) store.onSavePatient(res)

                      return store.fetchPatientInsCompanies();
                  });

                  store.form.isLocked = true
              }}>
                Guardar pacient
              </Button>
                {!store.hasFeature('bookingShortcut') && <Button
                    icon={'add'}
                    intent={disableButtons ? 'none' : 'warning'}
                    disabled={disableButtons}
                    onClick={async (e) => {
                        let res = await store.form.save();
                        let id = res?.id || store.form.data?.id
                        console.log(`patient res`, res);
                        if (store.onSavePatient) store.onSavePatient(res)
                        await store.fetchPatientInsCompanies();
                        if (res?.id) {
                            props.pageContext.navigate({
                                to: 'schedule', args: {
                                    patientId: id,
                                    patientData: res.id ? res : store.md.form.data,
                                },
                                inNewTab: false, focusNewTab: true
                            })
                        }
                        // let onSubmit = patientForm.onSubmit(e);
                        // console.log(`onSubmit`, onSubmit);
                        store.form.isLocked = true
                    }}>
                    Guardar + crear cita
                </Button>}
            </FooterButtonsContainer>
          </FooterContainer>
        </React.Fragment>}
        {/*{props.showAlerts && <PatientInfoAlerts store={store}/>}*/}

    </Container>
})
