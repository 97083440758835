import {SVVStore} from "../../../SVVStore";
import {AgGridColumn, AgGridColumnProps} from "ag-grid-react";
import {
    CellValueChangedEvent, ICellEditorParams,
    ICellRendererParams,
    IRichCellEditorParams,
    ValueFormatterParams
} from "ag-grid-community";
import * as _ from 'lodash'
import numeral from 'numeral'
import {
    dec,
    formatFormalName,
    insCompanyPaymentFormMap,
    insCompanyPaymentMethodNamesMap, stdDateColumn
} from "../../../../../application/utils/utils";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import {observer} from "mobx-react";
import styled from 'styled-components'
import {Icon, Tooltip} from "@blueprintjs/core";
import {SVVLinesGridPaymentMethodCR} from "./components/SVVLinesGridPaymentMethodCR";
import {useUpdate} from "react-use";
import {autorun} from "mobx";
import NumericCellEditor from "../../../../../application/controls/NumericCellEditor";
import moment from "moment";
import {SelectCE} from "../../../../../application/utils/components/SelectCE";

const TestCRContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  //justify-content: start;
  grid-template-columns: minmax(0px, auto) 1fr auto;
  grid-gap: 2px;
  height: 100%;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export let getPaymentsCells = (store: SVVStore, rootField = 'payment.active.') => {
    let paymentField = (field) => `${rootField}${field}`
    let getValueFromGetter = (params, paymentFieldName) => {
        return _.get(params.data, paymentField(paymentFieldName))
    }
    (SVVLinesGridPaymentMethodCR as any).store = store;
    let canEditLockedInvoiceLines = dec().canEditLockedInvoiceLines();
    let columns = [
        {
            field: paymentField('paymentMethod'),
            headerName: 'M. Pagament',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params: IRichCellEditorParams) => {
                let visitId = params.data.visit.id;
                let visit = store.getLocalVisit(visitId);
                let resourceId = visit.resource?.id;
                let resourceProductMapElement = store.resourceProductMap[resourceId];
                let values = store.svvRes.patient.paymentMethods.options

                return {
                    values: values,
                    formatValue: (params) => {
                        // Add default marker
                        // console.log(`params, params?.name`, params, params?.name);
                        if (params.id == null) return '';
                        if (params.isInsCompany) return params.shortName;
                        return params.name
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value.id == null) return ''
                if (params.value?.isInsCompany) return params.value?.shortName;
                return params.value?.name
            },
            cellRendererFramework: SVVLinesGridPaymentMethodCR,
            // valueSetter: (params: ValueSetterParams) => {
            //     console.log(`params.newValue`, params.newValue);
            //     params.node.setDataValue('duration', params.newValue.duration)
            //     params.node.setDataValue('product', params.newValue)
            // },
            width: 120,
            editable: ({data}) => !data.draftInvoiceNumber || canEditLockedInvoiceLines
        },
        {
            field: paymentField('insCompany'),
            headerName: 'Client',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params: IRichCellEditorParams) => {
                // console.log(`IRichCellEditorParams`, params);
                // let visitId = params.data.visit.id;
                // let visit = store.getLocalVisit(visitId);
                // let resourceId = visit.resource?.id;
                let paymentMethodId = _.get(params.data, paymentField('paymentMethod.id'));
                let paymentMethodsInsCompaniesMapElement = store.paymentMethodsInsCompaniesMap[paymentMethodId];
                // console.log(`params.data`, params.data);
                // console.log(`resourceId, resourceProductMapElement`, resourceId, resourceProductMapElement);
                let values = paymentMethodsInsCompaniesMapElement
                return {
                    values: values,
                    formatValue: (params) => {
                        // Add default marker
                        // console.log(`params, params?.name`, params, params?.name);
                        if (params?.id == null) return null
                        return !!params.value?.shortName ? params.value?.shortName : formatFormalName(params)
                    },
                }
            },
            valueGetter: params => {
                let value = getValueFromGetter(params, 'insCompany')
                let paymentMethodId = _.get(params.data, paymentField('paymentMethod.id'));
                if (paymentMethodId == 'private')
                    return store.patientForm.form.values()
                else if (paymentMethodId == 'endOfMonth')
                    return value
                else if (getValueFromGetter(params, 'paymentMethod.insCompany'))
                    return getValueFromGetter(params, 'paymentMethod.insCompany')
                else if (paymentMethodId == 'endOfMonth')
                    return '--'
                return value
            },
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.data.id == null) return null
                return !!params.value?.shortName ? params.value?.shortName : formatFormalName(params.value)
            },

            cellRendererFramework: observer(forwardRef((params: ICellRendererParams, ref) => {
                useImperativeHandle(ref, () => {
                    return {
                        refresh: (params) => {
                            return true;
                        }
                    };
                })
                let visitLineId = params.data.id
                // let [s, setS] = useState(false)
                if (!store.visitLinesRateOptionsMap) return null
                let paymentMethod = getValueFromGetter(params, 'paymentMethod');

                // autorun(() => {
                //     let paymentMethod = getValueFromGetter(params, 'paymentMethod');
                //     setS(true)
                // })
                // if (_.isEmpty(params.valueFormatted)) return null
                let rates = store.visitLinesRateOptionsMap[visitLineId]?.rates
                let rateCount = rates?.length;
                let indicator = null
                if (params.value?.id == null && !_.isEmpty(paymentMethod?.id)) {
                    let nameElem = insCompanyPaymentMethodNamesMap[paymentMethod?.id];
                    let message = `Selecciona un client per ${nameElem?.long}`;
                    indicator = <Tooltip content={message}>
                        <Icon icon={'select'} intent={"none"}
                              color={'#8cb6de'}
                              onClick={() => {
                                  params.node.setDataValue(paymentField('rate'), rates[0])
                              }}
                        />
                    </Tooltip>
                }

                let isRedundant = paymentMethod?.isInsCompany

                return <TestCRContainer>
                    <span style={{color: isRedundant ? 'gray' : ''}}>{params.valueFormatted}</span>
                    <span/>
                    {indicator}
                </TestCRContainer>
            })),
            // valueSetter: (params: ValueSetterParams) => {
            //     console.log(`params.newValue`, params.newValue);
            //     params.node.setDataValue('duration', params.newValue.duration)
            //     params.node.setDataValue('product', params.newValue)
            // },
            editable: (params) => (['ss', 'endOfMonth'].includes(getValueFromGetter(params, 'paymentMethod.id'))),
            width: 135,

        },
        // N. Polissa
        {
            field: paymentField('insCompanyPaymentForm'),
            headerName: 'F. Pagament',
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params: IRichCellEditorParams) => {
                let values = _.keys(insCompanyPaymentFormMap)
                if (!getValueFromGetter(params, 'paymentMethod.isInsCompany')) values = []
                return {
                    values: values,
                    formatValue: (params) => {
                        // console.log(`params, params?.name`, params, params?.name);
                        if (params == null) return null
                        return insCompanyPaymentFormMap[params]
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                if (!getValueFromGetter(params, 'paymentMethod.isInsCompany')) return '--'
                let value = params.value;
                return insCompanyPaymentFormMap[value]
            },
            width: 120,
            editable: (params) => (getValueFromGetter(params, 'paymentMethod.isInsCompany'))
        },
        {
            field: paymentField('rate'),
            headerName: 'Barem / Tarifa',
            // cellEditor: 'agRichSelectCellEditor',
            cellEditorFramework: SelectCE,
            cellEditorParams: (params: IRichCellEditorParams) => {
                let visitLineId = params.data.id
                let visitLinesRateOptionsMapElement = store.visitLinesRateOptionsMap[visitLineId];
                let values = visitLinesRateOptionsMapElement?.rates || []

                if (store.allRatesMode)
                    values = store.getRateSheet(visitLinesRateOptionsMapElement?.rateSheetId)?.rates || [];

                return {
                    values: [{id: null, name: '--', price: 0}, ...values],
                    formatValue: (params) => {
                        // console.log(`params, params?.name`, params, params?.name);
                        if (params == null) return ''
                        if (params.id == null) return '--'
                        let startsWithCode = _.isFinite(_.toNumber((params.name || '').substr(1, 2)))
                        return `${!startsWithCode ? `${params?.code || params?.id} ` : ''}${params.name}`
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                let value = params.value;
                if (!value) return ''
                if (value.id == null) return ''
                // if (value == null || value.id == null) return ''
                let startsWithCode = _.isFinite(_.toNumber((value.name || '').substr(1, 2)))

                return `${!startsWithCode ? `${value?.code || value?.id} ` : ''}${value?.name}`
            },
            // valueSetter: (params: ValueSetterParams) => {
            //     console.log(`params.newValue`, params.newValue);
            //     params.node.setDataValue('duration', params.newValue.duration)
            //     params.node.setDataValue('product', params.newValue)
            // },
            cellRendererFramework: observer(forwardRef((params: ICellRendererParams, ref) => {
                useImperativeHandle(ref, () => {
                    return {
                        // refresh: (params) => {
                        //     return true;
                        // }
                    };
                })
                let visitLineId = params.data.id
                if (!store.visitLinesRateOptionsMap) return null
                let visitLinesRateOptionsMapElement = store.visitLinesRateOptionsMap[visitLineId];
                let values = visitLinesRateOptionsMapElement?.rates
                let count = values?.length;
                let allValues;
                if (store.allRatesMode) {
                    let rateSheet = store.getRateSheet(visitLinesRateOptionsMapElement?.rateSheetId);
                    allValues = rateSheet?.rates;
                }
                return <div style={{
                    display: 'grid', gridTemplateColumns: '1fr', gridAutoFlow: 'column',
                    columnGap: 4, alignItems: "center"
                }}>
                    {(visitLinesRateOptionsMapElement?.insCompanyHasNoRateSheet) &&
                    <span style={{
                        color: 'skyblue',
                        fontWeight: 'bold'
                    }}>({visitLinesRateOptionsMapElement?.insCompany?.name} no té full de barems)</span>}
                    <span style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>{params.valueFormatted}</span>
                    {count >= 0 &&
                    <span style={{color: 'gray', fontWeight: count > 1 ? 'bold' : 'normal'}}
                          onClick={() => store.refetchVisitLinesRateOptions(visitLineId)}
                    >
                      ({count == null ? '...' : count})
                    </span>}
                    {(allValues && (visitLinesRateOptionsMapElement?.rateSheetId != null)) &&
                    <span style={{
                        color: '#F29D49',
                        fontWeight: allValues?.length > count ? 'bold' : 'normal'
                    }}>({allValues?.length})</span>}


                </div>
            })),
            editable: ({data}) => !data.invoiceNumber || canEditLockedInvoiceLines,
            width: 370,

        },
        {
            field: paymentField('date'),
            headerName: 'D. Prestació',
            width: 100,
            // editable: ({data}) => !data.invoiceNumber,
            ...stdDateColumn(),
            cellEditor: 'dateTimeCE',
            cellEditorParams: (params: ICellEditorParams) => {
                let visitId = params.data.visit.id;
                let visit = store.getLocalVisit(visitId);
                return {
                    defaultValue: visit.appointmentDate || new Date()
                }
            },
            editable: true,
        },
        {
            field: paymentField('price'),
            headerName: '€',
            width: 60,
            editable: ({data}) => !data.invoiceNumber || canEditLockedInvoiceLines,
            valueFormatter: params => {
                if (params.value == null) return null
                return numeral(params.value).format('0.00')
            },
            // cellEditorFramework: NumericCellEditor,
            type: ['numericColumn', 'rightAligned'],
        },

        {
            field: paymentField('paymentMethod.mutuaNumber'),
            headerName: 'N. Polissa / CIP / DNI',
            width: 130,
            editable: false,
            valueGetter: params => {
                let value = getValueFromGetter(params, 'paymentMethod.mutuaNumber')
                if (getValueFromGetter(params, 'paymentMethod.isInsCompany'))
                    return value
                else if (_.get(params.data, paymentField('paymentMethod.id')) == 'ss')
                    return store.patientForm.$('cip').value || '[Falta el CIP]'
                else if (_.get(params.data, paymentField('paymentMethod.id')) == 'private')
                    return store.patientForm.$('nif').value || '[Falta el NIF]'
                else if (_.get(params.data, paymentField('paymentMethod.id')) == 'endOfMonth')
                    return '--'
            }
        },
        {
            field: paymentField('authorizationNumber'),
            headerName: 'N. Autorització',
            width: 120,
            editable: true
        },
        {
            field: paymentField('volantNumber'),
            headerName: 'N. Volant',
            width: 90,
            editable: true
        },
        // {
        //     field: paymentField('comment'),
        //     headerName: 'Comentari',
        //     width: 120,
        //     editable: true,
        //     // cellEditor: 'agLargeTextCellEditor'
        //
        // },

    ] as AgGridColumnProps[];
    let cellEditorSubmitterLogic = (e: CellValueChangedEvent) => {
        let visitLineId = e.data.id;
        let fieldName = e.colDef.field;
        let refetch = false;
        let refetchVisitLinesRateOptions = false;
        let savePrice = false;
        if (fieldName == paymentField('paymentMethod') || fieldName == paymentField('insCompany')) {
            // e.node.setDataValue(paymentField('rate'), null)
            refetch = true
            refetchVisitLinesRateOptions = true
            // store.refetchVisitLinesRateOptions(visitLineId)
            //     .then(() => null).then(() => {
            //     setTimeout(() => {
            //         store.refetch();
            //         store.refetchVisitLinesRateOptions(visitLineId)
            //     }, 20)
            // })

            // if (getValueFromGetter(e, 'paymentMethod.isInsCompany')) {
            //     e.node.setDataValue(paymentField('insCompany'), e.data)
            // } else {
            //     e.node.setDataValue(paymentField('insCompany'), null)
            // }
            // if (e.data.id == 'ss') {
            //     e.node.setDataValue(paymentField('paymentMethod.mutuaNumber'), 'DNI')
            // }
        }
        if (fieldName == paymentField('insCompanyPaymentForm')) {
            refetch = true
        }
        if (fieldName == paymentField('rate')) {
            refetch = true
            savePrice = true
            // e.node.setDataValue(paymentField('price'), e.newValue?.price)
        }
        // After saving

        return {
            refetch: refetch,
            refetchVisitLinesRateOptions: refetchVisitLinesRateOptions,
            savePrice
        }

    };
    return {columns, cellEditorSubmitterLogic}
}
