import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {PrescriptionDataView} from "../components/PrescriptionDataView";

const Container = styled.div`
    display: grid;
    
`

export interface MPPMainProps {

}

export const MPPMain = observer((props: MPPMainProps) => {
    return <Container>
        <PrescriptionDataView/>
    </Container>
})