import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid, MasterLayout} from "react-c4/src/index";
import {useOrgFVStore} from "./OrgFileView"
import {OrgFVMainInvoiceCounter} from "./OrgFVMainInvoiceCounter";
import {Button, InputGroup, Popover} from "@blueprintjs/core";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {RowNode} from "ag-grid-community";
import {formatFullName} from "../../application/utils/utils";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 8px;
`
const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 8px;
  place-content: start;
  padding-left: 5px;
`

const PopoverContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding: 16px;
  grid-gap: 16px;

`

const PopoverButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-auto-flow: column;
`


export interface OrgFVMainProps {
    pageContext
}

export const OrgFVMain = observer((props: OrgFVMainProps) => {
        let store = useOrgFVStore();
        return <Container>
            <MasterLayout
                renderHeader={() => {
                    return null
                }}
                body={
                    <C3AgGrid
                        columnDefs={[
                            {
                                field: 'name',
                                headerName: 'Nom',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'shortName',
                                headerName: 'Nom curt',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'cif',
                                headerName: 'CIF',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'website',
                                headerName: 'Pàgina web',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'mobile',
                                headerName: 'Mobil',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'phone',
                                headerName: 'Telèfon',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'email',
                                headerName: 'Email',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'address',
                                headerName: 'Direcció',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'postalCode',
                                headerName: 'C. Postal',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'city',
                                headerName: 'Ciutat',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'mercantileRegistry',
                                headerName: 'R.Mercantil',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'bankAccount',
                                headerName: 'Conta Bancaria',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'rgpdFooterNote',
                                headerName: 'Nota Peu pàgina',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'lastFooterLine',
                                headerName: 'Última línia peu factura',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            }, {
                                field: 'order',
                                headerName: 'Ordre',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            }
                        ]}
                        processCellCallback={(params) => {
                            let data = (params.node as RowNode).data
                            return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                        }}
                        getContextMenuItems={(params) => {
                            // let fileName = store.fr
                            var result = [
                                {
                                    // custom item
                                    name: 'Exporta a Excel',

                                    action: function () {
                                        params.api.exportDataAsExcel({
                                            fileName: 'llistat-organitzacions',
                                            processCellCallback: (params) => {
                                                let data = (params.node as RowNode).data
                                                if (params.column.getColId() == 'isActive') {
                                                    return data.isActive == true ? 'X' : ''
                                                }
                                                return params.value
                                            }
                                        })
                                    },
                                }
                            ]
                            return result
                        }}
                        rowData={store.orgRes}
                        selection={store.orgSel}
                        cellEditorSubmitter={async (e) => {
                            await store.saveOrg({
                                id: e.data.id,
                                [e.colDef.field]: e.newValue
                            })
                        }
                        }
                    />
                }
            />
            <ButtonContainer>
                <Button icon={'document'}
                        onClick={() => store.createOrg()}
                />
                <Button icon={'trash'}
                        disabled={!store.resourceLocked}
                        onClick={() => store.deleteSelectedOrg()}
                />
                <Popover
                    isOpen={store.resourcePopoverCheck}
                    content={<PopoverContainer>
                        <InputGroup {...store.filterForm.$('resourceEditCode').bind()} leftIcon={'lock'} type={'password'}
                                    placeholder={`Codi secret de borrat`}/>
                        <PopoverButtonsContainer>
                            <Button icon={'cross'} onClick={() => {
                                store.resourcePopoverCheck = false
                                store.filterForm.$('resourceEditCode').set('')
                            }}>Cancel·la</Button>
                            <Button icon={'tick'} disabled={store.filterForm.$('resourceEditCode').value != '7778'}
                                    intent={'warning'} onClick={async () => {
                                if (true) {
                                    store.resourceLocked = true
                                    store.filterForm.$('resourceEditCode').set('')
                                    await store.fetch()
                                    store.resourcePopoverCheck = false
                                }

                            }}>Desbloquejar Borrar Organització</Button>
                        </PopoverButtonsContainer>
                    </PopoverContainer>}
                >
                    <Button
                        icon={store.resourceLocked ? 'unlock' : 'lock'}
                        intent={'none'}
                        onClick={() => {
                            if (store.resourceLocked) {
                                store.resourceLocked = false;
                            } else {
                                store.resourceLocked = false;
                                store.resourcePopoverCheck = !store.resourcePopoverCheck;
                            }
                        }}
                    />
                </Popover>
            </ButtonContainer>
            <OrgFVMainInvoiceCounter pageContext={props.pageContext}/>
        </Container>
    }
)
