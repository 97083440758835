import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4/src/index";
import {CellValueChangedEvent, ICellRendererParams, RowNode} from "ag-grid-community";
import {formatFullName, stdDateColumn} from "../../../../application/utils/utils";
import moment from "moment";
import {useADVStore} from "../../AllDeltasView";
import {json} from "express";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;

`

export interface DeltasGridProps {

}

export const DeltasGrid = observer((props: DeltasGridProps) => {
    let store = useADVStore()
    let appCols = [
        {
            field: 'eventValues.title',
            headerName: 'App Title',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 155,
        }, {
            field: 'eventValues.description',
            headerName: 'App Description',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 200,
        },
        {
            field: 'startDate',
            headerName: 'App start',
            ...stdDateColumn(),
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'endDate',
            headerName: 'App end',
            ...stdDateColumn(),
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'eventValues',
            headerName: 'App values',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            valueFormatter: (params) => {
                return JSON.stringify(params.value)
            }
        },
        {
            field: 'eUpdatedAt',
            headerName: 'App Updated At',
            ...stdDateColumn(),
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        // {
        //     field: 'startDate',
        //     headerName: 'App Date',
        //     filter: 'agTextColumnFilter',
        //     ...stdDateColumn(),
        // }, {
        //     field: 'startDate',
        //     headerName: 'Time',
        //     filter: 'agTextColumnFilter',
        //     ...stdDateColumn(),
        //     valueFormatter({value}) {
        //         if (value === undefined) return ''
        //         if ([null, ''].includes(value)) return ''
        //         return moment(value).format('HH:mm');
        //     },
        // }, {
        //     field: 'updatedAt',
        //     headerName: 'Updated At',
        //     filter: 'agTextColumnFilter',
        //     ...stdDateColumn(),
        //     valueFormatter({value}) {
        //         if (value === undefined) return ''
        //         if ([null, ''].includes(value)) return ''
        //         return moment(value).format('HH:mm');
        //     },
        // },
    ]
    let centerCols = [
        {
            field: 'action',
            headerName: 'Action',
            // valueFormatter: function ({value}) {
            //     return <Button>{'->'}</Button>
            // },
            cellRendererFramework: (params: ICellRendererParams) => {
                return <button onClick={() => store.resolveSelectedDeltas(params.value)}>{params.value}</button>
            }
        },
    ]
    let meetupCols = [
        {
            field: 'meetupEventValues.title',
            headerName: 'M.Title',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 155,
        }, {
            field: 'meetupEventValues.description',
            headerName: 'M. Description',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 200,
        },
        {
            field: 'startDate',
            headerName: 'M. start',
            ...stdDateColumn(),
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'endDate',
            headerName: 'M. end',
            ...stdDateColumn(),
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'meetupEventValues',
            headerName: 'M. values',
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            valueFormatter: (params) => {
                console.log(`params.data`, params.data);
                return JSON.stringify(params.value)
            }
        },
        {
            field: 'meUpdatedAt',
            headerName: 'M. Updated At',
            ...stdDateColumn(),
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },

    ].reverse()
    return <Container>
        <MasterLayout
            renderHeader={() => null}

            body={
                <C3AgGrid
                    columnDefs={[
                        {
                            field: '',
                            headerName: '',
                            width: 30,
                            checkboxSelection: true,
                            // suppressFilter: true,
                        },
                        ...appCols,
                        ...centerCols,
                        ...meetupCols,

                    ]}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    params.api.exportDataAsExcel({
                                        fileName: `deltas-list`,
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'createdAt') {
                                                return moment(data.createdAt).format('DD/MM/YYYY')
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    // rowData={store.meetupGroupsRes}
                    selection={store.deltasSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        // await store.saveMeetupEvent({
                        //     id: e.data.id,
                        //     [e.colDef.field]: e.newValue
                        // })
                    }}
                    rowSelection={'multiple'}

                />
            }
        />
    </Container>
})
