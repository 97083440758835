import {C3Selection, IATSStyleApp, C3, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {autorun, observable} from "mobx";
import MobxReactForm from "mobx-react-form";
import {GridApi} from "ag-grid-community";
import {formatFullName} from "../../application/utils/utils";

export class UserFVStore {
    appStore: AppStore
    pageContext

    @observable.shallow
    usersRes

    usersSel: C3Selection

    usersGridApi: GridApi

    filterForm: MRF

    @observable
    editingEnabled = true

    @observable
    resourcePopoverCheck = false

    @observable.shallow
    orgsRes: any[]

    orgSel: C3Selection

    userForm: MRF

    @observable.shallow
    allRolesRes: any[]

    @observable.shallow
    userRolesSel: C3Selection


    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.orgsRes = this.appStore.orgs

        this.filterForm = new MobxReactForm({
            fields: [
                'resourceEditCode',
                'orgs',
            ],
            values: {
                resourceEditCode: '',
                orgs: [],
            },
        })

        this.orgSel = new C3Selection(({
            items: this.orgsRes
        }))

        this.userRolesSel = new C3Selection(({
            items: this.allRolesRes
        }))

        this.userForm = new MobxReactForm({
            fields: [
                'roles'
            ],
            values: {
                roles: []
            }
        })

        this.usersSel = new C3Selection(({
            items: this.usersRes,
        }))
        await this.fetch()
        await this.fetchAllRoles()

        autorun(async () => {
            this.orgsRes = this.appStore.orgs;
            this.orgSel.itemsRef.current = this.orgsRes;
            this.orgSel.selectId(this.appStore.auth.loggedInUser?.org?.id)
        })
        autorun(async () => {
            this.filterForm.$('orgs').set(this.orgSel.selectedItem);
            await this.fetch()
        })

        autorun(async () => {
            await this.fetchUserRoles(this.usersSel.selectedId)
        })
    }
    async refresh() {
        await this.fetch()
        await this.fetchUserRoles(this.usersSel.selectedId)
    }

    async fetch() {
        const query = this.orgSel.selectedId ? `org=${this.orgSel.selectedId}` : '';
        if (query === '') return null
        this.usersRes = await C3.instance.client.fetcher.fetch('/auth/all-users', {
            query: [query].join('&')
        });
        this.usersSel.itemsRef.current = this.usersRes;
        return this.usersSel;
    }

    async fetchAllRoles() {
        this.allRolesRes = await C3.instance.client.fetcher.fetch('/auth/all-roles', {});
        this.allRolesRes = this.allRolesRes.map((v) => {
            v.name = v.displayName
            return v
        })
        return this.allRolesRes;
    }


    async createUser() {
        await C3.instance.client.fetcher.fetch(`/auth/all-users`, {
            method: 'POST',
            body: {
                name: 'nou usuari',
                email: 'insertar mail',
                org: {id: this.orgSel.selectedId},
                isActive: true
            }
        })
        await this.fetch()
    }

    async deleteUser(userId) {
        await C3.instance.client.fetcher.fetch(`/auth/all-users/${userId}`, {
            method: 'DELETE',
        })
        await this.fetch()
    }

    async deleteSelectedUser() {
        let selectedId = this.usersSel.selectedId;
        if (selectedId === null) return;
        await this.deleteUser(selectedId)
    }

    async saveUser(user) {
        await C3.instance.client.fetcher.fetch(`/auth/all-users`, {
            method: 'PATCH',
            body: user
        })
    }
    async resetPinForSelectedUser() {
        let selectedItem = this.usersSel.selectedItem;
        let userId = selectedItem?.id
        if(!userId) return
        let res = await C3.instance.client.fetcher.fetch(`/auth/all-users/${userId}/reset-pin`, {
            method: 'POST',
            body: {}
        })
        let newPin = res.pinNumber
        this.usersGridApi.getRowNode(userId as any).setDataValue('pinNumber', newPin)
        IATSStyleApp.instance.toaster.show({
            intent: "success",
            icon: 'key',
            message: `Nou PIN per a ${formatFullName(selectedItem)?.toUpperCase()}: ${newPin}`
        })
    }

    async resetCustomResourceGroups() {
        let selectedItem = this.usersSel.selectedItem;
        let userId = selectedItem?.id
        if(!userId) return
        let res = await C3.instance.client.fetcher.fetch(`/auth/all-users/${userId}/reset-crg`, {
            method: 'POST',
            body: {}
        })
    }

    async fetchUserRoles(userId) {
        if (userId == null) return
        this.userRolesSel.itemsRef.current = await C3.instance.client.fetcher.fetch(`/auth/${userId}/roles`, {});
        // this.userForm.$('roles').set(this.userRolesSel);
    }

    async createRoleAssign() {
        if (this.usersSel.selectedId == null) return
        await C3.instance.client.fetcher.fetch(`/auth/user-roles`, {
            method: 'POST',
            body: {
                user: this.usersSel.selectedId
            }
        })
        await this.fetchUserRoles(this.usersSel.selectedId)
    }

    async saveUserRole(userRole) {
        if (userRole.user == null || userRole.role == null) return
        await C3.instance.client.fetcher.fetch(`/auth/user-roles`, {
            method: `PATCH`,
            body: userRole
        })
        await this.fetchUserRoles(this.usersSel.selectedId)
    }

    async deleteSelectedRoleAssign() {
        let roleAssignId = this.userRolesSel.selectedId;
        if (roleAssignId == null) return;
        await this.deleteRoleAssign(roleAssignId);
    }

    async deleteRoleAssign(roleAssignId) {
        await C3.instance.client.fetcher.fetch(`/auth/user-roles/${roleAssignId}`, {
            method: `DELETE`,
        })
        await this.fetchUserRoles(this.usersSel.selectedId)
    }

}
