import React, {useState, useEffect, useContext} from "react";
import styled, {css} from 'styled-components'
import {observer} from 'mobx-react'
import {Icon, IconName} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
  //border: #0e5a8a dashed 3px;
  //border: 2px dashed gray;
  grid-auto-flow: column;
  height: 100%;
  padding: 12px; // 16
  grid-template-rows: auto 1fr;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  background-color: #f8fbfd;
  @media screen and (max-width: 1024px) {
    border-radius: 3px;
  }
  &:hover{
    > * {
      fill: white;
    }
    //background-color: #f5f8fa;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.36);
    fill: white;
  }
  ${p => !p.primary && css`
      //background-color: rgba(15, 153, 96, 0.15);
      //color: #0d8050;
      grid-template-rows: auto;
      grid-template-columns: auto 1fr;
      align-items: center;
      align-content: center;
      padding: 8px;
      grid-gap: 4px;

   `}
`;
const IconContainer = styled.div`
  //border: 2px dashed gray;
  font-size: 40px !important; // 60
  color: #768797;
  justify-self: center;
  display: grid;
  place-items: center;
  @media screen and (max-width: 1024px) {
    font-size: 35px !important;
  }
  @media screen and (max-width: 800px) {
    font-size: 18px !important;
  }
`;
const LabelContainer = styled.div`
  font-size: 22px;
  justify-self: center;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
  ${p => !p.primary && css`
      font-size: 16px;
   `}
`;

export interface FilePageIconProps {
    name: string
    icon: IconName
    onClick: () => void
    primary
}

export const FilePageIcon = observer((props: FilePageIconProps) => {
    return <Container primary={props.primary} data-cy={'FilePageIcon-' + props.name} onClick={props.onClick}>
        <IconContainer>
            <Icon icon={props.icon} iconSize={props.primary ? 50 : 18}/>
        </IconContainer>
        <LabelContainer primary={props.primary}>
            {props.name}
        </LabelContainer>
    </Container>;
})
