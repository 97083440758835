// Interfaces
import {C3} from "react-c4";

export interface LustUploadResponse {
    bucket_id: number;
    checksum: number;
    image_id: string;
    io_time: number;
    processing_time: number;
}

export interface RegisterImageResponse {
    id: number;
    // Add other fields as necessary
}

// Constants
const LustConstants = {
    BASE_URL: "https://bis.protocolapp.net/v1/images",
    PROFILE_BUCKET: "bis-profile-pictures",
    EVENT_BUCKET: "bis-event-images",
    // Add other buckets as needed
};

export class LustImageUploader {
    private baseUrl: string;

    constructor(baseUrl: string = LustConstants.BASE_URL) {
        this.baseUrl = baseUrl;
        window['LustImageUploader'] = this;
    }

    private async uploadToLust(file: File, bucket: string): Promise<LustUploadResponse> {
        const response = await fetch(`${this.baseUrl}/${bucket}`, {
            method: 'POST',
            body: file,
            headers: {
                'Content-Type': 'application/octet-stream',
                'Content-Length': file.size.toString()
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    }

    private async registerImageWithServer(lustResponse: LustUploadResponse, file: File): Promise<RegisterImageResponse> {
        const response = await C3.instance.client.fetcher.fetch('/image/register', {
            method: 'POST',
            body: {
                uploadResponse: lustResponse,
                filename: file.name,
                mimeType: file.type
            }
        });

        return response;
    }

    public async uploadProfileImage(file: File): Promise<RegisterImageResponse> {
        const lustResponse = await this.uploadToLust(file, LustConstants.PROFILE_BUCKET);
        return this.registerImageWithServer(lustResponse, file);
    }

    public async uploadEventImage(file: File): Promise<RegisterImageResponse> {
        const lustResponse = await this.uploadToLust(file, LustConstants.EVENT_BUCKET);
        return this.registerImageWithServer(lustResponse, file);
    }

    public async uploadMultipleImages(files: FileList, bucketType: 'profile' | 'event'): Promise<RegisterImageResponse[]> {
        const bucket = bucketType === 'profile' ? LustConstants.PROFILE_BUCKET : LustConstants.EVENT_BUCKET;
        const uploadedImages: RegisterImageResponse[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            if (file) {
                try {
                    const lustResponse = await this.uploadToLust(file, bucket);
                    const registeredImage = await this.registerImageWithServer(lustResponse, file);
                    uploadedImages.push(registeredImage);
                } catch (error) {
                    console.error(`Failed to upload file ${file.name}:`, error);
                }
            }
        }

        return uploadedImages;
    }

    public static buildImageUrl(bucket: string, etag: string, size?: string): string {
        let url = `${LustConstants.BASE_URL}/${bucket}/${etag}`;
        if (size) {
            url += `?size=${encodeURIComponent(size)}`;
        }
        return url;
    }
}
