import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {ProductEditor} from "./components/ProductEditor";
import {ProductRatesEditor} from "./components/ProductRatesEditor/ProductRatesEditor";
import {useProdFVStore} from "./ProdFV";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 8px;
`

export interface ProdFVMainProps {
    pageContext
}

export const ProdFVMain = observer((props: ProdFVMainProps) => {
    let store = useProdFVStore()
    return <Container style={store.isMatrixFullScreen ? {gridTemplateColumns: '1fr 0px'}: {}}>
        <ProductEditor/>
        <ProductRatesEditor/>
    </Container>
})
