import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSCVStore} from "../ScheduleView";
import {SCVCalendar} from "./SCVCalendar/SCVCalendar";
import {EventDetail} from "./components/EventDetail";
import Splitter from "m-react-splitters";
import {MeetupEventDetail} from "./components/MeetupEventDetail";
import {WeeklyScheduleDetail} from "./components/WeeklyScheduleDetail";
import {EventFeatureEditor} from "./components/EventFeatureEditor/EventFeatureEditor";
import {FeatureEditorDetail} from "./components/FeatureEditorDetail";

const Container = styled.div`
  display: grid;
  height: 100%;
`
const DetailContainer = styled.div`
  display: grid;
  height: 100%;
  //overflow: auto;
`

export interface SVMainProps {
    pageContext
}

export const SCVMain = observer((props: SVMainProps) => {
    let store = useSCVStore();
    let isEvent = store.eventSel.selectedItem?.type == 'event';
    let SplitterComponent: any = isEvent ? Splitter : Splitter;
    return <Container>
        <SplitterComponent
            position="vertical"
            primaryPaneMinWidth={0}
            primaryPaneWidth={'70%'}
            postPoned={false}>
            <SCVCalendar pageContext={props.pageContext}/>
            {store.detailMode == 'event' ? <DetailContainer>
                {store.eventSel.selectedId ? (isEvent ? <EventDetail/> :
                    <MeetupEventDetail/>) : <div>Selecciona un event</div>}
            </DetailContainer> : ((store.detailMode == 'feature-editor' && isEvent) ? <FeatureEditorDetail/> :
                <WeeklyScheduleDetail/>)}

        </SplitterComponent>


    </Container>
})
