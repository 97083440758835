import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid, MasterLayout} from "react-c4/src/index";
import {observer} from "mobx-react";
import {OrgFVStore} from "./OrgFVStore";
import {useOrgFVStore} from "./OrgFileView";


const Container = styled.div`
  display: grid;

`

export interface OrgFVMainInvoiceCounterProps {
    pageContext
}

export const OrgFVMainInvoiceCounter = observer((props: OrgFVMainInvoiceCounterProps) => {
    let store = useOrgFVStore();
    return <Container>
        <MasterLayout
            renderHeader={() => {
                return null
            }}
            actions={[
                {
                    name: 'create',
                    iconName: 'document',
                    callback: () => {
                        store.createInvoiceCounter()
                    }
                },
                {
                    name: 'delete',
                    iconName: 'trash',
                    callback: () => {
                        store.deleteSelectedInvoiceCounter()
                    }
                }
            ]}
            body={
                <C3AgGrid
                    columnDefs={[
                        {
                            field: 'code',
                            headerName: 'Codi',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'year',
                            headerName: 'Any',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'prefix',
                            headerName: 'Prefix',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'counter',
                            headerName: 'Contador Factura',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'draftCounter',
                            headerName: 'Contador Prefactura',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'comments',
                            headerName: 'Comentaris',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        }
                    ]}
                    rowData={store.invoiceCounterRes}
                    selection={store.invoiceCounterSel}
                    cellEditorSubmitter={async (e) => {
                        await store.saveInvoiceCounter({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                    }
                    }
                />
            }
        />
    </Container>
})
