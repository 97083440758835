import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const specialtySchema: C3SchemaInfo = {
    'specialty': {
        fields: {
            id: {},
            name: {},
            description: {},
            color: {},
            refMatEntries: {
                type: 'referrer-matrix',
            },
            referredVisitLines: {
                type: 'visit-line',
            },
            contacts: {
                type: 'contact',
            }
        }
    }
}
