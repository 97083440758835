import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useProductFamiliesFVStore} from "../ProductFamiliesFileView";
import {C3AgGrid, MasterLayout} from "react-c4";
import {PFEditorFV} from './components/PFEditorFV'

const Container = styled.div`
  display: grid;

`

export interface ProductFamiliesFVMainProps {
    pageContext
}

export const ProductFamiliesFVMain = observer((props: ProductFamiliesFVMainProps) => {
    let store = useProductFamiliesFVStore()
    return <Container>
        <PFEditorFV />
    </Container>
})
