import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {BSVStore} from "./BSVStore";
import {useC3Store} from "react-c4";
import {BSVMain} from "./BSVMain/BSVMain";

const Container = styled.div`
    display: grid;
    
`


export const BSVContext = React.createContext(null as BSVStore)
export const useBSVStore = () => useContext(BSVContext)

export interface BasicStatisticsViewProps {
    pageContext?
    onStoreReady: (s: BSVStore) => any
}

export const BasicStatisticsView = observer((props: BasicStatisticsViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new BSVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <BSVContext.Provider value={store}>
        <Container>
            <BSVMain pageContext={props.pageContext}/>
        </Container>
    </BSVContext.Provider>
})
