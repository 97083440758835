import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const documentSchema: C3SchemaInfo = {
    'document': {
        fields: {
            id: {},
            name: {},
            description: {},
            eid: {},
            documentType: {
                type: 'document-type'
            },
            // MTO Rels / Places where documents are attached
            patient: {
                type: 'patient'
            },
            episode: {
                type: 'episode'
            },
            visit: {
                type: 'visit'
            },
            visitLine: {
                type: 'visit-line'
            },
        }
    }
}
