import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SRVLeftPanel} from "../../ScheduleRulesView/SRVMain/SRVLeftPanel";
import {SchedulePageSubheader} from "../../../pages/SchedulePage/SchedulePageSubheader";
import {ScheduleView} from "../../ScheduleView/ScheduleView";
import Splitter from "m-react-splitters";
import {useARVStore} from "../AppointmentsReviewView";

const Container = styled.div`
    display: grid;
    
`
const SCVContainer = styled.div`
    height: 100%;
    //transform: scale(0.8);
    //   box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.36);
       background-color: white;
`

export interface ARVMainProps {

}

export const ARVMain = observer((props: ARVMainProps) => {
    let store = useARVStore()
    return <Container>
        <Splitter
            position="vertical"
            primaryPaneMinWidth={0}
            primaryPaneWidth={'400px'}
            postPoned={false}>
            <SRVLeftPanel/>
            <SCVContainer>
                <SchedulePageSubheader store={store.scvStore}/>
                <ScheduleView onStoreReady={(scvStore) => store.setSCVStore(scvStore)} pageContext={store.pageContext}/>
            </SCVContainer>

        </Splitter>
    </Container>
})
