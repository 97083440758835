import {C3, C3Selection, IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import * as moment from "moment";

export class MPEStore {
    appStore: AppStore
    pageContext

    meetupEventsSel: C3Selection

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.meetupEventsSel = new C3Selection({})

        await this.fetchMeetupEventsToPush()

    }

    async fetchMeetupEventsToPush() {
        let res = await C3.instance.client.fetcher.fetch(`/meetup-event/events-to-push`, {})
        res = res.map(me => {
            me.updatedAt = moment(me.updatedAt).format("DD/MM/YYYY hh:mm")
            me.start = moment(me.start).format("DD/MM/YYYY hh:mm")
            me.end = moment(me.end).format("DD/MM/YYYY hh:mm")
            if (!me.status) return me
            me.status.update = moment(me.status.update).format("DD/MM/YYYY hh:mm")
            me.status.start = moment(me.status.time).format("DD/MM/YYYY hh:mm")
            me.status.end = moment(me.status.start).add(me.status?.duration).format("DD/MM/YYYY hh:mm")
            return me
        })
        console.log(`meetupEventsToPush`, res);

        this.meetupEventsSel.itemsRef.current = res
    }

    meetupEventFieldMap = {
        name: 'name',
        description: 'description',
        updatedAt: 'update',
        start: 'start',
        end: 'end',
    }


    getFieldsWithDelta(meetupEvent) {
        return Object.keys(this.meetupEventFieldMap)
            .map(f => (meetupEvent[f] != status[this.meetupEventFieldMap[f]]) ? f : null)
            .filter(a => a)
    }

    getStatusField(f) {
        return this.meetupEventFieldMap[f]
    }
}