import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const privateInvoicingViewSchema: C3SchemaInfo = {
    'private-invoicing-view': {
        fields: {
            id: {},
            name: {},

        }
    }
}
