import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useALVStore} from "../AppointmentListView";
import {isDev} from "../../../application/utils/utils";
import {Button, ButtonGroup, Tooltip} from "@blueprintjs/core";
import * as _ from "lodash";
import {ResourceSelector} from "../../../application/components/shared/ResourceSelector";





export interface ALVHeaderProps {
    pageContext
}
const Container = styled.div`
    display: grid;
    height: 100%;
  padding: 3px;
  align-content: center;
`
const ResourceSelectorContainer = styled.div`
    display: grid;
    padding: 4px;
    margin-bottom: -8px;
    place-content: center;
`

export const ALVHeader = observer((props: ALVHeaderProps) => {
    let store= useALVStore()

    return <Container>
        {isDev() &&
        <ResourceSelectorContainer>
            <ResourceSelector/>
        </ResourceSelectorContainer>}
    </Container>
})
