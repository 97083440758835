import React, {useState, useEffect, useContext, useRef} from "react";
import styled, {css} from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {useSVVStore} from "../../../StandardVisitView";
import {Icon} from "@blueprintjs/core";

const Container = styled.div`
  position: relative;
  display: grid;
  height: 168.4px;
  width: 119px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  background-color: white;
  overflow: hidden;${({selected}) => selected ? css`
  background-color: rgb(212, 235, 255);
  color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.26);
` : css`
  background-color: white;
`};
`
const OverlayLabelContainer = styled.div`
  position: absolute;
  bottom: 0;${({selected}) => selected ? css`
  background-color: rgb(91, 165, 229, 0.5);
  color: white;
` : css`
  background-color: rgb(245, 245, 245, 0.5);
`};

  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: center;
  padding: 8px;
  font-size: 12px;
`
const OverlayButtonContainer = styled.div`
  display: grid;
  place-content: center;
  padding: 1px;
`

export interface SVVADThumbnailProps {
    document
    onClick
    onDoubleClick
    selected: boolean
    dlDoc
    documentRef
    style?
    onKeyDown?
}

export const SVVADThumbnail = observer((props: SVVADThumbnailProps) => {
    let store = useSVVStore()
    let color = '#9b9b9b';
    let backgroundColor;
    if (props.selected) backgroundColor = 'blue'
    let type = props.document.type;

    let id = props.document.id;
    let state = store.getDocumentState(id)

    // console.log(`props.dlDoc?.downloadUrl`, props.dlDoc?.downloadUrl);
    return <Container onClick={props.onClick} onDoubleClick={props.onDoubleClick} selected={props.selected}
                      style={props.style} onKeyDown={props.onKeyDown}>
        {/*<label style={{color: color, fontSize: 12, margin: 2}}>{props.document.name}</label>*/}
        {props.dlDoc?.downloadUrl && <Document
          ref={props.documentRef}
          file={props.dlDoc.downloadUrl}
          onLoadSuccess={({numPages}) => {
              store.setDocumentState(id, {
                  loading: false,
                  numPages: numPages
              })
          }}
          onSourceError={() => {
              if (state.retries < 5){
                  (window as any).requestIdleCallback(() => {
                      props.documentRef.current?.loadDocument?.();
                      store.setDocumentState(id, {
                          error: false,
                          loading: true,
                          retries: (state.retries || 0) + 1
                      })
                      console.log(`state.retries`, state.retries);
                  })
              }else{
                  // File not found
              }

              store.setDocumentState(id, {
                  error: true,
                  loading: false
              })
              console.log('source error');
              console.log(`props.documentRef`, props.documentRef);

          }}
          onLoadError={() => {
              store.setDocumentState(id, {
                  error: false,
                  pdfError: true,
                  loading: false
              })

          }}
          error={'No es pot mostrar el document'}
          loading={''}
          className={state.loading ? 'bp3-skeleton' : ''}
        >
          <Page pageNumber={state.page} height={168.4}/>
        </Document>}
        <OverlayLabelContainer style={state.loading ? {backgroundColor: 'white'} : {}} selected={props.selected}>
            <OverlayButtonContainer onClick={(e) => {
                e.stopPropagation()
                state.page = (Math.max(1, state.page - 1));
            }}><Icon icon={'chevron-left'}/></OverlayButtonContainer>
            {((type && store.documentTypesMap[type].shortName) || type || '--').toUpperCase()}
            <OverlayButtonContainer onClick={(e) => {
                e.stopPropagation()
                state.page = (Math.min(state.numPages, state.page + 1));
            }}><Icon icon={'chevron-right'}/></OverlayButtonContainer>
        </OverlayLabelContainer>
    </Container>
})
