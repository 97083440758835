import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ALVStore} from "../../components/AppointmentListView/ALVStore";
import {isDev, paymentFormMap, stdCurrencyFormat} from "../../application/utils/utils";
import {useALVStore} from "../../components/AppointmentListView/AppointmentListView";
import * as moment from "moment";
import {Button, ButtonGroup, Tooltip} from "@blueprintjs/core";
import {DateInputControl} from 'react-c4';
import * as _ from "lodash";


const Container = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
`
const ButtonContainer = styled.div`
  padding-left: 10px
`

export interface AppointmentListPageHeaderProps {
    store: ALVStore
}

export const AppointmentListPageHeader = observer((props: AppointmentListPageHeaderProps) => {
    let store = props.store
    if (store == null) return null
    let filterForm = store.filterForm;

    return <Container
        onWheel={(e: WheelEvent) => {
            let deltaY = Math.round(Math.abs(e.deltaY) / e.deltaY)
            props.store.moveRange(deltaY)
        }}>
        <ButtonGroup>
            {store.orgsSel.items.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={()=>store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>

            <DateInputControl

                field={filterForm.$('startDate')} fill={true} placeholder={"Data document"}/>
            <DateInputControl

                field={filterForm.$('endDate')} fill={true} placeholder={"Data document"}/>

        <ButtonGroup>
            <Button icon={'arrow-left'}
                    onClick={(e) => {
                        store.moveRange(e.shiftKey ? -7 : -1)
                    }}
            />
            <Button
                onClick={(e) => {
                    store.goToDate(new Date())
                }}
            > Avui </Button>
            <Button icon={'arrow-right'}
                    onClick={(e) => {
                        store.moveRange(e.shiftKey ? 7 : 1)
                    }}
            />
        </ButtonGroup>

        <Button icon={'print'} onClick={() => {
            store.print()
        }}>Imprimir</Button>
        <ButtonContainer>
            <Button icon={'refresh'} onClick={() => {
                store.fetch()
            }}/>
        </ButtonContainer>
        <div style={{color: 'gray'}}>
            Botó dret sobre la llista per exportar a Excel
        </div>
    </Container>
})
