import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {TextArea, Button} from "@blueprintjs/core";
import {useRSCStore} from "../../../RSCMain";
import {MRFField} from "react-c4/src/forms/MRF";

const Container = styled.div`
    display: grid;
    
`

export interface RSCEdgeCreationProps {
    field: MRFField,
}

export const RSCEdgeCreation = observer((props: RSCEdgeCreationProps) => {
    let field = props.field;
    let store = useRSCStore();
    let inputRef = store.extCenterRef;
    let linkCommentField = field.$('linkComment');
    return /*<Container>
        <TextArea small {...{...linkCommentField.bind(), inputRef: inputRef}} placeholder={'Comentari'}/>
        <Button onClick={() =>{
            const submitLink = {
                medicalId: store.form.$('medical.setId').value,
                extCenterId: store.form.$('extCenter.setId').value
            };
            store.createNewLink();
            store.submit(submitLink);
        }}>Crear Asociació</Button>
    </Container>*/
})
