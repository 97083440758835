import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {RateFVStore} from "../../files-views/RateFileView/RateFVStore";
import {Button, ButtonGroup} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
`
const HideButtonContainer = styled.div`
    display: none
`
const Label = styled.div`
  font-size: 20px;
    margin: 5px;
    margin-bottom: 10px;
`
const ButtonContainer = styled.div`
  padding-left: 5px
`

const SpanContainer = styled.div`
    color: red;
  font-weight: bold;
`

export interface RatePageHeaderProps {
    store: RateFVStore
}

export const RatePageHeader = observer((props: RatePageHeaderProps) => {
    let store = props.store;
    if (store === null) return null;
    return <Container>
        <ButtonGroup>
            {store.orgsSel.items.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={()=>store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>

        <ButtonContainer>
            <Button icon={'refresh'}
                    onClick={async () => {
                        await store.refresh()
                    }}
            />
        </ButtonContainer>
        <Button icon={'upload'} onClick={() => {
            store.excelImportClick()
        }}>Importa Excel</Button>
        <SpanContainer>
            Format de l'arxiu .xlsx, no .xls. (Format: Codi | Nom Barem | Preu | Descripció ) La primera fila de la capçalera no s'importa. La descripció és opcional.
        </SpanContainer>
    </Container>
})
