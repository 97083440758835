import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useMIVStore} from "../../ManualInvoicingView";
import {invoiceNumberCell} from "../../../StandardVisitView/SVVMain/SVVPivateInvoicing/utils/invoiceNumberCell";
import {CellDoubleClickedEvent, ValueFormatterParams} from "ag-grid-community";
import {stdDateColumn, stdIntNumericColumn, stdNumericColumn} from "../../../../application/utils/utils";
import moment from "moment";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface InvoiceGridProps {

}

export const InvoiceGrid = observer((props: InvoiceGridProps) => {
    let store = useMIVStore();
    return <Container>
        <MasterLayout
            renderHeader={() => {
                return null
            }}
            actions={
                [
                    {
                        name: 'document',
                        iconName: 'add',
                        enabled: store.canCreateInvoice,
                        text: 'Crear factura',
                        callback: () => {
                            store.createInvoice()
                        }
                    },
                    {
                        name: 'Delete',
                        iconName: 'trash',
                        enabled: store.canEditInvoiceLine,
                        text: 'Borrar factura',
                        callback: () => {
                            if (store.invoiceLinesSel.selectedIds == null) return
                            store.deleteSelectedInvoice()
                        }
                    },
                ]}
            body={<C3AgGrid
                selection={store.invoicesSel}
                defaultColDef={{}}
                floatingFilter={false}
                columnDefs={[
                    // {
                    //     field: 'id',
                    //     headerName: 'Id',
                    //     width: 60
                    // },
                    {
                        field: 'invoiceNumber',
                        headerName: 'N. Factura',
                        ...invoiceNumberCell(),
                        width: 94,
                        editable: false,
                        onCellDoubleClicked: (e: CellDoubleClickedEvent) => {
                            // store.goToInvoice(e.data.invoiceId)
                        },
                        filter: 'agTextColumnFilter',
                        colSpan: function (params) {
                            return params.data.id === 'not-invoiced' ? 2 : 1;
                        }
                    }, {
                        field: 'count',
                        headerName: 'N. Línies',
                        width: 80,
                        filter: 'agTextColumnFilter',
                        ...stdIntNumericColumn()

                    }, {
                        field: 'total',
                        headerName: 'Total €',
                        width: 100,
                        filter: 'agTextColumnFilter',
                        ...stdNumericColumn()
                    }, {
                        field: 'modality',
                        headerName: 'Modalitat',
                        width: 100,
                        filter: 'agTextColumnFilter',
                    }
                ]}
                rowData={store.invoicesRes}
            />}/>

    </Container>
})
