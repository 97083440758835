import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from 'lodash'
import {SVVCalendar} from "./SVVCalendar";
import {useSVVStore} from "../../StandardVisitView";
import {SVVSelectResource} from "./SVVSelectResource";
import ErrorBoundary from "react-c4/src/app-shells/IATSStyle/page-templates/ErrorBoundary";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-gap: 8px;
    justify-items: stretch;
    //height: 280px;
    min-height: 300px;
    //border-bottom: 1px solid black;
    //border-left: 1px solid black;
    overflow: hidden;
`
const CollapsedContainer = styled.div`
    background-color: #c2d5e3;
    border-radius: 3px;
    padding: 4px;
    //color: white;
    //font-weight: bold;
`

export interface SVVCalendarsAreaProps {

}

export const SVVCalendars = observer((props: SVVCalendarsAreaProps) => {
    let store = useSVVStore()
    let calendars = [
        {
            name: 'RM-1',
            tests: ['Prova1',
                // 'Prova 2'
            ]
        },
        // {name: 'RM-2', tests: ['Prova 3']}, {
        //     name: 'RX',
        //     tests: ['Prova 4']
        // }
    ]
    // let visitLines = store.form.$('visitLines').value;
    let values = store.form.values();
    // if(store.calendarCollapsed) return <CollapsedContainer onClick={() => store.calendarCollapsed = false}> Data:
    // --</CollapsedContainer>
    if (store.switchingVisitSelection) return <Container/>
    return <Container>
        {/*{JSON.stringify(store.selectedVisitIds)}*/}
        {/*{JSON.stringify(store.selectedResources, null, 2)}*/}
        {/*{JSON.stringify(store.form.$('visitLines').value, null, 2)}*/}
        {store.orderedSelectedResources().map((resource, i) => {
            let resourceId = resource.id
            let involvedVisitsIds = store.selectedResources[resourceId] as number[];
            if (resourceId == null) {
                return <SVVSelectResource visitIds={involvedVisitsIds}/>
            }
            return <ErrorBoundary>
                <SVVCalendar key={resourceId} resourceId={resourceId} involvedVisitsIds={involvedVisitsIds || []} i={i}/>
            </ErrorBoundary>
        })}
        {/*{_.uniqBy(store.visitLinesSel.selFields, f => f.$('resource').value?.id).map((f, i) => {*/}
        {/*    return <SVVCalendar key={i} i={i} field={f} />*/}
        {/*})}*/}
    </Container>
})
