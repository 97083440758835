import {C3, C3Selection, IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {observable} from "mobx";
import {GridApi} from "ag-grid-community";

export class ULEStore {
    appStore: AppStore
    pageContext

    gridApi: GridApi

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.logsSel = new C3Selection({})
        await this.fetch()
    }

    @observable.shallow
    logsRes

    logsSel: C3Selection

    async fetch() {
        this.logsRes = await C3.instance.client.fetcher.fetch('/user-log-entry/logs', {})
        this.logsSel.itemsRef.current = this.logsRes;
        this.scrollToLast()
        return this.logsRes
    }

    scrollToLast(){
        let api = this.gridApi;
        api.ensureIndexVisible(this.logsRes?.length - 1)
    }
    onGridInit() {

    }
}
