import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRateFVStore} from "../RateFileView";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import { C3AgGrid } from "react-c4";
import {CellValueChangedEvent, ICellRendererParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import {Checkbox, NonIdealState} from "@blueprintjs/core";
import {formatFullName, isDev, stdBoolColumn, stdNumericColumn} from "../../../application/utils/utils";
import * as _ from "lodash";

const Container = styled.div`
    display: grid;
    
`

export interface RateFileViewRatesheetGridProps {

}

export const RateFileViewRatesheetGrid = observer((props: RateFileViewRatesheetGridProps) => {
    let store = useRateFVStore()
    return <Container>
        <MasterLayout
            renderHeader={() => null}
            actions={[

/*                {
                    name: 'delete',
                    iconName: 'trash',
                    text: 'Esborra',
                    confirmation: true,
                    confirmationText: `Estàs segur que vols eliminar el full de barems?`,
                    enabled: store.rateSheetsSel.selectedId != null,
                    callback: async () => {
                        let idx = store.rateSheetsSel.selectedIndex
                        await store.deleteSelectedRateSheet();
                        store.rateSheetsSel.selectIndex(idx);
                    }
                },*/
                {
                    name: 'showActives',
                    iconName: 'strikethrough',
                    intent: store.showInactiveInsCompanies ? "success" : "none",
                    text: store.showInactiveInsCompanies? 'Mostra mútues només actives' : 'Mostra mútues inactives',
                    callback: () => {
                        store.showInactiveInsCompanies = !store.showInactiveInsCompanies
                    }
                },
            ]}
            body={
                <C3AgGrid
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        suppressMenu: true
                    }}
                    columnDefs={[
                        /*{
                            field: 'orgs',
                            headerName: 'Organitzacions',
                            editable: true,
                            floatingFilter: true,
                            valueFormatter: (params: ValueFormatterParams) => {
                                if (_.isEmpty(params.value)) return '-'
                                if (_.isArray(params.value)) params.value = params.value[0]
                                return params?.value.shortName
                            },
                        },*/
                        {
                            field: 'name',
                            headerName: 'Nom',
                            editable: true,
                            width: 300,
                            filter: 'agTextColumnFilter',

                        },
                        {
                            field: 'isActive',
                            headerName: 'Actiu',
                            ...stdBoolColumn(),
                            width: 40
                        },
                        {
                            field: 'insCompanies',
                            headerName: 'Mútues Assignat',
                            editable: true,
                            width: 200,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'ordinal',
                            headerName: 'Ordinal',
                            type: ['numericColumn'],
                            editable: true,
                            width: 100,
                            ...stdNumericColumn(),
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'count',
                            headerName: 'Tarifes',
                            type: ['numericColumn', 'rightAligned'],
                            editable: true,
                            width: 90,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'description',
                            headerName: 'Descripció',
                            editable: true,
                            width: 400,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
                                    params.api.exportDataAsExcel({
                                        fileName: `llistat-fulles-barems-${orgName}`,
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'isActive') {
                                                return data.isActive == true ? 'X' : ''
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    rowData={store.rateSheetsRes}
                    selection={store.rateSheetsSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        await store.saveRateSheet({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                    }}

                />
            }
        />
    </Container>
})
