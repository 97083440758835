import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ICIStore} from "./ICIStore";
import {useC3Store} from "react-c4";
import { ICIMain } from "./ICIMain/ICIMain";

const Container = styled.div`
    display: grid;
   height: 100%;
   padding: 8px;
`


export const ICIContext = React.createContext(null as ICIStore)
export const useICIStore = () => useContext(ICIContext)

export interface InsCompanyInvoicingViewProps {
    pageContext?
    onStoreReady: (s: ICIStore) => any
}

export const InsCompanyInvoicingView = observer((props: InsCompanyInvoicingViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new ICIStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <ICIContext.Provider value={store}>
        <Container>
            <ICIMain pageContext={props.pageContext}/>
        </Container>
    </ICIContext.Provider>
})
