import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useMIVStore} from "../../ManualInvoicingView";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {stdCurrencyFormat} from "../../../../application/utils/utils";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
`

const FirstLineContainer = styled.div`
  display: grid;
  justify-items: end;
`

const SecondLineContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  font-weight: bold;
`

export interface InvoiceInfoProps {

}

export const InvoiceInfo = observer((props: InvoiceInfoProps) => {
    let store = useMIVStore()
    if (store == null || store.invoicesSel == null || store.invoicesSel.selectedId == null) return null
    let subtotal = store.invoiceRes?.total || store.invoicesSel.selectedItem.total;
    return <Container>
        <FirstLineContainer>
            <ButtonGroup>
                <Button
                    onClick={() => requestAnimationFrame(() => store.viewMode = 'edit')}
                    active={store.effectiveViewMode == "edit"}
                    icon={'edit'}
                    disabled={!store.canEdit}
                >
                    Edita
                </Button>
                <Button
                    icon={'eye-open'}
                    onClick={() => requestAnimationFrame(() => store.viewMode = 'preview')}
                    active={store.isPreview}
                    disabled={!store.canPreview}
                >
                    Previsualitza
                </Button>
            </ButtonGroup>
        </FirstLineContainer>
        <SecondLineContainer>
            <label>Total: {stdCurrencyFormat(subtotal)} €</label>
        </SecondLineContainer>
    </Container>
})
