import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {IPPreviewGrid} from "./IPPreviewGrid";
import {IPPreviewDocument} from "./IPPreviewDocument";

const Container = styled.div`
    display: grid;
    
`

export interface IPPreviewProps {

}

export const IPPreview = observer((props: IPPreviewProps) => {
    return <Container>
        {true ? <IPPreviewGrid/> : <IPPreviewDocument/>}
    </Container>
})
