import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ICIStore} from "../../components/InsCompanyInvoicingView/ICIStore";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {DateInputControl} from "react-c4";
import {stopPropagationForAgGrid} from "ag-grid-community/dist/lib/utils/event";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  grid-template-columns: repeat(4, auto) 1fr auto;
`

const LabelContainer = styled.div`
  font-size: 16px;
  align-self: center;
`

export interface InsCompanyInvoicingPageHeaderProps {
    store: ICIStore
}

export const InsCompanyInvoicingPageHeader = observer((props: InsCompanyInvoicingPageHeaderProps) => {
    if (props.store == null) return null
    let store = props.store
    let filterForm = store.filterForm
    return <Container>
        {/*<Button icon={'application'}*/}
        {/*        active={props.store.allInsCompanies}*/}
        {/*        onClick={() => props.store.allInsCompanies = !props.store.allInsCompanies}*/}
        {/*>*/}
        {/*    Totes les mútues*/}
        {/*</Button>*/}
        {/*<Button icon={'document-open'}*/}
        {/*        active={props.store.pendingLinesOnly}*/}
        {/*        onClick={() => props.store.pendingLinesOnly = !props.store.pendingLinesOnly}*/}
        {/*>*/}
        {/*    No facturades*/}
        {/*</Button>*/}
        <ButtonGroup>
            {store.orgsSel.items?.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={() => store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>
        {(store.assistantStep >= 3) ? null : <>
            <DateInputControl field={filterForm.$('startDate')} fill={true} placeholder={'Inici període'}/>
            <DateInputControl field={filterForm.$('endDate')} fill={true} placeholder={'Fi període'}/>
            {store.arePeriodDatesDirty && <Button icon={'undo'} onClick={() => store.resetPeriodDates()}/>}
        </>}
        <ButtonGroup>
            {store.typeSel.items.map(it => {
                let selected = store.typeSel.isSelected(it.id);
                return <Button
                    // intent={'success'}
                    disabled={store.assistantMode || store.invoiceStatus >= 1}
                    active={selected}
                    icon={selected ? 'tick' : null}
                    onClick={() => store.typeSel.selectId(it.id)}
                >
                    {it.name}
                </Button>
            })}
        </ButtonGroup>
        <div/>
        {store.assistantMode ? <LabelContainer>Desactivar mode asistent:</LabelContainer> :
            <LabelContainer>Activar mode asistent:</LabelContainer>}
        <Button
            icon={'flow-linear'}
            active={store.assistantMode}
            onClick={() => {

                store.assistantMode = !store.assistantMode
                if (store.assistantMode) {
                    // On init
                    store.insCompaniesSel.clearSelection()
                    // store.invoicesSel.clearSelection()
                    store.invoiceRes = null
                    store.assistantStep = 0
                } else {
                    // On cancel
                    store.assistantStep = 0
                }
            }}
        >Mode assistent</Button>
        {store.assistantMode && <div style={{
            fontSize: 16,
            display: "grid",
            alignItems: "center",
            fontWeight: "bold"
        }}>{store.assistantStep + 1}. {store.assistantMessage}</div>}
        <Button icon={'refresh'}
                loading={store.loading.refreshing}
                disabled={store.loading.refreshing}
                onClick={() => {
                    store.refresh()
                }}/>
    </Container>
})
