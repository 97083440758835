import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {UserFilePageHeader} from "./UserFilePageHeader";
import {UserFVStore} from "../../files-views/UserFileView/UserFVStore";
import {UserFileView} from "../../files-views/UserFileView/UserFileView";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface UserFilePageProps {
     pageContext
}

export const UserFilePage = page(observer((props: UserFilePageProps) => {
    let [store, setStore] = useState(null as UserFVStore);

    return <Page
        title={'Usuaris'}
        subtitle={`Manteniment d'usuaris`}

        icon={'document'}
        attributes={[
            {
                name: 'Total',
                value: 15, // TODO
                unit: 'usuaris'
            }
        ]}
        renderCustomHeaderArea={() => {
            return <UserFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <UserFileView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
