import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
// import Editor, {DiffEditor, useMonaco, loader} from "@monaco-editor/react";
import {observer} from 'mobx-react'
import {useFFVStore} from "../FeatureFileView";
import ErrorBoundary from "react-c4/src/app-shells/IATSStyle/page-templates/ErrorBoundary";

const Container = styled.div`
  display: grid;

`

export interface FFVFeatureEditorProps {

}

export const FFVFeatureEditor = observer((props: FFVFeatureEditorProps) => {
    let store = useFFVStore()

    function handleEditorChange(value, event) {
        // here is the current value
        // console.log(`value, event`, value, event);
        store.handleEditorChange(value, event)
    }

    function handleEditorDidMount(editor, monaco) {
        store.monaco = monaco
        store.setupMonaco(editor, monaco);

    }

    function handleBeforeMount(monaco) {
        store.monacoBeforeMount(monaco)
        console.log("beforeMount: the monaco instance:", monaco);
    }

    function handleEditorValidation(markers) {
        // model markers
        // markers.forEach(marker => console.log('onValidate:', marker.message));
    }

    return (
        <ErrorBoundary>
            {/*<Editor*/}
            {/*    height="90vh"*/}
            {/*    defaultLanguage="typescript"*/}
            {/*    // defaultValue={store.defaultValue}*/}
            {/*    value={store.featuresSel.selectedItem?.code}*/}
            {/*    path={(store.featuresSel.selectedId || 'blank').toString()}*/}
            {/*    options={{}}*/}
            {/*    theme={'vs-dark'}*/}
            {/*    onChange={handleEditorChange}*/}
            {/*    onMount={handleEditorDidMount}*/}
            {/*    beforeMount={handleBeforeMount}*/}
            {/*    onValidate={handleEditorValidation}*/}
            {/*/>*/}
        </ErrorBoundary>
    );
})
