import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";

const Container = styled.div`
    display: grid;
    grid-auto-columns: auto 1fr auto;
`

export interface IPGridProps {

}

export const IPGrid = observer((props: IPGridProps) => {
    return <Container>

    </Container>
})
