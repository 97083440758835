import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {Observer, observer} from 'mobx-react'
import {SVVCalendars} from "./SVVCalenadars/SVVCalendars";
import {SVVLines} from "./SVVLines/SVVLines";
import {PatientOverview} from "../../PatientOverview/PatientOverview";
import {useSVVStore} from "../StandardVisitView";
import {SVVVisitHeader} from "./SVVVisitHeader/SVVVisitHeader";
import {SVVVisitFooter} from "./SVVVisitFooter/SVVVisitFooter";
import {SVVDateTimeFilters} from "./SVVDateTimeFilters/SVVDateTimeFilters";
import {Button, Icon} from "@blueprintjs/core";
import {SVVPrivateInvoicePreview} from "./SVVPivateInvoicing/SVVPrivateInvoicePreview/SVVPrivateInvoicePreview";
import {SVVPrivateInvoicingPanel} from "./SVVPivateInvoicing/SVVPrivateInvoicingPanel/SVVPrivateInvoicingPanel";
import * as _ from "lodash";
import {SVVAttachDocumentsPanel} from "./SVVAttachDocuments/SVVAttachDocumentsPanel";
import {SVVDelivery} from "./SVVDelivery/SVVDelivery";
import {SVVPrintCanvas} from "./SVVPrintCanvas/SVVPrintCanvas";

const Container = styled.div`
    display: grid;
    grid-template-columns: 3fr auto;
    grid-gap: 8px;
`
const RightContainer = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-template-rows: auto 1fr;
`
const MinimizedRightContainer = styled.div`
    display: grid;
    place-content: start;
    grid-gap: 8px;
`
const MinimizedRightContainerItem = styled.div`
    display: grid;
    place-content: start;
    grid-gap: 8px;
    padding: 4px;
    margin: -4px;
    padding-right: 10px;
    margin-right: -10px;
`
const TitleContainer = styled.div`
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    user-select: none;
    line-height: 65%;
`
const CollapseIconContainer = styled.div`
    line-height: 0%;
`


const LeftContainer = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr auto;
   
    grid-gap: 8px;
`

export interface SVVMainProps {
    pageContext
}

export const SVVMain = observer((props: SVVMainProps) => {
    let store = useSVVStore()
    let report = {
        sel: store.visitLinesSelKey,
        // value: store.$selVisitLine?.values(),
        // fields: store.$visitLines.values()
    };
    let headerElement = <SVVVisitHeader/>
    if (store.isPrivateInvoicingMode)
        headerElement = <SVVPrivateInvoicingPanel/>

    let rightPanelElement;
    if (store.isPrintCanvasMode) {
        rightPanelElement = <SVVPrintCanvas/>
    } else if (store.isAttachDocumentsMode) {
        rightPanelElement = <SVVAttachDocumentsPanel/>;
    } else if (store.isPrivateInvoicingMode) {
        rightPanelElement = <SVVPrivateInvoicePreview/>;
    } else if (store.isDeliveryMode) {
        rightPanelElement = <SVVDelivery/>
    }


    let patientForm = store.patientForm;
    let isCollapsed = store.patientSidebarCollapsed;
    return <Container>
        <LeftContainer>
            {/*<SVVCalendars/>*/}
            {headerElement}
            <SVVLines/>
            <SVVVisitFooter/>
        </LeftContainer>
        {((store.isPrivateInvoicingMode && !store.isPrivateInvoicePreviewCollapsed)
            || store.isAttachDocumentsMode
            || store.isDeliveryMode
            || store.isPrintCanvasMode
        ) ?
            rightPanelElement
            :
            (!isCollapsed ?
                    <RightContainer>
                        {/*<SVVDateTimeFilters/>*/}
                        <Button onClick={() => store.patientSidebarCollapsed = true} icon={"double-chevron-right"}/>

                        <PatientOverview
                            onStoreReady={(patientOverviewStore) => store.setPatientOverviewStore(patientOverviewStore)}
                            pageContext={''} featureMode={"reception"} form={patientForm}/>
                    </RightContainer>
                    :
                    <MinimizedRightContainer>
                        <MinimizedRightContainerItem onClick={() => store.patientSidebarCollapsed = false}>
                            <CollapseIconContainer>
                                <Icon iconSize={14} icon={'double-chevron-left'}/>
                            </CollapseIconContainer>
                            <TitleContainer>
                                {patientForm.$('name').value} {patientForm.$('surname1').value} {patientForm.$('surname2').value}
                            </TitleContainer>
                        </MinimizedRightContainerItem>

                        {store.isPrivateInvoicingMode &&
                        <MinimizedRightContainerItem onClick={() => store.isPrivateInvoicePreviewCollapsed = false}>
                          <CollapseIconContainer>
                            <Icon iconSize={14} icon={'double-chevron-left'}/>
                          </CollapseIconContainer>

                          <Observer>
                              {() => {
                                  return <TitleContainer> {['Factura privada', store.potentialLinesToAddToInvoice > 0 && `(${store.potentialLinesToAddToInvoice})`]
                                      .filter(s => !_.isEmpty(s)).join(' ').toUpperCase()}
                                  </TitleContainer>
                              }}
                          </Observer>

                        </MinimizedRightContainerItem>}


                    </MinimizedRightContainer>
            )


        }

        {/*<div style={{height: 100, fontSize: 12}}>*/}
        {/*    <pre>{JSON.stringify(report, null, 2)}</pre>*/}
        {/*</div>*/}
    </Container>
})
