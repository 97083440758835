import {C3SchemaInfo} from "react-c4";
import {filesLocalSchema} from "./local-entities/files-local.schema";
import {contactsFilterSchema} from "./filters/contacts-filter.schema";
import {productSchema} from "./entities/packages/products/product.schema";
import {businessLineSchema} from "./entities/packages/products/files/business-line.schema";
import {deliveryItemTypeSchema} from "./entities/packages/products/files/delivery-item-type.schema";
import {productBodyPartSchema} from "./entities/packages/products/files/product-body-part.schema";
import {productFamilySchema} from "./entities/packages/products/files/product-family.schema";
import {productSubfamilySchema} from "./entities/packages/products/files/product-subfamily.schema";
import {rateSchema} from "./entities/packages/rates/rate.schema";
import {rateSheetSchema} from "./entities/packages/rates/rate-sheet.schema";
import {resourceSchema} from "./entities/packages/resources/resource.schema";
import {resourceTypeSchema} from "./entities/packages/resources/files/resource-type.schema";
import {waitingRoomSchema} from "./entities/packages/organizations/files/waiting-room.schema";
import {facilitySchema} from "./entities/packages/organizations/facility.schema";
import {contactSchema} from "./entities/packages/contacts/contact.schema";
import {specialtySchema} from "./entities/packages/contacts/files/specialty.schema";
import {documentSchema} from "./entities/packages/documents/document.schema";
import {documentTypeSchema} from "./entities/packages/documents/document-type.schema";
import {invoiceSchema} from "./entities/packages/invoices/invoice.schema";
import {invoiceLineSchema} from "./entities/packages/invoices/invoice-line.schema";
import {orgSchema} from "./entities/packages/organizations/org.schema";
import {patientSchema} from "./entities/packages/patients/patient.schema";
//import {visitLinePaymentSchema} from "./entities/packages/payments/visit-line-payment.schema";
import {smallCounterBoxSchema} from "./entities/packages/payments/files/small-counter-box.schema";
import {referrerMatrixSchema} from "./entities/packages/referrers/referrer-matrix.schema";
import {episodeSchema} from "./entities/packages/visits/episode.schema";
import {visitSchema} from "./entities/packages/visits/visit.schema";
import {visitLineSchema} from "./entities/packages/visits/visit-line.schema";
import {visitStatusSchema} from "./entities/packages/visits/files/visit-status.schema";
import {bookVisitViewSchema} from "./screens/book-visit-view.schema";
import {productRatesRelViewSchema} from "./screens/product-rates-rel-view.schema";
import {patientInsCompanySchema} from "./entities/packages/patients/patient-ins-company.schema";
import {privateInvoicingViewSchema} from "./screens/private-invoicing-view.schema";

export let c3Schema: C3SchemaInfo = {
    patient: {
        name: 'patient',
        fields: {
            id: {
                name: 'id',
                label: 'ID',
                value: null,
            },
            historyNumber: {
                name: "name",
                label: "Número d'història",
                placeholder: "Número d'història",
                rules: "required|string|between:2,10",
                value: "",
            }, surname1: {
                name: "surname1",
                label: "Primer cognom",
                placeholder: "Nom",
                rules: "required|string|between:2,25",
                value: "",
            }, surname2: {
                name: "surname2",
                label: "Segon cognom",
                placeholder: "Nom",
                rules: "string",
                value: "",
            }, name: {
                name: "name",
                label: "Nom",
                placeholder: "Nom",
                rules: "required|string|between:2,25",
                value: "",
            },
            email: {
                name: "email",
                label: "Email",
                placeholder: "Nom",
                rules: "string|email",
                value: "",
            }, nif: {
                name: "nif",
                label: "NIF",
                placeholder: "Nom",
                rules: "string",
                value: "",
            }, cip: {
                name: "cip",
                label: "CIP",
                placeholder: "CIP",
                rules: "string",
                value: "",
            },
            mobile: {
                label: "Mòbil",
                rules: "string",
            },
            phone2: {
                name: "phone2",
                label: "Telèfon",
                placeholder: "Telèfon",
                rules: "string",
                value: "",
            },
            address: {
                label: 'Adreça'
            }, postalCode: {
                label: 'CP'
            }, city: {
                label: 'Població'
            },
            birthDate: {
                name: "phone2",
                label: "Data naixement",
                placeholder: "Data naixement",
                rules: "date",
            },
            gender: {
                name: "gender",
                label: "Gènere",
                placeholder: "Gènere",
                rules: "string|email",
                value: "",
            },
            comment: {
                name: "comment",
                label: "Comentari",
                placeholder: "Comentari",
                rules: "string|email",
                value: "",
            },

            mutua: {
                name: "mutua",
                type: 'mutua',
                label: "Mutua per defecte",
                placeholder: "Mutua",
            },
            mutuaNumber: {
                name: "mutuaNumber",
                label: "Número de pòlissa",
                placeholder: "Número de pòlissa",
            },
            defaultPaymentMethod: {
                name: "paymentMethod",
                label: "Pagament per defecte",
                placeholder: "Pagament",
                output: v => v?.id
            },
            language: {
                name: "language",
                label: "Idioma",
                placeholder: "Idioma",
            },
            sex: {
                label: 'Sexe',
                output: v => v?.id
            }

        },
        // fields2: {
        //     id: {},
        //     historyNumber: {},
        //     isActive: {},
        //     isHistorical: {},
        //     isFromWebsite: {},
        //     cip: {},
        //     name: {},
        //     surname1: {},
        //     surname2: {},
        //     mobile: {},
        //     phone2: {},
        //     email: {},
        //     birthDate: {},
        //     nif: {},
        //     gender: {},
        //     status: {},
        //     mutua: {relation: true, type: 'mutua'},
        //     mutuaNumber: {},
        //     invoices: {relation: true, type: 'invoice', multi: true},
        //     appointments: {relation: true, type: 'appointment', multi: true},
        //     fileReferences: {relation: true, type: 'fileReference', multi: true},
        //     createdAt: {},
        //     updatedAt: {},
        //     fullName: {
        //         computed: true
        //     },
        // }
    },
    appointment: {
        name: 'appointment',
        fields: {
            id: {},
            patient: {type: 'patient'},
            episodeNumber: {
                label: "Num. Episodi",
            },
            endDate: {},
            duration: {
                value: 30
            },
            estimatedDuration: {},
            status: {},
            machine: {
                label: 'Màquina',
                type: 'machine',
            },
            testType: {
                label: 'Prova 1',
                type: 'test-type',
            }, testType2: {
                label: 'Prova 2',
                type: 'test-type',
            }, testType3: {
                label: 'Prova 3',
                type: 'test-type',
            }, testType4: {
                label: 'Prova 4',
                type: 'test-type',
            }, testType5: {
                label: 'Prova 5',
                type: 'test-type',
            },
            realDuration: {},
            watingTime: {},
            assignedPrice: {},
            price: {
                label: 'Preu',
            },
            administrativeComments: {
                label: 'Num. Autorització',
            },
            authorizationNumber: {
                label: 'Num. Autorització',
            },
            technicianComments: {},
            ticketNumber: {},
            pickUpDate: {},
            technician: {
                type: 'technician'
            },
            visitReason: {},
            numPlates: {},
            closed: {},
            fileReferences: {},
            createdAt: {},
            updatedAt: {},
            referral: {
                label: "Referent",
                type: 'referral'
            },
            externalCenter: {
                label: "Centre referent",
                type: 'external-center'

            },
            appointmentDate: {},

            paymentMethod: {
                label: "Forma de pagament",
            },
            slotIndex: {},
            slot: {},

            // Other fields - to classify
            mutua: {
                type: 'mutua'
            },

            //Temp
            template: {
                label: 'Plantilla'
            },
            date: {
                label: 'Data document'
            }

        }
    },
    attachment: {
        name: 'attachment',
        fields: {}
    },
    machine: {
        name: 'machine',
        fields: {
            id: {},
            name: {
                label: "Nom",
            },
            color: {},
            description: {
                label: 'Descripció'
            },
        }
    },
    'test-type': {
        fields: {
            name: {}
        }
    },
    'business-line': {
        name: 'business-line',
        fields: {
            id: {},
            name: {
                label: 'Nom'
            }
        }
    },


    // Contacts
    ...contactSchema,
    // Contacts files
    ...specialtySchema,
    // Documents
    ...documentSchema,
    ...documentTypeSchema,
    // Documents files
    // Invoices
    ...invoiceSchema,
    ...invoiceLineSchema,
    // Invoices files
    // Organizations
    ...orgSchema,
    ...facilitySchema,
    // Organizations files
    ...waitingRoomSchema,
    // Patients
    ...patientSchema,
    ...patientInsCompanySchema,
    // Payments
    //...visitLinePaymentSchema,
    // Payments files
    ...smallCounterBoxSchema,
    // Products
    ...productSchema,
    // Products Files
    ...businessLineSchema,
    ...deliveryItemTypeSchema,
    ...productBodyPartSchema,
    ...productFamilySchema,
    ...productSubfamilySchema,
    // Rates
    ...rateSchema,
    ...rateSheetSchema,
    // Rates files
    // Referrers
    ...referrerMatrixSchema,
    // Referrers files
    // Resources
    ...resourceSchema,
    // Resources files
    ...resourceTypeSchema,
    // Visits
    ...episodeSchema,
    ...visitSchema,
    ...visitLineSchema,
    // Visits files
    ...visitStatusSchema,


    // Non API entities

    'filter-contacts': {
        local: true,
        localData: [],
        fields: {
            'contact-types': {}
        }
    },
    // Locals
    ...filesLocalSchema,

    // Filters
    ...contactsFilterSchema,

    // Screens
    ...bookVisitViewSchema,
    ...productRatesRelViewSchema,
    ...privateInvoicingViewSchema,
}
// console.log(`c3Schema`, c3Schema);
