import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {PFVStore} from "../../components/PatientFusionView/PFVStore";
import {Button, Icon} from '@blueprintjs/core'

const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
`
const SpanContainer = styled.span`
    font-size: 16px;
  align-self: center;
`

export interface PatientFusionPageHeaderProps {
    store: PFVStore
}

export const PatientFusionPageHeader = observer((props: PatientFusionPageHeaderProps) => {
    let store = props.store;
    if(store == null) return null
    return <Container>
        <Button
            style={{margin: 10}} icon={'refresh'} onClick={()=>{store.refresh()}}>
        </Button>
        <SpanContainer>Desmarcar columna Dup. <Icon icon={'tick-circle'} /> per treure un pacient de les dues llistes.</SpanContainer>
    </Container>
})
