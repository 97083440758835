import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button} from "@blueprintjs/core";
import {ProcessTabLayout} from "../components/ProcessTabLayout";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import ReactSelect from "react-select";
import { Field, SelectControl } from "react-c4";

const Container = styled.div`
    display: grid;
    
`

export interface JustificantTabProps {
    store: PatientOverviewStore

}

export const JustificantTab = observer((props: JustificantTabProps) => {
    return <ProcessTabLayout title={'Imprimeix justificant'} buttonsFragment={<React.Fragment>
        <Button onClick={() => props.store.closeAdditionalTab()}>Sortir</Button>
        <Button icon={'eye-open'}>Previsualitza</Button>
        <Button icon={'print'}>Impirmir justificant</Button>
    </React.Fragment>}>
        <Container>
            <div style={{width: 140}}>
                <ReactSelect
                    placeholder={'Idioma'}
                    defaultValue={{
                        id: 1,
                        name: 'Català'
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={[
                        {
                            id: 1,
                            name: 'Català'
                        }, {
                            id: 2,
                            name: 'Castellà'
                        }, {
                            id: 3,
                            name: 'Anglès'
                        }, {
                            id: 4,
                            name: 'Francès'
                        }, {
                            id: 5,
                            name: 'Alemany'
                        },
                    ]}
                />
            </div>
            <Field name={'template'} c={SelectControl} options={[{id: 1, name: 'Plantilla 1'}, {id: 2, name: 'Plantilla 2'}]}/>
            {/*<div>Plantilla 1 / Plantilla 2</div>*/}
        </Container>
    </ProcessTabLayout>
})
