import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {StandardVisitView} from "../../components/StandardVisitView/StandardVisitView";
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {SVVStore} from "../../components/StandardVisitView/SVVStore";
import {IObservableValue, observable} from "mobx";
import {NonIdealState} from "@blueprintjs/core";
import {BookVisitsPageHeader} from "./BookVisitsPageHeader";

const Container = styled.div`
    display: grid;
    height: 100%;
    
`

export interface BookVisitsPageProps {

}

export const BookVisitsPage = page(observer((props: BookVisitsPageProps) => {
    // let svvStore: IObservableValue<SVVStore> = observable.box(null);
    let [svvStore, setSvvStore] = useState(null as SVVStore);
    let patientName = [svvStore?.patientForm?.$('name').value, svvStore?.patientForm?.$('surname1').value, svvStore?.patientForm?.$('surname2').value ].join(' ');
    return <Page
        title={'Cites i visites'}
        subtitle={patientName}
        // icon={'inbox'}z
        attributes={[
            {
                name: 'Usuari',
                value: svvStore && svvStore.appStore.auth.loggedInUser?.fullName
            }, {
                name: 'Total',
                value: svvStore?.svvRes?.visits?.length,
                unit: 'visites'
            }
        ]}
        fullCustomHeaderArea
        renderCustomHeaderArea={() => {
            return <BookVisitsPageHeader store={svvStore}/>
        }}
        renderBody={({pageContext}) => {
            let patientId = pageContext.args.patientId;
            if(patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <StandardVisitView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setSvvStore(s);
                }} patientId={patientId} {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
