import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const deliveryItemTypeSchema: C3SchemaInfo = {
    'delivery-item-type': {
        fields: {
            id: {},
            name: {
                label: 'Nom'
            },
            products: {
                type: 'product'
            }
        }
    }
}
