import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3} from "react-c4";
import {Button, ButtonGroup, Tag} from "@blueprintjs/core";
import {Intent} from "@blueprintjs/core/lib/esm/common/intent";
import {formatFormalName, formatFullName, isDev, useAppStore} from "../../utils/utils";
import * as _ from 'lodash'

const Container = styled.div`
   width: 320px;
   height: 120px + 12*2px;
   padding: 16px;
   display: grid;
   grid-template-columns: auto 1fr;
   grid-template-rows: auto auto;
   grid-template-areas:
   "Picture Details"
   "Picture Button";
   grid-gap: 16px;
`
const PictureArea = styled.div`
    grid-area: Picture;
    background-image: url('images/Sarah-User.png');
    background-size: contain;
    background-color: rgba(125, 188, 255, 0.6);
    //border: 3px dashed rgb(23, 69, 221);
    border-radius: 100px;
    height: 120px;
    width: 120px;
`
const DetailsArea = styled.div`
    grid-area: Details;
    display: grid;
    grid-gap: 4px;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
    "Name"
    "Role"
    "Account"
    ".";
`
const NameArea = styled.div`
    grid-area: Name;
    text-transform: uppercase;
    font-weight: bold
`

const RolesArea = styled.div`
    grid-area: Account;
    font-size: 11px;
    color: gray;
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

`
const RoleArea = styled.div`
    grid-area: Role;
    color: lightgray;
    font-size: 11px;
`
const ButtonArea = styled.div`
    grid-area: Button;
`

export interface UserComponentProps {
    navigate
}

export const UserComponent = observer((props: UserComponentProps) => {
    let appStore = useAppStore()
    // let auth = C3.instance.auth
    // let token = auth.keycloak.tokenParsed;
    // @ts-ignore
    // let fullName = [token?.given_name, token?.familyName].filter(s => s != null).join(' ')
    // @ts-ignore
    // let email = [token?.email].filter(s => s != null).join(' ')
    let loggedInUser = appStore.auth.loggedInUser;
    let email = loggedInUser?.email
    return (
        <Container>
            <PictureArea/>
            <DetailsArea>
                <NameArea>
                    {formatFullName(loggedInUser) || 'Nom Complet'}
                </NameArea>
                <RoleArea>
                    <ButtonGroup>
                        {appStore.orgsSel.items.map(it => {
                            let selected = appStore.orgsSel.isSelected((it.id));
                            return <Button
                                key={it.id}
                                minimal
                                small
                                active={selected}
                                onClick={() => appStore.orgsSel.selectId(it.id)}
                            >
                                {it.shortName}
                            </Button>
                        })}
                    </ButtonGroup>
                </RoleArea>
                {<RolesArea>
                    {_.entries(loggedInUser?.roles).map(([roleK, roleV]) => {
                        return <Tag minimal key={roleK}>{roleV.displayName}</Tag>
                    })}
                </RolesArea>}

            </DetailsArea>
            <ButtonArea>
                <Button
                    onClick={() => {
                        props.navigate({to: 'profile', inNewTab: true, focusNewTab: true})
                    }}
                    icon={'arrow-right'} intent={Intent.PRIMARY}>
                    Anar al perfil
                </Button>
            </ButtonArea>
        </Container>
    );

})
