import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {C3AgGrid, MasterLayout} from "react-c4";
import {CellValueChangedEvent, IRichCellEditorParams, ValueFormatterParams} from "ag-grid-community";
import {SelectCE} from "../../../../../application/utils/components/SelectCE";

const Container = styled.div`
    display: grid;
    height: 300px;
    grid-template-rows: auto 1fr;
`

export interface InsCompaniesTabProps {
    store: PatientOverviewStore
}

export const InsCompaniesTab = observer((props: InsCompaniesTabProps) => {
    let store = props.store
    return <Container>
        Nota: Abans de posar mútua pulsi a l'esquerra sobre el pacient
        <MasterLayout
            // md={props.store.patientInsCompaniesMD}
            renderHeader={() => null}
            actions={[
                {
                    name: 'new',
                    iconName: 'plus',
                    text: 'Afegir mútua',
                    callback: () => {
                        store.patientInsCompaniesCreating = !store.patientInsCompaniesCreating
                    }
                },
                {
                    name: 'remove',
                    iconName: 'minus',
                    text: 'Treure mútua',
                    callback: () => {
                        store.removePatientInsCompany(store.patientInsCompaniesSel.selectedId)
                    }
                }
            ]}
            body={<C3AgGrid
                floatingFilter={false}
                columnDefs={[
                    {
                        field: 'insCompany',
                        headerName: 'Mutua',
                        editable: true,
                        // cellEditor: 'agRichSelectCellEditor',
                        cellEditorFramework: SelectCE,
                        cellEditorParams: (params: IRichCellEditorParams) => {
                            return {
                                values: store.insCompanies,
                                formatValue: (params) => {
                                    return params?.shortName
                                },
                            }
                        },
                        valueFormatter: (params: ValueFormatterParams) => {
                            return params.value?.shortName
                        },
                        width: 260
                    }, {
                        field: 'mutuaNumber',
                        headerName: 'Num. Pòlissa',
                        editable: true,
                        width: 160

                    },
                    // {
                    //     field: 'mutuaNumber',
                    //     headerName: 'Per defecte',
                    //     editable: true
                    // },

                ]}
                selection={store.patientInsCompaniesSel}
                rowData={store.patientInsCompaniesSel.items}
                // rowData={store.patientInsCompaniesCreating ? [...(props.store.patientInsCompanies || []), {}] : props.store.patientInsCompanies}
                cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    // await new Promise((resolve, reject) => setTimeout(resolve, 1000))
                    // await new Promise(r => setTimeout(r, 1000));
                    // On update
                    let fieldName = e.colDef.field;
                    let newVar = await store.savePatientInsCompany({id: e.data?.id, [fieldName]: e.newValue});
                    return newVar
                }}
            />}
        />
    </Container>
})
