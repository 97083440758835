import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useUserFVStore} from "./UserFileView";
import {Field, SelectControl, Form, C3AgGrid, MasterLayout} from "react-c4";
import {Button, InputGroup, Popover} from "@blueprintjs/core";
import {formatFullName, isDev} from "../../application/utils/utils";
import {CellValueChangedEvent, IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";

const Container = styled.div`
  display: grid;
  padding: 5px;
  grid-template-rows: auto 1fr;
  height: 100%;
`

export interface UserFVDetailProps {

}

export const UserFVDetail = observer((props: UserFVDetailProps) => {
    let store = useUserFVStore()
    let editableCheck = store.editingEnabled
    return <Container>
        <h3>Rols:</h3>
        <MasterLayout
            renderHeader={() => null}
            actions={[
                {
                    name: 'new',
                    iconName: 'document',
                    text: 'Afegir Rol',
                    callback: () => {
                        store.createRoleAssign()
                    }
                },
                {
                    name: 'remove',
                    iconName: 'trash',
                    text: 'Eliminar Rol',
                    confirmation: true,
                    confirmationText: 'Esta segur que vol borrar aquest rol?',
                    callback: () => {
                        store.deleteSelectedRoleAssign()
                    }
                }
            ]}
            body={<C3AgGrid
                floatingFilter={false}
                columnDefs={[
                    {
                        field: 'role',
                        headerName: 'Rol',
                        editable: editableCheck,
                        cellEditor: 'agRichSelectCellEditor',
                        cellEditorParams: (params: IRichCellEditorParams) => {
                            return {
                                values: store.allRolesRes,
                                formatValue: (params) => {
                                    if (params?.displayName == null ) return '-'
                                    return params?.displayName
                                },
                            }
                        },
                        valueFormatter: (params: ValueFormatterParams) => {
                            if (params?.value?.displayName == null ) return '-'
                            return params.value?.displayName
                        },
                        width: 160
                    }

                ]}
                processCellCallback={(params) => {
                    let data = (params.node as RowNode).data
                    return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                }}
                getContextMenuItems={(params) => {
                    // let fileName = store.fr
                    var result = [
                        {
                            // custom item
                            name: 'Exporta a Excel',

                            action: function () {
                                let user = formatFullName(store.usersSel.selectedItem).replace(' ', '-')
                                params.api.exportDataAsExcel({
                                    fileName: `llistat-rols-${user}`,
                                    processCellCallback: (params) => {
                                        let data = (params.node as RowNode).data
                                        if (params.column.getColId() == 'role') {
                                            return data.role.displayName;
                                        }
                                        if (params.column.getColId() == 'isActive'){
                                            return data.isActive == true ? 'X': '';
                                        }
                                        return params.value
                                    }
                                })
                            },
                        }
                    ]
                    return result
                }}
                selection={store.userRolesSel}
                cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    if (e.colDef.field == 'role'){
                        let newRolUserRelation = {
                            id: e.data?.id,
                            user: store.usersSel.selectedId,
                            role: e.newValue.id
                        }
                        let newVar = await store.saveUserRole(newRolUserRelation);
                        return newVar
                    } else {
                        let fieldName = e.colDef.field;
                        let newVar = await store.saveUserRole({id: e.data?.id, [fieldName]: e.newValue});
                        return newVar
                    }

                }}
            />}
        />
    </Container>
})
