import * as React from "react";
import * as ReactDOM from "react-dom";
import {FocusStyleManager} from "@blueprintjs/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {AgGridReact} from "ag-grid-react";
import {BrowserRouter as Router, Link, Route} from 'react-router-dom';

import * as _ from "lodash";
import styled from "styled-components";
import {pages} from "./pages";
import AppSidebar from "./application/AppSidebar";
import {IATSStyleApp, IATSStyleAppShell, C4Application} from "react-c4";
import {client} from "./application/app";
import {c3Schema} from "./application/schema/c3Schema";
import * as moment from "moment";
import {BISApplication} from "./application/BISApplication";
import './application/assets/icon.png';
import './application/assets/m_logo.svg';
import {PrivateInvoice} from "./documents/invoice-variants/PrivateInvoice";
import {PrivateInvoiceRoute} from "./documents/printed-documents/PrivateInvoiceRoute";
import {FitxaBlanca} from "./documents/FitxaBlanca";
import {FitxaBlancaRoute} from "./documents/printed-documents/FitxaBlancaRoute";
import {VisitLabel} from "./documents/VisitLabel/VisitLabel";
import { ReactQueryDevtools } from 'react-query-devtools'

// @ts-ignore
// AgGridReact.prototype.areEquivalent = (a, b) => a === b;
// AgGridReact.prototype.areEquivalent = (a, b) => _.isEqual(a, b);
import Mobx from "mobx";

import mobxFormatters from "mobx-formatters";
import {SystemTrayComponent} from "./application/components/SystemTray/SystemTrayComponent";
import {AppStore} from "./application/AppStore";
import {OverlayLoginScreen} from "./application/auth/components/OverlayLoginScreen/OverlayLoginScreen";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {WaitingRoomDisplayView} from "./components/WaitingRoomDisplayView/WaitingRoomDisplayView";
import {YourTurnRoute} from "./documents/printed-documents/YourTurnRoute";
import {WebCitationView} from "./components/WebCitationView/WebCitationView";

if(process.env.INST_IS_PROD == 'true'){
    Sentry.init({
        dsn: "https://aa51e9cf696c440fa1f565169dbedc33@o468006.ingest.sentry.io/5495294",
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}


moment.locale('ca', require('moment/locale/ca'))
FocusStyleManager.onlyShowFocusOnTabs();


declare let module: any;
// if(process.env.NODE_ENV != 'production')
mobxFormatters(Mobx)


const AppContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

C4Application.initInstance(new BISApplication())

IATSStyleApp.initInstance();


let appStore = new AppStore();
IATSStyleApp.instance.startApplication({
    client,
    schemaInfo: c3Schema as any,
    pages: pages as any,
    appComponent: <IATSStyleAppShell sidebarComponent={AppSidebar}/>,
    containerComponent: AppContainer,
    keycloakInitOptions: {
        realm: "guirado-his",
        url: "https://demo.medicalione.com/auth",
        clientId: "ig-his",
        onLoad: 'login-required'
    },
    additionalRoutesElement: <React.Fragment>
        <Route exact path={'/invoice/:id'} component={PrivateInvoiceRoute}/>
    </React.Fragment>,
    appStore: appStore,
    elements: {
        systemTrayElement: <SystemTrayComponent/>,
        devtools: <>
            <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} toggleButtonProps={{

            }} />
        </>,
        overlayLoginScreen: <OverlayLoginScreen/>
    }
})
appStore.init()
setTimeout(() => {
    let loaderEl = document.getElementById('loader');
    if (loaderEl) loaderEl.remove()
    document.getElementById('root').classList.remove('hidden')
})


if (module.hot) {
    const lastState = JSON.parse(localStorage.getItem('navState'))//window['navState'];
    const first = lastState == null;
    module.hot.accept();
    try {
        // @ts-ignore
        if (window.navRef) window.navRef.current.setState(lastState);
        // @ts-ignore
    } catch (e) {
        console.log(`Failed to restore lastState`);
        throw e;
    }
    // @ts-ignore

    if (window.navRef) setTimeout(window.navRef.current.forceUpdate(() => {
        // toaster.dismiss('updating');
        if (!first) IATSStyleApp.instance.toaster.show({
            message: 'Hot update applied',
            intent: 'success',
            icon: 'changes',
            timeout: 1000
        });
    }));

}
