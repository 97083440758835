import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {RefFVStore} from "../../files-views/ReferrerFileView/RefFVStore";
import {Button, ButtonGroup} from "@blueprintjs/core";

import {observer} from "mobx-react";
import {DateInputControl} from "react-c4/src/index";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
`

const StatusContainer = styled.div`
    display: grid;
    place-content: center;
    place-items: center;
    grid-auto-flow: column;
    grid-gap: 8px;
`
const ButtonContainer = styled.div`
  padding-left: 5px
`

const FilterContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
`


export interface ReferrerPageHeaderProps {
    store: RefFVStore
}

export const ReferrerPageHeader = observer((props: ReferrerPageHeaderProps) => {
    let store = props.store
    if (store == null) return null
    return <Container>
        <ButtonGroup>
            {store.orgsSel.items.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={()=>store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>
        <Button icon={'upload'} disabled={true} onClick={() => {
            // store.excelImportClick()
        }}>Importar Excel</Button>
        {/*<Button
            active={store.filterDateHandler}
            onClick={()=>{store.filterDateHandler = !store.filterDateHandler}}
        >Mostrar Filtres</Button>
        { store.filterDateHandler &&
        <FilterContainer>
            <DateInputControl
                field={store.filterForm.$('startDate')} fill={true} placeholder={"Data document"}/>
            <DateInputControl

                field={store.filterForm.$('endDate')} fill={true} placeholder={"Data document"}/>
            <ButtonGroup>
                <Button icon={'arrow-left'}
                        onClick={(e) => {
                            store.moveRange(e.shiftKey ? -7 : -1)
                        }}
                />
                <Button
                    onClick={(e) => {
                        store.goToDate(new Date())
                    }}
                > Avui </Button>
                <Button icon={'arrow-right'}
                        onClick={(e) => {
                            store.moveRange(e.shiftKey ? 7 : 1)
                        }}
                />
            </ButtonGroup>
        </FilterContainer>
        }*/}
        <ButtonContainer>
            <Button icon={'refresh'}
                    onClick={async () => {
                        await store.refresh()
                    }}
            />
        </ButtonContainer>

    </Container>
})
