import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SVHTasksBarTask} from "./SVHTasksBarTask";
import {useSVVStore} from "../../../../StandardVisitView";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: center;
    background-color: #c3ced6;
    padding: 8px;
    //background-color: #96c3e5;
`

export interface SVHTasksBarProps {

}

export const SVHTasksBar = observer((props: SVHTasksBarProps) => {
    let store = useSVVStore()
    let sideTasks = [
        {
            name: 'Filiació pacient',
            color: '#ccc',
            type: 'check-indicator',
            indicators: [
                {text: 'Nom'},
                {text: 'DNI'},
                // {text: 'MP', tooltip: 'Mètode Pagament'},
                {text: 'Tel', tooltip: 'Telèfon'},
                {text: 'RGPD', tooltip: 'Protecció de dades'},

            ]
        }, {
            name: 'Dades visita',
            color: '#ccc',
            type: 'check-indicator',
            indicators: [
                // {text: 'Maq'},
                {text: 'Comentari Tècnic', tooltip: 'Comentari tècnic (visita)'},
                // {text: 'MP', tooltip: 'Mètode Pagament (visita)'},


            ]
        }, {
            name: 'Documents',
            color: '#ccc',
            type: 'check-indicator',
            indicators: [
                {text: 'CI'},
                {text: 'Petició'},
                // {text: 'A2'},
                // {text: 'A3'},

            ]
        }, {
            name: 'Worklist',
            color: '#ccc',
            type: 'check-indicator',
            indicators: [
                {text: 'Enviar reg.'},


            ]

        }, {
            name: 'Entrega',
            color: '#ccc',
            type: 'check-indicator',
            indicators: [
                {text: 'Entregar 17/09/20', intent: 'success'},


            ]
        }
    ]
    return <Container>
        {sideTasks.map(st => {
            return <SVHTasksBarTask task={st}/>
        })}
    </Container>
})
