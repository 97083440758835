import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {formatFormalName} from "../../application/utils/utils";
import {SchedulePageHeader} from "../SchedulePage/SchedulePageHeader";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {Page} from "react-c4";
import {MPPStore} from "../../components/PrescriptionView/MPPStore";
import {PrescriptionPageHeader} from "./PrescriptionPageHeader";
import {PrescriptionView} from "../../components/PrescriptionView/PrescriptionView";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface PrescriptionPageProps {
    pageContext
}

export const PrescriptionPage = page(observer((props: PrescriptionPageProps) => {
    let [store, setStore] = useState(null as MPPStore);

    // let patientName = [store?.patientForm?.$('name').value, store?.patientForm?.$('surname1').value, store?.patientForm?.$('surname2').value ].join(' ');
    let patientName = 'Nombre del paciente'
    return <Page
        title={'Prescripción'}
        subtitle={patientName}
        icon={'label'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <PrescriptionPageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <PrescriptionView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                  {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
