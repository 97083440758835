import React, {useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {DateInputControl, getMomentFormatter} from "react-c4";
import {DFEStore} from "./DFEStore";
import {ICellEditorParams} from "ag-grid-community";
import {bindWithAS, nextAnimationFrame, useAS} from "../../application/utils/utils";
import {Button, ButtonGroup, Checkbox, TextArea} from "@blueprintjs/core";
import moment from "moment";

let w = 400;
let h = 320;
const Container = styled.div`
  display: grid;
  width: ${w}px;
  height: auto;
  background-color: #F5F8FA;
  grid-template-rows: auto 1fr;
  grid-gap: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
`
const FullEntryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  padding: 1px;

  :hover {
    background-color: #95dfe5;
  }
`
const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  padding: 1px;

`
const DeliveryMethodContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
const UnlinkedScrollContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`

const DateColor = styled.div`
  //background-color: #db3737;
`
const DeliveredToOtherContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`

export const DFEContext = React.createContext(null as DFEStore)
export const useStore = () => useContext(DFEContext)


export interface DFEMainProps {
    store: DFEStore
    params: ICellEditorParams
}

export const DFEMain = observer((props: DFEMainProps) => {
    let store = props.store
    let vlAS = useAS(async (field, e) => {
        let fieldName = field.name;
        let vlf = store.svvStore.getVisitLineField(store.visitLineId);
        await store.saveVisitLine({[fieldName]: vlf.$(fieldName).value})
    })
    let as = useAS(async (field, e) => {
        let fieldName = field.name;
        await store.saveDeliveryInfo({[fieldName]: store.form.$(fieldName).value})
    })
    if (!store.visitId) return null
    // let deliveryDate = store.form.$('deliveryDate');
    let $deliveryDate = store.svvStore.getVisitLineField(store.visitLineId).$('deliveryDate');
    let $deliveredDate = store.svvStore.getVisitLineField(store.visitLineId).$('deliveredDate');
    let $comment = store.form.$('comment');
    let deliveredToOtherComment = store.form.$('deliveredToOtherComment');

    let value = store.form.$('deliveredItems').value;
    // console.log(`deliveredItems`, value);
    let deliveredItems = JSON.parse(value || '{}');
    let showDates = false;
    let actualUser =  store.appStore.auth.loggedInUser.id
    return <DFEContext.Provider value={props.store}>
        <Container>
            <DateContainer>
                <label>
                    Data Prevista: <DateInputControl fill field={$deliveryDate}
                                                     as={vlAS} {...getMomentFormatter('DD/MM/YYYY')}/>
                </label>
                <label>
                    Data Entregat: <DateColor>
                    <DateInputControl fill field={$deliveredDate} as={vlAS} {...getMomentFormatter('DD/MM/YYYY')}/>
                </DateColor>
                </label>
            </DateContainer>
            <DateContainer>
                {/*<Button small onClick={() => {*/}
                {/*    let date = new Date();*/}
                {/*    $deliveredDate.set(date)*/}
                {/*    as.current($deliveredDate, date)*/}
                {/*}}>Auto</Button>*/}
                <Button small onClick={() => {
                    $deliveryDate.set('auto')
                    vlAS.current($deliveryDate, 'auto')
                }}>Auto</Button> <Button small onClick={() => {
                    let date = new Date();
                    $deliveredDate.set(date)
                    vlAS.current($deliveredDate, date)
                }}>Avui</Button>
            </DateContainer>


            <div>
                <label>Comentari:</label>
                <TextArea style={{height: 50}} placeholder={'Comentari'}
                          fill={true}  {...bindWithAS(store.form.$('comment'), as)} />

            </div>

            {$deliveredDate.value && <React.Fragment>
              <Checkbox onChange={() => {
                  store.handleDeliverToOther();
              }} checked={store.deliveredToOther}
                        label={'Entregat a un altre'}/>
                {/*{store.deliveredToOther && <DeliveredToOtherContainer>*/}
                {/*  <label>Entregat a:</label> <TextArea style={{height: 50}} {...{...deliveredToOtherComment.bind()}}*/}
                {/*                                       placeholder={'Comentari'}/>*/}
                {/*</DeliveredToOtherContainer>}*/}
              <Button
                active={store.allSelectedMethods}
                onClick={() => {
                    store.selectAllMethods(!store.allSelectedMethods)
                }}>
                  {!store.allSelectedMethods ? 'Selecciona tot' : 'Deselecciona tot'}
              </Button>
                {store.allDeliveryItems.map((itemId) => {
                    let itemInfo = store.deliveryItemMap[itemId]
                    let itemData = deliveredItems[itemId] || {check: false}
                    let checked = itemData.check || false;
                    store.getUserName(itemData.userId, itemId)
                    return <DeliveryMethodContainer key={`${itemInfo.name}`}>
                        <Checkbox key={itemId} onChange={() => {
                            store.markItemDelivered(itemId, !checked, true, actualUser)
                        }}
                                  checked={checked} label={`${itemInfo.name}`}/>
                        {itemId == 'plaques' && store.plaquesDeliveredBy && <label>{store.plaquesDeliveredBy}
                        {itemData.date &&
                            <span> - ({moment(itemData.date).format('DD/MM/YYYY HH:mm')})</span>}</label>}
                        {itemId == 'book' && store.bookDeliveredBy && <label>{store.bookDeliveredBy}
                            {itemData.date &&
                            <span> - ({moment(itemData.date).format('DD/MM/YYYY HH:mm')})</span>}</label>}
                        {itemId == 'cd' && store.cdDeliveredBy && <label>{store.cdDeliveredBy}
                            {itemData.date &&
                            <span> - ({moment(itemData.date).format('DD/MM/YYYY HH:mm')})</span>}</label>}
                        {itemId == 'informe' && store.informeDeliveredBy && <label>{store.informeDeliveredBy}
                            {itemData.date &&
                            <span> - ({moment(itemData.date).format('DD/MM/YYYY HH:mm')})</span>}</label>}
                    </DeliveryMethodContainer>
                })}
            </React.Fragment>}

            <Checkbox onChange={() => store.handleForAllVisitLines()} checked={store.forAllVisitLines}
                      label={'Replicar per tota la Visita'}/>

            {$deliveredDate.value ? <ButtonGroup>
                <Button onClick={async () => {
                    await store.saveDeliveryInfo({status: 'partial', replicateForVisit: store.forAllVisitLines}, true)
                    await nextAnimationFrame()
                    store.close()
                }}>Guardar però falta alguna entrega</Button>
                <Button onClick={async () => {
                    await store.saveDeliveryInfo({status: 'complete', replicateForVisit: store.forAllVisitLines}, true)
                    await nextAnimationFrame()
                    store.close()
                }}>Guardar tot Entregat</Button>
            </ButtonGroup> : <Button onClick={() => {
                store.close()
            }}>Guardar</Button>}
        </Container>
    </DFEContext.Provider>
})
