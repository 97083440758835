import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {stdDateColumn, stdHourColumn} from "../../../application/utils/utils";
import {useSRVStore} from "../ScheduleRulesView";
import {
    CellValueChangedEvent,
    ICellEditorParams,
    IRichCellEditorParams,
    ValueFormatterParams,
    ValueParserParams
} from "ag-grid-community";
import moment from "moment";
import * as _ from "lodash";
import {SVVLinesGridVisitCR} from "../../StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridVisitCR";
import {SVVLinesGridReferrerCE} from "../../StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridReferrerCE";
import {SVVLinesGridDeliveryCE} from "../../StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridDeliveryCE";
import {SVVLinesGridDeliveryCR} from "../../StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridDeliveryCR";
import {DateTimeCE} from "../../../application/utils/components/DateTimeCE";
import {SelectCE} from "../../../application/utils/components/SelectCE";


export interface SRVRulesGridProps {

}

export const SRVRulesGrid = observer((props: SRVRulesGridProps) => {
    let store = useSRVStore()
    return <MasterLayout renderHeader={() => null}
                         actions={[
                             {
                                 name: 'refresh',
                                 iconName: 'refresh',
                                 callback: () => {
                                     store.refetchAll()
                                 }

                             },
                             {
                                 name: 'new',
                                 iconName: 'add',
                                 tooltip: 'Clic al calendari per crear una regla',
                                 callback: () => {
                                     store.createRuleInCurrentView()
                                 }
                             },
                             {
                                 name: 'delete',
                                 iconName: 'trash',
                                 callback: () => {
                                     store.deleteSelectedRule()
                                 }
                             },

                         ]}
    >
        <C3AgGrid
            columnDefs={[
                {
                    field: '',
                    headerName: '',
                    width: 30,
                    checkboxSelection: true,
                },
                {
                    field: 'resource',
                    headerName: 'Recurs',
                    editable: true,
                    cellEditorFramework: SelectCE,
                    cellEditorParams: (params: IRichCellEditorParams) => {
                        return {
                            values:  store.appStore.resources,
                            formatValue: (params) => {
                                return params?.name
                            },
                        }
                    },
                    valueFormatter: (params: ValueFormatterParams) => {
                        if (_.isEmpty(params.value)) return '-'
                        return params?.value.name
                    },
                    width: 85
                },
                (false ? {
                    field: 'resource',
                    headerName: 'Màquina',
                    width: 30,
                } : null),
                {
                    field: 'applicableFrom',
                    headerName: 'Inici',
                    editable: true,
                    ...stdDateColumn(),
                    cellEditor: 'dateTimeCE',

                },
                {
                    field: 'applicableUntil',
                    headerName: 'Fi',
                    editable: true,
                    ...stdDateColumn(),
                    cellEditor: 'dateTimeCE',

                },
                {
                    field: 'title',
                    headerName: 'Nom / Títol',
                    cellEditor: 'agLargeTextCellEditor',
                    editable: true,
                    width: 150
                },




                {
                    field: 'startDate',
                    headerName: 'Des de',
                    ...stdHourColumn(),
                    editable: false,
                    width: 65

                },
                {
                    field: 'endDate',
                    headerName: 'Fins a',
                    ...stdHourColumn(),
                    editable: false,
                    width: 65

                },
                {
                    field: 'expression',
                    headerName: 'Expr',

                },
                {
                    field: 'type',
                    headerName: 'Tipus',
                    editable: true,
                },
                {
                    field: 'id',
                },
            ].filter(v => v)}
            rowData={store.rulesRes}
            selection={store.rulesSel}
            cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                let newVar = await store.saveRule({
                    id: e.data.id,
                    [e.colDef.field]: e.newValue
                })
                store.scvStore.fetch()
                return newVar
            }}

            frameworkComponents={{
                dateTimeCE: DateTimeCE,
            }}

        />
    </MasterLayout>
})
