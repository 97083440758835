import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {RSCCombinedInput} from "./RSCCombinedInput/RSCCombinedInput";
import {useRSCStore} from "../../../../RSCMain";

const Container = styled.div`
    display: grid;
    
`

export interface RSCRefExtCenterEditorProps {

}

export const RSCRefExtCenterInput = observer((props: RSCRefExtCenterEditorProps) => {
    let store = useRSCStore()
    return <RSCCombinedInput
        fieldName={'extCenter'}
        title={'Centre referent'}
        inputRef={store.extCenterRef}
        entityTitle={'centre referent'}
        associatingToText={''}
    />
})
