import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {formatFullName, stdDateColumn, stdIntNumericColumn} from "../../../../application/utils/utils";
import {CellValueChangedEvent, ICellRendererParams, RowNode, ValueParserParams} from "ag-grid-community";
import {C3AgGrid} from "react-c4/src/index";
import {Checkbox, Icon} from "@blueprintjs/core";
import {useMPEStore} from "../../MeetupPushEventsView";
import moment from "moment";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface MPEGridProps {

}

export const MPEGrid = observer((props: MPEGridProps) => {
    let store = useMPEStore()

    let warningColumn = {
        cellRendererFramework: (params: ICellRendererParams) => {
            let data = params.data;
            let value = params.value;
            let hasDelta = store.getFieldsWithDelta(data).includes('name')
            return <div style={{color: hasDelta ? '#a87b47' : ''}}>{value}</div>
        }
    }

    let getColumns = (isStatus = false) => {
        let getField = (fieldName) => {
            return isStatus ? `status.${store.meetupEventFieldMap[fieldName] || fieldName}` : fieldName
        }
        return [
            {
                field: getField('name'),
                headerName: 'Nom',
                editable: false,
                width: 120,
                ...warningColumn
            },
            {
                field: getField('updatedAt'),
                headerName: 'Data modificació',
                editable: false,
                width: 120,
                valueFormatter({value}) {
                    if (value === undefined) return ''
                    if ([null, ''].includes(value)) return false ? '' : '(Sense data)'
                    return moment(value).format('DD/MM/YYYY hh:mm');
                },
                valueParser: (params: ValueParserParams) => {
                    let moment1 = moment(params.newValue, 'DD/MM/YYYY hh:mm');
                    moment(params.oldValue).startOf('date').add(moment1.date()).add(moment1.date())
                    return moment1.toDate()
                },
                ...warningColumn
            },
            {
                field: getField('start'),
                headerName: 'Start',
                editable: false,
                width: 120,
                valueFormatter({value}) {
                    if (value === undefined) return ''
                    if ([null, ''].includes(value)) return false ? '' : '(Sense data)'
                    return moment(value).format('DD/MM/YYYY hh:mm');
                },
                valueParser: (params: ValueParserParams) => {
                    let moment1 = moment(params.newValue, 'DD/MM/YYYY hh:mm');
                    moment(params.oldValue).startOf('date').add(moment1.date()).add(moment1.date())
                    return moment1.toDate()
                },
            },
            {
                field: getField('end'),
                headerName: 'End',
                editable: false,
                width: 120,
                valueFormatter({value}) {
                    if (value === undefined) return ''
                    if ([null, ''].includes(value)) return false ? '' : '(Sense data)'
                    return moment(value).format('DD/MM/YYYY hh:mm');
                },
                valueParser: (params: ValueParserParams) => {
                    let moment1 = moment(params.newValue, 'DD/MM/YYYY hh:mm');
                    moment(params.oldValue).startOf('date').add(moment1.date()).add(moment1.date())
                    return moment1.toDate()
                },
                ...warningColumn
            },
        ]

    }

    return <Container>
        <C3AgGrid
            columnDefs={[
                {
                    field: 'id',
                    headerName: 'Id',
                    editable: false,
                    width: 60,
                    ...stdIntNumericColumn(),
                },
                ...getColumns(false),
                {
                    field: '',
                    headerName: '',
                    editable: false,
                    width: 60,
                    cellRendererFramework: (params: ICellRendererParams) => {
                        return <div>{'-->'}</div>
                    }
                },
                ...getColumns(true).reverse()
            ]}
            processCellCallback={(params) => {
                let data = (params.node as RowNode).data
                return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
            }}
            // getContextMenuItems={(params) => {
            //     // let fileName = store.fr
            //     var result = [
            //         {
            //             // custom item
            //             name: 'Exporta a Excel',
            //
            //             action: function () {
            //                 let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
            //                 params.api.exportDataAsExcel({
            //                     fileName: `llistat-referidors-pendents${orgName}`,
            //                     processCellCallback: (params) => {
            //                         let data = (params.node as RowNode).data
            //                         if (params.column.getColId() == 'createdAt') {
            //                             return moment(data.createdAt).format('DD/MM/YYYY')
            //                         }
            //                         if (params.column.getColId() == 'specialty' && !!data.specialtyName == true){
            //                             return data.specialtyName
            //                         }
            //                         return params.value
            //                     }
            //                 })
            //             },
            //         }
            //     ]
            //     return result
            // }}
            // rowData={store.pendingReferrersRes}
            selection={store.meetupEventsSel}
            cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                // await store.saveReferrer({
                //     id: e.data.id,
                //     [e.colDef.field]: e.newValue
                // })
            }}
            // rowSelection={'multiple'}

        />
    </Container>
})