import React, {useContext, useEffect} from "react";
import {observer} from 'mobx-react'
import {ExtCenterFVStore} from "./ExtCenterFVStore";
import {C3AgGrid, MasterLayout, useC3Store} from "react-c4";
import {ExtCenterFVMain} from "./ExtCenterFVMain";



export const ExtCenterFVContext = React.createContext(null as ExtCenterFVStore)
export const useExtCenterFVStore = () => useContext(ExtCenterFVContext)

export interface ExtCenterFileViewProps {
    pageContext?
    onStoreReady: (s: ExtCenterFVStore) => any
}

export const ExtCenterFileView = observer((props: ExtCenterFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new ExtCenterFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null

    return <ExtCenterFVContext.Provider value={store}>
        <ExtCenterFVMain/>
    </ExtCenterFVContext.Provider>
})
