import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {
    CellValueChangedEvent,
    IRichCellEditorParams, RowNode,
    ValueFormatterParams,
    ValueGetterParams,
    ValueSetterParams
} from "ag-grid-community";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {useRFVStore} from "../../../ResourceFileView";
import {
    formatFullName,
    stdBoolColumn,
    stdIntNumericColumn,
    stdNumericColumn
} from "../../../../../application/utils/utils";
import {observer} from "mobx-react";
import * as _ from "lodash";
import moment from "moment";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`
const GridTitle = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
`

export interface PREActiveProductsGridProps {

}

export const REEActiveProductsGrid = observer((props: PREActiveProductsGridProps) => {
        let store = useRFVStore()

        return <Container>
            <GridTitle>(3) Proves assignades a {store?.resourcesSel?.selectedItem?.name &&
            <label> {store?.resourcesSel?.selectedItem?.name} ({store.assignedProductsRes?.length})</label>}:</GridTitle>
            <C3AgGrid
                columnDefs={[
                    {
                        field: '',
                        headerName: '',
                        width: 30,
                        checkboxSelection: true,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },

                    {
                        field: 'code',
                        headerName: 'C. Prova',
                        editable: false,
                        headerTooltip: 'Codi Prova',
                        width: 60,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'name',
                        headerName: 'Nom',
                        floatingFilter: true,
                        editable: false,
                        width: 150,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'durationInResources',
                        headerName: `Duració a ${store.resourcesSel.selectedItem ? store.resourcesSel.selectedItem.name : 'màquina'}`,
                        editable: true,
                        width: 140,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        valueGetter: (params: ValueGetterParams) => {
                            let rId = store.resourcesSel.selectedId
                            let row = params.data;
                            return row.durationInResources && row.durationInResources[rId]
                        },
                        valueSetter: (params: ValueSetterParams) => {
                            let rId = store.resourcesSel.selectedId
                            let row = params.data;
                            params.data.durationInResources = {
                                ...row.durationInResources,
                                [rId]: params.newValue
                            }
                            return true

                        },
                        ...stdIntNumericColumn(),
                        // valueFormatter: (params: ValueFormatterParams) => {
                        //     let rId = store.resourcesSel.selectedId
                        //     if (!params.value) return
                        //     return params.value[rId] // TODO check when endpoint is done
                        // },
                    },
                    {
                        field: 'id',
                        headerName: 'Id',
                        editable: false,
                        headerTooltip: 'Codi Prova',
                        width: 60,
                        ...stdIntNumericColumn(),
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                ]}
                processCellCallback={(params) => {
                    let data = (params.node as RowNode).data
                    return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                }}
                getContextMenuItems={(params) => {
                    // let fileName = store.fr
                    var result = [
                        {
                            // custom item
                            name: 'Exporta a Excel',

                            action: function () {
                                let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName : '';
                                let resourceName = store.resourcesSel.selectedItem.name.replace(" ", "-").replace('.', '-');
                                params.api.exportDataAsExcel({
                                    fileName: `llistat-proves-${resourceName}-${orgName}`,
                                    processCellCallback: (params) => {
                                        let data = (params.node as RowNode).data
                                        if (params.column.getColId() == 'isActive') {
                                            return data.isActive == true ? 'X' : '';
                                        }
                                        return params.value
                                    }
                                })
                            },
                        }
                    ]
                    return result
                }}
                rowData={store.assignedProductsRes}
                selection={store.assignedProductsSel}
                cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    let newValue = e.newValue;
                    if (e.colDef.field == 'durationInResources') {
                        let rId = store.resourcesSel.selectedId
                        newValue = {
                            [rId]: e.newValue
                        }
                    }

                    await store.saveActiveProduct({
                        id: e.data.id,
                        [e.colDef.field]: newValue
                    })
                }}
            />
        </Container>
    }
)
