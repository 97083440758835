import React from 'react';
import styled from 'styled-components';
import {NavigationSpy} from 'react-navigation-plane';
import {Sidebar, C3, IATSStyleApp} from 'react-c4';
import {UserComponent} from "./auth/components/UserComponent";
import {dec, isAdmin, useAppStore} from "./utils/utils";
import {AppStore} from "./AppStore";
import {observer} from "mobx-react";
import {toJS} from "mobx";

const LogoContainer = styled.div`
    background-color: #34424e;
    grid-area: logo;
    color: #a7bfcb;
    //background-color: rgba(147,209,230,0.49);
    font-style: normal;
    text-align: center;
    font-size: 18px;
    //font-size: 16px;
    display: grid;
    align-items: center;
    justify-content: center;
    //letter-spacing: 1px;
    //height: 42px;
    margin-top: 8px;
    height: 32px;
    
    ${p => p.transient ? `
    background-color: rgba(138,230,138,0.73);
    color: white;
    ` : ``}
    ${p => p.isLocal ? `
    background-color: rgba(147,209,230,0.49);
    color: white;
    ` : ``}
`

export interface IATSSidebarProps {
    logout: Function
}

let officialHostname = 'medicalone.cat';

// In the future may be deprecated in favor of a managed approach
function AppSidebar(props: IATSSidebarProps) {
    let appStore = useAppStore()
    let featuresForUser = appStore.auth.featureFilter.featuresForUser;
    let liu = appStore.auth.loggedInUser;
    return <NavigationSpy>
        {({navigate}) => {
            appStore.navContext = {navigate}
            const open = (pageName) => navigate({to: pageName, inNewTab: true, focusNewTab: true});
            let showFilesButton = dec().showFilesButton();
            return <Sidebar
                buttons={[
                    {iconName: 'home', tooltip: 'Inicio', onClick: () => open('home')},
                    // {iconName: 'dashboard', tooltip: 'Tauler de control', onClick: () => open('dashboard')},
                    // {iconName: 'inbox', tooltip: 'Reservar cites', onClick: () => open('patients')},
                    // {iconName: 'numbered-list', tooltip: 'Agenda del dia', onClick: () => open('episodesOfToday')},
                    // {iconName: 'calendar', tooltip: 'Agendes', onClick: () => open('schedule')},
                    // {iconName: 'people', tooltip: 'Contactes', onClick: () => open('ContactsPage')},
                    // {iconName: 'settings', tooltip: 'Configuració', onClick: () => open('settings')},
                    (showFilesButton && {iconName: 'database', tooltip: 'Ficheros', onClick: () => open('files')}),
                    {
                        iconName: 'user', tooltip: '', onClick: () => {

                        }
                    },
                    // {
                    //     iconName: 'person', tooltip: '', onClick: () => {
                    //         return open('profile');
                    //     }
                    // }, // mes endavant iconName= user
                    {
                        iconName: 'log-out', tooltip: 'Cerrar sesión', onClick: () => {

                            appStore.auth.logout();
                            // window.location.href = C3.instance.auth.keycloak.createLogoutUrl()
                        }
                    },
                ].filter(b => b) as any}
                bottomCount={showFilesButton ? 3 : 2}
                selectedIndex={null}
                userComponent={<UserComponent navigate={navigate}/>}
                // pictureUrl={}
                loggedIn={appStore.auth.isLoggedIn}
                renderLogo={() => {
                    return <LogoContainer transient={window.location.port !== "3110"}
                                          isLocal={window.location.hostname !== officialHostname}>
                        {(process.env.INSTANCE_SHORT_TITLE || '--')}
                    </LogoContainer>;
                }}

            />;
        }}
    </NavigationSpy>;
}

export default observer(AppSidebar);
