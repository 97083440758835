import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {languages} from "../../../../application/utils/languages";
import {Field, SelectControl} from "react-c4";
import {useAVVStore} from "../../AllVisitsView";
import {formatFullName, isDev} from "../../../../application/utils/utils";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    //padding: 4px;
    grid-gap: 8px;
    grid-auto-flow: column;
    justify-content: start;
    padding: 1px;
    padding-left: 4px;
`
const SelectContainer = styled.div`
    width: 350px
`

export interface AVVFiltersHeaderProps {

}

export const AVVFiltersHeader = observer((props: AVVFiltersHeaderProps) => {
    let store = useAVVStore()
    return <Container>
        <SelectContainer>
            (1) Selecciona centre
            <Field field={store.filterForm.$('refExtCenter')} c={SelectControl} options={store.refExtCenterSel.items}/>
        </SelectContainer>
        <SelectContainer>
            (2) Selecciona referidor
            <Field field={store.filterForm.$('refMedical')} c={SelectControl} options={store.refMedicalSel.items}
                   props={{renderOption: ((r) => formatFullName(r)) as any}}/>
        </SelectContainer>
        <Button icon={'filter-remove'} onClick={() => {
            store.removeFilers()
        }}>Netejar Filtres</Button>
        <Button active={store.showHidden} icon={'filter-list'} onClick={() => {
            store.showHiddenVisits()
        }}>Inclou Cancel·lats</Button>
    </Container>
})
