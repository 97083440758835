import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {RFVStore} from "../../files-views/ResourceFileView/RFVStore";
import {Button, ButtonGroup, Tag} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
`
const ButtonContainer = styled.div`
  padding-left: 15px
`

const StatusContainer = styled.div`
  display: grid;
  place-content: center;
  place-items: center;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding-left: 15px;
`

export interface ResourceFilePageHeaderProps {
    store: RFVStore
}

export const ResourceFilePageHeader = observer((props: ResourceFilePageHeaderProps) => {
    let store = props.store;
    if (store === null) return null;

    let orgName = store.orgsSel?.selectedItem?.shortName;
    let machineName = store.resourcesSel?.selectedItem?.name;
    let productName = store.allProductsSel?.selectedItem?.name;


    return <Container>

        <ButtonGroup>
            {store.orgsSel.items?.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={() => store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>
        <ButtonContainer>
            <Button icon={'refresh'}
                    onClick={() => store.fetchItems()}
            />
        </ButtonContainer>
        {productName && machineName && <StatusContainer>
            Assignant prova <Tag minimal large intent={'warning'}> {productName}</Tag> a
            màquina <Tag minimal large intent={'warning'}>  {machineName}</Tag> a organització
            <Tag minimal large intent={'warning'}>  {orgName}</Tag>
        </StatusContainer>}
    </Container>
})
