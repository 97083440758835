import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout, Transfer} from "react-c4";
import {CellValueChangedEvent, RowNode} from "ag-grid-community";
import {useExtCenterFVStore} from "../ExtCenterFileView";
import {formatFullName, isDev, stdBoolColumn} from "../../../application/utils/utils";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`
const GridTitle = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
  
`
const GridTitleContainer = styled.div`
  display: flex;
  padding: 0px 15px;
`

export interface ExtCenterEditorFVProps {

}

export const ExtCenterEditorFV = observer((props: ExtCenterEditorFVProps) => {
    let store = useExtCenterFVStore()
    return <Container>
        <GridTitleContainer>
            <GridTitle>Centres:</GridTitle>
        </GridTitleContainer>
            <MasterLayout
                renderHeader={() => {
                    return null
                }}
                actions={[
                    {
                        name: 'create',
                        iconName: 'document',
                        callback: () => {
                            store.createExtCenter()
                        }
                    },
                    {
                        name: 'delete',
                        iconName: 'trash',
                        callback: () => {
                            store.deleteSelectedExtCenter()
                        }
                    },
                    {
                        name: 'showActives',
                        intent: store.showInactiveMedicals ? "success" : "none",
                        iconName: 'strikethrough',
                        text: store.showInactiveMedicals ? 'Mostra només metges actius' : 'Mostra metges inactius',
                        callback: () => {
                            store.showInactiveMedicals = !store.showInactiveMedicals
                        }
                    }
                ]}
                body={
                    <C3AgGrid
                        columnDefs={[
                            {
                                field: 'name',
                                headerName: 'Nom Centre',
                                editable: true,
                                width: 250,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'code',
                                headerName: 'Codi Centre',
                                editable: true,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'isActive',
                                headerName: 'Actiu',
                                ...stdBoolColumn(),
                                width: 55
                            }
                        ]}
                        floatingFilter={false}
                        processCellCallback={(params) => {
                            let data = (params.node as RowNode).data
                            return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                        }}
                        getContextMenuItems={(params) => {
                            // let fileName = store.fr
                            var result = [
                                {
                                    // custom item
                                    name: 'Exporta a Excel',

                                    action: function () {
                                        params.api.exportDataAsExcel({
                                            fileName: 'llistat-Centres',
                                            processCellCallback: (params) => {
                                                let data = (params.node as RowNode).data
                                                if (params.column.getColId() == 'isActive') {
                                                    return data.isActive == true ? 'X' : ''
                                                }
                                                return params.value
                                            }
                                        })
                                    },
                                }
                            ]
                            return result
                        }}
                        rowData={store.extCenterRes}
                        selection={store.extCenterSel}
                        cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                            let newVar = await store.saveExtCenter({
                                id: e.data.id,
                                [e.colDef.field]: e.newValue
                            })
                            return newVar
                        }}

                    />
                }
            />
    </Container>
})
