import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Icon, Popover} from "@blueprintjs/core";
import {useAppStore} from "../../../utils/utils";
import moment from "moment";
import {useInterval, useUpdate} from "react-use";

const Container = styled.div`
    display: grid;
    //width: 12px;

`

const PopoverContainer = styled.div`
    padding: 8px;
    display: grid;
    grid-auto-flow: row;
`
const ItemContainer = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-template-columns: auto 1fr;
    align-items: center;
`
const IndicatorContainer = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 50%;
`

export interface TrayConnectionProps {

}

export const TrayConnection = observer((props: TrayConnectionProps) => {
    let appStore = useAppStore()
    let update = useUpdate()
    let interval = useInterval(() => {
        update()
    }, 1000)
    let online = appStore.network?.online;
    let serverOnline = appStore.networkStatus.serverConnected
    let realtimeOnline = appStore.events.isOnline
    let ago = moment(appStore.events.lastSeen).fromNow();//.format('HH:mm:ss');
    // TODO Impl event source stats
    return <Container><Popover
        content={<PopoverContainer>
            {/*<ItemContainer>*/}
            {/*    /!*<IndicatorContainer*!/*/}
            {/*    /!*    style={{backgroundColor: online ? '#0D8050' : '#C23030'}}/>*!/*/}
            {/*    <span>{online ? 'En línia' : 'Fora de línia'}</span>*/}
            {/*</ItemContainer>*/}
            <ItemContainer>
                {/*<IndicatorContainer*/}
                {/*    style={{backgroundColor: online ? '#0D8050' : '#C23030'}}/>*/}
                <span>{serverOnline ? 'Servidor connectat' : 'Servidor desconnectat'}</span>
            </ItemContainer>

            <ItemContainer>
                <IndicatorContainer
                    style={{backgroundColor: realtimeOnline ? '#0D8050' : '#C23030'}}/>
                <span>{realtimeOnline ? (appStore.events.lastSeen ? `Temps real (${ago})` : 'Temps real Connectat') : 'Temps real desconnectat'}</span>
            </ItemContainer>

        </PopoverContainer>}
    >

        {/*<Icon icon={'cell-tower'}/>*/}
        {realtimeOnline ? <img height={18} style={{color: 'red'}}
                               src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9Ik91dGxpbmVkIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlLz48ZyBpZD0iRmlsbCI+PHBhdGggZD0iTTE2LDIxYTMsMywwLDEsMCwzLDNBMywzLDAsMCwwLDE2LDIxWm0wLDRhMSwxLDAsMSwxLDEtMUExLDEsMCwwLDEsMTYsMjVaIi8+PHBhdGggZD0iTTE2LDRBMTkuODUsMTkuODUsMCwwLDAsMS44Niw5Ljg2bDEuNDEsMS40MWExOCwxOCwwLDAsMSwyNS40NiwwbDEuNDEtMS40MUExOS44NiwxOS44NiwwLDAsMCwxNiw0WiIvPjxwYXRoIGQ9Ik00LjY5LDEyLjY5LDYuMSwxNC4xYTE0LDE0LDAsMCwxLDE5LjgsMGwxLjQxLTEuNDFhMTYsMTYsMCwwLDAtMjIuNjIsMFoiLz48cGF0aCBkPSJNNy41MSwxNS41MWwxLjQyLDEuNDJhMTAsMTAsMCwwLDEsMTQuMTQsMGwxLjQyLTEuNDJhMTIsMTIsMCwwLDAtMTcsMFoiLz48cGF0aCBkPSJNMTAuMzQsMTguMzRsMS40MiwxLjQyYTYsNiwwLDAsMSw4LjQ4LDBsMS40Mi0xLjQyYTgsOCwwLDAsMC0xMS4zMiwwWiIvPjwvZz48L3N2Zz4="
                               alt="Red dot"/> : <Icon intent={'warning'} icon={'offline'}/>}

    </Popover>
    </Container>
})
