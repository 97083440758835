import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Icon, Popover} from "@blueprintjs/core";
import {formatFullName, isDev, useAppStore} from "../../../utils/utils";

const Container = styled.div`
    display: grid;
    
`
const PopoverContainer = styled.div`
    padding: 8px;
    display: grid;
    grid-auto-flow: row;
`
const ItemContainer = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-template-columns: auto 1fr;
    align-items: center;
`

export interface TraySupportProps {

}

export const TraySupport = observer((props: TraySupportProps) => {
    let appStore = useAppStore()

    let subject = 'Incidència HIS';
    let body = `Usuari: ${formatFullName(appStore?.auth?.loggedInUser)}, Organització: ${appStore?.orgsSel?.selectedItem?.initials}`;
    let mailtoLink = 'mailto:soporte@medicalione.com?subject=' + subject + '&body=' + body + '"';
    return <Container>
        <Popover
            content={<PopoverContainer>
                <ItemContainer>
                    <span>Suport tècnic</span>
                </ItemContainer>
                <ItemContainer>
                    {isDev() && <span>Tel: +34 656 64 46 11</span>}
                </ItemContainer>
                <ItemContainer>
                    <a href={mailtoLink} target={'_blank'}>soporte@medicalione.com</a>
                </ItemContainer>
            </PopoverContainer>}
        >
            <Icon icon={'help'} color={'gray'}/>
        </Popover>
    </Container>
})
