import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {RSCRefMedicalInput} from "./components/inputs/RSCRefMedicalInput";
import {RSCRefExtCenterInput} from "./components/inputs/RSCRefExtCenterInput";
import {RSCLinkIndicator} from "./components/RSCLinkIndicator";
import {RSCEdgeCreation} from "./components/RSCEdgeCreation";
import {useRSCStore} from "../../RSCMain";
import {Icon, Tooltip} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  padding-right: 18px;
  align-items: start;
`
const IconContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  justify-items: center;
`

export interface RSCHeaderProps {

}

/**
 * Header and header-related contents
 * Includes item and edge creation
 */
export const RSCHeader = observer((props: RSCHeaderProps) => {
    let store = useRSCStore()
    let field = store.form.$('extCenter');
    return <Container>
        <RSCRefExtCenterInput/>
        <IconContainer>
            <RSCLinkIndicator/>
            <Icon
                htmlTitle={'Netejar filtre'}
                onClick={()=>{
                    store.clearImputs()
                }}
                icon={'filter-remove'}></Icon>
        </IconContainer>
        <RSCRefMedicalInput/>
        {store.isCreatingAssociation && <RSCEdgeCreation field={field}/>}
    </Container>
})
