import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRSCStore} from "../../RSCMain";
import {formatFullName, isDev} from "../../../../application/utils/utils";
import {Button, Icon, ButtonGroup} from "@blueprintjs/core";
import {RSCEntryDisplay} from "./components/RSCEntryDisplay";

const Container = styled.div`
  display: grid;
  overflow: auto;
  height: 100%;
  //grid-template-columns: 1fr 1fr;
`
const FullEntryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  padding: 1px;

  :hover {
    background-color: #95dfe5;
  }
`
const NewEntryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  padding: 1px;

`
const LinkedScrollContainer = styled.div`
  //display: grid;
  //grid-gap: 2px;
`
const UnlinkedScrollContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`
const HelperContainer = styled.span`
  justify-self: center;
  background: darkgrey;
  padding: 8px;
  border-radius: 15px;
  margin: 3px;
  height: fit-content;
`

const RemoveContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
`

export interface RSCBodyProps {

}

/**
 * Options display aligned to header
 */
export const RSCBody = observer((props: RSCBodyProps) => {
    let store = useRSCStore()
    if (store.medicalsRes == null || store.extCentersRes == null) return null
    let $medicalCreating = store.form.$('medical.creating');
    let $extCenterCreating = store.form.$('extCenter.creating');
    let newExtCenterButton = <div>
        {!$extCenterCreating.value && <Button
            minimal
            icon={'plus'}
            onClick={() => {
                $extCenterCreating.onChange(true)
            }}
        >Nou centre referent</Button>}

    </div>;
    let newMedicalButton = <div>
        {!$medicalCreating.value && <Button
            minimal
            icon={'plus'}
            onClick={() => {
                $medicalCreating.onChange(true)
            }}
        >Nou metge referent</Button>}
    </div>;
    let buttonStyles = {
        justifyContent: 'space-between',
        justifySelf: "center",
        padding: 10,
    }
    return <Container>
        {store.isLinked ? <LinkedScrollContainer>
                {store.res && store.res.length > 0 && store.res?.map((e, i) => {
                    return <FullEntryContainer
                        key={`${e.medicalId}${e.extCenterId}`}
                    >
                        <RSCEntryDisplay item={e} text={e.extCenterName} type={'extCenter'}/>
                        <RSCEntryDisplay item={e} text={formatFullName(e)} type={'medical'}/>
                    </FullEntryContainer>
                })}
                <NewEntryContainer
                    key={'new'}
                >
                    {newExtCenterButton}
                    {newMedicalButton}
                </NewEntryContainer>
            </LinkedScrollContainer> :
            <UnlinkedScrollContainer>
                <div style={{overflow: "auto"}}>
                    {store.extCentersRes && store.extCentersRes.length > 0 && store.extCentersRes.map((e) => {
                        return <RSCEntryDisplay key={e.id} item={e} text={e.name} type={'extCenter'}/>
                    })}
                    {newExtCenterButton}

                </div>
                <div style={{overflow: "auto"}}>
                    {store.medicalsRes && store.medicalsRes.length > 0 && store.medicalsRes.map((e) => {
                        return <RSCEntryDisplay key={e.id} item={e} text={formatFullName(e)} type={'medical'}/>
                    })}
                    {newMedicalButton}
                </div>
            </UnlinkedScrollContainer>
        }
        {/*<HelperContainer><Icon icon={'warning-sign'}/> Cercar Referidors solament per un camp a la vegada: nom, primer*/}
        {/*    cognom o segon cognom.</HelperContainer>*/}
        <RemoveContainer>
            <Button style={buttonStyles} onClick={()=>{store.submit({extCenterId: null})}}>
                Desseleccionar Centre
            </Button>
            <Button style={buttonStyles} onClick={()=>{store.submit({medicalId: null})}}>
                Desseleccionar Metge
            </Button>
        </RemoveContainer>
    </Container>
})
