import {C3, C3Selection, IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {Monaco, default as monaco} from "@monaco-editor/react";
import * as _ from 'lodash'

export class FFVStore {
    appStore: AppStore
    pageContext


    featuresSel: C3Selection
    featuresRes
    defaultValue = `
export class Feature1 extends BaseFeature {

}
    `
    monaco: Monaco;

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.featuresSel = new C3Selection({
            onSelectionChanged: () => {
                this.initMonacoForFeature()
            }
        })
        await this.fetchFeatures()
    }

    async fetchFeatures() {
        let res = await C3.instance.client.fetcher.fetch(`/event-feature/all`, {
            query: [].join('&'),
        });
        this.featuresRes = res
        this.featuresSel.itemsRef.current = res
    }

    async saveAll() {
        let body = this.featuresSel.itemsRef.current.filter(f => f.dirty);
        body.forEach((f) => {
            delete f.dirty
        })
        // console.log(`saving`, body);
        let res = await C3.instance.client.fetcher.fetch(`/event-feature/save-many`, {
            method: 'POST',
            body: {features: body},
            query: [].join('&'),
        });
        await this.fetchFeatures()

    }

    async deleteSelectedFeatures() {
        let res = await C3.instance.client.fetcher.fetch(`/`, {
            method: 'DELETE',
            body: {ids: []},
            query: [].join('&'),
        });
    }

    async createFeature() {
        let res = await C3.instance.client.fetcher.fetch(`/event-feature`, {
            method: 'POST',
            body: {name: 'New Feature'},
            query: [].join('&'),
        });
        await this.fetchFeatures()
    }

    monacoBeforeMount(monaco) {

    }

    setupMonaco(editor, monaco) {
        console.log("onMount: the editor instance:", editor);
        console.log("onMount: the monaco instance:", monaco)

        monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: true,
            noSyntaxValidation: false
        });

// compiler options
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monaco.languages.typescript.ScriptTarget.ES6,
            allowNonTsExtensions: true
        });

// extra libraries
        var libSource = [
            'declare class BaseEvent {',
            '    /**',
            '     * Returns the next fact',
            '     */',
            '    static next():string',
            '}'
        ].join('\n');
        var libUri = 'ts:filename/facts.d.ts';
        monaco.languages.typescript.javascriptDefaults.addExtraLib(libSource, libUri);
// When resolving definitions and references, the editor will try to use created models.
// Creating a model for the library allows "peek definition/references" commands to work with the library.
//         monaco.editor.createModel(libSource, 'typescript', monaco.Uri.parse(libUri));
    }

    initMonacoForFeature() {
        let feature = this.featuresSel.selectedItem
        // this.monaco.editor.setModelContent(this.monaco.editor.getModel(), feature.code)


    }

    debouncer = _.debounce(() => this.saveAll(), 3000)

    async handleEditorChange(value, event) {
        let feature = this.featuresSel.selectedItem
        if (!feature) return
        feature.code = value
        feature.dirty = true
        this.debouncer()
    }

    saveFeatureLocally(data) {
        let feature = this.featuresRes.find(id => data.id);
        Object.assign(feature, data, {dirty: true})
        this.debouncer()
    }
}
