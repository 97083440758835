import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Icon} from "@blueprintjs/core";
import moment from "moment";
import {useAppStore} from "../../../utils/utils";
import {useInterval, useUpdate} from "react-use";

const Container = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    align-content: center;
    justify-items: center;
    color: #137cbd;
    font-size: 11px;
`
const TimeContainer = styled.div`
    
`
const DateContainer = styled.div`
    
`

export interface TrayClockProps {

}

export const TrayClock = observer((props: TrayClockProps) => {
    let appStore = useAppStore()
    let update = useUpdate()
    useInterval(() => {
        update()
    }, 1000)
    return <Container>
        <TimeContainer>{moment().format('HH:mm')}</TimeContainer>
        <DateContainer>{moment().format('dd DD/MM/YY')}</DateContainer>
    </Container>
})
