import {AppStore} from "../AppStore";
import {autorun, computed, observable, reaction} from "mobx";
// import {io, Socket} from 'socket.io-client';
import {C3} from "react-c4";
import Rx, {Subject} from "rxjs";
import {stream} from "xlsx";
import {dec} from "../utils/utils";

export interface NotifyData {
    src?: string
    entity?: 'p' | 'v' | 'vl' | 'i' | 'il' | 'cal',
    days?: string[]
    vIds?: number[]
    vlIds?: number[]
    rIds?: number[]
    pIds?: number[]
    uId?: [number, any]
}


export class EventsStore {
    appStore: AppStore


    // socket: Socket

    evtSource: EventSource
    bcChannel: BroadcastChannel

    obs: Subject<NotifyData> = new Subject<NotifyData>()

    @observable
    lastSeen: Date = null

    @observable
    receivedEvents = 0

    @observable
    isOnline

    constructor(appStore: AppStore) {
        this.appStore = appStore;

    }

    triedReconnect = false
    switchedSession = false
    bcChannelUsed = false
    sseChannelIsUsed = false

    async init() {
        this.bcChannel = new BroadcastChannel('test_channel')

        this.bcChannel.onmessage = (e) => {
            let data = e.data;
            ingestEvent(JSON.parse(data))
            this.bcChannelUsed = true
            this.log(`SSE bcChannel data`, data);
            if (this.evtSource.readyState == 1) {
                // this.evtSource.close()
                // this.log(`SSE Closed ES`);
            }

        }
        let baseUrl = C3.instance.client.fetcher.baseUrl;
        let ingestEvent = (parsed) => {
            // this.log(`SSE Ingested event`, parsed);
            this.log(`SSE: `, parsed);

            let t = parsed.t;
            if (t && t.startsWith('sys')) {
                if (parsed.c) {
                    if(!parsed.noupd) this.checkCommit(parsed.c)
                }
            } else {
                if(dec().logSSEEvents()) console.log(`Event: `, parsed);
                this.obs.next(parsed)
                this.lastSeen = new Date()
                this.receivedEvents++;
            }
            this.isOnline = true
        }
        let retryTimeout;
        let connectTimeout;
        let buildES = (sessionId) => {
            if (this.evtSource)
                this.evtSource.close()

            if (sessionId == null) return
            this.evtSource = new EventSource(`${baseUrl}/events/session/${sessionId}`, {withCredentials: false});

            this.evtSource.onmessage = (event) => {
                let data = event.data;
                if (data == 'used') {
                    this.log(`SSE stream ${sessionId} USED`);
                    // if (!this.switchedSession)
                    //     this.appStore.auth.newSession()
                    // this.switchedSession = true
                    this.sseChannelIsUsed = true
                    this.isOnline = false
                    return
                }
                let parse = JSON.parse(data);
                this.bcChannel.postMessage(data)
                ingestEvent(parse)
            }


            this.evtSource.onopen = (event) => {
                this.isOnline = true
                if (retryTimeout) clearTimeout(retryTimeout)
                this.triedReconnect = false
                this.log(`SSE Open`);
            }

            this.evtSource.onerror = (event) => {
                this.log(`onerror`, event);
                if (!this.bcChannelUsed) {
                    this.isOnline = false
                    if (!this.triedReconnect) {
                        let ms = this.sseChannelIsUsed ? 30000 : 10000;
                        if (connectTimeout) return
                        this.log(`SSE Reconnecting in ${ms / 1000}s: `, sessionId);
                        connectTimeout = setTimeout(() => {
                            buildES(sessionId)
                        }, ms)
                        connectTimeout = null
                        this.triedReconnect = true
                    } else {
                        if (!retryTimeout) {
                            retryTimeout = setTimeout(() => {
                                if (!this.switchedSession) this.appStore.auth.newSession()
                                this.switchedSession = true
                                retryTimeout = null
                            }, 2000)
                        }
                    }

                } else {
                    this.evtSource.close()
                    this.log(`SSE Close`);
                }

            }
        };
        reaction(() => {
            let sessionId = this.appStore.auth.sessionToken;
            return sessionId
        }, buildES)
        buildES(this.appStore.auth.sessionToken)


        // let path = C3.instance.client.fetcher.baseUrl;
        // this.log(`socket on `, path);
        // this.socket = io( 'ws://localhost:3001',{
        //     // port: '3001',
        //     // hostname: 'localhost',
        //     transports: ['websocket'],
        //     reconnectionDelay: 100000,
        //     // path: ' /socket'
        //
        // })
        // this.socket.emit('events', { test: 'test' });
        // // this.socket.emit('hello', 'can you hear me?', 1, 2, 'abc');
        // // this.socket.on()
        // this.socket.on('connect', () => {
        //     this.log(`CONNECTED!!`);
        // })
    }


    checkCommit(newCommit) {
        let reloadedWithCommit = sessionStorage.getItem('reloaded-w-c')

        if (!newCommit) return
        let commithash = process.env.COMMIT_HASH;
        this.log(`[Reloader] Server commit: ${newCommit} current:`, commithash);
        if (!commithash) {
            this.log(`[Reloader] Local commit hash not available`, commithash);
            return;
        }
        if (newCommit == reloadedWithCommit) {
            this.log(`[Reloader] Already reloaded with this commit on this session`, reloadedWithCommit);
            return;
        }
        if (newCommit != commithash) {
            this.log(`[Reloader] Reloading with rwc`, commithash);
            sessionStorage.setItem('reloaded-w-c', newCommit)
            window.location.reload()
        }
    }

    log(...args) {
        if (!dec().logSSE()) return
        console.log(...args);
    }
}
