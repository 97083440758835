import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {CellValueChangedEvent, RowNode} from "ag-grid-community";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {useProdFVStore} from "../../../ProdFV";
import {
    formatFullName,
    stdDateColumn,
    stdIntNumericColumn,
    stdNumericColumn
} from "../../../../../application/utils/utils";
import {observer} from "mobx-react";
import {Button, Icon, Tooltip} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  height: 100%;
`
const GridToolbarContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 8px;
`

export interface PREAllRatesGridProps {

}

export const PREAllRatesGrid = observer((props: PREAllRatesGridProps) => {
        let store = useProdFVStore()
        return <Container>
            <MasterLayout
                // actions={[
                //     {
                //         name: 'magic filter',
                //         iconName: 'filter',
                //         text: 'Magic Filter',
                //         // enabled: !!store.allMGMeetupEventsSel.selectedId,
                //         active: store.allMGMeetupEventsSel,
                //         callback: () => {
                //             store.createInvoiceLine()
                //         }
                //     },
                // ]}
                renderToolbar={() => <GridToolbarContainer>
                    <Tooltip content={'Download group'}>
                        <Button onClick={() => {
                            store.updateMeetupEventsForSelectedGroup()
                        }}><Icon icon={'download'}/></Button>
                    </Tooltip>
                    <Tooltip content={'Copy event'}>
                        <Button onClick={() => {
                            store.copyEventFromMeetupEvent()
                        }}><Icon icon={'import'}/></Button>
                    </Tooltip>
                    <Tooltip content={'From today'}>
                        <Button active={store.filterFromToday} onClick={() => {
                            store.filterFromToday = !store.filterFromToday
                            store.fetchMeetupEventsForSelMeetupGroup()
                        }}>FT</Button>
                    </Tooltip>
                    <Tooltip content={'Auto Filter'}>
                        <Button active={store.filterRes?.auto} onClick={() => {
                            store.filterRes.auto = true
                            store.fetchMeetupEventsForSelMeetupGroup()
                        }}><Icon icon={'automatic-updates'}/></Button>
                    </Tooltip>
                    <Tooltip content={'Same Week'}>
                        <Button active={store.filterRes?.sameWeek} onClick={() => {
                            store.filterRes.sameWeek = !store.filterRes?.sameWeek
                            store.filterRes.auto = false
                            store.fetchMeetupEventsForSelMeetupGroup()
                        }}>SW</Button>
                    </Tooltip>
                    <Tooltip content={'Same Day'}>
                        <Button active={store.filterRes?.sameDay} onClick={() => {
                            store.filterRes.sameDay = !store.filterRes?.sameDay
                            store.filterRes.auto = false
                            store.fetchMeetupEventsForSelMeetupGroup()
                        }}>SD</Button>
                    </Tooltip>
                    <Tooltip content={'Same Title'}>
                        <Button active={store.filterRes?.sameTitle} onClick={() => {
                            store.filterRes.sameTitle = !store.filterRes?.sameTitle
                            store.filterRes.auto = false
                            store.fetchMeetupEventsForSelMeetupGroup()
                        }}>ST</Button>
                    </Tooltip>
                </GridToolbarContainer>}
                renderHeader={() => null}
                body={<C3AgGrid
                    onRowDoubleClicked={({data, event}) => {
                        window.open(`https://www.meetup.com/${data.urlName}/events/${data.meetupId}/`, "_blank");
                    }}
                    columnDefs={[
                        {
                            field: '',
                            headerName: '',
                            width: 30,
                            checkboxSelection: true,
                            // suppressFilter: true,
                        },
                        {
                            field: 'name',
                            headerName: 'Nom',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 320,
                        },
                        {
                            field: 'start',
                            headerName: 'Start',
                            filter: 'agTextColumnFilter',
                            ...stdDateColumn(),
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'end',
                            headerName: 'End',
                            filter: 'agTextColumnFilter',
                            ...stdDateColumn(),
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'row_number',
                            headerName: 'Row index',
                            filter: 'agTextColumnFilter',
                            ...stdIntNumericColumn(),
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'urlName',
                            headerName: 'Url Name',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'rsvp_limit',
                            headerName: 'Capacitat',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'meetupId',
                            headerName: 'Id de Meetup',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },

                        {
                            field: 'duration',
                            headerName: 'Duration',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'timedelta.days',
                            headerName: 'Time delta',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'who',
                            headerName: 'Who',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    // let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
                                    // params.api.exportDataAsExcel({
                                    //     fileName: `llistat-barems-disponibles-${orgName}`,
                                    //     processCellCallback: (params) => {
                                    //         let data = (params.node as RowNode).data
                                    //         if (params.column.getColId() == 'isActive') {
                                    //             return data.isActive == true ? 'X' : ''
                                    //         }
                                    //         return params.value
                                    //     }
                                    // })
                                },
                            }
                        ]
                        return result
                    }}
                    rowData={store.allMGMeetupEventRes}
                    selection={store.allMGMeetupEventsSel}
                    // cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    //     await store.saveRate({
                    //         id: e.data.id,
                    //         [e.colDef.field]: e.newValue
                    //     })
                    // }}
                    // rowData={store.referrersRes}
                    // selection={store.referrersSel}
                    // cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    //     await store.saveReferrer({
                    //         id: e.data.id,
                    //         [e.colDef.field]: e.newValue
                    //     })
                    // }}

                />}
            />
        </Container>
    }
)
