import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {InsCompanyFVStore} from "./InsCompanyFVStore";
import {useC3Store} from "react-c4";
import {InsCompanyFVMain} from "./InsCompanyFVMain";

const Container = styled.div`
    display: grid;
    
`


export const InsCompanyFVContext = React.createContext(null as InsCompanyFVStore)
export const useInsCompanyFVStore = () => useContext(InsCompanyFVContext)

export interface InsCompanyFVProps {
    pageContext?
    onStoreReady: (s: InsCompanyFVStore) => any
}

export const InsCompanyFV = observer((props: InsCompanyFVProps) => {
    let store = useC3Store(() => {
        let storeInst = new InsCompanyFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <InsCompanyFVContext.Provider value={store}>
        <Container>
            <InsCompanyFVMain pageContext={props.pageContext}/>
        </Container>
    </InsCompanyFVContext.Provider>
})
