import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSVVStore} from "../../StandardVisitView";

const Container = styled.div`
    display: grid;
    
`

export interface SVVSelectResourceProps {
    visitIds: number[]
}

export const SVVSelectResource = observer((props: SVVSelectResourceProps) => {
    let store = useSVVStore()
    return <Container>
        {props.visitIds.map(id => store.getLocalVisit(id)).map(visit => {
            return <div>
                <label>{visit.id} Tria una màquina: </label>
                <select
                    value={visit.resource?.id}
                    onChange={async (e) => {
                        // visit.resource =
                        await store.saveVisit({id: visit.id, resource: {id: e.target.value}})
                        // await store.refetch();
                    }}
                    /*{...store.visitsSel.selField.$('resource').bind()}*/
                >
                    <option value={null}>--</option>
                    {store.resourcesDS.items?.map((res) => {
                        return <option value={res.id}>{res.name}</option>

                    })}
                </select>
            </div>
        })}
    </Container>
})
