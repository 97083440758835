import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {SCVStore} from "../../components/ScheduleView/SCVStore";
import {Button, ButtonGroup, Popover, Tag, Tooltip} from "@blueprintjs/core";
import {formatFormalName, isDev} from "../../application/utils/utils";
import {DateInputControl, getMomentFormatter} from "react-c4";
import {DateInput, DateRangeInput} from "@blueprintjs/datetime";
import ErrorBoundary from "react-c4/src/app-shells/IATSStyle/page-templates/ErrorBoundary";
import moment from "moment";
import {SchedulePageHeaderAcceptTmp} from "./components/SchedulePageHeaderAcceptTmp";
import LU from "react-day-picker/src/addons/MomentLocaleUtils.js";
import {ResourceSelector} from "../../application/components/shared/ResourceSelector";


const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  grid-template-columns: 3fr auto;
  width: 100%;
  justify-content: start;
`
const RightContainer = styled.div`
    display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;

`

export interface SchedulePageHeaderProps {
    store: SCVStore
}

export const SchedulePageHeader = observer((props: SchedulePageHeaderProps) => {
    let store = props.store
    if (store == null) return null

    // let oldSelector = <ButtonGroup vertical={false}>
    //     {/*<Button intent={'primary'} active={!store.tmpHidden} onClick={() => store.tmpHidden = !store.tmpHidden}>IRM</Button>*/}
    //     {!store.tmpHidden && _.entries(store.totalResources).map(([k, v]: any, i) => {
    //         let id = v.id;
    //         let selected = store.enabledResourcesSel.isSelected(id);
    //         let button = <Button
    //             key={id}
    //             icon={selected ? 'tick' : null}
    //             active={selected}
    //             onClick={(e) => {
    //                 if (e.ctrlKey) {
    //                     store.enabledResourcesSel.selectId(id, true, true)
    //                 } else {
    //                     store.enabledResourcesSel.selectId(id, !selected, false)
    //                 }
    //             }}
    //             onDoubleClick={() => {
    //                 store.enabledResourcesSel.selectWhere(() => true, true, true)
    //             }}
    //         >{v.title}</Button>
    //         return button;
    //         return <Tooltip content={v.tooltipName} hoverOpenDelay={3200}>
    //             {button}
    //         </Tooltip>
    //     })}
    //     {/*<Button intent={'primary'}>GE</Button>*/}
    //
    // </ButtonGroup>;




    return <Container>

        {store.patientId != null &&
        <Tag large icon={'person'} onClick={() => store.patientId = null}>{formatFormalName(store.patientData)}</Tag>}
        {/*{store.patientId == null && <Button icon={'person'} onClick={() => store.pageContext.navigate({*/}
        {/*    to: 'patients',*/}
        {/*    replace: true*/}
        {/*})}>Vincular pacient</Button>}*/}
        {store.patientId != null &&
        <Button icon={'cross'} onClick={() => store.patientId = null}>Desvincular pacient</Button>}

        {/*<ResourceSelector/>*/}
        <ErrorBoundary>
            <DateInput  {...getMomentFormatter('DD/MM/YYYY')}
                        locale={'ca'}
                        todayButtonText={'Avui'}
                        localeUtils={LU}
                        minDate={moment().subtract(7, 'years').toDate()}
                        maxDate={moment().add(2, 'years').toDate()}
                        value={store.startDate}
                        {...{
                            defaultValue: new Date(), onChange(v) {
                                return props.store.goToDate(v);
                            }
                        }} />
        </ErrorBoundary>
        <RightContainer>
            <ButtonGroup>
                <Button
                    onClick={() => store.backButton()}
                    icon={'chevron-backward'}/>
                <Button
                    onClick={() => store.forwardButton()}
                    icon={'chevron-forward'}/>
            </ButtonGroup> <Button icon={'refresh'} onClick={() => store.refetch()}/>
            {store.tmpEvents.length > 0 && <SchedulePageHeaderAcceptTmp store={store}/>}
        </RightContainer>
        <div/>
        <ButtonGroup>
            <Button disabled={!store.eventSel.selectedId}>Event Següent</Button>
            <Button disabled={!store.eventSel.selectedId}>Event anterior</Button>
        </ButtonGroup>
        <Button active={store.showMeetupEvents} onClick={() => {store.showMeetupEvents = !store.showMeetupEvents}}>Mostrar meetup events</Button>
        <Button loading={store.loading.pullAll} onClick={() => {store.pullAll()}} icon={'import'}>Pull</Button>
        <Button loading={store.loading.autoAssignMeetupEvents} onClick={() => {store.autoAssignMeetupEvents()}} icon={'git-merge'}>Auto assign</Button>
        <Button loading={store.loading.attendeesForAllMeetupEvents} onClick={() => {store.fetchAttendeesForAllMeetupEvents()}} icon={'people'}>Fetch attendees</Button>
        <ButtonGroup>
            <Button active={store.detailMode == 'event'} onClick={() => store.detailMode = "event"}>Event</Button>
            <Button active={store.detailMode == 'feature-editor'} onClick={() => store.detailMode = "feature-editor"}>Features</Button>
            <Button active={store.detailMode == 'schedule'} onClick={() => store.detailMode = "schedule"}>Schedule</Button>
        </ButtonGroup>
    </Container>
})
