import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import moment from "moment";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {useSVVStore} from "../../../../../StandardVisitView";

const Container = styled.div`
  display: grid;

`
const ResourceContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 4px;
  border-radius: 4px;
  padding: 4px;
  margin: -4px;
  background-color: #96c3e5;
  align-items: center;
`
const ResourceNameContainer = styled.div`
  font-weight: bold;
  font-size: 15px;
`
const AppointmentsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  align-items: center;
`
const AppointmentContainer = styled.div`
  display: grid;
  border-radius: 4px;
  align-items: center;
  padding: 4px;
  //grid-gap: 4px;
  font-size: 11px;
  grid-auto-flow: row;
  background-color: #5d88a3;
  justify-items: center;
  color: white;
  cursor: default;
`
const VisitIdContainer = styled.div`
  font-weight: bold;
`
const DateContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  //font-size: 10px;
  //color: #2d4658;
`

export interface SVHResourceHeaderProps {
    visit
    resource
}

export const SVHResourceHeader = observer((props: SVHResourceHeaderProps) => {
    let store = useSVVStore()
    let rightFlat = {borderBottomRightRadius: 0, borderTopRightRadius: 0};
    let leftFlat = {borderBottomLeftRadius: 0, borderTopLeftRadius: 0};
    if (!props.resource) return null
    let visitsInResource = store.selectedResources[props.resource?.id];

    return <Container>
        <ResourceContainer>
            {props.resource && <ResourceNameContainer>{props.resource?.name}</ResourceNameContainer>}
            <AppointmentsContainer>
                {visitsInResource.map((visitId) => {
                    let visit = store.getLocalVisit(visitId)
                    let moment1 = moment(visit?.appointmentDate);
                    let duration = visit.duration;
                    let moment2 = moment1.clone().add(duration, 'minutes');
                    return <AppointmentContainer>
                        <VisitIdContainer>
                            T{visitId} <span style={{fontWeight: 'normal'}}>{moment1.format('DD/MM/YY')}</span>
                        </VisitIdContainer>
                        <DateContainer>
                            {visit?.appointmentDate
                            && (moment1.format('HH:mm')) + ' - ' +
                            moment2.format('HH:mm')
                            || '--/--/-- --:--'}
                        </DateContainer>
                    </AppointmentContainer>
                })}
            </AppointmentsContainer>
            {/*{visit?.appointmentDate ? <ButtonGroup minimal>*/}
            {/*        <Button*/}
            {/*            disabled={false} icon="chevron-backward" style={rightFlat}*/}
            {/*            onClick={() => {*/}

            {/*            }}*/}
            {/*        />*/}
            {/*        <Button*/}
            {/*            icon="chevron-forward" style={leftFlat}*/}
            {/*            onClick={() => {*/}

            {/*            }}*/}
            {/*        />*/}
            {/*    </ButtonGroup> :*/}
            {/*    <Button*/}
            {/*        disabled={false} minimal*/}
            {/*        onClick={() => store.visitViewMode = "form"}*/}
            {/*    >Auto</Button>*/}
            {/*}*/}
            {/*{confirmingScreen ? 'Confirma la cita' : 'Programant cita'}*/}
            {/*{moment(appointmentForm.$('date')).format('LL HH:mm')}*/}
        </ResourceContainer>
    </Container>
})
