import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useBusinessLineFVStore} from "./BusinessLineFileView";
import {C3AgGrid, MasterLayout} from "react-c4/src/index";
import {CellValueChangedEvent, RowNode} from "ag-grid-community";
import {formatFullName, stdBoolColumn, stdIntNumericColumn} from "../../application/utils/utils";

const Container = styled.div`
    display: grid;
    height: calc(100vh - 100px);
    padding: 8px;
`

export interface BusinessLineFVMainProps {
    pageContext
}

export const BusinessLineFVMain = observer((props: BusinessLineFVMainProps) => {
    let store = useBusinessLineFVStore()
    return <Container>
        <MasterLayout
            renderHeader={() => {
                return null
            }}
            actions={[
                {
                    name: 'create',
                    iconName: 'document',
                    callback: () => {
                        store.createBusinessLine()
                    }
                },
                {
                    name: 'delete',
                    iconName: 'trash',
                    callback: () => {
                        store.deleteSelectedBusinessLine()
                    },
                    confirmation: true,
                    confirmationText: 'Està segur que vol borrar aquesta línia de negoci'
                },
            ]}
            body={
                <C3AgGrid
                    columnDefs={[
                        {
                            field: 'name',
                            headerName: 'Nom',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'isActive',
                            headerName: 'Actiu',
                            editable: true,
                            width: 50,
                            ...stdBoolColumn(),
                        },
                        {
                            field: 'id',
                            headerName: 'Id',
                            editable: false,
                            width: 60,
                            ...stdIntNumericColumn(),
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    params.api.exportDataAsExcel({
                                        fileName: 'llistat-linees-negoci',
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'isActive') {
                                                return data.isActive == true ? 'X' : ''
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    rowData={store.businessLineRes}
                    selection={store.businessLineSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        await store.saveBusinessLine({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                    }}

                />
            }
        />
    </Container>
})
