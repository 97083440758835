import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SelectControl, InputControl} from "react-c4";
import ReactSelect from "react-select";
import {Button, Radio, RadioGroup, Spinner} from "@blueprintjs/core";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {ProcessTabLayout} from "../components/ProcessTabLayout";


const Container = styled.div`
    display: grid;
    grid-gap: 8px;
`
const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
    grid-gap: 4px;
`

export interface RGPDProps {
    store: PatientOverviewStore
}

export const RGPD = observer((props: RGPDProps) => {
    let [s, setS] = useState('cash')
    let [step, setStep] = useState(0)
    return <ProcessTabLayout title={'RGPD'} buttonsFragment={<React.Fragment>
        <Button onClick={() => props.store.closeAdditionalTab()}>Cancelar</Button>
        <Button rightIcon={'arrow-right'} onClick={() => setStep(1)}>Seguent pas</Button>
    </React.Fragment>}>
        <Container>
            {step === 0 && <React.Fragment>
              <div style={{width: 140}}>
                <ReactSelect
                  placeholder={'Idioma'}
                  defaultValue={{
                      id: 1,
                      name: 'Català'
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  options={[
                      {
                          id: 1,
                          name: 'Català'
                      }, {
                          id: 2,
                          name: 'Castellà'
                      }, {
                          id: 3,
                          name: 'Anglès'
                      }, {
                          id: 4,
                          name: 'Francès'
                      }, {
                          id: 5,
                          name: 'Alemany'
                      },
                  ]}
                />
              </div>
              <RadioGroup
                  // label="Signatura RGPD"
                onChange={(v: any) => setS(v.target.value)}
                selectedValue={s}
              >
                <Radio label="Signar digitalment (tablet)" value="tablet" />
                <Radio label="Imprimir / Escanejar" value="print" />
              </RadioGroup>
            </React.Fragment>}
            {step === 1 && <React.Fragment>
              <div>Connectat a la tablet</div>
              <div><Spinner  size={12}/>Esperant firma del pacient</div>
            </React.Fragment>}
        </Container>
    </ProcessTabLayout>

})
