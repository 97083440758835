import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, Icon, Popover, Tab, Tabs} from "@blueprintjs/core";
import {useAppStore} from "../../../../utils/utils";
import {TAContrastAlertsTab} from "./components/TAContrastAlertsTab";
import _ from "lodash";
import moment from "moment";

const Container = styled.div`
    display: grid;
    
`


const ItemContainer = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-template-columns: auto 1fr;
    align-items: center;
`
const IndicatorContainer = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 50%;
`
const InnerContainer = styled.div`
    display: grid;
    position: relative;
    //padding: 4px;
    padding: 2px 3px;
`
const LabelContainer = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 9px;
    font-weight: bold;
    line-height: 0px;
    color: #bd1313;
`

const PopoverContainer = styled.div`
    padding: 8px;
    display: grid;
    grid-auto-flow: row;
    width: 550px;
    min-height: 300px;
    grid-template-rows: auto 1fr;
`

export interface TrayAlertsProps {

}

let int1 = 0
export const TrayAlerts = observer((props: TrayAlertsProps) => {
    let appStore = useAppStore()
    let contrast = appStore.contrastAlertsRes

    useEffect(() => {
        let fn = _.throttle(() => appStore.fetchContrastAlerts(), 3000)
        let s1 = appStore.events.obs.subscribe((e) => {
            if (!['v', 'vl'].includes(e.entity)) return
            let hit = _.isArray(e.days) && e.days.some(updatedDay => moment(updatedDay).isBetween(moment(), moment(), "day", "[]"))
            // console.log(`HIT`, hit);
            hit = true
            if (hit) {
                fn()
            }
        })
        return () => s1.unsubscribe()
    }, [])

    let contrastAlertsCount = appStore.contrastAlertsRes?.length || 0;
    return <Container><Popover
        // isOpen={true}
        content={
            <PopoverContainer>
                <Tabs id="TabsExample" onChange={() => null} selectedTabId="cont" >
                    <Tab id="cont" title={`Notificaciones farmacia (${contrastAlertsCount})`} panel={<div/>}/>
                    <Tab id="bb" disabled title="Otros (0)" panel={<div/>} />
                    <Tabs.Expander/>
                    <Button icon={'refresh'} onClick={() => {
                        appStore.fetchContrastAlerts()
                    }}/>
                </Tabs>
                <TAContrastAlertsTab/>
            </PopoverContainer>
        }
    >

        {/*<Icon icon={'cell-tower'}/>*/}
        <InnerContainer>
            <Icon intent={contrastAlertsCount > 0 ? 'warning' : 'none'} icon={'notifications'}/>
            {contrastAlertsCount > 0 && <LabelContainer>{contrastAlertsCount}</LabelContainer>}
        </InnerContainer>

    </Popover>
    </Container>
})
