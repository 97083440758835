import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {FeatureFilePageHeader} from "./FeatureFilePageHeader";
import {FFVStore} from "../../files-views/FeatureFileView/FFVStore";
import {FeatureFileView} from "../../files-views/FeatureFileView/FeatureFileView";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface FeatureFilePageProps {
    pageContext
}

export const FeatureFilePage = page(observer((props: FeatureFilePageProps) => {
    let [store, setStore] = useState(null as FFVStore);

    return <Page
        title={'Feature editor'}

        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <FeatureFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <FeatureFileView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                 {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
