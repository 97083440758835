import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useULEStore} from "../UserLogEntriesView";
import {C3AgGrid, MasterLayout} from "react-c4/src/index";
import {CellValueChangedEvent, ICellRendererParams, ValueFormatterParams} from "ag-grid-community";
import {
    formatFormalName,
    formatFullName,
    stdDateColumn,
    stdHourColumn,
    stdIntNumericColumn
} from "../../../application/utils/utils";
import {Icon} from "@blueprintjs/core";
import * as _ from 'lodash'

const Container = styled.div`
  display: grid;
  height: 100%;
  padding: 8px;

`

export interface ULEMainProps {
    pageContext
}

export const ULEMain = observer((props: ULEMainProps) => {
    let store = useULEStore()

    return <Container>
        <MasterLayout
            actions={[]}
            renderHeader={() => null}
            body={
                <C3AgGrid
                    onGridReady={({api}) => {
                        store.gridApi = api
                        store.onGridInit()
                    }}
                    columnDefs={[
                        {
                            field: 'date',
                            headerName: 'Data',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            ...stdDateColumn()
                        }, {
                            field: 'date',
                            headerName: 'Hora',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            ...stdHourColumn()
                        },
                        {
                            field: 'user',
                            headerName: 'Usuari',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            valueFormatter: params => {
                                if (params.value == null) return null
                                return formatFullName(params.value)
                            },
                            cellRendererFramework: (params: ICellRendererParams) => {
                                if (params.value == null) return null
                                return <div style={{
                                    display: 'grid',
                                    alignItems: "center",
                                    gridTemplateColumns: 'auto 1fr',
                                    gridGap: 4
                                }}>
                                    <Icon icon={'user'} style={{color: 'gray'}}/>
                                    {params.valueFormatted}
                                </div>
                            },
                            width: 110
                        },
                        {
                            field: 'action',
                            headerName: 'Acció',
                            filter: 'agTextColumnFilter',
                            type: ['rightAligned'],
                            floatingFilter: true,
                            cellRendererFramework: (params: ICellRendererParams) => {
                                let colorMap = {
                                    C: '#4da245',
                                    R: '#2e98b8',
                                    U: 'orange',
                                    D: 'red',
                                }
                                let textMap = {
                                    C: 'Crea',
                                    R: 'Consulta',
                                    U: 'Modifica',
                                    D: 'Esborra',
                                }
                                return <div style={{color: colorMap[params.value], fontWeight: 'bold'}}>
                                    {textMap[params.value]}
                                </div>
                            }
                        },
                        {
                            field: 'entity',
                            headerName: 'Entitat',
                            filter: 'agTextColumnFilter',
                            type: ['rightAligned'],
                            floatingFilter: true,
                            cellRendererFramework: (params: ICellRendererParams) => {

                                let textMap = {
                                    'patient': 'Pacient',
                                    'visit': 'Visita',
                                    'visit-line': 'Línia Visita',
                                    'invoice': 'Factura',


                                }
                                return <div style={{color: 'gray', fontWeight: 'bold'}}>
                                    {textMap[params.value] || _.startCase(params.value)}
                                </div>
                            }
                        },
                        {
                            field: 'entityId',
                            headerName: 'Id Entitat',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 80,
                            ...stdIntNumericColumn()
                        },
                        // {
                        //     field: 'existingValue',
                        //     headerName: 'Valor',
                        //     filter: 'agTextColumnFilter',
                        //     floatingFilter: true,
                        //     width: 280,
                        //
                        // },
                        {
                            field: 'description',
                            headerName: 'Descripció',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 1000,
                            valueFormatter: (params: ValueFormatterParams) => {
                                let objects = [params.data.existingValue, params.data.changedTo]
                                let str = _.uniq(objects
                                    .filter(v => v)
                                    .map(v => {
                                        let s = ''
                                        s = formatFormalName(v);
                                        // if (!_.trim(s)) return JSON.stringify(v)
                                        return s
                                    }))
                                    .join(' 🡒 ')
                                return str
                            },
                        },
                    ]}
                    rowData={store.logsRes}
                    selection={store.logsSel}
                />
            }
        />
    </Container>
})
