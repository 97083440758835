import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4";
import {AppStore} from "../../application/AppStore";
import {action, autorun, computed, observable, reaction} from "mobx";
import MobxReactForm from "mobx-react-form";
import {paymentFormMap} from "../../application/utils/utils";
import * as _ from 'lodash'
import {end} from "@popperjs/core";
import moment from "moment";
import {ChangedPath, GridApi} from "ag-grid-community";

export class DCSStore {
    appStore: AppStore

    pageContext

    filterForm: MRF

    @observable
    res: any

    sel: C3Selection
    gridApi: GridApi

    typesOptions

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.typesOptions = _.entries(_.pick({...paymentFormMap, all: 'Tots'}, ['cc', 'cash', 'transfer', 'all']))
            .map(([k, v]) => ({id: k, name: v}))
        this.sel = new C3Selection({})
        this.filterForm = new MobxReactForm({
            fields: [
                'startDate',
                'endDate',
                'orgs',
                'type',
            ],
            // initials,
            // defaults,
            values: {
                startDate: new Date(),
                endDate: new Date(),
                orgs: [],
                type: 'cc',

            },
            // labels,
            // placeholders,
            // rules,
            // extra,
            hooks: {}
        })
        this.filterForm.$('orgs').set(this.appStore.orgsSel.selectedItem);
        reaction(() => {
            let startDate = this.filterForm.$('startDate').value;
            let endDate = this.filterForm.$('endDate').value;
            let type = this.filterForm.$('type').value;
            return {
                startDate,
                endDate,
                type
            }
        },() => {
            return this.fetch();
        }, {delay: 5});

        this.fetch();
    }

    @action
    async fetch() {
        let startDate = moment(this.filterForm.$('startDate').value).startOf('day').toDate();
        let endDate = moment(this.filterForm.$('endDate').value || startDate).endOf('day').toDate();
        let type = this.filterForm.$('type').value;
        const orgs = this.appStore.orgsSel.selectedId ? `orgs=${this.appStore.orgsSel.selectedId}` : '';
        if (orgs === '') return
        let newVar = await C3.instance.client.fetcher.fetch(`/daily-checkout-sheet/state`, {
            method: 'GET',
            query: [`start=${startDate.toISOString()}`, `end=${endDate.toISOString()}`, `type=${type}`, orgs].join('&'),
        });
        this.res = newVar
        this.sel.itemsRef.current = newVar

        return newVar
    }

    async moveRange(days: number) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');
        [$startDate, $endDate].forEach($d => $d.onChange(moment($d.value).add(days, "days").toDate()))
    }

    async goToDate(date: Date) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');
        [$startDate, $endDate].forEach($d => $d.onChange(moment(date).toDate()))
    }

    printHandle

    @action
    async print() {
        if (!this.printHandle) return
        this.gridApi.setDomLayout('print')
        setTimeout(async () => {
            await this.printHandle()
            this.gridApi.setDomLayout(null)
        })
    }

    @computed
    get total(){
        return _.sum((this.res || []).map(p => Number(p.amount)))
    }
}
