import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const contactSchema: C3SchemaInfo = {
    'contact': {
        fields: {
            id: {},
            surname1: { label: "1er Cognom"},
            surname2: { label: "2on Cognom"},
            name: { label: "Nom"},
            isActive: {
                label: "Actiu",
            },
            isHistorical:{
                label: "Històric",
            },
            isCompany:{
                label: "Es companyia",
            },
            cip:{
                label: "CIP",
            },
            mobile:{
                label: "Mòbil",
            },
            phone2:{
                label: "Telèfon",
            },
            email:{
                label: "E-mail",
            },
            birthDate:{
                label: "Data de naixement"
            },
            nif: {
                label: "NIF",
            },
            sex: {
                label: "Gènere",
            },
            language:{
                label: "Llenguatge",
            },
            status:{
                label: "Status",
            },
            address:{
                label: "Adreça",
            },
            postalCode:{
                label: "Codi Postal",
            },
            city:{
                label: "Ciutat",
            },
            state:{
                label: "Estat",
            },
            countryCode:{
                label: "Codi del Pais",
            },
            notes:{
                label: "Notes",
            },
            isInsCompany: {
                label: "Companyia asseguradora",
            },
            isExtCenter: {
                label: "Centre extern",
            },
            isMedical:{
                label: "Contacte Medic",
            },
            isSalesman:{
                label: "Venedor",
            },
            isTechnician: {
                label: "Tècnic",
            },
            isDoctor: {
                label: "Doctor",
            },
            // TODO Contact fields

            rateSheet: {
                type: 'rate-sheet',
                label: 'Full de tarifes'
            },
            salesman:{
                type: 'salesman',
                label: 'Venedor'
            },
        }
    }
}
