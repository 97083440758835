import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const rateSchema: C3SchemaInfo = {
    'rate': {
        fields: {
            id: {},
            name: {
                label: 'Nom'
            },
            description: {
                label: 'Descripció'
            },
            code: {
                label: 'Codi'
            },
            price: {
                label: 'Preu'
            },
            ordinal: {
                label: 'Ordinal'
            },
            invoiceText: {
                label: 'Text factura'
            },
            active: {
                label: 'Actiu'
            },
            rateSheet: {
                type: 'rate-sheet',
                label: 'Full de tarifes',
            },
            referenceRate:{
                type: 'rate',
                label: 'Tarifa de referència',
            },
            products: {
                type: 'product',
                multivalued: true,
                label: 'Proves estàndard'
            }
            // TODO Add all fields
        }
    }
}
