import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {formatFormalName, formatFullName, useAppStore} from "../../../utils/utils";

const Container = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    align-content: center;
    justify-items: center;
    color: #137cbd;
    font-size: 11px;
`
const UserNameContainer = styled.div`
    
`
const UserRoleContainer = styled.div`
    
`


export interface TrayUserProps {

}

export const TrayUser = observer((props: TrayUserProps) => {
    let appStore = useAppStore()
    let loggedInUser = appStore.auth.loggedInUser;
    return <Container>
        <UserNameContainer>{appStore.auth.loggedInUser ? formatFullName(loggedInUser) : 'Convidat'}</UserNameContainer>
        {appStore.auth.loggedInUser && <UserRoleContainer>({appStore.auth.loggedInUser?.org?.shortName})</UserRoleContainer>}
    </Container>
})
