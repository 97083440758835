import React, {useEffect, useRef} from 'react';
import moment from 'moment';
import InvoicePage from "./InvoicePage";
import {AgGridColumnProps} from "ag-grid-react";
import {observer} from 'mobx-react';
import {useReactToPrint} from 'react-to-print';
import {MRF} from 'react-c4';


export interface InvoiceProps {
    invoice: {
        invoiceLines: {
            text?
            quantity?
            unitPrice?
            price?
            date?: Date
            [other: string]: any
            authorizationNumber?
        }[],
        total?,
        organization?,
        patient?,
        invoice?,

        bankAccount?,
        contact?,
        [other: string]: any

    }
    columnDefs: AgGridColumnProps[],
    organizationData?,
    patientData?,
    invoiceData?,
    insCompanyData?,
    notes?,
    case?,
    accountNumber?,

    onPrintReady?: (handle) => null
    invoiceForm?: MRF
    type: 'private' | 'ins-company'
    isPrinting
    lineCountMult?: number
    //insCompaniesData: any,
}


export const Invoice = observer((props: InvoiceProps) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (props.onPrintReady) props.onPrintReady(handlePrint)
    }, [])

    if (props.invoice == null) return <div>No s'ha trobat la factura</div>

    let lines = props.invoice.invoiceLines || []

    /**
     * @deprecated
     */
    const hardcodedInvoiceData = {
        company: "INSTITUTO DE RESONANCIA MAGNETICA DRA. GUIRADO, S.L.P.",
        companyNif: "B-61395646",
        address: "MUNTANER 531",
        cp: "08022",
        city: "BARCELONA",
        phone: "93 200 32 00/93 418 45 45",
        mail: "info@institutsguirado.com",
        receiverCompany: "Ib-Salut – Gerencia 603 – Area de Salut d’Eivissa i Formentera",
        receiverAddress: "C/Corona, s/n – Edificio J -Hospital Can Misses",
        receiverCp: "07800",
        receiverCity: "Ibiza",
        receiverCif: "Q0719003F",
        receiverClientCode: "CLDES07800-17",
        receiverMail: "admin@ib-salut.com",
        receiverPhone: "+34 978 505 86",
        invoiceNumber: "IRM" + props.invoice.invoiceNumber,
        invoiceDate: "17-03-2020",
        invoicePeriod: "desde 01/02/20 hasta 28/02/20",
        invoiceCoin: "EUR",
        patientName: "Francisco José López González",
        patientPolicy: "75645987",
        patientAddress: "C/Pujada de les Arenes, 27",
        patientCp: "07802",
        patientCity: "Ibiza",
        patientNif: "Q0719003F",
        patientCode: "CLPES07892-77",
        patientMail: "admin@ib-salut.com",
        patientPhone: "+34 978 505 86",
        notes: "Això és un exemple de com quedaría una nota extensa. Sempre ubicades en aquesta zona. Quan volguèssim fer referencia a dades d’un paciente, aniria al quadre el costat.",
        case: "2017 20094",
        lines: lines,

        accountNumber: "ES35 0182 4853 5602 0155 6645",
        mercantilRegistry: "Registro Mercantil de Barcelona Hoja B164367,Tomo 29887, Inscripción 1ª. NIF B61395646",
        vat: 0,
        vatPercent: 0,
        irpf: 0,
        irpfPercent: 0,
        totalPrice: "1.152,65",
        account: "Payment" // 'Transferencia bancaria a nuestra cuenta: 2100 1047 94 0200147373',
    };


    let res = [];
    let mult = props.lineCountMult || 1;
    let number = 35 * mult;
    let iterations = Math.ceil(lines.length / number);
    if (iterations == 0) iterations = 1
    for (let i = 0; i < iterations; i++) {
        let last = false;
        let linesForPage = [];

        if (i === iterations - 1) {
            last = true;
        }
        linesForPage = lines.slice(i * number, ((i + 1) * number));
        res.push(<InvoicePage
            type={props.type}
            key={i} lines={linesForPage} page={i + 1}
            lastpage={iterations == i + 1}
            columnDefs={props.columnDefs}
            invoice={props.invoice}
            invoiceForm={props.invoiceForm}
            pageCount={iterations}
        />);

    }
    // account: _.get(invoice,'embeddedClient.bankAccount'),
    return <div id={'invoice_content'}
                style={{display: "grid", gridAutoFlow: "row", gridGap: props.isPrinting ? 0 : 16}}
                ref={componentRef}>{res}</div>;
})

/*
 let exampleLines = [
 {
 patientName: "ARIAS CENTELLES, SUSANA",
 auth: "",
 date: "12/03/20",
 desc: "RM RODILLA",
 unit: "1",
 price: "90,00",
 },
 {
 patientName: "ARIAS CENTELLES, SUSANA",
 auth: "",
 date: "12/03/20",
 desc: "01317 RODILLA. UNA PROYECCION",
 unit: "3",
 price: "18,03",
 },
 {
 patientName: "ARIAS CENTELLES, SUSANA",
 auth: "",
 date: "12/03/20",
 desc: "06781 RODILLA. DOS PROYECCIONES",
 unit: "1",
 price: "9,48",
 },
 {
 patientName: "ARIAS CENTELLES, SUSANA",
 auth: "",
 date: "12/03/20",
 desc: "01310 GONIOMETRIA, MEDICION EE.II. 1P",
 unit: "1",
 price: "15,03",
 },
 {
 patientName: "BARBU BARBU, NICOLAE ADRIAN",
 auth: "",
 date: "13/03/20",
 desc: "RM EXTREMIDADES 2ª",
 unit: "2",
 price: "60,00",
 },
 {
 patientName: "BARBU BARBU, NICOLAE ADRIAN",
 auth: "",
 date: "13/03/20",
 desc: "RM COLUMNA",
 unit: "2",
 price: "120,00",
 },
 {
 patientName: "BARBU BARBU, NICOLAE ADRIAN",
 auth: "",
 date: "13/03/20",
 desc: "RM COLUMNA 2º",
 unit: "2",
 price: "120,00",
 },
 {
 patientName: "BATISTA MIRANDA, MARIA EUGENIA",
 auth: "",
 date: "03/03/20",
 desc: "RM CEREBRAL",
 unit: "1",
 price: "90,00",
 },
 {
 patientName: "BEA LLIMA, JOSEP MARIA",
 auth: "",
 date: "03/03/20",
 desc: "TAC CABEZA Y CUELLO",
 unit: "1",
 price: "68,00",
 },
 {
 patientName: "DE PEDRO BORRAS, PILAR",
 auth: "",
 date: "03/03/20",
 desc: "RM MUSCULO-ESQUELETICA",
 unit: "1",
 price: "90,00",
 },
 {
 patientName: "GRACENEA ALDAYA, FELICIANA",
 auth: "",
 date: "02/03/20",
 desc: "TAC RODILLA",
 unit: "1",
 price: "68,00",
 },
 {
 patientName: "LEGAZ SANZ, ANTONIO",
 auth: "",
 date: "05/03/20",
 desc: "01300 RX TORAX 1P",
 unit: "1",
 price: "7,21",
 },
 {
 patientName: "LEGAZ SANZ, ANTONIO",
 auth: "",
 date: "05/03/20",
 desc: "01299 RX TORAX SUC",
 unit: "1",
 price: "4,59",
 },
 {
 patientName: "LEGAZ SANZ, ANTONIO",
 auth: "",
 date: "05/03/20",
 desc: "01302 RX BRAZO 1P",
 unit: "1",
 price: "4,14",
 },
 {
 patientName: "LEGAZ SANZ, ANTONIO",
 auth: "",
 date: "05/03/20",
 desc: "01301 RX BRAZO SUC",
 unit: "1",
 price: "10,17",
 },
 {
 patientName: "MARTINEZ FRON, MARTA MARIA",
 auth: "",
 date: "13/03/20",
 desc: "RM MAMA",
 unit: "1",
 price: "130,00",
 },
 {
 patientName: "MILARA RAMIREZ, VALERIANA",
 auth: "",
 date: "03/03/20",
 desc: "RM EXTREMIDADES",
 unit: "1",
 price: "90,00",
 },
 {
 patientName: "RIERA FIGUERAS, FRANCESC",
 auth: "",
 date: "11/03/20",
 desc: "TAC CABEZA Y CUELLO",
 unit: "1",
 price: "68,00",
 },
 {
 patientName: "ZAGO MARTIN, LINO",
 auth: "",
 date: "04/03/20",
 desc: "RM RODILLA",
 unit: "1",
 price: "90,00",
 },
 {
 patientName: "ZAGO MARTIN, LINO",
 auth: "",
 date: "04/03/20",
 desc: "RM RODILLA 2º",
 unit: "1",
 price: "30,00",
 },
 ];

 */
