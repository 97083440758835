import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {OrgFVStore} from "../../files-views/OrgFileView/OrgFVStore";

const Container = styled.div`
    display: grid;
    
`

export interface OrgFilePageHeaderProps {
    store: OrgFVStore
}

export const OrgFilePageHeader = (props: OrgFilePageHeaderProps) => {
    return <Container>

    </Container>
}
