import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ProductFamiliesFVStore} from '../../ProductFamiliesFVStore'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useProductFamiliesFVStore} from "../../ProductFamiliesFileView";
import {RowNode} from "ag-grid-community";
import {formatFullName} from "../../../../application/utils/utils";

const Container = styled.div`
  display: grid;

`

export interface PFEditorFVProps {
    pageContext
}

export const PFEditorFV = observer(() => {
    let store = useProductFamiliesFVStore()
    return <Container>
        <MasterLayout renderHeader={() => {
            return null
        }}
                      actions={[
                          {
                              name: 'create',
                              iconName: 'document',
                              callback: () => {
                                  store.createProductFamily()
                              }
                          },
                          {
                              name: 'delete',
                              iconName: 'trash',
                              callback: () => {
                                  store.deleteSelectedProductFamily()
                              },
                              confirmationText: 'Està segur que vol borrar aquesta familia de proves',
                              confirmation: true
                          }
                      ]}
                      body={
                          <C3AgGrid
                              columnDefs={[
                                  {
                                      field: 'name',
                                      headerName: 'Nom',
                                      editable: true,
                                      filter: 'agTextColumnFilter',
                                      floatingFilter: true,
                                  },
                                  {
                                      field: 'color',
                                      headerName: 'Color',
                                      editable: true,
                                      filter: 'agTextColumnFilter',
                                      floatingFilter: true,
                                  },
                              ]}
                              processCellCallback={(params) => {
                                  let data = (params.node as RowNode).data
                                  return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                              }}
                              getContextMenuItems={(params) => {
                                  // let fileName = store.fr
                                  var result = [
                                      {
                                          // custom item
                                          name: 'Exporta a Excel',

                                          action: function () {
                                              params.api.exportDataAsExcel({
                                                  fileName: 'llistat-linees-negoci',
                                                  processCellCallback: (params) => {
                                                      let data = (params.node as RowNode).data
                                                      if (params.column.getColId() == 'isActive') {
                                                          return data.isActive == true ? 'X' : ''
                                                      }
                                                      return params.value
                                                  }
                                              })
                                          },
                                      }
                                  ]
                                  return result
                              }}
                              rowData={store.prodFamilyRes}
                              selection={store.prodFamilySel}
                              cellEditorSubmitter={async (e)=>{
                                  await store.saveProductFamily({
                                      id: e.data.id,
                                      [e.colDef.field]: e.newValue
                                  })
                              }
                              }
                          />
                      }
        />
    </Container>
})
