import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {CellValueChangedEvent, IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import {C3AgGrid, MasterLayout } from "react-c4";
import {useRefFVStore} from "../ReferrerFileView";
import {formatFullName, stdBoolColumn, stdIntNumericColumn} from "../../../application/utils/utils";
import {Button} from "@blueprintjs/core";
import * as _ from "lodash";
import moment from "moment";
import {SelectCE} from "../../../application/utils/components/SelectCE";

const Container = styled.div`
    display: grid;
  grid-template-rows: auto 1fr;
`
const GridTitle = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
`

export interface RefFVPendingReviewGridProps {

}

export const RefFVPendingReviewGrid = observer((props: RefFVPendingReviewGridProps) => {
    let store = useRefFVStore()

    return <Container>
        <GridTitle>(1) Seleccioni un o varis Referidors a Acceptar o Fusionar amb ja existents.</GridTitle>

        <MasterLayout
            actions={[
                {
                    name: 'create',
                    iconName: 'document',
                    callback: () => {
                        store.createReferrer()
                    }
                },
                {
                    name: 'delete',
                    iconName: 'trash',
                    enabled: store.canErase,
                    confirmation: true,
                    confirmationText: 'Està segur que vol esborrar aquest doctor referidor?',
                    callback: () => {
                        store.deleteSelectedReferrer()
                    }
                },
            ]}
            renderHeader={() => null}
            body={
                <C3AgGrid
                    columnDefs={[
                        {
                            field: '',
                            headerName: '',
                            width: 30,
                            checkboxSelection: true,
                            // suppressFilter: true,
                        },
                        {
                            field: 'createdAt',
                            headerName: 'Data creació',
                            editable: false,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            valueFormatter({value}) {
                                if (value == null) return '(Sense data)'
                                return moment(value).format('DD/MM/YYYY');
                            },
                            width: 95,
                        },
                        {
                            field: 'treatment',
                            headerName: 'Tr.',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 46,
                            cellEditor: 'agRichSelectCellEditor',
                            cellEditorParams: (params: IRichCellEditorParams) => {
                                return {
                                    values: store.treatmentMap,
                                    formatValue: (params) => {
                                        return params
                                    },
                                }
                            },
                            valueFormatter: (params: ValueFormatterParams) => {
                                if (_.isEmpty(params.value)) return '-'
                                return params?.value.tr
                            },
                        },
                        {
                            field: 'name',
                            headerName: 'Nom',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'surname1',
                            headerName: 'Cognom 1',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'surname2',
                            headerName: 'Cognom 2',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'visitCount',
                            headerName: 'Vistes',
                            editable: false,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            ...stdIntNumericColumn(),
                            width: 50
                        },
                        {
                            field: 'specialty',
                            headerName: 'Especialitat',
                            editable: true,
                            cellEditorFramework: SelectCE,
                            width: 125,
                            cellEditorParams: (params: IRichCellEditorParams) => {
                                return {
                                    values: store.specialtyListRes,
                                    formatValue: (params) => {
                                        return params?.name
                                    },
                                }
                            },
                            valueFormatter: (params: ValueFormatterParams) => {
                                if (_.isEmpty(params.data?.specialtyName)) return '-'
                                return params.data?.specialtyName
                            },
                        },
                        {
                            field: 'protocol',
                            headerName: 'Protocol',
                            editable: true,
                            cellEditor: 'agLargeTextCellEditor',
                            filter: 'agTextColumnFilter',
                            width: 200
                        },
                        {
                            field: 'comment',
                            headerName: 'Comentari',
                            editable: true,
                            cellEditor: 'agLargeTextCellEditor',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 200
                        },
                        {
                            field: 'id',
                            headerName: 'Id',
                            editable: false,
                            width: 60,
                            ...stdIntNumericColumn(),
                        },
                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
                                    params.api.exportDataAsExcel({
                                        fileName: `llistat-referidors-pendents${orgName}`,
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'createdAt') {
                                                return moment(data.createdAt).format('DD/MM/YYYY')
                                            }
                                            if (params.column.getColId() == 'specialty' && !!data.specialtyName == true){
                                                return data.specialtyName
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    rowData={store.pendingReferrersRes}
                    selection={store.pendingReferrersSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        await store.saveReferrer({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                    }}
                    rowSelection={'multiple'}

                />
            }
        />
    </Container>
})
