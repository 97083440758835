import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MPEStore} from "./MPEStore";
import {useC3Store} from "react-c4";
import {MPEMain} from "./MPEMain/MPEMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const MPEContext = React.createContext(null as MPEStore)
export const useMPEStore = () => useContext(MPEContext)

export interface MeetupPushEventsViewProps {
    pageContext?
    onStoreReady: (s: MPEStore) => any
}

export const MeetupPushEventsView = observer((props: MeetupPushEventsViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new MPEStore()
        storeInst.init();
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <MPEContext.Provider value={store}>
        <Container>
            <MPEMain pageContext={props.pageContext}/>
        </Container>
    </MPEContext.Provider>
})
