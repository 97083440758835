import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {IEVStore} from "../../components/InvoiceEditorView/IEVStore";

const Container = styled.div`
    display: grid;
    
`

export interface InvoiceEditorPageHeaderProps {
    store: IEVStore
}

export const InvoiceEditorPageHeader = observer((props: InvoiceEditorPageHeaderProps) => {
    return <Container>

    </Container>
})
