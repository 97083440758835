import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, IInputGroupProps, InputGroup} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  & > div {
    //height: 12px;
    line-height: 12px;
    > :nth-child(1) {
      //margin: 4px;
      > svg {
        //height: 16px;

      }
    }
    > :nth-child(2) {
      :focus {
        box-shadow: 0 0 0 0.5px #137cbd, 0 0 0 1px rgba(19, 124, 189, 0.3)
      }
    }
    > :nth-child(2) {
      > svg {
        height: 12px;

      }
    }
  }
  
`
const ItemsContainer = styled.div`
    display: grid;
    //grid-gap: 1px;
  grid-auto-flow: column;
`

export interface SmallSearchCreateInputProps {
    inputProps?: IInputGroupProps
    onClearClick?
}

export const SmallSearchCreateInput = observer((props: SmallSearchCreateInputProps) => {
    return <Container>
        <InputGroup round={true} leftIcon={"search"} rightElement={
            <ItemsContainer>
                {/*<Button minimal icon={'plus'}/>*/}
                <Button minimal icon={'cross'} onClick={props.onClearClick}/>
            </ItemsContainer>
        }
                    {...props.inputProps}
        />
    </Container>
})
