import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Button} from "@blueprintjs/core";
import {useSVVStore} from "../../../StandardVisitView";

const Container = styled.div`
    display: grid;
    justify-content: start;
    grid-auto-flow: column;
    grid-gap: 4px;
`

export interface SVVPrivateInvoicePreviewHeaderProps {

}

export const SVVPrivateInvoicePreviewHeader = observer((props: SVVPrivateInvoicePreviewHeaderProps) => {
    let store = useSVVStore()

    return <Container>
        <Button
            icon={'print'}
            onClick={() => {
                store.printInvoice()
            }}>{store.privateInvoicesSel.selectedItem?.invoiceNumber ? 'Imprimir' : 'Imprimir temporal' }</Button>
        <Button
            icon={'cross'}
            onClick={() => {
                store.closePrivateInvoicingMode()
            }}>Sortir</Button>
        <Button
            icon={'zoom-in'}
            disabled={store.invoicePreviewZoom > 200}
            onClick={() => {
                store.invoicePreviewZoom += 15
            }}>Amplia</Button>
        <Button
            icon={'zoom-out'}
            disabled={store.invoicePreviewZoom < 20}
            onClick={() => {
                store.invoicePreviewZoom -= 15
            }}>Redueix</Button>

        <div/>
        <Button
            icon={!store.isPrivateInvoicePreviewCollapsed ? 'double-chevron-right' : 'double-chevron-left'}
            onClick={() => store.isPrivateInvoicePreviewCollapsed = !store.isPrivateInvoicePreviewCollapsed}
        />
        {/*Id: {store.invoice?.id}*/}

    </Container>
})
