import {C3Selection, IATSStyleApp, C3, MRF} from "react-c4";
import {AppStore} from "../../application/AppStore";
import {autorun, observable} from "mobx";
import MobxReactForm from "mobx-react-form";
import numeral from 'numeral'
import {nextAnimationFrame, nextTimeout} from "../../application/utils/utils";
import * as _ from 'lodash'

export class RateFVStore {

    appStore: AppStore

    pageContext

    filterForm: MRF

    rateTypeSel: C3Selection

    @observable
    rateSheetsRes

    rateSheetsSel: C3Selection

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection

    @observable
    ratesRes: any[]

    ratesSel: C3Selection

    @observable
    statsRes: any

    @observable
    showInactiveRates = false

    @observable
    showInactiveInsCompanies = false

    @observable
    excelDialogOpen = false

    async init() {

        this.appStore = IATSStyleApp.instance.appStore
        this.orgsRes = this.appStore.orgs || [];
        this.rateSheetsSel = new C3Selection({
            items: this.rateSheetsRes,
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                setTimeout(async () => {
                    let selectedId = this.rateSheetsSel.selectedId;
                    await nextTimeout()
                    await this.fetchRates(selectedId)
                })
            }
        })
        this.filterForm = new MobxReactForm({
            fields: [
                'type',
                'orgs'
            ],
            values: {
                type: 'ins-company',
                orgs: []
            }
        })
        this.ratesSel = new C3Selection({
            items: this.ratesRes,
        })
        this.orgsSel = new C3Selection(({
            items: this.orgsRes
        }))
        this.rateTypeSel = new C3Selection(({
            items: [
                {id: 'ins-company', name: 'Mútues'},
            ],
            onSelectionChanged: () => {
                this.filterForm.$('type').set(this.rateTypeSel.selectedItem.id)
            }
        }))
        this.rateTypeSel.selectedIds = ['ins-company']

        await this.fetch()
        await this.fetchStats()

        autorun(() => {
            let type = this.filterForm.$('orgs').value;
            this.rateSheetsSel.selectedIds = []
            this.fetch()
            this.ratesRes = []
        })
        autorun(async () => {
            this.orgsRes = this.appStore.orgs;
            this.orgsSel.itemsRef.current = this.orgsRes;
            this.orgsSel.selectedIds = [this.orgsRes[0].id];

        })
        autorun(async () => {
            this.showInactiveRates;
            await this.fetchRates(this.rateSheetsSel.selectedId)
        })

        autorun(async () => {
            this.showInactiveInsCompanies;
            await this.fetch()
        })
    }

    //rateSheet
    async fetch() {
        const inactive = `inactive=${this.showInactiveInsCompanies.toString()}`
        const orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (orgs === '') return


        this.rateSheetsRes = await C3.instance.client.fetcher.fetch('/rate-sheet/with-rate-count', {
                query: [orgs, inactive].join('&')
            }
        )
        this.rateSheetsSel.itemsRef.current = this.rateSheetsRes;
        console.log(`this.rateSheetsRes`, this.rateSheetsRes);
        return this.rateSheetsRes
    }

    async fetchStats() {
        this.statsRes = await C3.instance.client.fetcher.fetch('/rate-sheet/stats', {})
    }

    async refresh() {
        await this.fetch()
        await this.fetchStats()
        await this.fetchRates(this.rateSheetsSel.selectedId)
    }

    async createRateSheet() {
        await C3.instance.client.fetcher.fetch('/rate-sheet', {
            method: 'POST',
            body: {name: 'nou full de tarifes'}
        })
        await this.fetch()
        await this.fetchStats()

    }

    async deleteRateSheet(rateSheetId) {
        await C3.instance.client.fetcher.fetch(`/rate-sheet/${rateSheetId}`, {
            method: 'DELETE',
        })
        await this.fetch()
        await this.fetchStats()

    }

    async deleteSelectedRateSheet() {
        let selectedId = this.rateSheetsSel.selectedId;
        if (selectedId == null) return
        await this.deleteRateSheet(selectedId)
    }

    async saveRateSheet(rateSheet) {
        await C3.instance.client.fetcher.fetch(`/rate-sheet/${rateSheet.id}`, {
            method: 'PATCH',
            body: rateSheet
        })
    }


    //rate
    async fetchRates(rateSheetId) {
        if (rateSheetId == null) return [];
        const inactive = `inactive=${this.showInactiveRates.toString()}`
        this.ratesRes = await C3.instance.client.fetcher.fetch(`/rate-sheet/${rateSheetId}/rates`, {
            query: inactive
        })
        this.ratesSel.itemsRef.current = this.ratesRes;
        return this.ratesRes
    }

    async createRate() {
        let id = this.rateSheetsSel.selectedId;
        if (id == null) return;
        await C3.instance.client.fetcher.fetch('/rate', {
            method: 'POST',
            body: {rateSheet: {id}, name: 'nova tarifa', active:true}
        })
        await this.fetchRates(this.rateSheetsSel.selectedId)
        await this.fetch()
        await this.fetchStats()

    }

    async deleteRate(rateId) {
        await C3.instance.client.fetcher.fetch(`/rate/${rateId}`, {
            method: 'DELETE',
        })
        await this.fetchRates(this.rateSheetsSel.selectedId)
        await this.fetch()
        await this.fetchStats()

    }

    async deleteSelectedRate() {
        let selectedId = this.ratesSel.selectedId;
        if (selectedId == null) return
        await this.deleteRate(selectedId)
    }

    async saveRate(rate) {
        await C3.instance.client.fetcher.fetch(`/rate/${rate.id}`, {
            method: 'PATCH',
            body: rate
        })
    }

    async excelImportClick() {
        this.excelDialogOpen = true
    }

    async processExcelImport(name, json) {
        console.table(json);
        // numeral.locale('en')
        let heurColCount = (json[json.length - 1] as any[])?.length
        let parsePrice = (price) => {
            if (_.isString(price)) {
                // console.log(`price`, price);
                price = price.replace(',', '.')
                price = price.replace(',', '.')
                price = price.replace('€', '')
                return _.trim(price)
                // console.log(`price2`, price, numeral(price).value());
            }else if (_.isNumber(price)){
                return price
            }
            return 0;
        }
        let midpoint = json.length / 2
        let priceColumnIndex = _.findLastIndex(json[midpoint], ((val) => parsePrice(val) > 0))

        // console.log(`priceColumnIndex`, priceColumnIndex);

        let noCode = heurColCount == 2 || priceColumnIndex == 1
        let result = json.map(r => {
            if(noCode) r.unshift(null)
            let [code, name, price, description, ...rest] = r as any
            // if (heurColCount == 2) {
            //     name = r[0]
            //     price = r[1]
            //     code = null
            // }

            price = parsePrice(price)
            return {
                code,
                name,
                price: numeral(price).value(),
                description
            }
        })
        result = result.filter((r, i) => {
            if (i < 5) {
                if (r.price == null) return false
                if (i == 0 && r.price === 0) return false
                if (r?.code?.length > 8) return false
            }
            return true
        })
        console.table(result)
        let res = await this.submitImport({
            name,
            rates: result,
            orgId: this.orgsSel.selectedId
        })
        // numeral.locale('ca-es')
        console.log(`res`, res);
        await nextTimeout(100)
        await this.fetch()
        await nextTimeout(300)
        // if (res?.id)
        //     this.rateSheetsSel.selectId(res.id, true, true)

    }

    async submitImport(body) {
        return await C3.instance.client.fetcher.fetch(`/rate-sheet/import-rs`, {
            method: 'POST',
            body
        })
    }

}
