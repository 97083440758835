import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useDCSStore} from "../../DailyCheckoutSheetView";
import * as moment from "moment";
import {paymentFormMap} from "../../../../application/utils/utils";
import * as _ from "lodash";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;

`
const TitleContainer = styled.div`
  font-size: 15px;
  font-weight: bold;
`
const TotalContainer = styled.div`
    font-size: 15px;
    font-weight: bold;


`

export interface DCSHeaderProps {

}

export const DCSHeader = observer((props: DCSHeaderProps) => {
    let store = useDCSStore()
    let startDate = store.filterForm.$('startDate').value;
    let endDate = store.filterForm.$('endDate').value;
    let type = store.filterForm.$('type').value;
    let orgName = store.filterForm.$('orgs').value.shortName;
    return <Container>
        <TitleContainer>
            INFORME de Full de Caixa - Data: {moment(startDate).format('DD/MM/YYYY')} a {moment(endDate).format('DD/MM/YYYY')} - FULL DE CAIXA [ {type == 'all' ? 'Targeta, efectiu i transferència' : paymentFormMap[type]} ] - Empresa: {orgName} - Total línies: {store.res?.length} - NOU: [Clic sobre fila per obrir factura]
        </TitleContainer>
        <div/>
        <div>
            <TotalContainer>
                Total: {store.total} €
            </TotalContainer>
        </div>
    </Container>
})
