import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MIVStore} from "../../components/ManualInvoicingView/MIVStore";
import {DateInputControl, Field, SelectControl} from "react-c4/src/index";
import {ButtonGroup, Button} from "@blueprintjs/core";
import moment from "moment";
import {isDev, useAS} from "../../application/utils/utils";
import * as _ from "lodash";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;

`
const InsCompanySelectContainer = styled.div`
  display: grid;
  width: 300px;
  margin: 0px 10px;

`

const ButtonContainer = styled.div`
  padding-left: 10px
`



export interface ManualInvoicingPageHeaderProps {
    store: MIVStore

}

export const ManualInvoicingPageHeader = observer((props: ManualInvoicingPageHeaderProps) => {
    let store = props.store
    if (store == null) return null
    return <Container>
        <ButtonGroup>
            {store.orgsSel.items?.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={() => store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>
        <InsCompanySelectContainer>
            <Field field={store.filterForm.$('insCompany')} props={{style: {zIndex: 120}}} c={SelectControl}
                   options={store.insCompaniesRes}/>

        </InsCompanySelectContainer>
        <ButtonContainer>
            <Button icon={'refresh'} onClick={() => {
                store.refreshButton()
            }}/>
        </ButtonContainer>
    </Container>
})
