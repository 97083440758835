import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRSCStore} from "../../../../../../RSCMain";
import {MRFField} from "react-c4/src/forms/MRF";
import {Icon} from "@blueprintjs/core";
import {formatFullName} from "../../../../../../../../application/utils/utils";
import {toJS} from "mobx";

const Container = styled.div`
  display: grid;
  border: 1px solid #707070;
  background-color: #8a9ba8;
  border-radius: 16px;
  align-items: center;
  padding: 6px;
  grid-template-columns: 1fr auto;
`
const CloseContainer = styled.div`
  display: grid;
  place-items: center;
  background-color: #FCFDFD;
  height: 16px;
  width: 16px;
  border-radius: 100%;
`

export interface RSCSetItemDisplayProps {
    field: MRFField,
    type
}

export const RSCSetItemDisplay = observer((props: RSCSetItemDisplayProps) => {
    let store = useRSCStore()
    let value = props.field.value;
    value.surname1 = value.surname1 === "value" ? '' : value.surname1;
    value.surname2 = value.surname2 === "value" ? '' : value.surname2;
    value.treatment = value.treatment === "value" ? '' : value.treatment;
    return <Container>
        {formatFullName({name: value.q, ...value})}
        <CloseContainer
            onClick={() => store.clearType(props.type)}
        >
            <Icon icon={'small-cross'}/>
        </CloseContainer>
    </Container>
})
