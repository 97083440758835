import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import NewTabQuickAction from './components/NewTabQuickAction';
import {NavigationConsumer, page} from 'react-navigation-plane';
import {Page} from 'react-c4';
import {useMeasure} from "react-use";
import {dec, isAdmin, isDemo, isDev, useAppStore} from "../../application/utils/utils";
import {observer} from "mobx-react";

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 10px auto 1fr 1fr;
  justify-items: stretch;
  align-items: stretch;
  padding: 4% 8px 8px;
  grid-row-gap: 16px;
  @media screen and (max-width: 500px) {
    grid-template-rows: auto auto 0px auto 1fr 1fr;
    padding: 1% 8px 8px;
    grid-row-gap: 4px;
  }
`;
const BoxedItemContainer = styled.div`
  justify-self: center;
  justify-content: center;
  align-content: center;
  width: 80%;
  min-width: 0px;
`;
const TitleContainer = styled(BoxedItemContainer)`
  width: 80%;
  //border: 2px dashed gray;
  font-size: 80px;
  font-family: Calibri, Arial, sans-serif;
  color: lightslategray;
  display: flex;
  justify-content: center;
  padding: 4px;


  @media screen and (min-width: 1600px) {
    font-size: 110px;
  }
  @media screen and (max-width: 910px) {
    font-size: 60px;
  }
  @media screen and (max-width: 430px) {
    font-size: 45px;
  }
`;
const MainActionsContainer = styled(BoxedItemContainer)`
  width: 100%;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 16px;
  cursor: auto;

  font-size: 45px;
  @media screen and (max-width: 1420px) {
    grid-template-rows: auto auto;
    grid-gap: 12px;
  }
  @media screen and (max-width: 590px) {
    grid-auto-flow: row;
    grid-template-columns: auto auto;
    font-size: 30px;
    grid-gap: 8px;
  }

`;
const ImpressumContainer = styled.div`
  display: grid;
  justify-content: center;
  align-content: end;
`

export interface HomePageProps {

}

const HomePage = observer(() => {
    // const [ref, {x, y, width, height, top, right, bottom, left}] = useMeasure();
    // let [state, setState] = useState(null)
    // useEffect(() => {
    //     if (state != null) return
    //     if (width > 0) {
    //         setState({width, height})
    //     }
    // }, [width, height])
    // let iconSize = 60;
    // if (state?.height < 800) iconSize = 40;
    let appStore = useAppStore()
    let featuresForUser = appStore.auth.featureFilter.featuresForUser;
    let liu = appStore.auth.loggedInUser;
    return <Page
        title={'Inici'}
        subtitle={process.env.INSTANCE_TITLE}
        actions={[]}
        renderCustomHeaderArea={() => <div/>}
        renderBody={() => {
            return <NavigationConsumer>
                {({navigate}) => {
                    let featuresForUser = appStore.auth.featureFilter.featuresForUser;
                    let liu = appStore.auth.loggedInUser;
                    return <Container>
                        <TitleContainer>
                            {!isDemo() ? <span>
                                    {/*<img src={logo} width="600px"/>*/}
                                {process.env.INSTANCE_TITLE}{process.env.INST_IS_PROD == 'true' ?
                                <span style={{color: 'lightblue', fontSize: 37}}>BASE DE DADES REAL</span> :
                                <span style={{color: "red", fontSize: 37}}>(Proves)</span>}
                                </span> : <span>BIS Admin</span>}
                        </TitleContainer>
                        <BoxedItemContainer className={'pt-input-group pt-large'}>

                            <div className="bp3-input-group bp3-large">
                                <span className="bp3-icon bp3-icon-search"/>
                                <input className="bp3-input bp3-disabled"
                                       type="search"
                                       placeholder={`Cerca a qualsevol lloc`}
                                    // disabled={true}
                                       onClickCapture={() => navigate({to: 'patients'})}
                                       dir="auto"
                                />
                            </div>

                        </BoxedItemContainer>
                        <div/>
                        <MainActionsContainer>

                            {/*<NewTabQuickAction name={'Tauler de control'} icon={'dashboard'}*/}
                            {/*                   onClick={() => navigate({to: 'dashboard'})}/>*/}
                            <NewTabQuickAction name={'Calendari'} icon={'calendar'}
                                               onClick={() => navigate({to: 'schedule'})}/>
                            <NewTabQuickAction name={'Meetup push'} icon={'export'}
                                               onClick={() => navigate({to: 'meetupPushEventsPage'})}/>
                            <NewTabQuickAction name={'Feature editor'} icon={'plus'}
                                               onClick={() => navigate({to: 'featureFilePage'})}/>
                            {/*<NewTabQuickAction name={'Pacientes'} icon={'people'}*/}
                            {/*                   onClick={() => navigate({to: 'patients'})}/>*/}
                            {/*<NewTabQuickAction name={'Reservar cites'} icon={'inbox'}*/}
                            {/*                   onClick={() => navigate({to: 'patients'})}/>*/}



                            <NewTabQuickAction name={`Check statistacs`} icon={'grouped-bar-chart'}
                                               onClick={() => navigate({to: 'basicStatisticsPage'})}/>
                            <NewTabQuickAction name={`Meetup Events`} icon={'calendar'}
                                               onClick={() => navigate({to: 'meetupEventsPage'})}/>
                            <NewTabQuickAction name={`Event Deltas`} icon={'calendar'}
                                               onClick={() => navigate({to: 'allDeltasPage'})}/>
                            <NewTabQuickAction name={`Assign Meetup Events`} icon={'calendar'}
                                               onClick={() => navigate({to: 'productFilePage'})}/>

                            {/*<NewTabQuickAction name={'Contactes'} icon={'people'}*/}
                            {/*                   onClick={() => navigate({to: 'ContactsPage'})}/>*/}
                            {/*<NewTabQuickAction name={'Fitxers'} icon={'database'}*/}
                            {/*                   onClick={() => navigate({to: 'files'})}/>*/}
                            {/*<NewTabQuickAction name={'Perfil'} icon={'user'}*/}
                            {/*                   onClick={() => navigate({to: 'profile'})}/>*/}
                            {/* Add actions here */}
                            {/*<NewTabQuickAction name={'Administració'} icon={'settings'}*/}
                            {/*                   onClick={() => navigate({to: 'settings'})}/>*/}
                            {/*<NewTabQuickAction name={'Facturació'} icon={'document'}*/}
                            {/*                   onClick={() => navigate({to: 'invoicesPage'})}/>*/}
                            {/*<NewTabQuickAction name={'Facturació auto'} icon={'document'}*/}
                            {/*                   onClick={() => navigate({to: 'automaticInvoicesPage'})}/>*/}
                            {/*<NewTabQuickAction name={'Worklist'} icon={'console'}*/}
                            {/*                   onClick={() => navigate({to: 'automaticInvoicesPage'})}/>*/}
                            {/*<NewTabQuickAction name={'Explotació estadísitica'} icon={'chart'}*/}
                            {/*                   onClick={() => navigate({to: ''})}/>*/}

                        </MainActionsContainer>
                        <div/>
                        {<ImpressumContainer>
                            © 2021 BIS - bielsimon2@gmail.com
                        </ImpressumContainer>}
                    </Container>;
                }}
            </NavigationConsumer>;
        }}
    />;
});

export default page(HomePage);
