import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const invoiceLineSchema: C3SchemaInfo = {
    'invoice-line': {
        fields: {
            id: {},
            invoice: {
                type: 'invoice'
            },
            amount: {},
            text: {},
            price: {},
            visitLine: {
                type: 'visit-line',
                required: false
            },

        }
    }
}
