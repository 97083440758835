import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, InputGroup, Popover} from "@blueprintjs/core";
import {useICIStore} from "../../InsCompanyInvoicingView";
import {isDev} from "../../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-gap: 8px;
    align-items: center;
`
const PassCodePopoverContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    padding: 16px;
    grid-gap: 16px;
    
`
const PassCodePopoverButtonsContainer = styled.div`
    display: grid;
    justify-content: end;
    grid-gap: 8px;
    grid-auto-flow: column;
`

export interface ICIInsCompanyBodyFooterProps {

}

export const ICIInsCompanyBodyFooter = observer((props: ICIInsCompanyBodyFooterProps) => {
    let store = useICIStore();

    let selectedCount = store?.visitLinesSel?.selectedIds?.length;

    let passCodePopoverContent = <PassCodePopoverContainer>
        <InputGroup {...store.filterForm.$('invoicingCode').bind()} leftIcon={'lock'} type={'password'}
                    placeholder={'Codi secret facturació'}/>
        <PassCodePopoverButtonsContainer>
            <Button icon={'cross'} onClick={() => {
                store.closingInvoicePrompt = false
                store.filterForm.$('invoicingCode').set('')
            }}>Cancel·la</Button>
            <Button icon={'tick'} intent={'warning'}
                    disabled={store.filterForm.$('invoicingCode').value != '777'}
                    onClick={async () => {
                        if (true) {
                            if (!store.byPatientInvoicing) {
                                await store.closeInsCompanyInvoice()
                            } else {
                                await store.runByPatientInvoicing()
                            }
                            store.closingInvoicePrompt = false
                            store.filterForm.$('invoicingCode').set('')
                        }
                    }}>{!store.byPatientInvoicing ? 'Emetre factura' :
                <span>Crear i emetre factures <span style={{fontWeight: "bold"}}>per pacient</span></span>}</Button>
        </PassCodePopoverButtonsContainer>
    </PassCodePopoverContainer>;

    return <Container>

        {store.assistantMode && <Button
          icon={'arrow-left'}
          disabled={!store.canGoBack}
          onClick={() => store.goBack()}
        >Enrere</Button>}
        {store.isInvoicableType && store.invoiceStatus == 0 && <Button
          icon={'select'}
          active={store.allLinesSelected}
          onClick={() => {
              if (!store.allLinesSelected)
                  store.visitLinesSel.selectWhere(() => true)
              else
                  store.visitLinesSel.clearSelection()
          }}
        >
          Selecciona totes
        </Button>}

        {!store.isInvoicableType && store.invoiceStatus == 0 &&
        <span style={{color: 'gray'}}>Selecciona chipcard / manual per crear pre-factura</span>}


        {store.invoiceStatus >= 1 && <Button
          icon={'print'}
          active={store.isPrinting}
          onClick={() => {
              store.printInvoice()
          }}
        >
          Imprimir {store.invoiceRes?.invoiceNumberText}
        </Button>}

        {!(store.byPatientInvoicing && store.invoiceStatus == 0) ? <React.Fragment>
                {store.invoiceStatus == 0 && <Button
                  onClick={() => {
                      store.createInsCompanyInvoice(true)
                  }}
                  loading={store.loading.creatingDraft}
                  disabled={!store.canCreateDraftInvoice || store.loading.creatingDraft}
                  icon={'document'} intent={store.canViewBeInvoiced ? 'primary' : 'none'}>
                  Crear pre-factura <span>
          <span
            style={{fontWeight: selectedCount > 0 ? "bold" : 'normal'}}>{selectedCount}</span>/{store.visitLinesRes?.length || 0}</span>
                </Button>}

                {store.invoiceStatus == 1 &&
                <Popover isOpen={store.closingInvoicePrompt} content={passCodePopoverContent}>
                  <Button
                    icon={'document'} intent={'warning'}
                    loading={store.loading.closingInvoice}
                    disabled={store.loading.closingInvoice}
                    onClick={() => {
                        store.closingInvoicePrompt = !store.closingInvoicePrompt
                    }}
                  >
                    Emetre factura {store.invoiceRes?.invoiceNumberText}
                  </Button>
                </Popover>}
            </React.Fragment> :
            <React.Fragment>
                {store.invoiceStatus == 0 &&
                <Popover isOpen={store.closingInvoicePrompt} content={passCodePopoverContent}>
                  <Button
                    icon={'document'} intent={'warning'}
                    loading={store.loading.closingInvoice}
                    disabled={store.loading.closingInvoice}
                    onClick={() => {
                        store.closingInvoicePrompt = !store.closingInvoicePrompt
                    }}
                  >
                    Crear i emetre factures per pacient
                  </Button>
                </Popover>}
            </React.Fragment>}

        {/*{store.invoiceStatus == 2 && <Button*/}
        {/*  disabled={true}*/}
        {/*  icon={'document'} intent={"none"}>*/}
        {/*  Factura {store.invoiceRes?.invoiceNumberText}*/}
        {/*</Button>}*/}
    </Container>
})
