import React, {useContext, useEffect} from "react";
import {observer} from 'mobx-react'
import {RFVStore} from "./RFVStore";
import {C3AgGrid, MasterLayout, useC3Store} from "react-c4";
import {RFVMain} from "./RFVMain";



export const RFVContext = React.createContext(null as RFVStore)
export const useRFVStore = () => useContext(RFVContext)

export interface ResourceFileViewProps {
    pageContext?
    onStoreReady: (s: RFVStore) => any
}

export const ResourceFileView = observer((props: ResourceFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new RFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null

    return <RFVContext.Provider value={store}>
        <RFVMain/>
    </RFVContext.Provider>
})
