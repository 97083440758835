import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRSCStore} from "../../../RSCMain";
import {Icon, Tooltip} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;

  width: 50px;
  justify-items: center;
  grid-auto-rows: 1fr 21px;

`
const LinkIconContainer = styled.div`
  transform: rotate(45deg) translate(-0%, 0);


`


export interface RSCLinkIndicatorProps {

}

export const RSCLinkIndicator = observer((props: RSCLinkIndicatorProps) => {
    let store = useRSCStore()
    let element;
    if (store.isCreatingAssociation) {
        element = <Tooltip content={`Creant associació`}>
            <div>
                <Icon icon={'add'} color={'#5C7080'}/>
            </div>
        </Tooltip>

    } else if (store.isLinked) {
        element = <LinkIconContainer>
            <Icon icon={'link'} color={'#5C7080'}/>
        </LinkIconContainer>
    } else {
        element = <div>
            <Icon icon={'th-disconnect'} color={'#5C7080'}/>
        </div>
    }
    return <Container
        onClick={() => store.form.$('linked').onChange(!store.isLinked)}
    >
        <div/>
        {element}
    </Container>
})
