import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {DCSStore} from "../../components/DailyCheckoutSheetView/DCSStore";
import {DateInputControl} from "react-c4";
import {Button, ButtonGroup} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  grid-template-columns: repeat(5, auto) 1fr auto;
`
const DateContainer = styled.div`
    width: 100px;
`

export interface DailyCheckoutSheetPageHeaderProps {
    store: DCSStore
}

export const DailyCheckoutSheetPageHeader = observer((props: DailyCheckoutSheetPageHeaderProps) => {
    let store = props.store
    if (store == null) return null
    let filterForm = store.filterForm;
    return <Container>
        <DateContainer>
            <DateInputControl  field={filterForm.$('startDate')} fill={true} placeholder={'Data document'}/>
        </DateContainer>
        <DateContainer>
            <DateInputControl field={filterForm.$('endDate')} fill={true} placeholder={'Data document'}/>

        </DateContainer>
        <ButtonGroup>
            <Button icon={'arrow-left'}
                    onClick={(e) => {
                        store.moveRange(e.shiftKey ? -7 : -1)
                    }}
            />
            <Button
                onClick={(e) => {
                    store.goToDate(new Date())
                }}
            >Avui</Button>
            <Button icon={'arrow-right'}
                    onClick={(e) => {
                        store.moveRange(e.shiftKey ? 7 : 1)
                    }}
            />
        </ButtonGroup>
        <ButtonGroup>
            {store.typesOptions.map(to => {
                let selected = store.filterForm.$('type').value == to.id;
                return <Button
                    active={selected}
                    icon={selected ? 'tick' : undefined}
                    onClick={() => {
                        store.filterForm.$('type').onChange(to.id)
                    }}>{to.name}</Button>
            })}
        </ButtonGroup>
        <Button icon={'refresh'} onClick={() => {
            store.fetch()
        }}/>
        <Button icon={'print'} onClick={() => {
            store.print()
        }}>Imprimir</Button>
    </Container>
})
