import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {PFVStore} from "./PFVStore";
import {useC3Store} from "react-c4";
import {PFVMain} from "./PFVMain/PFVMain";
import {APVMain} from "../AppointmentsView/APVMain/APVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const PFVContext = React.createContext(null as PFVStore)
export const usePFVStore = () => useContext(PFVContext)

export interface PatientFusionViewProps {
    pageContext?
    onStoreReady: (s: PFVStore) => any
    viewConfig?
}

export const PatientFusionView = observer((props: PatientFusionViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new PFVStore()
        storeInst.init();
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null;
    return <PFVContext.Provider value={store}>
        <Container>
            <PFVMain  viewConfig={store.viewConfig} pageContext={props.pageContext}/>
        </Container>
    </PFVContext.Provider>
})
