import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import moment from "moment";
import {CellValueChangedEvent, IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import {formatFullName, stdIntNumericColumn} from "../../../application/utils/utils";
import {SelectCE} from "../../../application/utils/components/SelectCE";
import {C3AgGrid} from "react-c4/src/index";
import {useMPEStore} from "../MeetupPushEventsView";
import {MEVMasterGrid} from "../../MeetupEventsView/MEVMain/components/MEVMasterGrid";
import {MEVDetailGrid} from "../../MeetupEventsView/MEVMain/components/MEVDetailGrid";
import Splitter from "m-react-splitters";
import {MPEGrid} from "./components/MPEGrid";
import {FieldDeltas} from "./components/FieldDeltas";

const Container = styled.div`
    display: grid;
    padding: 8px;
`

export interface MPEMainProps {
    pageContext
}

export const MPEMain = observer((props: MPEMainProps) => {
    let store = useMPEStore()
    return <Container>
        <Splitter
            position="vertical"
            primaryPaneMinWidth={0}
            primaryPaneWidth={'800px'}
            postPoned={false}>
            <MPEGrid/>
            <FieldDeltas/>
        </Splitter>
    </Container>
})