import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {SVVPIPPaymentGrid} from "./SVVPIPPaymentGrid";
import {SVVPIPPaymentTotals} from "./SVVPIPPaymentTotals";

const Container = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 8px;
    grid-template-rows: auto 1fr ;
    
`

export interface SVVPIPPaymentProps {

}

export const SVVPIPPayment = observer((props: SVVPIPPaymentProps) => {
    return <Container>
        <SVVPIPPaymentTotals/>
        <SVVPIPPaymentGrid/>
    </Container>
})
