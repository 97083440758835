import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {UserFVStore} from "./UserFVStore";
import {useC3Store} from "react-c4";
import {UserFVMain} from "./UserFVMain";

const Container = styled.div`
    display: grid;
    height: 100%;

`


export const UserFVContext = React.createContext(null as UserFVStore)
export const useUserFVStore = () => useContext(UserFVContext)

export interface UserFileViewProps {
    pageContext?
    onStoreReady: (s: UserFVStore) => any
}

export const UserFileView = observer((props: UserFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new UserFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <UserFVContext.Provider value={store}>
        <Container>
            <UserFVMain pageContext={props.pageContext}/>
        </Container>
    </UserFVContext.Provider>
})
