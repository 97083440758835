import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4";
import {AppStore} from "../../application/AppStore";
import {autorun, computed, observable} from "mobx";
import {C3Transfer} from "react-c4/src/components/Transfer/C3Transfer";
import MobxReactForm from "mobx-react-form";

export class RFVStore {

    appStore: AppStore

    transfer: C3Transfer

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection


    filterForm: MRF

    @observable.shallow
    subFamilyRes: any[]
    subFamilySel: C3Selection

    @observable.shallow
    businessLineRes: any[]
    businessLineSel: C3Selection

    @observable
    showInactiveProducts = false

    @observable
    resourceLocked = false

    @observable
    resourcePopoverCheck = false

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.orgsRes = this.appStore.orgs || [];

        this.filterForm = new MobxReactForm({
            fields: [
                'orgs',
                'productSubfamily',
                'BusinessLine',
                'resourceEditCode'
            ],
            values: {
                orgs: [],
                productSubfamily: [],
                BusinessLine: [],
                resourceEditCode: ''
            }
        })
        this.orgsSel = new C3Selection(({
            items: this.orgsRes
        }))
        this.subFamilySel = new C3Selection({
            items: this.subFamilyRes
        })
        this.businessLineSel = new C3Selection({
            items: this.businessLineRes
        })

        this.resourcesSel = new C3Selection({
            items: this.resourcesRes,
        })
        this.allProductsSel = new C3Selection({})
        this.assignedProductsSel = new C3Selection({})
        this.transfer = new C3Transfer({
            mainSel: this.resourcesSel,
            assignedSel: this.assignedProductsSel,
            allSel: this.allProductsSel,
            fetchAssigned: async () => {
                return await this.fetchAssignedProducts()
            },
            assignSubmitter: async (productIds, resourceId, assign) => {
                console.log(`productIds, resourceId, assign`, productIds, resourceId, assign);
                await C3.instance.client.fetcher.fetch(`/resource/${resourceId}/can-do-products/assign-many`, {
                    method: 'POST',
                    body: {
                        productIds: productIds,
                        assign
                    }
                })
            }
        })
        autorun(async () => {
            this.orgsRes = this.appStore.orgs;
            this.orgsSel.itemsRef.current = this.orgsRes;
            this.subFamilyRes = this.appStore.productSubfamily;
            this.businessLineRes = this.appStore.businessLine;
            if (this.orgsRes) this.orgsSel.selectedIds = [this.orgsRes[0].id];
        })
        autorun(async () => {
            this.filterForm.$('orgs').set(this.orgsSel.selectedItem);
            await this.fetch()
            await this.fetchAllProducts()
        })
        autorun(async () => {
            this.showInactiveProducts;
            await this.fetchAllProducts()
        })

        autorun(() => {
            this.filterForm.$('orgs').set(this.orgsSel.selectedItem);
            this.assignedProductsRes = [];
        })
        window['store'] = this

    }

    @observable.shallow
    resourcesRes

    resourcesSel: C3Selection

    async fetchItems() {
        this.fetch();
        this.fetchAssignedProducts()
        this.fetchAllProducts()
    }

    async fetch() {
        const query = this.orgsSel.selectedId ? `org=${this.orgsSel.selectedId}` : '';
        if (query === '') return
        this.resourcesRes = await C3.instance.client.fetcher.fetch('/resource/with-org', {
            query: [query]
        })
        this.resourcesSel.itemsRef.current = this.resourcesRes;
        return this.resourcesRes
        await this.fetchAssignedProducts()
    }

    async createResource() {
        await C3.instance.client.fetcher.fetch('/resource/', {
            method: 'POST',
            body: {
                name: 'nova màquina',
                org: {id: this.orgsSel.selectedId}
            }
        })
        await this.fetch()
        await this.fetchAssignedProducts()
    }

    async deleteResource(resourceId) {
        await C3.instance.client.fetcher.fetch(`/resource/${resourceId}`, {
            method: 'DELETE',
        })
        await this.fetch()
    }

    async deleteSelectedResource() {
        let selectedId = this.resourcesSel.selectedId;
        if (selectedId == null) return
        await this.deleteResource(selectedId)
        await this.fetchAssignedProducts()
    }

    async saveResource(resource) {
        await C3.instance.client.fetcher.fetch(`/resource/${resource.id}`, {
            method: 'PATCH',
            body: resource
        })
    }

    assignedProductsSel: C3Selection

    @observable.shallow
    assignedProductsRes

    async fetchAssignedProducts() {
        let resourceId = this.resourcesSel.selectedId;
        if (resourceId == null) return
        this.assignedProductsRes = await C3.instance.client.fetcher.fetch(`/resource/${resourceId}/products`, {})
        this.assignedProductsSel.itemsRef.current = this.assignedProductsRes;
        return this.assignedProductsRes
    }

    allProductsSel: C3Selection

    @observable.shallow
    allProductsRes

    async fetchAllProducts() {
        let orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (orgs === '') return
        this.allProductsRes = await C3.instance.client.fetcher.fetch(`/product/maintenance`, {
            query: [`inactive=${this.showInactiveProducts.toString()}`, orgs].join('&')
        })
        this.allProductsSel.itemsRef.current = this.allProductsRes;
        return this.allProductsRes
    }

    async updateAssociations(assign = true, productIds) {
        const resourceId = this.resourcesSel.selectedId

        await C3.instance.client.fetcher.fetch(`/resource/${resourceId}/can-do-products/assign-many`, {
            method: 'POST',
            body: {
                productIds: productIds,
                assign
            }
        })
        await this.fetchAllProducts()
        await this.fetchAssignedProducts()
    }

    async addProduct() {
        const productIds = this.allProductsSel.selectedIds
        await this.updateAssociations(true, productIds)
        this.assignedProductsSel.selectedIds =  this.allProductsSel.selectedIds
        this.clearSelection()
    }

    async removeProduct() {
        const productIds = this.assignedProductsSel.selectedIds
        this.allProductsSel.selectedIds =  productIds
        await this.updateAssociations(false, productIds)
    }

    async clearSelection() {
        this.allProductsSel.selectedIds = []
    }

    @computed
    get blockAddOrRemove() {
        let resourceId = this.resourcesSel.selectedId;
        let productId = this.allProductsSel.selectedIds;
        let productAssigned = this.assignedProductsSel.selectedId
        let productCheck = productAssigned || productId


        return resourceId == null || productCheck == null
    }

    @computed
    get canAddProduct() {
        if (this.blockAddOrRemove) return false;
        let sourceSel = this.allProductsSel;
        let targetSel = this.resourcesSel;
        if (sourceSel.selectedIds.length == 0) return false;
        return true
    }

    @computed
    get canRemoveProduct() {
        if (this.blockAddOrRemove) return false;

        let sourceSel = this.assignedProductsSel;
        let targetSel = this.resourcesSel;
        if (sourceSel.selectedIds.length == 0) return false;

        return true
    }

    async createProduct() {

        await C3.instance.client.fetcher.fetch('/product/maintenance', {
            method: 'POST',
            body: {
                name: 'Nova prova',
                orgs: [{id: this.orgsSel.selectedId}],
                isActive: true,
            }
        })
        await this.fetchAllProducts()
        await this.fetchAssignedProducts()
    }

    async deleteProduct() {
        await C3.instance.client.fetcher.fetch(`/product/maintenance/${this.allProductsSel.selectedId}`, {
            method: 'DELETE',
        })
        await this.fetchAssignedProducts()
        await this.fetchAllProducts()
    }

    async saveProduct(product) {
        await C3.instance.client.fetcher.fetch(`/product/maintenance/${this.allProductsSel.selectedId}`, {
            method: 'PATCH',
            body: product
        })
        await this.fetchAssignedProducts()
    }

    async saveActiveProduct(product) {
        await C3.instance.client.fetcher.fetch(`/product/maintenance/${this.assignedProductsSel.selectedId}`, {
            method: 'PATCH',
            body: product
        })
    }
}
