import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useAVVStore} from "../AllVisitsView";
import {AVVHeader} from "./AVVHeader/AVVHeader";
import {AVVGrid} from "./AVVGrid/AVVGrid";
import {AVVFooter} from "./AVVFooter/AVVFooter";
import {useReactToPrint} from "react-to-print";
import * as moment from "moment";
import {AVVFiltersHeader} from "./AVVFiltersHeader/AVVFiltersHeader";

const OuterContainer = styled.div`
    background-color: white;
`
const Container = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
`

export interface AVVMainProps {
    pageContext
}

export const AVVMain = observer((props: AVVMainProps) => {
    let store = useAVVStore()
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        documentTitle: 'llistat-caixa-' + moment(store.filterForm.$('startDate').value).format('DD-MM-YYYY'),
        content: () => componentRef.current,
    });
    useEffect(() => {
        store.printHandle = handlePrint
    }, [])
    return <OuterContainer>
        {store.filtersEnabled && <AVVFiltersHeader/>}
        <Container ref={componentRef}>
            <AVVHeader/>
            <AVVGrid/>
            <AVVFooter/>
        </Container>
    </OuterContainer>
})
