import {AppStore} from "../AppStore";
import {action, autorun, computed, observable, toJS} from "mobx";
import {C3, MRF} from "react-c4";
import MobxReactForm from "mobx-react-form";
import {LoginFormStore} from "./components/OverlayLoginScreen/LoginFormStore";
import moment from "moment";
import {isAdmin, isDev, isProd} from "../utils/utils";
import {FeatureFilter} from "./features/FeatureFilter";
import cuid from 'cuid';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface LoggedInUser {
    id
    name
    roles: { name, displayName, role, readonly }[]
    email
    fullName
    isAdmin
    org?
}

interface DeviceInfo {
    id
    key
    pairingCode
    paired
}

export class AuthStore {
    appStore: AppStore

    @observable
    loggedInUser: LoggedInUser

    @observable
    token: string

    @observable
    sessionToken: string

    device: DeviceInfo


    loginFormStore: LoginFormStore = new LoginFormStore(this)
    featureFilter: FeatureFilter = new FeatureFilter(this)

    lockedOnly

    constructor(appStore: AppStore) {
        this.appStore = appStore;
        this.init()
    }

    init() {
        let lastLoggedIn = localStorage.getItem('last-loggged-in');
        let shouldRecoverSession = (moment().diff(moment(lastLoggedIn), 'minute', false) < 5) || isDev();
        this.getDeviceToken().then(() => {
            if (shouldRecoverSession) {
                this.recoverSession()
            } else {
                this.logout(false)
            }
        })
    }

    recoverSession() {
        this.token = localStorage.getItem('session-token')
        this.sessionToken = localStorage.getItem('session-token-2')
        setTimeout(() => {
            autorun(async () => {
                C3.instance.client.fetcher.token = this.token
                await this.fetchMe()
            })
        })
    }


    @action
    logout(lockOnly = false) {
        this.loggedInUser = null
        this.token = null
        this.loginFormStore.loginForm.clear()
        localStorage.removeItem('session-token')
        localStorage.removeItem('session-token-2')
        localStorage.removeItem('last-loggged-in')
    }

    clearTmpData() {
        sessionStorage.removeItem('dev-token')
    }

    async refreshDevToken() {
        sessionStorage.removeItem('dev-token')
        await this.getDeviceToken()
    }

    @computed
    get isLoggedIn() {
        return this.token != null
        // return true;

        // return this.loggedInUser != null
    }

    // Feature filter
    hasFeature(featureKey) {
        // Look for standalone rbac
        this.featureFilter.hasFeature(featureKey)
    }

    async fetchMe() {
        if (!this.token) return null
        let res = await C3.instance.client.fetcher.fetch(`/auth/me`, {
            query: [`c=${this.getDeviceCuid()}`]
        });
        if (res.statusCode) {
            if (res.message == 'Invalid device') {
                console.log(`Error device res:`, res);
                this.logout()
                await this.refreshDevToken()
            } else if (res.message == 'Invalid session') {
                console.log(`Error session res:`, res);
                await this.refreshDevToken()

            }

        } else {
            this.appStore.networkStatus.serverConnected = true
            this.loggedInUser = res
            console.log(`res`, res);
            if (res.sessionId) {
                this.setToken(this.token, res.sessionId)
            }
            if (res.deviceId) {
                sessionStorage.setItem('dev-token', res?.deviceId)
            }
            window['user'] = toJS(this.loggedInUser)
            this.featureFilter.onUpdateUser()
            this.appStore.orgsSel.selectId(this.loggedInUser?.org?.id)
            setTimeout(() => {
                this.appStore.orgsSel.selectId(this.loggedInUser?.org?.id)
            }, 1000)
        }
        return this.loggedInUser
    }

    // Use CUID and ID
    getDeviceCuid() {
        let key = 'device-cuid';
        let devToken = localStorage.getItem(key);
        if (devToken) return devToken
        else {
            let value = cuid();
            localStorage.setItem(key, value)
            return value
        }
    }

    async getDeviceToken() {
        let key = 'dev-token';
        let devToken = sessionStorage.getItem(key);
        if (devToken) return Number(devToken)
        else {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            // This is the visitor identifier:
            const visitorId = result.visitorId;
            console.log(visitorId);

            let res = await C3.instance.client.fetcher.fetch(`/auth/auth-dev`, {
                method: 'POST',
                body: {
                    cuid: this.getDeviceCuid(),
                    fp: visitorId,
                    // extras: result
                },
                suppressDevToken: true,
            });
            sessionStorage.setItem(key, res.id)
            return res.id
        }
    }

    setToken(token, sessionToken) {
        this.token = token
        // this.sessionToken = sessionToken
        localStorage.setItem('session-token', token)
        // localStorage.setItem('last-token-2', sessionToken)
        // localStorage.setItem('session-token-2', sessionToken)
        localStorage.setItem('last-loggged-in', moment().toISOString())

    }

    async newSession() {
        let res = await C3.instance.client.fetcher.fetch(`/auth/new-session`, {
            method: 'POST',
            body: {},
            suppressDevToken: false,
        });
        let sessionToken = res?.id;
        localStorage.setItem('session-token-2', sessionToken)
        this.sessionToken = sessionToken
    }


    // Basic functionality
    async loginWithUsername(username, password) {
        let res = await C3.instance.client.fetcher.fetch(`/auth/login/username`, {
            method: 'POST',
            body: {
                username,
                password,
            },
            auth: false
        })
        if (res?.id) {
            this.setToken(res?.id, res?.sessionId)
        }
        return res
    }

    async loginWithPIN(pinNumber) {
        let loginFetch = async () => await C3.instance.client.fetcher.fetch(`/auth/login/pin`, {
            method: 'POST',
            body: {
                pinNumber,
            },
            auth: false,
            errors: true
        });

        let res: any;
        try {
            res = await loginFetch();
            if (res.statusCode == 400) {
                res = await loginFetch();
                // window.location.reload()
            }
        } catch (e) {

        }
        this.token = res?.token
        this.setToken(res?.token, null)
        console.log(`res`, res);
        if (res?.token) {
            let newUser = false
            let lastSession = localStorage.getItem('last-token-2');
            if (this.token != res?.id) {
                newUser = true;
            }
            if (lastSession != res?.sessionId) {
                newUser = true;
            }
            await this.fetchMe()
            this.token = res?.token
            this.setToken(res?.token, res?.sessionId)
            sessionStorage.setItem('dev-token', res?.deviceId)
            if (newUser) {
                console.log(`NEW USER!`);
                setTimeout(() => window.location.reload())
            }
        }
        return res
    }
}
