import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page, useC3Store} from "react-c4";
import {DashboardPageStore} from "../Dashboard/DashboardPageStore";
import {AIPHeader} from "./components/AIPHeader";
import {AIPGrid} from "./components/AIPGrid";
import {Button} from "@blueprintjs/core";
import {AutomaticInvoicesPageStore} from "./AutomaticInvoicesPageStore";
import {AIPFooter} from "./components/AIPFooter";

const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr auto;
    padding: 8px;
    grid-gap: 8px;
`
const FooterContainer = styled.div`
 
`

export interface InvoicesPageProps {

}

export const AutomaticInvoicesPage = page(observer((props: InvoicesPageProps) => {
    let store = useC3Store(() => {
        let storeInst = new AutomaticInvoicesPageStore();
        return storeInst
    })
    if (store == null) return null
    return <Page
        title={'Facturació automàtica'}
        subtitle={''}
        // icon={'inbox'}z
        attributes={[
        ]}
        renderBody={({pageContext}) => {
            return <Container>
                <AIPHeader store={store}/>
                <AIPGrid store={store}/>
                <AIPFooter store={store}/>

            </Container>
        }}
    />
}))
