import {SVVStore} from "../StandardVisitView/SVVStore";
import {action, autorun, computed, observable, toJS} from "mobx";
import {C3, IATSStyleApp, MRF} from "react-c4";
import MobxReactForm from "mobx-react-form";
import {ICellEditorParams} from "ag-grid-community";
import * as _ from 'lodash';
import {dec, formatFullName} from "../../application/utils/utils";
import {AppStore} from "../../application/AppStore";


export class RSCStore {
    svvStore: SVVStore
    appStore: AppStore

    @observable
    visitLineId: number

    @observable
    res = []

    @observable
    extCentersRes = []

    @observable
    medicalsRes = []

    form: MRF

    @observable
    initialized = false

    extCenterRef
    medicalRef

    @observable
    testForLinkIds = {
        medicalId: null,
        extCenterId: null
    }

    @observable
    linkExists

    @observable
    visitLine

    params: ICellEditorParams

    constructor(svvStore: SVVStore) {
        this.svvStore = svvStore;

    }

    init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.form = new MobxReactForm({
            fields: [
                ...(_.flatMap(['medical', 'extCenter'], s => [
                        'q',
                        'surname1',
                        'surname2',
                        'treatment',
                        'notes',
                        'creating',
                        'setId',
                        'linkComment'
                    ].map(s2 => `${s}.${s2}`)
                )),
                'linked',
            ],
            values: {
                linked: dec().rscLinkedByDefault(),
                medical: {
                    q: '',
                    surname1: '',
                    surname2: '',
                    treatment: '',
                    notes: '',
                    setId: null,
                    creating: false
                },
                extCenter: {
                    q: '',
                    surname1: '',
                    surname2: '',
                    treatment: '',
                    notes: '',
                    setId: null,
                    creating: false,
                    linkComment: ''
                },
                linkComment: ``
            }
        })
        autorun(async () => {
            let extCenterQ = this.form.$('extCenter.q').value;
            let medicalQ = this.form.$('medical.q').value;
            let linked = this.isLinked
            if (linked) {
                await this.fetch(extCenterQ, medicalQ)
            }
        }, {delay: 50})
        autorun(async () => {
            let extCenterQ = this.form.$('extCenter.q').value;
            let linked = this.isLinked
            if (!linked) {
                await this.fetchUnlinked('extCenter', extCenterQ)
            }
        }, {delay: 50})
        autorun(async () => {
            let medicalQ = this.form.$('medical.q').value;
            let linked = this.isLinked
            if (!linked) {
                await this.fetchUnlinked('medical', medicalQ)
            }
        }, {delay: 50})

        autorun(() => {
            let extCenterId = this.testForLinkIds.extCenterId;
            let medicalId = this.testForLinkIds.medicalId;
            if (extCenterId && medicalId) {

            }
        })

        this.initialized = true

    }

    initForVisitLine(visitLineId, params: ICellEditorParams) {
        this.visitLineId = visitLineId
        this.params = params
        this.clearType('medical');
        this.clearType('extCenter');
        this.setLinked(dec().rscLinkedByDefault()); // TODO set to true when links are made
        this.loadVisitLineInfo()
        setTimeout(() => {
            this.medicalRef?.current?.focus?.()
        }, 50)
    }

    loadVisitLineInfo () {
        this.visitLine = this.svvStore.getVisitLineField(this.visitLineId).value;
        if (this.visitLine.refMedical) this.selectEntry('medical', this.visitLine.refMedical);
        if (this.visitLine.refExtCenter) this.selectEntry('extCenter', this.visitLine.refExtCenter);
        this.setLinked(dec().rscLinkedByDefault())
    }

    @computed
    get bothSet() {
        return this.isEffectivelySet('extCenter') && this.isEffectivelySet('medical')
    }


    @computed
    get isCreatingAssociation() {
        let bothSet = this.bothSet;
        if (!bothSet) {
            return false
        } else {
            // Add link not exists
            let linkExists = true;

            return linkExists
        }
    }

    @computed
    get isCreating() {
        let value = this.form.$('extCenter.creating').value;
        let value1 = this.form.$('medical.creating').value;
        // console.log(`value`, value);
        console.log(`value, value1`, value, value1);
        return value || value1
    }

    isEffectivelySet(type) {
        return this.getTypeField(type).$('creating').value || this.getTypeField(type).$('setId').value
    }

    @computed
    get isLinked() {
        return !this.isCreating && this.form.$('linked').value
    }

    setLinked(value) {
        this.form.$('linked').onChange(value)
    }


    async fetch(extCenterQ, medicalQ) {
        let res = await C3.instance.client.fetcher.fetch(`/svv/all-referrers`, {
            query: [`extCenterQ=${extCenterQ}`, `medicalQ=${medicalQ}`].filter(v => v != null).join('&'),
        })
        this.res = res
    }

    async fetchUnlinked(type, q) {
        let res = await C3.instance.client.fetcher.fetch(`/svv/unlinked-referrers`, {
            query: [`type=${type}`, `q=${q}`].filter(v => v != null).join('&'),
        })
        if (type == 'medical') {
            this.medicalsRes = res
        } else {
            this.extCentersRes = res
        }
    }

    async fetchLinkExists(extCenterId, medicalId) {
        let res = await C3.instance.client.fetcher.fetch(`/svv/referrer-link-exists`, {
            query: [`extCenterId=${extCenterId}`, `medicalId=${medicalId}`].filter(v => v != null).join('&'),
        })
        this.linkExists = res
    }

    saving = false

    async submit(e, stop = true) {
        let refExtCenterId = e.extCenterId
        let refMedicalId = e.medicalId
        if (this.visitLineId) {
            let visitLine = {
                id: this.visitLineId,
                visit: {id: this.params.data.visit.id},
                refExtCenter: {id: refExtCenterId},
                refMedical: {id: refMedicalId}
            };
            this.saving = true

            // console.log(`visitLine`, visitLine);
            await this.svvStore.saveVisitLine(visitLine, false)
            setTimeout(() => {
                this.svvStore.refetchVisitLine(visitLine.id)
            }, 50)
            this.saving = false
            if (this.params) {
                // props.api.stopEditing()
                this.params.api.stopEditing(true)
            }
        }
        if (stop){
            this.clearType('medical');
            this.clearType('extCenter');
        }
    }

    getTypeField(type) {
        return this.form.$(type)
    }

    @action
    clearType(type) {
        let field = this.getTypeField(type)
        field.$('setId').set(null)
        field.$('q').set('')
        field.$('creating').set(false)
        field.$('surname1').set('')
        field.$('surname2').set('')
        field.$('treatment').set('')
        field.$('notes').set('')
    }

    @action
    selectEntry(type, entry) {
        if (!entry.id) return
        let field = this.getTypeField(type)
        this.setLinked(false)
        field.$('setId').set(entry.id)
        field.$('q').set(entry.name)
        field.$('surname1').set(entry.surname1)
        field.$('surname2').set(entry.surname2)
        field.$('treatment').set(entry.treatment)
        field.$('creating').set(false)
    }

    @action
    startCreating(type) {
        let field = this.getTypeField(type)

    }

    async saveNewRef(refType) {
        let extCenterQ = this.form.$('extCenter.q').value;
        let medicalQ = this.form.$('medical.q').value;
        let query = refType === "medical" ? 'referrer' : 'ext-center'
        let newContact = {
            orgs:[{id:this.appStore.orgsSel.selectedId}],
            isMedical: refType === 'medical' ? true : false,
            isExtCenter: refType === 'medical' ? false : true,
            name: refType === "medical" ? medicalQ : extCenterQ,
            surname1: refType === 'medical' ? this.form.$('medical.surname1').value : null,
            surname2: refType === 'medical' ? this.form.$('medical.surname2').value : null,
            treatment: refType === 'medical' ? this.form.$('medical.treatment').value : null,
            notes: refType === 'medical' ? this.form.$('medical.notes').value : this.form.$('extCenter.notes').value,
            isActive: true,
        }
        await C3.instance.client.fetcher.fetch(`/contact/${query}`, {
            method: 'POST',
            body: newContact
        })

        let newEntry
        if (refType === 'medical'){
            await this.fetchUnlinked(refType, medicalQ)
            newEntry = this.medicalsRes.find((medical)=>{
                let medicalFullName = formatFullName(medical);
                let newMedicalFullName = formatFullName({
                    name: medicalQ,
                    surname1: this.form.$('medical.surname1').value,
                    surname2: this.form.$('medical.surname2').value,
                    treatment: this.form.$('medical.treatment').value,
                })
                return medicalFullName == newMedicalFullName
            })
        } else {
            await this.fetchUnlinked(refType, extCenterQ);
            newEntry = this.extCentersRes.find((center)=>{
                return center.name == extCenterQ
            })
        }
        this.clearType(refType);
        await this.selectEntry(refType, newEntry)
    }

/*    async createLinkSubmit(refType){
        await this.saveNewRef(refType);
        if (this.isCreatingAssociation) await this.createNewLink();
        let  referrersIds = {
            medicalId: this.form.$(`medical.setId`).value,
            extCenterId: this.form.$(`extCenter.setId`).value
        };
        this.submit(referrersIds);
    }*/

/*    async createWithoutCenter(){
        await this.saveNewRef('medical');
        let medical = {
            name: this.form.$('medical.q').value,
            surname1: this.form.$('medical.surname1').value,
            surname2: this.form.$( 'medical.surname2').value,
            treatment: this.form.$('medical.treatment').value
        }
        let medicalFullName = formatFullName(medical);
        this.form.$('extCenter.q').value = medicalFullName;
        await this.saveNewRef('extCenter');
        await this.createNewLink();
        let  referrersIds = {
            medicalId: this.form.$(`medical.setId`).value,
            extCenterId: this.form.$(`extCenter.setId`).value
        };
        await this.submit(referrersIds);
    }*/

/*    async createNewLink() {
        const extCenterId = this.form.$(`extCenter.setId`).value;
        const medicalId = this.form.$(`medical.setId`).value;
        const comment = this.form.$('linkComment').value;
        const newLink = {
            medicalIds: [medicalId],
            extCenterId: extCenterId,
            assign: true,
            comment: comment
        }
        await C3.instance.client.fetcher.fetch(`/referrer-matrix/ext-center/assign-many`, {
            method: `POST`,
            body: newLink
        })
        this.fetchLinkExists(extCenterId, medicalId);
        this.setLinked(true);
    }*/

    async createSubmit(refType){
        await this.saveNewRef(refType);
        let  referrersIds = {
            medicalId: this.form.$(`medical.setId`).value,
            extCenterId: this.form.$(`extCenter.setId`).value
        };
        this.submit(referrersIds);
    }

    async clearImputs() {
        this.clearType('medical');
        this.clearType('extCenter');
    }
}
