import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {CellValueChangedEvent, RowNode} from "ag-grid-community";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {useProdFVStore} from "../../../ProdFV";
import {
    formatFullName,
    stdDateColumn,
    stdIntNumericColumn,
    stdNumericColumn
} from "../../../../../application/utils/utils";
import {observer} from "mobx-react";

const Container = styled.div`
    display: grid;
    
`

export interface PREActiveRatesGridProps {

}

export const PREActiveRatesGrid = observer((props: PREActiveRatesGridProps) => {
        let store = useProdFVStore()
        return <Container>
            <C3AgGrid
                onRowDoubleClicked={({data, event}) => {
                    window.open(`https://www.meetup.com/${data.urlName}/events/${data.meetupId}/`, "_blank");
                }}
                columnDefs={[
                    {
                        field: '',
                        headerName: '',
                        width: 30,
                        checkboxSelection: true,
                        // suppressFilter: true,
                    },
                    {
                        field: 'name',
                        headerName: 'Nom',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 320,
                    },
                    {
                        field: 'row_number',
                        headerName: 'Row index',
                        filter: 'agTextColumnFilter',
                        ...stdIntNumericColumn(),
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'urlName',
                        headerName: 'Url Name',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'rsvp_limit',
                        headerName: 'Capacitat',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'meetupId',
                        headerName: 'Id de Meetup',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'start',
                        headerName: 'Start',
                        filter: 'agTextColumnFilter',
                        ...stdDateColumn(),
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'end',
                        headerName: 'end',
                        filter: 'agTextColumnFilter',
                        ...stdDateColumn(),
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'duration',
                        headerName: 'Duration',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'timedelta.days',
                        headerName: 'Time delta',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 95,
                    },
                    {
                        field: 'who',
                        headerName: 'Who',
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 95,
                    },
                ]}
                // processCellCallback={(params) => {
                //     let data = (params.node as RowNode).data
                //     return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                // }}
                getContextMenuItems={(params) => {
                    // let fileName = store.fr
                    var result = [
                        {
                            // custom item
                            name: 'Exporta a Excel',

                            action: function () {
                                params.api.exportDataAsExcel({
                                    fileName: `barems-${store.allMeetupGroupsTabsSel.selectedItem?.name.replace(' ', '_')}-${store.eventsSel.selectedItem?.name.replace(' ', '_')}`,
                                    processCellCallback: (params) => {
                                        let data = (params.node as RowNode).data
                                        if (params.column.getColId() == 'isActive') {
                                            return data.isActive == true ? 'X' : ''
                                        }
                                        return params.value
                                    }
                                })
                            },
                        }
                    ]
                    return result
                }}
                rowData={store.eventMGMeetupEventRes}
                selection={store.eventMGMeetupEventsSel}
                // cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                //     await store.saveProduct({
                //         id: e.data.id,
                //         [e.colDef.field]: e.newValue
                //     })
                // }}
                // rowData={store.referrersRes}
                // selection={store.referrersSel}
                // cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                //     await store.saveReferrer({
                //         id: e.data.id,
                //         [e.colDef.field]: e.newValue
                //     })
                // }}

            />    </Container>
    }
)
