import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRFVStore} from "./ResourceFileView";
import {ResourceEditor} from './components/ResourceEditor'
import {ResourceProductEditor} from './components/ResourceProductEditor/ResourceProductEditor'
import {Button, InputGroup, Popover} from "@blueprintjs/core";
import Splitter from "m-react-splitters";

const Container = styled.div`
  display: grid;
  height: calc(100vh - 100px);
  padding: 8px;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
`
const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 8px;
  place-content: start;
`

const PopoverContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding: 16px;
  grid-gap: 16px;

`

const PopoverButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-auto-flow: column;
`

export interface RFVMainProps {

}

export const RFVMain = observer((props: RFVMainProps) => {
    let store = useRFVStore()
    return <Splitter
        position="vertical"
        primaryPaneMaxWidth="80%"
        primaryPaneMinWidth={0}
        primaryPaneWidth="400px"
    >
        <Container>
        <ResourceEditor/>
        <ButtonContainer>

            <Button icon={'document'}
                    disabled={!store.resourceLocked}
                    onClick={() => store.createResource()}
            />
            <Button icon={'trash'}
                    disabled={!store.resourceLocked}
                    onClick={() => store.deleteSelectedResource()}
            />
            <Popover
                isOpen={store.resourcePopoverCheck}
                content={<PopoverContainer>
                    <InputGroup {...store.filterForm.$('resourceEditCode').bind()} leftIcon={'lock'} type={'password'}
                                placeholder={`Codi secret d'edició`}/>
                    <PopoverButtonsContainer>
                        <Button icon={'cross'} onClick={() => {
                            store.resourcePopoverCheck = false
                            store.filterForm.$('resourceEditCode').set('')
                        }}>Cancel·la</Button>
                        <Button icon={'tick'} disabled={store.filterForm.$('resourceEditCode').value != '777'}
                                intent={'warning'} onClick={async () => {
                            if (true) {
                                store.resourceLocked = true
                                store.filterForm.$('resourceEditCode').set('')
                                await store.fetch()
                                store.resourcePopoverCheck = false
                            }

                        }}>Desbloquejar edició</Button>
                    </PopoverButtonsContainer>
                </PopoverContainer>}
            >
                <Button
                    icon={store.resourceLocked ? 'unlock' : 'lock'}
                    intent={'none'}
                    onClick={() => {
                        if (store.resourceLocked) {
                            store.resourceLocked = false;
                        } else {
                            store.resourceLocked = false;
                            store.resourcePopoverCheck = !store.resourcePopoverCheck;
                        }
                    }}
                />
            </Popover>
        </ButtonContainer>

    </Container>
        <ResourceProductEditor/>
    </Splitter>
})
