import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const referrerMatrixSchema: C3SchemaInfo = {
    'referrer-matrix': {
        fields: {
            id: {},
            extCenter: {
                type: 'contact'
                // param for options
            },
            specialty: {
                type: 'specialty'
            },
            medical: {
                type: 'contact'
                // param for options
            },
            protocol: {},
            assistant: {},

        }
    }
}
