import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import ReactDOM from "react-dom";
import {ICellEditorParams} from "ag-grid-community";
import {createPopper, Instance as PopperInstance} from "@popperjs/core";
import {getMomentFormatter} from "react-c4";
import {ErrorBoundary} from "@sentry/react";
import * as _ from 'lodash'
import Select from "react-select";
import {toJS} from "mobx";

const Container = styled.div`
  display: grid;

`

export const SelectCE = observer(forwardRef((props: ICellEditorParams, ref) => {
    const [contextElement, setContextElement] = useState(document.body as HTMLElement);


    const [popper, setPopper] = useState(null as PopperInstance);
    const referenceElementRef = useRef();
    const popperElementRef = useRef();

    const [editing, setEditing] = useState(true);


    const refContainer = useRef(null);

    let selectRef = useRef(null)

    useLayoutEffect(() => {
        const popper: PopperInstance = createPopper(referenceElementRef.current, popperElementRef.current, {
            placement: "auto-end",
            modifiers: [
                {
                    name: 'flip',
                    enabled: true,
                },

            ],
            onFirstUpdate: () => {

            }
        });

        setPopper(popper)
        focus();
    }, []);

    const keyDownEvent = (event) => {
        // console.log(`event.keyCode`, event.keyCode);
        if ([37, 39].indexOf(event.keyCode) > -1) { // left and right
            // setHappy(!happy);
            // Update search focus
            event.stopPropagation();
        }
        if ([27].indexOf(event.keyCode) > -1) { // left and right
            // setHappy(!happy);
            props.api.stopEditing(true)
            event.stopPropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', keyDownEvent);

        return () => {
            window.removeEventListener('keydown', keyDownEvent);
        };
    }, [keyDownEvent]);

    let cellEditorParams = props.colDef?.cellEditorParams

    if (_.isFunction(cellEditorParams)) cellEditorParams = cellEditorParams(props)
    let [state, setState] = useState(props.value)
    let [returning, setReturning] = useState(props.value)
    let [dirty, setDirty] = useState(false)
    console.log(`state`, state);
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return returning;
            },

            isPopup() {
                return true;
            },

            init(params: ICellEditorParams) {

            }

        }
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing(!dirty)

        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container: any = ReactDOM.findDOMNode(popperElementRef.current);
            if (container) {
                container.focus();
            }
        })
    };


    // let rscStore = store.getRSCStore();

    useEffect(() => {
        // if (rscStore) {
        //     rscStore.initForVisitLine(props.data.id, props)
        // }
        console.log(`props.data.id`, props.data.id);
    }, [props.data.id])


    // let momentFormatter = getMomentFormatter('DD/MM/YYYY');

    let options = [
        {value: 'chocolate2', label: `w:${props.column.getActualWidth()}`},
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]

    let formatValue = cellEditorParams.formatValue
    // if(!_.isFunction(renderOption)){
    //     renderOption = (option) => {
    //         if(option == null)
    //         return option['name']
    //     }
    // }

    let rawOptions = cellEditorParams.values || [];
    // console.log(`ref`, ref);

    options = rawOptions
    // console.log(`options`, toJS(options));
    // console.log(`state`, toJS(state));
    //     .map((o) => {
    //
    //     return {...o, value: o.id, label: ceParams.formatValue(o)}
    // })

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 200,
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return {...provided, opacity, transition};
        }
    }

    const customStyles2 = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            borderRadius: 0,
            minHeight: '28px',
            height: '28px',
            margin: -2,
            marginRight: 2,
            boxShadow: state.isFocused ? null : null,
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            fontSize: 12
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '28px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '28px',
        }),
    };

    return (
        <>
            <div style={{
                // height: 25
                backgroundColor: 'transparent'
            }} ref={referenceElementRef}>

            </div>

            {ReactDOM.createPortal(
                <div

                    ref={popperElementRef}
                    style={{
                        ...popper?.state?.styles,
                        zIndex: 100,
                        width: props.column.getActualWidth()
                    }}
                    {...(popper?.state?.attributes)}
                >
                    <ErrorBoundary>
                        <Select
                            // ref={(r) => {
                            //     console.log(`r`, r);
                            // }}
                            defaultMenuIsOpen={true}
                            autoFocus
                            value={state}
                            getOptionLabel={formatValue}
                            getOptionValue={v => v.id}
                            onChange={(values, {action}) => {
                                if (action == "select-option") {
                                    console.log(`values`, values);
                                    setState(values)
                                    setReturning(values)
                                    setDirty(true)
                                    requestAnimationFrame(() => setEditing(false))
                                }
                            }}
                            styles={customStyles2}
                            options={options}/>

                        {/*<DatePicker*/}
                        {/*    locale={'ca'} todayButtonText={'Avui'} canClearSelection={true}*/}
                        {/*    minDate={moment().subtract(120, 'years').toDate()}*/}
                        {/*    maxDate={moment().add(120, 'years').toDate()}*/}

                        {/*    {...momentFormatter}*/}
                        {/*    onChange={(v) => setState(v)}*/}

                        {/*    value={state ? moment(state).toDate() : new Date()}*/}
                        {/*    highlightCurrentDay*/}
                        {/*    localeUtils={MomentLocaleUtils}*/}
                        {/*/>*/}
                    </ErrorBoundary>
                    {/*<RSCMain store={rscStore} params={props.value}/>*/}
                </div>,
                document.body
            )}

        </>
    );
}))
