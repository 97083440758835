import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {UserFVStore} from "../../files-views/UserFileView/UserFVStore";
import {Button, ButtonGroup} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`

const ButtonContainer = styled.div`
  padding-left: 5px
`
const SpanContainer = styled.div`
  padding-left: 15px;
  color: red;
  font-weight: bold;
`

export interface UserFilePageHeaderProps {
    store: UserFVStore
}

export const UserFilePageHeader = observer((props: UserFilePageHeaderProps) => {
    let store = props.store;
    if (store === null) return null;
    return <Container>
        <ButtonGroup>
            {store.orgSel.items?.map(it => {
                let selected = store.orgSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={() => store.orgSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>
        <ButtonContainer>
            <Button icon={'refresh'}
                    onClick={async () => {
                        await store.refresh()
                    }}
            />
        </ButtonContainer>
        {/*<SpanContainer>Per editar, crear o borrar un usuari desbloquejar el candau</SpanContainer>*/}
    </Container>
})
