import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Invoice, InvoiceProps} from "../Invoice/Invoice";
import {useReactToPrint} from 'react-to-print';
import moment from "moment";
import {MRF} from "react-c4";
import {stdNumericColumn} from "../../application/utils/utils";

const Container = styled.div`
    display: grid;
    
`

export interface PrivateInvoiceProps extends Partial<InvoiceProps> {
    onPrintReady?: (handle) => null
    invoiceForm?: MRF
    isPrinting
}

export const PrivateInvoice = observer((props: PrivateInvoiceProps) => {
    // if(props.data == null) return null

    let exampleLines = [
        {
            date: new Date(),
            text: 'AA',
        }, {
            date: new Date(),
            text: 'BB',
            quantity: 2,
            total: 450
        }, {
            date: new Date(),
            text: 'XCC',
            quantity: 25,
            total: 243
        }
    ];
    let getearliest_invoiceline_date = (invoice) => {
        let dates = invoice.lines.map(a => {
            return a.date
        })
        return new Date(Math.min.apply(null, dates))

    }
    let getlatest_invoiceline_date = (invoice) => {
        let dates = invoice.lines.map(a => {
            return a.date
        })
        return new Date(Math.max.apply(null, dates))

    }
    // console.log(`props.invoice`, props.invoice);
    return <Invoice
        isPrinting={props.isPrinting}
        onPrintReady={props.onPrintReady}
        organizationData={{
            // company:props.data.organization.name,
            // companyNif: props.data.organization.cif,
            // address: props.data.organization.address,
            // cp: props.data.organization.cif + " "+ props.data.organization.city,
            // phone: props.data.organization.phone,
            // mail: props.data.organization.email,
            // mercantileRegistry: props.data.organization.mercantileRegistry,
        }}
        patientData={{
            // patientName: props.data.patient.name + " " + props.data.patient.surname + " " +
            // props.data.patient.surname2, patientPolicy: props.data.patient.patientInsCompanies.mutuaNumber,
            // patientAddress: props.data.patient.address, patientCp: props.data.patient.postalCode, patientCity:
            // props.data.patient.city, patientNif: props.data.patient.nif, patientCode: "CLPES"+
            // props.data.patient.id, patientMail: props.data.patient.email, patientPhone: props.data.patient.phone,
        }}
        invoiceData={{
            // invoiceNumber: (props.data.invoice.isPrivate?"DIMPRIV":"DIMM") +(new
            // date(props.data.invoice.invoiceDate).getFullYear().toString().substr(-2))+
            // props.data.invoice.invoiceNumber, invoiceDate: props.data.invoice.invoiceDate, invoicePeriod: "desde "+
            // getearliest_invoiceline_date(props.data.invoice) +" hasta " +
            // getlatest_invoiceline_date(props.data.invoice), invoiceCoin: "EUR",
        }}
        notes={'??'}
        case={'??'}
        // accountNumber={props.data.bankAccount.iban}


        columnDefs={[
            {
                field: 'date',
                headerName: 'Data',
                width: 90,
                valueFormatter({value}) {
                    if (value == null) return '(Sense data)'
                    return moment(value).format('DD/MM/YYYY');
                }
            }, {
                field: 'text',
                headerName: 'Concepte',
                width: 250,
                editable: !props.invoice?.invoiceNumber
            }, {
                field: 'price',
                headerName: '€/u',
                width: 70,
                ...stdNumericColumn()
            }, {
                field: 'quantity',
                headerName: 'Q',
                width: 50
            }, {
                field: 'totalPrice',
                headerName: 'Total €',
                width: 75,
                ...stdNumericColumn()
            },
        ]}
        invoice={props.invoice}
        invoiceForm={props.invoiceForm}
        type={'private'}
    />
})
