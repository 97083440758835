import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSVVStore} from "../../StandardVisitView";
import {Form, Field, SelectControl, LayoutBoundary} from "react-c4";
import {Button, NonIdealState, Popover} from "@blueprintjs/core";
import {SVHIdHeader} from "./components/SVHIdHeader/SVHIdHeader";
import {SVHFirstRow} from "./components/SVHFirstRow";
import {SVHBodyFields} from "./components/SVHBodyFields";
import {SVHProcessStages} from "./components/SVHProcessStages";
import {SVHChooseOneVisit} from "./components/secondary/SVHChooseOneVisit";
import {SVVCalendars} from "../SVVCalenadars/SVVCalendars";
import {SVHTasksBar} from "./components/SVHTasksBar/SVHTasksBar";
import {ErrorBoundary} from "@sentry/react";
import {SCVCalendar} from "../../../ScheduleView/SVMain/SCVCalendar/SCVCalendar";
import {ScheduleView} from "../../../ScheduleView/ScheduleView";

const Container = styled.div`
    display: grid;
    min-height: 210px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto 1fr;
    grid-gap: 4px;
    
`
const LeftContainer = styled.div`
    display: grid;
    grid-gap: 8px;
    align-items: start;
    grid-template-rows: auto auto 100px;
`
const ToolBarContainer = styled.div`
    display: grid;
    align-content: start;
    grid-auto-flow: row;
    grid-gap: 4px;
`
const IdHeaderContainer = styled.div`
    
`
const FirstRowContainer = styled.div`
    
`
const BodyFieldsContainer = styled.div`
    
`


export interface SVVVisitHeaderProps {

}

export const SVVVisitHeader = observer((props: SVVVisitHeaderProps) => {
    let store = useSVVStore()
    // if(!store.exactlyOneVisit()) return <div>Selecciona només una visita</div>

    let containerStyle = {}
    return <Container
        // onKeyDown={(e: KeyboardEvent) => {
        //     if (e.key == 'P') {
        //         store.visitViewMode = "form"
        //     } else if (e.key == 'O') {
        //         store.visitViewMode = "calendar"
        //     }
        //     console.log(`e.key`, e.key);
        // }}
    >
        <LeftContainer style={store.effectiveVisitViewMode == "form" ? {gridTemplateRows: 'auto auto 100px'} : {gridTemplateRows: 'auto auto'}}>
            <SVHIdHeader/>
            {store.effectiveVisitViewMode == "form" &&
            <React.Fragment>
                {
                    store.exactlyOneVisit ? <React.Fragment>
                        <SVHFirstRow/>
                        <LayoutBoundary>
                            <SVHBodyFields/>
                        </LayoutBoundary>
                        <SVHProcessStages/>
                        {false && <SVHTasksBar/>}
                    </React.Fragment> : <div>
                        {<SVHChooseOneVisit/>}
                    </div>
                }
            </React.Fragment>
            }
            {store.effectiveVisitViewMode == "calendar" && <SVVCalendars/>}
            {store.effectiveVisitViewMode == "calendarScv" && <ErrorBoundary>
              <ScheduleView pageContext={store.pageContext} onStoreReady={(s) => store.scvStore = s}/>
            </ErrorBoundary>}

        </LeftContainer>
        <ToolBarContainer>
            {/*<Button onClick={() => {*/}
            {/*    store.addVisit()*/}
            {/*}}>Afegir cita</Button>*/}
            {/*<Button onClick={() => {*/}
            {/*    store.removeSelVisit()*/}
            {/*}}>Eliminar cita</Button>*/}
            {/*<Button onClick={() => {*/}
            {/*    store.addVisitLine()*/}
            {/*}}>Afegir línia</Button>*/}
            {/*<Button onClick={() => {*/}
            {/*    store.removeSelVisitLine()*/}
            {/*}}>Eliminar línia</Button>*/}
            {/*<Button onClick={() => {*/}

            {/*}}>Reprogramar</Button>*/}
            {/*<Button onClick={() => {*/}

            {/*}}>Anul·lar</Button>*/}

        </ToolBarContainer>
        {/*<Field name={''} field={$visits.$('resource')} c={SelectControl}/>*/}
    </Container>
})

