import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useCFVStore} from '../ContactFileView'
import {C3AgGrid, MasterLayout} from "react-c4";
import {CellValueChangedEvent, IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import * as _ from "lodash";
import {
    formatFormalName,
    formatFullName,
    stdBoolColumn,
    stdDateColumn,
    stdIntNumericColumn
} from "../../../application/utils/utils";
import {Button, InputGroup, Popover} from "@blueprintjs/core";
import {SelectCE} from "../../../application/utils/components/SelectCE";

const Container = styled.div`
  display: grid;
  height: calc(100vh - 100px);
  padding: 8px;
  grid-template-columns: 1fr auto;

`

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 8px;
  place-content: start;
  padding-left: 5px;
`

const PopoverContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding: 16px;
  grid-gap: 16px;

`

const PopoverButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-auto-flow: column;
`

export interface CFVMainProps {
    pageContext
}

export const CFVMain = observer((props: CFVMainProps) => {
    let store = useCFVStore();
    let editableCheck = store.editorLocked;

    let insCompanyColumns = [
        {
            field: 'orgs',
            headerName: 'Organització',
            editable: false,
            cellEditor: 'agRichSelectCellEditor',
            valueFormatter: (params: ValueFormatterParams) => {
                if (_.isEmpty(params.value)) return '-'
                if (_.isArray(params.value)) params.value = params.value[0]
                return params?.value.shortName
            },
            filter: 'agTextColumnFilter',
        },
        {
            field: 'code',
            headerName: 'Codi Mutua',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'name',
            headerName: 'Nom',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 300
        }, {
            field: 'shortName',
            headerName: 'Nom Curt',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 100
        },
        {
            field: 'rateSheet',
            headerName: 'Full de Barems',
            editable: editableCheck,
            width: 178,
            cellEditorFramework: SelectCE,
            cellEditorParams: (params: IRichCellEditorParams) => {
                return {
                    values: store.rateSheetRes,
                    formatValue: (params) => {
                        return params?.name
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                if (_.isEmpty(params.value)) return '-'
                return params?.value.name
            },
            filter: false,
        },
        {
            field: 'isActive',
            headerName: 'Actiu',
            ...stdBoolColumn(()=>editableCheck),
            width: 55,
            editable: editableCheck,
        },
        {
            field: 'byPatientInvoicing',
            headerName: 'Facturar per pacient',
            ...stdBoolColumn(()=>editableCheck),
            width: 55,
            editable: editableCheck,
        },
        {
            field: 'subaccount',
            headerName: 'Subcompte',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'defaultPaymentForm',
            headerName: 'M. Pag. Predefinida',
            headerTooltip: 'Metode Pagament Pedefinida',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params: IRichCellEditorParams) => {
                return {
                    values: store.paymentMethodsMap,
                    formatValue: (params) => {
                        return params
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                if (_.isEmpty(params.value)) return '-'
                return params?.value.defaultPaymentForm
            },
        },
        {
            field: 'nif',
            headerName: 'CIF',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'address',
            headerName: 'Adreça',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 150,
        },
        {
            field: 'postalCode',
            headerName: 'C. Postal',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'city',
            headerName: 'Població',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'mobile',
            headerName: 'Tel 1',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'phone2',
            headerName: 'Tel 2',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'iban',
            headerName: 'Iban',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'oldCode',
            headerName: 'Codi Antic',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'state',
            headerName: 'País',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'id',
            headerName: 'Id',
            editable: false,
            width: 60,
            ...stdIntNumericColumn(),
        },
    ];
    let personColumns = [
        {
            field: 'surname1',
            headerName: 'Cognom 1',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'surname2',
            headerName: 'Cognom 2',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
    ]
    let alwaysColumns = [
        {
            field: 'notes',
            headerName: 'Notes',
            editable: editableCheck,
            filter: 'agTextColumnFilter',
            width: 250,
            floatingFilter: true,
        }
    ]
    // TODO When more than one contact type is displayed, accumulate bitmask of types and use all enabled column groups as colDefs, in specific order
    return <Container>
        <MasterLayout
            renderHeader={() => {
                return null
            }}
            body={
                <C3AgGrid
                    columnDefs={[
                        ...insCompanyColumns,
                        ...alwaysColumns
                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    params.api.exportDataAsExcel({
                                        fileName: 'llistat-Mutues',
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'orgs') {
                                                return data.orgs[0].shortName
                                            }
                                            if (params.column.getColId() == 'isActive') {
                                                return data.isActive == true ? 'X' : ''
                                            }
                                            if (params.column.getColId() == 'rateSheet') {
                                                return data.rateSheet ? data.rateSheet.name : '-'
                                            }
                                            if (params.column.getColId() == 'defaultPaymentForm') {
                                                return data.defaultPaymentForm ? data.defaultPaymentForm : '-'
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    rowData={store.contactsRes}
                    selection={store.contactSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        await store.saveContact({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                    }}

                />
            }
        />

        <ButtonContainer>

            <Button icon={'document'}
                    disabled={!store.editorLocked}
                    onClick={() => store.createContact()}
            />
            <Button icon={'trash'}
                    disabled={!store.editorLocked}
                    onClick={() => store.deleteSelectedContact()}
            />
            <Button icon={'strikethrough'} onClick={() => {
                store.showInactive = !store.showInactive
            }}
                    intent={store.showInactive ? "success" : "none"}
            ></Button>
            <Popover
                isOpen={store.popoverCheck}
                content={<PopoverContainer>
                    <InputGroup {...store.filterForm.$('editCode').bind()} leftIcon={'lock'} type={'password'}
                                placeholder={`Codi secret d'edició`}/>
                    <PopoverButtonsContainer>
                        <Button icon={'cross'} onClick={() => {
                            store.popoverCheck = false
                            store.filterForm.$('editCode').set('')
                        }}>Cancel·la</Button>
                        <Button icon={'tick'} disabled={store.filterForm.$('editCode').value != '777'}
                                intent={'warning'} onClick={async () => {
                            if (true) {
                                store.editorLocked = true
                                store.popoverCheck = false
                                store.filterForm.$('editCode').set('')
                                await store.fetch()
                            }

                        }}>Desbloquejar edició</Button>
                    </PopoverButtonsContainer>
                </PopoverContainer>}
            >
                <Button
                    icon={store.editorLocked ? 'unlock' : 'lock'}
                    intent={'none'}
                    onClick={() => {
                        if (store.editorLocked) {
                            store.editorLocked = false;
                        } else {
                            store.editorLocked = false;
                            store.popoverCheck = !store.popoverCheck;
                        }
                    }}
                />
            </Popover>
        </ButtonContainer>
    </Container>
})
