import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MPPStore} from "./MPPStore";
import {useC3Store} from "react-c4";
import {MPPMain} from "./MPPMain/MPPMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const MPPContext = React.createContext(null as MPPStore)
export const useMPPStore = () => useContext(MPPContext)

export interface PrescriptionViewProps {
    pageContext?
    onStoreReady: (s: MPPStore) => any
}

export const PrescriptionView = observer((props: PrescriptionViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new MPPStore()
        storeInst.init();
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <MPPContext.Provider value={store}>
        <Container>
            <MPPMain/>
        </Container>
    </MPPContext.Provider>
})
