import { C3, C3HttpClient, C3HttpFetcher, IATSStyleApp } from 'react-c4';
import { c3Schema } from './schema/c3Schema';

let forceProdBackend = process.env.PROD_BACKEND == "true";

let useHttps = window.location.protocol === 'https:' || forceProdBackend
let prodBackend = 'bis-dev.protocolapp.net';
let c3HttpFetcher = new C3HttpFetcher({
    hostname: forceProdBackend ? prodBackend : window.location.hostname,
    prefix: '/api',
    port: useHttps ? 443 : 3005,
    protocol: useHttps ? 'https' : 'http',
});
console.log(`baseUrl`, c3HttpFetcher.baseUrl);
export let client = new C3HttpClient({
    fetcher: c3HttpFetcher, endpointNameMap: { patient: 'patient' },
});

