import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ProductFamiliesFVStore} from "./ProductFamiliesFVStore";
import {useC3Store} from "react-c4";
import {ProductFamiliesFVMain} from "./ProductFamiliesFVMain/ProductFamiliesFVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
    padding: 8px;
`


export const ProductFamiliesFVContext = React.createContext(null as ProductFamiliesFVStore)
export const useProductFamiliesFVStore = () => useContext(ProductFamiliesFVContext)

export interface ProductFamiliesFileViewProps {
    pageContext?
    onStoreReady: (s: ProductFamiliesFVStore) => any
}

export const ProductFamiliesFileView = observer((props: ProductFamiliesFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new ProductFamiliesFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <ProductFamiliesFVContext.Provider value={store}>
        <Container>
            <ProductFamiliesFVMain pageContext={props.pageContext}/>
        </Container>
    </ProductFamiliesFVContext.Provider>
})
