import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {AVVStore} from "./AVVStore";
import {useC3Store} from "react-c4";
import {AVVMain} from "./AVVMain/AVVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const AVVContext = React.createContext(null as AVVStore)
export const useAVVStore = () => useContext(AVVContext)

export interface AllVisitsViewProps {
    pageContext?
    onStoreReady: (s: AVVStore) => any
}

export const AllVisitsView = observer((props: AllVisitsViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new AVVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <AVVContext.Provider value={store}>
        <Container>
            <AVVMain pageContext={props.pageContext}/>
        </Container>
    </AVVContext.Provider>
})
