import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3Selection} from "react-c4";
import * as _ from 'lodash'
import {useAVVStore} from "../AllVisitsView";
import {AVVStore} from "../AVVStore";
import moment from "moment";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2px;
    align-items: end;
        //overflow: auto;
    justify-content: start;
    width: 65px;
    
    .on-hover-all {
            visibility: hidden;
         }
     :hover {
         .on-hover-all {
            visibility: visible;
         }
     }
    
`
const BarContainer = styled.div`
    width: 10px;
    background-color: #d6a34b;
    font-size:8px;
    color: white;
     .on-hover {
            visibility: hidden;
         }
    :hover {
         border: 1px solid black;
         background-color: #d6a34b;
         .on-hover {
            visibility: visible;
         }
    }
    position: relative;
    display: grid;
    justify-items: center;
    cursor: pointer;
`

export interface MicroBarChartProps {
    sel: C3Selection
    store: AVVStore
}

export const MicroBarChart = observer((props: MicroBarChartProps) => {
    let totalHeight = 30
    let dayTotals = props.store.statsRes.dayTotals as any[];
    // let totalCounts = dayTotals.map(r => r.count);
    let maxDayCount = _.max(dayTotals.map(r => Number(r.count)))
    return <Container
        style={{height: totalHeight}}

    >
        {dayTotals.map(r => {
            let value = r.count / maxDayCount;
            if (value > 1) value = 1
            let moment1 = moment(r.day);
            let startDate = props.store.filterForm.$('startDate').value;
            let endDate = props.store.filterForm.$('endDate').value;
            let color;
            if (moment1.isBetween(startDate, moment(endDate), 'day', '[]')) {
                color = '#b31e28'
            }
            return <BarContainer
                onClick={() => props.store.goToDate(moment1.toDate())}
                style={{height: 1 + (value * totalHeight), backgroundColor: color}}>
                <div className={'on-hover-all'} style={{fontWeight: 'bold'}}>
                    {r.count}
                </div>
                <div className={'on-hover-all'} style={{position: 'absolute', bottom: -10, color: 'black'}}>
                    {moment1.format('dd')}
                </div>
            </BarContainer>
        })}
        {/*{JSON.stringify(dayTotals.map(dt => dt.count))}*/}
        {/*{maxDayCount} */}
    </Container>
})
