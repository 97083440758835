import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SRVStore} from "../../components/ScheduleRulesView/SRVStore";
import {ResourceSelector} from "../../application/components/shared/ResourceSelector";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    
`

export interface ScheduleRulesPageHeaderProps {
    store: SRVStore
}

export const ScheduleRulesPageHeader = observer((props: ScheduleRulesPageHeaderProps) => {
    return <Container>
        <ResourceSelector/>
        {/*<Button icon={'refresh'}*/}
        {/*        onClick={async () => {*/}
        {/*            await props.store.refresh()*/}
        {/*        }}*/}
        {/*/>*/}
    </Container>
})
