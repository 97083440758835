import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const productSubfamilySchema: C3SchemaInfo = {
    'product-subfamily': {
        fields: {
            id: {},
            name: {
                label: 'Nom'
            },
            'productFamily': {
                type: 'product-family',
                label: 'Família'
            },
            products: {
                multivalued: true,
                type: 'product',
                label: 'Proves'
            }
        }
    }
}
