import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const waitingRoomSchema: C3SchemaInfo = {
    'waiting-room': {
        fields: {
            id: {},
            name: {},
            facility: {
                type: 'facility',
                label: 'Edifici'
            },
            resources: {
                type: 'resource',
                multivalued: true,
                label: 'Recursos',
            }
        }
    }
}
