import {ICellRendererParams, ValueFormatterParams, ValueGetterParams} from "ag-grid-community";
import * as _ from 'lodash'
import React from "react";
import styled from 'styled-components'
import {Icon} from "@blueprintjs/core";

const CellWithIconContainer = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr auto;
    justify-content: end;
    grid-auto-flow: column;
    align-items: center;
    height: 100%;
`

export let invoiceNumberCell = (field = 'invoiceNumber', showPaid = false) => {
    return {
        field: field,
        headerName: 'N. Factura',
        width: 120,
        valueGetter: (params: ValueGetterParams) => {
            let data = params.data;
            let invoiceNumber = _.get(data, field);
            let draftInvoiceNumber = data.draftInvoiceNumber;
            return invoiceNumber || draftInvoiceNumber //|| data.id
        },
        valueFormatter: (params: ValueFormatterParams) => {
            let data = params.data;
            if (params.value == null) return ''
            let invoiceNumber = data.invoiceNumber;
            let draftInvoiceNumber = data.draftInvoiceNumber;
            let prefix = invoiceNumber ? '' : draftInvoiceNumber ? '*' : ''
            return `${prefix}${params.value}`
        },
        cellRendererFramework: (params: ICellRendererParams) => {
            let data = params.data;
            let invoiceNumberText = data?.invoiceNumberText;
            let invoiceNumber = data.invoiceNumber;
            let draftInvoiceNumber = data.draftInvoiceNumber;
            let title = [invoiceNumberText, data?.total && `Total: ${data?.total} €`].filter(v => v).join(' ');
            if (draftInvoiceNumber && !invoiceNumber) {
                return <CellWithIconContainer>
                    <div/>
                    <div style={{color: '#ee3023'}} title={title}>{params.valueFormatted}</div>
                </CellWithIconContainer>
            } else {
                // TODO Private bold and ins company in gray
                return <CellWithIconContainer>

                    <div/>
                    <div title={title}>{params.valueFormatted}</div>
                    {showPaid && params.valueFormatted && <Icon icon={data.invoicePaid ? 'tick' : 'credit-card'} intent={data.invoicePaid ? 'success' : 'warning'}/>}
                </CellWithIconContainer>
            }
        }
    }
}
