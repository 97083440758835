import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SVVPrivateInvoicePreviewHeader} from "../../../StandardVisitView/SVVMain/SVVPivateInvoicing/SVVPrivateInvoicePreview/SVVPrivateInvoicePreviewHeader";
import ErrorBoundary from "react-c4/src/app-shells/IATSStyle/page-templates/ErrorBoundary";
import {PrivateInvoice} from "../../../../documents/invoice-variants/PrivateInvoice";
import {useICIStore} from "../../InsCompanyInvoicingView";
import {InsCompanyInvoice} from "../../../../documents/invoice-variants/InsCompanyInvoice";

const Container = styled.div`
    display: grid;
    
`
const ViewerContainer = styled.div`
  //margin: 1px ;
  //overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-scrolling: touch;
  @media screen and (max-width: 1000px) {
    overflow-x: scroll;
  }

`
const SheetContainer = styled.div`
  //padding: -50px;
  margin-left: -20px;
  margin-right: -40px;
`;

export interface ICIInsCompanyBodyPreviewProps {

}

export const ICIInsCompanyBodyPreview = observer((props: ICIInsCompanyBodyPreviewProps) => {
    let store = useICIStore();

    let padding = `${Math.round(((store.invoicePreviewZoom / 100) - 1) * 200)}%`;
    // if (!store.canViewBeInvoiced) return null
    let compDocMarginPx = 20
    let mLeft = compDocMarginPx * -0.5
    let mTop = compDocMarginPx * -1.5
    let scrollbarWidth = 17;
    let mRight = (compDocMarginPx * -1 * 2) + (scrollbarWidth * 2 / 3)
    return <Container>
        <ViewerContainer>
            <SheetContainer style={{
                transform: `scale(${store.invoicePreviewZoom / 100})`,
                paddingTop: padding,
                marginLeft: mLeft,
                marginRight: mRight,
                marginTop: mTop
            }}>
                <ErrorBoundary>
                    <InsCompanyInvoice invoice={store.invoiceRes as any}
                                       isPrinting={store.isPrinting}
                                       onPrintReady={(h) => store.printHandle = h}/>
                </ErrorBoundary>
            </SheetContainer>
        </ViewerContainer>
    </Container>
})
