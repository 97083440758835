import {IATSStyleApp, MRF, C3} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {SVVStore} from "../StandardVisitView/SVVStore";
import {action, autorun, computed, observable} from "mobx";
import {ICellEditorParams} from "ag-grid-community";
import * as _ from "lodash";
import MobxReactForm from "mobx-react-form";
import {toJS} from "mobx";



export class DFEStore {
    appStore: AppStore
    svvStore: SVVStore

    pageContext

    @observable
    visitLineId: number

    @observable
    visitId: number

    form: MRF

    @observable
    initialized = false

    @observable
    deliveryType: any[]

    @observable
    allSelectedMethods = false


    params: ICellEditorParams

    constructor(svvStore: SVVStore) {
        this.svvStore = svvStore;

    }

    @observable
    visitLine

    @observable
    visit

    @observable
    forAllVisitLines = false

    @observable
    deliveredToOther = false


    // @computed
    // get colorStatus() {
    //     let status = this.form.$('deliveredStatus').value
    //     let color
    //     if (status === 'completed') {
    //         color = {
    //             intent: 'success',
    //             number: '#0f9960'
    //         };
    //     } else if (status === 'partial') {
    //         color = {
    //             intent: 'warning',
    //             number: '#d9822b'
    //         };
    //     } else {
    //         color = {
    //             intent: 'none',
    //             number: '#db3737'
    //         };
    //     }
    //     return color
    // }


    @action
    handleDeliverToOther() {
        this.deliveredToOther = !this.deliveredToOther
        this.saveDeliveryInfo({deliveredToOther: this.deliveredToOther})
    }


    handleDeliveryMethod(deliveryMethod) {
        console.log(`deliveryMethod`, deliveryMethod);
        deliveryMethod.check = !deliveryMethod.check
    }

    @action
    handleForAllVisitLines() {
        this.forAllVisitLines = !this.forAllVisitLines
    }


    selectedMethodsForFetch(): any {
        let selectedMethods = this.deliveryType.filter((dM) => {
            return dM.check === true
        })
        let result = {}
        selectedMethods.map((dM) => {
            result[dM.name] = {
                date: dM.date ? dM.date : this.form.$('date').value
            }
        })
        return result;
    }


    init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.form = new MobxReactForm({
            fields: [
                'comment',
                'deliveredToOtherComment',
                'deliveredItems'
            ],
            values: {
                comment: '',
                deliveredToOtherComment: '',
            }
        })

        this.initialized = true


    }

    async initForVisitLine(visitLineId, visitId, params: ICellEditorParams) {
        this.visitLineId = visitLineId
        this.visitId = visitId
        this.params = params
        this.clear()
        this.loadInfoIntoForm()

        setTimeout(() => {
            this.autofocus()
        })
    }

    allDeliveryItems = ['plaques', 'cd', 'book', 'informe']
    deliveryItemMap = {
        plaques: {
            name: 'Plaques',
        },
        cd: {
            name: 'Cd',
        },
        book: {
            name: 'Book',
        },
        informe: {
            name: 'Informe',
        },
    }

    clear() {
        this.form.$('comment').set('');
        this.form.$('deliveredToOtherComment').set('');
        this.form.$('deliveredItems').set(JSON.stringify({}))
        this.deliveryType = [
            {id: 'plaques', name: 'Plaques', check: false, date: undefined},
            {id: 'cd', name: 'Cd', check: false, date: undefined},
            {id: 'book', name: 'Book', check: false, date: undefined},
            {id: 'informe', name: 'Informe', check: false, date: undefined}
        ]
        this.forAllVisitLines = false
    }

    autofocus() {

    }

    loadInfoIntoForm() {
        this.visitLine = this.svvStore.getVisitLineField(this.visitLineId).value
        this.visit = this.svvStore.getVisitField(this.visitLine.visit.id).value
        let deliveryInfo = this.visitLine.deliveryInfo;
        if (deliveryInfo == null || deliveryInfo == '') return
        this.deliveredToOther = deliveryInfo.deliveredToOther
        this.form.$('comment').set(deliveryInfo.comment ? deliveryInfo.comment : '');
        this.form.$('deliveredToOtherComment').set(deliveryInfo.deliveredToOtherComment ? deliveryInfo.deliveredToOtherComment : '');
        let value = deliveryInfo.deliveredItems || {};
        let s = JSON.stringify(value);
        this.form.$('deliveredItems').set(s);
        // this.loadDeliveryMethods()
    }

    markItemDelivered(itemId, delivered?, send = true, deliveredBy: number = undefined) {
        let deliveredItemsValue = JSON.parse(this.form.$('deliveredItems').value || '{}');

        let itemsInfo = deliveredItemsValue || {}
        if(deliveredItemsValue[itemId]?.check == delivered) return
        deliveredItemsValue[itemId] = {
            date: new Date().toISOString(),
            check: delivered,
            userId: deliveredBy
        }
        if(send) this.saveDeliveryInfo({deliveredItems: itemsInfo})
        let newValue = JSON.stringify(itemsInfo);
        console.log(`newValue`, newValue);
        this.form.$('deliveredItems').set(newValue)
        // this.loadDeliveryMethods()
    }

    // loadDeliveryMethods() {
    //     let deliveredItems = JSON.parse(this.form.$('deliveredItems').value || '{}')
    //     for (const dm of this.deliveryType) {
    //         for (let i in deliveredItems) {
    //             if (dm.name == i) {
    //                 dm.check = true
    //                 dm.date = deliveredItems[i].date
    //             }
    //         }
    //     }
    // }

    // async saveForm(deliveryStatus) {
    //     const vl = {
    //         id: this.visitLineId,
    //         deliveryInfo: {
    //             deliveryDate: this.form.$('deliveryDate').value,
    //             deliveredItems: this.selectedMethodsForFetch(),
    //             deliveredToOther: this.deliveredToOther,
    //             deliveredToOtherComment: this.form.$('deliveredToOtherComment').value,
    //             comment: this.form.$('comment').value,
    //             deliveryStatus: deliveryStatus
    //         }
    //     }
    //     console.log(`saving vl body`, vl);
    //     await this.svvStore.saveVisitLine(vl, true)
    //     // await this.svvStore.refetch()
    // }

    async saveDeliveryInfo(deliveryInfo, refetch = false) {
        const vl = {
            id: this.visitLineId,
            visit: {id: this.visitId},
            deliveryInfo: deliveryInfo
        }
        console.log(`saving`, vl);
        await this.svvStore.saveVisitLine(vl, !deliveryInfo.replicateForVisit && refetch)
        if(deliveryInfo.replicateForVisit){
            await this.svvStore.refetchVisitLinesForVisits([this.visitId])
        }
    }

    async saveVisitLine(body) {
        const vl = {
            id: this.visitLineId,
            visit: {id: this.visitId},
            ...body
        }
        await this.svvStore.saveVisitLine(vl, false)
        // await this.svvStore.refetch()
    }

    close() {
        this.params.api.stopEditing(true)
    }

    selectAllMethods(active = true) {
        this.allDeliveryItems.forEach(itemId => this.markItemDelivered(itemId, true, false))
        let deliveredItemsValue = JSON.parse(this.form.$('deliveredItems').value || '{}');
        this.saveDeliveryInfo({deliveredItems: deliveredItemsValue})
    }

    @observable
    plaquesDeliveredBy: string

    @observable
    bookDeliveredBy: string

    @observable
    cdDeliveredBy: string

    @observable
    informeDeliveredBy: string

    async getUserName(userId, dM){
        if (userId == null) return null
        let user = await C3.instance.client.fetcher.fetch(`/auth/getUserName/${userId}`,{})
        if(dM == 'plaques') this.plaquesDeliveredBy = user.name
        if(dM == 'book') this.bookDeliveredBy = user.name
        if(dM == 'cd') this.cdDeliveredBy = user.name
        if(dM == 'informe') this.informeDeliveredBy = user.name
        return user.name
    }

}
