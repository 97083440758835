import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {CellValueChangedEvent} from "ag-grid-community";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {useRateFVStore} from "./RateFileView";
import {C3AgGrid} from "react-c4";
import {RateFileViewRatesheetGrid} from "./components/RateFileViewRatesheetGrid";
import {RateFileViewRateGrid} from "./components/RateFileViewRateGrid";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 8px;
    grid-gap: 8px;
`

export interface RateFVMainProps {
    pageContext
}

export const RateFVMain = observer((props: RateFVMainProps) => {
    let store = useRateFVStore()
    return <Container>
        <RateFileViewRatesheetGrid/>
        <RateFileViewRateGrid/>
    </Container>
})
