import {C3, C3Selection, IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {SCVStore} from "../ScheduleView/SCVStore";
import {autorun, observable, reaction, toJS} from "mobx";
import moment from "moment";
import later from "@breejs/later";
import {nextAnimationFrame} from "../../application/utils/utils";

export class SRVStore {
    appStore: AppStore
    pageContext


    @observable.shallow
    scvStore: SCVStore

    @observable.shallow
    rulesRes

    rulesSel: C3Selection

    daysOfWeekSel: C3Selection

    daysOfWeek = [
        {id: 'Mon', name: 'Dilluns'},
        {id: 'Tue', name: 'Dimarts'},
        {id: 'Wed', name: 'Dimecres'},
        {id: 'Thu', name: 'Dijous'},
        {id: 'Fri', name: 'Divendres'},
    ]


    @observable
    mode: 'block' | 'bounds'

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.rulesSel = new C3Selection({
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                let selectedRule = this.rulesSel.selectedItem;
                if (!selectedRule) return;
                if (selectedRule.expr) {
                    this.daysOfWeekSel.selectedIds = selectedRule.expr.split(',')
                } else {
                    this.daysOfWeekSel.selectedIds = []
                }

            }
        })
        this.daysOfWeekSel = new C3Selection({
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                if (this.rulesSel.selectedId == null) return;
                console.log(`selectedIds`, this.daysOfWeekSel.selectedIds);
                console.log(`toJS(selectedIds)`, toJS(this.daysOfWeekSel.selectedIds));
                let toJSSelectedIds = toJS(this.daysOfWeekSel.selectedIds)
                let expr = toJSSelectedIds.join(',');
                console.log(`expr`, expr);
                await this.saveRule({...this.rulesSel.selectedItem, exprType: 'weekly', expr: expr});
                console.log(`this.rulesSel.selectedItem`, this.rulesSel.selectedItem);
            }
        })
        this.daysOfWeekSel.itemsRef.current = this.daysOfWeek;
        this.mode = 'block'
        reaction(() => ({
            mode: this.mode,
            start: this.scvStore?.startDate,
            end: this.scvStore?.endDate,
        }), () => this.fetch(this.mode), {delay: 5})

        await nextAnimationFrame()
        await this.scvStore.showView('workWeekView')
        this.scvStore.showWeekend = true

        //this.fetch(this.mode)
        window['srvStore'] = this
        window['later'] = later
    }

    setSCVStore(store: SCVStore) {
        this.scvStore = store
        // console.log(`store`, store);
        this.scvStore.isRulesPage = true
        this.scvStore.cacheEnabled = false
        this.scvStore.srvStore = this
        this.scvStore.onDateClick = async (params) => {
            // console.log(`onDateClick params `, params);
            let id = this.rulesSel.selectedId;
            let item = this.rulesSel.selectedItem;
            let duration = id ? moment(item?.end).subtract(item?.start).minutes() : 60;
            if (duration < 30) duration = 30
            let ruleBody = {
                id: id,
                startDate: params.date,
                endDate: moment(params.date).add(duration, 'minutes').toDate(),
                resource: {id: params.resource.id},
                ...(!id && {title: 'Sense títol'})
            };
            if (id) {
                await this.saveRule(ruleBody)
            } else {
                await this.createRule(ruleBody)
            }
            await this.scvStore.refetch()
        }
        this.scvStore.onResizeEvent = (params) => {
            // console.log(`resize params `, params);
            this.saveRule({
                id: parseInt(params.event.id),
                startDate: params.event.start,
                endDate: params.event.end,
            })
            this.scvStore.refetch()
        }
        this.scvStore.onDropEvent = async (params) => {
            // console.log(`onDropEvent params `, params);
            let resourceId = (params as any).newResource?.id;
            this.saveRule({
                id: parseInt(params.event.id),
                startDate: params.event.start,
                endDate: params.event.end,
                ...(resourceId && {resource: {id: resourceId}})
            })
            await this.scvStore.refetch()
            this.rulesSel.selectId(parseInt(params.event.id), true, true, true, true)
        }
        this.scvStore.onDragEvent = (params) => {
            // console.log(`onDragEvent params `, params);
            // console.log(`params`, params, params.event.id);

        }
        autorun(async () => {
            let selectedId = this.rulesSel.selectedId;
            await nextAnimationFrame()
            if (this.scvStore.meetupEventsEventSource)
                this.scvStore.meetupEventsEventSource.refetch()
            // this.scvStore.fetch()

        })
        this.scvStore.refetch()
    }

    async fetch(type) {
        let start = this.scvStore?.startDate;
        let end = this.scvStore?.endDate;
        if (!start) return
        let startDateStr = moment(start).toISOString();
        let endDateStr = moment(end).toISOString();
        this.rulesRes = await C3.instance.client.fetcher.fetch('/schedule-view/calendar-rules', {
            query: [
                `type=${type}`,
                `start=${startDateStr}`,
                `end=${endDateStr}`,
            ].join('&'),
        })
        this.rulesSel.itemsRef.current = this.rulesRes;
        return this.rulesRes
    }


    async saveRule(rule) {
        await nextAnimationFrame()
        console.log(`rule`, rule);
        if (!rule.id) return
        await C3.instance.client.fetcher.fetch(`/schedule-view/calendar-rules/${rule.id}`, {
            method: 'PATCH',
            body: {...rule, type: this.mode}
        })
        await Promise.all([this.fetch(this.mode), this.scvStore.refetch()])
    }

    async refetchAll() {
        return await Promise.all([this.fetch(this.mode), this.scvStore.refetch()])
    }

    async setRepeat(rule, newValue: boolean) {
        if (newValue) {
            // Set to week
            rule.applicableFrom = rule.startDate;
            rule.applicableUntil = moment(rule.endDate).add(120, 'day');
            rule.r = newValue
        } else {
            // Set to same as day
            rule.applicableFrom = rule.startDate;
            rule.applicableUntil = rule.endDate;
            rule.r = newValue
        }
        await this.saveRule(rule)
    }

    async createRuleInCurrentView() {
        await this.createRule({})
    }

    async createRule(body) {
        let res = await C3.instance.client.fetcher.fetch('/schedule-view/calendar-rules', {
            method: 'POST',
            body: {name: 'Sense títol', type: this.mode, ...body}
        })
        await this.refetchAll()
        this.rulesSel.selectId(res.id)
    }

    async deleteRule(ruleId) {
        await C3.instance.client.fetcher.fetch(`/schedule-view/calendar-rules/${ruleId}`, {
            method: 'DELETE',
        })
        await this.refetchAll()
    }

    async deleteSelectedRule() {
        let selectedId = this.rulesSel.selectedId;
        console.log(`selectedId`, selectedId);
        console.log(`this.rulesSel`, this.rulesSel);
        if (selectedId == null) return
        await this.deleteRule(selectedId)
    }
}
