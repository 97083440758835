import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {IEVStore} from "./IEVStore";
import {useC3Store} from "react-c4";
import {IEVMain} from "./IEVMain/IEVMain";

const Container = styled.div`
    display: grid;
    
`


export const IEVContext = React.createContext(null as IEVStore)
export const useIEVStore = () => useContext(IEVContext)

export interface InvoiceEditorViewProps {
    pageContext?
    onStoreReady: (s: IEVStore) => any
}

export const InvoiceEditorView = observer((props: InvoiceEditorViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new IEVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <IEVContext.Provider value={store}>
        <Container>
            <IEVMain pageContext={props.pageContext}/>
        </Container>
    </IEVContext.Provider>
})
