import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {RSCHeader} from "./components/1-RSCHeader/RSCHeader";
import {RSCBody} from "./components/2-RSCBody/RSCBody";
import {RSCStore} from "./RSCStore";
import {SCVStore} from "../ScheduleView/SCVStore";
import {ICellEditorParams} from "ag-grid-community";

let colW = 258;
let w = 350;
let h = 320;
const Container = styled.div`
  display: grid;
  // width: ${w}px;
  height: ${h}px;
  background-color: #F5F8FA;
  grid-template-rows: auto 1fr;
  grid-gap: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);

`

export const RSCContext = React.createContext(null as RSCStore)
export const useRSCStore = () => useContext(RSCContext)

export interface RSCMainProps {
    store: RSCStore
    params: ICellEditorParams
}

export const RSCMain = observer((props: RSCMainProps) => {
    return <RSCContext.Provider value={props.store}>
        <Container>
            <RSCHeader/>
            <RSCBody/>
        </Container>
    </RSCContext.Provider>
})
