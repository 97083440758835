import {logoName} from "../../application/utils/utils";

export let logoImgs = {
    'logo.jpg': require('./' + 'logo.jpg'),
    'logoDimat.png': require('./' + 'logoDimat.png'),
    'logoEspuche.png': require('./' + 'logoEspuche.png'),
    'logoGuirado.jpg': require('./' + 'logoGuirado.jpg'),
    'logoMedicalOne.png': require('./' + 'logoMedicalOne.png'),

}

