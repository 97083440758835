import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {formatFormalName, paymentFormMap} from "../../application/utils/utils";
import {SchedulePageHeader} from "../SchedulePage/SchedulePageHeader";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {Page} from "react-c4";
import {DCSStore} from "../../components/DailyCheckoutSheetView/DCSStore";
import {DailyCheckoutSheetPageHeader} from "./DailyCheckoutSheetPageHeader";
import {DailyCheckoutSheetView} from "../../components/DailyCheckoutSheetView/DailyCheckoutSheetView";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface DailyCheckoutSheetPageProps {

}

export const DailyCheckoutSheetPage = page(observer((props: DailyCheckoutSheetPageProps) => {
    let [store, setStore] = useState(null as DCSStore);
    let type = store?.filterForm?.$('type')?.value;

    return <Page
        title={'Full de caixa diari'}
        subtitle={(type == 'all' ? 'Targeta, efectiu i transferència' : paymentFormMap[type])}
        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <DailyCheckoutSheetPageHeader store={store}/>
        }}

        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <DailyCheckoutSheetView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))


