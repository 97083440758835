import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSVVStore} from "../../StandardVisitView";
import {Button, ButtonGroup, Tooltip} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: start;
  align-items: center;
`

export interface SVVAttachDocumentsHeaderProps {

}

export const SVVAttachDocumentsHeader = observer((props: SVVAttachDocumentsHeaderProps) => {
    let store = useSVVStore()
    let inputRef = useRef(null)
    let attachFile = () => {
        let input = inputRef.current
        if (input != null) {
            input.click();
        }
    }
    let selectedCount = store.documentsSel.selectedIds.length;
    return <Container>
        <Button icon={'cross'} onClick={() => store.exitAttachDocuments()}>Tanca</Button>
        {!store.isAttachDocumentsModeReadonly && store.canAttachDocuments &&
        <Button icon={'folder-open'} loading={store.isAttachingDocuments} onClick={() => attachFile()}>Afegir
          documents</Button>}
        <input id="myInput"
               type="file"
               ref={inputRef}
               style={{display: 'none'}}
               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                   store.attachDocuments(e.currentTarget.files)
               }}
               multiple
        />

        {/*<ButtonGroup>*/}
        {/*    <Tooltip content={'Adjunta a visita'} hoverOpenDelay={1000}>*/}
        {/*        <Button active={store.isDocAttachingToVisit}*/}
        {/*                onClick={() => store.setDocAttachingToVisit(true)}>V</Button></Tooltip>*/}
        {/*    <Tooltip content={'Adjunta a acta'} hoverOpenDelay={1000}>*/}
        {/*        <Button active={!store.isDocAttachingToVisit}*/}
        {/*                onClick={() => store.setDocAttachingToVisit(false)}>A</Button></Tooltip>*/}
        {/*</ButtonGroup>*/}

        <Button icon={'eye-open'}
                active={store.isAttachDocumentsModeLargePreview}
                onClick={() => store.isAttachDocumentsModeLargePreview = !store.isAttachDocumentsModeLargePreview}/>

        <Button icon={'trash'}
                disabled={!store.canDeleteDocuments}
                onClick={() => store.deleteSelectedDocuments()}
        />

        <Button
            active={store.isAttachDocumentsModeShowHistory}
            icon={'history'}
            onClick={() => store.isAttachDocumentsModeShowHistory = !store.isAttachDocumentsModeShowHistory}>Anteriors</Button>
        {selectedCount > 1 ? <div>{selectedCount} seleccionats</div> : null}

    </Container>
})
