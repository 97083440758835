import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const bookVisitViewSchema: C3SchemaInfo = {
    'book-visit-gen': {
        local: true,
        fields: {
            id: {},
            // Generation
            resources: {
                type: 'resource',
                label: 'Recursos (Màquines)',
                multivalued: true
            },
            products: {
                type: 'product',
                label: 'Productes (Proves)',
                multivalued: true
            },
            // End Generation
            patient: {
                type: 'patient',
                label: 'Pacient',
            },
            visitLines: {
                type: 'visit-line',
                label: 'Línies de visita',
                multivalued: true,
                subfields: true,
                fields: {
                    // visit: {
                    //     subfields: true
                    // },
                    // resource: {
                    //     subfields: true
                    // },

                }
            }
        }
    }
}
