import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {BSVStore} from "../../components/BasicStatisticsView/BSVStore";
import {MEVStore} from "../../components/MeetupEventsView/MEVStore";
import {Button, Icon, InputGroup, Popover} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;

`

const PopoverContainer = styled.div`
  display: grid;
  padding: 8px;
  grid-gap: 8px;
`

export interface BasicStatisticsPageHeaderProps {
    store: MEVStore
}

export const MeetupEventsPageHeader = observer((props: BasicStatisticsPageHeaderProps) => {
    let store = props.store
    return <Container>
        <Popover content={<PopoverContainer>
            <div>
                Url Name:
                <InputGroup {...store?.urlNameForm?.$('urlName').bind()} placeholder={'Url name'}/>
            </div>
            <Button onClick={() => store?.createMeetupGroup()}>Crear grup</Button>
        </PopoverContainer>}>
            <Button>Afegir grup</Button>
        </Popover>
        <Button active={store?.filterFromToday} onClick={() => store.filterFromToday = !store.filterFromToday}>Filtrar a partir d'avui</Button>


    </Container>
})
