import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {CellValueChangedEvent} from "ag-grid-community";
import {useRefFVStore} from "./ReferrerFileView";
import {RefFVReferrerForm} from "./components/RefFVReferrerForm";
import {RefFVPendingReviewGrid} from "./components/RefFVPendingReviewGrid";
import {RefFVReviewedGrid} from "./components/RefFVReviewedGrid";
import {RefFVReviewedForm} from "./components/RefFVReviewedForm";

const Container = styled.div`
  display: grid;
  height: calc(100vh - 100px);
  padding: 8px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`
const BodyContainer = styled.div`
  display: grid;
  grid-template-rows: 3fr 1fr;

`

export interface RefFVMainProps {

}

export const RefFVMain = observer((props: RefFVMainProps) => {
    let store = useRefFVStore()
    return <Container>
        <BodyContainer>
            <RefFVPendingReviewGrid/>
            <RefFVReferrerForm/>
        </BodyContainer>

            <RefFVReviewedGrid/>
            {/*<RefFVReviewedForm/>*/}
    </Container>
})
