import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const invoiceSchema: C3SchemaInfo = {
    'invoice': {
        fields: {
            id: {},

            // TODO Add all invoice fields

            lines: {
                type: 'invoice-lines',
                multivalued: true
            },
            contact: {
                type: 'contact'
            },
            patient: {
                type: 'patient'
            }
        }
    }
}
