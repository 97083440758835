import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import { useC3Store } from "react-c4";
import {SCVStore} from "./SCVStore";
import {SVVMain} from "../StandardVisitView/SVVMain/SVVMain";
import {SVVStore} from "../StandardVisitView/SVVStore";
import {SCVMain} from "./SVMain/SCVMain";

const Container = styled.div`
    display: grid;
    padding: 8px;
    grid-gap: 8px;
    grid-auto-flow: row;
    grid-template-rows: auto;
    height: 100%;
    
`


export const SCVContext = React.createContext(null as SCVStore)
export const useSCVStore = () => useContext(SCVContext)


export interface ScheduleViewProps {
    pageContext?
    onStoreReady: (s: SCVStore) => any
}

export const ScheduleView = observer((props: ScheduleViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new SCVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <SCVContext.Provider value={store}>
        <Container>
            <SCVMain pageContext={props.pageContext}/>
        </Container>
    </SCVContext.Provider>
})
