import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {BusinessLineFVStore} from "./BusinessLineFVStore";
import {useC3Store} from "react-c4";
import {BusinessLineFVMain} from "./BusinessLineFVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const BusinessLineFVContext = React.createContext(null as BusinessLineFVStore)
export const useBusinessLineFVStore = () => useContext(BusinessLineFVContext)

export interface BusinessLineFileViewProps {
    pageContext?
    onStoreReady: (s: BusinessLineFVStore) => any
}

export const BusinessLineFileView = observer((props: BusinessLineFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new BusinessLineFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <BusinessLineFVContext.Provider value={store}>
        <Container>
            <BusinessLineFVMain pageContext={props.pageContext}/>
        </Container>
    </BusinessLineFVContext.Provider>
})
