import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const rateSheetSchema: C3SchemaInfo = {
    'rate-sheet': {
        fields: {
            id: {},
            name: { label: "Nom" },
            description: { label: "Notes"},
            paymentDescription: { label:"Descripció de pagament"},
            chipboardPayment:{ label: "Chip"},
            manualPayment:{ label: "Manual"},
            monthlyPayment:{ label: "Mensual"},
            authRequired:{ label: "Requereix Autorització"},
            hoursToDeliver: {label: "Hores entrega"},
            insCompanies: {
                type: 'contact',
                multivalued: true
            },
            rates: {
                type: 'rate',
                multivalued: true
            },
            createdAt: {},

        }
    }
}

