import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {autorun, computed, observable, untracked} from "mobx";
import moment from "moment";
import MobxReactForm from "mobx-react-form";
import {isDev, nextAnimationFrame, nextTimeout} from "../../application/utils/utils";
import * as _ from 'lodash'
import {connectableObservableDescriptor} from "rxjs/internal/observable/ConnectableObservable";
import {GridApi} from "ag-grid-community";

export class ICIStore {
    appStore: AppStore
    pageContext


    visitLinesSel: C3Selection

    // @observable.shallow
    // orgsRes: any[]
    // orgsSel: C3Selection

    @observable
    visitLinesRes: any[]

    filterForm: MRF

    @observable
    allInsCompanies: boolean = false

    @observable
    simpleMode: boolean = false

    @observable
    pendingLinesOnly: boolean = true

    typeSel: C3Selection

    @observable
    viewMode: 'edit' | 'preview' = "edit"

    printHandle

    @observable
    invoicePreviewZoom = 85

    @observable
    headerExpanded = false

    @observable
    assistantMode = !isDev()

    @observable
    closingInvoicePrompt = false

    @observable
    addLinesToInvoice = false

    @observable
    editingNotes = false

    @observable
    loading = {
        refreshing: false,
        fetchingInvoice: false,
        fetchingLines: false,
        creatingDraft: false,
        creatingByPatientInvoices: false,
        closingInvoice: false,

    }

    @observable
    isAssigningLinesToInvoice = false

    @observable
    assigningLinesToInvoiceItem = null

    vlGridApi: GridApi

    invoiceForm: MRF

    @observable
    popoverDateLockerCheck = false

    @observable
    dateEditorLocked = false

    @observable
    byPatientInvoicing = false

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection

    async init() {
        this.assistantMode = localStorage.getItem('assistant-mode-1') == 'true'
        autorun(() => {
            localStorage.setItem('assistant-mode-1', this.assistantMode ? 'true' : 'false')
        })
        this.appStore = IATSStyleApp.instance.appStore
        this.orgsSel = new C3Selection({})
        this.orgsRes = this.appStore.orgs
        this.invoiceCounterSel = new C3Selection(({
            items: this.invoiceCounterRes
        }))
        this.invoiceForm = new MobxReactForm({
            fields: [
                'id',
                'notes',
                'comment',
                'invoiceDate',
                'invoiceCounter',
                'invoiceYear',
                'editCode'
                // ...[
                //     'id',
                //     'name',
                //     'surname1',
                //     'surname2',
                //     'nif',
                //     'address',
                //     'city',
                //     'postalCode',
                // ].map(p => `patientAltInvoicing.${p}`),
                // 'payments[]'
            ],
            values: {
                notes: "",
                comment: "",
                invoiceDate: new Date(),
                editCode: ''
            }
        })

        this.insCompaniesSel = new C3Selection({
            onSelectionChanged: async () => {
                // this.visitLinesRes = []
                // this.visitLinesSel.itemsRef.current = []
                this.byPatientInvoicing = false
                await nextAnimationFrame()
                if (this.insCompaniesSel.selectedIds.length > 0) {
                    this.selectNotInvoiced()
                    if (this.assistantStep == 0) this.assistantStep = 1
                }
            }
        })
        // this.orgsSel = new C3Selection(({
        //     items: this.orgsRes,
        //     onSelectionChanged: () => {
        //         this.filterForm.$('orgs').set(this.orgsSel.selectedItem)
        //     }
        // }))
        this.invoicesSel = new C3Selection({
            onSelectionChanged: () => {
                // this.visitLinesRes = []
                // this.visitLinesSel.itemsRef.current = []
            }
        })
        this.visitLinesSel = new C3Selection({
            items: []
        })
        let resetDates = this.getResetDates();

        this.filterForm = new MobxReactForm({
            fields: [
                'startDate',
                'endDate',
                'type',
                'orgs',
                'invoicingCode',
                'invoiceCounter',
                'invoiceNumber',
                'editCode'
            ],
            values: {
                startDate: resetDates[0],
                orgs: [],
                endDate: resetDates[1],
                type: 'all',
                invoicingCode: '',
                invoiceYear: moment(new Date).format('YY'),
                editCode: ''
            },
            hooks: {}
        })


        this.typeSel = new C3Selection({
            items: [
                {id: 'all', name: 'Tots'},
                {id: 'chipcard', name: 'Chipcard'},
                {id: 'manual', name: 'Manual'},
                {id: 'unassigned', name: 'No Assignat'},
            ],
            onSelectionChanged: () => {
                let id = this.typeSel.selectedItem.id as string;
                this.filterForm.$('type').set(id)
                if (this.assistantStep)
                    if (['chipcard', 'manual'].includes(id)) this.assistantStep = 2
            },
        })
        this.typeSel.selectedIds = ['all']


        await this.fetchInsCompanies()
        autorun(async () => {
            this.orgsSel.selectedId
            this.invoicesRes = [];
            this.insCompaniesRes = [];
            this.visitLinesRes = [];
            let selectedId = this.insCompaniesSel.selectedId;
            let type = this.filterForm.$('type').value;
            if (untracked(() => this.invoicesSel.selectedId) == 'not-invoiced') {
                this.invoiceRes = null
                await this.fetchSelectedInsCompanyPendingLines()
            }
            await this.fetchInsCompanyInvoices(selectedId, this.startDate, this.endDate, type)
            await nextAnimationFrame()
            this.selectNotInvoiced()
        })
        autorun(async () => {
            let selectedId = this.orgsSel.selectedId;
            await this.fetchInvoiceCounter()
            if (this.invoiceCounterRes == null) return;
            this.filterForm.$('invoiceCounter').set(this.invoiceCounterRes[0])
            // if (this.filterForm.$('invoiceCounter').value == null) {
            // }
        })
        autorun(() => {
            if (this.invoicesSel.selectedId == 'not-invoiced') {
                this.invoiceRes = null
                this.fetchSelectedInsCompanyPendingLines()
            } else {
                Promise.all([this.fetchInvoice(this.invoicesSel.selectedId),
                    this.fetchInvoiceVisitLines(this.invoicesSel.selectedId)])
            }
        })
        autorun(async () => {
            this.filterForm.$('orgs').value;
            this.fetchInsCompanies();
        })
        autorun(async () => {
            this.filterForm.$('orgs').value;
            this.orgsRes = this.appStore.orgs;
            this.orgsSel.itemsRef.current = this.orgsRes;
            if (this.orgsRes == null) return
            this.orgsSel.selectedIds = [this.orgsRes[0].id];

        })
        // autorun(async () => {
        //     this.orgsRes = this.appStore.orgs;
        //     if (this.orgsRes.length === 0) return
        //     this.orgsSel.itemsRef.current = this.orgsRes;
        //     this.orgsSel.selectedIds = [this.orgsRes[0].id];
        // })
        autorun(() => {
            // console.log(`this.loading.fetchingLines, this.loading.fetchingInvoice`, this.loading.fetchingLines, this.loading.fetchingInvoice);
            if (this.vlGridApi) {
                if (this.loading.fetchingLines || this.loading.fetchingInvoice)
                    this.vlGridApi.showLoadingOverlay()
                else this.vlGridApi.hideOverlay()
            }
        })
        autorun(() => {
            let id = this.invoicesSel.selectedId
            this.dateEditorLocked = false
        })

        // intercept(this, 'canViewBeInvoiced', () => {
        //
        // })

        window['store'] = this

    }

    steps = {
        'choose-ins-company': 0,
        'choose-filter': 1,
    }
    @observable
    assistantStep = 0;

    @computed
    get assistantMessage() {
        let s = this.assistantStep
        return [
            'Selecciona una mútua',
            'Selecciona chipcard/manual',
            'Selecciona línies a facturar i crea la pre-factura',
            'Tanca la factura (Aquest procés és irreversible!)',
            'Visualitza i imprimeix la factura',
        ][s]
    }

    async goBack() {
        if (this.assistantStep == 0) {

        } else if (this.assistantStep == 1) {
            this.insCompaniesSel.selectedIds = []
            this.insCompaniesSel.clearSelection()
            this.assistantStep = 0
        } else if (this.assistantStep == 3) {
            await this.deleteInvoice()
            this.assistantStep = 2
        } else {
            this.assistantStep--;
        }
    }

    @computed
    get canGoBack() {
        return this.assistantStep > 0 && this.assistantStep < 4
        // return [1].includes(this.assistantStep)
    }

    // @computed
    // get assistantStep() {
    //     if (!this.insCompaniesSel.selectedId)
    //         return 0
    //     if (!['chipcard', 'manual'].includes(this.typeSel.selectedId as string))
    //         return 1
    //     return 100
    // }

    @computed
    get canPreview() {
        if (this.invoiceStatus == 0) return false
        return true
    }

    @computed
    get canEdit() {
        if (this.assistantMode && this.assistantStep >= 3) return false
        return true
    }

    @computed
    get effectiveViewMode(): "edit" | "preview" {
        if (this.isPrinting) return 'preview'
        if (!this.canEdit) return "preview"
        if (!this.canPreview) return "edit"
        return this.viewMode
    }

    @computed
    get isPreview() {
        return this.effectiveViewMode == "preview"
    }


    @computed
    get isInvoicableType() {
        return ['chipcard', 'manual'].includes(this.typeSel.selectedId as string);
    }


    @computed
    get canViewBeInvoiced() {
        let insCompanySelected = this.insCompaniesSel.selectedId;
        return insCompanySelected && this.isInvoicableType
    }

    @computed
    get canCreateDraftInvoice() {
        let someVisitLineSelected = this.visitLinesSel.selectedItems.length > 0;
        return this.canViewBeInvoiced && someVisitLineSelected
    }

    @computed
    get startDate() {
        return moment(this.filterForm.$('startDate').value).toDate();
    }

    @computed
    get endDate() {
        return moment(this.filterForm.$('endDate').value || this.startDate).toDate();
    }

    @computed
    get type() {
        return this.filterForm.$('type').value
    }


    // [inscomp] InsCompanies grid (Grid 1)

    insCompaniesSel: C3Selection

    @observable
    insCompaniesRes: any[]

    async fetchInsCompanies() {
        let orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (orgs === '') return
        let res = await C3.instance.client.fetcher.fetch('/ins-company-invoicing/ins-companies', {
            query: [`start=${this.startDate.toISOString()}`, `end=${this.endDate.toISOString()}`, orgs].join('&')
        })
        this.insCompaniesSel.itemsRef.current = res
        this.insCompaniesRes = res
        this.updateNotInvoicedRow()
        return res
    }


    // [inscomp] Invoices grid (Grid 2)


    invoicesSel: C3Selection

    @observable
    /**
     * The current invoice
     */
    invoicesRes: any[]

    async fetchSelectedInsCompanyPendingLines() {
        let selectedId = this.insCompaniesSel.selectedId;
        let type = this.filterForm.$('type').value;
        return await this.fetchPendingVisitLines(selectedId, this.startDate, this.endDate, type)

    }

    async refetchBodyGrid() {
        if (this.invoicesSel.selectedId == 'not-invoiced') {
            this.invoiceRes = null
            await this.fetchSelectedInsCompanyPendingLines()
        } else {
            await Promise.all([this.fetchInvoice(this.invoicesSel.selectedId),
                this.fetchInvoiceVisitLines(this.invoicesSel.selectedId)])
        }
    }

    async fetchSelectedInsCompanyInvoices() {
        let selectedId = this.insCompaniesSel.selectedId;
        let type = this.filterForm.$('type').value;
        return await this.fetchInsCompanyInvoices(selectedId, this.startDate, this.endDate, type)
    }

    async fetchInsCompanyInvoices(selectedId, startDate, endDate, type) {
        if (selectedId == null) {
            this.invoicesRes = []
            this.invoicesSel.itemsRef.current = []
            return
        }
        if (this.orgsSel.selectedId == null) return
        let res = await C3.instance.client.fetcher.fetch(`/ins-company-invoicing/ins-companies/${selectedId}/invoices`, {
            query: [`start=${startDate.toISOString()}`, `end=${endDate.toISOString()}`, `type=${type}`, `org=${this.orgsSel.selectedId}`].join('&'),
        })

        this.invoicesSel.itemsRef.current = res ? [{id: 'not-invoiced', invoiceNumber: ''}, ...res] : [];
        this.invoicesRes = res

        this.updateNotInvoicedRow()

        return res
    }

    updateNotInvoicedRow() {
        if (!this.invoicesRes || this.invoicesRes?.length == 0) return
        let newVar = {
            id: 'not-invoiced',
            invoiceNumber: `<No facturades ${_.lowerCase(this.typeSel.selectedItem?.name)}>`,
            count: this.selectedTypeStats?.count,
            total: this.selectedTypeStats?.sum,
            // periodStartDate: this.startDate,
            // periodEndDate: this.endDate
        };
        let [first, ...rest] = this.invoicesSel.itemsRef.current
        this.invoicesSel.itemsRef.current = [newVar, ...rest]
        this.invoicesRes = this.invoicesSel.itemsRef.current
    }

    @computed
    get selectedTypeStats() {
        let value = this.filterForm.$('type').value;
        if (value == 'all') value = 'total'
        let selectedItemElement = this.insCompaniesSel.selectedItem?.[value];
        return selectedItemElement
    }


    @observable
    invoiceRes

    async fetchInvoice(selectedId) {
        this.loading.fetchingInvoice = true
        if (selectedId == null) {
            this.invoiceRes = null
            this.loading.fetchingInvoice = false
            return
        }
        let res = await C3.instance.client.fetcher.fetch(`/ins-company-invoicing/invoice/${selectedId}`, {})
        this.invoiceRes = res

        this.invoiceForm.clear()
        this.invoiceForm.set(res)
        this.loading.fetchingInvoice = false

        return res
    }

    async fetchPendingVisitLines(insCompanyId, startDate, endDate, type) {
        this.loading.fetchingLines = true

        let res: any = []
        if (insCompanyId != null) {
            res = await C3.instance.client.fetcher.fetch(`/ins-company-invoicing/ins-companies/${insCompanyId}/pending-visit-lines`, {
                query: [`start=${startDate.toISOString()}`, `end=${endDate.toISOString()}`, `type=${type}`].join('&'),
            });
        }
        this.visitLinesSel.selectedIds = []
        this.visitLinesSel.itemsRef.current = res
        this.visitLinesRes = res
        this.loading.fetchingLines = false

        return res
    }

    async fetchInvoiceVisitLines(selectedId) {
        let res: any = []
        if (selectedId != null) {
            res = await C3.instance.client.fetcher.fetch(`/ins-company-invoicing/invoice/${selectedId}/visit-lines`, {});
        }
        this.visitLinesSel.selectedIds = []
        this.visitLinesSel.itemsRef.current = res
        this.visitLinesRes = res
        return res
    }

    selectNotInvoiced() {
        let index = this.invoicesSel.items.findIndex(i => i.invoiceNumber == null && i.draftInvoiceNumber);
        if (index >= 0) {
            this.invoicesSel.selectIndex(index)

        } else {
            this.invoicesSel.selectId('not-invoiced')
        }
    }

    async deleteInvoice() {
        let selectedId = this.invoicesSel.selectedId;
        if (selectedId == null) {
            this.invoiceRes = null
            return
        }
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${selectedId}`, {
            method: 'DELETE'
        });
        this.invoiceRes = null
        await this.fetchSelectedInsCompanyInvoices()
        this.selectNotInvoiced()
        // In case we show something related on the left
        await this.fetchInsCompanies()
        return
    }

    async refresh() {
        this.loading.refreshing = true
        await this.fetchInsCompanies()
        await this.fetchSelectedInsCompanyInvoices()
        await this.refetchBodyGrid()
        this.loading.refreshing = false

    }

    //
    @computed
    get tmpInvoice() {
        return {
            org: {
                id: 1, name: 'DIMAT',
            },
            invoiceLines: this.visitLinesRes,//this.visitLinesSel.selectedItems
        }
    }

    @computed
    get invoiceStatus(): 0 | 1 | 2 {
        let res = this.invoiceRes;
        return res?.draftInvoiceNumber ? (res?.invoiceNumber ? 2 : 1) : 0
    }

    @computed
    get allLinesSelected() {
        return this.visitLinesSel.allSelected
    }

    async createInsCompanyInvoice(fromSelected?: boolean) {
        this.loading.creatingDraft = true
        let insCompanyCounter = 2;
        let startingLineIds = this.visitLinesSel.selectedItems.map(vl => vl.ilId);
        if (startingLineIds.length == 0) return
        let result = await C3.instance.client.fetcher.fetch(`/invoice/new-draft-invoice`, {
            method: 'POST',
            body: {
                orgId: this.orgsSel.selectedId,
                counterCode: insCompanyCounter,
                startingLineIds: startingLineIds,
                insCompanyId: this.insCompaniesSel.selectedId,
                isChipcard: this.type == 'chipcard',
                periodStartDate: this.filterForm.$('startDate').value,
                periodEndDate: this.filterForm.$('endDate').value,
            },
        });
        if (result.error) {
            IATSStyleApp.instance.toaster.show({
                intent: "warning",
                icon: 'error',
                message: `No s'ha pogut crear la pre-factura`,
                action: {
                    icon: 'info-sign',
                    text: 'Més informació',
                    onClick: () => alert(`${result.message}`)
                }
            })
        }
        if (result.id) {
            await this.fetchSelectedInsCompanyInvoices()
            await nextTimeout(500)
            this.invoicesSel.selectId(result.id)
            await this.fetchInsCompanies()
            // await this.refetch()
            // await this.fetchAllInvoices()
            // this.privateInvoicesSel.selectId(result.id)
            // if (!fromSelected) {
            //     this.visitLinesSel.clearSelection()
            // }
            // await this.refetch()
            if (true) this.viewMode = "preview"
            if (this.assistantMode) this.assistantStep = 3
        }
        this.loading.creatingDraft = false

        return result

    }


    async removeSelectedLinesFromInvoice() {
        let res = await C3.instance.client.fetcher.fetch(`/invoice/${this.invoicesSel.selectedId}/remove-lines-from-invoice`, {
            method: 'POST',
            body: {
                ilIds: this.visitLinesSel.selectedItems.map(it => it.ilId)
            }
        })
        await this.refresh()
    }

    async finishAddingLinesToInvoice() {
        let res = await C3.instance.client.fetcher.fetch(`/invoice/add-lines-to-invoice`, {
            method: 'POST',
            body: {
                ilIds: this.visitLinesSel.selectedItems.map(it => it.ilId),
                invoiceId: this.assigningLinesToInvoiceItem?.id
            }
        })
        await this.refresh()
    }

    async closeInsCompanyInvoice() {
        this.loading.closingInvoice = true

        if (this.invoiceStatus != 1) {
            this.loading.closingInvoice = false
            return
        }
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.invoiceRes.id}/close`, {
            method: 'POST', body: {}
        });
        await Promise.all([this.fetchInvoice(this.invoiceRes.id), this.refresh()])
        await nextAnimationFrame()
        this.invoicesSel.selectId(result.id)
        if (this.assistantMode) this.assistantStep = 4
        this.loading.closingInvoice = false
    }

    async savePrivateInvoice(body, refetch) {
        let result = await C3.instance.client.fetcher.fetch(`/invoice/${this.invoiceRes.id}`, {
            method: 'PATCH', body: body
        });
        if (refetch) await this.fetchInvoice(this.invoiceRes.id)
    }

    async runByPatientInvoicing() {
        this.loading.creatingByPatientInvoices = true
        let insCompanyCounter = 2;
        let startingLineIds = this.visitLinesSel.selectedItems.map(vl => vl.ilId);
        if (startingLineIds.length == 0) return
        let result = await C3.instance.client.fetcher.fetch(`/invoice/by-patient-invoicing`, {
            method: 'POST',
            body: {
                orgId: this.appStore.orgsSel.selectedId,
                counterCode: insCompanyCounter,
                startingLineIds: startingLineIds,
                insCompanyId: this.insCompaniesSel.selectedId,
                isChipcard: this.type == 'chipcard',
                periodStartDate: this.filterForm.$('startDate').value,
                periodEndDate: this.filterForm.$('endDate').value,
            }
        });
        await this.fetchSelectedInsCompanyInvoices()
        await nextTimeout(500)
        this.invoicesSel.selectIds(result.ids) // CHECK
        await this.fetchInsCompanies()

        // await this.refetch()
        // await this.fetchAllInvoices()
        // this.privateInvoicesSel.selectId(result.id)
        // if (!fromSelected) {
        //     this.visitLinesSel.clearSelection()
        // }
        // await this.refetch()
        if (true) this.viewMode = "preview"
        this.loading.creatingByPatientInvoices = false
        if (this.assistantMode) this.assistantStep = 3
        return result
    }

    @observable
    isPrinting: boolean = false

    async printInvoice() {
        this.isPrinting = true
        await nextTimeout(500)
        this.printHandle()
        await nextTimeout(2000)
        //
        this.isPrinting = false
    }

    getResetDates() {
        let lastMonth = 1; // 1 (0 for testing this month)
        return [
            moment().subtract(lastMonth, 'month').startOf('month').toDate(),
            moment().subtract(lastMonth, 'month').endOf('month').toDate(),
        ]
    }

    @computed
    get arePeriodDatesDirty() {
        let resetDates = this.getResetDates();
        return [this.filterForm.$('startDate'),
            this.filterForm.$('endDate')]
            .some((df, i) => !moment(df.value).isSame(resetDates[i], 'day'))
    }

    resetPeriodDates() {
        let resetDates = this.getResetDates();
        this.filterForm.$('startDate').set(resetDates[0])
        this.filterForm.$('endDate').set(resetDates[1])
    }

    async addIVALine() {
        await this.createInvoiceLine({
            price: Number(this.invoiceRes.total) * 0.21,
            text: 'IVA 21%',
        })
    }

    @observable
    showIva = false

    async createInvoiceLine(body?) {
        if (!this.invoicesSel.selectedId) return
        await C3.instance.client.fetcher.fetch(`/invoice/${this.invoicesSel.selectedId}/new-invoice-line`, {
            method: 'POST',
            body: {
                price: 50,
                text: 'Nova línia de factura',
                ...body,
            },
        })
        await this.fetchInvoiceVisitLines(this.invoicesSel.selectedId)
        await this.refresh()
    }

    @computed
    get canAddIva() {

        let actualInvoice = this.invoicesSel.selectedItem
        return !!actualInvoice?.invoiceNumber == false && !!actualInvoice?.draftInvoiceNumber == true;
    }

    @observable
    invoiceSearchResult
    @observable
    invoiceSearchCheck = false

    async findInvoice() {
        if (this.filterForm.$('invoiceCounter').value == null || this.filterForm.$('invoiceNumber').value.trim() == null) return;
        let invoice = await C3.instance.client.fetcher.fetch(`/invoice/find-invoice`, {
            query: [
                `counterId=${this.filterForm.$('invoiceCounter').value.id ? this.filterForm.$('invoiceCounter').value.id : this.filterForm.$('invoiceCounter').value}`,
                `invoiceNumberStr=${this.filterForm.$('invoiceNumber').value.trim()}`,
                `invoiceYear=${this.filterForm.$('invoiceYear').value.trim()}`
            ].join('&'),
        })
        this.invoiceSearchResult = invoice;
        this.invoiceSearchCheck = true
        return invoice;
    }

    async assignLinesToInvoice() {
        if (this.invoiceSearchResult == null || this.invoiceSearchResult.id == null || this.visitLinesSel == null) return
        await C3.instance.client.fetcher.fetch(`/invoice/${this.invoiceSearchResult.id}/add-lines-to-invoice/`, {
            method: 'POST',
            body: {
                ilIds: this.visitLinesSel.selectedItems.map(it => it.ilId),
                invoiceId: this.invoiceSearchResult.id
            }
        })
        this.addLinesToInvoice = false
        this.refresh()
    }

    @observable.shallow
    invoiceCounterRes: any[]
    invoiceCounterSel: C3Selection

    async fetchInvoiceCounter() {
        let orgId = this.orgsSel.selectedId;
        if (orgId == null) {
            return null
        }
        let res = await C3.instance.client.fetcher.fetch(`/organization/all-organizations/${orgId}/invoice-counter`, {
            query: [`code=2`]
        });
        res = res.map(r => ({...r, name: `${r?.org?.shortName}: ${r.prefix}`}))
        this.invoiceCounterRes = res
        this.invoiceCounterSel.itemsRef.current = this.invoiceCounterRes;
        return this.invoiceCounterRes
    }

    @computed
    get canAddLines(): boolean {
        if (this.invoiceSearchResult == null) return false
        return this.visitLinesSel.selectedIds.length > 0 && !!this.invoiceSearchResult.id
    }

    resetPopOver() {
        this.addLinesToInvoice = false;
        this.invoiceSearchCheck = false;
        this.filterForm.$('invoiceYear').set(moment(new Date).format('YY'));
        this.filterForm.$('invoiceNumber').set('')
    }

    @computed
    get dateLocked() {
        if (this.invoiceStatus == 2) {
            return !this.dateEditorLocked
        }
        return false
    }
}
