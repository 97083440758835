import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3Form} from "react-c4";
import {Tag} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    font-size: 17px;
    
`
const TopColumnsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, 6, auto);
    grid-auto-flow: row;
`
const AttributeContainer = styled.div`
    margin-top: 4px;
`
const LargeContainer = styled.div`
    height: 75px;
    margin-top: 4px;

`

export interface FitxaBlancaViewProps {
    form: C3Form
}

export const FitxaBlancaView = observer((props: FitxaBlancaViewProps) => {
    return <Container>
        <TopColumnsContainer>
            <AttributeContainer>
                DATA PROVA: 04/05/2020
            </AttributeContainer>

            <AttributeContainer>
                MAQUINA: T-CBTC-1
            </AttributeContainer>
            <AttributeContainer>
                PROVA: TAC Abdominal
            </AttributeContainer>
            <AttributeContainer>
                CENTRE: H. Mataró
            </AttributeContainer>
            <AttributeContainer>
                TÈCNINC: José Pérez López
            </AttributeContainer>
            <AttributeContainer>
                Num Plaques: 4
            </AttributeContainer>
            <AttributeContainer>
                Edat a la prova: 53 anys
            </AttributeContainer>
            <AttributeContainer>Pes:</AttributeContainer>
            <AttributeContainer>
                Estat de la prova: <Tag minimal intent={'success'} style={{transform: 'scale(1.06)'}}>Informada</Tag>
            </AttributeContainer>


        </TopColumnsContainer>
        <LargeContainer>Protocol:</LargeContainer>
        <LargeContainer>Comentaris Cita:</LargeContainer>
        <LargeContainer>Comentaris Administratius:</LargeContainer>
        <LargeContainer>Comentaris Tècnic:</LargeContainer>

        <LargeContainer>Antecedents / Al·lèrgies:</LargeContainer>
        <AttributeContainer>Motiu de la visita:</AttributeContainer>
        <AttributeContainer>Data entregat: 13:27h (veure comprovant signat entrega)</AttributeContainer>
        <AttributeContainer>Comantaris Entrega: Entregats tots els CD-ROM de forma correcta</AttributeContainer>
        <LargeContainer>
            Notes per facturació: ...
        </LargeContainer>
    </Container>
})
