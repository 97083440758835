import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {TrayClock} from "./components/TrayClock";
import {Icon} from "@blueprintjs/core";
import {TrayPrinter} from "./components/TrayPrinter";
import {TrayUser} from "./components/TrayUser";
import {TrayConnection} from "./components/TrayConnection";
import {TrayTablet} from "./components/TrayTablet";
import {dec, isDev, useAppStore} from "../../utils/utils";
import {TraySupport} from "./components/TraySupport";
import {TrayAlerts} from "./components/TrayAlerts/TrayAlerts";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    background-color: #ced9e0;
    grid-gap: 8px;
    align-items: center;
    color: #34424e;
    padding-right: 8px;
`
const CommitContainer = styled.div`
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    color: #137cbd;
    font-size: 11px;
    cursor: default;
    user-select: all;
    
`

export interface SystemTrayComponentProps {

}

export const SystemTrayComponent = observer((props: SystemTrayComponentProps) => {
    // @ts-ignore
    let appStore = useAppStore()
    let commithash = process.env.COMMIT_HASH;
    // TODO Add report issue / reportar incidència w/ screenshot
    return <Container>
        {/*<div>*/}
        {/*    {`HIS-M-One`}*/}
        {/*</div>*/}
        <CommitContainer title={'Rev. ' + commithash}>{commithash}</CommitContainer>
        {isDev() && <TrayTablet/>}
        {dec().showTrayAlerts() && <TrayAlerts/>}
        <TraySupport/>
        <TrayConnection/>
        {isDev() && <TrayPrinter name={'I1'}/>}
        {isDev() && <TrayPrinter name={'I2'}/>}
        {isDev() && <TrayPrinter name={'I3'}/>}
        <TrayUser/>
        <TrayClock/>
    </Container>
})
