import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout, Transfer} from "react-c4";
import {CellValueChangedEvent} from "ag-grid-community";
import {useExtCenterFVStore} from "../ExtCenterFileView";
import {isDev, stdBoolColumn} from "../../../application/utils/utils";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`
const GridTitle = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
  
`
const GridTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`

export interface ECReferrersFVProps {

}

export const ECReferrersFV = observer((props: ECReferrersFVProps) => {
    let store = useExtCenterFVStore()
    return <Container>
        <GridTitleContainer>
            <GridTitle>Metges assignats al Centre {store.extCenterSel?.selectedItem?.name}:</GridTitle>
            <GridTitle>Total de Metges a la Base de Dades:</GridTitle>
        </GridTitleContainer>
            <Transfer
                columnDefs={[
                    {
                        field: '',
                        headerName: '',
                        width: 30,
                        checkboxSelection: true,
                        // suppressFilter: true,
                    },
                    {
                        field: 'name',
                        headerName: 'Nom',
                        filter: 'agTextColumnFilter',
                        editable: false

                    },
                    {
                        field: 'surname1',
                        headerName: 'Cognom1',
                        filter: 'agTextColumnFilter',
                        editable: false
                    },
                    {
                        field: 'surname2',
                        headerName: 'Cognom2',
                        filter: 'agTextColumnFilter',
                        editable: false
                    },
                    {
                        field: 'treatment' ,
                        headerName: 'Tr',
                        filter: 'agTextColumnFilter',
                        width: 40,
                        editable: false
                    },
                    {
                        field: 'code',
                        headerName: 'Codi',
                        editable: false,
                        width: 75,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'isActive',
                        headerName: 'Actiu',
                        ...stdBoolColumn(()=>false),
                        width: 55
                    },
                ]}
                {...store.transfer.bind()}
            />
    </Container>
})
