import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Icon, InputGroup, TextArea, Button, Checkbox, RadioGroup, Radio} from "@blueprintjs/core";
import {MRFField} from "react-c4/src/forms/MRF";
import {useRSCStore} from "../../../../../../RSCMain";

const Container = styled.div`
    display: grid;
    background-color: #C6E1ED;
    padding: 2px 4px;
    padding-bottom: 3px;
    grid-gap: 2px;
    border-radius: 8px;
    border: 1px solid #707070;
    min-width: 200px;
`
const HeaderContainer = styled.div`
    font-size: 11px;
    display: grid;
    grid-template-columns: 1fr auto;
`
const CloseContainer = styled.div`
    display: grid;
    place-items: center;
    background-color: #FCFDFD;
    height: 16px;
    width: 16px;
    border-radius: 100%;
`
const MedicalContainer = styled.div`
    display: grid;
`
const ButtonContainer = styled.div`
  display: grid;
`

export interface RSCNewItemInputBoxProps {
    entityTitle
    associatingToText
    field: MRFField,
    fieldName
    inputRef
}

export const RSCNewItemInputBox = observer((props: RSCNewItemInputBoxProps) => {
    let field = props.field
    let qField = field.$('q')
    let surnameOneField = field.$('surname1');
    let surnameSecondField = field.$('surname2');
    let treatmentField = field.$('treatment');
    let notesField = field.$('notes')
    let creatingField = field.$('creating')
    let store = useRSCStore()
    return <Container>
        <HeaderContainer>
            <span>Nou {props.entityTitle} {props.associatingToText && props.associatingToText}</span>
            <CloseContainer
                onClick={() => creatingField.onChange(false)}
            >
                <Icon icon={'small-cross'}/>
            </CloseContainer>
        </HeaderContainer>
        <InputGroup small {...{...qField.bind(), inputRef: props.inputRef}} placeholder={'Nom del ' + props.entityTitle}/>
        {props.fieldName === 'medical' && <MedicalContainer> <InputGroup small {...{...surnameOneField.bind(), inputRef: props.inputRef}}
                     placeholder={'Cognom del ' + props.entityTitle}/>
         <InputGroup small {...{...surnameSecondField.bind(), inputRef: props.inputRef}}
                                                      placeholder={'Segon cognom del ' + props.entityTitle}/>

             <RadioGroup {...{...treatmentField.bind(), inputRef: props.inputRef}} onChange={(event)=>{
                 treatmentField.value = event.currentTarget.value;
             }}
                         selectedValue={treatmentField.value}
                         inline={true}
             >

                 <Radio value={'Dr'} label={'Dr'}/>
                 <Radio value={'Dra'} label={'Dra'}/>

             </RadioGroup>
        </MedicalContainer> }
        <InputGroup small {...{...notesField.bind(), inputRef: props.inputRef}} placeholder={'Comentari'}/>


        {/*{ props.fieldName === 'medical'?
            <ButtonContainer>
                {store.isCreatingAssociation ?  <Button onClick={() => {
                    store.createLinkSubmit(props.fieldName)
                }}>Crear, vincular i assignar</Button> :
                 <ButtonContainer>
                     <Button onClick={() => {
                         store.saveNewRef(props.fieldName)
                     }}>Crear referent amb centre</Button>
                     <Button onClick={() => {
                         store.createWithoutCenter()
                     }}>Crear referent sense centre</Button>

                 </ButtonContainer>
                }
            </ButtonContainer> :
            <ButtonContainer>
                {store.isCreatingAssociation ?
                    <Button onClick={() => {
                    store.createLinkSubmit(props.fieldName)
                }}>Crear, vincular i assignar</Button> :
                    <Button onClick={() => {
                        store.saveNewRef(props.fieldName)
                    }}>Crear</Button>
                }
            </ButtonContainer>

        }*/}
        <Button onClick={() => {
            store.saveNewRef(props.fieldName)
        }}>Crear</Button>
        <Button onClick={() => {
            store.createSubmit(props.fieldName)
        }}>Crear i assignar</Button>

    </Container>
})
