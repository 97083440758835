import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import moment from "moment";
import {useSVVStore} from "../../../StandardVisitView";
import * as _ from 'lodash'
const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(auto-fit, 1fr);
    //background-color: #e5e8ea;
    grid-gap: 5px;
    overflow: hidden;
   
   
`

const StageContainer = styled.div`
    display: grid;
    place-items: center;
    
    
    background: #ddd;
    height: 50px;
    text-align: center;
    padding: 4px;
    //border-left: 1px solid ${p => p.color};
    position: relative;
    //margin-left: 5px;
    // border-radius: 2px;
        place-content: center;
        cursor: pointer;

  
`
const StageTitleContainer = styled.div`
    
`
const StageTimeContainer = styled.div`
    
`

export interface SVHProcessStagesProps {

}

export const SVHProcessStages = observer((props: SVHProcessStagesProps) => {
    let store = useSVVStore()
    let visit = store.visitsSel.selectedItem
    if (visit == null) return null;

    let stages = [
        {
            name: visit.isDraft ? 'Cita Temporal' : !visit.isVisit ? 'Cita' : 'Visita',
            color: visit.isDraft ? '#d9a08f' : !visit.isVisit ? '#8fd9a2' : '#8fc2d9',
            date: 'confirmationDate'
        },
        // WR
        //     name: 'Arribat',
        //     color: '#ccc',
        //     date: 'arrivalDate'
        // },
        {
            name: 'E. Recep',
            color: '#ccc',
            date: 'enterReceptionDate'
        },
        {
            name: 'En sala d\'espera',
            color: '#ccc',
            date: 'enterWaitingRoomDate',
        }, {
            name: 'Cridat',
            color: '#e5d0a3',
            date: 'summonDate',
        }, {
            name: 'Dins visita',
            color: '#e5d0a3',
            date: 'enterRoomDate',
        }, {
            name: 'Fora visita',
            color: '#e5d0a3',
            date: 'exitRoomDate',
        }, {
           // name: 'S. Recepció',
            color: '#ccc',
            date: 'exitReceptionDate',
            unable: true
        }, {
            //name: 'Sortida centre',
            color: '#ccc',
            date: 'exitDate',
            unable: true
        },

    ]
    let index = null
    stages.forEach((st, i) => {
        let date = visit[st.date];
        // console.log(`date`, JSON.stringify(date));
        if(moment(date).isValid()) index = i;
    })
    // if(index == null) index = stages.length-1;


    return <Container>
        {stages.map((st, i) => {
            let selected = i == index;
            let backgroundColor = i < index ? 'lightblue' : (selected ? st.color : st.color);
            let date = visit[st.date];
            return <StageContainer
                key={i} color={backgroundColor} style={{backgroundColor: backgroundColor}}
                onClick={() => {
                    if (st.unable == true) return
                    store.post(`/visits/${visit.id}/process`, {
                        check: !date,
                        stageName: st.date
                    }).then(() => store.fetchPatientVtfStats())
                    visit[st.date] = new Date()
                    // store.saveVisit({id: visit.id, [st.date]: new date()})
                }}
            >
                <StageTitleContainer
                    style={{fontWeight: selected ? 'bold' : ''}}

                >
                    {st.name}
                </StageTitleContainer>
                {date && <StageTimeContainer>
                    {moment(date).format('DD/MM HH:mm')}
                </StageTimeContainer>}
            </StageContainer>
        })}
    </Container>
})
