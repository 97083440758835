import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const productSchema: C3SchemaInfo = {
    'product': {
        fields: {
            id: {},
            name: {
                label: 'Nom'
            },
            shortName: {
                label: 'Nom curt'
            },
            isTest:{
                label: 'És una prova'
            },
            duration:{
                label: 'Duració'
            },
            isProduct:{
                label: 'És un producte'
            },
            isReport:{
                label: 'És un informe'
            },
            defDeliveryHours: {
                label: 'H. Entrega per defecte'
            },
            defNumPlates: {
                label: 'N. Plaques per defecte'
            },
            businessLine: {
                type: 'business-line',
                label: 'Línia de negoci per defecte'
            },
            productBodyPart: {
                type: 'body-part',
                label: 'Part del cos'
            },
            orgProducts: {
                type: 'org-product'
            },
            deliveryItemTypes: {
                type: 'delivery-item-type',
                multivalued: true,
                label: 'Items d\'entrega'
            },
            rates: {
                type: 'rate',
                multivalued: true,
                label: 'Tarifes'
            }
        }
    }
}
