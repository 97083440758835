import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSVVStore} from "../../../StandardVisitView";
import {SVVPrivateInvoicingPanelLinesGrid} from "./SVVPrivateInvoicingPanelLinesGrid";
import {Button, ButtonGroup, InputGroup, NonIdealState, TextArea} from "@blueprintjs/core";
import {DateInput} from "@blueprintjs/datetime";
import {Field, Form, SelectControl} from "react-c4";
import {SVVPrivateInvoicingPanelInvoicesGrid} from "./SVVPrivateInvoicingPanelInvoicesGrid";
import {SVVPIPPayment} from "./SVVPIPPayment/SVVPIPPayment";
import {SVVPIPInvoiceEditor} from "./SVVPIPInvoiceEditor/SVVPIPInvoiceEditor";

const InvoiceMDContainer = styled.div`
    display: grid;
    grid-template-columns: minmax(265px, 2fr) 5fr;
    grid-gap: 8px;
    height: 380px;
`
const Container = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-gap: 4px;
    align-content: start;
    //padding: 8px;
`


export interface SVVPrivateInvoicingPanelProps {

}

export const SVVPrivateInvoicingPanel = observer((props: SVVPrivateInvoicingPanelProps) => {
    let store = useSVVStore()
    let selected = store.privateInvoicesSel.selectedItem;
    let invoiceForm = store.privateInvoiceForm;

    return <InvoiceMDContainer style={{gridTemplateColumns: !store.isPrivateInvoicingModeMasterVisible ? '1fr' : null}}>
        {store.isPrivateInvoicingModeMasterVisible && <SVVPrivateInvoicingPanelInvoicesGrid/>}
        {!selected && <NonIdealState icon={'document'} title={'Selecciona una factura'}/>}
        {selected && <Container>

            {/*<SVVPrivateInvoicingPanelLinesGrid/>*/}
            {selected && <SVVPIPInvoiceEditor/>}
            {selected?.invoiceNumber && <SVVPIPPayment/>}


            {/*<div>*/}
            {/*    <LeftSectionContainer>*/}
            {/*        <label>Mostra a factura</label>*/}
            {/*        <ButtonGroup>*/}
            {/*            <Button>Noms de proves</Button>*/}
            {/*            <Button>Noms de barems</Button>*/}
            {/*        </ButtonGroup>*/}
            {/*    </LeftSectionContainer>*/}
            {/*    <PaymentSectionContainer>*/}
            {/*        <SectionTitleContainer>Pagament</SectionTitleContainer>*/}
            {/*        <div>Total: <span>333,45 €</span></div>*/}
            {/*        <div>*/}
            {/*            <label>Data pagat</label>*/}
            {/*            <DateInput/>*/}
            {/*        </div>*/}
            {/*        <ButtonGroup>*/}
            {/*            <Button icon={'manual'}>Efectiu</Button>*/}
            {/*            <Button icon={'credit-card'}>Targeta</Button>*/}
            {/*            <Button icon={'bank-account'}>Transferència</Button>*/}
            {/*        </ButtonGroup>*/}
            {/*        /!*<Field field={store.privateInvoiceForm.$('name')} c={SelectControl} options={[]}/>*!/*/}
            {/*        <LeftSectionContainer>*/}
            {/*            <label>Comentari pagament</label>*/}
            {/*            <TextArea/>*/}
            {/*        </LeftSectionContainer>*/}
            {/*    </PaymentSectionContainer>*/}
            {/*</div>*/}

        </Container>}
    </InvoiceMDContainer>
})
