export let sexOptions = [
    {id: 'M', name: 'Hombre'},
    {id: 'F', name: 'Mujer'},
    {id: 'O', name: 'Otros'},
]

export let sexShortStr = {
    M: 'H',
    F: 'D',
    O: 'A',
}
