import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, InputGroup, Popover} from "@blueprintjs/core";
import {ADVStore} from "../../components/AllDeltasView/ADVStore";

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;

`

const PopoverContainer = styled.div`
  display: grid;
  padding: 8px;
  grid-gap: 8px;
`

export interface BasicStatisticsPageHeaderProps {
    store: ADVStore
}

export const AllDeltasPageHeader = observer((props: BasicStatisticsPageHeaderProps) => {
    let store = props?.store
    if (store == null) return null
    return <Container>
        Total deltas: {store?.deltasSel?.itemsRef.current.length}
        <Button active={store.mode == 'import/export'} onClick={() => {
            store?.mode == 'deltas' ? store.mode = 'import/export' : store.mode = 'deltas'
        }}>{store?.mode}</Button>
    </Container>
})
