import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3, TabsPage, useC3Store} from "react-c4";
import {NonIdealState, Tag} from "@blueprintjs/core";
import {ProfileGeneralTab} from "./tabs/ProfileGeneralTab";
import {ProfilePermissionsTab} from "./tabs/ProfilePermissionsTab";
import {page} from "react-navigation-plane";
import {ProfilePageStore} from "./ProfilePageStore";
import {SVVStore} from "../../components/StandardVisitView/SVVStore";
import * as _ from 'lodash'

const Container = styled.div`
    display: grid;
    
`

export interface ProfilePageProps {

}

export const ProfilePageContext = React.createContext(null as ProfilePageStore)
export const useProfileStore = () => useContext(ProfilePageContext)

export const ProfilePage = page(observer((props: ProfilePageProps) => {
    let store = useC3Store(() => {
        let storeInst = new ProfilePageStore()
        storeInst.init()
        return storeInst;
    })
    if (store == null) return null
    let auth = C3.instance.auth
    let token = ''//auth.keycloak.tokenParsed;
    let fullName = ''//[token?.given_name, token?.familyName].filter(s => s != null).join(' ')
    let email = ''//[token?.email].filter(s => s != null).join(' ')
    if(!store.appStore.auth.isLoggedIn) return <NonIdealState icon={'person'} title={'Inicia sessió per accedir al perfil'}/>
    return <ProfilePageContext.Provider value={store}>
        <TabsPage

            title={[store.form.$('name').value, store.form.$('surname1').value, store.form.$('surname2').value].join(' ')}
            subtitle={"Perfil de l'usuari"}
            renderTagsArea={() => <Tag minimal large>Tècnic</Tag>}
            attributes={[
                {name: 'Nom complet', value: fullName},
                {name: 'Email', value: email},
                {name: 'Rol complet', value: store.appStore.auth?.loggedInUser?.roles && _.entries(store.appStore.auth?.loggedInUser.roles).map(([k, v]) => v.displayName).join(', ')},
            ]}
            tabs={[
                {
                    name: 'general',
                    displayName: 'Informació de l\'usuari',
                    render: () => <ProfileGeneralTab/>
                },
                // {
                //     name: 'settings',
                //     displayName: 'Preferències',
                //     render: () => <ProfilePermissionsTab/>
                // },{
                //     name: 'myVisits',
                //     displayName: 'Les meves proves',
                //     render: () => <ProfilePermissionsTab/>
                // }, {
                //     name: 'permissions',
                //     displayName: 'Permisos',
                //     render: () => <ProfilePermissionsTab/>
                // }, {
                //     name: 'notifications',
                //     displayName: 'Notificacions',
                //     render: () => <ProfilePermissionsTab/>
                // }
            ]}
        />
    </ProfilePageContext.Provider>
}))
