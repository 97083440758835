import React, {useState, useEffect, useContext, useImperativeHandle, forwardRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'

const Container = styled.div`
  display: grid;

`

export interface ProductNameCRProps {

}

export const ProductNameCR = observer(forwardRef((props: ProductNameCRProps, ref) => {
    useImperativeHandle(ref, () => {
        return {
            refresh: (params) => {
                // if (params.value !== value) {
                //     setValue(params.value)
                // }
                return true;
            }
        };
    });
    return <Container>
        aaa
    </Container>
}))
