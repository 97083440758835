import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const productRatesRelViewSchema: C3SchemaInfo = {
    'product-rates-rel-view': {
        local: true,
        fields: {
            id: {},
            selcontrolDetailRatesCMP: {
                type: 'rate-sheet',
                subfields: true
            }
        }
    }
}
