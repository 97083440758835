import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'

const Container = styled.div`
    display: grid;
    
`

export interface InsCompanyFVMainProps {
    pageContext
}

export const InsCompanyFVMain = observer((props: InsCompanyFVMainProps) => {
    return <Container>

    </Container>
})
