import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";

const Container = styled.div`
    display: grid;
    
`

export interface IPPreviewDocumentProps {

}

export const IPPreviewDocument = observer((props: IPPreviewDocumentProps) => {
    return <Container>

    </Container>
})
