import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useMEVStore} from "../MeetupEventsView";
import {MEVMasterGrid} from "./components/MEVMasterGrid";
import Splitter from "m-react-splitters";
import {MEVDetailGrid} from "./components/MEVDetailGrid";

const Container = styled.div`
  display: grid;
  height: 100%;

`

export interface BSVMainProps {
    pageContext
}

export const MEVMain = observer((props: BSVMainProps) => {
    let store = useMEVStore()
    return <Container>
        <Splitter
            position="vertical"
            primaryPaneMinWidth={0}
            primaryPaneWidth={'400px'}
            postPoned={false}>
            <MEVMasterGrid/>
            <MEVDetailGrid/>
        </Splitter>

    </Container>
})
