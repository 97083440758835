import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle} from "react";
import styled, {css} from 'styled-components'
import {observer} from 'mobx-react'
import {ICellRendererParams} from "ag-grid-community";
import {ICellRendererReactComp} from "ag-grid-react";
import {useSVVStore} from "../../../../StandardVisitView";
import moment from "moment";
import * as _ from 'lodash'
import {Icon, Tooltip} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    
`

export interface SVVLinesGridVisitCRProps extends ICellRendererParams {

}

export const SVVLinesGridDeliveryCR = observer(forwardRef((props: SVVLinesGridVisitCRProps, ref) => {
    useImperativeHandle(ref, () => {
        return {
            refresh: (params) => {
                // if (params.value !== value) {
                //     setValue(params.value)
                // }
                return true;
            }
        };
    });

    let store = useSVVStore()

    let attachDocumentsMode = store.isAttachDocumentsMode;

    let visitId = props.data.visit?.id;
    // let visit = store.getLocalVisit(visitId)
    // let visitField = store.visitsSel.getFieldById(visitId);

    let colors = {
        'complete': '#3dc10a',
        'partial': '#cb3409',
    }
    let deliveryInfo = props.data.deliveryInfo;
    let color = colors[deliveryInfo.status]
    return <Container
        style={{color: color, fontWeight: 'bold'}}

    >
        {props.valueFormatted}
    </Container>
}))

