import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSVVStore} from "../../../StandardVisitView";
import {MRFField} from "react-c4/src/forms/MRF";
import {bindWithAS, useAS} from "../../../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    grid-gap: 4px;
    //padding: 0 8px 0;
    height: 100px;
   
`

export interface SVHBodyFieldsProps {

}

export const SVHBodyFields = observer((props: SVHBodyFieldsProps) => {
    let store = useSVVStore()
    // let debouncer = useDebouncer(() => {
    //     let fieldName = 'administrativeComments';
    //
    //     console.log(`hit `, fieldName);
    //     store.saveVisit({id: visitField.$('id').value, [fieldName]: visitField.$(fieldName).value})
    //     console.log(`saved `, fieldName);
    // })
    let visitField = store.visitsSel.selField;
    let as = useAS((field, e) => {
        visitField = store.visitsSel.selField;
        let fieldName = field.name;
        store.saveVisit({id: visitField.$('id').value, [fieldName]: visitField.$(fieldName).value}, false)
    })
    if (!visitField) return <div/>
    let $resource = visitField?.$('resource');
    let disabled = $resource.value?.id == null || !store.isMainMode


    return <Container>
        <textarea {...bindWithAS(visitField.$('administrativeComments'), as)} placeholder={'Comentari cita'}
                  disabled={disabled}/>

        <textarea {...bindWithAS(visitField.$('referrerComments'), as)} placeholder={'Comentari fitxa'}
                  disabled={disabled}/>

        <textarea {...bindWithAS(visitField.$('technicianComments'), as)} placeholder={'Comentari tècnic'}
                  disabled={disabled}/>
        {/*<textarea {...bindWithAS(visitField.$('deliveryComments'), as)} placeholder={'Comentari entrega'}*/}
        {/*          disabled={disabled}/>*/}
    </Container>
})
