import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Button, Icon} from "@blueprintjs/core";
import {useMPEStore} from "../../MeetupPushEventsView";
import * as moment from "moment";

const Container = styled.div`
  display: grid;
  padding: 8px;
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  justify-content: end;
  grid-template-columns: auto auto;
`
const FieldsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`
const SingleFieldContainer = styled.div`
  display: grid;

`
const FieldLabelContainer = styled.div`
  display: grid;
  font-weight: bold;
`
const FieldDeltaContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 8px;
  justify-items: center;
  justify-content: start;
  align-items: center;
`

export interface FieldDeltasProps {

}

export const FieldDeltas = observer((props: FieldDeltasProps) => {
    let store = useMPEStore()
    let meetupEvent = store.meetupEventsSel.selectedItem
    if (!meetupEvent?.id) return null
    let status = meetupEvent.status



    return <Container>
        <ButtonsContainer>
            <Button onClick={() => {
                window.open(`https://www.meetup.com/${meetupEvent.urlName}/events/${meetupEvent.meetupId}/`, '_blank')
            }} icon={'share'}>View</Button>
            <Button onClick={() => {
                window.open(`https://www.meetup.com/${meetupEvent.urlName}/events/${meetupEvent.meetupId}/edit`, '_blank')
            }} icon={'share'}>Edit</Button>
        </ButtonsContainer>
        <FieldsContainer>
            {store.getFieldsWithDelta(meetupEvent).map((f, i) => {
                return <SingleFieldContainer key={i}>
                    <FieldLabelContainer>{f}</FieldLabelContainer>
                    <FieldDeltaContainer>
                        <div>{status[store.getStatusField(f)]}</div>
                        <Icon icon={'arrow-right'}/>
                        <div>{meetupEvent[f]}</div>
                        <Button minimal onClick={() => {
                            navigator.clipboard.writeText(meetupEvent[f])
                        }} icon={'clipboard'}>Copy</Button>
                    </FieldDeltaContainer>
                </SingleFieldContainer>
            })}
        </FieldsContainer>

    </Container>
})