import React, {useState, useEffect, useContext, useImperativeHandle, forwardRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useICIStore} from "../InsCompanyInvoicingView";
import {CellClickedEvent, ICellRendererParams, RowNode} from "ag-grid-community";
import numeral from 'numeral'
import {Tooltip} from "@blueprintjs/core";
import {formatFullName} from "../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    height: 100%;
`
const DotContainer = styled.div`
    position: absolute;
    right: -2px;
    top: -1px;
    //display: inline-block;
    background-color: #db3e3e;
    border-radius: 100px;
    //border: 1px solid green;
    height: 15px;
    width: 15px;
    min-width: 0;
    min-height: 0;
    line-height: 0px;
    white-space: normal;
    direction: unset;
    font-size: 0px;
    transform: scale(0.33);
`

export interface ICIInsCompaniesGridProps {

}

export const ICIInsCompaniesGrid = observer((props: ICIInsCompaniesGridProps) => {
    let store = useICIStore();
    let sumCellW = 80;

    let getTotalCellTooltipFromValue = (value) => {
        let dr = value?.drafts;
        let draftsArr = dr?.drafts || [];
        let draftsStr = draftsArr?.map(s => `*${s}`);
        return `${dr?.count} línies (${numeral(dr?.sum).format('0')} €) en factures temporals: ${draftsStr?.join(', ')}`;
    }
    let cellRenderer = observer(forwardRef((params: ICellRendererParams, ref) => {
        useImperativeHandle(ref, () => {
            return {
                // refresh: () => {
                //
                // }
            }
        })
        let value = params.value;
        let count = value?.count;
        let sum = value?.sum;
        let dr = value?.drafts;
        let drCount = dr?.count;
        // if (!count) return <div/>
        let fw: any = 'normal';
        if (params.colDef.cellRendererParams?.primary) {
            fw = 'bold'
        }

        let title: string;
        if (drCount) title = getTotalCellTooltipFromValue(value)
        return <div

            onClick={() => {

            }}
            style={{display: 'grid', gridTemplateColumns: '1fr auto', gridGap: 4, width: sumCellW - 20}}>
            <span style={{color: '#0F9960', fontWeight: fw}}>{sum > 0 && numeral(sum).format('0')}</span>
            <span style={{color: '#D9822B', fontWeight: fw}}>{count > 0 && `(${count})`}</span>
            {/*{drCount && <DotContainer/>}*/}
        </div>
    }))
    let sumCmp = (valueA, valueB, nodeA, nodeB, isInverted) => {
        return valueB.sum - valueA.sum
    };
    return <Container>
        <MasterLayout
            body={<C3AgGrid
                selection={store.insCompaniesSel}
                defaultColumnDef={{resizable: true, sortable: true, suppressMenu: true}}
                frameworkComponents={{sumCr: cellRenderer}}
                columnDefs={[
                    {
                        field: 'name',
                        headerName: 'Mútua',
                        width: 130,
                        pinned: 'left',
                        cellStyle: (params) => {
                            let inactive = params.data?.total?.count <= 0 && !params.data?.chipcard?.drafts?.count && !params.data?.manual?.drafts?.count;
                            return {
                                'font-weight': 'bold',
                                ...(inactive && {
                                    // textDecoration: 'line-through',
                                    color: 'gray',
                                    'font-weight': 'normal'
                                }),
                            }

                        },
                        filter: 'agTextColumnFilter',

                    }, {
                        field: 'chipcard',
                        headerName: 'Chipcard (€)',
                        headerTooltip: 'Import chipcard no facturat (sense filtre per dates)',
                        width: sumCellW,
                        comparator: sumCmp,
                        cellRenderer: 'sumCr',
                        tooltipComponentParams: {
                            tooltipShowDelay: 0
                        },
                        tooltipValueGetter({value}) {
                            if(!value.drafts?.count) return null
                            return getTotalCellTooltipFromValue(value);
                        }
                    }, {
                        field: 'manual',
                        headerName: 'Manual (€)',
                        headerTooltip: 'Import manual no facturat (sense filtre per dates)',
                        width: sumCellW,
                        comparator: sumCmp,
                        cellRenderer: 'sumCr',
                    }, {
                        field: 'unassigned',
                        headerName: 'No assignat (€)',
                        headerTooltip: 'Import total no facturat (sense filtre per dates)',
                        width: sumCellW,
                        comparator: sumCmp,
                        cellRenderer: 'sumCr',
                    }, {
                        field: 'total',
                        headerName: 'Total (€)',
                        headerTooltip: 'Total (€) (sense filtre per dates)',
                        width: sumCellW,
                        cellRenderer: 'sumCr',
                        comparator: sumCmp,
                        cellRendererParams: {
                            primary: true
                        },
                        pinned: 'left'

                    },
                ]}
                processCellCallback={(params) => {
                    let data = (params.node as RowNode).data
                    return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                }}
                getContextMenuItems={(params) => {
                    // let fileName = store.fr
                    var result = [
                        {
                            // custom item
                            name: 'Exporta a Excel',

                            action: function () {
                                params.api.exportDataAsExcel({
                                    fileName: `Llistat-total-mutues`,
                                    processCellCallback: (params) => {
                                        let data = (params.node as RowNode).data
                                        if (params.column.getColId() == 'chipcard') {
                                            if (data.chipcard.count == null) return ''
                                            return `${data.chipcard.sum} (${data.chipcard.count})`
                                        }
                                        if (params.column.getColId() == 'manual') {
                                            if (data.manual.count == null) return ''
                                            return `${data.manual.sum} (${data.manual.count})`
                                        }
                                        if (params.column.getColId() == 'unassigned') {
                                            if (data.unassigned.count == null) return ''
                                            return `${data.unassigned.sum} (${data.unassigned.count})`
                                        }
                                        if (params.column.getColId() == 'total') {
                                            if (data.total.count == null) return ''
                                            return `${data.total.sum} (${data.total.count})`
                                        }
                                        return params.value
                                    }
                                })
                            },
                        }
                    ]
                    return result
                }}
                onCellClicked={(e: CellClickedEvent) => {
                    if(store.assistantMode) return;
                    let id = e.column.getColDef().field;
                    if (id == 'name') return
                    if (id == 'total') id = 'all'
                    store.typeSel.selectId(id)
                    setTimeout(() => {
                        e.api.ensureColumnVisible(e.column)
                    })
                }}
                rowSelection={'single'}
            />}

            renderHeader={() => {
                return null
            }}
        />
    </Container>
})
