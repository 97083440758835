import {IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";

export class MPPStore {
    appStore: AppStore
    pageContext

    patientForm: MRF

    async init() {
        this.appStore = IATSStyleApp.instance.appStore

    }
}