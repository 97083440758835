import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import { C3 } from "react-c4";
import {useProfileStore} from "../ProfilePage";
import {bindWithAS, isDev, useAS} from "../../../application/utils/utils";
import {UserProfileEditor} from "../components/UserProfileEditor";
import {ResourceSelector} from "../../../application/components/shared/ResourceSelector";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    padding: 8px;
    grid-auto-flow: row;
    grid-gap: 16px;
    align-content: start;
`

export interface ProfileGeneralTabProps {

}

export const ProfileGeneralTab = observer((props: ProfileGeneralTabProps) => {
    let store = useProfileStore()
    let auth = C3.instance.auth
    let token = auth.keycloak.tokenParsed;
    // @ts-ignore
    let fullName = [token?.given_name, token?.familyName].filter(s => s != null).join(' ')
    // @ts-ignore
    let email = [token?.email].filter(s => s != null).join(' ')
    return <Container>
        {/*TODO Afegir imatge de perfil*/}
        {/*TODO Afegir visualització resum (botó editar)*/}
        {/* Fer pàgina per seccions*/}
        <UserProfileEditor/>
        {<ResourceSelector/>}
        <Button>Restaurar agendes per defecte</Button>
    </Container>
})
