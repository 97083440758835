import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {usePFVStore} from "../../PatientFusionView";
import {C3AgGrid, MasterLayout} from "react-c4";
import moment from "moment";
import {CellValueChangedEvent, IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import * as _ from "lodash";
import {formatFullName, stdIntNumericColumn} from "../../../../application/utils/utils";
import {SelectCE} from "../../../../application/utils/components/SelectCE";
import {Button, Popover, Icon} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  padding: 8px;
  place-content: center;
  grid-auto-flow: row;
  grid-gap: 8px;
  justify-items: center;
`
const ButtonContainer = styled.div`
  display: grid;
  padding: 8px;
  place-content: center;
  grid-auto-flow: column;
  grid-gap: 8px;
`
const PopoverContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding: 16px;
  grid-gap: 16px;

`

const PopoverButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-auto-flow: column;
`

export interface PFVPatientFormProps {

}

export const PFVPatientForm = observer((props: PFVPatientFormProps) => {
    let store = usePFVStore()
    return <Container>
        <ButtonContainer>
            <Popover
                isOpen={store.confirmMergeWithListOpen}
                content={<PopoverContainer>
                    <div>
                        <div>
                            Fusionar {store.patientSel.selectedIds.length} pacients
                            ({_.sum(store.patientSel.selectedItems.map(r => r.visitCount))} visites)
                            amb {formatFullName(store.finalPatientSel.selectedItem)}
                        </div>
                        <div>
                            <Icon icon={'warning-sign'}/>Alerta: Aquesta acció és irreversible
                        </div>
                    </div>
                    <PopoverButtonsContainer>
                        <Button icon={'cross'} onClick={() => {
                            store.confirmMergeWithListOpen = false
                        }}>Cancela</Button>
                        <Button icon={'git-merge'} intent={'warning'} onClick={async () => {
                            console.log("Fusiona pacients I DEIXA A LA LLISTA" )
                            await store.mergeSelectedPatients( true)
                            store.confirmMergeWithListOpen = false

                        }}>Fusiona pacients</Button>
                    </PopoverButtonsContainer>
                </PopoverContainer>}
            >
                <Button
                    icon={'git-merge'}
                    intent={'none'}
                    disabled={!store.canMergePatient}
                    onClick={() => {
                        store.confirmMergeWithListOpen = true
                    }}
                >
                    Fusionar i deixar a la llista
                </Button>
            </Popover>
            <Popover
                isOpen={store.confirmMergeOpen}
                content={<PopoverContainer>
                    <div>
                        <div>
                            Fusionar {store.patientSel.selectedIds.length} pacients
                            ({_.sum(store.patientSel.selectedItems.map(r => r.visitCount))} visites)
                            amb {formatFullName(store.finalPatientSel.selectedItem)}
                        </div>
                        <div>
                            <Icon icon={'warning-sign'}/>Alerta: Aquesta acció és irreversible
                        </div>
                    </div>
                    <PopoverButtonsContainer>
                        <Button icon={'cross'} onClick={() => {
                            store.confirmMergeOpen = false
                        }}>Cancela</Button>
                        <Button icon={'git-merge'} intent={'warning'} onClick={async () => {
                            //console.log("Fusiona pacients I ESBORRA DE LA LLISTA" )
                            await store.mergeSelectedPatients()
                            store.confirmMergeOpen = false

                        }}>Fusiona Pacients</Button>
                    </PopoverButtonsContainer>
                </PopoverContainer>}
            >
                <Button
                    icon={'git-merge'}
                    intent={'none'}
                    disabled={!store.canMergePatient}
                    onClick={() => {
                        store.confirmMergeOpen = true
                    }}
                >
                    Fusionar i treure de la llista
                </Button>
            </Popover>

        </ButtonContainer>
    </Container>
})
