import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useAppStore, isDev} from "../../utils/utils";
import {C3Selection} from "react-c4";
import {Button, ButtonGroup, Tooltip} from "@blueprintjs/core";
import * as _ from "lodash";
import {toJS} from "mobx";

const Container = styled.div`
  display: grid;

`

export interface ResourceSelectorProps {
    resSel?: Selection
}

export const ResourceSelector = observer((props: ResourceSelectorProps) => {
    let appStore = useAppStore()
    // Store res sel on server / local storage
    let resourceSel: C3Selection = appStore.resourceSel
    let resourceOrgGroupSel: C3Selection = appStore.resourceOrgGroupSel
    let resourceGroupSel: C3Selection = appStore.resourceGroupSel

    let buttons = []
    resourceOrgGroupSel.items.forEach(og => {
        if (!og.visible) return
        let orgSelected = resourceOrgGroupSel.isSelected(og.id)
        buttons.push(<Button
            key={`og-${og.id}`}
            // intent={'primary'}
            // intent={'success'}
            // icon={orgSelected ? 'tick' : null}
            active={orgSelected}
            onClick={() => {
                resourceOrgGroupSel.selectId(og.id, !orgSelected, false)
                // resourceSel.clearSelection()
                // resourceGroupSel.clearSelection()
            }}
        ><span style={{fontWeight: 'bolder', textDecoration: 'underline'}}>{og.name}</span></Button>)
        let groups = og.groups || [];
        if (orgSelected) {
            // Groups (contents of orgGroup)
            groups.forEach(rg => {
                let groupSelected = resourceGroupSel.isSelected(rg.id)
                buttons.push(<Button
                    // intent={'warning'}
                    // intent={'warning'}

                    key={`${og.id}-rg-${rg.id}`}
                    // icon={groupSelected ? 'tick' : null}

                    active={groupSelected}
                    onClick={() => {
                        resourceGroupSel.selectId(rg.id, !groupSelected, false);
                    }}

                ><span style={{fontWeight: 'bold'}}>{rg.name}</span></Button>)
                // Resources
                if (groupSelected && rg?.resources) {
                    rg.resources.forEach(rId => {
                        let resource = appStore.resourceSel.items.find(it => it.id == rId)
                        let resourceSelected = resourceSel.isSelected(rId)
                        buttons.push(<Button
                            key={`${og.id}-rg-${rg.id}-${rId}`}
                            icon={resourceSelected ? 'tick' : null}
                            active={resourceSelected}
                            onClick={() => {
                                resourceSel.selectId(resource.id, !resourceSelected, false)
                            }}
                        >{resource?.title || resource?.shortName || resource?.name}</Button>)
                    })
                }

            })
        }
    })
    return <Container>
        <ButtonGroup>
            {buttons}
        </ButtonGroup>

        {/*<ButtonGroup vertical={false}>*/}
        {/*    <Button intent={'primary'} active={!store.tmpHidden} onClick={() => store.tmpHidden = !store.tmpHidden}>IRM</Button>*/}
        {/*    {!store.tmpHidden && _.entries(store.totalResources).map(([k, v]: any, i) => {*/}
        {/*        let id = v.id;*/}
        {/*        let selected = store.enabledResourcesSel.isSelected(id);*/}
        {/*        let button = <Button*/}
        {/*            key={id}*/}
        {/*            icon={selected ? 'tick' : null}*/}
        {/*            active={selected}*/}
        {/*            onClick={(e) => {*/}
        {/*                if (e.ctrlKey) {*/}
        {/*                    store.enabledResourcesSel.selectId(id, true, true)*/}
        {/*                } else {*/}
        {/*                    store.enabledResourcesSel.selectId(id, !selected, false)*/}
        {/*                }*/}
        {/*            }}*/}
        {/*            onDoubleClick={() => {*/}
        {/*                store.enabledResourcesSel.selectWhere(() => true, true, true)*/}
        {/*            }}*/}
        {/*        >{v.title}</Button>*/}
        {/*        return button;*/}
        {/*        return <Tooltip content={v.tooltipName} hoverOpenDelay={3200}>*/}
        {/*            {button}*/}
        {/*        </Tooltip>*/}
        {/*    })}*/}
        {/*    <Button intent={'primary'}>GE</Button>*/}

        {/*</ButtonGroup>*/}
    </Container>
})
