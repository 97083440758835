import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SelectControl} from "react-c4";
import {useProdFVStore} from "../../../ProdFV";
import {Alignment, Button, InputGroup, Navbar, Tab, Tabs} from "@blueprintjs/core";
import * as _ from 'lodash'

const Container = styled.div`
  display: grid;
  place-items: start;
  grid-template-columns: 100px 1fr;
  grid-gap: 8px;
  background-color: white;
  align-items: end;
  //overflow: hidden;
`
const ScrollContainer = styled.div`
    overflow-x: auto;
    width: calc(63vw);
    scrollbar-width: thin;
    padding-left: 16px;
    padding-right: 16px;
`

export interface PREHeaderProps {

}

export const PREHeader = observer((props: PREHeaderProps) => {
    let store = useProdFVStore()
    if (store == null) return null
    let sel = store.allMeetupGroupsTabsSel;
    let res = store?.allMeetupGroupsTabsRes;
    if (res == null) return null
    let searchString = store.form.$('rateSheetSearch').value.toLowerCase();
    let items = res
    if (!_.isEmpty(searchString))
        items = items.filter(rs => (rs.name.toLowerCase() as string).includes(searchString));

    return <Container>
        {/*<Navbar>*/}
        {/*    <Navbar.Group align={Alignment.LEFT}>*/}
        {/*        <Navbar.Heading>Blueprint</Navbar.Heading>*/}
        {/*        <Navbar.Divider/>*/}
        {/*        <Button className="bp3-minimal" icon="home" text="Home"/>*/}
        {/*        <Button className="bp3-minimal" icon="document" text="Files"/>*/}
        {/*    </Navbar.Group>*/}
        {/*</Navbar>*/}
        <ScrollContainer>
            <Tabs id="TabsExample" large onChange={(id) => sel.selectId(id)} selectedTabId={sel.selectedId}>
                {items.length>0 && items.map((mg) => {
                    // let selected = store.allRateSheetSel.isSelected(mg.id)
                    let count = store?.eventsRes?.[store.eventsSel.selectedIndex]?.rs?.[mg.id] || 0;
                    return <Tab id={mg.id} title={<div ref={r => store.refMap[mg.id] = r} >
                        {`${mg.name || mg.urlName} `}
                        {<React.Fragment>
                            <span style={{color: 'gray', fontWeight: 'bold'}}>{count}</span>
                            <span style={{color: 'darkgray', fontWeight: 'bold'}}>/</span>
                        </React.Fragment>}
                        <span  style={{color: '#F29D49', fontWeight: 'bold'}}>{mg.count}</span>
                    </div>}/>

                    // return <li className="bp3-tab" role="tab" aria-selected={selected ? 'true' : 'false'} onClick={() => {
                    //     store.allRateSheetSel.selectId(mg.id)
                    // }}>{mg.name}</li>
                })}
                <Tabs.Expander/>
            </Tabs>
        </ScrollContainer>
    </Container>
})
