import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const resourceSchema: C3SchemaInfo = {
    'resource': {
        fields: {
            id: {},
            name: {
                label: 'Nom'
            },
            shortName: {
                label: 'Nom Curt'
            },
            comment: {
                label: 'Comentari'
            },
            contact: {
                type: 'contact'
            },
            resourceType: {
                type: 'resource-type'
            },
            canDo: {
                type: 'product',
                multivalued: true,
                label: 'Proves assignades'
            },
        }
    }
}
