import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useMIVStore} from "../ManualInvoicingView";
import {InvoiceGrid} from "./components/InvoiceGrid";
import {InvoiceLineGrid} from "./components/InvoiceLineGrid";
import {InvoiceInfo} from "./components/InvoiceInfo";
import {AppointmentsViewMaster} from "../../AppointmentsView/master/AppointmentsViewMaster";
import Splitter from "m-react-splitters";
import {InvoicePreview} from "./components/InvoicePreview";
import {ICIInsCompanyBodyFooter} from "../../InsCompanyInvoicingView/ICIMain/ICIInsCompanyBody/ICIInsCompanyBodyFooter";
import {MIVInvoiceLineGridFooter} from "./components/MIVInvoiceLineGridFooter";
import {bindWithAS, isDev, useAS} from "../../../application/utils/utils";
import * as _ from "lodash";
import {DateInputControl} from "react-c4/src/index";
import moment from "moment";
import {Button, Popover} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  padding: 8px;
`

const FirstRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 8px;
  padding-left: 8px;
  align-items: start;
`

const MainDateContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`

const RightPanelContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  grid-gap: 8px;
`
const DateContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  font-weight: bold;
  align-items: center;
  grid-gap: 4px;
  margin-right: 5px;
`
const NotesPopoverContainer = styled.div`
    display: grid;
    padding: 12px;
    grid-template-rows: auto auto;
    grid-gap: 8px;
`


export interface MIVMainProps {

}

export const MIVMain = observer((props: MIVMainProps) => {
    let store = useMIVStore()
    let invoiceForm = store?.invoiceForm
    let as = useAS((field, e) => {
        if (store == null) return
        let fieldName = field.path;
        let body = {id: store.invoiceForm.$('id').value};
        _.set(body, fieldName, store.invoiceForm.$(fieldName).value)
        let promise = store.savePrivateInvoice(body, true);
    })
    return <Container>

        <Splitter
            position="vertical"
            primaryPaneMaxWidth="80%"
            primaryPaneMinWidth={0}
            primaryPaneWidth={"30%"}
            postPoned={false}
        >
            <InvoiceGrid/>
            <RightPanelContainer>
                <FirstRowContainer>
                    <MainDateContainer>
                        {store.invoiceStatus >= 1 && <DateContainer>
                          <label>Data factura:</label>
                          <DateInputControl field={invoiceForm.$('invoiceDate')} as={as}
                                            locked={store.invoiceStatus == 2}/>
                        </DateContainer>}
                        {store.invoiceStatus == 2 &&
                        <span
                          style={{alignSelf: 'center'}}>(facturada {moment(invoiceForm.$('invoiceDate').value).fromNow()})</span>}
                    </MainDateContainer>
                    {store.invoiceStatus > 0 && <Popover
                      isOpen={store.editingNotes}
                      content={<NotesPopoverContainer>
                          <label>Notes:</label>
                          <textarea
                              style={{width: 142 * 2, height: 100, margin: 1}}
                              {...bindWithAS(invoiceForm.$('notes'), as)} placeholder={'Notes a factura'}/>
                      </NotesPopoverContainer>}
                    >
                      <Button
                        icon={'edit'}
                        active={store.editingNotes}
                        onClick={() => {
                            store.editingNotes = !store.editingNotes
                            store.refresh()
                        }}>
                        Editar notes
                      </Button>
                    </Popover>}
                    <InvoiceInfo/>

                </FirstRowContainer>
                {store.viewMode == "edit" ? <InvoiceLineGrid/> : <InvoicePreview/>}
                <MIVInvoiceLineGridFooter/>
            </RightPanelContainer>
        </Splitter>
    </Container>

})
