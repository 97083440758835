import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as moment from "moment";
import {paymentFormMap, stdCurrencyFormat} from "../../../../application/utils/utils";
import {useAVVStore} from "../../AllVisitsView";
import {ALVStore} from "../../../AppointmentListView/ALVStore";
import {AVVStore} from "../../AVVStore";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
`

const TitleContainer = styled.div`
  font-size: 15px;
  font-weight: bold;
`
const TotalContainer = styled.div`
  font-size: 15px;
  font-weight: bold;
`
const SpanContainer = styled.span`
    font-size: 12px;
  font-weight: normal;
`

export interface AVVHeaderProps {

}

export const AVVHeader = observer((props: AVVHeaderProps) => {
    let store = useAVVStore()
    let startDate = store.filterForm.$('startDate').value;
    let endDate = store.filterForm.$('endDate').value;
    let type = store.filterForm.$('type').value;
    let orgName = store.filterForm.$('orgs').value.shortName;
    return <Container>

        <TitleContainer>
            INFORME Actes - Empresa: {orgName} - Total línies: {store.res?.length} <SpanContainer>sense filtres</SpanContainer>
        </TitleContainer>
        <div/>
        <div>
            <TotalContainer>
                Total: {stdCurrencyFormat(store.total)} €
            </TotalContainer>
        </div>
    </Container>
})
