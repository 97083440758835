import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {AutomaticInvoicesPageStore} from "../AutomaticInvoicesPageStore";
import {DateInput, DateRangeInput} from "@blueprintjs/datetime";
import {getMomentFormatter, SelectControl} from "react-c4";
import ReactSelect from "react-select";
import {Radio, RadioGroup} from "@blueprintjs/core";


const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: start;
    grid-auto-columns: 180px;
    align-items: center;
`

export interface AIPHeaderProps {
    store: AutomaticInvoicesPageStore

}

export const AIPHeader = observer((props: AIPHeaderProps) => {
    let store = props.store
    return <Container>
        <DateInput {...getMomentFormatter('DD/MM/YYYY')} {...store.filterForm.$('dateFrom').bind()}/>
        <DateInput {...getMomentFormatter('DD/MM/YYYY')} {...store.filterForm.$('dateTo').bind()}/>
        {/*<SelectControl field={store.filterForm.$('insCompany')} options={store.insCompanies}/>*/}
        {/*<RadioGroup*/}
        {/*    onChange={event => {*/}
        {/*        store.filterForm.$('isChipcard').onChange(event.target.value);*/}
        {/*    }}*/}
        {/*    selectedValue={(store.filterForm.$('isChipcard').value || false).toString()}*/}

        {/*>*/}
        {/*    <Radio label="Directe" value={'false'}/>*/}
        {/*    <Radio label="Chipcard" value={'true'}/>*/}
        {/*</RadioGroup>*/}
    </Container>
})
