import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {SVVStore} from "../../components/StandardVisitView/SVVStore";
import {Button, ButtonGroup, Callout} from "@blueprintjs/core";
import {useMeasure} from "react-use";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    align-items: center;
    justify-content: start;
    
`
const BlinkContainer = styled.div`
    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
    animation: blinker 1s ease-in-out infinite;
`

export interface BookVisitsPageHeaderProps {
    store: SVVStore
}

export const BookVisitsPageHeader = observer((props: BookVisitsPageHeaderProps) => {
    let store = props.store
    const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();


    if (store == null) return null
    let rightFlat = {borderBottomRightRadius: 0, borderTopRightRadius: 0};
    let leftFlat = {borderBottomLeftRadius: 0, borderTopLeftRadius: 0};
    let allFlat = {borderRadius: 0}
    let stats = store.patientStats;
    let vtfStats = store.patientVtfStats;
    let getHistoryButton = (id, text) => {
        let selected = store.historyViewMode === id;
        let count = stats?.[id];
        return <Button
            disabled={false}
            onClick={() => store.historyViewMode = id}
            active={selected}
        >{text} {count != null ? `(${count})` : '   '}</Button>
    };
    let getVtfButton = (id = 'T', text) => {
        let selected = store.visitTypesSel.isSelected(id);
        let count = vtfStats[id];
        return <Button
            icon={selected ? 'tick' : null}
            intent={(id == 'T' && count > 0) ? 'warning' : 'none'}
            disabled={false}
            onClick={() => store.visitTypesSel.selectId(id, !selected, false)}
            active={selected}
        >{text} {count != null ? `(${count})` : '   '}</Button>
    };
    let bp1 = width > 510;
    let bp2 = width > 700;
    bp1 = true
    bp2 = true
    return <Container ref={ref}>
        {/*<Button minimal={true} active={store.showHistorical} icon={'history'}*/}
        {/*        onClick={() => store.showHistorical = !store.showHistorical}>Mostra anteriors</Button>*/}
        {bp1 && (store.showHistorical || true) && <ButtonGroup>
            {/*{getHistoryButton('todayOnly', 'Avui')}*/}
            {getHistoryButton('today', 'Avui i futures')}
            {getHistoryButton('lastWeek', 'Última setmana')}
            {getHistoryButton('lastYear', 'Últim any')}
            {getHistoryButton('all', 'Totes')}

        </ButtonGroup>}
        <ButtonGroup>
            {getVtfButton('T', 'Temporals')}
            {getVtfButton('C', 'Cites')}
            {getVtfButton('V', 'Visites')}
        </ButtonGroup>
        {store.isSelectedAppointmentToday && !store.patientValid && bp2 && <BlinkContainer>
            <Callout intent={'warning'} icon={'warning-sign'} title={'Falten camps obligatoris Pacient'}/>
        </BlinkContainer>}
        {/*{store.invoicesCollapsed ? 'A' : 'B'}*/}
    </Container>
})
