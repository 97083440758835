import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {ICellRendererParams} from "ag-grid-community";
import {Icon, Tooltip} from "@blueprintjs/core";
import {SVVStore} from "../../../../SVVStore";

const Container = styled.div`
  display: grid;

`
const TestCRContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  //justify-content: start;
  grid-template-columns: minmax(0px, auto) 1fr auto;
  grid-gap: 2px;
  height: 100%;

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

@observer
export class SVVLinesGridPaymentMethodCR extends React.Component<ICellRendererParams, any> {
    refresh() {
        return true
    }

    render() {
        let params = this.props
        let store = (SVVLinesGridPaymentMethodCR as any).store as SVVStore
        let rootField = 'payment.active.'
        let paymentField = (field) => `${rootField}${field}`
        let getValueFromGetter = (params, paymentFieldName) => {
            return _.get(params.data, paymentField(paymentFieldName))
        }
        let visitLineId = params.data.id
        if (!store.visitLinesRateOptionsMap) return null
        if (_.isEmpty(params.valueFormatted)) return null
        let rates = store.visitLinesRateOptionsMap[visitLineId]?.rates
        let rateCount = rates?.length;
        let indicator = null
        let paymentMethod = getValueFromGetter(params, 'paymentMethod');
        let insCompany = getValueFromGetter(params, 'insCompany');
        if (rateCount <= 0) {
            let message = 'Aquesta prova no té barems';
            if (insCompany || getValueFromGetter(params, 'paymentMethod.isInsCompany')) {
                let insCompanyName = _.toUpper(insCompany?.name);//getValueFromGetter(params, 'paymentMethod.name');
                message = `${insCompanyName} no inclou ${params.data.product?.name}`
                if (params.data.product?.name == null) {
                    message = `Selecciona una prova`
                }
            }
            indicator = <Tooltip content={message}>
                <Icon icon={'warning-sign'} intent={'warning'} iconSize={16}/>
            </Tooltip>
        }
        let shouldSelect = getValueFromGetter(params, 'rate')?.id == null;
        if (rateCount == 1 && shouldSelect) {
            indicator =
                <Tooltip content={`Clic per assignar ${rates[0]?.code || rates[0]?.id} ${rates[0]?.name}`}>
                    <Icon icon={'caret-right'} intent={"none"}
                          color={'#8cb6de'}
                          onClick={() => {
                              params.node.setDataValue(paymentField('rate'), rates[0])
                              params.api.refreshCells({columns: [paymentField('rate')]})
                          }}
                    />
                </Tooltip>
        }
        if (rateCount > 1 && shouldSelect) {
            indicator = <Tooltip content={`Aquesta prova té ${rateCount} barems`}>
                <Icon icon={shouldSelect ? 'select' : 'select'} intent={"none"}
                      color={shouldSelect ? '#8cb6de' : 'lightgray'}
                      onClick={() => {
                          params.api.ensureColumnVisible(paymentField('rate'));
                          params.api.setFocusedCell(params.rowIndex, paymentField('rate'));
                      }}
                />
                {/*<Icon icon={'multi-select'} intent={"none"}/>*/}
                {/*<Icon icon={'warning-sign'} intent={'warning'} />*/}
            </Tooltip>
        }
        if (['ss', 'endOfMonth'].includes(paymentMethod?.id) && (insCompany?.id == null)) indicator = null

        return <TestCRContainer key={'a' + params.value?.id}>
            <span>{params.valueFormatted}</span>
            <span/>
            {indicator}
        </TestCRContainer>


    }
}
