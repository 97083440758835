import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const visitStatusSchema: C3SchemaInfo = {
    'visit-status': {
        fields: {
            id: {},
            name: {},
            // Not a priority for now
        }
    }
}
