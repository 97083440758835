import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {List, AutoSizer, Table, Column, defaultTableRowRenderer, defaultTableHeaderRowRenderer} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {useSVVStore} from "../../StandardVisitView";
import {SVVAttachDocumentsListItem} from "./SVVAttachDocumentsListItem/SVVAttachDocumentsListItem";
import {Icon, NonIdealState, Spinner} from "@blueprintjs/core";
import ReactProgressiveList from 'react-progressive-list'
const Container = styled.div`
    display: grid;
    align-content: start;
    //grid-gap: 8px;
    //padding: 8px;
    //padding-top: 4px;
    user-select: none;
    height: 100%;
`

export interface SVVAttachDocumentsListProps {

}

export const SVVAttachDocumentsList = observer((props: SVVAttachDocumentsListProps) => {
    let store = useSVVStore()
    let noRowsRenderer = () => {
        return <NonIdealState title={'No hi ha cap document'} icon={'document'}/>
    }

    // return <ReactProgressiveList
    //     initialAmount={10}
    //     progressiveAmount={2}
    //     renderItem={(index) => {
    //         let d = store.documentsRes[index]
    //         return <SVVAttachDocumentsListItem isScrolling={false} style={{}} key={d.id} index={index} document={d}/>
    //     }}
    //     renderLoader={() => <Spinner />}
    //     rowCount={store.documentsRes.length}
    //     useWindowScroll
    // />

    return <Container>
        {store.documentsRes.length>0 && <span><Icon icon={'arrow-down'}/>2 Clics a la imatge per descarregar el document</span>}
        {/*<AutoSizer>*/}
        {/*    {({height, width}) => (*/}
        {/*        <List*/}
        {/*            ref="List"*/}
        {/*            // className={styles.List}*/}
        {/*            // style={{padding: , paddingTop: 4}}*/}
        {/*            height={height}*/}
        {/*            width={width}*/}
        {/*            overscanRowCount={20}*/}
        {/*            noRowsRenderer={noRowsRenderer}*/}
        {/*            rowCount={store.documentsRes.length}*/}
        {/*            rowHeight={*/}
        {/*                    168.4 + 8*/}
        {/*            }*/}
        {/*            rowRenderer={({index, isScrolling, key, style}) => {*/}
        {/*                let d = store.documentsRes[index]*/}
        {/*                // return 'AAAA'*/}
        {/*                return <SVVAttachDocumentsListItem isScrolling={isScrolling} style={style} key={d.id} index={index} document={d}/>*/}

        {/*            }}*/}
        {/*            // scrollToIndex={scrollToIndex}*/}
        {/*        />*/}
        {/*    )}*/}
        {/*</AutoSizer>*/}
        {store.documentsRes.map((d, i) => {
            return <SVVAttachDocumentsListItem key={d.id} index={i} document={d}/>
        })}
    </Container>
})
