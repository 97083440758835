import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MPPStore} from "../../components/PrescriptionView/MPPStore";

const Container = styled.div`
    display: grid;
    
`

export interface PrescriptionPageHeaderProps {
    store: MPPStore
}

export const PrescriptionPageHeader = observer((props: PrescriptionPageHeaderProps) => {
    let store = props.store
    if (store == null) return null
    return <Container>
        MPP Header
    </Container>
})