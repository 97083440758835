import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SVVMain} from "./SVVMain/SVVMain";
import {useC3Store} from "react-c4";
import {SVVStore} from "./SVVStore";

const Container = styled.div`
    display: grid;
    padding: 8px;
    grid-gap: 8px;
    grid-auto-flow: row;
    grid-template-rows: auto;
    height: 100%;
`

export const SVVContext = React.createContext(null as SVVStore)
export const useSVVStore = () => useContext(SVVContext)

export interface StandardVisitViewProps {
    pageContext?
    selectedVisitIds?: number[],
    selectedVisitLineIds?: number[]
    selectedInvoiceIds?: number[]
    newAppointments?
    patientId: number
    onStoreReady: (s: SVVStore) => any
}

export const StandardVisitView = observer((props: StandardVisitViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new SVVStore(props.patientId, props.selectedVisitIds, props.selectedVisitLineIds, props.selectedInvoiceIds , props.newAppointments);
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    let genMD = store.genMD
    return <SVVContext.Provider value={store}>
        <Container>
            <SVVMain pageContext={props.pageContext}/>
        </Container>
    </SVVContext.Provider>
})
