import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Tag} from "@blueprintjs/core";
import FullCalendar, {PluginDef} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import locale_ca from '@fullcalendar/core/locales/ca'
// import '@fullcalendar/core/main.css'
// import '@fullcalendar/daygrid/main.css'
// import '@fullcalendar/timegrid/main.css'
// import '@fullcalendar/timeline/main.css'
// import '@fullcalendar/resource-timeline/main.css'
import './calendar.css'
import {useSVVStore} from "../../StandardVisitView";
import {MRFField} from "react-c4/src/forms/MRF";
import moment from "moment";
import * as _ from 'lodash'
import {formatFormalName} from "../../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    grid-auto-flow: row;
    justify-content: stretch;
    //border: 1px solid gray;
    //border-top: 1px solid black;
    //border-right: 1px solid black;
    padding-right: 0.5px;
    padding-top: 0.5px;
    grid-gap: 4px;
    
`
const HeaderContainer = styled.div`
    display: grid;
    padding: 2px;
    font-size: 16px;
    place-items: center;
`

const ProductToolbarContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: 4px;
    cursor: pointer;
`
const CalendarContainer = styled.div`
    display: grid;
    //height: 180px;
`

export interface SVVCalendarProps {
    resourceId
    involvedVisitsIds
    i
}

let names = ['J. Garcia', 'Aina', 'Anna', 'Josep', 'Aldeida', 'Ona', 'Jaume', 'Joan', 'Carme', 'Jordi', 'Arnau', 'Pep']
let tests = ['Cuello', 'Abdomen']
let eventsArr = [
    {title: 'J. Garcia', machine: 1, test: 'RM Cuello', start: 1, dur: 30},
    {title: 'Anna Gómez', machine: 1, test: 'RM Abdomen', start: 2, dur: 30},
    {title: 'Anna Gómez', machine: 3, test: 'RM Abdomen', start: 4, dur: 40},
    {title: 'Aina Garcia', machine: 3, test: 'RM Abdomen', start: 4, dur: 50},
    {title: 'Aina Garcia', machine: 3, test: 'RM Abdomen', start: 5, dur: 50},
]
let fillMachine = (machineId, machineName) => {
    for (let i = 0; i < 100; i++) {
        eventsArr.push({title: 'J. Garcia', machine: machineId, test: `${machineName} ${tests[0]}`, start: i, dur: 30})
    }
}
// fillMachine(1, 'RM')
// fillMachine(3, 'CBCT')
export const SVVCalendar = observer((props: SVVCalendarProps) => {
    let store = useSVVStore()
    // let field = props.field;
    let fields = props.involvedVisitsIds.map(visitId => store.visitsSel.getFieldById(visitId))
    let [localSelField, setLocalSelField] = useState(_.first(fields) as MRFField)
    let oldEvents = eventsArr.filter(it => it.machine == props.resourceId);
    let cachedVisits = store.resourceScheduleCache[props.resourceId] || [];
    let events: any[]
    events = cachedVisits.filter(v => !props.involvedVisitsIds.includes(v.id)).map((v, i) => {
        let prefix = v.visitNumberPrefix;
        return {
            id: v.id,
            title: `${v.p}${v.visitNumber} ${formatFormalName(v.patient)}`,
            start: moment(v.appointmentDate).toDate(),
            end: moment(v.appointmentDate).add(v.duration, "minutes").toDate(),
            ...(prefix == 'T' && {
                backgroundColor: '#d9900b',
                borderColor: '#fae161',
            }),
            ...(prefix == 'C' && {
                backgroundColor: '#d9be8f',
                style: {borderWidth: 4}
            }),
            ...(prefix == 'V' && {
                style: {borderWidth: 4}
            })
        }
    });
    useEffect(() => {
        store.refetchResourceScheduleCache(props.resourceId)
    }, [])
    events.push(...fields.map((field) => {
        let visitId = field.$('id').value;
        let prefix = field.$('visitNumberPrefix').value;
        return {
            id: null,
            title: `${field.$('visitNumberText').value} ${store.patientForm.$('surname1').value} ${store.patientForm.$('surname2').value}, ${store.patientForm.$('name').value}`,
            start: moment(field.$('appointmentDate').value)
                .toDate(),
            end: moment(field.$('appointmentDate').value)
                .add(field.$('duration').value, 'minutes')
                .toDate(),
            eventColor: "blue",

            borderColor: 'red',

            ...(prefix == 'T' && {
                backgroundColor: '#d9900b',

            }),
            ...(prefix == 'C' && {
                backgroundColor: '#d9be8f',

            }),
            ...(prefix == 'V' && {})
        }
    }))

    let scrollField = fields[0];
    let scrollDate = scrollField?.$('duration').value;
    if (scrollField?.$('duration')?.value) {
        scrollDate = moment(scrollField.$('appointmentDate').value)
            .add(scrollField.$('duration').value, 'minutes')
            .toDate()
    }
    let s = moment(scrollDate).format('HH:mm:ss');
    console.log(`s`, s);
    let slotMinTime = "07:00";
    let slotMaxTime = "21:00";
    return <Container>
        {/*<HeaderContainer>*/}
        {/*    /!*{field.$('resource').value?.name} [Suma de temps totes línies]*!/*/}
        {/*</HeaderContainer>*/}
        {/*<ProductToolbarContainer>*/}
        {/*    /!*{props.products.map(p => {*!/*/}
        {/*    /!*    return <Tag minimal key={p}>{p}</Tag>*!/*/}
        {/*    /!*})}*!/*/}
        {/*</ProductToolbarContainer>*/}
        <CalendarContainer>

            <FullCalendar
                stickyHeaderDates={true}
                monthMode={false}
                dayCount={30}
                initialView="timeGrid10Days"
                schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                // ref={calendarRef}
                plugins={[dayGridPlugin, resourceTimelinePlugin, timeGridPlugin, interactionPlugin] as PluginDef[]}
                headerToolbar={{

                    left: 'prev,next today',
                    center: 'title',
                    // right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek,resourceTimelineWeek'
                    right: 'timeGrid1Days,timeGrid10Days,timeGrid30Days'
                }}
                // height={height}
                weekends={false}
                // events={[
                //     {title: 'event 1', date: '2019-12-01'},
                //     {title: 'event 2', date: '2019-12-02'},
                //     {title: 'event 3', date: '2019-12-06'},
                // ]}
                height={350}
                windowResizeDelay={500}
                views={{
                    timeGrid1Days: {
                        type: 'timeGrid',
                        buttonText: '1 dia',
                        duration: {days: 1},
                        dayHeaderFormat: {weekday: 'short', day: 'numeric', month: 'numeric', omitCommas: true}
                    },
                    timeGrid10Days: {
                        type: 'timeGrid',
                        buttonText: 'set',
                        duration: {days: 7},
                        dayHeaderFormat: {weekday: 'short', day: 'numeric', month: 'numeric', omitCommas: true}
                    },
                    timeGrid30Days: {
                        type: 'timeGrid',
                        buttonText: '30 d',
                        duration: {days: 30},
                        dayHeaderFormat: {weekday: 'short', day: 'numeric', month: 'numeric', omitCommas: true}
                    },
                } as any}
                events={events}
                allDaySlot={false}

                // ref={this.calendarComponentRef}
                // weekends={this.state.calendarWeekends}
                // slotLabelFormat={(d) => {
                //     let s = moment(d.date).format('HH:mm');
                //     return s;
                // }}
                // eventRender={(params) => {
                //     // params.el = document.createElement('div')
                //     if (params.event.id == null) {
                //         params.el.innerHTML = '<h3>Nova cita</h3>'
                //
                //     }
                //     return params.el
                // }}
                slotDuration={`00:${15}:00`}
                slotMinTime={slotMinTime + ":00"}
                slotMaxTime={slotMaxTime + ":00"}
                dateClick={(params) => {
                    // console.log(`field.values()`, field.values());
                    setTimeout(async () => {
                        // store.visitsSel.fi.$('date').onChange(params.date)
                        let visits = store.form.$('visits').values();
                        let visitIndex = visits.findIndex((v) => v.id == localSelField.value?.id);
                        let $visit = store.form.$('visits').$(visitIndex);
                        $visit.$('appointmentDate').onChange(params.date)
                        await store.saveVisit({id: localSelField.value?.id, appointmentDate: params.date})
                        await store.refetchVisitLinesForVisits([localSelField.value?.id])
                        // console.log(`props.involvedVisitsIds`, props.involvedVisitsIds, $visit.value);
                    })
                }}
                // eventClick={handleEventClick}
                locales={[locale_ca]}
                aspectRatio={3.5}
                editable={false}
                dragScroll={true}
                eventResizableFromStart={true}
                scrollTime={scrollDate != null ? s : '09:00:00'}
            />
        </CalendarContainer>
    </Container>
})
