import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {ContactFilePageHeader} from "./ContactFilePageHeader";
import {CFVStore} from "../../files-views/ContactFileView/CFVStore";
import {ContactFileView} from "../../files-views/ContactFileView/ContactFileView";

const Container = styled.div`
  display: grid;

`

export interface ContactFilePageProps {

}

export const ContactFilePage = page(observer((props: ContactFilePageProps) => {
    let [store, setStore] = useState(null as CFVStore);

    return <Page
        title={'Mútues'}
        subtitle={`Manteniment de mútues`}

        icon={'id-number'}
        attributes={[
            {
                name: 'Contacts',
                value: store?.contactsRes?.length,
            }
        ]}
        renderCustomHeaderArea={() => {
            return <ContactFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            return <Container>
                <ContactFileView
                    onStoreReady={(s) => {
                        setStore(s);
                    }}
                    {...pageContext.args}
                    pageContext={pageContext}
                />
            </Container>
        }}
    />
}))
