import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4";
import MobxReactForm from "mobx-react-form";
import {AppStore} from "../../application/AppStore";
import {computed, observable, autorun, action} from "mobx";
import {nextAnimationFrame, nextTimeout} from "../../application/utils/utils";
import moment from "moment";

export class RefFVStore {

    appStore: AppStore

    filterForm: MRF

    treatmentMap = ['Dr', 'Dra']

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection

    @observable
    filterDateHandler = false

    @observable
    excelDialogOpen = false


    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.specialtyListRes = this.appStore.specialty
        this.orgsRes = this.appStore.orgs || [];
        this.pendingReferrersSel = new C3Selection({
            items: this.pendingReferrersRes,
        })
        this.reviewedReferrersSel = new C3Selection({
            items: this.reviewedReferrersRes,
        })
        this.orgsSel = new C3Selection(({
            items: this.orgsRes,
            onSelectionChanged: () => {
                this.filterForm.$('orgs').set(this.orgsSel.selectedItem)
            }
        }))

        this.filterForm = new MobxReactForm({
            fields: [
                'protocol',
                'specialty',
                'orgs',
                'startDate',
                'endDate',
                'invoiceCounter',
            ],
            values: {
                orgs: [],
                protocol: '',
                specialty: '',
                startDate: new Date(),
                endDate: new Date(),
            }
        });
        await this.refresh()
        autorun(() => {
            this.specialtyListRes = this.appStore.specialty
        })
        autorun(async () => {
            this.orgsRes = this.appStore.orgs;
            if (this.orgsRes == null || this.orgsRes.length === 0) return
            this.orgsSel.itemsRef.current = this.orgsRes;
            this.orgsSel.selectedIds = [this.orgsRes[0].id];
        })
        autorun(async ()=>{
            this.filterForm.$('orgs').set(this.orgsSel.selectedItem);
            await this.fetchReviewed();
            await this.fetchToReview();
        })
        autorun(() => {
            let startDate = this.filterForm.$('startDate').value;
            let endDate = this.filterForm.$('endDate').value;

            this.fetchToReview()
        }, {delay: 5})
    }

    @action
    async moveRange(days: number) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');

        [$startDate, $endDate].forEach($d => $d.onChange(moment($d.value).add(days, "days").toDate()))
    }

    @action
    async goToDate(date: Date) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');
        [$startDate, $endDate].forEach($d => $d.onChange(moment(date).toDate()))
    }

    @observable
    showInactive= false

    @observable
    confirmMergeOpen: boolean = false

    @observable.shallow
    pendingReferrersRes

    pendingReferrersSel: C3Selection

    async refresh() {
        await Promise.all([this.fetchToReview(), this.fetchReviewed()])
    }

    async fetchToReview() {
        const startDate = moment(this.filterForm.$('startDate').value).startOf('day').toDate();
        const endDate = moment(this.filterForm.$('startDate').value).endOf('day').toDate();
        let startDateQuery = '';
        let endDateQuery = '';
        if ( this.filterDateHandler){
            startDateQuery = `start=${startDate.toISOString()}`;
            endDateQuery = `end=${endDate.toISOString()}`;
        }
        let counterId = this.filterForm.$('invoiceCounter').value?.id;
        let orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (orgs === '') return
        this.pendingReferrersRes = await C3.instance.client.fetcher.fetch('/contact/referrer-by-review', {
            query: [
                startDateQuery,
                endDateQuery,
                `counterId=${counterId}`,
            ].join('&')
        })
        this.pendingReferrersSel.itemsRef.current = this.pendingReferrersRes;
        return this.pendingReferrersRes
    }

    @observable.shallow
    reviewedReferrersRes

    reviewedReferrersSel: C3Selection

    @observable.shallow
    specialtyListRes: any[]

    async fetchReviewed() {
        let orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (orgs === '') return
        this.reviewedReferrersRes = await C3.instance.client.fetcher.fetch('/contact/referrer-by-review', {
            query: [`reviewed=true`, `accepted=true`, orgs, `inactive=${this.showInactive}`].join('&')
        })
        this.reviewedReferrersSel.itemsRef.current = this.reviewedReferrersRes;
        return this.reviewedReferrersRes
    }

    @computed
    get canMergeReferrer() {
        return this.pendingReferrersSel.selectedIds.length > 0 && this.reviewedReferrersSel.selectedId
    }

    @computed
    get canAcceptReferrer() {
        return this.pendingReferrersSel.selectedIds.length > 0
    }

    @computed
    get canMarkAsPendingReview() {
        return this.reviewedReferrersSel.selectedIds.length > 0
    }

    @computed
    get canErase(): boolean {
        if (this.pendingReferrersSel.selectedItem === undefined) return false
        return this.pendingReferrersSel.selectedItem?.visitCount === 0
    }

    async acceptSelectedReferrers() {
        let ids = this.pendingReferrersSel.selectedIds;
        await this.acceptReferrer(ids, true)
        this.reviewedReferrersSel.clearSelection()
        this.reviewedReferrersSel.selectIds(ids, true, true)
        this.pendingReferrersSel.clearSelection()
    }

    async acceptReferrer(referrerIds, accept) {
        if (!referrerIds) return
        await C3.instance.client.fetcher.fetch(`/contact/referrer/accept-many`, {
            method: 'POST',
            body: {
                ids: referrerIds,
                accept: accept
            }
        })
        await this.refresh()
    }

    async mergeSelectedReferrers() {
        await this.mergeReferrers(this.pendingReferrersSel.selectedIds, this.reviewedReferrersSel.selectedId)
        this.pendingReferrersSel.clearSelection()
        this.reviewedReferrersSel.selectId(this.reviewedReferrersSel.selectedId, true, true)

    }

    async markSelectedAsPending() {
        let ids = this.reviewedReferrersSel.selectedIds;
        await this.acceptReferrer(ids, false)
        this.pendingReferrersSel.selectIds(ids, true, true)
        this.reviewedReferrersSel.clearSelection()
    }

    async mergeReferrers(sourceIds, targetId) {
        if (!sourceIds || !targetId) return
        await C3.instance.client.fetcher.fetch(`/contact/referrer/merge-many`, {
            method: 'POST',
            body: {
                sourceIds,
                targetId
            }
        })
        await this.refresh()
    }

    async createReferrer() {
        await C3.instance.client.fetcher.fetch('/contact/referrer', {
            method: 'POST',
            body: {name: 'nou referent', orgs:[{id:this.orgsSel.selectedId}], isActive: true}
        })
        await this.fetchToReview()
    }

    async deleteReferrer(referrerId) {
        await C3.instance.client.fetcher.fetch(`/contact/referrer/${referrerId}`, {
            method: 'DELETE',
        })
        await this.fetchToReview()
    }

    async deleteSelectedReferrer() {
        let selectedId = this.pendingReferrersSel.selectedId;
        console.log(`selectedId`, selectedId);
        console.log(`this.referrersSel`, this.pendingReferrersSel);
        if (selectedId == null) return
        await this.deleteReferrer(selectedId)
    }

    async saveReferrer(referrer) {
        if (!referrer.id) return
        await C3.instance.client.fetcher.fetch(`/contact/referrer/${referrer.id}`, {
            method: 'PATCH',
            body: referrer
        })
    }

    async excelImportClick() {
        this.excelDialogOpen = true
    }

    async processExcelImport(name, json) {
        console.table(json);
        json.splice(0, 1)
        let result = json.map(r => {
            let [id, subaccount, description, nif, address, city, province, postalCode, ...rest] = r as any
            // if (heurColCount == 2) {
            //     name = r[0]
            //     price = r[1]
            //     code = null
            // }


            return {
                id,
                subaccount,
                notes: description,
                nif,
                address,
                city,
                province,
                postalCode,
            }
        })
        result = result.filter((r, i) => {
            // if (!r.id || _.isEmpty(r.id)) return false
            return true
        })
        console.log(`result`, result);
        console.table(result)
        let res = await this.submitImport({
            name,
            referrers: result,
            orgId: this.orgsSel.selectedId
        })
        // numeral.locale('ca-es')
        console.log(`res`, res);
        await nextTimeout(100)
        await this.refresh()
        await nextTimeout(300)
        // if (res?.id)
        //     this.rateSheetsSel.selectId(res.id, true, true)

    }

    async submitImport(body) {
        return await C3.instance.client.fetcher.fetch(`/contact/import-reviewed-referrers`, {
            method: 'POST',
            body
        })
    }

}
