import React, {useState, useEffect, useContext} from "react";
import styled, {css} from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import * as moment from "moment";
import {Button, Tooltip} from "@blueprintjs/core";
import {useSCVStore} from "../../ScheduleView";

const Container = styled.div`
    display: grid;
    
`
const HeaderContainer = styled.div`
    background-color: #bfccd6;
    
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    grid-gap: 4px;
    padding: 4px;
    align-items: center;
    font-size: 20px;
    
    ${p => p.isLockedIn && css`
      background-color: rgba(15, 153, 96, 0.15);
      color: #0d8050;
    `}
    
`
const NameContainer = styled.div`
   display: grid;
   grid-gap: 4px;
   grid-auto-flow: column;
   grid-template-columns: auto auto 1fr;
`
const InnerNameContainer = styled.div`
    text-transform: uppercase;
`
const HeaderRightContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0px;
    overflow: hidden;
    font-size: 13px;
`
const HeaderButtonsContainer = styled.div`
    display: contents;
`
export interface DetailHeaderProps {
    type: 'meetup-event' | 'event'
}

export const DetailHeader = observer((props: DetailHeaderProps) => {
    let store = useSCVStore()

    let $name = store.eventForm.$('name');
    let $startDate = store.eventForm.$('startDate');
    let $location = store.eventForm.$('location');
    let item = store.eventSel.selectedItem;
    if(!item) return <Container>No event selected</Container>
    let eventId = (item.id as string).split('-')[1];
    return <Container>
        <HeaderContainer style={{...( props.type == 'meetup-event' ? {backgroundColor: '#ffb2b5'} : {})}} isLockedIn={false}
                         onClick={() => store.basicInfoCollapsed = !store.basicInfoCollapsed}>
            <NameContainer>
                {/*{creating && <Tag large>Nou pacient</Tag>}*/}
                {(store.eventForm.$('id').value == '') && <span>Selecciona un event</span>}
                {store.eventSel.selectedItem?.type == 'meetup-event' && <div onClick={() => {
                    if (store.eventSel.selectedItem.event?.id) store.eventSel.selectId(`event-${store.eventSel.selectedItem.event.id}`)
                }} style={{
                    borderRadius: '50%',
                    boxSizing: 'border-box',
                    border: '5px solid rgba(255,255,255,0.6)',
                    backgroundColor: store.eventSel.selectedItem?.event?.id ? 'limegreen' : 'orange',
                    height: 20,
                    width: 20,
                    placeSelf: "center",
                    lineHeight: '100%',
                    display: 'grid',
                    placeItems: 'center',
                }}/>}
                <InnerNameContainer>
                    {$name.value}
                </InnerNameContainer>
                <div/>
            </NameContainer>
            <Button onClick={() => {
                window.open(`https://www.barcelonainternationalsocial.com/events/${eventId}`, "_blank");
            }} icon={'share'}/>
            {/*<Button onClick={() => window.open(`https://www.meetup.com/${item?.urlName}/events/${item?.meetupId}/edit`, "_blank")} icon={'edit'}/>*/}
            {props.type == 'meetup-event' && !item?.event?.id && <Tooltip content={`Copiar event de meetup`}>
                <Button onClick={() => store.copyEventFromMeetupEvent()} icon={'import'}/>
            </Tooltip>}
            {store.basicInfoCollapsed && <HeaderRightContainer>
                {$location.value && <div>{$location.value}</div>}
                <div>{$startDate.value && moment($startDate.value).format('DD/MM/YYYY')}</div>
            </HeaderRightContainer>}
            <HeaderButtonsContainer onClick={e => e.stopPropagation()}>
                {props.type == 'event' && <Button icon={store.basicInfoCollapsed ? 'double-chevron-down' : 'double-chevron-up'}
                         onClick={() => store.basicInfoCollapsed = !store.basicInfoCollapsed}/>}
                {/*<Button icon={'document-open'} onClick={() => {*/}
                {/*    if(props.pageContext) props.pageContext.navigate({*/}
                {/*        to: 'patient',*/}
                {/*        focusNewTab: true,*/}
                {/*        inNewTab: true,*/}
                {/*        args: {entityId: form.entityId}*/}
                {/*    })*/}
                {/*}}/>*/}
                {/*<Button icon={locked ? 'lock' : 'unlock'} onClick={() => {*/}
                {/*    // return store.form.isLocked = !locked;*/}

                {/*    if (locked) {*/}
                {/*        store.form.isLocked = false*/}
                {/*    } else {*/}
                {/*        store.form.save().then((res) => {*/}
                {/*            if (store.onSavePatient) store.onSavePatient(res)*/}
                {/*            return store.fetchPatientInsCompanies();*/}
                {/*        });*/}
                {/*        store.form.isLocked = true*/}
                {/*    }*/}


                {/*}}/>*/}
            </HeaderButtonsContainer>
        </HeaderContainer>
    </Container>
})
