import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {formatFormalName, formatFullName, stdDateColumn, stdIntNumericColumn} from "../../../application/utils/utils";
import {C3AgGrid, MasterLayout} from "react-c4";
import {useALVStore} from "../AppointmentListView";
import {toJS} from "mobx";
import {useReactToPrint} from "react-to-print";
import * as moment from "moment";
import {RowNode} from "ag-grid-community";




export interface ALVMainProps {
    pageContext
}
const Container = styled.div`
    display: grid;
    height: 100%;
`
export const ALVMain = observer((props: ALVMainProps) => {

    let store= useALVStore()
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        documentTitle: 'llistat-cites-' + moment(store.filterForm.$('startDate').value).format('DD-MM-YYYY'),
        content: () => componentRef.current,
    });
    useEffect(() => {
        store.printHandle = handlePrint
    }, [])

    return <Container ref={componentRef}>
        <MasterLayout
            actions={[]}
            renderHeader={()=> null}
            body={<C3AgGrid
                selection={store.sel}
                onGridReady={({api})=> store.gridApi = api}
                defaultColDef={{
                    editable: false,
                    // filter: 'agTextColumnFilter'
                    filter: false,
                    resizable: true,
                    suppressMenu: true,
                    width: 100,
                    sortable: true
                }}
                onRowDoubleClicked={({data, event}) => {
                    setTimeout(() => {
                        if (store.pageContext) {
                            store.pageContext.navigate({
                                to: 'bookVisitPage', args: {
                                    patientId: data.pId,
                                    selectedVisitIds: [data.vId]

                                },
                                inNewTab: !(event as any).ctrlKey, focusNewTab: true
                            })
                        }
                    })
                }}
                columnDefs={[
                    {
                        field: 'orgName',
                        headerName: 'Org',
                        width: 85,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        },
                    {
                        field: 'start',
                        headerName: 'DATA',
                        width: 125,
                        ...stdDateColumn()
                    },
                    {
                        field: 'start',
                        headerName: 'H.Ent',
                        width: 70,
                        valueFormatter({value, data}): string {
                            return moment(value).format('HH:mm')

                        }
                    },
                    {
                        field: 'end',
                        headerName: 'H.Sort',
                        width: 70,
                        valueFormatter({value, data}): string {
                            return moment(value).format('HH:mm')

                        }
                    },
                    {
                        field: 'resourceName',
                        headerName: 'Màquina',
                        width: 85,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'lines',
                        headerName: 'Proves',
                        width: 140,
                        valueFormatter({value, data}): string {
                            return `${value.filter(v => v.name).map(v => v.name)}`

                        },
                    },
                    {
                        field: 'title',
                        headerName: 'Nom',
                        width: 140,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'tel',
                        headerName: 'Mobil/Telèfon',
                        width: 140,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,

                    },
                    {
                        field: 'id',
                        headerName: 'N.Cita/Visita',
                        width: 100,
                        ...stdIntNumericColumn(),
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'paymentMethod',
                        headerName: 'Tipus de Pagament',
                        width: 100,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'comm',
                        headerName: 'Comentaris',
                        width: 140,
                    },
                ]}
                processCellCallback={(params) => {
                    let data = (params.node as RowNode).data
                    return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                }}
                getContextMenuItems={(params) => {
                    var result = [
                        {
                            name: 'Exporta a Excel',

                            action: function () {
                                params.api.exportDataAsExcel({
                                    fileName: 'Llistat-Cites',
                                    processCellCallback: (params) => {
                                        let data = (params.node as RowNode).data
                                        if (params.column.getColId() == 'start') {
                                            return stdDateColumn().valueFormatter({value: data.start})
                                        }
                                        if (params.column.getColId() == "start_1") {
                                             return moment(params.value).format('HH:MM')
                                        }
                                        if (params.column.getColId() == 'end') {
                                            return moment(params.value).format('HH:MM')
                                        }
                                        if (params.column.getColId() == 'lines'){
                                                return `${params.value.filter(v => v.name).map(v => v.name)}`
                                        }
                                        return params.value
                                    }
                                })
                            },
                        }
                    ]
                    return result
                }}
            />}
        />
    </Container>
})
