import {C3Selection, IATSStyleApp, C3, MRF} from "react-c4";
import MobxReactForm from "mobx-react-form";
import {AppStore} from "../../application/AppStore";
import {C3Transfer} from "react-c4/src/components/Transfer/C3Transfer";
import numeral from 'numeral'
import {autorun, observable} from "mobx";
import * as _ from "lodash";
import {nextTimeout} from "../../application/utils/utils";

export class CFVStore {

    appStore: AppStore
    pageContext

    filterForm: MRF


    contactTypeSel: C3Selection

    @observable.shallow
    contactsRes: any
    contactSel: C3Selection

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection

    @observable.shallow
    rateSheetRes: any[]
    rateSheetSel: C3Selection

    @observable
    showInactive = false
    private contactType: any;

    paymentMethodsMap = [
        'chipcard',
        'manual'
    ]

    @observable
    editorLocked = false

    @observable
    popoverCheck = false

    @observable
    excelDialogOpen = false


    async init() {
        this.appStore = IATSStyleApp.instance.appStore;
        this.orgsRes = this.appStore.orgs || [];

        this.filterForm = new MobxReactForm({
            fields: [
                'type',
                'orgs',
                'editCode'
            ],
            values: {
                type: 'ins-company',
                orgs: [],
                editCode: ''
            }
        })

        this.rateSheetSel = new C3Selection(({
            items: this.rateSheetRes
        }))
        this.orgsSel = new C3Selection(({
            items: this.orgsRes
        }))

        this.contactTypeSel = new C3Selection(({
            items: [
                {id: 'ins-company', name: 'Mútues'},
                /*                {id: 'Technician', name: 'Tècnics'}*/
            ],
            onSelectionChanged: () => {
                this.filterForm.$('type').set(this.contactTypeSel.selectedItem.id)
            }
        }))
        this.contactTypeSel.selectedIds = ['ins-company']

        this.contactSel = new C3Selection({
            items: this.contactsRes,
        })
        await this.fetch()


        autorun(() => {
            let type = this.filterForm.$('type').value;
            this.fetch()
        })

        autorun(async () => {
            this.rateSheetSel.itemsRef.current = this.rateSheetRes
        })
        autorun(async () => {
            this.orgsRes = this.appStore.orgs;
            this.orgsSel.itemsRef.current = this.orgsRes;
            if (this.orgsRes == null) return
            this.orgsSel.selectedIds = [this.orgsRes[0].id];
        })
        autorun(async () => {
            this.filterForm.$('orgs').set(this.orgsSel.selectedItem);
            await this.fetchRateSheet(this.contactTypeSel.selectedId)
        })
    }


    async fetch() {
        const contactType = this.filterForm.$(`type`).value;
        const query = contactType === 'ins-company' && this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (query === '') return

        this.contactsRes = await C3.instance.client.fetcher.fetch(`/contact/${contactType}`, {
            method: 'GET',
            query: [query, `inactive=${this.showInactive.toString()}`].join('&'),
        });
        this.contactSel.itemsRef.current = this.contactsRes;
        return this.contactsRes
    }

    async refresh() {
        await this.fetch();
        await this.fetchRateSheet(this.contactType)
    }


    async createContact() {
        const contactType = this.filterForm.$(`type`).value;
        let newContact = {}
        if (contactType === 'ins-company') {
            const orgsId = [{id: this.orgsSel.selectedId}]
            newContact = {name: 'nova mútua', orgs: orgsId, isActive: true, defaultPaymentForm: 'chipcard'}
        } else {
            newContact = {name: 'nou contacte', isActive: true}
        }
        await C3.instance.client.fetcher.fetch(`/contact/${contactType}`, {
            method: 'POST',
            body: newContact
        })
        await this.fetch()
    }

    async deleteContact(contactId) {
        const contactType = this.filterForm.$(`type`).value;
        await C3.instance.client.fetcher.fetch(`/contact/${contactType}/${contactId}`, {
            method: 'DELETE',
        });
        await this.fetch();
    }

    async deleteSelectedContact() {
        let selectedId = this.contactSel.selectedId;
        if (selectedId == null) return;
        await this.deleteContact(selectedId);
    }

    async saveContact(contact) {
        if (contact.rateSheet?.id) contact.rateSheet = {id: contact.rateSheet.id};
        const contactType = this.filterForm.$(`type`).value;
        await C3.instance.client.fetcher.fetch(`/contact/${contactType}`, {
            method: 'PATCH',
            body: contact
        })
    }

    async fetchRateSheet(contactType) {
        const query = contactType === 'ins-company' && this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        const rateSheet = await C3.instance.client.fetcher.fetch((`/rate-sheet/rate-active/${this.orgsSel.selectedId}`), {
            query: [query]
        })
        this.rateSheetRes = rateSheet;
    }

    // Excel import

    async excelImportClick() {
        this.excelDialogOpen = true
    }

    async processExcelImport(name, json) {
        console.table(json);
        json.splice(0, 1)
        let result = json.map(r => {
            let [id, subaccount, description, nif, address, city, province, postalCode, ...rest] = r as any
            // if (heurColCount == 2) {
            //     name = r[0]
            //     price = r[1]
            //     code = null
            // }


            return {
                id,
                subaccount,
                notes: description,
                nif,
                address,
                city,
                province,
                postalCode,
            }
        })
        result = result.filter((r, i) => {
            // if (!r.id || _.isEmpty(r.id)) return false
            return true
        })
        console.log(`result`, result);
        console.table(result)
        let res = await this.submitImport({
            name,
            contacts: result,
            orgId: this.orgsSel.selectedId
        })
        // numeral.locale('ca-es')
        console.log(`res`, res);
        await nextTimeout(100)
        await this.fetch()
        await nextTimeout(300)
        // if (res?.id)
        //     this.rateSheetsSel.selectId(res.id, true, true)

    }

    async submitImport(body) {
        return await C3.instance.client.fetcher.fetch(`/contact/import-contacts`, {
            method: 'POST',
            body
        })
    }
}
