import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {MeetupEventDetailTabs} from "./MeetupEventDetailTabs";
import {DetailHeader} from "./DetailHeader";

const Container = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
`
const DetailBodyContainer = styled.div`
  overflow: auto;
  height: 100%;
  min-height: 0px;
`
const DetailBodyInnerContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  height: auto;
  padding-right: 8px;
`

export interface MeetupEventDetailProps {

}

export const MeetupEventDetail = observer((props: MeetupEventDetailProps) => {
    return <Container>
        <DetailHeader type={'meetup-event'}/>
        <DetailBodyContainer>
            <DetailBodyInnerContainer>
                <MeetupEventDetailTabs/>
            </DetailBodyInnerContainer>
        </DetailBodyContainer>
    </Container>
})