import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const facilitySchema: C3SchemaInfo = {
    'facility': {
        fields: {
            id: {},
            name: {
                label: 'Nom',
            },
            address: {
                label: 'Adreça',
            },
            org: {
                type: 'organization'
            },
            waitingRooms: {
                type: 'waiting-room'
            }
        }
    }
}
