import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, ButtonGroup} from "@blueprintjs/core";
import {SCVStore} from "../../components/ScheduleView/SCVStore";
import moment from "moment";
import {isDev} from "../../application/utils/utils";

const SubheaderContainer = styled.div`
  display: grid;
  grid-gap: 3px;
  padding: 2px 8px;
  background-color: white;
  border-top: 1px solid lightblue;
  height: auto;
  grid-template-columns: auto 1fr auto;
`
const SHLeftContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;

`
const SHCenterContainer = styled.div`
  display: grid;
  place-content: center;
  font-size: 18px;
`
const SHRightContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
`
const ButtonContainer = styled.div`
    margin-left: 4px;
`

export interface SchedulePageSubheaderProps {
    store: SCVStore
}

export const SchedulePageSubheader = observer((props: SchedulePageSubheaderProps) => {
    let store = props.store
    if (store == null) return null
    return <SubheaderContainer>
        <SHLeftContainer>
            <ButtonGroup>
                <Button
                    onClick={() => store.backButton()}
                    icon={'chevron-backward'}/>
                <Button
                    onClick={() => store.forwardButton()}
                    icon={'chevron-forward'}/>
            </ButtonGroup>
            <Button active={store?.isToday} onClick={() => store.todayButton()}>Avui</Button>
        </SHLeftContainer>
        <SHCenterContainer>
            {moment(store?.startDate).format('dddd')} {store?.displayDate}
        </SHCenterContainer>
        <SHRightContainer>
            <ButtonContainer>
                <ButtonGroup>
                    <Button
                        onClick={() => store.showView('workWeekView')}>
                        Setmana
                    </Button>
                    <Button
                        onClick={() => store.showView('defaultView')}
                    >
                        Dia
                    </Button>
                </ButtonGroup>
            </ButtonContainer>
            <ButtonContainer>
            <Button
                active={store.showWeekend}
                onClick={() => store.changeShowWeekend()}>
                Mostrar Caps de Setmana
            </Button>
                </ButtonContainer>
            <ButtonContainer>
                {false && isDev() && <ButtonGroup>
                    {store.paymentMethodsMap.map((pm) => {
                        return <Button
                            active={store.filterForm.$('paymentMethod').value == pm.id}
                            onClick={(event) => {
                                event.preventDefault()
                                store.filterForm.$('paymentMethod').set(pm.id);
                                store.refetch()
                            }}
                        >{pm.name}</Button>
                    })}
                </ButtonGroup>}
                </ButtonContainer>
        </SHRightContainer>
    </SubheaderContainer>
})
