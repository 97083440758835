import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useFFVStore} from "../FeatureFileView";
import {C3AgGrid, MasterLayout} from "react-c4";
import {stdIntNumericColumn} from "../../../application/utils/utils";
import {CellValueChangedEvent} from "ag-grid-community";

const Container = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
`

export interface FFVFeaturesMasterProps {

}

export const FFVFeaturesMaster = observer((props: FFVFeaturesMasterProps) => {
    let store = useFFVStore()

    return <Container>
        <MasterLayout
            renderHeader={() => null}
            actions={[
                {
                    name: 'refresh',
                    iconName: 'refresh',
                    text: 'Refrescar',
                    callback: () => {
                        store.fetchFeatures()
                    }
                },
                {
                  name: 'create',
                  iconName: 'add',
                  text: 'Crear',
                  callback: () => {
                      store.createFeature()
                  }
                },
                {
                    name: 'save',
                    iconName: 'upload',
                    text: 'Save all',
                    callback: () => {
                        store.saveAll()
                    }
                },
                {
                    name: 'Delete',
                    iconName: 'trash',
                    text: 'Delete all features',
                    callback: () => {
                        store.deleteSelectedFeatures()
                    },
                },
            ]}
        >
            <C3AgGrid
                selection={store.featuresSel}
                columnDefs={[
                    {
                        field: '',
                        headerName: '',
                        width: 30,
                        checkboxSelection: true,
                        // suppressFilter: true,
                    },
                    {
                        field: 'id',
                        headerName: 'Id',
                        editable: false,
                        width: 60,
                        ...stdIntNumericColumn(),
                    },
                    {
                        field: 'name',
                        headerName: 'Feature Name',
                        editable: true,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 125,
                    },
                    {
                        field: 'description',
                        headerName: 'Feature Name',
                        editable: true,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        width: 125,
                    },
                ]}
                cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    let data = e.data;
                    store.saveFeatureLocally({
                        id: data.id,
                        [e.colDef.field]: e.newValue
                    })
                }}
            />
        </MasterLayout>
    </Container>
})
