import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {BusinessLineFVStore} from "../../files-views/BusinessLineFileView/BusinessLineFVStore";

const Container = styled.div`
    display: grid;
    
`

export interface BusinessLineFilePageHeaderProps {
    store: BusinessLineFVStore
}

export const BusinessLineFilePageHeader = observer((props: BusinessLineFilePageHeaderProps) => {
    return <Container>

    </Container>
})
