import {IATSStyleApp, C3, C3Selection, C3Form} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {computed, observable, autorun} from "mobx";

export class PFVStore {
    appStore: AppStore
    pageContext

    patientForm: C3Form

    @observable
    patientRes: any[]

    patientSel: C3Selection
    finalPatientSel: C3Selection

    @observable
    confirmMergeOpen = false
    @observable
    confirmMergeWithListOpen = false

    @computed
    get canMergePatient() {
        return this.patientSel.selectedIds.length > 0 && this.finalPatientSel.selectedId
    }

    viewConfig = 'patient'

    async init() {
        this.appStore = IATSStyleApp.instance.appStore


        this.patientSel = new C3Selection({
            items: [],
        })
        this.finalPatientSel = new C3Selection({
            items: [],
        })

        console.log("ITEMS patientSel", this.patientSel.items);
        this.patientForm = new C3Form({
            entityType: 'patient',
            autofetch: true,
            options: {}
        })

        this.fetchDuplicatePatients()

        autorun(() => {
            this.finalPatientSel.selectedItem
            let patientId = this.finalPatientSel.selectedId;
            if (patientId == null) {
                this.confirmMergeWithListOpen = false;
                this.confirmMergeOpen = false;
                return;
            }
            this.patientForm.setEntityId(patientId)
        })
        autorun(() => {
            let patientId = this.patientSel.selectedId;
            if (patientId == null) {
                this.confirmMergeWithListOpen = false;
                this.confirmMergeOpen = false;
                return;
            }
            this.patientForm.setEntityId(patientId)
        })

    }

    patientId

    async fetchDuplicatePatients() {
        let res = await C3.instance.client.fetcher.fetch(`/patient/duplicate-maintenance`, {})
        this.patientRes = res;
        this.patientSel.itemsRef.current = this.patientRes;
        this.finalPatientSel.itemsRef.current = this.patientRes;
    }

    async refresh() {
        await this.fetchDuplicatePatients()
    }

    async savePatient(patient) {
        if (patient.id == null) return
        await C3.instance.client.fetcher.fetch(`/patient/${patient.id}`, {
            method: 'PATCH',
            body: patient
        })
        await this.refresh()
    }

    async mergeSelectedPatients(isDuplicated = false) {
        let sourceIds = this.patientSel.selectedIds;
        let targetId = this.finalPatientSel.selectedId
        let duplicatedQ = `keepDuplicated=${isDuplicated}`
        if (sourceIds || targetId) {
            let response = await C3.instance.client.fetcher.fetch(`/patient/duplicate-maintenance/merge`, {
                method: 'POST',
                body: {
                    sourceIds,
                    targetId
                },
                query: [duplicatedQ].join('&'),
            })
            if (response.success) {
                IATSStyleApp.instance.toaster.show({
                    intent: "success",
                    icon: 'git-merge',
                    message: 'S\'ha realitzat la fusió de pacients correctament'
                })
            } else {
                IATSStyleApp.instance.toaster.show({
                    intent: "danger",
                    icon: 'error',
                    message: `No s'ha pogut realitzar la fusió de pacients`
                })
            }
        }
        await this.refresh()
    }
}
