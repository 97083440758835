import {C3, C3Selection, IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {autorun, observable, reaction} from "mobx";

export class ADVStore {
    appStore: AppStore
    pageContext

    deltasSel: C3Selection

    // Filter params
    @observable
    appEventIds

    @observable
    appEventRuleIds

    @observable
    meetupEventIds

    @observable
    meetupGroupIds

    @observable
    meetupSeriesIds

    @observable
    mode: 'deltas' | 'import/export' = 'deltas'

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.deltasSel = new C3Selection({})
        reaction(() => {
            return [this.appEventIds, this.appEventRuleIds, this.meetupEventIds, this.meetupGroupIds, this.meetupSeriesIds]
        }, () => this.fetchDeltas())
        this.refetchScreen()

        reaction(() => {
            return this.mode
        }, () => this.refetchScreen())
    }

    async fetchDeltas() {
        // Send filters to server
        let res = await C3.instance.client.fetcher.fetch('/event-delta', {})
        this.deltasSel.itemsRef.current = res
        return res
    }

    async fetchImportExport() {
        let res = await C3.instance.client.fetcher.fetch('/event-delta/import-export', {})
        this.deltasSel.itemsRef.current = res
        return res
    }

    async refetchScreen() {
        return this.mode == 'deltas' ? await this.fetchDeltas() : await this.fetchImportExport()
    }

    async recalculateDeltas() {
        await C3.instance.client.fetcher.fetch('/event-delta/recalculate', {})
        // this.deltasSel.itemsRef.current = res
        // return res
        await this.refetchScreen()
    }

    async resolveSelectedDeltas(direction: 'auto' | '->' | '<-' | 'C->' | 'C<-') {
        let cmds = this.deltasSel.selectedIds.map((id) => {
            return {id, direction}
        })
        await C3.instance.client.fetcher.fetch('/event-delta/resolve-deltas', {
            method: 'POST',
            body: {cmds},
        })
        // this.deltasSel.itemsRef.current = res
        // return res
        await this.refetchScreen()
    }

    async deleteAllDeltas() {
        await C3.instance.client.fetcher.fetch('/event-delta/delete-all', {
            method: 'DELETE'
        })
        await this.refetchScreen()
    }
}