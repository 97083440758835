import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ProcessTabLayout} from "../components/ProcessTabLayout";
import {Button} from "@blueprintjs/core";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {AttachmentProcessTabLayout} from "../components/AttachmentProcessTabLayout";

const Container = styled.div`
    display: grid;
    
`

export interface RequestTabProps {
    store: PatientOverviewStore

}

export const RequestTab = observer((props: RequestTabProps) => {
    return <AttachmentProcessTabLayout store={props.store} documentType={'Petició'}/>
})
