import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ULEStore} from "../../components/UserLogEntriesView/ULEStore";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    
`

export interface UserLogEntriesPageHeaderProps {
    store: ULEStore
}

export const UserLogEntriesPageHeader = observer((props: UserLogEntriesPageHeaderProps) => {
    return <Container>
        <Button
            icon={'refresh'}
            onClick={() => props.store.fetch()}>Refresca</Button>
    </Container>
})
