import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {observable} from "mobx";
import MobxReactForm from "mobx-react-form";

export class OrgFVStore {

    appStore: AppStore
    pageContext

    filterForm: MRF

    @observable
    resourceLocked = false

    @observable
    resourcePopoverCheck = false

    @observable
    async init() {
        this.appStore = IATSStyleApp.instance.appStore

        this.filterForm = new MobxReactForm({
            fields: [
                'resourceEditCode'
            ],
            values: {
                resourceEditCode: ''
            }
        })

        this.orgSel = new C3Selection({
            items: this.orgRes,
            onSelectionChanged: () => {
                this.fetchSelectedInvoiceCounters()
            }
        })
        this.orgSel.selectId(this.appStore.auth.loggedInUser?.org?.id)

        this.invoiceCounterSel = new C3Selection({
            items: this.invoiceCounterRes,

        })

        await this.fetch()
    }

    @observable.shallow
    orgRes

    orgSel: C3Selection

    @observable.shallow
    invoiceCounterRes


    invoiceCounterSel: C3Selection

    async fetch() {
        this.orgRes = await C3.instance.client.fetcher.fetch('/organization/all-organizations', {})
        this.orgSel.itemsRef.current = this.orgRes;
        return this.orgRes
    }
    async fetchSelectedInvoiceCounters(){
        await this.fetchInvoiceCounters(this.orgSel.selectedId)
    }
    async fetchInvoiceCounters(orgId) {
        this.invoiceCounterRes = await C3.instance.client.fetcher.fetch(`/organization/all-organizations/${orgId}/invoice-counter`, {})
        this.invoiceCounterSel.itemsRef.current = this.invoiceCounterRes;
        return this.invoiceCounterRes
    }

    async createOrg() {
        await C3.instance.client.fetcher.fetch('/organization/all-organizations', {
            method: 'POST',
            body: {name: 'Nova organització', isActive: true}
        })
        await this.fetch()
    }
    async deleteOrg(orgId) {
        await C3.instance.client.fetcher.fetch(`/organization/all-organizations/${orgId}`, {
            method: 'DELETE',
        })
        await this.fetch()
    }
    async deleteSelectedOrg() {
        let selectedId = this.orgSel.selectedId;
        if (selectedId == null) return
        await this.deleteOrg(selectedId)
    }
    async saveOrg(org) {
        await C3.instance.client.fetcher.fetch(`/organization/all-organizations`, {
            method: 'PATCH',
            body: org
        })
    }

    async getAllInvoiceCounter(invoiceId) {
        await C3.instance.client.fetcher.fetch(`/organization/${invoiceId}/invoice-counter`, {
            method: 'GET',
        })
        await this.fetchSelectedInvoiceCounters()
    }
    async createInvoiceCounter() {
        await C3.instance.client.fetcher.fetch('/organization/invoice-counter', {
            method: 'POST',
            body: {name: 'Nou comptador'}
        })
        await this.fetchSelectedInvoiceCounters()
    }
    async deleteInvoiceCounter(invoiceId) {
        await C3.instance.client.fetcher.fetch(`/organization/invoice-counter/${invoiceId}`, {
            method: 'DELETE',
        })
        await this.fetchSelectedInvoiceCounters()
    }
    async deleteSelectedInvoiceCounter() {
        let selectedId = this.invoiceCounterSel.selectedId;
        if (selectedId == null) return
        await this.deleteInvoiceCounter(selectedId)
    }
    async saveInvoiceCounter(invoiceCounter) {
        await C3.instance.client.fetcher.fetch(`/organization/invoice-counter`, {
            method: 'PATCH',
            body: invoiceCounter
        })
        await this.fetchSelectedInvoiceCounters()
    }
}
