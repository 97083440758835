import { C3Schema, C3SchemaInfo } from "react-c4/src/schema/C3Schema";

export const filesLocalSchema: C3SchemaInfo = {
    'files-local': {
        local: true,
        localData: [
            // Id corresponds to entity name
            {id: 'products', name: 'Productes', category: 'Facturació'},
            {id: 'banks', name: 'Bancs', category: 'Facturació'},
            {id: 'machines', name: 'Màquines', category: 'Recursos'},
        ]
    }
}
