import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSVVStore} from "../../../StandardVisitView";
import {InputGroup, Tag, TextArea} from "@blueprintjs/core";
import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";
import {bindWithAS, useAS} from "../../../../../application/utils/utils";
import {DateInputControl, getMomentFormatter } from "react-c4";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
  grid-gap: 4px;
  width: 180px;
`
const LinkedToContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  gap: 4px

`
const CreatedAtContainer = styled.div`
  color: #8a9ba8;
`

export interface SVVADInfoProps {
    document
}

export const SVVADInfo = observer((props: SVVADInfoProps) => {
    let store = useSVVStore()
    let id = props.document?.id;
    let as = useAS((field, e) => {
        let fieldName = field.name;
        store.updateDocument({id: id, [fieldName]: field.value})
    }, 800)
    if (id == null) return <div>No doc id</div>
    let docField = store.documentsSel.getFieldById(id)
    if (docField == null) return <div>No doc field</div>
    return <Container>
        <InputGroup disabled defaultValue={props.document?.name}/>
        <DateInputControl field={docField.$('date')} as={as} fill={true} placeholder={'Data document'}/>

        <TextArea {...bindWithAS(docField.$('description'), as)} placeholder={'Comentari'}/>
        <CreatedAtContainer>
            Adjuntat el: {moment(docField.$('createdAt').value).format('DD/MM/YYYY')}
        </CreatedAtContainer>
        <LinkedToContainer>
            {_.sortBy(props.document.visits, v => v.id).map((v, i) => {
                let onRemoveVisit: () => void;
                onRemoveVisit = () => {
                    store.selectVisit(v.id, false, false, false, true)

                    // let exisitngIds = store.visitsSel.selectIds(props.exisitngIds.map(id => ({id})));

                };

                return <Tag key={'V' + v.id} intent={"primary"} onRemove={onRemoveVisit}>V{v.visitNumber}</Tag>
            })}
            {_.sortBy(props.document.visitLines, vl => vl.id).map(vl => {
                let onRemoveVisitLine: () => void;
                onRemoveVisitLine = () => {
                    store.visitLinesSel.selectId(vl.id, false, false, false, true)
                };
                return <Tag key={'VL' + vl.id}
                            intent={"none"}
                            onRemove={onRemoveVisitLine}

                >V{vl.visitLineNumber}</Tag>
            })}
        </LinkedToContainer>
    </Container>
})
