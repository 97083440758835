import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SRVRulesGrid} from "./SRVRulesGrid";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {useSRVStore} from "../ScheduleRulesView";
import moment from "moment";
import {DateInput} from "@blueprintjs/datetime";
import ErrorBoundary from "react-c4/src/app-shells/IATSStyle/page-templates/ErrorBoundary";
import {getMomentFormatter} from "react-c4";
import LU from "react-day-picker/src/addons/MomentLocaleUtils.js";


const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;
    padding: 8px;
    grid-gap: 8px;
`
const HeaderContainer = styled.div`
    display: grid;
    min-height: 100px;
    grid-gap: 8px;
    align-content: start;
    align-items: start;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;

`
const HeaderFirstLineContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-content: start;
  grid-auto-flow: column;
`
const HeaderBodyContainer = styled.div`
  display: grid;
  
`
const RepeatContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 8px;
  place-content: start;
  
  
`

export interface SRVLeftPanelProps {

}

export const SRVLeftPanel = observer((props: SRVLeftPanelProps) => {
    let store = useSRVStore()
    // V1: Mode bloquejar bloc només
    // startDate, endDate, title
    // applicableFrom = startDate
    // applicableUntil = endDate
    // Do you undrerestand?
    let active = store.rulesSel.selectedItem?.r;
    let repeatComponentMap = {
        'weekly': <React.Fragment>
            <ButtonGroup>
                {store.rulesSel.selectedItem?.r ? store.daysOfWeek.map((d) => {
                    let isSelected = store.daysOfWeekSel.isSelected(d.id);
                    return <Button key={d.id}
                                   active={isSelected}
                                   onClick={() => store.daysOfWeekSel.selectId(`${d.id}`, !isSelected, false)}>{d.name}</Button>
                }) : <div/>}
            </ButtonGroup>
        </React.Fragment>,
        'cron': <React.Fragment>
            // cron input
        </React.Fragment>
    }
    return <Container>
        <HeaderContainer>
            <HeaderFirstLineContainer>

                {/*<div>From To (336 dies, 4 regles)</div>*/}
                {store.scvStore && <ErrorBoundary>
                  <DateInput  {...getMomentFormatter('DD/MM/YYYY')}
                              locale={'ca'}
                              todayButtonText={'Avui'}
                              localeUtils={LU}
                              minDate={moment().subtract(7, 'years').toDate()}
                              maxDate={moment().add(2, 'years').toDate()}
                              value={store.scvStore.startDate}
                              {...{
                                  defaultValue: new Date(), onChange(v) {
                                      return store.scvStore.goToDate(v);
                                  }
                              }} />
                  <DateInput  {...getMomentFormatter('DD/MM/YYYY')}
                              locale={'ca'}
                              todayButtonText={'Avui'}
                              localeUtils={LU}
                              minDate={moment().subtract(7, 'years').toDate()}
                              maxDate={moment().add(2, 'years').toDate()}
                              value={store.scvStore.endDate}
                              disabled={true}
                              {...{
                                  defaultValue: new Date(), onChange(v) {
                                  }
                              }} />
                </ErrorBoundary>}
                <div>
                    <ButtonGroup>
                        <Button onClick={() => store.mode = 'block'} active={store.mode == 'block'}>Block</Button>
                        <Button disabled onClick={() => store.mode = 'bounds'}
                                active={store.mode == 'bounds'}>Bounds</Button>
                    </ButtonGroup>
                </div>
                {store.mode == 'block' ? <RepeatContainer>
                    <Button icon={'repeat'} onClick={() => {
                        let rule = store.rulesSel.selectedItem;
                        let isRepeat = rule?.r;
                        store.setRepeat(rule, !isRepeat)
                    }} active={active}>Repeteix</Button>
                </RepeatContainer> : ''}

            </HeaderFirstLineContainer>
            <HeaderBodyContainer>
                {repeatComponentMap['weekly']}
            </HeaderBodyContainer>
        </HeaderContainer>
        <SRVRulesGrid/>
    </Container>
})
