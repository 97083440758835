import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useMIVStore} from "../../ManualInvoicingView";
import {stdIntNumericColumn, stdNumericColumn} from "../../../../application/utils/utils";
import {CellValueChangedEvent} from "ag-grid-community";

const Container = styled.div`
  display: grid;
`

export interface InvoiceLineGridProps {

}

export const InvoiceLineGrid = observer((props: InvoiceLineGridProps) => {
    let store = useMIVStore()
    return <Container>
        <MasterLayout
            renderHeader={() => {
                return null
            }}
            actions={
                [
                    {
                        name: 'document',
                        iconName: 'add',
                        text: 'Afegir línia',
                        enabled: store.canEditInvoiceLine,
                        callback: () => {
                            store.createInvoiceLine()
                        }
                    },

                    {
                        name: 'showActives',
                        iconName: 'trash',
                        enabled: (!!store.invoiceLinesSel.selectedId && store.canEditInvoiceLine),
                        text: 'Esborrar línia de factura',
                        callback: () => {
                            if (store.invoiceLinesSel.selectedIds == null) return
                            store.deleteSelectedInvoiceLine()
                        }
                    },
                    {
                        name: 'document',
                        iconName: 'percentage',
                        text: 'Afegir IVA',
                        enabled: store.canEditInvoiceLine,
                        callback: () => {
                            store.addIVALine()
                        }
                    },
                ]}
            body={<C3AgGrid
                selection={store.invoiceLinesSel}
                defaultColDef={{}}
                floatingFilter={false}
                columnDefs={[
                    {
                        field: 'id',
                        headerName: 'Identificador',
                        width: 120,
                        ...stdIntNumericColumn(),
                    },
                    {
                        field: 'text',
                        headerName: 'Concepte / Prova',
                        editable: true,
                        width: 480,
                    },
                    {
                        field: 'price',
                        headerName: 'Preu',
                        width: 180,
                        editable: true,
                        ...stdNumericColumn()
                    },
                ]}
                rowData={store.invoiceLinesRes}
                cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                    let data = e.data;
                    console.log(`data`, data);
                    let newVar = await store.saveInvoiceLine({
                        id: data.id,
                        [e.colDef.field]: e.newValue
                    })
                    await store.fetchInvoiceWithLines(store.invoicesSel?.selectedId)
                    return newVar
                }}
                    />
                }
            />
                </Container>
                })
