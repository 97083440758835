import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useDCSStore} from "../../DailyCheckoutSheetView";
import {
    formatFormalName,
    formatFullName,
    paymentFormMap,
    stdDateColumn,
    stdNumericColumn
} from "../../../../application/utils/utils";
import {RowNode} from "ag-grid-community";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface DCSGridProps {

}

export const DCSGrid = observer((props: DCSGridProps) => {
    let store = useDCSStore()
    let type = store.filterForm.$('type').value;
    return <Container>
        <MasterLayout
            actions={[]}
            renderHeader={() => null}
            body={<C3AgGrid
                selection={store.sel}
                onGridReady={({api}) => store.gridApi = api}
                defaultColDef={{
                    editable: false,
                    // filter: 'agTextColumnFilter'
                    filter: false,
                    floatingFilter: false,
                    resizable: true,
                    sortable: true,
                    suppressMenu: true,
                    width: 100
                }}
                columnDefs={[
                    {
                        field: 'invNumText',
                        headerName: 'N. Factura',
                        width: 130,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'invoiceDate',
                        headerName: 'D. Factura',
                        ...stdDateColumn(),
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'nif',
                        headerName: 'DNI/CIF',
                        width: 85,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'fullName',
                        headerName: 'Nom Complet',
                        width: 170,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                    {
                        field: 'amount',
                        headerName: 'Pagat',
                        width: 60,
                        ...stdNumericColumn(),
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    }, {
                        field: 'paymentObject',
                        headerName: {cash: 'C. Petita', cc: 'Datàfon', transfer: 'C. Bancari'}[type || 'cc'],
                        valueFormatter({value}: { value: any }): string {
                            if (type == 'cc')
                                return 'DT-01'
                            else
                                return null
                        },
                        width: 80
                    }, {
                        field: 'visitLineNumbers',
                        headerName: 'Línies visita',
                        width: 190,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    }, {
                        field: 'texts',
                        headerName: 'Text línies factura',
                        width: 250,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    }, /*{
                        field: 'invTotal',
                        headerName: 'Total Fra',
                        width: 60,
                        ...stdNumericColumn()
                    },*/ {
                        field: 'paymentDate',
                        headerName: 'Data pagament',
                        ...stdDateColumn(),
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    }, {
                        field: 'pf',
                        headerName: 'F. Pagament',
                        valueFormatter({value}: { value: any }): string {
                            return paymentFormMap[value]
                        },
                        width: 80,
                    },
                    {
                        field: 'orgName',
                        headerName: 'Organització',
                        width: 110,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                    },
                ]}
                onRowDoubleClicked={({data, event}) => {
                    setTimeout(() => {
                        if (data.patientId == null) return
                        if (store.pageContext) {
                            console.log('data.invoiceId', data.invoiceId)
                            store.pageContext.navigate({
                                to: 'bookVisitPage', args: {
                                    patientId: data.patientId,
                                    selectedInvoiceIds: [data.invoiceId],
                                    //selectedVisitLineIds: [data.vId]
                                },
                                inNewTab: (event as any).ctrlKey, focusNewTab: true
                            })
                        }
                    })
                }}
                processCellCallback={(params) => {
                    let data = (params.node as RowNode).data
                    return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                }}
                getContextMenuItems={(params) => {
                    // let fileName = store.fr
                    var result = [
                        {
                            // custom item
                            name: 'Exporta a Excel',

                            action: function () {
                                params.api.exportDataAsExcel({
                                    fileName: 'Llistat-Caixa',
                                    processCellCallback: (params) => {
                                        let data = (params.node as RowNode).data

                                        if (params.column.getColId() == 'invoiceDate') {
                                            return stdDateColumn().valueFormatter({value: data.invoiceDate})
                                        }
                                        if (params.column.getColId() == 'paymentDate') {
                                            return stdDateColumn().valueFormatter({value: data.paymentDate})
                                        }
                                        if (params.column.getColId() == 'pf') {
                                            let value
                                            if (params.value==='cc'){
                                                value = 'Targeta'
                                            } else if (params.value === 'cash') {
                                                value = 'Efectiu'
                                            }
                                            return value
                                        }
                                        return params.value
                                    }
                                })
                            },
                        }
                    ]
                    return result
                }}
            />}
        />

    </Container>
})
