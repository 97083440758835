import React, {useState, useEffect, useContext, useRef} from "react";
import styled, {css} from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSCVStore} from "../../ScheduleView";
import {
    Button,
    Dialog,
    Icon,
    InputGroup,
    Popover,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Tag,
    TextArea
} from "@blueprintjs/core";
import {DateInput} from "@blueprintjs/datetime";
import {C3AgGrid, DateInputControl, TimeInputControl} from "react-c4";
import {rule} from "postcss";
import {
    formatFullName, isDev,
    paymentFormMap,
    stdDateColumn,
    stdIntNumericColumn,
    stdNumericColumn
} from "../../../../application/utils/utils";
import {ICellRendererParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import * as moment from "moment";
import {MeetupEventDetailTabs} from "./MeetupEventDetailTabs";
import {DetailHeader} from "./DetailHeader";

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  justify-content: stretch;
  align-content: start;
  padding: 8px;
  //overflow: scroll;
  height: 100%;

`
const InputsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`
const TimeRangeContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: start;
  align-items: center;

`

const DateRangeContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: start;
  align-items: center;

`
const ButtonsContainer = styled.div`
  display: grid;
  //grid-template-columns: auto auto;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
`

const GridContainer = styled.div`
  display: grid;
  height: 300px;
`
const NameContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
  grid-template-columns: auto auto 1fr;
`
const InnerNameContainer = styled.div`
  text-transform: uppercase;
`
const HeaderRightContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0px;
  overflow: hidden;
  font-size: 13px;
`
const HeaderButtonsContainer = styled.div`
  display: contents;
`

const DescriptionContainer = styled.div`
  display: grid;
  min-height: 300px;
`
const AttendeeDetailContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: start;
  justify-content: start;
`
const DialogContainer = styled.div`
  display: grid;
  padding: 8px;
  grid-template-rows: 1fr auto;

`
const DialogButtonsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
  justify-self: end;
`
const RuleInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  align-items: center;
`
const DeleteButtonsPopoverContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 8px;
  grid-gap: 8px;
  justify-content: center;
`
const DetailBodyContainer = styled.div`
  overflow: auto;
  height: 100%;
  min-height: 0px;
`
const DetailBodyInnerContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  height: auto;
  padding-right: 8px;
`

export interface EventDetailProps {

}

export const EventDetail = observer((props: EventDetailProps) => {
    let store = useSCVStore()

    let $name = store.eventForm.$('name');
    let $description = store.eventForm.$('description');
    let $expr = store.eventForm.$('ruleExpr');
    // let $applicableFrom = store.eventForm.$('ruleApplicableFrom');
    // let $applicableUntil = store.eventForm.$('ruleApplicableUntil');
    let $startDate = store.eventForm.$('startDate');
    let $endDate = store.eventForm.$('endDate');
    let $location = store.eventForm.$('location');
    let $howToFindUs = store.eventForm.$('howToFindUs');
    let $recurrenceEditMode = store.eventForm.$('recurrenceEditMode');


    let inputRef = useRef(null)
    let attachFile = () => {
        let input = inputRef.current
        if (input != null) {
            input.click();
        }
    }

    return <Container>
        {/*<Button active={!store.isDetailCollapsed} onClick={() => store.isDetailCollapsed = !store.isDetailCollapsed}*/}
        {/*        icon={store.isDetailCollapsed ? 'chevron-down' : 'chevron-right'}>{store.isDetailCollapsed ? 'Collapse' : 'Collapsed'}</Button>*/}
        <DetailHeader type={'event'}/>
        <DetailBodyContainer>
            <DetailBodyInnerContainer>
                {!store.basicInfoCollapsed && <div>
                    <InputsContainer>
                        <InputGroup large {...$name.bind()}
                                    placeholder={'Títol'} leftIcon={'edit'}
                        />
                        <DescriptionContainer>
                            <TextArea large {...$description.bind()}
                                      placeholder={'Descripció'}
                            />
                        </DescriptionContainer>
                        <InputGroup large {...$location.bind()}
                                    placeholder={'Localització'} leftIcon={'map-marker'}
                        />
                        <InputGroup large {...$howToFindUs.bind()}
                                    placeholder={'How to find us'} leftIcon={'comment'}
                        />
                        <DateRangeContainer>
                            <DateInputControl
                                style={{border: '1px solid gray', padding: 4, borderRadius: 3}}
                                field={$startDate}
                                // as={as}
                                // locked={!store.isMainMode}
                            /> -
                            <DateInputControl
                                style={{border: '1px solid gray', padding: 4, borderRadius: 3}}
                                field={$endDate}
                                // as={as}
                                // locked={!store.isMainMode}
                            />
                        </DateRangeContainer>
                        <TimeRangeContainer>
                            <TimeInputControl
                                style={{border: '1px solid gray', padding: 4, borderRadius: 3}}
                                field={$startDate}
                                // as={as}
                                // locked={!store.isMainMode}
                            /> -
                            <TimeInputControl
                                style={{border: '1px solid gray', padding: 4, borderRadius: 3}}
                                field={$endDate}
                                // as={as}
                                // locked={!store.isMainMode}
                            />
                        </TimeRangeContainer>
                    </InputsContainer>

                    {$expr.value || store.showRule ? <div>
                        <RuleInputContainer>
                            <InputGroup large {...$expr.bind()}
                                        placeholder={'Rule'} leftIcon={'timeline-events'}
                            />
                            {store.eventSel.selectedItem?.isDirty && <Tag intent={'warning'}>Dirty</Tag>}
                        </RuleInputContainer>
                        <div>Rule: {store.rRuleExpr}</div>
                        <DateRangeContainer>
                            {/*Del<DateInputControl field={$applicableFrom}/>*/}
                            {/*al<DateInputControl field={$applicableUntil}/>*/}
                        </DateRangeContainer>
                    </div> : <Button icon={'timeline-events'} onClick={() => store.showRule = true}>Add rule</Button>}


                    <ButtonsContainer>
                        <Button disabled={!store.canSave} onClick={() => {
                            if ($expr.value) store.isUpdateRecurringOpen = true
                            else store.saveEvent({...store.eventForm.values(), id: store.calendarEventsSelSelectedId})
                        }}>Save</Button>
                        {/*<Button onClick={() => store.ensureEvent(store.eventSel.selectedId)}>Ensure</Button>*/}
                        <Popover isOpen={store.isDeletePopoverOpen}
                                 content={<DeleteButtonsPopoverContainer>
                                     <Button onClick={() => store.deleteEvent(store.calendarEventsSelSelectedId)} icon={'trash'}
                                             intent={'danger'}>Delete this event</Button>
                                     <Button onClick={() => store.deleteFollowingEvents(store.calendarEventsSelSelectedId)}
                                             icon={'trash'} intent={'danger'}>Delete following events of rule</Button>
                                 </DeleteButtonsPopoverContainer>}>
                            <Button icon={'trash'} onClick={() => {
                                store.isDeletePopoverOpen = !store.isDeletePopoverOpen
                            }}>Delete</Button>
                        </Popover>


                        {/*<Button icon={'cross'} onClick={() => store.exitAttachDocuments()}>Tanca</Button>*/}
                        <Button icon={'folder-open'} loading={store.isUploadingImage} onClick={() => attachFile()}>Afegir imatge</Button>
                        <input id="myInput"
                               type="file"
                               ref={inputRef}
                               style={{display: 'none'}}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   store.uploadImageForEvent(e.currentTarget.files)
                               }}
                               multiple
                        />

                        <Button onClick={() => store.fetchMeetupRSVPs(store.calendarEventsSelSelectedId)}>Fetch Meetup
                            Attendees</Button>
                        <Button onClick={() => store.clearMeetupAttendees(store.eventSel.selectedId)}>Clear Meetup
                            Attendees</Button>
                      <Button onClick={(e) => store.openAllMeetupEvents(store.eventSel.selectedId, !e.shiftKey)}>Open Meetup Events</Button>
                    </ButtonsContainer>
                </div>}

                <div>{JSON.stringify(store.eventAttendees?.attendees?.length)} {store.eventSel?.selectedItem && `#${store.calendarEventsSelSelectedId}`}</div>


                <img width={300} src={store.eventForm.$('img').value}/>


                <GridContainer>
                    <C3AgGrid
                        selection={store.meetupEventSel}
                        // rowData={store.eventAttendees}
                        // onGridReady={({api}) => store.gridApi = api}
                        defaultColDef={{
                            editable: false,
                            // filter: 'agTextColumnFilter'
                            filter: false,
                            floatingFilter: false,
                            resizable: true,
                            sortable: true,
                            suppressMenu: true,
                            width: 100
                        }}
                        columnDefs={[
                            {
                                field: '',
                                headerName: '',
                                width: 30,
                                checkboxSelection: true,
                                // suppressFilter: true,
                            },
                            {
                                field: 'urlName',
                                headerName: 'Url name',
                                width: 80,
                            },
                            {
                                field: 'attendance',
                                headerName: 'Guests',
                                ...stdIntNumericColumn(),
                                width: 40,
                            },
                            {
                                field: 'attendeeCount',
                                headerName: 'Guests',
                                ...stdIntNumericColumn(),
                                width: 40,
                            },
                            {
                                field: 'name',
                                headerName: 'Meetup Event Name',
                                width: 400,
                            },
                            {
                                field: 'start',
                                headerName: 'Start',
                                width: 400,
                                ...stdDateColumn(),
                            },
                        ]}
                        onRowDoubleClicked={({data, event}) => {
                            window.open(`https://www.meetup.com/${data.urlName}/events/${data.meetupId}/`, "_blank");
                        }}
                        processCellCallback={(params) => {
                            let data = (params.node as RowNode).data
                            return formatFullName({
                                name: data.medName,
                                surname1: data.medSurname1,
                                surname2: data.medSurname1
                            })
                        }}
                        getContextMenuItems={(params) => {
                            // let fileName = store.fr
                            let result = []
                            return result
                        }}
                    />
                </GridContainer>

                {store.meetupEventSel.selectedIds?.length == 1 && <MeetupEventDetailTabs/>}

                <AttendeeDetailContainer>
                    <img onClick={() => store.imageEnlarged = !store.imageEnlarged}
                         width={store.imageEnlarged ? 30 : null}
                         src={store.meetupEventAttendeesSel.selectedItem?.photo}/>
                    {store.meetupEventAttendeesSel.selectedItem?.name}
                </AttendeeDetailContainer>

                <Dialog onClose={() => store.isUpdateRecurringOpen = false} isOpen={store.isUpdateRecurringOpen}>
                    <DialogContainer>

                        <RadioGroup
                            // onChange={(event) => event.currentTarget.value}
                            selectedValue={$recurrenceEditMode.value || null}
                            {...$recurrenceEditMode.bind()}
                            label="Updating recurring event "
                        >
                            <Radio label="Following" value="following"/>
                            <Radio label="Only this event" value="only"/>
                        </RadioGroup>
                        <DialogButtonsContainer>
                            <Button onClick={() => store.isUpdateRecurringOpen = false}>Cancel</Button>
                            <Button intent={'primary'} onClick={() => {
                                store.saveEvent({...store.eventForm.values(), id: store.calendarEventsSelSelectedId})
                                store.isUpdateRecurringOpen = false
                            }}>Apply</Button>
                        </DialogButtonsContainer>

                    </DialogContainer>
                </Dialog>
            </DetailBodyInnerContainer>
        </DetailBodyContainer>
    </Container>
})
