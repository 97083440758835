import {IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";

export class IEVStore {
    appStore: AppStore

    pageContext

    async init() {
        this.appStore = IATSStyleApp.instance.appStore

    }

}
