import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SRVStore} from "./SRVStore";
import {useC3Store} from "react-c4";
import {SRVMain} from "./SRVMain/SRVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const SRVContext = React.createContext(null as SRVStore)
export const useSRVStore = () => useContext(SRVContext)

export interface ScheduleRulesViewProps {
    pageContext?
    onStoreReady: (s: SRVStore) => any
}

export const ScheduleRulesView = observer((props: ScheduleRulesViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new SRVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <SRVContext.Provider value={store}>
        <Container>
            <SRVMain pageContext={props.pageContext}/>
        </Container>
    </SRVContext.Provider>
})
