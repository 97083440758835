import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ULEStore} from "./ULEStore";
import {useC3Store} from "react-c4";
import {ULEMain} from "./ULEMain/ULEMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const ULEContext = React.createContext(null as ULEStore)
export const useULEStore = () => useContext(ULEContext)

export interface UserLogEntriesViewProps {
    pageContext?
    onStoreReady: (s: ULEStore) => any
}

export const UserLogEntriesView = observer((props: UserLogEntriesViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new ULEStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <ULEContext.Provider value={store}>
        <Container>
            <ULEMain pageContext={props.pageContext}/>
        </Container>
    </ULEContext.Provider>
})
