import React, {useState, useEffect, useContext, useRef, useLayoutEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSCVStore} from "../../ScheduleView";
import json5 from "json5";
import {Button} from "@blueprintjs/core";
import {IgStorySchedule} from "./ScheduleGen/IgStorySchedule";

const Container = styled.div`
  display: grid;
  padding: 8px;
  height: 80vh;
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  
`
const ScrollContainer = styled.div`
    overflow: auto;
  height: 100%;
`

export interface WeeklyScheduleDetailProps {

}

export const WeeklyScheduleDetail = observer((props: WeeklyScheduleDetailProps) => {
    let store = useSCVStore();
    let eventsByDow = store.eventsByDow;
    // flat events
    let ref = useRef<HTMLDivElement>();
    useLayoutEffect(() => {
        if (ref.current) {
            // center scrollbar vertically
            ref.current.scrollTop = ref.current.scrollHeight / 2 - ref.current.clientHeight / 2

        }
    })
    // [dow][timeOfDay_0_1_2_3]
    return <Container>
        <ButtonsContainer>
            <Button onClick={() => store.fetchEventsByDow()}>Refetch</Button>
            <Button onClick={() => store.downloadScheduleImage()}>Download</Button>
        </ButtonsContainer>
        <ScrollContainer ref={ref}>
            <IgStorySchedule flatEvents={eventsByDow} store={store} />
        </ScrollContainer>

    </Container>
})
