import styled from 'styled-components';
import React from 'react';
import {isDemo, logoMap, logoName} from "../../application/utils/utils";
import * as _ from "lodash";
import {logoImgs} from "../img/imgExports";

const Container = styled.div`
    padding: 3px;
    display: grid;
    place-items: center;
    
    
    
`;


const img = require('../img/' + logoName);
const imgEspuche = require('../img/' + 'logoEspuche.png');

console.log(`logoImgs`, logoImgs);


// TODO Use guirado logo when org name is guirado
const InvoiceLogo = (props: {org?}) => {

    let exists = true;

    //console.log("IMAGEN ", image)
    let usedImg = img;
    let idEspuche = 8;
    let orgId = props.org.id;

    usedImg = logoMap[orgId]
    if(!isDemo() && orgId == idEspuche) usedImg = imgEspuche;
    return (
        <Container>
            {exists ? <img src={usedImg} alt={"LOGO"} width={145}/> : "LOGO"}
        </Container>
    );
};

export default InvoiceLogo;
