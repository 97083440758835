import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";
import moment from 'moment'
import {sexShortStr} from "../../../../utils/sexOptions";

export const patientSchema: C3SchemaInfo = {
    'patient': {
        fields: {
            id: {},
            historyNumber: {
                label: 'N.H.',
                width: 73
            },
            birthDate: {
                label: 'Data naix*.',
                rules: 'required',
                valueFormatter: ({value}) => {
                    if (value == null) return '--'
                    return moment(value).format('DD/MM/YYYY')
                },
                default: null,
                initial: null,
                filter: false,
            },
            nif: {
                label: 'NIF'
            },
            sex: {
                label: 'H/D*',
                valueFormatter: ({value}) => {
                    return sexShortStr[value] || '-'
                },
                width: 49,
                filter: false,
                rules: 'required',
            },
            // isFromWebsite: {
            //     label: 'Web'
            // },

            surname1: {
                label: 'Cognom 1*',
                rules: 'required|string|between:2,15',
            },
            surname2: {
                label: 'Cognom 2',
            },
            name: {
                label: 'Nom*',
                rules: 'required|string|between:2,15',
            },
            phone2: {
                label: 'Mòbil*.'
            },
            mobile: {
                label: 'Tel*.',
            },
            email: {
                label: 'Email',
                width: 159
            },

            cip: {
                label: 'CIP'
            },

            language: {
                label: 'Idioma',
                width: 50,
                filter: false
            },
            status: {
                label: 'Estat'
            },

            postalCode: {
                label: 'Codi postal'
            },
            city: {
                label: 'Ciutat'
            },
            address: {
                label: 'Adreça'
            },
            state: {
                label: 'País'
            },
            countryCode: {
                label: 'Codi país'
            },
            insCompanies: {
                type: 'contact',
                multivalued: true,
                // Recursive
            },
            visits: {
                type: 'visit',
                multivalued: true
            },
            documents: {
                type: 'document',
                multivalued: true,
            },
            invoices: {
                type: 'invoice',
                multivalued: true,
            },
            orgs: {
                multivalued: true
            },
            comments:  {
                label: 'Comentaris'
            },
            allergies:  {
                label: 'Comentaris'
            },
            // TODO Add patient fields
            defaultPaymentMethod: {
                label: 'Mètode P.D.',
                filter: false
            },
            defaultInsCompany: {},
            isDuplicate:{}
        }
    } as any
}
