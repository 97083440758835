import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {action, autorun, computed, observable, reaction} from "mobx";
import {GridApi} from "ag-grid-community";
import moment from "moment";
import MobxReactForm from "mobx-react-form";
import * as _ from "lodash";
import {formatFormalName, formatFullName} from "../../application/utils/utils";
import {auto} from "@popperjs/core";


export class AVVStore {
    appStore: AppStore
    pageContext

    filterForm: MRF

    @observable
    res: any

    @observable
    statsRes: any = {
        dayTotals: []
    }

    sel: C3Selection
    gridApi: GridApi

    typeSel: C3Selection

    dateTypeSel: C3Selection

    refMedicalSel: C3Selection

    refExtCenterSel: C3Selection

    chartSel: C3Selection
    @observable
    showHidden: boolean = false


    @observable
    /**
     * If false, filters are hidden and not sent to the server
     */
    filtersEnabled = false

    @observable
    mode: 'full' | 'integrated'

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.sel = new C3Selection({})
        this.typeSel = new C3Selection({
            items: [
                {id: 'all', name: 'Tots'},
                {id: 'chipcard', name: 'Chipcard'},
                {id: 'manual', name: 'Manual'},
                {id: 'unassigned', name: 'No Assignat'},
            ],
            onSelectionChanged: () => {
                this.filterForm.$('type').set(this.typeSel.selectedId)
            },
        })
        this.dateTypeSel = new C3Selection({
            items: [
                {id: 'appointment', name: 'Visita'},
                {id: 'invoicing', name: 'Prestació'},
            ],
            onSelectionChanged: () => {
                this.filterForm.$('dateType').set(this.dateTypeSel.selectedId)
            }
        })
        this.refMedicalSel = new C3Selection({
            items: []
        })
        this.refExtCenterSel = new C3Selection({
            items: []
        })

        this.typeSel.selectedIds = ['all']
        this.dateTypeSel.selectedIds = ['appointment']

        this.filterForm = new MobxReactForm({
            fields: [
                'startDate',
                'endDate',
                'orgs',
                'type',
                'refMedical',
                'refExtCenter',
                'dateType'
            ],
            // initials,
            // defaults,
            values: {
                startDate: new Date(),
                endDate: new Date(),
                orgs: [],
                type: 'all',
                refMedical: [],
                refExtCenter: [],
                dateType: 'appointment'
            },
            // labels,
            // placeholders,
            // rules,
            // extra,
            hooks: {}
        })
        this.filterForm.$('orgs').set(this.appStore.orgsSel.selectedItem);

        reaction(() => this.filtersEnabled, async () => {
            if (this.filtersEnabled) {
                await this.fetchAllMedicals();
            }
        }, {delay: 5, name: 'filterEnable'})

        // await this.fetch()
        reaction(() => {
            let startDate = this.filterForm.$('startDate').value;
            let endDate = this.filterForm.$('endDate').value;
            let type = this.filterForm.$('type').value;
            let dateType = this.filterForm.$('dateType').value;
            let orgs = this.filterForm.$('orgs').value;
            return {startDate, endDate, type, dateType, orgs}
        }, () => {
            this.fetch();
            this.fetchStats();
        }, {delay: 5, name: 'filterChange'})


        // autorun(() => {
        //     this.filterForm.$('orgs').set(this.appStore.orgsSel.selectedItem);
        //     this.fetch()
        // })
        reaction(() => {
            let orgs = this.filterForm.$('orgs').value;
            let selectedCenter = this.filterForm.$('refExtCenter').value.id;
            return {orgs, selectedCenter}
        }, () => {
            let selectedMedical = this.filterForm.$('refMedical').set([]);
        }, {delay: 5, name: 'filterChangeMedicalsCenters'})

        reaction(() => {
            let selectedCenter = this.filterForm.$('refExtCenter').value.id;
            let selectedMedical = this.filterForm.$('refMedical').value.id;
            return {selectedCenter, selectedMedical}
        }, () => {
            this.fetch()
        }, {delay: 5, name: 'centerMedicalFetch'})


        await this.fetch()
        await this.fetchStats()

    }

    async showHiddenVisits() {
        this.showHidden = !this.showHidden
        await this.fetch()
    }

    async fetch() {
        let startDate = moment(this.filterForm.$('startDate').value).toDate();
        let endDate = moment(this.filterForm.$('endDate').value || startDate).toDate();
        let orgs = this.appStore.orgsSel.selectedId ? `orgs=${this.appStore.orgsSel.selectedId}` : '';
        let extCenterSel = this.filterForm.$('refExtCenter').value.id ? `refExtCenterIds=${this.filterForm.$('refExtCenter').value.id}` : null;
        let medicalSel = this.filterForm.$('refMedical').value.id ? `refMedicalIds=${this.filterForm.$('refMedical').value.id}` : null;
        if (orgs == '') return null
        let res = await C3.instance.client.fetcher.fetch(`/all-visits-view/state`, {
            method: 'GET',
            query: [
                `start=${startDate.toISOString()}`,
                `end=${endDate.toISOString()}`,
                `type=${this.filterForm.$('type').value}`,
                `usingDate=${this.filterForm.$('dateType').value}`,
                `showHidden=${this.showHidden}`,
                orgs,
                extCenterSel,
                medicalSel
            ].join('&'),
        });
        res.map((data) => {
            if (data == null) return
            data.med = formatFullName({
                name: data.medName,
                surname1: data.medSurname1,
                surname2: data.medSurname2
            })
            data.name = formatFormalName({
                name: data.name,
                surname1: data.surname1,
                surname2: data.surname2
            })

            if (!!data.insCompany == false && !!data.invoiceNumber == true) {
                data.insCompany = 'PRIVAT'
            }
        })
        this.res = res;
        this.sel.itemsRef.current = res
    }


    async fetchStats() {
        let startDate = moment(this.filterForm.$('startDate').value).toDate();
        let endDate = moment(this.filterForm.$('endDate').value || startDate).toDate();
        let res = await C3.instance.client.fetcher.fetch(`/all-visits-view/stats`, {
            method: 'GET',
            query: [`start=${startDate.toISOString()}`, `end=${endDate.toISOString()}`, `type=${this.filterForm.$('type').value}`].join('&'),
        });
        this.statsRes = res;
        // this.sel.itemsRef.current = res
    }



    async fetchAllMedicalsForCenters() {
        const orgs = this.appStore.orgsSel.selectedId ? `orgs=${this.appStore.orgsSel.selectedId}` : '';
        const extCenterId = this.filterForm.$('refExtCenter').value.id
        const extCenter = extCenterId ? `extCenterId=${extCenterId}` : null;
        if (extCenter == null || orgs === '') return
        let res = await C3.instance.client.fetcher.fetch(`/svv/ref-ext-center-to-ref-medical`, {
            query: [orgs,
                extCenter].join('&'),
        })
        res[extCenterId].forEach(r => {
            r.name = formatFullName(r)
            r.surname1 = ''
        })
        this.refMedicalSel.itemsRef.current = res[extCenterId];
        return res[extCenterId]
    }


    async fetchAllMedicals() {
        const orgs = this.appStore.orgsSel.selectedId ? `orgs=${this.appStore.orgsSel.selectedId}` : '';
        let res = await C3.instance.client.fetcher.fetch(`/svv/ref-medicals`, {
            query: [orgs, `inactive=${false}`].filter(a => a).join('&')
        })
        this.refMedicalSel.itemsRef.current = res;
        return this.refMedicalSel
    }


    async fetchAllCenters() {
        const orgs = this.appStore.orgsSel.selectedId ? `orgs=${this.appStore.orgsSel.selectedId}` : '';
        if (orgs === '') return
        let res = await C3.instance.client.fetcher.fetch(`/svv/ref-ext-centers`, {
            query: [
                orgs
            ]
        })
        this.refExtCenterSel.itemsRef.current = res;
        return res
    }


    @action
    async moveRange(days: number) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');

        [$startDate, $endDate].forEach($d => $d.onChange(moment($d.value).add(days, "days").toDate()))

    }

    @action
    async goToDate(date: Date) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');
        [$startDate, $endDate].forEach($d => $d.onChange(moment(date).toDate()))
    }

    printHandle

    @action
    async print() {
        if (!this.printHandle) return
        this.gridApi.setDomLayout('print')
        setTimeout(async () => {
            await this.printHandle()
            this.gridApi.setDomLayout(null)
        })
    }

    @computed
    get total() {
        return _.sum((this.res || []).map(p => Number(p.price)))
    }

    async removeFilers() {
        this.filterForm.$('refExtCenter').set('')
        this.filterForm.$('refMedical').set('')
    }
}
