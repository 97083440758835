import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {usePFVStore} from "../../PatientFusionView";
import {C3AgGrid, MasterLayout} from "react-c4";
import moment from "moment";
import {CellValueChangedEvent, IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import * as _ from "lodash";
import {formatFullName, stdBoolColumn, stdIntNumericColumn} from "../../../../application/utils/utils";
import {SelectCE} from "../../../../application/utils/components/SelectCE";


const Container = styled.div`
  display: grid;
  padding-right: 5px;
  grid-template-rows: auto 1fr;
`
const SpanContainer = styled.div`
  display: grid;
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
`

export interface PFVMergeGridProps {

}

export const PFVMergeGrid = observer((props: PFVMergeGridProps) => {
    let store = usePFVStore()
    let editableCheck = false;
    return <Container>
        <SpanContainer>(1) Selecciona del llistat, un o varis, pacients a fusionar.</SpanContainer>
        <MasterLayout
            renderHeader={() => null}
            body={
                <C3AgGrid
                    columnDefs={[
                        {
                            field: '',
                            headerName: '',
                            width: 30,
                            checkboxSelection: true,
                            // suppressFilter: true,
                        },
                        {
                            field: 'historyNumber',
                            headerName: 'H.C.',
                            headerTooltip: 'Història Cínica',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'birthDate',
                            headerName: 'Data Naix.',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                            valueFormatter({value}) {
                                if (value == null) return '(Sense data)'
                                return moment(value).format('DD/MM/YYYY');
                            },

                        },
                        {
                            field: 'nif',
                            headerName: 'NIF',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'sex',
                            headerName: 'H/D',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'surname1',
                            headerName: 'Cognom1',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'surname2',
                            headerName: 'Cognom2',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'name',
                            headerName: 'Nom',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'mobile',
                            headerName: 'Mòbil',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'phone2',
                            headerName: 'Tel.',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'email',
                            headerName: 'Email',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'language',
                            headerName: 'Idioma',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'postalCode',
                            headerName: 'Codi postal',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'city',
                            headerName: 'Ciutat',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'address',
                            headerName: 'Adreça',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'coment',
                            headerName: 'Comentari',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'allergies',
                            headerName: 'Al·lèrgies',
                            editable: editableCheck,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'isDuplicate',
                            headerName: 'Dup.',
                            editable: true,
                            width: 60,
                            headerTooltip: 'Duplicat: desmarcar per treure de la llista',
                            ...stdBoolColumn(),
                        },
                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({
                            name: data.medName,
                            surname1: data.medSurname1,
                            surname2: data.medSurname1
                        })
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    params.api.exportDataAsExcel({
                                        fileName: `llistat-pacients-duplicats`,
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'createdAt') {
                                                return moment(data.createdAt).format('DD/MM/YYYY')
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    rowData={store.patientRes}
                    selection={store.patientSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        await store.savePatient({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                    }}
                    rowSelection={'multiple'}

                />
            }
        />
    </Container>
})
