import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle} from "react";
import styled, {css} from 'styled-components'
import {observer} from 'mobx-react'
import {ICellRendererParams} from "ag-grid-community";
import {ICellRendererReactComp} from "ag-grid-react";
import {useSVVStore} from "../../../../StandardVisitView";
import moment from "moment";
import * as _ from 'lodash'
import {Icon, Tooltip} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    padding-left: 4px;
    align-content: start;
    width: 100%;
    height: 100%;
    margin: 0px -16px 0px -8px;
    border: 2px solid ${({isDraft, isVisit, isCancelled}) => {
        if(isCancelled) return '#d61515'
    return isDraft ? '#d6b615' : isVisit ? '#1590d6' : '#d68f15';
}};
    background: #ececec;
      border-left: 1px solid lightgrey !important;
      border-right: 1px solid lightgrey !important;
      border-bottom: 1px solid lightgrey !important;
    overflow: hidden;
    line-height: 103%;
`
const ContentContainer = styled.div`
    display: grid;
    justify-content: start;
    grid-auto-rows: auto;
    //min-height: 100px;
    height: min-content;
    overflow: visible;

`
const ResourceContainer = styled.div`
    
`
const DateContainer = styled.div`
    font-size: 11px;
    
`
const VisitLineNumbersContainer = styled.div`
    position: absolute;
    right: 1px;
    top: -2px;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 2px;
    align-content: stretch;
`
const VisitLineNumberContainer = styled.div`
    display: grid;
    place-content: center;
    color: #8a9ba8;
    font-size: 11px;
    ${({selected}) => selected ? css`
        font-weight: bold;
    ` : ''}
`

export interface SVVLinesGridVisitCRProps extends ICellRendererParams {

}

export const SVVLinesGridVisitCR = observer(forwardRef((props: SVVLinesGridVisitCRProps, ref) => {
    useImperativeHandle(ref, () => {
        return {
            refresh: (params) => {
                // if (params.value !== value) {
                //     setValue(params.value)
                // }
                return true;
            }
        };
    });

    let store = useSVVStore()

    if (props.data.index != 1 || props.value?.id == null) return null



    let attachDocumentsMode = store.isAttachDocumentsMode;

    let visitId = props.data.visit?.id;
    let visit = store.getLocalVisit(visitId)
    let visitField = store.visitsSel.getFieldById(visitId);
    // console.log(`props.data`, props.data);
    if (visit == null) return <div>Visit: null</div>
    let isSelectedForDocument = false;
    if(attachDocumentsMode){
        let selectedIds = store.documentsVisitsSel.selectedIds;
        isSelectedForDocument = selectedIds.includes(visitId)
        // console.log(`selectedIds`, selectedIds);
        // console.log(`isSelectedForDocument`, isSelectedForDocument);
    }
    let halfSelected = store.isVisitHalfSelected(visit.id);
    let fullySelected = store.isVisitFullySelected(visit.id);
    let isDraft = visit.isDraft;
    let isVisit = visit.isVisit;
    let bgColor = fullySelected ? '#d7b57c' : halfSelected ? '#d6cab4' : '#d9be8f'
    // if(isDraft) bgColor = fullySelected ? '#d9cc8f' : halfSelected ? '#d6d0b4' : '#ececec'
    if(isDraft) bgColor = fullySelected ? 'rgb(220,139,58)' : halfSelected ? '#f5bf5b' : 'rgb(220,139,58)'
    if(isVisit) bgColor = fullySelected ? '#7ec3d7' : halfSelected ? '#b5cfd7' : '#90c8d9'
    let cancelColor =  visitField.$('cancelledAt').value || visitField.$('noShow').value
    if(cancelColor) bgColor = fullySelected ? '#d77e7e' : halfSelected ? '#d7b5b5' : '#d99090'

    let visitLines = visit?.visitLines;
    // console.log(`visitLines`, visitLines);
    let date = visitField.$('appointmentDate').value;
    let moment1 = moment(date);
    let $duration = visitField.$('duration');
    let duration = $duration.value;
    let moment2 = moment1.clone().add(duration, 'minutes');


    return <Container
        style={{height: props.node.rowHeight * visitLines?.length - 4, backgroundColor: bgColor}}
        isDraft={isDraft}
        isVisit={isVisit}
        isCancelled={cancelColor}
        onClick={(e) => {
            if(attachDocumentsMode) return
            if(e.shiftKey){
                let someSelected = store.selectedVisitIds.includes(visit.id);
                let visitLinesIds: number[] = visit.visitLines.map(vl => vl.id);
                let fullySelected = visitLinesIds.every(vlId => store.visitLinesSel.selectedIds.includes(vlId))
                store.visitLinesSel.selectIds(visitLinesIds, e.shiftKey ? !someSelected : !fullySelected)
            }else {
                store.selectVisitOnly(visit.id, true)
            }

        }}
        onDoubleClick={() => {
            // store.selectVisitOnly(visit.id)
        }}
    >
        <ContentContainer>
            <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr' , alignItems: 'center', justifyContent: "start", gap: 2}}>
                {(visit.rescheduledAs) && <Tooltip content={`Reprogramada pel dia ${moment(visit.rescheduledAs).format('DD/MM/YYYY')}`}><Icon icon={'reset'} iconSize={11}/></Tooltip>}
                <div>
                    {attachDocumentsMode && <input type={'checkbox'} checked={isSelectedForDocument} onChange={(e) => {
                        // console.log(`e.target.checked`, e.target.checked);
                        store.documentsVisitsSel.selectId(visitId, e.target.checked, false, false, true)
                        // console.log(`store.documentVisitsSel`, JSON.stringify(store.documentsVisitsSel.items));
                    }}/>}
                    {(visit.cancelledAt) && <Icon icon={'disable'} iconSize={11}/>}
                    {(visit.noShow) && <Icon icon={'blocked-person'} iconSize={11}/>}
                    {(visit.remindedAt) && <Icon icon={'phone'} iconSize={11}/>}
                </div>

                {<strong style={isSelectedForDocument ? {backgroundColor: '#2B95D6', color: 'white', padding: 1, paddingLeft: 2, paddingRight: 2, borderRadius: 1} : {}}>{store.getVisitDisplayName(visit)}</strong>}
            </div>
            <ResourceContainer>{visit.resource?.name || '--'}</ResourceContainer>
            {date && <DateContainer>
              <div>{moment1.format('DD/MM/YY')}</div>
              <div>{moment1.format('HH:mm')}-{moment2.format('HH:mm')}</div>
            </DateContainer>}
            <VisitLineNumbersContainer style={{height: props.node.rowHeight * visitLines.length}}>
                {visitLines.map(vl => {
                    let $vl = store.getVisitLineField(vl.id);
                    let index = $vl.$('index').value;
                    let product = $vl.$('product').value;
                    return <VisitLineNumberContainer
                        key={vl.id} selected={store.visitLinesSel.isSelected(vl.id)}
                        onClick={(e) => {
                            e.stopPropagation()
                            store.refetchVisitLine(vl.id)
                        }}
                        onDoubleClick={(e) => {
                            e.stopPropagation()
                            store.refetchVisit(vl.visit?.id)
                        }}
                    >
                        {product && _.padStart(index, 2, '0')}
                    </VisitLineNumberContainer>
                })}
            </VisitLineNumbersContainer>
        </ContentContainer>
    </Container>
}))

