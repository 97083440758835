import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSVVStore} from "../../../StandardVisitView";
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';


const Container = styled.div`
    display: grid;
    width: 500px;
    place-content: center;
`

export interface SVVAttachDocumentsLargePreviewProps {

}

export const SVVAttachDocumentsLargePreview = observer((props: SVVAttachDocumentsLargePreviewProps) => {
    let store = useSVVStore();
    let item = store.documentsSel.selectedItem
    let id = item?.id
    let state = store.getDocumentState(id)
    let documentRef = useRef(null)
    return <Container>
        {state.downloadUrl && <Document
          ref={documentRef}
          file={state.downloadUrl}
          onLoadSuccess={({numPages}) => {
              store.setDocumentState(id, {
                  loading: false,
                  numPages: numPages
              })
          }}
          onSourceError={() => {

              // if (state.error == true)
              //     setTimeout(() => {
              //         documentRef.current?.loadDocument?.();
              //         store.setDocumentState(id, {
              //             error: false,
              //             loading: false
              //         })
              //     }, 1000)
              // store.setDocumentState(id, {
              //     error: true,
              //     loading: false
              // })

          }}
          onLoadError={() => {
              // store.setDocumentState(id, {
              //     error: true,
              //     loading: false
              // })

          }}
          error={'No es pot mostrar el document'}
          loading={''}
          className={state.loading ? 'bp3-skeleton' : ''}
        >
          <Page pageNumber={state.page} width={500}/>
        </Document>}

    </Container>
})
