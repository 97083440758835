import {C3, C3MasterDetail, MRF} from "react-c4";
import {GridApi} from "ag-grid-community";
import * as _ from 'lodash'
import moment from "moment";
import {computed, observable, autorun} from "mobx";
import MobxReactForm from "mobx-react-form";


export class PatientsPageStore {
    md: C3MasterDetail

    externalFilter = ''
    @observable
    statistics: any = {}

    gridRef = {current: null as GridApi}

    newPatientForm: MRF

    init() {
        C3.instance.client.fetcher.fetch(`/patient/statistics`, {})
            .then(r => this.statistics = r)
        this.md = new C3MasterDetail({
            entityType: 'patient',
            autoFetch: true,
            store: this,
        })
        this.md.selection.incomplete = true
        this.md.fetchItems()
            .then(() => setTimeout(() => {
                // this.md.selection.selectId(1)
            }))

        this.newPatientForm = new MobxReactForm({
            fields: [
                'name',
                'surname1',
                'surname2',
                'mobile',
                'insCompany',
            ],

        })

        autorun(()=>{
            this.md.form.selection.selectedId;
            this.isDuplicated = this.md.form.$('isDuplicate').value
        })

    }

    createPatient() {
        this.md.selection.selectIds([], true, true, true)
        this.md.startCreating()
        if (this.gridRef.current) {
            let filterModel = this.gridRef.current.getFilterModel();
            console.log(`this.gridRef.current`, filterModel);
            // this.md.form.form.$('name').set('Nom1')
            _.entries(filterModel).forEach(([k, v]) => {
                let value = v.filter;
                if (k == 'birthDate') {
                    let moment1 = moment(value, 'DD/MM/YYYY');
                    if (!moment1.isValid() || _.isEmpty(value) || value == null) {
                        value = ''
                    } else {
                        value = moment1.toDate()
                        this.md.form.form.$(k).set(value)
                    }
                } else {
                    this.md.form.form.$(k).set(value)
                }
            })
        }
        this.md.form.isLocked = false
    }

    isCreatingPatientNewSystem = false

    createPatientNewSystem() {
        if (this.isCreatingPatientNewSystem) {
            // Clear
        } else {

        }
        this.isCreatingPatientNewSystem = !this.isCreatingPatientNewSystem
    }

    @observable
    isDuplicated

    patient

    async saveSelectedPatient() {
        this.patient = this.md.form.data
        if (this.patient==null) {
            console.log('patient is null +++')
            return
        }
        this.patient.isDuplicate =  !this.patient.isDuplicate
        if (this.patient.id == null) return
        await this.savePatient(this.patient)
        await this.md.fetchItems()
        this.md.form.fetch()
    }

    async savePatient(patient) {
        await C3.instance.client.fetcher.fetch(`/patient/${patient.id}`, {
            method: 'PATCH',
            body: patient
        });
    }

    async deleteSelectedPatient() {
        let result = await C3.instance.client.fetcher.fetch(`/patient/${this.md.selection.selectedId}`, {
            method: 'DELETE'
        });
        await this.md.fetchItems()
        this.md.form.fetch()
    }
}
