import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {isProd, useAppStore} from "../../../utils/utils";
import {useIdle, useNetworkState} from "react-use";
import {LoginForm} from "./LoginForm";

const Container = styled.div`
  display: grid;
  position: absolute;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(16px); // 6px
  place-content: center;
  place-items: center;
  z-index: 100;

  background-color: rgba(236, 251, 255, 0.4);
`

// const Container = styled.div`
//     display: grid;
//     grid-template-columns: 1fr auto 1fr;
//     //background-color: #738592;
//     //background-color: #c3ced5;
//     height: 100vh;
// `
const AccountListContainer = styled.div`
  display: grid;
  align-self: end;
  justify-self: start;
  grid-gap: 2px;
  padding-left: 16px;
  padding-bottom: 24px;
  grid-auto-flow: row;
`
const AccountListItemContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  //border: 1px solid black;
  ${p => p.selected ? `
        background-color: rgba(206,234,248,0.45);
        backdrop-filter: blur(1px);
    ` : ''}
`
const AvatarContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(26, 26, 36, 0.18);
  backdrop-filter: blur(1px);
  text-transform: uppercase;
  border-radius: 50%;
  font-size: larger;
  font-weight: bold;
  color: white;
  line-height: 0px;
  padding-bottom: 1px;
  height: 40px;
  width: 40px;
`
const ToolbarContainer = styled.div`
  position: absolute;
  display: grid;
  grid-gap: 16px;
  padding: 8px;
  grid-auto-flow: column;
  width: min-content;
  position: absolute;
  bottom: 16px;
  right: 16px;
`
const ToolbarIconContainer = styled.div`
  right: 16px;
  bottom: 16px;
  height: 40px;
  width: 40px;
  background-color: rgba(173, 216, 230, 0.51);

`
const LoginFormContainer = styled.div`


`
const PinInput = styled.input`
  position: absolute;
`

export interface OverlayLoginScreenProps {

}

export const OverlayLoginScreen = observer((props: OverlayLoginScreenProps) => {
    let appStore = useAppStore()
    let auth = appStore.auth;
    const isIdle = useIdle(25 * 60 * 1e3);
    useEffect(() => {
        if(isIdle && isProd() && !appStore.isWRDView) auth.logout(false)
    }, [isIdle])
    const networkState = useNetworkState()
    useEffect(() => {
       appStore.network = networkState
    }, [networkState])
    // auth.logout()
    // return null;
    if (auth.isLoggedIn) return null;
    // return null;
    return <Container>
        {/*<AccountListContainer>*/}
        {/*    <AccountListItemContainer>A</AccountListItemContainer>*/}
        {/*    <AccountListItemContainer>B</AccountListItemContainer>*/}
        {/*    <AccountListItemContainer>C</AccountListItemContainer>*/}
        {/*</AccountListContainer>*/}
        <LoginForm/>
        {/*<ToolbarContainer>*/}
        {/*    <ToolbarIconContainer/>*/}
        {/*    <ToolbarIconContainer/>*/}
        {/*    <ToolbarIconContainer/>*/}
        {/*</ToolbarContainer>*/}
    </Container>
})
