import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Page} from "react-c4";
import {page} from "react-navigation-plane";
import {ExtCenterFileView} from "../../files-views/ExtCenterFileView/ExtCenterFileView";
import {ExtCenterFVStore} from "../../files-views/ExtCenterFileView/ExtCenterFVStore";
import {ExtCenterFilePageHeader} from "./ExtCenterFIlePageHeader";


const Container = styled.div`
    display: grid;
    
`



export interface SchedulePageProps {

}

export const ExtCenterFilePage = page(observer((props: SchedulePageProps) => {
    let [store, setStore] = useState(null as ExtCenterFVStore);

    return <Page
        title={'Centres referents'}
        subtitle={'Manteniment de centres referents'}
        icon={'office'}
        attributes={[
            {
                name: 'Centres referents',
                value: store?.extCenterRes?.length,
            }
        ]}
        renderCustomHeaderArea={() => {
            return <ExtCenterFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            return <Container>
                <ExtCenterFileView
                    onStoreReady={(s) => {
                        setStore(s);
                    }}
                    {...pageContext.args}
                    pageContext={pageContext}
                />
            </Container>
        }}
    />
}))
