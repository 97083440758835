import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {EFEFeatureSelector} from "./EFEFeatureSelector";
import {EFEFeatureSorter} from "./EFEFeatureSorter";


const Container = styled.div`
  display: grid;

`

export interface EventFeatureEditorProps {

}

export const EventFeatureEditor = observer((props: EventFeatureEditorProps) => {
    return <Container>
        {/* + Selector*/}
        <EFEFeatureSelector/>
        {/* DND Features*/}
        <EFEFeatureSorter/>
    </Container>
})
