import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Button, ButtonGroup, Tooltip} from "@blueprintjs/core";
import {SVVADThumbnail} from "./SVVADThumbnail";
import {useSVVStore} from "../../../StandardVisitView";
import {SVVADInfo} from "./SVVADInfo";

const Container = styled.div`
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: 8px;
    height: calc(168.4px + 8px);
    margin: 4px;
`


export interface SVVAttachDocumentsListItemProps {
    document
    index
    style?
    isScrolling?
}

export const SVVAttachDocumentsListItem = observer((props: SVVAttachDocumentsListItemProps) => {
    let store = useSVVStore()
    let id = props.document.id;
    let documentsSel = store.documentsSel;
    let selected = documentsSel.isSelected(id)
    let [dlDoc, setDlDoc] = useState(null);
    let documentRef = useRef();

    useEffect(() => {
        store.getDlDoc(id).then(dlDoc => {
            setDlDoc(dlDoc);
            store.setDocumentState(id, {downloadUrl: dlDoc.downloadUrl})
        })
    }, [])

    return <Container style={props.style} tabIndex={100 + props.index}>
        <SVVADThumbnail documentRef={documentRef} dlDoc={dlDoc} selected={selected} document={props.document}
                        onClick={(e) => {
                            documentsSel.itemsRef.current = store.documentsList
                            documentsSel.selectId(id, !selected, !e.shiftKey);
                        }}
                        onDoubleClick={() => {
                            store.dlDocument(id)
                        }}

                        onKeyDown={(e) => {
                            let key = (e.key || '').toLowerCase();
                            let foundKey = _.keys(store.documentTypesMap).find(k => k.startsWith(key));
                            if (!_.isEmpty(foundKey)) {
                                store.updateDocument({id, type: foundKey})
                            }
                        }}
        />
        <ButtonGroup vertical={true}>
            {_.entries(store.documentTypesMap).map(([k, v]: any, i) => {
                let displayName = v.buttonName || k.toUpperCase();
                let button = <Button
                    key={k}
                    active={props.document.type === k}
                    onClick={() => {
                        store.updateDocument({id, type: k})
                    }}>{displayName}</Button>;
                return button;
                return <Tooltip content={v.tooltipName} hoverOpenDelay={3200}>
                    {button}
                </Tooltip>
            })}
        </ButtonGroup>
        <SVVADInfo document={props.document}/>
    </Container>
})




