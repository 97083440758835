import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useICIStore} from "../InsCompanyInvoicingView";
import {invoiceNumberCell} from "../../StandardVisitView/SVVMain/SVVPivateInvoicing/utils/invoiceNumberCell";
import {CellDoubleClickedEvent, ValueFormatterParams} from "ag-grid-community";
import {stdDateColumn, stdIntNumericColumn, stdNumericColumn} from "../../../application/utils/utils";
import moment from "moment";

const Container = styled.div`
    display: grid;
    height: 100%
    
`

export interface ICIInvoicesGridProps {

}

export const ICIInvoicesGrid = observer((props: ICIInvoicesGridProps) => {
    let store = useICIStore();
    return <Container>
        <MasterLayout
            body={<C3AgGrid
                selection={store.invoicesSel}
                columnDefs={[
                    // {
                    //     field: 'id',
                    //     headerName: 'Id',
                    //     width: 60
                    // },
                    {
                        field: 'invoiceNumber',
                        headerName: 'N. Factura',
                        ...invoiceNumberCell(),
                        width: 94,
                        editable: false,
                        onCellDoubleClicked: (e: CellDoubleClickedEvent) => {
                            // store.goToInvoice(e.data.invoiceId)
                        },
                        filter: 'agTextColumnFilter',
                        colSpan: function(params) {
                            return params.data.id === 'not-invoiced' ? 2 : 1;
                        }
                    },
                    {
                        field: 'invoiceDate',
                        headerName: 'Data',
                        width: 100,
                        filter: 'agTextColumnFilter',
                        ...stdDateColumn(),
                        valueFormatter({value, data}) {
                            if (data.id == 'not-invoiced') return ''
                            if (value == null) return '(Sense data)'
                            return moment(value).format('DD/MM/YYYY');
                        },
                    }, {
                        field: 'count',
                        headerName: 'N. Línies',
                        width: 80,
                        filter: 'agTextColumnFilter',
                        ...stdIntNumericColumn()

                    }, {
                        field: 'total',
                        headerName: 'Total €',
                        width: 100,
                        filter: 'agTextColumnFilter',
                        ...stdNumericColumn()
                    }, {
                        field: 'periodStartDate',
                        headerName: 'Període',
                        width: 100,
                        filter: 'agTextColumnFilter',
                        valueFormatter: (params: ValueFormatterParams) => {
                            let start = params.data.periodStartDate;
                            let end = params.data.periodEndDate;
                            let str = [start && moment(start).format('D/M'), end && moment(end).format('D/M')].join(' - ')
                            return str
                        }

                    },
                ]}
                rowSelection={'single'}
            />}

            renderHeader={() => {
                return null
            }}
        />
    </Container>
})
