import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {RSCCombinedInput} from "./RSCCombinedInput/RSCCombinedInput";
import {useRSCStore} from "../../../../RSCMain";

const Container = styled.div`
    display: grid;
    
`

export interface RSCRefMedicalInputProps {

}

export const RSCRefMedicalInput = observer((props: RSCRefMedicalInputProps) => {
    let store = useRSCStore()
    return <RSCCombinedInput
        fieldName={'medical'}
        title={'Metge referidor'}
        inputRef={store.medicalRef}
        entityTitle={'metge referidor'}
        associatingToText={''}
    />
})
