import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {formatFormalName, formatFullName, stdDateColumn, stdNumericColumn} from "../../../../application/utils/utils";
import {C3AgGrid, MasterLayout} from "react-c4";
import {useICIStore} from "../../InsCompanyInvoicingView";
import {RowNode} from "ag-grid-community";
import moment from "moment";

const Container = styled.div`
    display: grid;
    
`

export interface ICIInsCompanyBodyGridProps {

}

export const ICIInsCompanyBodyGrid = observer((props: ICIInsCompanyBodyGridProps) => {
    let store = useICIStore();
    let overlayNoRowsTemplate;
    if (store.insCompaniesSel.selectedId)
        overlayNoRowsTemplate = store.invoiceStatus == 0 ? 'No hi ha cap línia per facturar' : 'La factura no conté cap línia';
    return <MasterLayout
        body={<C3AgGrid
            onGridReady={({api}) => store.vlGridApi = api}

            selection={store.visitLinesSel}
            defaultColDef={{
                filter: 'agTextColumnFilter',
                floatingFilterComponentParams: {suppressFilterButton: true},
                suppressMenu: true,
                sortable: true

            }}
            overlayNoRowsTemplate={overlayNoRowsTemplate}

            columnDefs={[
                {
                    field: '',
                    headerName: '',
                    width: 30,
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    // suppressFilter: true,
                    filter: false,
                },
                {
                    field: 'visitNumber',
                    headerName: 'Visita',
                    width: 75,
                    valueFormatter({value, data}): string {
                        if (data.text == 'IVA 21%'){
                            return value
                        }
                        return `V${value}`
                    }

                },
                {
                    field: 'visitLineNumber',
                    headerName: 'L. Visita',
                    width: 77,
                    valueFormatter({value, data}): string {
                        return value && `${value}`
                    }
                },
                {
                    field: 'date',
                    headerName: 'Data prova',
                    ...stdDateColumn(),
                    filter: false,
                    valueFormatter({value, data}) {
                        if (data.text == 'IVA 21%'){
                            return ''
                        }
                        if (value == null) return '(Sense data)'
                        return moment(value).format('DD/MM/YYYY');
                    }

                },
                {
                    field: 'name',
                    headerName: 'Pacient',
                    width: 200,

                    filterValueGetter: ({data}) => {
                        return formatFormalName(data)
                    },

                    valueFormatter({value, data}): string {
                        return formatFormalName(data)
                    }
                },
                {
                    field: 'resourceName',
                    headerName: 'Maq.',
                    width: 85
                }, {
                    field: 'productName',
                    headerName: 'Prova',
                    width: 140
                }, {
                    field: 'rate',
                    headerName: 'Barem',
                    width: 225,
                    valueFormatter({value, data}) {
                        if (data.text == 'IVA 21%'){
                            return data.text
                        }
                        return value;
                    }
                },
                {
                    field: 'price',
                    headerName: 'Preu',
                    width: 60,
                    ...stdNumericColumn(),
                    filter: false,

                }, {
                    field: 'insCompanyPaymentForm',
                    headerName: 'Tipus',
                    width: 100,
                    filter: false,
                }, {
                    field: 'insCompany',
                    headerName: 'Mútua',
                    width: 210,
                },
                // {
                //     field: '',
                //     headerName: 'Client',
                //     ...stdDateColumn()
                // },
                {
                    field: 'invoiceNumber',
                    headerName: 'Nº factura',
                    width: 80
                },
                {
                    field: 'authorizationNumber',
                    headerName: 'Autorització',
                    width: 130
                },
                {
                    field: 'extCenter',
                    headerName: 'Ref. centre',
                    width: 80
                },
                {
                    field: 'med',
                    headerName: 'Ref. Metge',
                    width: 80,
                    valueFormatter({data}): string {
                        return formatFullName({
                            name: data.medName,
                            surname1: data.medSurname1,
                            surname2: data.medSurname1
                        })
                    }
                },
                {
                    field: '',
                    headerName: 'Ref. Especialitat',
                    width: 80
                },
                {
                    field: 'orgName',
                    headerName: 'Org.',
                    width: 110
                },
            ]}
            onRowDoubleClicked={({data}) => {
                console.log(`data`, data);
                store.pageContext.navigate({
                    to: 'bookVisitPage', args: {
                        patientId: data.pId,
                        selectedVisitIds: [data.vId]
                    },
                    inNewTab: false, focusNewTab: true
                })
            }}
            getContextMenuItems={(params) => {
                let result = [
                    {
                        // custom item
                        name: 'Exporta a Excel',
                        action: function () {
                            let isNotInvoiced = store.invoicesSel.selectedId == 'not-invoiced';
                            let insCompanyName = (store.insCompaniesSel.selectedItem?.name || '').substr(0, 12)
                            let type = isNotInvoiced ? 'pend' : store.invoicesSel.selectedItem?.invoiceNumber ||  store.invoicesSel.selectedItem?.invoiceNumber
                            let fileName = [insCompanyName, type].join('-');
                            params.api.exportDataAsExcel({
                                fileName: fileName,
                                processCellCallback: (params) => {
                                    let data = (params.node as RowNode).data
                                    // if (params.column.getColDef().valueFormatter) {
                                    //     return params.column.getColDef().valueFormatter({data})
                                    // }
                                    if (params.column.getColId() == 'date') {
                                        return stdDateColumn().valueFormatter({value: data.date})
                                    }
                                    if (params.column.getColId() == 'name') {
                                        return formatFormalName(data)
                                    }
                                    if (params.column.getColId() == 'med') {
                                        return formatFullName({
                                            name: data.medName,
                                            surname1: data.medSurname1,
                                            surname2: data.medSurname1
                                        })
                                    }
                                    return params.value
                                }
                            })
                        },
                    }
                ]
                return result
            }}
                // rowSelection={'multi'}

                />}
                renderHeader={() => {
                return null
            }}
                />
            })
