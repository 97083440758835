import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import Splitter from "m-react-splitters";
import {SCVCalendar} from "../../../components/ScheduleView/SVMain/SCVCalendar/SCVCalendar";
import {EventDetail} from "../../../components/ScheduleView/SVMain/components/EventDetail";
import {MeetupEventDetail} from "../../../components/ScheduleView/SVMain/components/MeetupEventDetail";
import {WeeklyScheduleDetail} from "../../../components/ScheduleView/SVMain/components/WeeklyScheduleDetail";
import {useFFVStore} from "../FeatureFileView";
import {FFVFeaturesMaster} from "./FFVFeaturesMaster";
import {FFVFeatureEditor} from "./FFVFeatureEditor";

const Container = styled.div`
  display: grid;

`

export interface FFVMainProps {

}

export const FFVMain = observer((props: FFVMainProps) => {
    let store = useFFVStore()
    return <Container>
        <Splitter
            position="vertical"
            primaryPaneMinWidth={0}
            primaryPaneWidth={'380px'}
            postPoned={true}>
            <FFVFeaturesMaster/>
            <FFVFeatureEditor/>
        </Splitter>
    </Container>
})
