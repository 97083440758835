import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {
    formatFormalName,
    formatFullName,
    paymentFormMap,
    stdDateColumn,
    stdNumericColumn
} from "../../../../application/utils/utils";
import {C3AgGrid, MasterLayout} from "react-c4";
import {useAVVStore} from "../../AllVisitsView";
import {RowNode} from "ag-grid-community";

const Container = styled.div`
    display: grid;
    height: 100%;
`

export interface AVVGridProps {

}

export const AVVGrid = observer((props: AVVGridProps) => {
    let store = useAVVStore()
    return <Container>
        <MasterLayout
            actions={[]}
            renderHeader={() => null}
            body={<C3AgGrid
                selection={store.sel}
                onGridReady={({api}) => store.gridApi = api}
                defaultColDef={{
                    editable: false,

                    resizable: true,
                    suppressMenu: true,
                    width: 100,
                    sortable: true
                }}
                onRowDoubleClicked={({data, event}) => {
                    setTimeout(() => {
                        if (store.pageContext) {
                            store.pageContext.navigate({
                                to: 'bookVisitPage', args: {
                                    patientId: data.pId,
                                    selectedVisitIds: [data.vId]

                                },
                                inNewTab: !(event as any).ctrlKey, focusNewTab: true
                            })
                        }
                    })
                }}
                columnDefs={[
                    {
                        field: 'visitNumber',
                        headerName: 'Visita',
                        width: 75,
                        valueFormatter({value, data}): string {
                            return `V${value}`
                        },
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'visitLineNumber',
                        headerName: 'L. Visita',
                        width: 77,
                        valueFormatter({value, data}): string {
                            return value && `${value}`
                        },
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'appointmentDate',
                        headerName: 'Data cita',
                        ...stdDateColumn()
                    },
                    {
                        field: 'date',
                        headerName: 'D. Prestació',
                        ...stdDateColumn()
                    },
                    {
                        field: 'name',
                        headerName: 'Pacient',
                        width: 200,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'resourceName',
                        headerName: 'Maq.',
                        width: 85,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'productName',
                        headerName: 'Prova',
                        width: 140,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'rate',
                        headerName: 'Barem',
                        width: 225,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'price',
                        headerName: 'Preu',
                        width: 60,
                        ...stdNumericColumn(),
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'insCompanyPaymentForm',
                        headerName: 'Tipus',
                        width: 100,
                    },
                    {
                        field: 'insCompany',
                        headerName: 'Mutua',
                        width: 210,
                        filter: 'agTextColumnFilter',
                    },
                    // {
                    //     field: '',
                    //     headerName: 'Client',
                    //     ...stdDateColumn()
                    // },
                    {
                        field: 'invoiceNumber',
                        headerName: 'Nº factura',
                        width: 80,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'extCenter',
                        headerName: 'Ref. centre',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'med',
                        headerName: 'Ref. Metge',
                        filter: 'agTextColumnFilter',

                    },
                    {
                        field: '',
                        headerName: 'Ref. Especialitat',
                        width: 80
                    },
                    {
                        field: 'status',
                        headerName: 'Estat',
                        valueFormatter({data, value}): string {
                            return {
                                cancelled: 'Cancel·lat',
                                'no-show': 'No Vingut',
                            }[value]
                        },
                        width: 110
                    },
                    {
                        field: 'cip',
                        headerName: 'CIP',
                        width: 110,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'authorizationNumber',
                        headerName: 'N.Autorització',
                        width: 110,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field:'deliveryDate',
                        headerName: 'E. Prevista',
                        filter: 'agTextColumnFilter',
                        ...stdDateColumn()
                    },
                    {
                        field:'deliveredDate',
                        headerName: 'D. Entrega',
                        filter: 'agTextColumnFilter',
                        ...stdDateColumn()
                    },
                    {
                        field: 'createdByUser',
                        headerName: 'Creació cita',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'admittedByUser',
                        headerName: 'Recepció',
                        filter: 'agTextColumnFilter',
                    },
                    /*{
                        field: 'deliveredByUser',
                        headerName: 'Entrega',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'lastUpdatedByUser',
                        headerName: 'Ultima modifiació',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'remindedByUser',
                        headerName: 'Recordatori',
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'summonedByUser',
                        headerName: 'Cridat a máquina',
                        filter: 'agTextColumnFilter',
                    },*/
                    {
                        field: 'orgName',
                        headerName: 'Organització',
                        width: 110
                    },
                ]}
                processCellCallback={(params) => {
                    let data = (params.node as RowNode).data
                    return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                }}
                getContextMenuItems={(params) => {
                    // let fileName = store.fr
                    var result = [
                        {
                            // custom item
                            name: 'Exporta a Excel',

                            action: function () {
                                params.api.exportDataAsExcel({
                                    fileName: 'llistat-actes',
                                    processCellCallback: (params) => {
                                        let data = (params.node as RowNode).data
                                        if (params.column.getColId() == 'appointmentDate') {
                                            return stdDateColumn().valueFormatter({value: data.date})
                                        }
                                        if (params.column.getColId() == 'date') {
                                            return stdDateColumn().valueFormatter({value: data.date})
                                        }
                                        if (params.column.getColId() == 'deliveryDate') {
                                            return stdDateColumn().valueFormatter({value: data.deliveryDate})
                                        }
                                        if (params.column.getColId() == 'deliveredDate') {
                                            return stdDateColumn().valueFormatter({value: data.deliveredDate})
                                        }
                                        // if(params.column.getColId() == 'med'){
                                        //     return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                                        // }
                                        return params.value
                                    }
                                })
                            },
                        }
                    ]
                    return result
                }}
            />}
        />
    </Container>
})
