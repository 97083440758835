import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4/src/index";
import moment from "moment";
import {
    formatFullName,
    stdDateColumn,
    stdIntNumericColumn,
    stdNumericColumn
} from "../../../../application/utils/utils";
import {CellValueChangedEvent, RowNode, ValueFormatterParams} from "ag-grid-community";
import {useMEVStore} from "../../MeetupEventsView";

const Container = styled.div`
  display: grid;
  height: 100%;

`

export interface MEVDetailGridProps {

}

export const MEVDetailGrid = observer((props: MEVDetailGridProps) => {
    let store = useMEVStore()
    let editableCheck = true
    return <Container>
        <MasterLayout
            renderHeader={() => null}
            actions={[
                {
                    name: 'refresh',
                    iconName: 'refresh',
                    text: 'Refrescar grup',
                    callback: () => {
                        store.fetchMeetupEvents()
                    }
                },
                {
                    name: 'push',
                    iconName: 'export',
                    text: 'Enviar canvis a Meetup',
                    callback: () => {
                        // store.patientInsCompaniesCreating = !store.patientInsCompaniesCreating
                    }
                },
                {
                    name: 'import',
                    iconName: 'import',
                    text: 'Copiar event de meetup',
                    callback: () => {
                        store.copyEventFromMeetupEvent()
                    }
                },
                {
                    name: 'import',
                    iconName: 'download',
                    text: 'Fetch event attendees for selected',
                    callback: () => {
                        store.fetchMeetupRSVPsForSelectedEvents()
                    }
                },
                // {
                //     name: 'new',
                //     iconName: 'plus',
                //     text: 'Afegir grup',
                //     callback: () => {
                //         // store.patientInsCompaniesCreating = !store.patientInsCompaniesCreating
                //     }
                // },

                // {
                //     name: 'remove',
                //     iconName: 'trash',
                //     text: 'Treure grup',
                //     callback: () => {
                //         // store.removePatientInsCompany(store.patientInsCompaniesSel.selectedId)
                //     }
                // }
            ]}
            body={
                <C3AgGrid
                    onRowDoubleClicked={({data, event}) => {
                        window.open(`https://www.meetup.com/${data.urlName}/events/${data.meetupId}/`, "_blank");
                    }}
                    columnDefs={[
                        {
                            field: '',
                            headerName: '',
                            width: 30,
                            checkboxSelection: true,
                            // suppressFilter: true,
                        },
                        {
                            field: 'name',
                            headerName: 'Name',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 320,
                        },
                        {
                            field: 'attendance',
                            headerName: 'Attendance',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                            ...stdIntNumericColumn(),
                        },
                        {
                            field: 'row_number',
                            headerName: 'Row index',
                            filter: 'agTextColumnFilter',
                            ...stdIntNumericColumn(),
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'urlName',
                            headerName: 'Url Name',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'rsvp_limit',
                            headerName: 'Capacitat',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'meetupId',
                            headerName: 'Id de Meetup',
                            filter: 'agTextColumnFilter',
                            editable: true,
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'start',
                            headerName: 'Start',
                            filter: 'agTextColumnFilter',
                            ...stdDateColumn(),
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'end',
                            headerName: 'end',
                            filter: 'agTextColumnFilter',
                            ...stdDateColumn(),
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'dow',
                            headerName: 'Day of week',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                            valueFormatter: ({value}) => {
                                if (value == null) return '--'
                                return moment.weekdaysShort()[value]
                            },
                            filterValueGetter: (params) => {
                                return moment.weekdays()[params.data.dow] + params.data.dow + moment.weekdaysShort()[params.data.dow]
                            }
                        },
                        {
                            field: 'woy',
                            headerName: 'Week of year',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                            valueFormatter: ({value}) => {
                                if (value == null) return '--'
                                return Math.floor(value).toString()
                            }
                        },
                        {
                            field: 'duration',
                            headerName: 'Duration',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'timedelta.days',
                            headerName: 'Time delta',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'who',
                            headerName: 'Who',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'eventId',
                            headerName: 'Attached event id',
                            filter: 'agTextColumnFilter',
                            ...stdIntNumericColumn(),
                            floatingFilter: true,
                            width: 60,
                        },
                        {
                            field: 'eventName',
                            headerName: 'Attached event name',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'localized_location',
                            headerName: 'Localització',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 200,
                        },
                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({
                            name: data.medName,
                            surname1: data.medSurname1,
                            surname2: data.medSurname1
                        })
                    }}
                    // getContextMenuItems={(params) => {
                    //     // let fileName = store.fr
                    //     var result = [
                    //         {
                    //             // custom item
                    //             name: 'Exporta a Excel',
                    //
                    //             action: function () {
                    //                 params.api.exportDataAsExcel({
                    //                     fileName: `llistat-pacients-duplicats`,
                    //                     processCellCallback: (params) => {
                    //                         let data = (params.node as RowNode).data
                    //                         if (params.column.getColId() == 'createdAt') {
                    //                             return moment(data.createdAt).format('DD/MM/YYYY')
                    //                         }
                    //                         return params.value
                    //                     }
                    //                 })
                    //             },
                    //         }
                    //     ]
                    //     return result
                    // }}
                    // rowData={store.meetupGroupsRes}
                    selection={store.meetupEventsSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        // await store.saveMeetupEvent({
                        //     id: e.data.id,
                        //     [e.colDef.field]: e.newValue
                        // })
                    }}
                    rowSelection={'multiple'}

                />
            }
        />
    </Container>
})