import React, {useState, useEffect, useContext, useRef, useImperativeHandle, forwardRef, useLayoutEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import ReactDOM from "react-dom";
import {RSCMain} from "../../../../../ReferrerSelector/RSCMain";
import {ICellEditorParams} from "ag-grid-community";
import {createPopper, Instance as PopperInstance, VirtualElement} from "@popperjs/core";
import {useSVVStore} from "../../../../StandardVisitView";

const Container = styled.div`
  display: grid;

`

const virtualReference = {
    getBoundingClientRect() {
        return {
            top: 10,
            left: 10,
            bottom: 20,
            right: 100,
            width: 90,
            height: 10,
        };
    },
};

export const SVVLinesGridReferrerCE = observer(forwardRef((props: ICellEditorParams, ref) => {
    let store = useSVVStore();
    const [contextElement, setContextElement] = useState(document.body as HTMLElement);


    const [popper, setPopper] = useState(null as PopperInstance);
    const referenceElementRef = useRef();
    const popperElementRef = useRef();

    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);

    useLayoutEffect(() => {
        const popper: PopperInstance = createPopper(referenceElementRef.current, popperElementRef.current, {
            placement: "auto-end",
            modifiers: [
                {
                    name: 'flip',
                    enabled: true,
                },

            ],
            onFirstUpdate: () => {
                let current = store?.rscStore?.medicalRef?.current;
                if(current){
                    current?.focus()
                }
            }
        });

        setPopper(popper)
        focus();
    }, []);

    const keyDownEvent = (event) => {
        console.log(`event.keyCode`, event.keyCode);
        if ([37, 39].indexOf(event.keyCode) > -1) { // left and right
            // setHappy(!happy);
            // Update search focus
            event.stopPropagation();
        }
        if ([27].indexOf(event.keyCode) > -1) { // left and right
            // setHappy(!happy);
            props.api.stopEditing(true)
            event.stopPropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', keyDownEvent);

        return () => {
            window.removeEventListener('keydown', keyDownEvent);
        };
    }, [keyDownEvent]);

    useImperativeHandle(ref, () => {
        return {
            // getValue() {
            //     return undefined;
            // },

            isPopup() {
                return true;
            },
            init(params: ICellEditorParams) {

            }

        }
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing(true)

        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container: any = ReactDOM.findDOMNode(popperElementRef.current);
            if (container) {
                container.focus();
            }
        })
    };


    let rscStore = store.getRSCStore();

    useEffect(() => {
        if (rscStore) {
            rscStore.initForVisitLine(props.data.id, props)
        }
        console.log(`props.data.id`, props.data.id);
        return () => {
            rscStore.svvStore.refetchVisitLine(props.data.id)
        }
    }, [props.data.id])
    return (
        <>
            <div style={{
                // height: 25
                backgroundColor: 'transparent'
            }} ref={referenceElementRef}>

            </div>

            {ReactDOM.createPortal(
                <div

                    ref={popperElementRef}
                    style={{
                        ...popper?.state?.styles,
                        zIndex: 100
                    }}
                    {...(popper?.state?.attributes)}
                >
                    <RSCMain store={rscStore} params={props.value}/>
                </div>,
                document.body
            )}
            {/*<div ref={setPopperElement} style={styles.popper} {...attributes.popper}>*/}
            {/*    <RSCMain/>*/}
            {/*    <div ref={setArrowElement} style={styles.arrow} />*/}
            {/*</div>*/}
        </>
    );
}))
