import React, {useContext, useEffect} from "react";
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout, useC3Store} from "react-c4";
import { RefFVStore } from "./RefFVStore";
import {RefFVMain} from "./RefFVMain";
import {ExcelImportDialog} from "../../application/utils/excel-import/ExcelImportDialog";



export const RefFVContext = React.createContext(null as RefFVStore)
export const useRefFVStore = () => useContext(RefFVContext)

export interface ReferrerFileViewProps {
    pageContext?
    onStoreReady: (s: RefFVStore) => any
}

export const ReferrerFileView = observer((props: ReferrerFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new RefFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null

    return <RefFVContext.Provider value={store}>
        <ExcelImportDialog isOpen={store.excelDialogOpen} onClose={() => store.excelDialogOpen = false}
                           processResult={async (name, json) => {
                               await store.processExcelImport(name, json)
                           }}
                           formatString={`Id | Subcompte | Descripció | NIF | Domicili | Població | Provincia | C.P.`}
        />
        <RefFVMain/>
    </RefFVContext.Provider>
})
