import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page, useC3Store} from "react-c4";
import {IPHeader} from "./components/IPHeader";
import {IPPreview} from "./components/preview/IPPreview";
import {DashboardPageStore} from "../Dashboard/DashboardPageStore";
import {InvoicesPageStore} from "./InvoicesPageStore";
import {IPGrid} from "./components/IPGrid";
import {IPFooter} from "./components/IPFooter";

const Container = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
`
const LeftContainer = styled.div`
    display: grid;
`

export interface InvoicesPageProps {

}

export const InvoicesPage = page(observer((props: InvoicesPageProps) => {
    let store = useC3Store(() => {
        let storeInst = new InvoicesPageStore();
        return storeInst
    })
    if (store == null) return null
    return <Page
        title={'Factures'}
        subtitle={'Totes les factures'}
        // icon={'inbox'}z
        attributes={[]}
        renderBody={({pageContext}) => {
            return <Container>
                <LeftContainer>
                    <IPHeader/>
                    <IPGrid/>
                    <IPFooter/>
                </LeftContainer>
                <IPPreview/>
            </Container>
        }}
    />
}))
