import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, Classes, Dialog, FileInput, Icon} from "@blueprintjs/core";
import * as XLSX from "xlsx";
import numeral from 'numeral'

const Container = styled.div`
  display: grid;
  justify-items: center;

`

const WarningContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  align-self: center;
  justify-items: center;
  padding: 15px;
  font-size: 15px;
`

export interface ExcelImportDialogProps {
    isOpen
    onClose
    processResult: (name, json, workbook) => any
    formatString?
}

export const ExcelImportDialog = observer((props: ExcelImportDialogProps) => {
    return <Dialog isOpen={props.isOpen} onClose={props.onClose} title="Importar excel">
        <div className={Classes.DIALOG_BODY}>
            <FileInput disabled={false} text="Tria un fitxer..." buttonText={"Explora"} onInputChange={(e) => {
                var files = e.currentTarget.files, f = files[0];
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = new Uint8Array((e.target as any).result);
                    var workbook = XLSX.read(data, {type: 'array'});
                    var first_sheet_name = workbook.SheetNames[0];
                    var worksheet = workbook.Sheets[first_sheet_name];
                    let json = XLSX.utils.sheet_to_json(worksheet, {header: 1, blankrows: false});
                    props.processResult(f.name, json, workbook)
                    // Codi Nom Preu
                    props.onClose()


                    /* DO SOMETHING WITH workbook HERE */
                };
                reader.readAsArrayBuffer(f);
            }}/>
            <div style={{fontSize: 12, padding: 2}}>Format: {props.formatString}</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={props.onClose}>Tancar</Button>
            </div>
        </div>
        <WarningContainer>
            <Icon icon={'warning-sign'} intent={'danger'}/>
            <span><strong>Si no importa bé revisar excel:</strong> columnes amagades o columnes buides entremig.</span>
        </WarningContainer>
    </Dialog>
})
