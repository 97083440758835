import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {bindWithAS, useAS} from "../../../application/utils/utils";
import {useProfileStore} from "../ProfilePage";

const Container = styled.div`
    display: grid;
    
`
const FieldContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: start;
    grid-gap: 8px;
    grid-row-gap: 4px;
`



export interface UserProfileEditorProps {

}

export const UserProfileEditor = observer((props: UserProfileEditorProps) => {
    let store = useProfileStore()

    let as = useAS((field, e) => {
        let fieldName = field.name;
        store.saveProfile({id: 1, [fieldName]: store.form.$(fieldName).value})
    }, 800)
    return <Container>
        <FieldContainer>
            Nom <div><input {...bindWithAS(store.form.$('name'), as)}/></div>
            Cognom 1 <div><input {...bindWithAS(store.form.$('surname1'), as)}/></div>
            Cognom 2 <div><input {...bindWithAS(store.form.$('surname2'), as)}/></div>
            Email <div><input {...bindWithAS(store.form.$('email'), as)}/></div>
        </FieldContainer>
    </Container>
})
