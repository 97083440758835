import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useReactToPrint} from "react-to-print";

const Container = styled.div`
`;

const Page = styled.div`
    display: grid;
    //align-items: stretch;
    //
    //margin: 2mm;
    @media print {
      width: 28mm;
      height: 87mm;
    }
    @page {
      size: 28mm 87mm;
      margin: 0;
    }
    //width: 28mm;
    //height: 87mm;
    //padding: 1mm;
    //padding-right: 3mm;
    //page-break-after: always;
    font-size: 14px;
    background-color: white;
    writing-mode: tb-rl;
    text-orientation: sideways;
    font-family: Verdana;
    transform: rotate(180deg);
    place-content: center;
`;
//51mm 19mm

const Label = styled.div`
    display:grid;
    grid-template-rows: auto auto auto;
    place-items: center;
    place-content: center;
    grid-template-areas: "  Name"
    " Episode"
    " Barcode";
    text-align:center;
    //border: 1px dashed gray;
    //padding-left: 4px;
    grid-gap: 2px;

`;
/*   grid-template-columns: 34mm 25mm;
 grid-template-rows: 5mm 3mm 9mm;
 */


const Name = styled.div`
    grid-area: Name;
    font-weight: bold;
    width: max-content;
`;
const Episode = styled.div`
    grid-area: Episode;

`;

const Barcode = styled.div`
    grid-area: Barcode;
    font-family: 'Libre Barcode 128 Text';
    font-size: 42px;
    //margin-right: 8px;
`;

export interface VisitLabelProps {
    data?: {
        fullName
        visitNumber
        date,
        visit?
    }
    onPrintReady?: (handle) => null

}

export const VisitLabel = observer((props: VisitLabelProps) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (props.onPrintReady) props.onPrintReady(handlePrint)
    }, [])
    const data = props.data
    //     ||
    //     {
    //     fullName: "VILA DUART, FRANCESC",
    //     visitNumber: "367521",
    //     date: "25-02-2020",
    // }
    let fontSize = 14;
    let nameLength = (data.fullName || '').length;
    if(nameLength > 31)
        fontSize = 11
    if(nameLength > 35)
        fontSize = 10
    if(nameLength > 40)
        fontSize = 9
    return <Page ref={componentRef}>
        <link href="https://fonts.googleapis.com/css?family=Libre+Barcode+128+Text" rel="stylesheet"/>
        <Label>
            <Name style={{fontSize}}>{_.toUpper(data.fullName)}</Name>
            <Episode>{data.visitNumber + "   " + data.date}</Episode>
            <Barcode>{data.visitNumber}</Barcode>
        </Label>
    </Page>
})
