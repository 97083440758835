import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'

const Container = styled.div`
    display: grid;
    align-items: center;
    
`

export interface TrayTabletProps {

}

export const TrayTablet = observer((props: TrayTabletProps) => {
    return <Container>
        <svg color={'#34424e'} height={18} version="1.1" x="0px" y="0px"
             viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M8924.5,4026c-26.5-14.4-171-144.5-320.4-293.8l-269.8-267.4l498.6-498.6l501-501l212,212c305.9,298.7,354.1,370.9,354.1,517.9v118l-330,337.2c-183,185.5-361.3,351.7-397.4,370.9C9095.5,4062.2,8991.9,4064.6,8924.5,4026z"/><path
                d="M766.3,3281.8c-171-24.1-305.9-91.5-428.7-214.4c-125.3-125.3-185.5-250.5-216.8-450.4c-16.9-110.8-24.1-1098.3-19.3-3006l7.2-2842.3l69.8-149.4c79.5-173.4,226.4-322.7,395-399.8l113.2-53h4046.6H8780l137.3,67.4c161.4,79.5,291.4,216.8,373.3,392.6l60.2,130.1v2567.7c0,1411.5-4.8,2570.1-9.6,2574.9c-4.8,7.2-224-204.7-484.1-464.9l-474.5-476.9V-962.3v-1919.7H5431.9H2481.3v2601.4v2601.4h2066.7h2064.2l476.9,476.9c260.1,260.1,474.5,484.2,474.5,493.8C7563.6,3315.5,937.3,3308.2,766.3,3281.8z M1604.5,54.1c137.3-65,207.2-180.7,207.2-334.8c0-301.1-310.7-479.3-570.9-325.2c-132.5,77.1-190.3,192.7-175.8,361.3c12,149.3,69.8,233.6,199.9,301.1C1387.7,116.7,1476.8,116.7,1604.5,54.1z"/><path
                d="M6756.7,1887.1L5407.8,538.3l501-498.6l498.6-501L7761.1,892.3l1356.1,1356.1l-493.8,493.8c-272.2,272.2-498.6,493.8-505.8,493.8C8110.3,3236,7498.5,2629,6756.7,1887.1z"/><path
                d="M5191,270.9c-24.1-101.2-202.3-1139.3-197.5-1146.5c12-9.6,1168.2,197.5,1182.7,209.6c7.2,7.2-209.6,236-479.3,505.8C5248.8,285.3,5205.5,323.9,5191,270.9z"/></g></g>
        </svg>
    </Container>
})
