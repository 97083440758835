import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button} from "@blueprintjs/core";
import {ProcessTabLayout} from "../components/ProcessTabLayout";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {AttachmentProcessTabLayout} from "../components/AttachmentProcessTabLayout";

const Container = styled.div`
    display: grid;
    
`

export interface AuthorizationTabProps {
    store: PatientOverviewStore

}

export const AuthorizationTab = observer((props: AuthorizationTabProps) => {
    return <AttachmentProcessTabLayout store={props.store} documentType={'Autorització'}/>
})
