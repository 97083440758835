import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {CellClickedEvent, CellValueChangedEvent, RowNode} from "ag-grid-community";
import {useProdFVStore} from "../ProdFV";
import {observer} from "mobx-react";
import {SVVLinesGridVisitCR} from "../../../components/StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridVisitCR";
import {SVVLinesGridReferrerCE} from "../../../components/StandardVisitView/SVVMain/SVVLines/SVVLinesGrid/components/SVVLinesGridReferrerCE";
import {ProductNameCR} from "./ProductNameCR";
import * as _ from 'lodash'
import {formatFullName, stdDateColumn, stdIntNumericColumn} from "../../../application/utils/utils";
import {Button, Icon, Tooltip} from "@blueprintjs/core";

const Container = styled.div`
  display: grid;
  padding-right: 5px;
`
const GridToolbarContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 8px;
`

export interface ProductEditorProps {

}

export const ProductEditor = observer((props: ProductEditorProps) => {
        let store = useProdFVStore()

        let res = store.eventsRes;

        return <Container>
            <MasterLayout
                renderHeader={() => null}
                // actions={[
                //     {
                //         name: 'document',
                //         iconName: !store.isMatrixFullScreen ? 'double-chevron-right' : 'double-chevron-left',
                //         callback: () => {
                //             store.isMatrixFullScreen = !store.isMatrixFullScreen
                //         }
                //     },
                //     {
                //         name: 'showActives',
                //         iconName: 'strikethrough',
                //         intent: store.showInactive ? "success" : "none",
                //
                //         text: store.showInactive? 'Mostra només proves actives' : 'Mostra proves inactives',
                //         callback: () => {
                //             store.showInactive = !store.showInactive
                //         }
                //     }
                // ]}
                renderToolbar={() => <GridToolbarContainer>
                    <Tooltip content={!store.isMatrixFullScreen ? 'Expandeix' : 'Col·lapsa'}>
                        <Button icon={!store.isMatrixFullScreen ? 'double-chevron-right' : 'double-chevron-left'}
                                active={store.filterRes?.auto} onClick={() => {
                            store.isMatrixFullScreen = !store.isMatrixFullScreen
                        }}/>
                    </Tooltip>
                    {/*<Tooltip content={'Show Actives'}>*/}
                    {/*    <Button icon={'strikethrough'} intent={store.showInactive ? "success" : "none"}*/}
                    {/*            onClick={() => {*/}
                    {/*        // store.filterRes.sameWeek = !store.filterRes?.sameWeek*/}
                    {/*        // store.filterRes.auto = false*/}
                    {/*        // store.fetchMeetupEventsForSelMeetupGroup()*/}
                    {/*    }}/>*/}
                    {/*</Tooltip>*/}
                    <Tooltip content={'From today'}>
                        <Button active={store.filterFromToday} onClick={() => {
                            store.filterFromToday = !store.filterFromToday
                            store.fetchMeetupEventsForSelMeetupGroup()
                        }}>FT</Button>
                    </Tooltip>
                </GridToolbarContainer>}
                body={
                    <C3AgGrid
                        frameworkComponents={{productNameCR: ProductNameCR}}
                        onGridReady={({api}) => store.peGridApi = api}
                        defaultColDef={{
                            suppressMenu: true,
                            filter: false,
                            sortable: true,
                            floatingFilter: false,
                            resizable: true
                        }}
                        columnDefs={[
                            {
                                field: '',
                                headerName: '',
                                width: 30,
                                checkboxSelection: true,
                                // suppressFilter: true,
                            },
                            {
                                field: 'id',
                                headerName: 'Id',
                                pinned: true,
                                width: 50,
                                // suppressFilter: true,
                            },
                            {
                                field: 'startDate',
                                headerName: 'Data',
                                width: 30,
                                pinned: true,
                                filter: 'agTextColumnFilter',
                                ...stdDateColumn(),
                            },
                            {
                                field: 'name',
                                headerName: 'Nom',
                                width: 120,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            // {
                            //     field: 'code',
                            //     headerName: 'Codi Prova',
                            //     width: 120,
                            //     filter: 'agTextColumnFilter',
                            //     floatingFilter: true,
                            // },
                            {
                                field: 'assignedCount',
                                headerName: 'Assignades',
                                width: 120,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'id',
                                headerName: 'Id',
                                editable: false,
                                width: 60,
                                ...stdIntNumericColumn(),
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                        ]}
                        processCellCallback={(params) => {
                            let data = (params.node as RowNode).data
                            return formatFullName({
                                name: data.medName,
                                surname1: data.medSurname1,
                                surname2: data.medSurname1
                            })
                        }}
                        // getContextMenuItems={(params) => {
                        //     // let fileName = store.fr
                        //     var result = [
                        //         {
                        //             // custom item
                        //             name: 'Exporta a Excel',
                        //
                        //             action: function () {
                        //                 // let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
                        //                 // params.api.exportDataAsExcel({
                        //                 //     fileName: `llistat-Proves-${orgName}`,
                        //                 //     processCellCallback: (params) => {
                        //                 //         let data = (params.node as RowNode).data
                        //                 //         if (params.column.getColId() == 'isActive') {
                        //                 //             return data.isActive == true ? 'X' : ''
                        //                 //         }
                        //                 //         return params.value
                        //                 //     }
                        //                 // })
                        //             },
                        //         }
                        //     ]
                        //     return result
                        // }}
                        rowSelection={'single'}
                        rowData={res}
                        selection={store.eventsSel}
                        onCellClicked={(e: CellClickedEvent) => {
                            let split = e.column.getColDef().field.split('.');
                            if (split[0] == 'rs') {
                                let rsId = parseInt(split[1])
                                let productId = e.data.id
                                store.openSelection(productId, rsId)
                                setTimeout(() => {
                                    e.api.ensureColumnVisible(e.column)
                                })
                            }
                        }}
                        cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                            await store.saveEvent({
                                id: e.data.id,
                                [e.colDef.field]: e.newValue
                            })
                        }}
                        // rowData={store.referrersRes}
                        // selection={store.referrersSel}
                        // cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        //     await store.saveReferrer({
                        //         id: e.data.id,
                        //         [e.colDef.field]: e.newValue
                        //     })
                        // }}

                    />
                }
            />
        </Container>
    }
)
