import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {C3AgGrid, getMomentFormatter, MasterLayout} from "react-c4";
import {useSVVStore} from "../../../StandardVisitView";
import {ICellRendererParams, ValueFormatterParams, ValueGetterParams} from "ag-grid-community";
import {Icon} from "@blueprintjs/core";
import {invoiceNumberCell} from "../utils/invoiceNumberCell";
import numeral from 'numeral'
import {stdNumericColumn} from "../../../../../application/utils/utils";


const Container = styled.div`
    display: grid;
    
`

export interface SVVPrivateInvoicingPanelInvoicesGridProps {

}

export const SVVPrivateInvoicingPanelInvoicesGrid = observer((props: SVVPrivateInvoicingPanelInvoicesGridProps) => {
    let store = useSVVStore()
    let momentFormatter = getMomentFormatter('DD/MM/YY');

    return <Container>
        <MasterLayout
            header={false}
            actions={[
                {
                    name: 'new',
                    text: 'Nova factura privada buida',
                    iconName: 'document',
                    callback: () => {
                        store.createPrivateInvoice()
                    }
                },
                {
                    name: 'delete',
                    iconName: 'trash',
                    text: 'Esborra',
                    confirmation: true,
                    confirmationText: `Estàs segur que vols eliminar la pre-factura *${store.privateInvoicesSel?.selectedItem?.draftInvoiceNumber}?`,
                    enabled: store.isInvoiceOpen,
                    callback: async () => {
                        await store.deletePrivateInvoice();
                        store.privateInvoicesSel.selectIndex(0);
                    }
                },
            ]}
            body={<C3AgGrid

                selection={store.privateInvoicesSel}
                rowData={store.privateInvoicesRes}
                defaultColDef={{
                    resizable: true,
                    suppressColumnsToolPanel: true,
                    suppressMenu: true,
                }}
                floatingFilter={false}
                columnDefs={[
                    // {
                    //     field: 'org.name',
                    //     headerName: 'OrgFileView',
                    //     width: 30,
                    // },
                    {
                        field: 'invoiceNumberText',
                        ...invoiceNumberCell(),
                        headerName: 'F.',
                        width: 64,
                    }, {
                        field: 'invoiceDate',
                        headerName: 'Data',
                        valueFormatter: ({value}) => {
                            return momentFormatter.formatDate(value)
                        },
                        valueParser: ({newValue}) => {
                            return momentFormatter.parseDate(newValue)
                        },
                        width: 80,
                    },
                    {
                        field: 'paid',
                        headerName: 'Falta', // [OK] / 330/450 (falta 45)
                        cellRendererFramework: (params: ICellRendererParams) => {
                            let value = params.value;
                            if (params.data.id < 0 || !params.data.invoiceNumber) return <div></div>
                            if (value && params.data.remainingToPay == 0) {
                                return <Icon key={params.data.id} icon={'tick'}
                                             intent={'success'}/>
                            } else {
                                return <div key={params.data.id}>
                                    <Icon icon={value ? 'tick' : 'credit-card'} intent={value ? 'success' : 'warning'}/>
                                    {params.data.remainingToPay >= 0 ? <span
                                            style={{marginLeft: 4}}>{!value && `${numeral(params.data.remainingToPay).format('0.00')} €`}</span>
                                        : <span
                                            style={{
                                                marginLeft: 4,
                                                color: 'green'
                                            }}>{`+${numeral(params.data.remainingToPay * -1).format('0.00')} €`}</span>

                                    }

                                </div>
                            }

                        },
                        width: 80,
                    },
                    {
                        field: 'total',
                        headerName: 'Total €',
                        width: 120,
                      ...stdNumericColumn(),

                    },
                    {
                        field: 'lineCount',
                        headerName: 'Línies',
                        width: 90,
                    },

                ]}
            />}
        />
    </Container>
})
