import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {formatFormalName} from "../../application/utils/utils";
import {SchedulePageHeader} from "../SchedulePage/SchedulePageHeader";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {Page} from "react-c4";
import {ARVStore} from "../../components/AppointmentsReviewView/ARVStore";
import {AppointmentsReviewPageHeader} from "./AppointmentsReviewPageHeader";
import {AppointmentsReviewView} from "../../components/AppointmentsReviewView/AppointmentsReviewView";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface AppointmentsReviewPageProps {
     pageContext
}

export const AppointmentsReviewPage = page(observer((props: AppointmentsReviewPageProps) => {
    let [store, setStore] = useState(null as ARVStore);

    return <Page
        title={'Revisió de citacions web'}
        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <AppointmentsReviewPageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <AppointmentsReviewView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
