import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Icon, Popover} from "@blueprintjs/core";
import {useAppStore} from "../../../utils/utils";

const Container = styled.div`
    display: grid;
    position: relative;
    //padding: 4px;
    padding: 2px 3px;
`
const LabelContainer = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 8px;
    font-weight: bold;
    line-height: 0px;
    color: #137cbd;
`

const PopoverContainer = styled.div`
    padding: 8px;
`

export interface TrayPrinterProps {
    name
}

export const TrayPrinter = observer((props: TrayPrinterProps) => {
    let appStore = useAppStore()

    return <Popover content={<PopoverContainer>
        Impressora desconnectada
    </PopoverContainer>}>
        <Container>
            <Icon icon={'print'}/>
            <LabelContainer>{'X' || props.name}</LabelContainer>
        </Container>
    </Popover>
})
