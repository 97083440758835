import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, ButtonGroup, ControlGroup, InputGroup} from "@blueprintjs/core";
import {formatFormalName, useAppStore} from "../../../utils/utils";

const Container = styled.div`
  display: grid;
  border-top: 4px solid #137cbd;
  //height: 350px;
  //width: 250px;
  background-color: #FCFDFD;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  padding: 16px;
  align-content: stretch;
  grid-gap: 12px;


`
const TitleContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: bold;
`
const UserOrbContainer = styled.div`
  height: 180px;
  width: 180px;
  border-radius: 50%;
  background-color: #9fc8e2;
  align-self: center;
`
const NavButtonsContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 16px;
`
const LogoSectionContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  place-content: center;
  place-items: center;
`
const TmpUserNameContainer = styled.div`
  display: grid;
  place-content: center;
  font-size: 16px;
  height: 30px;
  font-family: Roboto, sans-serif;
  grid-gap: 8px;
  grid-template-columns: auto 1fr;
  align-items: center;
  
`

export interface LoginFormProps {

}

export const LoginForm = observer((props: LoginFormProps) => {
    let appStore = useAppStore()
    let store = appStore.auth.loginFormStore;
    let auth = appStore.auth;
    auth.lockedOnly
    let canUsePIN = auth.lockedOnly
    let rightFlat = {borderBottomRightRadius: 0, borderTopRightRadius: 0};
    let leftFlat = {borderBottomLeftRadius: 0, borderTopLeftRadius: 0};
    let allFlat = {borderRadius: 0}

    let input = null
    let inputButton = <Button rightIcon={'arrow-right'} onClick={() => store.goForward()}/>;
    if (store.loginMethod == "username") {
        // inputButton = null
        if (store.step == 0) {
            input = <ControlGroup
                style={{display: 'grid', gridTemplateColumns: '1fr 50px'}}>
                <InputGroup large {...store.loginForm.$('username').bind()}
                            placeholder={'Usuari'} leftIcon={'user'}
                />
                {inputButton}
            </ControlGroup>
        } else if (store.step == 1) {
            input = <ControlGroup
                style={{display: 'grid', gridTemplateColumns: '1fr 50px'}}>
                <InputGroup large {...store.loginForm.$('password').bind()}
                            placeholder={`Clau d'accés`} leftIcon={'lock'}
                            type={'password'}
                />
                {inputButton}
            </ControlGroup>
        }
    } else if (store.loginMethod == "pin") {
        input = <ControlGroup
            style={{display: 'grid', gridTemplateColumns: '1fr', width: 284}}>
            <InputGroup large {...store.loginForm.$('pin').bind()} placeholder={`PIN`} leftIcon={'lock'}
                        type={'password'}

            />
            {/*{inputButton}*/}
        </ControlGroup>
    }
    return <Container>
        <TitleContainer>
            {/*{<Button minimal disabled={!store.canGoBack} icon={'arrow-left'} onClick={() => store.goBack()}/>}*/}
            {/*Inicia sessió - DIMAT*/}
            {/*(C) 2020 MedicalOne S.L.*/}
            {/*soporte@medicalone.com*/}
        </TitleContainer>
        <LogoSectionContainer>
            <UserOrbContainer/>
            {/*<span>Laura Rocosa</span>*/}
        </LogoSectionContainer>
        {store.step == 0 && <ButtonGroup style={{display: 'grid', gridTemplateColumns: '50% 50%'}} minimal>
          <Button active={store.loginMethod == "username"} onClick={() => store.loginMethod = "username"}
                  style={rightFlat}>
            Usuari
          </Button>
          <Button active={store.loginMethod == "pin"} onClick={() => store.loginMethod = "pin"}
                  disabled={!store.canUsePin} style={leftFlat}>
            PIN
          </Button>
        </ButtonGroup>}
        {store.step == 1 && <TmpUserNameContainer>
            {<Button minimal disabled={!store.canGoBack} icon={'arrow-left'} onClick={() => store.goBack()}/>}
          <span>{formatFormalName(store.tmpUserRes)}</span>
        </TmpUserNameContainer>}
        <form onSubmit={(e) => {
            e.preventDefault()
            store.goForward();
        }}>
            {input}
        </form>
        {/*<InputGroup {...auth.loginForm.$('pin').bind()} placeholder={'PIN'} type={'password'} leftIcon={'lock'}/>*/}
        {/*<NavButtonsContainer>*/}
        {/*    /!*<Button >Enrere</Button>*!/*/}

        {/*    <Button rightIcon={'arrow-right'}>Endavant</Button>*/}
        {/*</NavButtonsContainer>*/}
    </Container>
})
