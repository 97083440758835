import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {BusinessLineFVStore} from "../../files-views/BusinessLineFileView/BusinessLineFVStore";
import {BusinessLineFileView} from "../../files-views/BusinessLineFileView/BusinessLineFileView";
import {BusinessLineFilePageHeader} from "./BusinessLineFilePageHeader";


const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface BusinessLineFilePageProps {
     pageContext
}

export const BusinessLineFilePage = page(observer((props: BusinessLineFilePageProps) => {
    let [store, setStore] = useState(null as BusinessLineFVStore);

    return <Page
        title={'Línies de negoci'}

        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <BusinessLineFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <BusinessLineFileView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
