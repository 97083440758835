import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSCVStore} from "../../../ScheduleView";
import Select from 'react-select'
// import 'antd/dist/antd.css'
const Container = styled.div`
  display: grid;
  width: 100%;
  padding: 4px;
`

export interface EFEFeatureSelectorProps {

}

export const EFEFeatureSelector = observer((props: EFEFeatureSelectorProps) => {
    let store = useSCVStore()
    return <Container>
        {/*<Select options={store.appStore.features} tagRender={tagRender} optionLabelProp={'name'}/>*/}
        <Select options={store.appStore.features} getOptionLabel={o => o.name} getOptionValue={o => o.id}
                isMulti
                onChange={(items, action) => {
                    console.log('change', items, action);
                    if (action.action == "select-option") {
                        store.addFeature(action.option)
                    } else if( action.removedValue) {
                        store.removeFeature(action.removedValue.id)
                    }
                }}/>
    </Container>
})
