import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ARVStore} from "./ARVStore";
import {useC3Store} from "react-c4";
import {ARVMain} from "./ARVMain/ARVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const ARVContext = React.createContext(null as ARVStore)
export const useARVStore = () => useContext(ARVContext)

export interface AppointmentsReviewViewProps {
    pageContext?
    onStoreReady: (s: ARVStore) => any
}

export const AppointmentsReviewView = observer((props: AppointmentsReviewViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new ARVStore()
        storeInst.init();
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <ARVContext.Provider value={store}>
        <Container>
            <ARVMain/>
        </Container>
    </ARVContext.Provider>
})
