import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {BSVStore} from "../../components/BasicStatisticsView/BSVStore";

const Container = styled.div`
    display: grid;
    
`

export interface BasicStatisticsPageHeaderProps {
    store: BSVStore
}

export const BasicStatisticsPageHeader = observer((props: BasicStatisticsPageHeaderProps) => {
    return <Container>

    </Container>
})
