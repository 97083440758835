import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {ProdFVStore} from "../../files-views/Product/ProdFVStore";
import {Button, InputGroup, Tag, ButtonGroup} from "@blueprintjs/core";
import {bindWithAS, useAS} from "../../application/utils/utils";
import {observer} from "mobx-react";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
`

const StatusContainer = styled.div`
    display: grid;
    place-content: center;
    place-items: center;
    grid-auto-flow: column;
    grid-gap: 8px;
`
const ButtonContainer = styled.div`
  padding-left: 5px
`

export interface ProductPageHeaderProps {
    store: ProdFVStore
}

export const ProductFilePageHeader = observer((props: ProductPageHeaderProps) => {
    let store = props.store
    let as = useAS(() => {

    })
    if (store == null) return null

    let eventName = store.eventsSel.selectedItem?.name;
    let meetupEventName = store.allMeetupGroupsTabsSel.selectedItem?.name;
    // let orgName = store.form.$('orgs').value.shortName;
    return <Container>
        <Button icon={'application'} active={store.isMatrixFullScreen}
                onClick={() => store.isMatrixFullScreen = !store.isMatrixFullScreen}>Vista global</Button>
        {/*<Button icon={'edit'}*/}
        {/*        onClick={() => store.pageContext.navigate({to: 'ratesFilePage'})}>Edició de*/}
        {/*    barems</Button>*/}
        {/*<ButtonGroup>*/}
        {/*    {store.orgsSel.items.map(it => {*/}
        {/*        let selected = store.orgsSel.isSelected((it.id));*/}
        {/*        return <Button*/}
        {/*        active={selected}*/}
        {/*        onClick={()=>store.orgsSel.selectId(it.id)}*/}
        {/*        >*/}
        {/*            {it.shortName}*/}
        {/*        </Button>*/}
        {/*    })}*/}
        {/*</ButtonGroup>*/}
        <ButtonContainer>
            <Button icon={'refresh'}
                    onClick={async () => {
                        await store.refresh()
                    }}
            />
        </ButtonContainer>
        <InputGroup {...store.form.$('rateSheetSearch').bind()} leftIcon={'search'}
                    placeholder={'Cerca fulls de barems...'}/>

        {eventName && meetupEventName && <StatusContainer>
            Assignant event <Tag minimal large intent={'warning'}> {eventName}</Tag> a
            meetup event <Tag minimal large intent={'warning'}>  {meetupEventName}</Tag>
            {/*a organització*/}
            {/*<Tag minimal large intent={'warning'}>  {orgName}</Tag>*/}
        </StatusContainer>}
    </Container>
})
