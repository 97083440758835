import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import moment from "moment";
import {SCVStore} from "../../../SCVStore";

const Container = styled.div`
    
    padding: 70px 30px ;
  //background: #0f9960;
  border: none;
  box-sizing: border-box;
  color: white;
  width: min-content;
  text-rendering: geometricPrecision;
  
  background: rgb(2,0,36);
  background: linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  
  //transform: scale(0.6);
  //background: rgb(131,58,180);
  //background: linear-gradient(135deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
`
const EventsContainer = styled.div`
    aspect-ratio: 9 / 16;
    display: flex;
    flex-direction: column;
  justify-content: space-evenly;
  gap: 8px;
  width: 375px;
  font-size: 14px;
  border: 1px solid white;
  box-sizing: border-box;
  padding: 16px;
  padding-top: 55px;
`
const EventContainer = styled.div`
    
`
const EventDowContainer = styled.span`
    text-transform: uppercase;
  font-weight: bold;
`
const EventTitleContainer = styled.span`
  text-transform: uppercase;
`
const EventLocationContainer = styled.span`
  text-transform: capitalize;
  font-style: italic;
`
const EventTimeContainer = styled.span`
    
`

export interface IgStoryScheduleProps {
    flatEvents: any[]
    store: SCVStore
}
let data = [
    {
        "originals": [
            {
                "id": 1717,
                "name": "LANGUAGE EXCHANGE & BOARD GAMES + DARTS & POOL + JAMBOREE FREE DISCO 😃🎉😃",
                "description": "Hi! Would you like to enjoy a great time while practicing languages, meeting new people and having lots of fun??\n\nWe have the definitive best MULTICULTURAL event in Barcelona !!! This MONDAY we’ll meet at LA PECERA POOL (C/ de Calàbria 171)\n\nYou can enjoy your time playing with BOARD GAMES, POOL and DARTS!!🎲🎯🎱(you can bring your own games if you want to!)\n\nThere's TERRACE too and multiple options to drink and eat!😋\n\nDarts tournament 🎯: We will organize in teams and participate in a darts tournament. The winner will get free dinner!\n\n2x1 SANGRIA\n\n2x1 MOJITOS &\nSEX ON THE BEACH COCKTAILS 😃🎉😃\n\nYou can enter by list from 12:00 pm until 2:00 am at JAMBOREE DISCO (FREE) 😃🎉😃\n\nGueslist: MEETUP\n\n**With your assistance you consent to give us your image rights only to use them for promotional networking.\n\n——————————————————————\n\nHola!\n\n¿Te gustaría disfrutar de un buen rato mientras practicas idiomas, conoces a gente nueva y te diviertes?? No os lo penséis! nos vemos este LUNES en LA PECERA POOL!! (Carrer de Calabria, 171).\n\nPuedes disfrutar de JUEGOS DE MESA, BILLAR y DARDOS!!🎲🎱🎯(puedes traerte tus propios juegos si quieres!)\n\nEl bar dispone de TERRAZA y múltiples opciones de comida y bebida!😋\n\nTorneo de dardos 🎯: Nos organizaremos en equipos y participaremos en un torneo de dardos. ¡El ganador recibirá una cena gratis!\n\n2x1 SANGRIA\n\n2x1 MOJITOS &\nSEX ON THE BEACH COCKTAILS 😃🎉😃\n\nPodemos entrar por lista a JAMBOREE DISCO desde las 24:00h hasta la 2:00h 😃🎉😃\n\nLista: MEETUP\n\nOur events are free of charge. Nuestros eventos son gratuitos.\n\n**Con la asistencia a nuestros eventos consientes en la cesión de tus derechos de imagen para nuestro uso promocional en redes sociales.",
                "startDate": "2022-12-12T17:00:30.000Z",
                "endDate": "2022-12-12T23:00:30.000Z",
                "location": "Pecera Pool",
                "longitude": null,
                "latitude": null,
                "capacity": null,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 4,
                "userId": 1,
                "origId": 1427,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=MO",
                "imageDocumentId": 62
            },
            {
                "id": 1718,
                "name": "LANGUAGE EXCHANGE & BOARD GAMES + DARTS & POOL + JAMBOREE FREE DISCO 😃🎉😃",
                "description": "Hi! Would you like to enjoy a great time while practicing languages, meeting new people and having lots of fun??\n\nWe have the definitive best MULTICULTURAL event in Barcelona !!! This MONDAY we’ll meet at LA PECERA POOL (C/ de Calàbria 171)\n\nYou can enjoy your time playing with BOARD GAMES, POOL and DARTS!!🎲🎯🎱(you can bring your own games if you want to!)\n\nThere's TERRACE too and multiple options to drink and eat!😋\n\nDarts tournament 🎯: We will organize in teams and participate in a darts tournament. The winner will get free dinner!\n\n2x1 SANGRIA\n\n2x1 MOJITOS &\nSEX ON THE BEACH COCKTAILS 😃🎉😃\n\nYou can enter by list from 12:00 pm until 2:00 am at JAMBOREE DISCO (FREE) 😃🎉😃\n\nGueslist: MEETUP\n\n**With your assistance you consent to give us your image rights only to use them for promotional networking.\n\n——————————————————————\n\nHola!\n\n¿Te gustaría disfrutar de un buen rato mientras practicas idiomas, conoces a gente nueva y te diviertes?? No os lo penséis! nos vemos este LUNES en LA PECERA POOL!! (Carrer de Calabria, 171).\n\nPuedes disfrutar de JUEGOS DE MESA, BILLAR y DARDOS!!🎲🎱🎯(puedes traerte tus propios juegos si quieres!)\n\nEl bar dispone de TERRAZA y múltiples opciones de comida y bebida!😋\n\nTorneo de dardos 🎯: Nos organizaremos en equipos y participaremos en un torneo de dardos. ¡El ganador recibirá una cena gratis!\n\n2x1 SANGRIA\n\n2x1 MOJITOS &\nSEX ON THE BEACH COCKTAILS 😃🎉😃\n\nPodemos entrar por lista a JAMBOREE DISCO desde las 24:00h hasta la 2:00h 😃🎉😃\n\nLista: MEETUP\n\nOur events are free of charge. Nuestros eventos son gratuitos.\n\n**Con la asistencia a nuestros eventos consientes en la cesión de tus derechos de imagen para nuestro uso promocional en redes sociales.",
                "startDate": "2022-12-19T17:00:30.000Z",
                "endDate": "2022-12-19T23:00:30.000Z",
                "location": "Pecera Pool",
                "longitude": null,
                "latitude": null,
                "capacity": null,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 4,
                "userId": 1,
                "origId": 1427,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=MO",
                "imageDocumentId": 62
            }
        ],
        "count": 2,
        "id": 1718,
        "name": "LANGUAGE EXCHANGE & BOARD GAMES + DARTS & POOL + JAMBOREE FREE DISCO 😃🎉😃",
        "description": "Hi! Would you like to enjoy a great time while practicing languages, meeting new people and having lots of fun??\n\nWe have the definitive best MULTICULTURAL event in Barcelona !!! This MONDAY we’ll meet at LA PECERA POOL (C/ de Calàbria 171)\n\nYou can enjoy your time playing with BOARD GAMES, POOL and DARTS!!🎲🎯🎱(you can bring your own games if you want to!)\n\nThere's TERRACE too and multiple options to drink and eat!😋\n\nDarts tournament 🎯: We will organize in teams and participate in a darts tournament. The winner will get free dinner!\n\n2x1 SANGRIA\n\n2x1 MOJITOS &\nSEX ON THE BEACH COCKTAILS 😃🎉😃\n\nYou can enter by list from 12:00 pm until 2:00 am at JAMBOREE DISCO (FREE) 😃🎉😃\n\nGueslist: MEETUP\n\n**With your assistance you consent to give us your image rights only to use them for promotional networking.\n\n——————————————————————\n\nHola!\n\n¿Te gustaría disfrutar de un buen rato mientras practicas idiomas, conoces a gente nueva y te diviertes?? No os lo penséis! nos vemos este LUNES en LA PECERA POOL!! (Carrer de Calabria, 171).\n\nPuedes disfrutar de JUEGOS DE MESA, BILLAR y DARDOS!!🎲🎱🎯(puedes traerte tus propios juegos si quieres!)\n\nEl bar dispone de TERRAZA y múltiples opciones de comida y bebida!😋\n\nTorneo de dardos 🎯: Nos organizaremos en equipos y participaremos en un torneo de dardos. ¡El ganador recibirá una cena gratis!\n\n2x1 SANGRIA\n\n2x1 MOJITOS &\nSEX ON THE BEACH COCKTAILS 😃🎉😃\n\nPodemos entrar por lista a JAMBOREE DISCO desde las 24:00h hasta la 2:00h 😃🎉😃\n\nLista: MEETUP\n\nOur events are free of charge. Nuestros eventos son gratuitos.\n\n**Con la asistencia a nuestros eventos consientes en la cesión de tus derechos de imagen para nuestro uso promocional en redes sociales.",
        "startDate": "2022-12-19T17:00:30.000Z",
        "endDate": "2022-12-19T23:00:30.000Z",
        "location": "Pecera Pool",
        "longitude": null,
        "latitude": null,
        "capacity": null,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": false,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 4,
        "userId": 1,
        "origId": 1427,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=MO",
        "imageDocumentId": 62,
        "dayOfWeek": "1",
        "timeOfDay": "2"
    },
    {
        "originals": [
            {
                "id": 1719,
                "name": "LANGUAGE EXCHANGE & SALSA-BACHATA + JAMBOREE FREE DISCO 😃🎉😃",
                "description": "We can DANCE SALSA-BACHATA FREE lessons (optional)\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\nwww barcelonainternationalsocial.com\n\nFinally we have the definitive event for everyone, a SALSA-BACHATA ALL LEVELS FREE event with a professional TEACHER 😃🎉😃\n\nWhere we can socialize, practice languages ​​and dance (optional).\n\nYou only pay for your drink\n\nIn the best place EL SABOR CUBANO DE GRACIA ( Carrer Francisco Giner 32, Barcelona)\n\nwith years of experience and an international environment\n\nenjoy the AUTHENTIC CUBAN MOJITOS 😃🎉😃\n\nCome and enjoy this unique experience\n\nYou can enter by list from 24:00h pm until 1:00 am at JAMBOREE (FREE)😃🎉😃\n\nGueslist: FRIENDS LIST\n\nConfirmation HERE: WhatsApp: +34645867255\nbefore 11:55 pm\n\n***\n\nPor fin tenemos el evento definitivo que tod@s, un evento de SALSA-BACHATA FREE para TODOS LOS NIVELES 😃🎉😃\n\nCon un dinamizador PROFESIONAL, donde podemos sociabilizar, practicar idiomas y bailar (opcional).\n\nSolo se paga tu consumicion\n\nEn el mejor lugar EL SABOR CUBANO DE GRACIA (Carrer Francisco Giner 32, Barcelona)\n\nCon años de experiencia y un ambiente internacional\n\nDisfruta de los MOJITOS CUBANOS\n\nVen y disfruta de esta experiencia unica\n\nPodeis entrar a la disco JAMBOREE (FREE) desde las 24:00h a 1am\n\nLista: FRIENDS LIST 😃🎉😃\n\nConfirmation HERE: WhatsApp: +34645867255\nantes de las 23:55h",
                "startDate": "2022-12-13T17:00:12.000Z",
                "endDate": "2022-12-13T22:00:12.000Z",
                "location": "Sabor Cubano de Gracia",
                "longitude": null,
                "latitude": null,
                "capacity": 125,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 0,
                "userId": 1,
                "origId": 1441,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=TU",
                "imageDocumentId": null
            },
            {
                "id": 1756,
                "name": "LANGUAGE EXCHANGE & SALSA-BACHATA + JAMBOREE FREE DISCO 😃🎉😃",
                "description": "We can DANCE SALSA-BACHATA FREE lessons (optional)\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\nwww barcelonainternationalsocial.com\n\nFinally we have the definitive event for everyone, a SALSA-BACHATA ALL LEVELS FREE event with a professional TEACHER 😃🎉😃\n\nWhere we can socialize, practice languages ​​and dance (optional).\n\nYou only pay for your drink\n\nIn the best place EL SABOR CUBANO DE GRACIA ( Carrer Francisco Giner 32, Barcelona)\n\nwith years of experience and an international environment\n\nenjoy the AUTHENTIC CUBAN MOJITOS 😃🎉😃\n\nCome and enjoy this unique experience\n\nYou can enter by list from 24:00h pm until 1:00 am at JAMBOREE (FREE)😃🎉😃\n\nGueslist: FRIENDS LIST\n\nConfirmation HERE: WhatsApp: +34645867255\nbefore 11:55 pm\n\n***\n\nPor fin tenemos el evento definitivo que tod@s, un evento de SALSA-BACHATA FREE para TODOS LOS NIVELES 😃🎉😃\n\nCon un dinamizador PROFESIONAL, donde podemos sociabilizar, practicar idiomas y bailar (opcional).\n\nSolo se paga tu consumicion\n\nEn el mejor lugar EL SABOR CUBANO DE GRACIA (Carrer Francisco Giner 32, Barcelona)\n\nCon años de experiencia y un ambiente internacional\n\nDisfruta de los MOJITOS CUBANOS\n\nVen y disfruta de esta experiencia unica\n\nPodeis entrar a la disco JAMBOREE (FREE) desde las 24:00h a 1am\n\nLista: FRIENDS LIST 😃🎉😃\n\nConfirmation HERE: WhatsApp: +34645867255\nantes de las 23:55h",
                "startDate": "2022-12-20T17:00:12.000Z",
                "endDate": "2022-12-20T22:00:12.000Z",
                "location": "Sabor Cubano de Gracia",
                "longitude": null,
                "latitude": null,
                "capacity": 125,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 0,
                "userId": 1,
                "origId": 1441,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=TU",
                "imageDocumentId": null
            }
        ],
        "count": 2,
        "id": 1756,
        "name": "LANGUAGE EXCHANGE & SALSA-BACHATA + JAMBOREE FREE DISCO 😃🎉😃",
        "description": "We can DANCE SALSA-BACHATA FREE lessons (optional)\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\nwww barcelonainternationalsocial.com\n\nFinally we have the definitive event for everyone, a SALSA-BACHATA ALL LEVELS FREE event with a professional TEACHER 😃🎉😃\n\nWhere we can socialize, practice languages ​​and dance (optional).\n\nYou only pay for your drink\n\nIn the best place EL SABOR CUBANO DE GRACIA ( Carrer Francisco Giner 32, Barcelona)\n\nwith years of experience and an international environment\n\nenjoy the AUTHENTIC CUBAN MOJITOS 😃🎉😃\n\nCome and enjoy this unique experience\n\nYou can enter by list from 24:00h pm until 1:00 am at JAMBOREE (FREE)😃🎉😃\n\nGueslist: FRIENDS LIST\n\nConfirmation HERE: WhatsApp: +34645867255\nbefore 11:55 pm\n\n***\n\nPor fin tenemos el evento definitivo que tod@s, un evento de SALSA-BACHATA FREE para TODOS LOS NIVELES 😃🎉😃\n\nCon un dinamizador PROFESIONAL, donde podemos sociabilizar, practicar idiomas y bailar (opcional).\n\nSolo se paga tu consumicion\n\nEn el mejor lugar EL SABOR CUBANO DE GRACIA (Carrer Francisco Giner 32, Barcelona)\n\nCon años de experiencia y un ambiente internacional\n\nDisfruta de los MOJITOS CUBANOS\n\nVen y disfruta de esta experiencia unica\n\nPodeis entrar a la disco JAMBOREE (FREE) desde las 24:00h a 1am\n\nLista: FRIENDS LIST 😃🎉😃\n\nConfirmation HERE: WhatsApp: +34645867255\nantes de las 23:55h",
        "startDate": "2022-12-20T17:00:12.000Z",
        "endDate": "2022-12-20T22:00:12.000Z",
        "location": "Sabor Cubano de Gracia",
        "longitude": null,
        "latitude": null,
        "capacity": 125,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": false,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 0,
        "userId": 1,
        "origId": 1441,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=TU",
        "imageDocumentId": null,
        "dayOfWeek": "2",
        "timeOfDay": "2"
    },
    {
        "originals": [
            {
                "id": 1683,
                "name": "LANGUAGE EXCHANGE & FREE TAPAS & DARTS TOURNAMENT + TWENTIES (FREE)  😃🎉😃",
                "description": "Hi!\nThis WEDNESDAY we are doing a language exchange at PECERA POOL! (C/ Calabria 171. Barcelona).\n\nThere will be a DARTS TOURNAMENT (optional): the prize for the WINNER team will be a DINNER 🙂🙂🙂\n\nThe place is huge! It has POOL, DARTS and TERRACE!!!\n\nIn this event you'll have the possibility to meet new interesting and international people, practice your language skills and have lots of fun!\n\nThere will be board games to play with too 🃏🎲😋\n\nGood background music, drinks, food and a VARIETY OF FREE TAPAS\n\nSPECIAL offers for our group: SANGRIA & CAVA SANGRIA 🙂🙂🙂\n\nCome, you will have an excellent time with a group that has been consolidated for a long time.\n\nTWENTIES DISCO (FREE)😃🎉😃\n\n- FREE PASS TILL 1:30 am\n\nGueslist: FRIENDS LIST\n\nSEARCH, COMPARE ...BUT YOU WON'T FIND A BETTER EVENT.\n\n*** With your assistance you consent to give us your image rights only to use them for promotional networking.\n---\n\n¡Hola! ¡Este MIÉRCOLES hacemos un intercambio de idiomas en PECERA POOL! (C/ Calabria 171. Barcelona).\n\nHabrá TORNEO DE DARDOS (opcional): el premio para el equipo GANADOR será una CENA 🙂🙂🙂\n\n¡El lugar es enorme! Tiene POOL, DARDOS y TERRAZA!!! ¡En este evento tendrás la posibilidad de conocer gente interesante e internacional, practicar tus habilidades lingüísticas y divertirte mucho!\n\nHabrá juegos de mesa para jugar también 🃏🎲😋 Buena música ambiental, bebidas, comida y VARIEDAD DE TAPAS GRATIS\n\nOfertas ESPECIALES para nuestro grupo: SANGRIA & CAVA SANGRIA 🙂🙂🙂 Ven, lo pasarás excelente con un grupo que se ha consolidado durante mucho tiempo.\n\nDISCO TWENTIES (GRATIS)😃🎉😃\n\n-FREE HASTA LA 1:30 am\n\nLista de invitados: FRIENDS LIST\n\nBUSCA, COMPARA... PERO NO ENCONTRARÁS MEJOR EVENTO.\n\n***Con tu asistencia, acepta sus derechos de imagen solo para usarlos para redes promocionales.",
                "startDate": "2022-12-14T17:00:54.000Z",
                "endDate": "2022-12-14T23:00:54.000Z",
                "location": "Pecera Pool",
                "longitude": null,
                "latitude": null,
                "capacity": 150,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": true,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 1,
                "userId": 1,
                "origId": 1600,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=WE",
                "imageDocumentId": null
            },
            {
                "id": 1765,
                "name": "LANGUAGE EXCHANGE & FREE TAPAS & DARTS TOURNAMENT + TWENTIES (FREE)  😃🎉😃",
                "description": "Hi!\nThis WEDNESDAY we are doing a language exchange at PECERA POOL! (C/ Calabria 171. Barcelona).\n\nThere will be a DARTS TOURNAMENT (optional): the prize for the WINNER team will be a DINNER 🙂🙂🙂\n\nThe place is huge! It has POOL, DARTS and TERRACE!!!\n\nIn this event you'll have the possibility to meet new interesting and international people, practice your language skills and have lots of fun!\n\nThere will be board games to play with too 🃏🎲😋\n\nGood background music, drinks, food and a VARIETY OF FREE TAPAS\n\nSPECIAL offers for our group: SANGRIA & CAVA SANGRIA 🙂🙂🙂\n\nCome, you will have an excellent time with a group that has been consolidated for a long time.\n\nTWENTIES DISCO (FREE)😃🎉😃\n\n- FREE PASS TILL 1:30 am\n\nGueslist: FRIENDS LIST\n\nSEARCH, COMPARE ...BUT YOU WON'T FIND A BETTER EVENT.\n\n*** With your assistance you consent to give us your image rights only to use them for promotional networking.\n---\n\n¡Hola! ¡Este MIÉRCOLES hacemos un intercambio de idiomas en PECERA POOL! (C/ Calabria 171. Barcelona).\n\nHabrá TORNEO DE DARDOS (opcional): el premio para el equipo GANADOR será una CENA 🙂🙂🙂\n\n¡El lugar es enorme! Tiene POOL, DARDOS y TERRAZA!!! ¡En este evento tendrás la posibilidad de conocer gente interesante e internacional, practicar tus habilidades lingüísticas y divertirte mucho!\n\nHabrá juegos de mesa para jugar también 🃏🎲😋 Buena música ambiental, bebidas, comida y VARIEDAD DE TAPAS GRATIS\n\nOfertas ESPECIALES para nuestro grupo: SANGRIA & CAVA SANGRIA 🙂🙂🙂 Ven, lo pasarás excelente con un grupo que se ha consolidado durante mucho tiempo.\n\nDISCO TWENTIES (GRATIS)😃🎉😃\n\n-FREE HASTA LA 1:30 am\n\nLista de invitados: FRIENDS LIST\n\nBUSCA, COMPARA... PERO NO ENCONTRARÁS MEJOR EVENTO.\n\n***Con tu asistencia, acepta sus derechos de imagen solo para usarlos para redes promocionales.",
                "startDate": "2022-12-21T17:00:54.000Z",
                "endDate": "2022-12-21T23:00:54.000Z",
                "location": "Pecera Pool",
                "longitude": null,
                "latitude": null,
                "capacity": 150,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": true,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 25,
                "userId": 1,
                "origId": 1600,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=WE",
                "imageDocumentId": null
            }
        ],
        "count": 2,
        "id": 1765,
        "name": "LANGUAGE EXCHANGE & FREE TAPAS & DARTS TOURNAMENT + TWENTIES (FREE)  😃🎉😃",
        "description": "Hi!\nThis WEDNESDAY we are doing a language exchange at PECERA POOL! (C/ Calabria 171. Barcelona).\n\nThere will be a DARTS TOURNAMENT (optional): the prize for the WINNER team will be a DINNER 🙂🙂🙂\n\nThe place is huge! It has POOL, DARTS and TERRACE!!!\n\nIn this event you'll have the possibility to meet new interesting and international people, practice your language skills and have lots of fun!\n\nThere will be board games to play with too 🃏🎲😋\n\nGood background music, drinks, food and a VARIETY OF FREE TAPAS\n\nSPECIAL offers for our group: SANGRIA & CAVA SANGRIA 🙂🙂🙂\n\nCome, you will have an excellent time with a group that has been consolidated for a long time.\n\nTWENTIES DISCO (FREE)😃🎉😃\n\n- FREE PASS TILL 1:30 am\n\nGueslist: FRIENDS LIST\n\nSEARCH, COMPARE ...BUT YOU WON'T FIND A BETTER EVENT.\n\n*** With your assistance you consent to give us your image rights only to use them for promotional networking.\n---\n\n¡Hola! ¡Este MIÉRCOLES hacemos un intercambio de idiomas en PECERA POOL! (C/ Calabria 171. Barcelona).\n\nHabrá TORNEO DE DARDOS (opcional): el premio para el equipo GANADOR será una CENA 🙂🙂🙂\n\n¡El lugar es enorme! Tiene POOL, DARDOS y TERRAZA!!! ¡En este evento tendrás la posibilidad de conocer gente interesante e internacional, practicar tus habilidades lingüísticas y divertirte mucho!\n\nHabrá juegos de mesa para jugar también 🃏🎲😋 Buena música ambiental, bebidas, comida y VARIEDAD DE TAPAS GRATIS\n\nOfertas ESPECIALES para nuestro grupo: SANGRIA & CAVA SANGRIA 🙂🙂🙂 Ven, lo pasarás excelente con un grupo que se ha consolidado durante mucho tiempo.\n\nDISCO TWENTIES (GRATIS)😃🎉😃\n\n-FREE HASTA LA 1:30 am\n\nLista de invitados: FRIENDS LIST\n\nBUSCA, COMPARA... PERO NO ENCONTRARÁS MEJOR EVENTO.\n\n***Con tu asistencia, acepta sus derechos de imagen solo para usarlos para redes promocionales.",
        "startDate": "2022-12-21T17:00:54.000Z",
        "endDate": "2022-12-21T23:00:54.000Z",
        "location": "Pecera Pool",
        "longitude": null,
        "latitude": null,
        "capacity": 150,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": true,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 25,
        "userId": 1,
        "origId": 1600,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=WE",
        "imageDocumentId": null,
        "dayOfWeek": "3",
        "timeOfDay": "2"
    },
    {
        "originals": [
            {
                "id": 1720,
                "name": "NEW LANGUAGE EXCHANGE & DJ SESSION + FREE FOOD & LA FIRA PROVENZA 🍻",
                "description": "WHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nTHIS EVENT IS FREE 😃🎉😃\n\nWELCOME FREE FOOD FOR OUR GRUOP🙂🙂🙂\n\nThis THURSDAY We are going to one of the most exclusive COCKTAIL BAR in Barcelona LA FLACA 🕺🍹🤩 & DJ SESSION\n\nCarrer Muntaner 83C, Barcelona\n\nAre you going to miss it??\n\nDo you want to enjoy this exclusive event? Sign up now!\n\nIt is located in one of the best areas of Barcelona, in EIXAMPLE\n\nIt is a place with a lot of space where We can stay until later because it is also a DISCO PUB\n\nIt has a restaurant inside with good food and good value for money\n\nAt first we will be in an exclusive area just for our group\n\nAnd later we can enjoy the whole place with more than 200 m2.\n\nYou have free entrance to LA FiRA PROVENZA (skipping the line) from 12 am till 1:00 am if you came first to the pre-party\n\nGueslist: FRIENDS LIST\n\nESTE EVENTO ES FREE 😃🎉😃\n\nEste JUEVES vamos a uno de los COCKTAIL BAR más exclusivos de Barcelona LA FLACA 🕺🍹🤩 con DJ SESSION\n\nFREE FOOD para nuestro grupo 🙂🙂🙂\n\nCarrer Muntaner 83C, Barcelona\n\nTe lo vas a perder??\n\nQuieres disfrutar de este evento exclusivo?? Apúntate!\n\nEstá en una de las mejores zonas, en el EIXAMPLE\n\nEs un lugar con mucho espacio donde podemos estar durante todo el evento\n\nTiene restaurante dentro con buena comida y buena relación calidad precio\n\nAl principio estaremos en una zona exclusiva solo para nuestro grupo\n\nY más tarde podremos disfrutar de todo el lugar con más de 200 m2.\n\nPodeis entrar FREE en LA FIRA PROVENZA (sin hacer cola) siempre que vengáis antes a la pre-party desde las 24h a 1:00 am\n\nGueslist: FRIENDS LIST 😃🎉😃",
                "startDate": "2022-12-15T18:00:45.000Z",
                "endDate": "2022-12-16T01:00:45.000Z",
                "location": "La Flaca",
                "longitude": null,
                "latitude": null,
                "capacity": null,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 1,
                "userId": 1,
                "origId": 1175,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=TH",
                "imageDocumentId": null
            },
            {
                "id": 1725,
                "name": "NEW LANGUAGE EXCHANGE & DJ SESSION + FREE FOOD & LA FIRA PROVENZA 🍻",
                "description": "WHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nTHIS EVENT IS FREE 😃🎉😃\n\nWELCOME FREE FOOD FOR OUR GRUOP🙂🙂🙂\n\nThis THURSDAY We are going to one of the most exclusive COCKTAIL BAR in Barcelona LA FLACA 🕺🍹🤩 & DJ SESSION\n\nCarrer Muntaner 83C, Barcelona\n\nAre you going to miss it??\n\nDo you want to enjoy this exclusive event? Sign up now!\n\nIt is located in one of the best areas of Barcelona, in EIXAMPLE\n\nIt is a place with a lot of space where We can stay until later because it is also a DISCO PUB\n\nIt has a restaurant inside with good food and good value for money\n\nAt first we will be in an exclusive area just for our group\n\nAnd later we can enjoy the whole place with more than 200 m2.\n\nYou have free entrance to LA FiRA PROVENZA (skipping the line) from 12 am till 1:00 am if you came first to the pre-party\n\nGueslist: FRIENDS LIST\n\nESTE EVENTO ES FREE 😃🎉😃\n\nEste JUEVES vamos a uno de los COCKTAIL BAR más exclusivos de Barcelona LA FLACA 🕺🍹🤩 con DJ SESSION\n\nFREE FOOD para nuestro grupo 🙂🙂🙂\n\nCarrer Muntaner 83C, Barcelona\n\nTe lo vas a perder??\n\nQuieres disfrutar de este evento exclusivo?? Apúntate!\n\nEstá en una de las mejores zonas, en el EIXAMPLE\n\nEs un lugar con mucho espacio donde podemos estar durante todo el evento\n\nTiene restaurante dentro con buena comida y buena relación calidad precio\n\nAl principio estaremos en una zona exclusiva solo para nuestro grupo\n\nY más tarde podremos disfrutar de todo el lugar con más de 200 m2.\n\nPodeis entrar FREE en LA FIRA PROVENZA (sin hacer cola) siempre que vengáis antes a la pre-party desde las 24h a 1:00 am\n\nGueslist: FRIENDS LIST 😃🎉😃",
                "startDate": "2022-12-22T17:00:45.000Z",
                "endDate": "2022-12-23T00:00:45.000Z",
                "location": "La Flaca",
                "longitude": null,
                "latitude": null,
                "capacity": null,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 1,
                "userId": 1,
                "origId": 1175,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=TH",
                "imageDocumentId": null
            }
        ],
        "count": 2,
        "id": 1725,
        "name": "NEW LANGUAGE EXCHANGE & DJ SESSION + FREE FOOD & LA FIRA PROVENZA 🍻",
        "description": "WHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nTHIS EVENT IS FREE 😃🎉😃\n\nWELCOME FREE FOOD FOR OUR GRUOP🙂🙂🙂\n\nThis THURSDAY We are going to one of the most exclusive COCKTAIL BAR in Barcelona LA FLACA 🕺🍹🤩 & DJ SESSION\n\nCarrer Muntaner 83C, Barcelona\n\nAre you going to miss it??\n\nDo you want to enjoy this exclusive event? Sign up now!\n\nIt is located in one of the best areas of Barcelona, in EIXAMPLE\n\nIt is a place with a lot of space where We can stay until later because it is also a DISCO PUB\n\nIt has a restaurant inside with good food and good value for money\n\nAt first we will be in an exclusive area just for our group\n\nAnd later we can enjoy the whole place with more than 200 m2.\n\nYou have free entrance to LA FiRA PROVENZA (skipping the line) from 12 am till 1:00 am if you came first to the pre-party\n\nGueslist: FRIENDS LIST\n\nESTE EVENTO ES FREE 😃🎉😃\n\nEste JUEVES vamos a uno de los COCKTAIL BAR más exclusivos de Barcelona LA FLACA 🕺🍹🤩 con DJ SESSION\n\nFREE FOOD para nuestro grupo 🙂🙂🙂\n\nCarrer Muntaner 83C, Barcelona\n\nTe lo vas a perder??\n\nQuieres disfrutar de este evento exclusivo?? Apúntate!\n\nEstá en una de las mejores zonas, en el EIXAMPLE\n\nEs un lugar con mucho espacio donde podemos estar durante todo el evento\n\nTiene restaurante dentro con buena comida y buena relación calidad precio\n\nAl principio estaremos en una zona exclusiva solo para nuestro grupo\n\nY más tarde podremos disfrutar de todo el lugar con más de 200 m2.\n\nPodeis entrar FREE en LA FIRA PROVENZA (sin hacer cola) siempre que vengáis antes a la pre-party desde las 24h a 1:00 am\n\nGueslist: FRIENDS LIST 😃🎉😃",
        "startDate": "2022-12-22T17:00:45.000Z",
        "endDate": "2022-12-23T00:00:45.000Z",
        "location": "La Flaca",
        "longitude": null,
        "latitude": null,
        "capacity": null,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": false,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 1,
        "userId": 1,
        "origId": 1175,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=TH",
        "imageDocumentId": null,
        "dayOfWeek": "4",
        "timeOfDay": "2"
    },
    {
        "originals": [
            {
                "id": 1721,
                "name": "FRIDAY LANGUAGE EXCHANGE 🎉 PARTY & BEERPONG + SURPRISE GAME & OTTO ZUTZ",
                "description": "APP: https://bit.ly/2Xlg72m\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nWould you like to enjoy a great time while practicing languages, meeting new people and having lots of fun??\n\nWe have the definitive best MULTICULTURAL event in Barcelona!!! This FRIDAY we’ll meet at PECERA POOL (Carrer Calabria 171 Barcelona)\n\nWITH MUSIC, POOL & DARTS & FOOTBALL TABLE 😃🎉😃 & BEERPONG\n\nSpecial this Friday:\n\nThis Friday we can participate in a new SURPRISE game, if you find your other half FREE DINNER! In this way we will promote even more the ability to meet all the attendees 😃🙂😃\n\nMore info during the event\n\nSEARCH, COMPARE ...BUT YOU WON T FIND ANYTHING BETTER.\n\nWe will play BEERPONG\n\nWe are usually +100 people!\n\nThe place is very big with large capacity and there is a big TERRACE.\n\nWe can go to OTTO ZUTZ (FREE) from 12:30 pm to 1:30 am\n\n——————————————————————\n\nHola!\n\n¿Te gustaría disfrutar de un buen rato mientras practicas idiomas, conoces a gente nueva y te divertirtes?\n\nNo os lo penséis! nos vemos este VIERNES en PECERA POOL!!! (Carrer Calabria 171 Barcelona).\n\nCon buena MUSICA, BILLARES, DARDOS y FUTBOLIN & BEERPONG\n\nEspecial este viernes:\n\nEste Viernes podemos participar el un nuevo juego SORPRESA, si encuentras tu otra mitad CENA (FREE), venga animaros de esta manera fomentaremos aun mas el poder conocer todos los asistentes 😃🙂😃\n\nMás info durante el evento\n\nBUSCA, COMPARA…PERO NO ENCONTRARÁS NADA MEJOR.\n\nEl local es muy grande con gran capacidad y hay una gran TERRAZA.\n\nPodemos ir a entrar OTTO ZUTZ (FREE) desde las 24:30h hasta la 1:30h\n\n**Con la asistencia a nuestros eventos consientes en la cesión de tus derechos de imagen para nuestro uso promocional en redes sociales.\n\nOur events are free of charge. Nuestros eventos son gratuitos.",
                "startDate": "2022-12-16T18:00:04.000Z",
                "endDate": "2022-12-17T04:00:04.000Z",
                "location": "Pecera Pool",
                "longitude": null,
                "latitude": null,
                "capacity": 200,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 7,
                "userId": 1,
                "origId": 1544,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=FR",
                "imageDocumentId": null
            },
            {
                "id": 1743,
                "name": "FRIDAY LANGUAGE EXCHANGE 🎉 PARTY & BEERPONG + SURPRISE GAME & OTTO ZUTZ",
                "description": "APP: https://bit.ly/2Xlg72m\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nWould you like to enjoy a great time while practicing languages, meeting new people and having lots of fun??\n\nWe have the definitive best MULTICULTURAL event in Barcelona!!! This FRIDAY we’ll meet at PECERA POOL (Carrer Calabria 171 Barcelona)\n\nWITH MUSIC, POOL & DARTS & FOOTBALL TABLE 😃🎉😃 & BEERPONG\n\nSpecial this Friday:\n\nThis Friday we can participate in a new SURPRISE game, if you find your other half FREE DINNER! In this way we will promote even more the ability to meet all the attendees 😃🙂😃\n\nMore info during the event\n\nSEARCH, COMPARE ...BUT YOU WON T FIND ANYTHING BETTER.\n\nWe will play BEERPONG\n\nWe are usually +100 people!\n\nThe place is very big with large capacity and there is a big TERRACE.\n\nWe can go to OTTO ZUTZ (FREE) from 12:30 pm to 1:30 am\n\n——————————————————————\n\nHola!\n\n¿Te gustaría disfrutar de un buen rato mientras practicas idiomas, conoces a gente nueva y te divertirtes?\n\nNo os lo penséis! nos vemos este VIERNES en PECERA POOL!!! (Carrer Calabria 171 Barcelona).\n\nCon buena MUSICA, BILLARES, DARDOS y FUTBOLIN & BEERPONG\n\nEspecial este viernes:\n\nEste Viernes podemos participar el un nuevo juego SORPRESA, si encuentras tu otra mitad CENA (FREE), venga animaros de esta manera fomentaremos aun mas el poder conocer todos los asistentes 😃🙂😃\n\nMás info durante el evento\n\nBUSCA, COMPARA…PERO NO ENCONTRARÁS NADA MEJOR.\n\nEl local es muy grande con gran capacidad y hay una gran TERRAZA.\n\nPodemos ir a entrar OTTO ZUTZ (FREE) desde las 24:30h hasta la 1:30h\n\n**Con la asistencia a nuestros eventos consientes en la cesión de tus derechos de imagen para nuestro uso promocional en redes sociales.\n\nOur events are free of charge. Nuestros eventos son gratuitos.",
                "startDate": "2022-12-23T18:00:04.000Z",
                "endDate": "2022-12-24T04:00:04.000Z",
                "location": "Pecera Pool",
                "longitude": null,
                "latitude": null,
                "capacity": 200,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 7,
                "userId": 1,
                "origId": 1544,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=FR",
                "imageDocumentId": null
            }
        ],
        "count": 2,
        "id": 1743,
        "name": "FRIDAY LANGUAGE EXCHANGE 🎉 PARTY & BEERPONG + SURPRISE GAME & OTTO ZUTZ",
        "description": "APP: https://bit.ly/2Xlg72m\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nWould you like to enjoy a great time while practicing languages, meeting new people and having lots of fun??\n\nWe have the definitive best MULTICULTURAL event in Barcelona!!! This FRIDAY we’ll meet at PECERA POOL (Carrer Calabria 171 Barcelona)\n\nWITH MUSIC, POOL & DARTS & FOOTBALL TABLE 😃🎉😃 & BEERPONG\n\nSpecial this Friday:\n\nThis Friday we can participate in a new SURPRISE game, if you find your other half FREE DINNER! In this way we will promote even more the ability to meet all the attendees 😃🙂😃\n\nMore info during the event\n\nSEARCH, COMPARE ...BUT YOU WON T FIND ANYTHING BETTER.\n\nWe will play BEERPONG\n\nWe are usually +100 people!\n\nThe place is very big with large capacity and there is a big TERRACE.\n\nWe can go to OTTO ZUTZ (FREE) from 12:30 pm to 1:30 am\n\n——————————————————————\n\nHola!\n\n¿Te gustaría disfrutar de un buen rato mientras practicas idiomas, conoces a gente nueva y te divertirtes?\n\nNo os lo penséis! nos vemos este VIERNES en PECERA POOL!!! (Carrer Calabria 171 Barcelona).\n\nCon buena MUSICA, BILLARES, DARDOS y FUTBOLIN & BEERPONG\n\nEspecial este viernes:\n\nEste Viernes podemos participar el un nuevo juego SORPRESA, si encuentras tu otra mitad CENA (FREE), venga animaros de esta manera fomentaremos aun mas el poder conocer todos los asistentes 😃🙂😃\n\nMás info durante el evento\n\nBUSCA, COMPARA…PERO NO ENCONTRARÁS NADA MEJOR.\n\nEl local es muy grande con gran capacidad y hay una gran TERRAZA.\n\nPodemos ir a entrar OTTO ZUTZ (FREE) desde las 24:30h hasta la 1:30h\n\n**Con la asistencia a nuestros eventos consientes en la cesión de tus derechos de imagen para nuestro uso promocional en redes sociales.\n\nOur events are free of charge. Nuestros eventos son gratuitos.",
        "startDate": "2022-12-23T18:00:04.000Z",
        "endDate": "2022-12-24T04:00:04.000Z",
        "location": "Pecera Pool",
        "longitude": null,
        "latitude": null,
        "capacity": 200,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": false,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 7,
        "userId": 1,
        "origId": 1544,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=FR",
        "imageDocumentId": null,
        "dayOfWeek": "5",
        "timeOfDay": "2"
    },
    {
        "originals": [
            {
                "id": 1722,
                "name": "INTERNATIONAL BRUNCH WITH LIVE MUSIC & HOUSE SESSION  🥪🍳😋",
                "description": "Hi!\n\nThis SATURDAY morning we are organizing a spectacular BRUNCH at LA FLACA. (C/ MUNTANER 83 C)! 🙂🙂🙂\n\nWe will meet in LA FLACA at 12 am! Check the map on how to arrive.\n\nThis is an event for anyone who wants to get out for SATURDAY morning to meet people, make new friends and taste delicious food 🥪🍳!\n\nIn this event you'll have the possibility to meet new interesting and international people, practise your language skills and have lots of fun with LIVE MUSIC & HOUSE SESSION 🙂🎉🙂\n\nThe place is very big and has a nice TERRACE\n\nLooking forward to seeing you!\n\n¡Hola!\n\n¡Este SABADO por la mañana estamos organizando un espectacular BRUNCH en La Flaca (C /Muntaner 83,C)! 😃😃😃\n\n¡Nos reuniremos en LA FLACA a las 12:00 am! Consulta en el mapa cómo llegar.\n\n¡Este es un evento para cualquiera que quiera salir SABADO por la mañana para conocer gente, hacer nuevos amigos y probar comida deliciosa🥪🍳!\n\n¡En este evento tendrás la posibilidad de conocer gente nueva e interesante internacional, practicar tus habilidades lingüísticas y divertirte mucho con MUSICA EN VIVO y HOUSE SESSION 😃🎉😃\n\nEl sitio es muy grande y tiene una bonita TERRAZA.\n\n¡Nos vemos alli!",
                "startDate": "2022-12-17T10:00:35.000Z",
                "endDate": "2022-12-17T13:00:35.000Z",
                "location": "La Flaca",
                "longitude": null,
                "latitude": null,
                "capacity": 100,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 7,
                "userId": 1,
                "origId": 1688,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SA",
                "imageDocumentId": null
            },
            {
                "id": 1772,
                "name": "INTERNATIONAL BRUNCH WITH LIVE MUSIC & HOUSE SESSION  🥪🍳😋",
                "description": "Hi!\n\nThis SATURDAY morning we are organizing a spectacular BRUNCH at LA FLACA. (C/ MUNTANER 83 C)! 🙂🙂🙂\n\nWe will meet in LA FLACA at 12 am! Check the map on how to arrive.\n\nThis is an event for anyone who wants to get out for SATURDAY morning to meet people, make new friends and taste delicious food 🥪🍳!\n\nIn this event you'll have the possibility to meet new interesting and international people, practise your language skills and have lots of fun with LIVE MUSIC & HOUSE SESSION 🙂🎉🙂\n\nThe place is very big and has a nice TERRACE\n\nLooking forward to seeing you!\n\n¡Hola!\n\n¡Este SABADO por la mañana estamos organizando un espectacular BRUNCH en La Flaca (C /Muntaner 83,C)! 😃😃😃\n\n¡Nos reuniremos en LA FLACA a las 12:00 am! Consulta en el mapa cómo llegar.\n\n¡Este es un evento para cualquiera que quiera salir SABADO por la mañana para conocer gente, hacer nuevos amigos y probar comida deliciosa🥪🍳!\n\n¡En este evento tendrás la posibilidad de conocer gente nueva e interesante internacional, practicar tus habilidades lingüísticas y divertirte mucho con MUSICA EN VIVO y HOUSE SESSION 😃🎉😃\n\nEl sitio es muy grande y tiene una bonita TERRAZA.\n\n¡Nos vemos alli!",
                "startDate": "2022-12-24T10:00:35.000Z",
                "endDate": "2022-12-24T13:00:35.000Z",
                "location": "La Flaca",
                "longitude": null,
                "latitude": null,
                "capacity": 100,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 7,
                "userId": 1,
                "origId": 1688,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SA",
                "imageDocumentId": null
            }
        ],
        "count": 2,
        "id": 1772,
        "name": "INTERNATIONAL BRUNCH WITH LIVE MUSIC & HOUSE SESSION  🥪🍳😋",
        "description": "Hi!\n\nThis SATURDAY morning we are organizing a spectacular BRUNCH at LA FLACA. (C/ MUNTANER 83 C)! 🙂🙂🙂\n\nWe will meet in LA FLACA at 12 am! Check the map on how to arrive.\n\nThis is an event for anyone who wants to get out for SATURDAY morning to meet people, make new friends and taste delicious food 🥪🍳!\n\nIn this event you'll have the possibility to meet new interesting and international people, practise your language skills and have lots of fun with LIVE MUSIC & HOUSE SESSION 🙂🎉🙂\n\nThe place is very big and has a nice TERRACE\n\nLooking forward to seeing you!\n\n¡Hola!\n\n¡Este SABADO por la mañana estamos organizando un espectacular BRUNCH en La Flaca (C /Muntaner 83,C)! 😃😃😃\n\n¡Nos reuniremos en LA FLACA a las 12:00 am! Consulta en el mapa cómo llegar.\n\n¡Este es un evento para cualquiera que quiera salir SABADO por la mañana para conocer gente, hacer nuevos amigos y probar comida deliciosa🥪🍳!\n\n¡En este evento tendrás la posibilidad de conocer gente nueva e interesante internacional, practicar tus habilidades lingüísticas y divertirte mucho con MUSICA EN VIVO y HOUSE SESSION 😃🎉😃\n\nEl sitio es muy grande y tiene una bonita TERRAZA.\n\n¡Nos vemos alli!",
        "startDate": "2022-12-24T10:00:35.000Z",
        "endDate": "2022-12-24T13:00:35.000Z",
        "location": "La Flaca",
        "longitude": null,
        "latitude": null,
        "capacity": 100,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": false,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 7,
        "userId": 1,
        "origId": 1688,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SA",
        "imageDocumentId": null,
        "dayOfWeek": "6",
        "timeOfDay": "1"
    },
    {
        "originals": [
            {
                "id": 1723,
                "name": "MEGA SATURDAY NIGHT DJ COCKTAIL PARTY & FREE FOOD + DUVET FREE 🕺🍹🤩",
                "description": "WHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nTHIS EVENT IS FREE 😃🎉😃\n\nWELCOME FREE FOOD FOR OUR GRUOP🙂🙂🙂\n\nThis Saturday we are going to one of the most exclusive COCKTAIL BAR in Barcelona LA FLACA 🕺🍹🤩\n\nCarrer Muntaner 83C, Barcelona\n\nAre you going to miss it??\n\nDo you want to enjoy this exclusive event? Sign up now!\n\nIt is located in one of the best areas of Barcelona, in EIXAMPLE\n\nIt is a place with a lot of space where we can stay until later because it is also a DISCO PUB\n\nIt has a restaurant inside with good food and good value for money\n\nAt first we will be in an exclusive area just for our group\n\nAnd later we can enjoy the whole place with more than 200 m2.\n\nYou have free entrance to DUVET from 12 am till 1:30 am if you came first to the pre-party\n\nGueslist: FRIENDS LIST\n\nESTE EVENTO ES FREE 😃🎉😃\n\nEste Sabado vamos a uno de los COCKTAIL BAR más exclusivos de Barcelona LA FLACA 🕺🍹🤩\n\nFREE FOOD para nuestro grupo 🙂🙂🙂\n\nCarrer Muntaner 83C, Barcelona\n\nTe lo vas a perder??\n\nQuieres disfrutar de este evento exclusivo?? Apúntate!\n\nEstá en una de las mejores zonas, en el EIXAMPLE\n\nEs un lugar con mucho espacio donde podemos estar durante todo el evento\n\nTiene restaurante dentro con buena comida y buena relación calidad precio\n\nAl principio estaremos en una zona exclusiva solo para nuestro grupo\n\nY más tarde podremos disfrutar de todo el lugar con más de 200 m2.\n\nPodemos entrar FREE en DUVET desde las 24h a 1:30am\n\nGueslist: FRIENDS LIST 😃🎉😃",
                "startDate": "2022-12-17T18:30:02.000Z",
                "endDate": "2022-12-18T04:30:02.000Z",
                "location": "La Flaca",
                "longitude": null,
                "latitude": null,
                "capacity": 250,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 0,
                "userId": 1,
                "origId": 1474,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SA",
                "imageDocumentId": null
            },
            {
                "id": 1737,
                "name": "MEGA SATURDAY NIGHT DJ COCKTAIL PARTY & FREE FOOD + DUVET FREE 🕺🍹🤩",
                "description": "WHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nTHIS EVENT IS FREE 😃🎉😃\n\nWELCOME FREE FOOD FOR OUR GRUOP🙂🙂🙂\n\nThis Saturday we are going to one of the most exclusive COCKTAIL BAR in Barcelona LA FLACA 🕺🍹🤩\n\nCarrer Muntaner 83C, Barcelona\n\nAre you going to miss it??\n\nDo you want to enjoy this exclusive event? Sign up now!\n\nIt is located in one of the best areas of Barcelona, in EIXAMPLE\n\nIt is a place with a lot of space where we can stay until later because it is also a DISCO PUB\n\nIt has a restaurant inside with good food and good value for money\n\nAt first we will be in an exclusive area just for our group\n\nAnd later we can enjoy the whole place with more than 200 m2.\n\nYou have free entrance to DUVET from 12 am till 1:30 am if you came first to the pre-party\n\nGueslist: FRIENDS LIST\n\nESTE EVENTO ES FREE 😃🎉😃\n\nEste Sabado vamos a uno de los COCKTAIL BAR más exclusivos de Barcelona LA FLACA 🕺🍹🤩\n\nFREE FOOD para nuestro grupo 🙂🙂🙂\n\nCarrer Muntaner 83C, Barcelona\n\nTe lo vas a perder??\n\nQuieres disfrutar de este evento exclusivo?? Apúntate!\n\nEstá en una de las mejores zonas, en el EIXAMPLE\n\nEs un lugar con mucho espacio donde podemos estar durante todo el evento\n\nTiene restaurante dentro con buena comida y buena relación calidad precio\n\nAl principio estaremos en una zona exclusiva solo para nuestro grupo\n\nY más tarde podremos disfrutar de todo el lugar con más de 200 m2.\n\nPodemos entrar FREE en DUVET desde las 24h a 1:30am\n\nGueslist: FRIENDS LIST 😃🎉😃",
                "startDate": "2022-12-24T18:30:02.000Z",
                "endDate": "2022-12-25T04:30:02.000Z",
                "location": "La Flaca",
                "longitude": null,
                "latitude": null,
                "capacity": 250,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": false,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 0,
                "userId": 1,
                "origId": 1474,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SA",
                "imageDocumentId": null
            }
        ],
        "count": 2,
        "id": 1737,
        "name": "MEGA SATURDAY NIGHT DJ COCKTAIL PARTY & FREE FOOD + DUVET FREE 🕺🍹🤩",
        "description": "WHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nTHIS EVENT IS FREE 😃🎉😃\n\nWELCOME FREE FOOD FOR OUR GRUOP🙂🙂🙂\n\nThis Saturday we are going to one of the most exclusive COCKTAIL BAR in Barcelona LA FLACA 🕺🍹🤩\n\nCarrer Muntaner 83C, Barcelona\n\nAre you going to miss it??\n\nDo you want to enjoy this exclusive event? Sign up now!\n\nIt is located in one of the best areas of Barcelona, in EIXAMPLE\n\nIt is a place with a lot of space where we can stay until later because it is also a DISCO PUB\n\nIt has a restaurant inside with good food and good value for money\n\nAt first we will be in an exclusive area just for our group\n\nAnd later we can enjoy the whole place with more than 200 m2.\n\nYou have free entrance to DUVET from 12 am till 1:30 am if you came first to the pre-party\n\nGueslist: FRIENDS LIST\n\nESTE EVENTO ES FREE 😃🎉😃\n\nEste Sabado vamos a uno de los COCKTAIL BAR más exclusivos de Barcelona LA FLACA 🕺🍹🤩\n\nFREE FOOD para nuestro grupo 🙂🙂🙂\n\nCarrer Muntaner 83C, Barcelona\n\nTe lo vas a perder??\n\nQuieres disfrutar de este evento exclusivo?? Apúntate!\n\nEstá en una de las mejores zonas, en el EIXAMPLE\n\nEs un lugar con mucho espacio donde podemos estar durante todo el evento\n\nTiene restaurante dentro con buena comida y buena relación calidad precio\n\nAl principio estaremos en una zona exclusiva solo para nuestro grupo\n\nY más tarde podremos disfrutar de todo el lugar con más de 200 m2.\n\nPodemos entrar FREE en DUVET desde las 24h a 1:30am\n\nGueslist: FRIENDS LIST 😃🎉😃",
        "startDate": "2022-12-24T18:30:02.000Z",
        "endDate": "2022-12-25T04:30:02.000Z",
        "location": "La Flaca",
        "longitude": null,
        "latitude": null,
        "capacity": 250,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": false,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 0,
        "userId": 1,
        "origId": 1474,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SA",
        "imageDocumentId": null,
        "dayOfWeek": "6",
        "timeOfDay": "2"
    },
    {
        "originals": [
            {
                "id": 1715,
                "name": "NEW LANGUAGE EXCHANGE & KARAOKE 😃🎉😃 + BOARD GAMES & JAMBOREE (FREE)",
                "description": "NEW EVENT this SUNDAY 😃🎉😃\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nNEW EVENT this SUNDAY 😃🎉😃\n\nWe are going to one of the most popular areas of Barcelona: EL BORN 🙂🙂🙂\n\nThe NAVIA ( c/ comerç 33, Barcelona ) is close to Santa María Church, the Fosar de les Moreres (commemorative monument to those who died in the Catalonia-Spain secession war)\n\nWe go back to enjoy our language exchange and KARAOKE on SUNDAYS\n\nThe place is very big with a TERRACE, good prices and a PRIVATE ZONE for us\n\nSinging, as always, is optional since the place has 2 floors.\n\nAre you going to miss it?? Come and enjoy our event in an international environment at BORN 😃🎉😃\n\nYou can enter by list from 24:00h pm until 1:00 am at JAMBOREE (FREE)😃🎉😃\n\nGueslist: FRIENDS LIST\n\nConfirmation HERE: WhatsApp: +34645867255\n\nbefore 11:55 pm\n\nNUEVO EVENTO este DOMINGO 😃🎉😃\n\nNos vamos a una de las mejores zonas populares de Barcelona: EL BORN 🙂🙂🙂\n\nEl NAVIA (c/ comerç 33, Barcelona) está cerca de la Iglesia de Santa María, el Fossar de les Moreres (monumento conmemorativo de los caídos en la guerra de secesión Cataluña-España)\n\nVolvemos a disfrutar de nuestro intercambio de idiomas y KARAOKE los DOMINGOS 😃🎉😃\n\nEl local es muy grande con TERRAZA, buenos precios y ZONA PRIVADA para nosotros\n\nCantar como siempre es opcional ya que el lugar tiene 2 plantas.\n\nte lo vas a perder?? Ven y disfruta de nuestro evento en un ambiente internacional en BORN 😃🎉😃\n\nPodemos ir a JAMBOREE (FREE) desde las 24h a las 1 am\n\nLista: FRIENDS LIST\n\nHay que mandar un mensaje de confirmación al: +34645867255 antes de las 23:55h",
                "startDate": "2022-12-11T17:00:03.000Z",
                "endDate": "2022-12-12T04:00:03.000Z",
                "location": "Sabor Cubano de Sarrià",
                "longitude": null,
                "latitude": null,
                "capacity": 250,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": true,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 0,
                "userId": 1,
                "origId": 1703,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SU",
                "imageDocumentId": null
            },
            {
                "id": 1724,
                "name": "NEW LANGUAGE EXCHANGE & KARAOKE 😃🎉😃 + BOARD GAMES & JAMBOREE (FREE)",
                "description": "NEW EVENT this SUNDAY 😃🎉😃\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nNEW EVENT this SUNDAY 😃🎉😃\n\nWe are going to one of the most popular areas of Barcelona: EL BORN 🙂🙂🙂\n\nThe NAVIA ( c/ comerç 33, Barcelona ) is close to Santa María Church, the Fosar de les Moreres (commemorative monument to those who died in the Catalonia-Spain secession war)\n\nWe go back to enjoy our language exchange and KARAOKE on SUNDAYS\n\nThe place is very big with a TERRACE, good prices and a PRIVATE ZONE for us\n\nSinging, as always, is optional since the place has 2 floors.\n\nAre you going to miss it?? Come and enjoy our event in an international environment at BORN 😃🎉😃\n\nYou can enter by list from 24:00h pm until 1:00 am at JAMBOREE (FREE)😃🎉😃\n\nGueslist: FRIENDS LIST\n\nConfirmation HERE: WhatsApp: +34645867255\n\nbefore 11:55 pm\n\nNUEVO EVENTO este DOMINGO 😃🎉😃\n\nNos vamos a una de las mejores zonas populares de Barcelona: EL BORN 🙂🙂🙂\n\nEl NAVIA (c/ comerç 33, Barcelona) está cerca de la Iglesia de Santa María, el Fossar de les Moreres (monumento conmemorativo de los caídos en la guerra de secesión Cataluña-España)\n\nVolvemos a disfrutar de nuestro intercambio de idiomas y KARAOKE los DOMINGOS 😃🎉😃\n\nEl local es muy grande con TERRAZA, buenos precios y ZONA PRIVADA para nosotros\n\nCantar como siempre es opcional ya que el lugar tiene 2 plantas.\n\nte lo vas a perder?? Ven y disfruta de nuestro evento en un ambiente internacional en BORN 😃🎉😃\n\nPodemos ir a JAMBOREE (FREE) desde las 24h a las 1 am\n\nLista: FRIENDS LIST\n\nHay que mandar un mensaje de confirmación al: +34645867255 antes de las 23:55h",
                "startDate": "2022-12-18T17:00:03.000Z",
                "endDate": "2022-12-19T04:00:03.000Z",
                "location": "Sabor Cubano de Sarrià",
                "longitude": null,
                "latitude": null,
                "capacity": 250,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": true,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 0,
                "userId": 1,
                "origId": 1703,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SU",
                "imageDocumentId": null
            },
            {
                "id": 1730,
                "name": "NEW LANGUAGE EXCHANGE & KARAOKE 😃🎉😃 + BOARD GAMES & JAMBOREE (FREE)",
                "description": "NEW EVENT this SUNDAY 😃🎉😃\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nNEW EVENT this SUNDAY 😃🎉😃\n\nWe are going to one of the most popular areas of Barcelona: EL BORN 🙂🙂🙂\n\nThe NAVIA ( c/ comerç 33, Barcelona ) is close to Santa María Church, the Fosar de les Moreres (commemorative monument to those who died in the Catalonia-Spain secession war)\n\nWe go back to enjoy our language exchange and KARAOKE on SUNDAYS\n\nThe place is very big with a TERRACE, good prices and a PRIVATE ZONE for us\n\nSinging, as always, is optional since the place has 2 floors.\n\nAre you going to miss it?? Come and enjoy our event in an international environment at BORN 😃🎉😃\n\nYou can enter by list from 24:00h pm until 1:00 am at JAMBOREE (FREE)😃🎉😃\n\nGueslist: FRIENDS LIST\n\nConfirmation HERE: WhatsApp: +34645867255\n\nbefore 11:55 pm\n\nNUEVO EVENTO este DOMINGO 😃🎉😃\n\nNos vamos a una de las mejores zonas populares de Barcelona: EL BORN 🙂🙂🙂\n\nEl NAVIA (c/ comerç 33, Barcelona) está cerca de la Iglesia de Santa María, el Fossar de les Moreres (monumento conmemorativo de los caídos en la guerra de secesión Cataluña-España)\n\nVolvemos a disfrutar de nuestro intercambio de idiomas y KARAOKE los DOMINGOS 😃🎉😃\n\nEl local es muy grande con TERRAZA, buenos precios y ZONA PRIVADA para nosotros\n\nCantar como siempre es opcional ya que el lugar tiene 2 plantas.\n\nte lo vas a perder?? Ven y disfruta de nuestro evento en un ambiente internacional en BORN 😃🎉😃\n\nPodemos ir a JAMBOREE (FREE) desde las 24h a las 1 am\n\nLista: FRIENDS LIST\n\nHay que mandar un mensaje de confirmación al: +34645867255 antes de las 23:55h",
                "startDate": "2022-12-25T17:00:03.000Z",
                "endDate": "2022-12-26T04:00:03.000Z",
                "location": "Sabor Cubano de Sarrià",
                "longitude": null,
                "latitude": null,
                "capacity": 250,
                "price": "0",
                "imageUrl": null,
                "rating": null,
                "isDirty": true,
                "ownRating": null,
                "resourceId": 1,
                "attendance": 0,
                "userId": 1,
                "origId": 1703,
                "saved": false,
                "attending": null,
                "ticketCuid": null,
                "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SU",
                "imageDocumentId": null
            }
        ],
        "count": 3,
        "id": 1730,
        "name": "NEW LANGUAGE EXCHANGE & KARAOKE 😃🎉😃 + BOARD GAMES & JAMBOREE (FREE)",
        "description": "NEW EVENT this SUNDAY 😃🎉😃\n\nWHATSAPP GROUP: https://bit.ly/3jLqd5j\n\nwww.barcelonainternationalsocial.com\n\nNEW EVENT this SUNDAY 😃🎉😃\n\nWe are going to one of the most popular areas of Barcelona: EL BORN 🙂🙂🙂\n\nThe NAVIA ( c/ comerç 33, Barcelona ) is close to Santa María Church, the Fosar de les Moreres (commemorative monument to those who died in the Catalonia-Spain secession war)\n\nWe go back to enjoy our language exchange and KARAOKE on SUNDAYS\n\nThe place is very big with a TERRACE, good prices and a PRIVATE ZONE for us\n\nSinging, as always, is optional since the place has 2 floors.\n\nAre you going to miss it?? Come and enjoy our event in an international environment at BORN 😃🎉😃\n\nYou can enter by list from 24:00h pm until 1:00 am at JAMBOREE (FREE)😃🎉😃\n\nGueslist: FRIENDS LIST\n\nConfirmation HERE: WhatsApp: +34645867255\n\nbefore 11:55 pm\n\nNUEVO EVENTO este DOMINGO 😃🎉😃\n\nNos vamos a una de las mejores zonas populares de Barcelona: EL BORN 🙂🙂🙂\n\nEl NAVIA (c/ comerç 33, Barcelona) está cerca de la Iglesia de Santa María, el Fossar de les Moreres (monumento conmemorativo de los caídos en la guerra de secesión Cataluña-España)\n\nVolvemos a disfrutar de nuestro intercambio de idiomas y KARAOKE los DOMINGOS 😃🎉😃\n\nEl local es muy grande con TERRAZA, buenos precios y ZONA PRIVADA para nosotros\n\nCantar como siempre es opcional ya que el lugar tiene 2 plantas.\n\nte lo vas a perder?? Ven y disfruta de nuestro evento en un ambiente internacional en BORN 😃🎉😃\n\nPodemos ir a JAMBOREE (FREE) desde las 24h a las 1 am\n\nLista: FRIENDS LIST\n\nHay que mandar un mensaje de confirmación al: +34645867255 antes de las 23:55h",
        "startDate": "2022-12-25T17:00:03.000Z",
        "endDate": "2022-12-26T04:00:03.000Z",
        "location": "Sabor Cubano de Sarrià",
        "longitude": null,
        "latitude": null,
        "capacity": 250,
        "price": "0",
        "imageUrl": null,
        "rating": null,
        "isDirty": true,
        "ownRating": null,
        "resourceId": 1,
        "attendance": 0,
        "userId": 1,
        "origId": 1703,
        "saved": false,
        "attending": null,
        "ticketCuid": null,
        "ruleExpr": "RRULE:FREQ=WEEKLY;BYDAY=SU",
        "imageDocumentId": null,
        "dayOfWeek": "7",
        "timeOfDay": "2"
    }
]
let dowMap = {
    "1": "Monday",
    "2": "Tuesday",
    "3": "Wednesday",
    "4": "Thursday",
    "5": "Friday",
    "6": "Saturday",
    "7": "Sunday"
}
export const IgStorySchedule = observer((props: IgStoryScheduleProps) => {
    let store = props.store;
    if(!props.flatEvents) return null;
    let showEnd = false
    return <Container id={'schedule-root'} onDoubleClick={async () => {
        if (!store) return;
        await store.downloadScheduleImage();
    }}>
        <EventsContainer
            style={props.store.printingImage ? {border: '0px solid white'} : {}}
        >
            {' '}
            {props.flatEvents.map((it) => {
                return <EventContainer
                    key={it.id}
                    onClick={() => {
                        if (!store) return;
                        let eventId = `event-${it.id}`;
                        store.eventSel.selectId(eventId)
                        store.calendarEventsSelSelectedId
                        store.detailMode = "event"
                    }}
                >
                    <EventDowContainer>
                        {dowMap[it.dayOfWeek]} <EventTimeContainer
                        style={{
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            borderRadius: '4px',
                            paddingLeft: '2px',
                            paddingRight: '0px',
                            marginRight: '6px',
                            // color: 'black',
                        }}
                    >
                        {moment(it.startDate).format('HH:mm')} {showEnd && <span>- {moment(it.endDate).format('HH:mm')})</span>}
                    </EventTimeContainer>{' '} ➡️
                    </EventDowContainer>{' '}
                    <EventTitleContainer>
                        {it.name}
                    </EventTitleContainer>{' '}
                    {' '}at{' '}
                    <EventLocationContainer>
                         {it.location}
                    </EventLocationContainer>
                </EventContainer>
            })}
        </EventsContainer>
    </Container>
})
