import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ALVStore} from "./ALVStore";
import {useC3Store} from "react-c4";
import {ALVMain} from "./Components/ALVMain";
import {ALVHeader} from "./Components/ALVHeader";

const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
`


export const ALVContext = React.createContext(null as ALVStore)
export const useALVStore = () => useContext(ALVContext)

export interface AppointmentListViewProps {
    pageContext?
    onStoreReady: (s: ALVStore) => any
}

export const AppointmentListView = observer((props: AppointmentListViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new ALVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <ALVContext.Provider value={store}>
        <Container>
            <ALVHeader pageContext={props.pageContext}/>
            <ALVMain pageContext={props.pageContext}/>
        </Container>
    </ALVContext.Provider>
})
