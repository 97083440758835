import {C3, C3Selection, IATSStyleApp} from "react-c4";
import MobxReactForm from "mobx-react-form";
/**
 * Schedule View Store
 */
import {AppStore} from "../../application/AppStore";
import {action, autorun, computed, observable, reaction, toJS} from "mobx";
import FullCalendar, {
    EventDropArg,
    EventInput,
    EventSourceApi,
    EventSourceInput,
    ViewContentArg
} from "@fullcalendar/react";
import React from "react";
import moment from "moment";
import {MRF} from "react-c4";
import {
    formatFormalName,
    formatFullName,
    getVisitStatusInfo,
    nextAnimationFrame,
    nextTimeout
} from "../../application/utils/utils";
import _ from "lodash";
import {Instance} from "tippy.js";
import {DateClickArg, EventDragStartArg, EventResizeDoneArg} from "@fullcalendar/interaction";
import {SRVStore} from "../ScheduleRulesView/SRVStore";
import {ARVStore} from "../AppointmentsReviewView/ARVStore";
import { RRule} from "rrule";
import html2canvas from "html2canvas";
import {LustImageUploader, RegisterImageResponse} from "./LustImageUploader";

export type FeaturesInEventData = [number, any][]

interface LustUploadResponse {
    bucket_id: number;
    checksum: number;
    image_id: string;
    io_time: number;
    processing_time: number;
}

export class SCVStore {

    appStore: AppStore

    calendarRef: React.RefObject<FullCalendar> = React.createRef()
    pageContext

    scrollPosition = -1

    filterForm: MRF

    eventForm: MRF

    meetupEventForm: MRF


    @observable
    patientId

    patientData

    onDateClick: (params: DateClickArg) => any

    onResizeEvent: (params: EventResizeDoneArg) => any
    onDropEvent: (params: EventDropArg) => any
    onDragEvent: (params: EventDragStartArg) => any

    isRulesPage: boolean = false
    srvStore: SRVStore = null
    arvStore: ARVStore = null

    @observable
    resSelInPage = true

    @observable
    tmpHidden = false

    eventSel: C3Selection

    @observable
    basicInfoCollapsed = false

    @observable
    showRule = false

    @observable
    imageEnlarged = false

    @observable
    detailMode: 'event' | 'schedule' | 'feature-editor' = 'event';//"feature-editor"

    paymentMethodsMap = [
        {
            name: 'M',
            id: 'insCompany',
        },
        {
            name: 'P',
            id: 'private',
        },
        {
            name: 'T',
            id: 'all',
        }
    ]

    @observable
    printingImage = false

    @observable
    isUpdateRecurringOpen = false

    @observable
    isDeletePopoverOpen = false

    tabsSel: C3Selection

    fetchPromise: Promise<any>
    mountPromise: Promise<any>
    resolveMountPromise: Function

    @observable
    showMeetupEvents = false

    @observable
    eventsByDow = null

    @observable
    loading = {
        pullAll: false,
        autoAssignMeetupEvents: false,
        attendeesForAllMeetupEvents: false,
        pullMeetupEvent: false,
    }

    async init() {
        this.appStore = IATSStyleApp.instance.appStore


        this.filterForm = new MobxReactForm({
            fields: [
                'paymentMethod'
            ],
            values: {
                paymentMethod: `all`
            },
        });

        this.eventForm = new MobxReactForm({
            fields: [
                'id',
                'name',
                'description',
                'location',
                'startDate',
                'endDate',
                // 'ruleId',
                'ruleExpr',
                // 'ruleApplicableFrom',
                // 'ruleApplicableUntil',
                'recurrenceEditMode',
                'howToFindUs',
                'img',
            ],
            values: {
                recurrenceEditMode: 'following'
            }
        });

        this.meetupEventForm = new MobxReactForm({
            fields: [
                'id',
                'meetupId',
                'urlName',
                'name',
                'description',
                'localized_location',
                'howToFindUs',
                'start',
                'end',
            ],
            values: {
                recurrenceEditMode: 'following'
            }
        });

        this.eventSel = new C3Selection({
            items: [],
            onSelectionChanged: (selectedIds) => {

                let event = this.eventSel.selectedItem;
                if (!event) return;
                let ruleExprText: string;
                try {
                    ruleExprText = event.ruleExpr && RRule.fromString(event.ruleExpr).toText();
                } catch (e) {
                    ruleExprText = '[Invalid]'
                }
                let obj: any = {
                    ...event,
                    ruleExpr: ruleExprText
                };
                this.showRule = false
                this.eventForm.update(obj)
                this.featuresData = obj.featuresData || []
                // this.dlDocument(event.imageDocumentId)
                this.fetchEventAttendees(this.calendarEventsSelSelectedId)
                this.fetchMeetupEventsForEvent(this.calendarEventsSelSelectedId)
                this.calculateEventRepeatingDeltas()
                this.meetupEventAttendeesSel.itemsRef.current = []

                if (event.type == 'meetup-event') {
                    this.fetchMeetupEvent(this.calendarEventsSelSelectedId)
                    this.fetchAttendeesForMeetupEvent([this.calendarEventsSelSelectedId])
                }

                // await nextAnimationFrame()
                if (this.calEventsEventSource)
                    this.calEventsEventSource.refetch()
                if (this.meetupEventsEventSource)
                    this.meetupEventsEventSource.refetch()
            }
        })
        this.meetupEventSel = new C3Selection({
            onSelectionChanged: (selectedIds) => {
                this.fetchAttendeesForMeetupEvent(this.meetupEventSel.selectedIds)
            }
        })
        this.meetupEventAttendeesSel = new C3Selection({})
        this.tabsSel = new C3Selection({
            items: [
                {id: 'general'},
                {id: 'attendees'},
                {id: 'plots'},
                {id: 'competència'},
            ]
        })

        console.time('scvLoad')
        console.time('scvMount')
        console.time('scvFetch')
        console.time('scvCacheApply')

        this.mountPromise = new Promise((resolve, reject) => {
            this.resolveMountPromise = resolve
            // setTimeout(() => {
            //     resolve('foo');
            // }, 300);
        });

        this.fetchPromise = this.fetchToCache()

        let r1 = reaction(() => {
            return this.startDate
        }, async () => {
            // this.eventsMap[this.currentDate.toISOString()] = await this.fetchEventsForDate(this.currentDate)
        })

        reaction(() => {
            return this.showMeetupEvents
        }, async () => {
            return this.meetupEventsEventSource.refetch()
        })
        let storageReactions = []
        // reactions to save ui state to local storage
        let createReaction = (key: string, obj, variable) => {
            //  first set the value from local storage
            let value = localStorage.getItem(key)
            if (value) {
                obj[variable] = JSON.parse(value)
            }
            let r = autorun(() => {
                console.log(`value ${variable}`, obj[variable]);
                localStorage.setItem(key, JSON.stringify(obj[variable]))
            })
            storageReactions.push(r)
        }
        // createReaction('scv-basicInfoCollapsed', this.basicInfoCollapsed)

       createReaction('scv-detailMode', this, 'detailMode')
         createReaction('scv-basicInfoCollapsed', this, 'basicInfoCollapsed')
        createReaction('scv-showRule', this, 'showRule')
        createReaction('scv-showMeetupEvents', this, 'showMeetupEvents')


        // setInterval(() => {
        //     this.fetchEventsForDate(this.currentDate)
        // }, 15 * 1000)
        window['store'] = this

        this.enabledResourcesSel = new C3Selection({})
        let a1 = autorun(() => {
            this.enabledResourcesSel.itemsRef.current = this.totalResources
            this.enabledResourcesSel.selectWhere(() => true)
        })
        let r2 = reaction(() => this.patientId, () => {
            if (this.patientId == null) this.clearTmpEvents()
        })

        let s1 = this.appStore.events.obs.subscribe((e) => {
            if (e.entity != "v") return
            console.log(`SCV e`, e);
            let hit = _.isArray(e.days) && e.days.some(updatedDay => moment(updatedDay).isBetween(this.startDate, this.endDate, "day", "[]"))
            if (hit) {
                console.log(`SCV HIT`);
                this.refetch()
            }
            // this.appStore.updateResourceSelectorAnimation(e)
        })

        let p1 = this.awaitBothPromisesAndUpdate()

        p1.then(() => this.refetch())

        await this.fetchEventsByDow()

        return () => {
            console.log(`Unmounted`);
            r1()
            a1()
            r2()
            s1.unsubscribe()
            storageReactions.forEach(r => r())
        }

    }

    fetchFinished = false

    async awaitBothPromisesAndUpdate() {
        this.fetchFinished = false
        // console.log(`Awaiting both promises`);
        let p = await Promise.all([this.mountPromise, this.fetchPromise])
        // console.log(`Promises resolved`);
        // let resPromise = this.mapResAndUpdateCache(p[1], '');
        let cacheKey = this.getCacheKey(this.startDate, this.endDate);
        this.applyCachedResIfPresent(cacheKey)
        console.timeEnd('scvLoad')
    }


    el: HTMLElement
    eventTooltips: { [k: string]: Instance } = {}

    async calendarViewDidMount(params: ViewContentArg & { el: HTMLElement }) {
        this.el = params.el
        params.el.scrollTop = this.scrollPosition
        let executed = false
        await nextTimeout()
        let configEventSource = () => {
            let api = this.calendarRef.current?.getApi?.();
            if (!api) throw new Error('Calendar still not mounted')
            // console.log(`cvdm api`, api);
            if (api) {
                this.tmpEventsEventSource = api?.addEventSource({
                    events: (info, successCallback, failureCallback) => {
                        successCallback(this.tmpEvents as any)
                    }
                })

                this.calEventsEventSource = api?.addEventSource({
                    events: (info, successCallback, failureCallback) => {
                        let events = this.eventsRes
                            .filter(e => e.type != 'meetup-event')
                            .map(e => {
                                let selected = this.eventSel.isSelected(e.id)
                                return {
                                    ...e,
                                    backgroundColor: selected ? 'orange' : null,
                                    borderColor: selected ? 'orange' : null,
                                }
                            });
                        successCallback(events)
                        console.timeEnd('scvCacheApply')
                    }
                })

                this.meetupEventsEventSource = api?.addEventSource({
                    events: (info, successCallback, failureCallback) => {
                        let meetupEvents = [];
                        if (this.showMeetupEvents) {
                            meetupEvents = this.eventsRes
                                .filter(e => e.type == 'meetup-event')
                                .map(e => {
                                    let selected = this.eventSel.isSelected(e.id)
                                    let color = 'gray'
                                    if (selected) color = '#f64060'
                                    if (this.meetupEventSel.isSelected(e.id)) color = '#ffc5cf'
                                    return {
                                        ...e,
                                        backgroundColor: color,
                                        borderColor: color,
                                    }
                                })
                        }
                        successCallback(meetupEvents as any)
                    }
                })
                api.updateSize()
                this.updateDisplayDate()
                // this.fetchToCache()

                executed = true
            } else {
                // configEventSource()
            }

            // this.calendarApi.view.currentStart

            // this.calendarApi.addEventSource({
            //     url: `${C3.instance.client.fetcher.baseUrl}/schedule-view/calendar-events`,
            //     editable: true
            // })
        };
        configEventSource()
        console.timeEnd('scvMount')
        this.resolveMountPromise()
        if (this.cacheEnabled && this.fetchFinished == false) {
            // If still waiting
            let cacheKey = this.getCacheKey(this.startDate, this.endDate);
            this.applyCachedResIfPresent(cacheKey);
            console.log(`[SCV] Loaded from cache`);
        }

        // setTimeout(() => {
        //     configEventSource()
        //     if (!executed)
        //         setTimeout(configEventSource, 2000)
        // }, 1000)

    }

    viewWillUnmount(params: ViewContentArg & { el: HTMLElement }) {
        this.scrollPosition = params.el.scrollTop
        console.log(`params.el.scrollTop`, params.el.scrollTop);
    }

    @observable
    eventsMap: any[] = []

    @observable.shallow
    eventsRes = []

    @observable
    meetupEventsRes = []

    @observable
    startDate: Date = new Date()

    @observable
    endDate: Date

    @observable
    enabledResourcesSel: C3Selection

    @observable
    cacheEnabled = true

    @computed
    get events() {
        return this.eventsRes
        return this.eventsMap[this.startDate.toISOString()] || []
    }

    @computed
    get totalResources() {
        let resources = this.appStore?.resources || [];
        return resources.map(r => {
            return {
                id: r.id,
                title: r.name,
            }
        })
    }

    getResource(resId) {
        return this.appStore.resources.find(r => r.id == resId)
    }

    @computed
    get effectiveResources(): any {
        return this.enabledResourcesSel.selectedItems
    }

    @computed
    get calendarApi() {
        return this.calendarRef.current?.getApi?.()
    }

    async refetch() {
        this.fetchFinished = false
        if (this.request) this.request.abort()
        if (this.cacheEnabled && this.fetchFinished == false) {
            // If still waiting
            let cacheKey = this.getCacheKey(this.startDate, this.endDate);
            this.applyCachedResIfPresent(cacheKey);
            console.log(`[SCV] Loaded from cache`);
        }
        this.fetchPromise = this.fetchToCache()
        if (this.fetchPromise) await this.awaitBothPromisesAndUpdate()
    }

    async fetchToCache() {
        let newStartDate: Date;
        let newEndDate: Date;
        if (this.calendarApi) {
            newStartDate = this.calendarApi.getDate();
            newEndDate = moment(this.calendarApi.getDate()).add(this.calendarApi.getCurrentData().viewSpec.duration.days - 1, "day").toDate();
        } else {
            newStartDate = new Date()
            newEndDate = new Date()
        }
        if (this.startDate != newStartDate) this.startDate = newStartDate
        if (this.endDate != newEndDate) this.endDate = newEndDate
        let scvEventFetchPromise = this.fetchEventsForDate(this.startDate, this.endDate, 'events')
        let scvMeetupEventFetchPromise = this.fetchEventsForDate(this.startDate, this.endDate, 'meetup-events')
        //

        // if (start == null) return []
        // if (!end)
        //     end = moment(start).endOf('day').toDate()

        // Generate cache key
        let cacheKey = this.getCacheKey(this.startDate, this.endDate);
        // this.applyCachedResIfPresent(cacheKey);
        // Await and map fetched
        try {
            let {events} = await scvEventFetchPromise;
            let meetupEvents = await scvMeetupEventFetchPromise;
            console.timeEnd('scvFetch')
            this.fetchFinished = true
            let resPromise = this.mapResAndUpdateCache(events, meetupEvents, cacheKey);
            return resPromise
        } catch (e) {
            if (e instanceof DOMException) {
            } else {
                throw e
            }
        }
        return null
    }

    request: AbortController

    async fetchEventsForDate(start: Date, end: Date, type: 'events' | 'meetup-events' = 'events') {
        let scvFetchPromise = C3.instance.client.fetcher.fetch(`/${type == 'events' ? 'event' : 'meetup-event'}/calendar`, {
            method: 'GET',
            query: [
                `start=${start.toISOString()}`,
                `end=${end.toISOString()}`,
            ].join('&'),
        });
        // this.request = C3.instance.client.fetcher.abortController
        return scvFetchPromise
    }


    updateCalendarWithRes(res) {
        this.eventsRes = res || []
        // console.log(`this.eventsRes`, this.eventsRes);
        this.eventSel.itemsRef.current = res
        // this.meetupEventSel.itemsRef.current = res.filter(e => e.type == 'meetup-event')
        if (this.calEventsEventSource)
            this.calEventsEventSource.refetch()
        if (this.meetupEventsEventSource)
            this.meetupEventsEventSource.refetch()
    }

    private async mapResAndUpdateCache(events: any[], meetupEvents: any[], cacheKey: string) {
        if (!events) debugger
        // console.log(`events`, events, meetupEvents);
        let res = [...events.map(e => ({
            ...e,
            id: `event-${e.id}`,
            origId: e.id,
            type: 'event'
        })), ...meetupEvents.map(e => ({
            ...e,
            id: `meetup-event-${e.id}`,
            origId: e.id,
            resourceId: e.resource.id,
            type: 'meetup-event'
        }))].map(e => {
            // let cancelled = e.cancelledAt || e.noShow
            let statusInfo = getVisitStatusInfo(e?.stage?.name, {
                cancelledAt: e.cancelledAt,
                noShow: e.noShow,
                reminded: e.remindedAt,
                reported: e.teleraStatus == 'reported'
            })
            let statusInfoColors = {}
            let colorAllDays = true;
            if (colorAllDays || moment(e.start).isBetween(moment().startOf('day'), moment().endOf('day'))) {
                statusInfoColors = {
                    backgroundColor: statusInfo?.color,
                    borderColor: statusInfo?.color,
                }
                if (e.type == 'meetup-event') {
                    let selected = this.eventSel.isSelected(e.id)
                    let color = 'gray'
                    if (selected) color = '#f64060'
                    if (this.meetupEventSel.isSelected(e.id)) color = '#ffc5cf'
                    statusInfoColors = {
                        backgroundColor: selected ? 'orange' : 'gray',
                        borderColor: selected ? 'orange' : 'gray',
                    }
                }
            }
            // console.log(`e.stage`, e?.stage, statusInfo);
            return ({
                startDate: e.start,
                endDate: e.end,
                ...e,
                // color
                type: e.type,
                title: e.name,
                // backgroundColor: '#5C7080',

                ...statusInfoColors,
                start: moment(e.startDate).toDate(),
                end: moment(e.endDate).toDate(),
                ...(e.type == 'meetup-event' ? {start: moment(e.start).toDate(), end: moment(e.end).toDate()} : {}),
                statusInfo
            });
        });

        this.eventsMap[cacheKey] = res
        // console.log(`res`, res);
        return res;
    }

    private applyCachedResIfPresent(cacheKey: string) {
        let cachedRes = this.eventsMap[cacheKey];
        // console.log(`applying cachedRes: ${cacheKey}`, cachedRes);
        if (cachedRes) {
            // setTimeout(() => this.updateCalendarWithRes(cachedRes))
            this.updateCalendarWithRes(cachedRes)
        }
    }

    private getCacheKey(start: Date, end: Date) {
        return moment(start).format('DD/MM/YYYY') + '-' + moment(end).format('DD/MM/YYYY');
    }

    async fetchMeetupEventsForEvent(eventId) {
        if (!eventId) return null
        let meetupEvents = await C3.instance.client.fetcher.fetch(`/event/${eventId}/meetup-events-stats`, {});
        // this.meetupEventSel.itemsRef.current = meetupEvents.map(e => ({...e, id: `meetup-event-${e.id}`, origId:
        // e.id, resourceId: e.resource.id, type: 'meetup-event'}))
        this.meetupEventSel.itemsRef.current = [...meetupEvents]
        console.log(`this.meetupEventSel.itemsRef.current`, this.meetupEventSel.itemsRef.current);
        return meetupEvents
    }

    async fetchMeetupEvent(id) {
        let res = await C3.instance.client.fetcher.fetch(`/meetup-event/${id}`, {});
        this.meetupEventForm.update(res)
    }

    async fetchAttendeesForMeetupEvent(meetupEventIds) {
        let meetupEventsAttendees = await C3.instance.client.fetcher.fetch(`/event/meetup-event-attendees`, {
            query: [`meetupEventIds=${meetupEventIds}`]
        });
        console.log(`meetupEventsAttendees`, meetupEventsAttendees);
        this.meetupEventAttendeesSel.itemsRef.current = [...meetupEventsAttendees]
        console.log(`this.meetupEventAttendeesSel`, this.meetupEventAttendeesSel.itemsRef.current);
        return meetupEventsAttendees
    }

    async copyEventFromMeetupEvent() {
        let meetupEventIds = [this.calendarEventsSelSelectedId];
        console.log(`meetupEventIds`, meetupEventIds);
        if (!meetupEventIds) return
        let res = await C3.instance.client.fetcher.fetch(`/meetup/copy-event`, {
            method: 'POST',
            body: {meetupEventIds}
        })
        await this.refetch()
    }

// Tmp events

    @observable
    tmpEvents: EventInput[] = []
    tmpEventsEventSource: EventSourceApi
    calEventsEventSource: EventSourceApi
    meetupEventsEventSource: EventSourceApi
    nextTmpId = 0

    addTmpEvent(resourceId, date: Date) {
        let foundEventIndex = this.tmpEvents.findIndex(e => e.resourceId == resourceId);
        if (foundEventIndex >= 0) {
            this.tmpEvents.splice(foundEventIndex, 1)
            // this.tmpEvents[foundEventIndex].id = `tmp-${this.nextTmpId++}`
            // this.tmpEvents[foundEventIndex].date = date
        } else {

        }
        this.tmpEvents.push({
            id: `tmp-${this.nextTmpId++}`,
            resourceId: resourceId,
            title: formatFormalName(this.patientData),
            start: date,
            durationEditable: true,
            end: moment(date).add(15, 'minutes').toDate(),
            extendedProps: {
                tmp: true,
                resourceId
            },
            backgroundColor: '#d9900b',
            borderColor: '#d69f5b'
        })
        this.tmpEventsEventSource.refetch()
    }

    clearTmpEvents() {
        this.tmpEvents = []
        this.tmpEventsEventSource.refetch()

    }

    removeTmpEvent(eventId) {
        _.remove(this.tmpEvents, e => e.id == eventId)
        this.tmpEventsEventSource.refetch()
    }

    acceptTmpEvents(jsEvent) {
        if (this.pageContext && this.patientId) {
            let newAppointments = this.tmpEvents.map(tmpEvt => {
                return {
                    resourceId: tmpEvt.resourceId,
                    appointmentDate: tmpEvt.start,
                }
            })
            this.pageContext.navigate({
                replace: true,
                to: 'bookVisitPage', args: {
                    patientId: this.patientId,
                    newAppointments,
                },
                inNewTab: jsEvent?.ctrlKey, focusNewTab: true
            })

        }
        this.tmpEvents = []
        this.tmpEventsEventSource.refetch()
    }

    @action
    backButton() {
        this.calendarApi.prev()
        this.updateDisplayDate()
        this.refetch()
    }

    forwardButton() {
        this.calendarApi.next()
        this.updateDisplayDate()
        this.refetch()
    }

    todayButton() {
        this.calendarApi.today()
        this.updateDisplayDate()
        this.refetch()

    }

    @computed
    get isToday() {
        return moment(this.startDate).isSame(moment(), 'day')
    }

    async goToDate(date: Date) {
        if (!_.isDate(date)) return
        let zonedDateInput: Date = date;
        if (this.calendarApi.getCurrentData().viewSpec.duration.days == 7) {
            zonedDateInput = moment(date).startOf('week').toDate();
        }
        this.calendarApi.gotoDate(zonedDateInput)
        this.updateDisplayDate()
        await this.refetch()

    }


    async showView(view) {
        this.calendarApi.changeView(view)
        this.updateDisplayDate()
        await this.refetch()
    }

    @action
    changeShowWeekend(): void {
        this.showWeekend = !this.showWeekend
    }

    @observable
    showWeekend = true

    @observable
    displayDate = ''

    updateDisplayDate() {
        this.displayDate = this.calendarApi.currentDataManager.getCurrentData().viewTitle

        this.tmpEventsEventSource?.refetch()

    }

    async saveVisit(visit, refetch = false) {
        this.cacheEnabled = false
        let res = await C3.instance.client.fetcher.fetch('/svv' + `/visits/${visit.id}`, {
            method: 'PATCH',
            body: visit
        })

        await nextAnimationFrame()
        if (refetch) await this.fetchToCache()
        return res
    }

    @computed
    get rRuleExpr() {
        let ruleExpr = this.eventForm.$('ruleExpr').value;
        try {
            return RRule.fromText(ruleExpr).toString()
        } catch (e) {
            return '[Invaild]'
        }
    }

    async saveEvent(event, refetch = true) {
        this.cacheEnabled = false

        // const expr = new RRule({
        //     freq: RRule.WEEKLY,
        //     interval: 5,
        //     byweekday: [RRule.MO, RRule.FR],
        //     dtstart: new Date(Date.UTC(2012, 1, 1, 10, 30)),
        //     until: new Date(Date.UTC(2012, 12, 31))
        // })

        event.ruleExpr = this.rRuleExpr;
        event._action = {mode: 'all'}

        // event: {
        //   ...
        //   recurrence: {
        //     expr: "RRULE:INTERVAL=2;FREQ=WEEKLY;BYDAY=FR;COUNT=9",
        //     mode: "all",
        //
        //   },
        //   ...
        // }
        console.log(`saved event`, event);
        let res = await C3.instance.client.fetcher.fetch(`/event/${event.id}/edit`, {
            method: 'PATCH',
            body: event
        })

        // await nextAnimationFrame()
        if (refetch) await this.refetch()
        if (event.ruleExpr) await this.calculateEventRepeatingDeltas();

        return res
    }

    async saveMeetupEvent(body) {
        if (!this.calendarEventsSelSelectedId) return
        let res = await C3.instance.client.fetcher.fetch(`/meetup-event/${this.calendarEventsSelSelectedId}/save-local`, {
            method: 'PATCH',
            body: body
        })
    }

    async pushMeetupEvent(id, force = false) {
        let res = await C3.instance.client.fetcher.fetch(`/meetup-event/${id}/push-meetup-event`, {
            method: 'POST',
            body: {force: force}
        })
    }

    async pullMeetupEvent(id) {
        this.loading.pullMeetupEvent = true
        await C3.instance.client.fetcher.fetch(`/meetup-event/${id}/pull-meetup-event`, {
            method: 'GET',
        })
        this.loading.pullMeetupEvent = false
        await this.refetch()
    }

    async pullAll() {
        this.loading.pullAll = true
        let res = await C3.instance.client.fetcher.fetch(`/meetup-event/pull-all`, {
            method: 'GET',
        })
        this.loading.pullAll = false
        IATSStyleApp.instance.toaster.show({
            intent: "success",
            icon: 'import',
            timeout: 6000,
            message: `Pulled ${res?.updatedMeetupEventsCount} events from ${res?.updatedGroupsCount}/${res?.activeGroupCount} groups. `,
            action: {
                text: 'Log',
                onClick: () => console.log(res)
            }
        })
        await this.refetch()
    }

    async autoAssignMeetupEvents() {
        this.loading.autoAssignMeetupEvents = true

        await C3.instance.client.fetcher.fetch(`/meetup-event/auto-assign`, {
            method: 'POST',
        })
        this.loading.autoAssignMeetupEvents = false

        await this.refetch()
    }

    async fetchAttendeesForAllMeetupEvents() {
        this.loading.attendeesForAllMeetupEvents = true

        let start = this.eventSel.selectedItem?.start;
        let res = await C3.instance.client.fetcher.fetch(`/meetup-event/all-attendees`, {
            method: 'GET',
            query: [`start=${(new Date()).toISOString()}`].join('&'),
        })
        this.loading.attendeesForAllMeetupEvents = false

        IATSStyleApp.instance.toaster.show({
            intent: "success",
            icon: 'import',
            timeout: 6000,
            message: `Imported ${res.importedAttendeesCount} new attendees for ${res.updatedMeetupEventsCount} events from ${res?.meetupGroupsCount}/${res?.activeGroupCount} groups. `,
            action: {
                text: 'Log',
                onClick: () => console.log(res)
            }
        })

        await this.refetch()
    }

    async ensureEvent(eventId, refetch = true) {
        let res = await C3.instance.client.fetcher.fetch(`/event/${eventId}/refresh`, {
            method: 'POST',
        })
        if (refetch) await this.refetch()
        return res
    }

    async deleteEvent(eventId, refetch = true) {
        let res = await C3.instance.client.fetcher.fetch(`/event/${eventId}`, {
            method: 'DELETE',
        })
        if (refetch) await this.refetch()
        return res
    }

    async deleteFollowingEvents(eventId) {
        let res = await C3.instance.client.fetcher.fetch(`/event/${eventId}/end-repeating-event`, {
            method: 'DELETE',
        })
        await this.refetch()
        return res
    }

    hideAllTooltips() {
        _.entries(this.eventTooltips).forEach(([k, v]) => v.hide())
    }

    async updateEventStartEnd(eventId, start: Date, end: Date, resourceId?) {
        this.eventTooltips[eventId]?.hide?.()

        let body = {
            id: eventId,
            startDate: moment(start).toDate(),
            duration: moment(end).diff(start, 'minutes'),
            endDate: moment(end).toDate(),
            // ...(resourceId && {resource: {id: resourceId}}),
        }
        // if (!this.canSave) return
        console.log(`start, end`, start, end);
        this.eventForm.$('startDate').onChange(start)
        this.eventForm.$('endDate').onChange(end)
        // this.eventForm.update({...this.eventForm.values(), startDate: start, endDate: end})
        // await this.saveEvent(body, false)
    }

    isUploadingImage = false

    @observable
    isAttachDocumentsMode = false

    private imageUploader: LustImageUploader = new LustImageUploader()

    @observable
    eventAttendees

    @observable
    meetupEventSel: C3Selection

    @observable
    meetupEventAttendeesSel: C3Selection

    @action
    async uploadImageForEvent(files: FileList) {
        if (files.length === 0) {
            console.error('No file selected');
            return null;
        }

        this.isUploadingImage = true;
        let uploadedImage: RegisterImageResponse | null = null;

        try {
            const file = files[0]; // Get only the first file
            uploadedImage = await this.imageUploader.uploadEventImage(file);

            if (this.calendarEventsSelSelectedId) {
                await this.associateImagesWithEvent(this.calendarEventsSelSelectedId, uploadedImage);
            }

            console.log('Successfully uploaded and attached image:', uploadedImage);
            this.isUploadingImage = false;
        } catch (error) {
            console.error('Failed to attach document:', error);
        } finally {
            this.isUploadingImage = false;
        }

        return uploadedImage;
    }

    private async associateImagesWithEvent(eventId: number, image: RegisterImageResponse) {
        // Implement the logic to associate the uploaded images with the event
        console.log('Associating image with event:', eventId, image);
        if (!eventId || !image) {
            console.error('Invalid eventId or image');
            return;
        }
        await this.saveEvent({
            id: eventId,
            imageId: image.id,
        })
    }

    downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        link['_target'] = 'blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async fetchMeetupRSVPs(eventId) {
        let res = await C3.instance.client.fetcher.fetch(`/event/sync-meetup-attendees/${eventId}`, {});
        await this.refetch()
        await this.fetchMeetupEventsForEvent(eventId)
        return res
    }

    async clearMeetupAttendees(eventId) {
        let res = await C3.instance.client.fetcher.fetch(`/event/${eventId}/clear-meetup-attendees`, {});
        await this.refetch()
        return res
    }

    async fetchEventAttendees(eventId) {
        let res = await C3.instance.client.fetcher.fetch(`/event/${eventId}/attendees`, {
            query: [
                `going=${true}`,
                `n=${20}`,
            ].join('&'),
        });
        this.eventAttendees = res
        return res
    }

    async openAllMeetupEvents(eventId, forEdit: boolean = true) {
        for (let i2 = 0; i2 < this.meetupEventSel.items.length; i2++) {
            const me = this.meetupEventSel.items[i2];
            let relative = forEdit ? `/edit/?fromSeries=true` : '';
            setTimeout(() => {
                window.open(`https://www.meetup.com/${me.urlName}/events/${me.meetupId}${relative}`, '_blank')
            }, 100)
        }
    }

    @observable
    missingEvents = []

    @observable
    extraEvents = []

    async calculateEventRepeatingDeltas() {
        // TODO in case it is implemented
        return []
        if (!this.calendarEventsSelSelectedId) return
        let res = await C3.instance.client.fetcher.fetch(`/event/${this.calendarEventsSelSelectedId}/event-repeating-deltas`, {});
        console.log(`res missingEvents extraEvents`, res);
        // this.missingEvents = res.missingEvents
        // this.extraEvents = res.extraEvents
        return res
    }

    async applyRepeatingDeltas(mode: 'create' | 'delete') {
        if (!this.calendarEventsSelSelectedId) return
        let res = await C3.instance.client.fetcher.fetch(`/event/${this.calendarEventsSelSelectedId}/apply-repeating-deltas`, {
            query: [`mode=${mode}`].join('&')
        });
        await this.calculateEventRepeatingDeltas()
        await this.refetch()
        return res
    }

    @observable
    i = 0

    @computed
    get canSave() {
        let selectedItem = this.eventSel.selectedItem;
        if (!selectedItem) return false
        console.log(`i`, this.i);
        let fields = ["name", "description", "startDate", "endDate", "location", "ruleExpr"];
        let values: any = {}
        fields.forEach(f => {
            let value = this.eventForm.$(f).value;
            if (f.endsWith('Date') && value) value = moment(value).toDate()
            return values[f] = value;
        })
        let filterItem = (i) => {
            if (i.startDate) i.startDate = moment(i.startDate).toDate()
            if (i.endDate) i.endDate = moment(i.endDate).toDate()
            return _.pick(i, fields);
        }
        let c1 = filterItem(selectedItem);

        let c2 = filterItem(values);
        console.log(`values, selectedItem`, values, selectedItem);
        console.log(c1, c2)
        let equal = _.isEqual(c1, c2)
        return (values.name && values.startDate && values.endDate) && !equal
    }

    @computed
    get calendarEventsSelSelectedId() {
        return this.eventSel.selectedItem?.origId
    }

    @computed
    get meetupEventsSelSelectedId() {
        if (!this.meetupEventSel.selectedId) return null
        return this.meetupEventSel.selectedItem?.id
    }

    async fetchEventsByDow() {
        let res = await C3.instance.client.fetcher.fetch('/event/schedule/events-by-dow-flat', {});
        console.log(`fetchEventsByDow`, res);
        this.eventsByDow = res
        return res
    }

    @observable
    featuresData = []

    addFeature(option) {
        console.log(`option`, option);
        this.featuresData.push(option)
        console.log(`this.featuresData`, this.featuresData);
        this.saveFeaturesDebouncer()
    }

    removeFeature(id) {
        console.log(`removing`, id);
        _.remove(this.featuresData, e => e.id == id)
        this.saveFeaturesDebouncer()
    }

    saveFeaturesDebouncer = _.debounce(() => this.saveFeatures(), 500)

    async saveFeatures() {
        let selectedId = this.eventSel.selectedItem.id as string;
        if (!selectedId) return
        selectedId = selectedId.split('-')[1]
        if (!selectedId) return
        await this.saveEvent({id: selectedId, featuresData: this.featuresData})
    }

    async downloadScheduleImage() {
        this.printingImage = true;
        await this.fetchEventsByDow()
        await nextAnimationFrame()
        let el = document.querySelector('#schedule-root')
        if (!el) return
        let res = await html2canvas(el as any, {
            // width: 1080,
            // height: 1920,
            scale: 4,
            useCORS: true,
            scrollX: 0,
            scrollY: 0,
            backgroundColor: null,
        });
        let dataUrl = res.toDataURL('image/png');
        // console.log(`dataUrl`, dataUrl);
        // window.open(dataUrl, '_blank')
        // copy dataurl to clipboard
        // await navigator.clipboard.writeText(dataUrl)
        this.downloadURI(dataUrl, `schedule-${moment().format('DD-MM-YYYY')}.png`)
        this.printingImage = false;
    }

}
