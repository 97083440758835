import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {AppointmentsView} from "../../components/AppointmentsView/AppointmentsView";
import {SVVStore} from "../../components/StandardVisitView/SVVStore";
import {BookVisitsPageHeader} from "../BookVisitsPage/BookVisitsPageHeader";
import {Button, ButtonGroup, NonIdealState} from "@blueprintjs/core";
import {StandardVisitView} from "../../components/StandardVisitView/StandardVisitView";
import {Page} from "react-c4";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {SCVStore} from "../../components/ScheduleView/SCVStore";
import {page} from "react-navigation-plane";
import {formatFormalName} from "../../application/utils/utils";
import {SchedulePageHeader} from "./SchedulePageHeader";
import {SchedulePageSubheader} from "./SchedulePageSubheader";
import {ResourceSelector} from "../../application/components/shared/ResourceSelector";


const Container = styled.div`
    display: grid;
    height: 100%;
`
const ResourceSelectorContainer = styled.div`
    display: grid;
    padding: 4px;
    margin-bottom: -8px;
    place-content: center;
`


export interface SchedulePageProps {

}

export const SchedulePage = page(observer((props: SchedulePageProps) => {
    let [scStore, setScStore] = useState(null as SCVStore);

    return <Page
        title={'Calendari'}
        // subtitle={(scStore?.appStore?.effectiveResources || []).map(er => er.title)
        //     .reduce((acc, cv, i, a) => acc + (i == 0 ? '' : ((i == a.length - 1) ? ' i ' : ', ')) + cv, '')}
        icon={'calendar'}
        attributes={[
            {
                name: 'Pacient',
                value: formatFormalName(scStore?.patientData),
            }
        ]}
        actions={[
            // {
            //     name: 'refresh',
            //     iconName: 'refresh',
            //     callback: () => scStore.fetch()
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <SchedulePageHeader store={scStore}/>
        }}
        renderBody={({pageContext}) => {
            let patientId = pageContext.args.patientId;
            let patientData = pageContext.args.patientData;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <SchedulePageSubheader store={scStore}/>
                <ResourceSelectorContainer>
                    <ResourceSelector/>
                </ResourceSelectorContainer>
                <ScheduleView onStoreReady={(s) => {
                    s.patientId = patientId
                    s.patientData = patientData
                    s.pageContext = pageContext
                    setScStore(s);
                }}
                              {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
