import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {formatFormalName} from "../../application/utils/utils";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {Page} from "react-c4";
import {ProdFVStore} from "../../files-views/Product/ProdFVStore";
import {ProductFilePageHeader} from "./ProductFilePageHeader";
import {ProdFV} from "../../files-views/Product/ProdFV";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface ProductFilePageProps {

}

export const ProductFilePage = page(observer((props: ProductFilePageProps) => {
    let [store, setStore] = useState(null as ProdFVStore);

    return <Page
        title={'Assign Events And Meetup Events'}
        subtitle={'Events i assignació meetup events'}

        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <ProductFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <ProdFV onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
