import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSVVStore} from "../../StandardVisitView";
import {VisitLabel} from "../../../../documents/VisitLabel/VisitLabel";
import {FitxaBlanca} from "../../../../documents/FitxaBlanca";
import {formatFormalName} from "../../../../application/utils/utils";
import moment from "moment";
import {YourTurnSheet} from "../../../../documents/YourTurnSheet/YourTurnSheet";
import {MedicalReceipt} from "../../../../documents/MedicalReceipt/MedicalReceipt";

const Container = styled.div`
    display: grid;
    grid-auto-flow: row;
    place-content: start;
    max-width: 700px;
    transform: scale(0.85) translate(-10%, -10%);
`

export interface SVVPrintCanvasProps {

}


export const SVVPrintCanvas = observer((props: SVVPrintCanvasProps) => {
    let store = useSVVStore()
    let printCanvasMap = {
        visitLabel: () => {
            let visit = store.visitsSel.selectedItem;
            if (!visit) return <div>--</div>
            return <VisitLabel onPrintReady={(h) => store.printHandle = h}
                               data={{
                                   fullName: formatFormalName(store.svvRes.patient),
                                   date: visit?.appointmentDate ? moment(visit?.appointmentDate).format('DD-MM-YYYY') : '(Sense data)',
                                   visitNumber: visit?.visitNumber,
                               }}/>;
        },
        fitxaBlanca: () => {
            let visit = store.visitsSel.selectedItem;
            if (!visit) return <div>--</div>
            return <FitxaBlanca onPrintReady={(h) => store.printHandle = h} data={{
                patient: store.svvRes.patient,
                visit: visit,
                visitLines: store.getVisitLinesForVisit([visit.id]),
                turn: store.patientTurnNumber,
                org: store.appStore.orgsSel
            }}/>;
        },
        yourTurn: () => {
            let visit = store.visitsSel.selectedItem;
            if (!visit) return <div>--</div>
            return <YourTurnSheet onPrintReady={(h) => store.printHandle = h} data={{
                turnNumber: store.patientTurnNumber,
                turnRes: store.patientTurnRes
            }}/>;
        },
        medicalReceipt: () => {
            let visit = store.visitsSel.selectedItem;
            if (!visit) return <div>--</div>
            return <MedicalReceipt onPrintReady={(h) => store.printHandle = h} svvStore={store} data={{
                visit: visit,
                visitLines: store.getVisitLinesForVisit([visit.id]),
                patient: store.svvRes.patient,
            }}/>;
        }
    }
    return <Container>
        {[store.currentlyPrinting].filter(s => s != null).map((printItemKey) => {
            return printCanvasMap[printItemKey]()
        })}
    </Container>
})
