import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRSCStore} from "../../../../../RSCMain";
import {SmallSearchCreateInput} from "./components/SmallSearchCreateInput";
import {RSCNewItemInputBox} from "./components/RSCNewItemInputBox";
import {RSCSetItemDisplay} from "./components/RSCSetItemDisplay";

const Container = styled.div`
  display: grid;
  width: 200px

`

export interface RSCCombinedInputProps {
    title,
    fieldName,
    inputRef,
    entityTitle,
    associatingToText,
}

/**
 * Dumb component that
 */
export const RSCCombinedInput = observer((props: RSCCombinedInputProps) => {
    let store = useRSCStore()
    let field = store.form.$(props.fieldName);
    let qField = field.$('q')
    let creatingField = field.$('creating')
    let setId = field.$('setId')
    let element: JSX.Element;
    let creating = creatingField.value;
    if (creating) {
        element = <RSCNewItemInputBox inputRef={props.inputRef} field={field} entityTitle={props.entityTitle}
                                      associatingToText={props.associatingToText} fieldName={props.fieldName}/>
    } else if (setId.value) {
        element = <RSCSetItemDisplay field={field} type={props.fieldName}/>
    } else {
        element = <SmallSearchCreateInput inputProps={{...qField.bind(), inputRef: props.inputRef}}
                                          onClearClick={() => qField.clear()}/>;
    }
    return <Container>
        <div>{props.title}</div>
        {element}
    </Container>
})
