import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const documentTypeSchema: C3SchemaInfo = {
    'document-type': {
        fields: {
            id: {},
            name: {},
            documents: {
                type: 'document'
            }
        }
    }
}
