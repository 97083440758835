import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {DCSFooter} from "./DCSFooter/DCSFooter";
import {DCSGrid} from "./DCSGrid/DCSGrid";
import {useDCSStore} from "../DailyCheckoutSheetView";
import {useReactToPrint} from "react-to-print";
import {DCSHeader} from "./DCSHeader/DCSHeader";
import * as moment from "moment";

const Container = styled.div`
  display: grid;
  height: 100%;
  padding: 8px;
  grid-auto-rows: auto 1fr auto;

  @media print {
    @page { size: landscape; }
    padding: 1cm;
  }

`

export interface DCSMainProps {
    pageContext
}

export const DCSMain = observer((props: DCSMainProps) => {
    let store = useDCSStore()
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        documentTitle: 'llistat-caixa-' + moment(store.filterForm.$('startDate').value).format('DD-MM-YYYY'),
        content: () => componentRef.current,
    });
    useEffect(() => {
        store.printHandle = handlePrint
    }, [])
    return <Container ref={componentRef}>
        <DCSHeader/>
        <DCSGrid/>
        <DCSFooter/>
    </Container>
})
