import HomePage from "./HomePage/HomePage";
import {ProfilePage} from "./ProfilePage/ProfilePage";
import {FilesPage} from "./FilesPage/FilesPage";
import {BookVisitsPage} from "./BookVisitsPage/BookVisitsPage";
import {PatientsPage} from "./PatientsPage/PatientsPage";
import {InvoicesPage} from "./InvoicesPage/InvoicesPage";
import {AutomaticInvoicesPage} from "./AutomaticInvoicesPage/AutomaticInvoicesPage";
import {SchedulePage} from "./SchedulePage/SchedulePage";
import {ResourceFilePage} from "../files-pages/ResourceFilePage/ResourceFilePage";
import {ReferrerFilePage} from "../files-pages/ReferrerFilePage/ReferrerFilePage";
import {ExtCenterFilePage} from "../files-pages/ExtCenterFilePage/ExtCenterFilePage";
import {DailyCheckoutSheetPage} from "./DailyCheckoutSheetPage/DailyCheckoutSheetPage";
import {RateFilePage} from "../files-pages/RateFilePage/RateFilePage";
import {ProductFilePage} from "../files-pages/ProductFilePage/ProductFilePage";
import {InsCompanyFilePage} from "../files-pages/InsCompanyFilePage/InsCompanyFilePage";
import {OrgFilePage} from "../files-pages/OrgFilePage/OrgFilePage";
import {AllVisitsPage} from "./AllVisitsPage/AllVisitsPage";
import {InsCompanyInvoicingPage} from "./InsCompanyInvoicingPage/InsCompanyInvoicingPage";
import {InvoiceEditorPage} from "./InvoiceEditorPage/InvoiceEditorPage";
import {AppointmentListPage} from "./AppointmentListPage/AppointmentListPage";
import {UserLogEntriesPage} from "./UserLogEntriesPage/UserLogEntriesPage";
import {ContactFilePage} from "../files-pages/ContactFilePage/ContactFilePage";
import {BasicStatisticsPage} from "./BasicStatisticsPage/BasicStatisticsPage";
import {ScheduleRulesPage} from "./ScheduleRulesPage/ScheduleRulesPage";
import {UserFilePage} from "../files-pages/UserFilePage/UserFilePage";
import {BusinessLineFilePage} from "../files-pages/BusinessLineFilePage/BusinessLineFilePage";
import {ProductFamiliesFilePage} from "../files-pages/ProductFamiliesFilePage/ProductFamiliesFilePage";
import {ManualInvoicingPage} from "./ManualInvoicingPage/ManualInvoicingPage";
import {AppointmentsReviewPage} from "./AppointmentsReviewPage/AppointmentsReviewPage";
import {PatientFusionPage} from "./PatientFusionPage/PatientFusionPage";
import {PrescriptionPage} from "./PrescriptionPage/PrescriptionPage";
import {MeetupEventsPage} from "./MeetupEventsPage/MeetupEventsPage";
import {AllDeltasPage} from "./AllDeltasPage/AllDeltasPage";
import {MeetupPushEventsPage} from "./MeetupPushEventsPage/MeetupPushEventsPage";
import {FeatureFilePage} from "../files-pages/FeatureFilePage/FeatureFilePage";

export const pages = {
    home: {
        component: HomePage,
        title: 'Inicio',
        singleInstance: true
    },
    // dashboard: {
    //     component: DashboardPage,
    //     title: 'Tauler de control',
    //     singleInstance: true
    // },
    profile: {
        component: ProfilePage,
        title: 'Perfil',
        singleInstance: true
    },
    bookVisitPage: {
        component: BookVisitsPage,
        title: 'Reserva de cites',
        singleInstance: false
    },
    schedule: {
        component: SchedulePage,
        title: 'Màquines',
        singleInstance: true
    },
    meetupPushEventsPage: {
        component: MeetupPushEventsPage,
        title: 'Màquines',
        singleInstance: true
    },
    files: {
        component: FilesPage,
        title: 'Fitxers',
        singleInstance: true
    },
    patients: {
        component: PatientsPage,
        title: 'Pacients',
        singleInstance: true
    },
    // patient: {
    //     component: PatientPage,
    //     title: 'Pacients',
    //     singleInstance: false
    // },

    // reception: {
    //     component: ReceptionPage,
    //     title: 'Recepcionista',
    //     singleInstance: true
    // },
    invoicesPage: {
        component: InvoicesPage,
        title: 'Factures',
        singleInstance: true,
    },
    automaticInvoicesPage: {
        component: AutomaticInvoicesPage,
        title: 'Facturació automàtica',
        singleInstance: true,
    },
    dailyCheckoutSheet: {
        component: DailyCheckoutSheetPage,
        title: 'Full de caixa',
        singleInstance: true
    },

    allVisitsPage: {
        component: AllVisitsPage,
        title: 'Visites diaries',
        singleInstance: true
    },
    insCompanyInvoicing: {
        component: InsCompanyInvoicingPage,
        title: 'Visites diaries',
        singleInstance: true
    },
    invoiceEditorPage: {
        component: InvoiceEditorPage,
        title: 'Factures',
        singleInstance: true
    },
    appointmentListPage: {
        component: AppointmentListPage,
        title: 'Llista de cites',
        singleInstance: true
    },

    userLogEntriesPage: {
        component: UserLogEntriesPage,
        title: `Registre d'activitat`,
        singleInstance: true
    },

    basicStatisticsPage: {
        component: BasicStatisticsPage,
        title: `Estadístiques bàsiques`,
        singleInstance: true
    },
    meetupEventsPage: {
        component: MeetupEventsPage,
        title: `Events i grups de meetup`,
        singleInstance: true
    },
    allDeltasPage: {
        component: AllDeltasPage,
        title: `Events i grups de meetup`,
        singleInstance: true
    },
    scheduleRulesPage: {
        component: ScheduleRulesPage,
        title: `Regles de calendari`,
        singleInstance: true
    },


    manualInvoicingPage: {
        component: ManualInvoicingPage,
        title: `Facuració manual`,
        singleInstance: true
    },
    appointmentsReviewPage: {
        component: AppointmentsReviewPage,
        title: `Revisió de cites`,
        singleInstance: true
    },
    patientFusionPage: {
        component: PatientFusionPage,
        title: `Fusió de pacients`,
        singleInstance: true
    },

    // Files
    resourcesPage: {
        component: ResourceFilePage,
        title: 'Recursos',
        singleInstance: true,
    },
    referrersPage: {
        component: ReferrerFilePage,
        title: 'Referents',
        singleInstance: true,
    },
    extCenterFilePage: {
        component: ExtCenterFilePage,
        title: 'Centres externs',
        singleInstance: true,
    },
    ratesFilePage: {
        component: RateFilePage,
        title: 'Barems',
        singleInstance: true,
    },
    productFilePage: {
        component: ProductFilePage,
        title: 'Proves',
        singleInstance: true,
    },
    insCompanyFilePage: {
        component: InsCompanyFilePage,
        title: 'Asseguradores',
        singleInstance: true,
    },
    orgFilePage: {
        component: OrgFilePage,
        title: 'Organitzacions',
        singleInstance: true,
    },
    contactsPage: {
        component: ContactFilePage,
        title: 'Contactes',
        singleInstance: true,
    },
    usersPage: {
        component: UserFilePage,
        title: 'Usuaris',
        singleInstance: true,
    },
    businessLinePage: {
        component: BusinessLineFilePage,
        title: 'Usuaris',
        singleInstance: true,
    },
    productFamiliesFilePage: {
        component: ProductFamiliesFilePage,
        title: 'Usuaris',
        singleInstance: true,
    },

    prescriptionPage: {
        component: PrescriptionPage,
        title: 'Prescripción',
        singleInstance: true,
    },
    featureFilePage: {
        component: FeatureFilePage,
        title: 'Feature editor',
        singleInstance: true,
    },
}
