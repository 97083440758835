import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {Button, Icon, InputGroup, Radio, RadioGroup, Spinner} from "@blueprintjs/core";
import {ProcessTabLayout} from "../components/ProcessTabLayout";

const Container = styled.div`
    display: grid;
    grid-gap: 8px;
    
`
const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: end;
    grid-gap: 4px;
`
const ButtonContainer = styled.div`
    display: grid;
    place-items: center;
`
const SplitContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
`

export interface ContrastTabProps {
    store: PatientOverviewStore

}

export const ContrastTab = observer((props: ContrastTabProps) => {
    let [amount, setAmount] = useState(0)
    let [paid, setPaid] = useState(false)
    let [connected, setConnected] = useState(false)
    let [accepted, setAccepted] = useState(false)
    let [s, setS] = useState('cash')
    return <ProcessTabLayout title={'Pagament contrast'} buttonsFragment={<React.Fragment>
        <Button onClick={() => props.store.closeAdditionalTab()}>Sortir</Button>
        {paid && <Button icon={'print'}>Impirmir rebut</Button>}
        {paid && <Button icon={'print'}>Impirmir factura</Button>}
    </React.Fragment>}>
        <Container>
            {!paid && <label>Import (€): <InputGroup defaultValue={'150'} onChange={(e) => setAmount(e.target.value)} style={{width: 150}}/></label>}
            <SplitContainer>
                {!paid && <RadioGroup
                    // label="Signatura RGPD"
                  onChange={(v) => setS((v.target as any).value)}
                  selectedValue={s}
                >
                  <Radio label="Pagament en efectiu" value="cash"/>
                  <Radio label="Pagament amb targeta" value="card"/>
                </RadioGroup>}
                <ButtonContainer>
                    {s === 'cash' ? <Button onClick={() => setPaid(true)}
                                            icon={paid ? 'tick-circle' : null}>{paid ? `Cobrat ${amount || 150}€ en efectiu` : 'Cobrar'}</Button> :
                        <div>
                            {connected ? <div>
                                <Spinner/>
                                <div>Connectant amb datàfon <Button>Cancelar</Button></div>
                            </div> : (!accepted ? <Button onClick={() => {
                                setConnected(true);
                                setTimeout(() => {
                                    console.log(`EEEEEE`);
                                    setAccepted(true)
                                }, 3000)
                            }}>Connecta amb datàfon</Button> : <div>
                                <Icon icon={'tick-circle'} intent={"success"}/>
                                <div>Transacció processada correctament {`Cobrat ${amount || 150}€ en efectiu`}</div>
                            </div>)}
                        </div>}
                </ButtonContainer>
            </SplitContainer>

        </Container>
    </ProcessTabLayout>

})
