// May be organized into multiple files
// recep, fact, tech
export let featureMap = {
    f1: ['admin', 'tech'],
    f2: {
        admin: true,
        technician: {level: 50, f: (ur) => ur.level}
    },

    filesButton: ['admin', 'ref', 'bar', 'mut'],
    contrastAlerts: ['admin', 'recep'],

    // pages
    dailyCheckoutSheet: ['admin', 'recep', 'fact'],
    allVisitsPage: ['admin', 'recep', 'fact'],
    insCompanyInvoicing: ['admin', 'fact'],
    basicStatisticsPage: ['admin'], // Add fact when finished
    allInvoicesPage: ['admin', 'fact', 'recep'],
    manualInvoicingPage: ['admin', 'fact'],
    appointmentListPage: ['admin', 'recep', 'fact'],
    productFilePage: ['admin', 'bar'],
    resourcesPage: ['admin', 'bar'],
    ratesFilePage: ['admin', 'bar'],
    referrersPage: ['admin', 'ref'],
    extCenterFilePage: ['admin', 'ref'],
    contactsPage: ['admin', 'mut'],
    businessLinePage: ['admin'],
    orgFilePage: ['admin'],
    scheduleRulesPage: ['admin', 'cal'],
    usersPage: ['admin'],
    userLogEntriesPage: ['admin'],
    patientFusionPage: ['admin', 'recep'],
    editLockedInvoiceLines: ['admin', 'recep'],
    dangerousFeatures: ['admin'],
}
