import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSVVStore} from "../../../../StandardVisitView";
import * as _ from 'lodash'
import {Button, ButtonGroup, NonIdealState, Tag, Tooltip} from "@blueprintjs/core";
import moment from "moment";
import {SVHResourceHeader} from "./components/SVHResourceHeader";
import {formatFullName, isDev} from "../../../../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    background-color: #b2dfff;
    padding: 8px;
    border-radius: 4px 4px 0 0;
`
const TitleContainer = styled.div`
    font-size: 22px;
    display: grid;
    justify-content: start;
    align-items: end;
    grid-auto-flow: column;
    grid-gap: 4px;
    color: #343434;
`
const LeftContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
`
const UserNameContainer = styled.div`
    color: #137cbd;

`
const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: #BFCCD6;
    padding: 8px;
    grid-gap: 8px;
    border-radius: 4px 4px 0 0;

`
const TitleAreaContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 2px;
    font-size: 18px;
    
`
const MachineNameContainer = styled.div`
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`


const MachinesHeaderContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-right: -115px;
`

const ViewSwitcherContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
`

export interface SVHIdHeaderProps {

}

export const SVHIdHeader = observer((props: SVHIdHeaderProps) => {
    let store = useSVVStore()
    // console.log(`store.visitLinesSel.selFields`, store.visitLinesSel.selFields);
    let visitCount = store.visitsSel.selFields.length;
    let visit = store.getLocalVisit(store.singleSelVisitId())
    // if (visit == null) return <NonIdealState title={'Selecciona o crea una visita'} icon={'document'}/>
    let isDraft = visit?.isDraft;
    let isVisit = visit?.isVisit;

    let rightFlat = {borderBottomRightRadius: 0, borderTopRightRadius: 0};
    let leftFlat = {borderBottomLeftRadius: 0, borderTopLeftRadius: 0};
    let allFlat = {borderRadius: 0}
    let loggedInUser = store.appStore.auth.loggedInUser;

    return <HeaderContainer>
        {visitCount == 1 ? <TitleAreaContainer>
                <MachineNameContainer>
                    {isDraft ? 'T' : (isVisit ? 'V' : 'C')}{_.last(store.selectedVisitIds)}{' '}
                    {visit?.resource?.name}{' '}

                </MachineNameContainer>
                <SVHResourceHeader resource={null} visit={visit}/>

            </TitleAreaContainer>
            :
            (visitCount == 0 ? <TitleAreaContainer>
                        {/*{store.visitLinesSel.selFields.length}*/}
                        {/*V5*/}

                        {visitCount == 0 && <div>Cap visita seleccionada</div>}
                        {/*<div style={{fontSize: 22}}>*/}
                        {/*    {visitCount == 1 &&*/}
                        {/*    <div>{isDraft ? 'T' : 'V'}{_.last(store.selectedVisitIds)}</div>}</div>*/}
                        {/*<div>*/}
                        {/*    {visit?.resource?.name}*/}
                        {/*</div>*/}

                    </TitleAreaContainer> :
                    <MachinesHeaderContainer>
                        {/*{visitCount} visites*/}
                        {store.orderedSelectedResources().map(resource => {
                            // let resource = store.resourcesDS.items.find(res => res.id == resource?.id);
                            return <SVHResourceHeader resource={resource} visit={visit}/>
                        })}
                    </MachinesHeaderContainer>
            )
        }

        <LeftContainer>

            <ViewSwitcherContainer>
                {/*<Button minimal icon={store.isCalendarExpanded ? 'double-chevron-up' : 'double-chevron-down'}/>*/}
                <ButtonGroup minimal>
                    <Tooltip content={'Dades de la visita'}>
                        <Button
                            disabled={false} icon="document" style={rightFlat}
                            onClick={() => store.visitViewMode = "form"}
                            active={store.effectiveVisitViewMode === "form"}>
                            Fitxa visita
                        </Button>
                    </Tooltip>
                    <Button
                        disabled={visitCount == 0} icon="calendar" //style={leftFlat}
                        onClick={() => store.visitViewMode = "calendar"}
                        active={store.effectiveVisitViewMode === "calendar"}>
                        Cal. Màq.
                    </Button>
                    {isDev() && <Button
                        disabled={false} icon="calendar" style={leftFlat}
                        onClick={() => store.visitViewMode = "calendarScv"}
                        active={store.effectiveVisitViewMode === "calendarScv"}>
                        Tots calendaris
                    </Button>}
                </ButtonGroup>

            </ViewSwitcherContainer>
            <UserNameContainer>
                {store.appStore.auth.loggedInUser ? formatFullName(loggedInUser) : 'Convidat'}
            </UserNameContainer>
        </LeftContainer>
    </HeaderContainer>
})
