import {C3, IATSStyleApp, MRF} from "react-c4";
import MobxReactForm from "mobx-react-form";
import {AppStore} from "../../application/AppStore";


export class ProfilePageStore {
    form: MRF
    appStore: AppStore

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.form = new MobxReactForm({
            fields: [
                'name',
                'surname1',
                'surname2',
                'email',

            ],
            // initials,
            // defaults,
            // values,
            // labels,
            // placeholders,
            // rules,
            // extra,
            // hooks
        })
        this.fetchProfile()
    }

    userId: number

    async fetchProfile(){
        await this.appStore.auth.fetchMe()
        this.form.update(this.appStore.auth.loggedInUser)
    }

    async saveProfile(body) {
        await C3.instance.client.fetcher.fetch(`/auth/me`, {
            method: 'POST',
            body: body,
        })
    }

}
