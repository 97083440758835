import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRefFVStore} from "../ReferrerFileView";
import {Button, Popover} from "@blueprintjs/core";
import * as _ from 'lodash'
import {formatFullName} from "../../../application/utils/utils";

const Container = styled.div`
  display: grid;
  padding: 8px;
  place-content: center;
  grid-auto-flow: row;
  grid-gap: 8px;
  justify-items: center;
`
const ButtonContainer = styled.div`
  display: grid;
  padding: 8px;
  place-content: center;
  grid-auto-flow: column;
  grid-gap: 8px;
`
const PopoverContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding: 16px;
  grid-gap: 16px;

`

const PopoverButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-auto-flow: column;
`
const ErraseContainer = styled.span`
  color: #db3737;
  font-weight: bold;
`

export interface RefFVReferrerFormProps {

}

export const RefFVReferrerForm = observer((props: RefFVReferrerFormProps) => {
    let store = useRefFVStore()
    return <Container>
        <ButtonContainer>


            <Button
                icon={"tick"}
                intent={'success'}
                disabled={!store.canAcceptReferrer}
                onClick={() => {
                    store.acceptSelectedReferrers()
                }}
            >
                Acceptar com a nou
            </Button>
            <Popover
                isOpen={store.confirmMergeOpen}
                content={<PopoverContainer>
                    <div>
                        <div>
                            Fusionar {store.pendingReferrersSel.selectedIds.length} metges
                            ({_.sum(store.pendingReferrersSel.selectedItems.map(r => r.visitCount))} visites)
                            amb {formatFullName(store.reviewedReferrersSel.selectedItem)}
                        </div>
                        <div>
                            Alerta: Aquesta acció és irreversible
                        </div>
                    </div>
                    <PopoverButtonsContainer>
                        <Button icon={'cross'} onClick={() => {
                            store.confirmMergeOpen = false
                        }}>Cancela</Button>
                        <Button icon={'git-merge'} intent={'warning'} onClick={async () => {
                            store.mergeSelectedReferrers()
                            store.confirmMergeOpen = false

                        }}>Fusiona refereridors</Button>
                    </PopoverButtonsContainer>
                </PopoverContainer>}
            >
                <Button
                    icon={'git-merge'}
                    intent={'none'}
                    disabled={!store.canMergeReferrer}
                    onClick={() => {
                        store.confirmMergeOpen = true
                    }}
                >
                    Fusionar amb existent
                </Button>
            </Popover>
            <Button
                icon={'undo'}
                intent={'none'}
                disabled={!store.canMarkAsPendingReview}
                onClick={() => {
                    store.markSelectedAsPending()
                }}
            >
                Marcar com a pendents
            </Button>
        </ButtonContainer>
        <ErraseContainer>Per esborrar Referidors han d'estar a l'apartat de pendents i s'han de seleccionar d'un en un.</ErraseContainer>
    </Container>
})
