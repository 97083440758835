import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useSVVStore} from "../../StandardVisitView";
import {Button, Callout} from "@blueprintjs/core";
import {documentTypesMap} from "../../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
`

export interface SVVVisitFooterDocumentsButtonsProps {
    visit
}

export const SVVVisitFooterDocumentsButtons = observer((props: SVVVisitFooterDocumentsButtonsProps) => {

    let store = useSVVStore();
    let visit = props.visit;

    if (!visit?.id) return null

    return <Container>
        {visit?.documents  ? visit.documents.map(d => {
            return <Button key={d.id} icon={'document'}>{d.type || d.description || 'Doc'}</Button>;
        }) :
            <Callout style={{transform: 'scale(0.8)', margin: '-4.5px', marginBottom: '-16px', marginLeft: -20, width: 'fit-content'}} intent={'warning'} icon={'warning-sign'} title={`${visit?.id} no té cap document`}/>
        }
        {store.visitLinesSel.selFields.map(vlf => {
            if(!vlf.has('documents')  || !vlf.$('documents').value) return null
            return vlf.$('documents').value.map(d => {
                return <Button key={d.id} minimal icon={'document'}>{d.type || d.description || 'Doc'}</Button>;
            })
        })}

    </Container>
})
