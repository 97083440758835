import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {PrivateInvoice} from "../invoice-variants/PrivateInvoice";

const Container = styled.div`
    display: grid;
    
`

export interface PrivateInvoiceRouteProps {
    match: { params: { id } }
}

export const PrivateInvoiceRoute = observer((props: PrivateInvoiceRouteProps) => {
    let id = props.match.params.id;
    return <PrivateInvoice
        // data={{
        //     lines: [
        //         {text: 'Prova 1', price: 55, quantity: 1, date: new Date(), unitPrice: 55},
        //         {text: 'Prova 2', price: 15, quantity: 1, date: new Date(), unitPrice: 15},
        //     ],
        //     total: 70
        // } as any}
    />;
})
