import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Button, Tag} from "@blueprintjs/core";
import {SCVStore} from "../../../components/ScheduleView/SCVStore";

const Container = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-auto-flow: column;
`

export interface SchedulePageHeaderAcceptTmpProps {
    store: SCVStore
}

export const SchedulePageHeaderAcceptTmp = observer((props: SchedulePageHeaderAcceptTmpProps) => {
    let store = props.store;
    let tmpEvents = store.tmpEvents;
    if(tmpEvents.length == 0) return null
    return <Container>
        {/*<Tag large intent={'none'}>{tmpEvents.length} noves cites</Tag>*/}
        <Button intent={'primary'} icon={'tick'} onClick={(e) => store.acceptTmpEvents(e)}>Crea {tmpEvents.length} cites</Button>
        <Button icon={'cross'} onClick={() => store.clearTmpEvents()}/>
    </Container>
})
