import {C3, C3Selection, IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {observable, autorun} from "mobx";
import {BusinessLineFVMainProps} from "./BusinessLineFVMain";

export class BusinessLineFVStore {
    appStore: AppStore
    pageContext

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.businessLineSel = new C3Selection({
            items: this.businessLineRes,
        })
        autorun
        await this.fetch()
    }

    @observable.shallow
    businessLineRes: any[]

    businessLineSel: C3Selection

    async fetch() {
        this.businessLineRes = await C3.instance.client.fetcher.fetch('/business-line', {})
        this.businessLineSel.itemsRef.current = this.businessLineRes;
        return this.businessLineRes
    }

    async createBusinessLine() {
        await C3.instance.client.fetcher.fetch('/business-line/maintenance', {
            method: 'POST',
            body: {name: 'nou element', isActive: true}
        })
        await this.fetch()
    }
    async deleteBusinessLine(businessLineId) {
        await C3.instance.client.fetcher.fetch(`/business-line/maintenance/${businessLineId}`, {
            method: 'DELETE',
        })
        await this.fetch()
    }
    async deleteSelectedBusinessLine() {
        let selectedId = this.businessLineSel.selectedId;
        if (selectedId == null) return
        await this.deleteBusinessLine(selectedId)
    }
    async saveBusinessLine(businessLine) {
        await C3.instance.client.fetcher.fetch(`/business-line/maintenance`, {
            method: 'PATCH',
            body: businessLine
        })
    }
}
