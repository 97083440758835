import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Tooltip} from "@blueprintjs/core";
import chroma from 'chroma-js'
import * as _ from 'lodash'
import {colors} from "../../../../../../application/utils/colors";
const Container = styled.div`
    display: grid;
    //border-top: 2px solid #008000;
    //border: 2px solid transparent;
    background-color: #e0ffe0;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    padding-top: 2px;
`

export interface SVHTasksBarTaskIndicatorProps {
    inidicator: {
        status: 'ok' | 'warn' | 'off' | 'disabled',
        text: string
        tooltip: string
    }
}

export const SVHTasksBarTaskIndicator = observer((props: SVHTasksBarTaskIndicatorProps) => {
    let color = [colors.error, colors.success, colors.warn][0];
    // let chroma3 = chroma(color);
    // let chroma1 = chroma({h: chroma3.get('hsv.h'), s: 1, v: 0.6});
    // let chroma2 = chroma({h: chroma1.get('hsv.h'), s: 0.12, v: 1});
    let ind = props.inidicator
    return <Tooltip content={ind.tooltip} >
        <Container style={{
            borderTop: `2.5px solid ${color.main}`,
            backgroundColor: color.background}}>
            {ind.text || '--'}
        </Container>
    </Tooltip>
})
