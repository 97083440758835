import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {PatientOverviewStore} from "../../PatientOverviewStore";
import {Tab, Tabs} from "@blueprintjs/core";
import {OverviewVisitsTab} from "./tabs/OverviewVisitsTab";
import {RGPD} from "./process-tabs/RGPD";
import {ContrastTab} from "./process-tabs/ContrastTab";
import {FitxaBlanca} from "./process-tabs/FitxaBlanca";
import {EntregaTab} from "./process-tabs/EntregaTab";
import {AuthorizationTab} from "./process-tabs/AuthorizationTab";
import {RequestTab} from "./process-tabs/RequestTab";
import {JustificantTab} from "./process-tabs/JustificantTab";
import {PaymentTab} from "./process-tabs/PaymentTab";
import {InsCompaniesTab} from "./tabs/InsCompaniesTab";
import {OverviewAppointmentsTab} from "./tabs/OverviewAppointmentsTab";
import {AdditionalFieldsTab} from "./tabs/AdditionalFieldsTab";

const Container = styled.div`
    display: grid;
    height: 100%;
`

export interface TabsAreaProps {
    store: PatientOverviewStore
    pageContext
}

export const TabsArea = observer((props: TabsAreaProps) => {
    let tabs = {
        rgpd: <RGPD store={props.store}/>,
        contrast: <ContrastTab store={props.store}/>,
        fitxaBlanca: <FitxaBlanca store={props.store}/>,
        entrega: <EntregaTab store={props.store}/>,
        authorization: <AuthorizationTab store={props.store}/>,
        request: <RequestTab store={props.store}/>,
        payment: <PaymentTab store={props.store}/>,
        justificant: <JustificantTab store={props.store}/>
    }
    let tabNames = {
        rgpd: 'RGPD',
        contrast: 'Contrast',
        fitxaBlanca: 'Fitxa blanca',
        entrega: 'Entrega',
        authorization: 'Autorització',
        request: 'Petició',
        payment: 'Pagament',
        justificant: 'Justificant'
    }
    // if(props.store?.md?.form == null) return null
    return <Container>
        <Tabs id="TabsExample" renderActiveTabPanelOnly={true} onChange={(id) => {
            props.store.selectedTab = id
        }} selectedTabId={props.store.selectedTab}>
            <Tab id="tests" title={`Cites`} panel={<OverviewAppointmentsTab pageContext={props.pageContext} store={props.store}/>}/>
            <Tab id="visits" title="Visites" panel={<OverviewVisitsTab  pageContext={props.pageContext} store={props.store}/>}/>
            {/*<Tab id="emailSms" title="Email / SMS" panel={<OverviewMessagesTab store={props.store}/>} panelClassName="ember-panel"/>*/}
            {/*<Tab id="documents" title="Documents" panel={<AttachmentsTab store={props.store}/>}/>*/}
            <Tab id="insCompanies" title="Mutues" panel={<InsCompaniesTab store={props.store}/>}/>
            <Tab id="additionalFields" title="Altres" panel={<AdditionalFieldsTab store={props.store}/>}/>
            <Tab id="modal" title={tabNames?.[props.store.tab]} panel={tabs?.[props.store.tab] || <div/>}/>
            <Tabs.Expander/>
            {/*<input className="bp3-input" type="text" placeholder="Cerca..."/>*/}
        </Tabs>
    </Container>
})
