import {C3, C3MasterDetail, C3Selection, C3DataSource, IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {action, autorun, computed, observable, toJS} from "mobx";
import {GridApi} from "ag-grid-community";
import moment from "moment";
import MobxReactForm from "mobx-react-form";
import * as _ from "lodash";

export class ALVStore {
    appStore: AppStore
    pageContext

    filterForm: MRF

    @observable
    res: any

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection

    @observable
    statsRes: any = {
        dayTotals: []
    }

    sel: C3Selection
    gridApi: GridApi

    typeSel: C3Selection

    chartSel: C3Selection

    @observable
    onlyPatientId


    md: C3MasterDetail

    machinesData: C3DataSource
    patientsData: C3DataSource
    testTypesData: C3DataSource

    @computed
    get totalResources() {
        let resources = this.appStore.resources || [];
        return resources.map(r => {
            return {
                id: r.id,
                title: r.name,
            }
        })
    }

    async init() {
        this.appStore = IATSStyleApp.instance.appStore

        this.sel = new C3Selection({})
        this.orgsRes = this.appStore.orgs || [];
        this.typeSel = new C3Selection({
            items: [
                {id: 'all', name: 'Tots'},
                {id: 'chipcard', name: 'Chipcard'},
                {id: 'manual', name: 'Manual'},
                {id: 'unassigned', name: 'No Assignat'},
            ],
            onSelectionChanged: () => {
                this.filterForm.$('type').set(this.typeSel.selectedItem.id)
            },
        })
        this.typeSel.selectedIds = ['all']
        this.orgsSel = new C3Selection(({
            items: this.orgsRes,
            onSelectionChanged: () => {
                this.filterForm.$('orgs').set(this.orgsSel.selectedItem)
            }
        }))
        this.filterForm = new MobxReactForm({
            fields: [
                'startDate',
                'endDate',
                'orgs',
                'type',
                'resource',
            ],
            // initials,
            // defaults,
            values: {
                startDate: new Date(),
                endDate: new Date(),
                type: 'all',
                orgs: [],
                resource: [],

            },
            // labels,
            // placeholders,
            // rules,
            // extra,
            hooks: {}

        })

        this.machinesData = new C3DataSource({
            entityType: 'machine',
            autoFetch: true
        })

        this.testTypesData = new C3DataSource({
            entityType: 'test-type',
            autoFetch: true
        })
        this.machinesData.fetch()
        this.testTypesData.fetch()
        await this.fetch()

        autorun(() => {
            let resourceListID = this.appStore.resourceGroupSel.selectedIds;
            this.fetch();
            this.fetchStats()
        })

        autorun(() => {
            let startDate = this.filterForm.$('startDate').value;
            let endDate = this.filterForm.$('endDate').value;
            let type = this.filterForm.$('type').value;
            this.fetch();
            this.fetchStats()
        }, {delay: 5})
        await this.fetchStats()

    }


    async fetch() {
        let startDate = moment(this.filterForm.$('startDate').value).toDate();
        let endDate = moment(this.filterForm.$('endDate').value || startDate).toDate();
        let orgId = this.orgsSel.selectedId;
        let res = await C3.instance.client.fetcher.fetch(`/schedule-view/calendar-events`, {
            method: 'GET',
            query: [`start=${startDate.toISOString()}`, `end=${endDate.toISOString()}`, `type=${this.filterForm.$('type').value}`, `showBlocks=${false}`, orgId && `orgs=${orgId}`].filter(a => a).join('&'),
        });
        res = res.map((value) => {
            let resource = toJS(this.appStore.resources).filter(r => r.id == value.resourceId)[0];
            value.resourceName = resource.name;
            if (value.tel) value.tel = value.tel.join('-');
            let paymentList = value.lines.filter((line) => {
                return !!line.payment == true
            })
            if (value.lines) value.paymentMethod = paymentList.map((line) => {
                if (line.payment == 'insCompany') {
                    line = 'Mútua'
                    return line
                } else if (line.payment == 'private') {
                    line = 'Privat'
                    return line
                }
            })
            value.paymentMethod = value.paymentMethod.join('-')
            return value
        })
        this.res = res;
        this.sel.itemsRef.current = res

    }


    async fetchStats() {
        let startDate = moment(this.filterForm.$('startDate').value).toDate();
        let endDate = moment(this.filterForm.$('endDate').value || startDate).toDate();
        let res = await C3.instance.client.fetcher.fetch(`/schedule-view/calendar-events`, {
            method: 'GET',
            query: [`start=${startDate.toISOString()}`, `end=${endDate.toISOString()}`, `type=${this.filterForm.$('type').value}`, `showBlocks=${false}`].join('&'),
        });
        this.statsRes = res;
        // this.sel.itemsRef.current = res
    }

    @action
    async moveRange(days: number) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');

        [$startDate, $endDate].forEach($d => $d.onChange(moment($d.value).add(days, "days").toDate()))

    }

    @action
    async goToDate(date: Date) {
        let $startDate = this.filterForm.$('startDate');
        let $endDate = this.filterForm.$('endDate');
        [$startDate, $endDate].forEach($d => $d.onChange(moment(date).toDate()))
    }

    printHandle

    @action
    async print() {
        if (!this.printHandle) return
        this.gridApi.setDomLayout('print')
        setTimeout(async () => {
            await this.printHandle()
            this.gridApi.setDomLayout(null)
        })
    }
}
