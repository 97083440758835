import {isAdmin} from "../../utils/utils";
import {AppStore} from "../../AppStore";
import {AuthStore} from "../AuthStore";
import * as _ from 'lodash'
import {observable, toJS} from "mobx";
import {FeatureDecisions} from "./FeatureDecisions";
import {featureMap} from "./featureMap";

export class FeatureFilter {
    appStore: AppStore
    authStore: AuthStore

    dec: FeatureDecisions

    constructor(authStore: AuthStore) {
        window['ff'] = this
        this.authStore = authStore
        this.appStore = this.authStore.appStore
        this.dec = new FeatureDecisions(this)
        this.featuresForUser = JSON.parse(window.sessionStorage.getItem('feat-for-user')) || {}
    }


    @observable.shallow
    featuresForUser = {}

    onUpdateUser(){
        this.processFeatureMapForUser()

    }
    processFeatureMapForUser() {
        if(!this.authStore.loggedInUser) return
        let userRoles = this.authStore.loggedInUser?.roles;
        let processFeatMap = () => {
            return _.mapValues(featureMap, f => {
                let featRes = {}
                if (_.isArray(f)) {
                    f.forEach(r => {
                        featRes[r] = true
                    })
                } else if (_.isObject(f)) {
                    featRes = f
                }
                return featRes
            })
        }
        let processedFeatMap = processFeatMap()

        let result = {};
        // ['admin', 'technician']
        Object.entries(processedFeatMap).map(([featK, featV]) => {
            // For each feature
            let allowedRoles = _.keys(featV)
            let userRolesKeys = _.keys(userRoles)
            let matchingRoles = _.intersection(allowedRoles, userRolesKeys);
            // Additional filters of matching roles

            if(matchingRoles.length >= 1){
                result[featK] = matchingRoles.map(k => toJS(userRoles[k]))
            }
        })
        this.featuresForUser = result
        // console.log(`this.featuresForUser`, toJS(this.featuresForUser));
        window['featuresForUser'] = toJS(this.featuresForUser)
        window.sessionStorage.setItem('feat-for-user', JSON.stringify(this.featuresForUser))
    }

    hasFeature(featureKey, fullRoles = false) {
        // Look for standalone rbac
        // if (isAdmin()) return true
        let featForUser = this.featuresForUser[featureKey];
        if (featForUser) {
            return fullRoles ? featForUser : featForUser?.[0]
        }
        return false
    }
}
