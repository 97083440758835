import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {AllDeltasView} from "../../components/AllDeltasView/AllDeltasView";
import {ADVStore} from "../../components/AllDeltasView/ADVStore";
import {AllDeltasPageHeader} from "./MeetupEventsPageHeader";

const Container = styled.div`
  display: grid;
  height: 100%;

`

export interface MeetupEventsPageProps {

}

export const AllDeltasPage = page(observer((props: MeetupEventsPageProps) => {
    let [store, setStore] = useState(null as ADVStore);

    return <Page
        title={'Event deltas'}

        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <AllDeltasPageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <AllDeltasView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
