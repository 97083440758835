import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useAppStore} from "../../../../../utils/utils";
import {TAContrastAlertsTabItem} from "./TAContrastAlertsTabItem";
import {useInterval, useUpdate} from "react-use";

const Container = styled.div`
    display: grid;
    > div:nth-of-type(odd){
      background-color: #e1e8ed;
    }
    grid-gap: 4px;
    align-content: start;
`

export interface TAContrastAlertsTabProps {

}
export const TAContrastAlertsTab = observer((props: TAContrastAlertsTabProps) => {
    let appStore = useAppStore()
    return <Container>
        {appStore.contrastAlertsRes.map(ca => {
            return <TAContrastAlertsTabItem key={ca.visitId} item={ca}/>
        })}
    </Container>
})
