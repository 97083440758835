import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import ReactDOM from "react-dom";
import {ICellEditorParams} from "ag-grid-community";
import {createPopper, Instance as PopperInstance} from "@popperjs/core";
import {DatePicker} from "@blueprintjs/datetime";
import moment from "moment";
import {getMomentFormatter} from "react-c4";
import MomentLocaleUtils from "react-day-picker/moment";
import {ErrorBoundary} from "@sentry/react";
import * as _ from 'lodash'

const Container = styled.div`
  display: grid;

`


export const DateTimeCE = observer(forwardRef((props: ICellEditorParams, ref) => {
    const [contextElement, setContextElement] = useState(document.body as HTMLElement);


    const [popper, setPopper] = useState(null as PopperInstance);
    const referenceElementRef = useRef();
    const popperElementRef = useRef();

    const [editing, setEditing] = useState(true);
    const refContainer = useRef(null);

    useLayoutEffect(() => {
        const popper: PopperInstance = createPopper(referenceElementRef.current, popperElementRef.current, {
            placement: "auto-end",
            modifiers: [
                {
                    name: 'flip',
                    enabled: true,
                },

            ],
            onFirstUpdate: () => {

            }
        });

        setPopper(popper)
        focus();
    }, []);

    const keyDownEvent = (event) => {
        // console.log(`event.keyCode`, event.keyCode);
        if ([37, 39].indexOf(event.keyCode) > -1) { // left and right
            // setHappy(!happy);
            // Update search focus
            event.stopPropagation();
        }
        if ([27].indexOf(event.keyCode) > -1) { // left and right
            // setHappy(!happy);
            props.api.stopEditing(true)
            event.stopPropagation();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', keyDownEvent);

        return () => {
            window.removeEventListener('keydown', keyDownEvent);
        };
    }, [keyDownEvent]);
    // let defaultValue = moment(new Date()).subtract(40, 'years').toDate();
    let cellEditorParams = props.colDef?.cellEditorParams

    if (_.isFunction(cellEditorParams)) cellEditorParams = cellEditorParams(props)
    let [state, setState] = useState(props.value)
    console.log(`state`, state);
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return state;
            },

            isPopup() {
                return true;
            },

            init(params: ICellEditorParams) {

            }

        }
    });

    useEffect(() => {
        if (!editing) {
            props.api.stopEditing(false)

        }
    }, [editing]);

    const focus = () => {
        window.setTimeout(() => {
            let container: any = ReactDOM.findDOMNode(popperElementRef.current);
            if (container) {
                container.focus();
            }
        })
    };


    // let rscStore = store.getRSCStore();

    useEffect(() => {
        // if (rscStore) {
        //     rscStore.initForVisitLine(props.data.id, props)
        // }
        console.log(`props.data.id`, props.data.id);
    }, [props.data.id])


    let momentFormatter = getMomentFormatter('DD/MM/YYYY');
    return (
        <>
            <div style={{
                // height: 25
                backgroundColor: 'transparent'
            }} ref={referenceElementRef}>

            </div>

            {ReactDOM.createPortal(
                <div

                    ref={popperElementRef}
                    style={{
                        ...popper?.state?.styles,
                        zIndex: 100
                    }}
                    {...(popper?.state?.attributes)}
                >
                    <ErrorBoundary>
                        <DatePicker
                            locale={'ca'} todayButtonText={'Avui'} canClearSelection={true}
                            minDate={moment().subtract(120, 'years').toDate()}
                            maxDate={moment().add(120, 'years').toDate()}

                            {...momentFormatter}
                            onChange={(v) => setState(v)}

                            value={state ? moment(state).toDate() : new Date()}
                            highlightCurrentDay
                            localeUtils={MomentLocaleUtils}
                        />
                    </ErrorBoundary>
                    {/*<RSCMain store={rscStore} params={props.value}/>*/}
                </div>,
                document.body
            )}

        </>
    );
}))
