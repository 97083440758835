import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {SVVLinesGrid} from "./SVVLinesGrid/SVVLinesGrid";
import {SVVLinesDetail} from "./_deprecated/SVVLinesDetail/SVVLinesDetail";
import {SVVLinesVisitDetail} from "./SVVLinesVisitDetail/SVVLinesVisitDetail";
import {LayoutBoundary, MasterDetailLayout} from "react-c4";
import {useSVVStore} from "../../StandardVisitView";
import {Spinner} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    grid-gap: 8px;
    
`
const LinesMDContainer = styled.div`
//position: absolute;
    display: grid;
    grid-template-columns: minmax(50%, 8fr) 3fr;
    min-height: 310px;
    grid-gap: 8px;
`


export interface SVVVisitLinesAreaProps {

}

export const SVVLines = observer((props: SVVVisitLinesAreaProps) => {
    let store = useSVVStore()
    return <LayoutBoundary>
        <SVVLinesGrid/>
    </LayoutBoundary>
    //
    // return <LinesMDContainer
    //     style={{gridTemplateColumns: store.mainGridCollapsed ? `${store.mainGridCollapsed ? 4 : 9}fr 4fr` : '1fr'}}
    //     // style={{gridTemplateColumns: `${store.mainGridCollapsed ? 50 : 70}px 30px`}}
    // >
    //     <SVVLinesGrid/>
    //     {store.mainGridCollapsed && <SVVLinesDetail/>}
    // </LinesMDContainer>
})
