import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {OrgFVStore} from "./OrgFVStore";
import {useC3Store} from "react-c4";
import {OrgFVMain} from "./OrgFVMain";
import {OrgFVMainInvoiceCounter} from "./OrgFVMainInvoiceCounter";

const Container = styled.div`
  display: grid;
  height: calc(100vh - 100px);
  padding: 8px;
  grid-gap: 8px;
    
`


export const OrgFVContext = React.createContext(null as OrgFVStore)
export const useOrgFVStore = () => useContext(OrgFVContext)

export interface OrgFVProps {
    pageContext?
    onStoreReady: (s: OrgFVStore) => any
}

export const OrgFileView = observer((props: OrgFVProps) => {
    let store = useC3Store(() => {
        let storeInst = new OrgFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <OrgFVContext.Provider value={store}>
        <Container>
            <OrgFVMain pageContext={props.pageContext}/>
        </Container>
    </OrgFVContext.Provider>
})
