import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {DeltasGrid} from "./components/DeltasGrid";

const Container = styled.div`
    display: grid;
  padding: 8px;
    
`

export interface ADVMainProps {
    pageContext
}

export const ADVMain = observer((props: ADVMainProps) => {
    return <Container>
        <DeltasGrid/>
    </Container>
})