import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const resourceTypeSchema: C3SchemaInfo = {
    'resource-type': {
        fields: {
            id: {},
            name: {
                label: 'Nom'
            },
            color: { name: 'Color'},
            resources: {
                type: 'resource',
                multivalued: true
            }
        }
    }
}
