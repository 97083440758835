import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {FilesInfo, TypeGroupInfo} from "../../../application/utils/filesInfo";
import * as _ from 'lodash'
import {FilePageIcon} from "./FilePageIcon";
import {Icon} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    align-content: start;
    border: 1px solid gray;
    
`
const HeaderContainer = styled.div`
    display: grid;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid gray;
    height: 30px;
    padding-left: 16px;
    grid-template-columns: auto auto ;
    grid-gap: 8px;
`
const TitleContainer = styled.div`
    font-weight: bold;
    font-size: 14px;
   
`
const BodyContainer = styled.div`
    display: grid;
    place-items: start;
    place-content: start;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(auto, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(auto, 1fr));
    padding: 12px;
    grid-gap: 12px;
`
const PrimaryFilesContainer = styled.div`
    display: grid;
    grid-gap: 10px;
`
const SecondaryFilesContainer = styled.div`
    display: grid;
    grid-gap: 8px;
`

export interface FilePageGroupContainerProps {
    group: TypeGroupInfo
    navigate: Function
}

export const FilePageGroupContainer = observer((props: FilePageGroupContainerProps) => {
    let group = props.group;
    let getFileElements = (primary) => {
        let files = group.files[primary ? 'primary' : 'secondary'];
        return _.keys(files).filter(k => files[k]).map(k => {
            let fileInfo = files[k];
            return <FilePageIcon key={k} primary={primary} name={fileInfo.name} icon={fileInfo.icon}
                                 onClick={() => props.navigate(fileInfo)}/>
        });
    };
    return <Container>
        <HeaderContainer>
            <Icon icon={group.icon}/>
            <TitleContainer>
                {group.name}
            </TitleContainer>
        </HeaderContainer>
        <BodyContainer>
            <PrimaryFilesContainer>
                {getFileElements(true)}
            </PrimaryFilesContainer>
            <SecondaryFilesContainer>
                {getFileElements(false)}
            </SecondaryFilesContainer>
        </BodyContainer>
    </Container>
})
