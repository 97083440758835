import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ProdFVStore} from "./ProdFVStore";
import {useC3Store} from "react-c4";
import {ProdFVMain} from "./ProdFVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const ProdFVContext = React.createContext(null as ProdFVStore)
export const useProdFVStore = () => useContext(ProdFVContext)

export interface ProdFVProps {
    pageContext?: any
    onStoreReady: (s: ProdFVStore) => any
}

export const ProdFV = observer((props: ProdFVProps) => {
    let store = useC3Store(() => {
        let storeInst = new ProdFVStore(props.pageContext.args)
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <ProdFVContext.Provider value={store}>
        <Container>
            <ProdFVMain pageContext={props.pageContext}/>
        </Container>
    </ProdFVContext.Provider>
})
