import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const orgSchema: C3SchemaInfo = {
    'organization': {
        fields: {
            id: {},
            name: {
                label: 'Nom'
            },
            shortName: {
                label: 'Nom curt'
            },
            initials: {
                label: 'Inicials'
            },
            cif: {
                label: 'CIF'
            },
            website: {
                label: 'Web'
            },
            mobile: {},
            phone2: {},
            email: {},
            address: {},
            facilities: {
                type: 'facility',
                multivalued: true
            },
            // TODO Add rels
        }
    }
}
