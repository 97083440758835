import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {usePFVStore} from "../PatientFusionView";
import {PFVMergeGrid} from './Components/PFVMergeGrid'
import {PFVFinalGrid} from './Components/PFVFinalGrid'
import {PFVPatientForm} from './Components/PFVPatientForm'
import {PatientOverview} from "../../PatientOverview/PatientOverview";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 5px
`
const LeftContainer = styled.div`
    display: grid;
  grid-template-rows: 5fr 1fr;
`

export interface PFVMainProps {
    pageContext
    viewConfig
}

export const PFVMain = observer((props: PFVMainProps) => {
    let store = usePFVStore()
    return <Container>
        <LeftContainer>
            <PFVMergeGrid/>
            <PFVPatientForm/>
        </LeftContainer>
        <PFVFinalGrid/>
        <PatientOverview pageContext={props.pageContext} featureMode={props.viewConfig} form={store.patientForm}/>
    </Container>
})
