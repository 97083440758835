export let colors = {
    success: {
        main: '#008000',
        background: '#e0ffe0'
    },
    warn: {
        main: '#e8ba00',
        background: '#fff6c4'
    },
    error: {
        main: '#c40000',
        background: '#ffdbdb'
    },
    chromeRed: '#de5246'
}
