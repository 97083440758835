import {C3Form, C3MasterDetail, C3DataSource, C3, C3Selection} from "react-c4";
import {autorun, observable, action, computed} from "mobx";
import {DateClickArg} from "@fullcalendar/interaction";
import moment from "moment";

let featureCols = ['booking', 'technician', 'reception']
let featureMap = {
    // ['booking', 'technician', 'reception']
    testsInfo: [0, 1, 1],
    // Buttons in testsInfo
    fitxaBlanca: [0, 1, 1],
    authorization: [0, 1, 1],
    petition: [0, 1, 1],
    payment: [0, 1, 1],
    contrast: [0, 1, 1],
    justificant: [0, 1, 1],
    delivery: [0, 1, 1],
    bookingShortcut: [0, 0, 1]

    // Patient info fields


}

export type PatientOverviewFeature = keyof typeof featureMap;

export class PatientOverviewStore {
    form: C3Form
    md: C3MasterDetail

    @observable
    selectedTab: any = 'tests'

    oldTab = 'tests'
    testsMD: C3MasterDetail

    visitsMD: C3MasterDetail

    @observable
    tab: 'rgpd' | 'contrast' = null

    @observable
    basicInfoCollapsed = false

    @observable
    testsCollapsed = false

    attachmentsMD = new C3MasterDetail({
        entityType: 'attachment',
        store: {}
    })

    @observable
    insCompanies: any[]

    patientInsCompaniesMD: C3MasterDetail
    patientInsCompaniesSel: C3Selection

    @observable
    patientInsCompanies: { id, mutuaNumber, default: boolean, insCompany: { id, name } }[] = []

    @observable
    visitsList: any
    @observable
    appointmentsList: any


    @observable
    patientInsCompaniesCreating: boolean = false

    // @observable
    // patientPaymentMethods: any[]

    featureMode: number

    @observable
    viewingDocument: boolean = false

    @observable.shallow
    externalStore: any

    onSavePatient: (patient) => any


    constructor(md: C3MasterDetail, form: C3Form, featureMode, externalStore?) {
        this.md = md;
        this.form = form || md?.form;
        this.featureMode = featureCols.indexOf(featureMode)
        if (!this.form.isCreating) this.form.isLocked = true
        this.externalStore = externalStore

    }

    selectAdditionalTab(tabName) {
        this.tab = tabName
        this.selectedTab = 'modal'
        if (this.selectedTab != 'modal') this.oldTab = this.selectedTab
    }

    @action
    closeAdditionalTab() {
        this.tab = null
        this.selectedTab = this.oldTab
    }

    init() {
        window['pstore'] = this
        C3.instance.client.fetcher.fetch('/patient-overview/ins-companies-options', {})
            .then(r => this.insCompanies = r)

        if (this.form == null) {
            // Create form
        }
        this.form.transform = {
            ...this.form.transform,
            to(v) {
                return {
                    ...v,
                    ...(v.language && {language: v.language.id}),
                    ...(v.sex && {sex: v.sex.id}),
                }

            },
            from(v) {
                return {
                    ...v,
                    // ...(v.language && {language: {id: v.language.id, name: 'aAA'}})
                }
            }
        }
        this.form.options = {
            ...this.form.options,
            mutua: new C3DataSource({entityType: 'mutua'}),
        }
        this.testsMD = new C3MasterDetail({
            entityType: 'test-type',
            store: this,

        })
        // this.testsMD.fetchItems()
        this.visitsMD = new C3MasterDetail({
            entityType: 'visit',
            dataSourceOpts: {
                updater: (ds) => autorun(() => {
                    ds.idFilters = {
                        patient: this.form.entityId
                    }
                    // if(this.md) this.md.fetchItems()
                })
            },
            formOptions: {
                options: {
                    // mutua: true,
                    // referral: true,
                    // externalCenter: true,
                }
            }
        })
        // this.visitsMD.fetchItems();

        this.patientInsCompaniesSel = new C3Selection({
            itemsRef: {current: this.patientInsCompanies}
        })

        let fetchInitials = () => {
            this.fetchPatientInsCompanies()
            this.fetchAppointmentsList()
            this.fetchVisitsList()
            // if (this.form.entityId) C3.instance.client.fetcher.fetch(`/patient/${this.form.entityId}/payment-methods`, {})
            //     .then(r => this.patientPaymentMethods = r)
        }
        if (this.md) {
            autorun(() => {
                this.form.entityId = (this.md.selection.selectedId)
                fetchInitials()
            })
        } else {
            fetchInitials()
        }
        // this.patientInsCompaniesMD = new C3MasterDetail({
        //     entityType: 'ins-company',
        //     dataSourceOpts: {
        //         updater: (ds) => autorun(() => {
        //             ds.idFilters = {
        //                 patient: this.md.selection.selectedId
        //             }
        //             this.md.fetchItems()
        //         })
        //     },
        // })
        // this.patientInsCompaniesMD.fetchItems()


    }

    async fetchPatientInsCompanies() {
        await this.fetch('/patient/ins-companies').then((r) => {
            this.patientInsCompaniesSel.itemsRef.current = [...(r || []), {id: -1}]
        })
    }

    fetchVisitsList() {
        this.fetch('/patient-overview/visits-list').then((r) => {
            this.visitsList = r
        })
    }

    fetchAppointmentsList() {
        this.fetch('/patient-overview/appointments-list').then((r) => {
            this.appointmentsList = r
        })
    }

    async fetch(endpoint = '', body = null, method = 'GET') {
        if (this.form.entityId == null) return null;
        return await C3.instance.client.fetcher.fetch(endpoint, {
            method: method,
            query: `patientId=${this.form.entityId}`,
            body
        })
    }

    async patch(endpoint, body, refetch = true) {
        // if(refetch) this.svvInvalidated = true
        let res = await this.fetch(endpoint, body, 'PATCH');
        // if (refetch) await this.refetch()

        return res
    }


    async savePatientInsCompany(object) {
        if (object.id == null) {
            await this.fetch('/patient/ins-companies', object, 'POST');
        } else {
            await this.fetch(`/patient/ins-companies/${object.id}`, object, 'PATCH');
        }
        await this.fetchPatientInsCompanies()
    }

    async savePatient(body) {
        let res = await this.fetch(`/patient/${body.id}`, body, 'PATCH');
        if (this.onSavePatient) this.onSavePatient(res)
    }

    async removePatientInsCompany(id) {
        await this.fetch(`/patient/ins-companies/${id}`, {}, 'DELETE');
        await this.fetchPatientInsCompanies()
    }

    @computed
    get patientValid() {
        let fullRequired = false
        if(this.externalStore?.isSelectedAppointmentToday){
            fullRequired = true
        }
        let basicCheck = this.form.$('name').value?.length > 1
            && this.form.$('surname1').value?.length > 1
            && (this.form.$('mobile').value?.length > 1 || this.form.$('phone2').value?.length > 1);
        if(fullRequired)
            basicCheck = basicCheck && moment(this.form.$('birthDate').value).isValid()
                && ['M', 'F'].includes(this.form.$('sex').value)
        return basicCheck

    }

    hasFeature(feature: PatientOverviewFeature) {
        return !!featureMap[feature][this.featureMode]
    }

    // [wkl] worklist
    async sendPatientDataToTelera(patientId) {
        let res = await C3.instance.client.fetcher.fetch(`/telera/update-patient/${patientId}`, {})
        console.log(res);
    }
}
