import React, {useEffect, useRef} from "react";
import styled from 'styled-components'
import {observer} from "mobx-react";
import {useReactToPrint} from "react-to-print";
import {SVVStore} from "../../components/StandardVisitView/SVVStore";
import moment from "moment";
import {clampValue} from "@blueprintjs/core/lib/esnext/components/forms/numericInputUtils";
import InvoiceLogo from "../components/InvoiceLogo";
import {formatFormalName, logoName} from "../../application/utils/utils";

// const img = require('./img/' + logoName);

const Page = styled.div`
  margin-right: 10px;
  @page {
    size: A4;
    margin: 0;
  }

  padding-bottom: 0cm;
  padding-top: 0cm;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  padding: 16px;
`

const Header = styled.div`
  //border: solid 1px #626262;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-template-areas: 
  "logo  centre centre"
  "logo  address contact";

`

const Logo = styled.div`
  display: grid;
  place-items: center;
  grid-area: logo;
`;

const CenterDetails = styled.div`
  grid-area: centre;
  padding-top: 10px;
  font-size: 12pt;
  font-family: Arial;


`
const Address = styled.div`
  grid-area: address;
  font-size: 10pt;
  font-family: Arial;
`

const Contact = styled.div`
  grid-area: contact;
  font-size: 10pt;
  font-family: Arial;
`

const ClientInfo = styled.div`
  padding: 2px;
  padding-left: 4px;
  border: dotted 1px black;
  font-size: 10pt;
`

const InvoiceData = styled.div`
  padding: 2px;
  padding-right: 4px;
  border: dotted 1px black;


  text-align: right;
  font-size: 10pt;
`

const Arial10 = styled.div`
  font-size: 10pt;
  font-family: Arial;
`


const BodyContainer = styled.div`
  display: grid;
  justify-items: center;
`

const Title = styled.div`
  font-size: 20px;
`;

const Text = styled.div`

`


interface FitxaBlancaProps {
    onPrintReady?: (handle) => null
    data?
    svvStore: SVVStore

}


export const MedicalReceipt = observer((props: FitxaBlancaProps) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (props.onPrintReady) props.onPrintReady(handlePrint)
    }, [])
    let data = props.data
    let visit = data.visit
    let patient = data.patient
    let org = props.svvStore.appStore.orgs[0]
    // if (visit == null) return <div>No s'ha trobat la visita</div>
    let usingVisitLines = props.data.visitLines.filter(vl => vl.product?.name != null);
    let vlNumbers = usingVisitLines.map(vl => vl.visitLineNumber).join(', ');
    let exitDate = moment(visit.exitRoomDate).add(20, 'minute').format('HH:mm')
    return <Page ref={componentRef}>
        <Container>
            {org ? <Header>
                <Logo>
                    <InvoiceLogo org={org}/>
                </Logo>
                <CenterDetails>
                    <div>{org.name}</div>
                    <Arial10>{org.cif}</Arial10>
                </CenterDetails>
                <Address>
                    <div>
                        {org.address}
                    </div>
                    <div>
                        {`${org.postalCode} ${org.city || ''}`}
                    </div>
                </Address>
                <Contact>
                    <div>
                        {`Tel. ${org.mobile}`}
                    </div>
                    <div>
                        {org.email}
                    </div>
                </Contact>
            </Header> : <div>Escull una organització!</div>}
            {/**/}
            <BodyContainer>
                <Title>
                    Justificant mèdic
                </Title>
                <Text>
                    El Sr. / Sra. {formatFormalName(patient)}, ha estat atès amb
                    data: {moment(visit.appointmentDate).format('DD/MM/YYYY')} amb hora
                    d'entrada: {moment(visit.appointmentDate).format('HH:mm')} i
                    hora de sortida: {exitDate}, en aquest centre per a realitzar-se el següent:
                </Text>
                {props.svvStore.visitsSel.selectedItems.map(v => {
                    return <div key={v?.id} style={{fontWeight: 'bold'}}>
                        {v?.resource?.name}
                    </div>
                })}
                <Text>
                    Atentament, ..............
                </Text>
            </BodyContainer>
        </Container>

    </Page>

})
