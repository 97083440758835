import React, {useState, useEffect, useContext, useRef, useLayoutEffect, RefObject} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import FullCalendar, {EventDropArg, PluginDef, ViewContentArg} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import locale_ca from "@fullcalendar/core/locales/ca";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import {useSCVStore} from "../../ScheduleView";
import {Icon, Popover, Tooltip} from "@blueprintjs/core";
import {createPopper, end} from '@popperjs/core';
import Tippy, {useSingleton} from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import * as _ from 'lodash'
import 'tippy.js/animations/scale.css';
import tippy, {Instance} from "tippy.js";
import ReactDOM from "react-dom";
import moment from "moment";
import {Simulate} from "react-dom/test-utils";
import {toJS, trace} from "mobx";
import {nextAnimationFrame} from "../../../../application/utils/utils";
import {useMouse} from "react-use";
import {useSRVStore} from "../../../ScheduleRulesView/ScheduleRulesView";


const Container = styled.div`
  display: grid;

  width: 100%;
`
const CalendarContainer = styled.div`
  height: 100%;
  width: calc(100vw - 50 - 16px);

`

export interface SCVCalendarProps {
    pageContext
}

export const SCVCalendar = observer((props: SCVCalendarProps) => {
    let store = useSCVStore();
    // let calendarRef = useRef();
    let timeTooltipRef: React.MutableRefObject<HTMLDivElement> = useRef();
    let popoverRef = useRef();
    let tippyRef = useRef(null as any);
    let activeElRef = useRef(null as HTMLElement);
    // const [source, target] = useSingleton();
    // store.calendarRef = calendarRef
    let events = store.events;
    // let m = useMouse(store.calendarRef as RefObject<any>)

    let srvStore = useSRVStore()
    useEffect(() => {
        tippyRef.current = tippy('#root', {
            content: 'Tooltip',
        });
    }, [])
    useLayoutEffect(() => {
        let scrollEl = store.el.querySelector('.fc-scroller-liquid-absolute');
        requestAnimationFrame(() => {
            if (scrollEl && store.scrollPosition) {
                scrollEl.scrollTop = store.scrollPosition
                // console.log(`restoring`, store.scrollPosition);
            }
        })

        // console.log(`M store.el?.scrollTop`, scrollEl?.scrollTop);
        return () => {
            // console.log(`UM store.el?.scrollTop`, scrollEl?.scrollTop);
            store.scrollPosition = scrollEl?.scrollTop
            // console.log(`store.el`, scrollEl);
        }
    })
    // console.log(`events`, events);
    let laneIndex = 0;
    let bodyEl: HTMLDivElement = null
    let listener = (event: MouseEvent) => {
        if(!timeTooltipRef.current) return
        // console.log(`event`, event, [event.offsetX, event.clientY], [bodyEl.scrollTop]);
        let current = timeTooltipRef.current as HTMLDivElement;
        current.style.top = `${event.clientY - 80}px`
        current.style.left = `${event.clientX - 30}px`
        let startHour = 7 // TODO Config
        let endHour = 21
        let hours = endHour - startHour + 1
        let rect = bodyEl.getBoundingClientRect();
        let effectivePosY = event.clientY - rect.y + bodyEl.scrollTop
        let totalY = bodyEl.scrollHeight
        let ratio = effectivePosY / totalY
        let roundedY = Math.round(effectivePosY);
        let hourFloat = ratio * hours + startHour
        if (true) hourFloat = Math.floor(hourFloat * (4 * 3)) / (4 * 3)
        let hour = Math.floor(hourFloat)
        let minute = Math.round((hourFloat - hour) * 60)
        // console.log(`hour, minute`, hour, minute, hourFloat - hour);
        if (timeTooltipRef.current) {
            (timeTooltipRef.current).innerText = `${_.padStart(hour.toString(), 2, '0')}:${_.padStart(minute.toString(), 2, '0')}`
        }
    };
    let listenerDebounced = _.debounce(listener, 0, {leading: true})
    let lastEvent = null
    let mouseListener = (event: MouseEvent) => {
        lastEvent = event
        listenerDebounced(event)
    }
    let scrollListener = (event) => {
        if (event == null) return
        listenerDebounced(lastEvent)
    }
    return <Container>
        {/*<Popover ref={popoverRef} target={'#id'}/>*/}
        {/*<Tippy ref={tippyRef}  content="Tooltip" reference={activeElRef} />*/}
        {/*<div id={'popover'}>Popover</div>*/}
        <CalendarContainer>
            {/*<div style={{*/}
            {/*    position: "absolute",*/}
            {/*    backgroundColor: 'black',*/}
            {/*    color: 'white',*/}
            {/*    fontWeight: 'bold',*/}
            {/*    borderRadius: '50%',*/}
            {/*    zIndex: 300,*/}
            {/*    padding: 6,*/}
            {/*    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.26)',*/}
            {/*    fontSize: 24*/}
            {/*}}*/}
            {/*     ref={timeTooltipRef}>23:30*/}
            {/*</div>*/}
            <FullCalendar
                stickyHeaderDates={true}
                monthMode={false}
                viewDidMount={(params: ViewContentArg & { el: HTMLElement }) => {
                    // console.log(`params.el`, params.el);
                    bodyEl = params.el.querySelector('.fc-scroller-liquid-absolute')
                    if (bodyEl) {
                        bodyEl.addEventListener('mousemove', mouseListener);
                        bodyEl.addEventListener('scroll', scrollListener);
                    }
                    store.calendarViewDidMount(params);
                }}
                viewWillUnmount={(params: ViewContentArg & { el: HTMLElement }) => {
                    if (bodyEl) {
                        bodyEl.removeEventListener("mouseleave", mouseListener)
                        bodyEl.removeEventListener("scroll", scrollListener)
                    }
                    store.viewWillUnmount(params)
                }}
                eventDidMount={async (params) => {
                    // var tooltip = new Tooltip(params.el, {
                    //     title: params.event.extendedProps.description,
                    //     placement: 'top',
                    //     trigger: 'hover',
                    //     container: 'body'
                    // });

                    let evt = params.event;
                    // console.log(`evt.extendedProps`, evt.extendedProps);
                    let tooltipEl = (tooltip = false) => {
                        let resourceId = evt.extendedProps.resourceId;
                        let resource = store.getResource(resourceId)
                        let visitLines = evt.extendedProps?.lines


                        return <div
                            onDoubleClick={(e) => {
                                window.open(`https://www.meetup.com/${evt.extendedProps?.urlName}/events/${evt.extendedProps?.meetupId}/`, "_blank");

                                // store.appStore.navContext.navigate({
                                //     to: 'productFilePage', args: {
                                //         eventId: [store.eventSel.selectedId]
                                //     },
                                //     inNewTab: e.shiftKey, focusNewTab: true
                                // })
                            }}
                            style={{
                                width: 'fit-content',
                                display: "grid",
                                gridAutoFlow: "row",
                                // gridTemplateColumns: "auto 1fr",
                                fontSize: 12
                            }}>
                            {/*<span style={{*/}
                            {/*    display: 'grid',*/}
                            {/*    gridTemplateColumns: 'auto 1fr',*/}
                            {/*    gap: 2,*/}
                            {/*    alignItems: "start"*/}
                            {/*}}/>*/}
                            {evt.extendedProps.comm && <span>{evt.extendedProps.comm}</span>}
                            <span style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr',
                                gap: 2,
                                alignItems: "start"
                            }}>
                                <span style={{
                                    display: 'grid',
                                    gridAutoFlow: 'column',
                                    gap: 2,
                                    alignItems: "start",
                                    paddingTop: 4,
                                }}>
                                {evt.extendedProps.type == 'meetup-event' && !evt.extendedProps.event?.id &&
                                <div style={{
                                    borderRadius: '50%',
                                    backgroundColor: 'orange',
                                    height: 8,
                                    width: 8,
                                    placeSelf: "center",
                                    boxSizing: 'border-box',
                                    border: '2.5px solid rgba(255,255,255,0.6)'
                                }}/>}

                                    {evt.extendedProps.comm &&
                                    <div style={{
                                        borderRadius: '50%',
                                        backgroundColor: 'red',
                                        height: 8,
                                        width: 8,
                                        placeSelf: "center",
                                    }}/>}
                                    {evt.extendedProps?.statusInfo?.icon &&
                                    <Icon icon={evt.extendedProps?.statusInfo?.icon} iconSize={12}/>}
                            </span>
                            <span style={{fontWeight: "bold"}}>{evt.title} </span>
                            </span>

                            <div style={{
                                display: 'grid',
                                gridTemplateRows: 'auto auto'
                            }}>
                                <div style={{fontWeight: "bold"}}>{evt.extendedProps.location &&
                                <Icon icon={'map-marker'}/>} {evt.extendedProps.location} </div>
                                <div style={{fontWeight: "bold"}}><Icon
                                    icon={'people'}/> {evt.extendedProps.attendance} going
                                </div>
                            </div>

                            <div>
                                {tooltip && <span
                                    style={{fontWeight: "bold"}}>
                                {moment(evt.start).format('HH:mm')} - {moment(evt.end).format('HH:mm')}
                            </span>}
                                {' '}
                                <span
                                    style={{fontWeight: "bold"}}>{evt.extendedProps.tmp ? `(Nova ${resource?.name})` : evt.extendedProps.vnt}</span> {evt.extendedProps?.tel?.length > 0 &&
                            <span>({evt.extendedProps.tel.map((tel) => tel).join('-')})</span>}
                                {' '}
                                {evt.extendedProps?.statusInfo?.name ? <span
                                    style={{fontWeight: "bold"}}>
                                    <span style={{
                                        borderRadius: 2,
                                        color: evt.extendedProps?.statusInfo?.color,
                                        height: 8,
                                        width: 8,
                                        placeSelf: "center"
                                    }}/>
                                    {`((${_.toUpper(evt.extendedProps?.statusInfo?.name)}))`}</span> : ''}

                            </div>

                        </div>;
                    }

                    let targetDiv = params.el.getElementsByClassName('fc-event-title fc-sticky').item(0)
                    if (targetDiv) {

                        ReactDOM.render(tooltipEl(), targetDiv, () => {

                            // let outerHeight = params.el.clientHeight;
                            // let height = targetDiv.firstElementChild?.clientHeight;
                            // let useTooltip = outerHeight < height
                            if (!evt.extendedProps.tmp) {
                                let key = `tt-${evt.id}`;
                                let inst: Instance = store.eventTooltips[params.event.id];
                                if (true) {

                                    inst = tippy(params.el, {
                                        placement: 'auto-end',
                                        // content: `<div id="${key}">${params.event.title}</div>`,
                                        // delay: [100, 3000],
                                        allowHTML: true,
                                        onShow: (e) => {
                                            store.hideAllTooltips()
                                            setTimeout(() => {
                                                let elementById = document.getElementById(key);

                                                if (!elementById) {
                                                    inst.setContent(`<div id="${key}"></div>`)
                                                    // inst.hide()
                                                }


                                                setTimeout(() => {
                                                    let elementById = document.getElementById(key);
                                                    if (elementById) {
                                                        // inst.show()
                                                        ReactDOM.render(
                                                            tooltipEl(true),
                                                            elementById)
                                                    } else {
                                                        inst.destroy()
                                                    }
                                                }, 10)
                                            })


                                        }
                                    });
                                    store.eventTooltips[params.event.id] = inst
                                    // console.log(`store.eventTooltips`, store.eventTooltips);
                                } else {
                                    inst.show()
                                }

                            }

                        })
                    }


                    // ReactDOM.render(<div>Hooooooooooooolaa</div>, document.getElementById('popover'))
                    // tippyRef.current.reference = params.el
                    // console.log(`tippyRef.current`, tippyRef.current);
                }}
                eventMouseEnter={(params) => {

                }}

                dayCount={1}
                initialView="workWeekView"
                schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                ref={store.calendarRef}
                plugins={[dayGridPlugin, resourceTimelinePlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin] as PluginDef[]}
                //headerToolbar={false}
                headerToolbar={false && {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'defaultView,workWeekView'
                }}
                weekends={store.showWeekend}
                height={'calc(100vh - 80px - 16px - 8px - 30px - 38px + 10px)'}
                windowResizeDelay={500}
                views={{
                    defaultView: {
                        type: 'resourceTimeGridDay',
                        buttonText: '1 Dia',
                        duration: {days: 1},
                        dayHeaderFormat: {weekday: 'short', day: 'numeric', month: 'numeric', omitCommas: true},
                    },
                    workWeekView: {
                        type: 'resourceTimeGrid',
                        buttonText: 'Setmana',
                        duration: {days: 7},
                        dayHeaderFormat: {weekday: 'short', day: 'numeric', month: 'numeric', omitCommas: true},
                    },

                } as any}
                resources={store.effectiveResources}

                resourceOrder={'order'}
                // events={events}
                allDaySlot={false}

                slotDuration={`00:${30}:00`}
                snapDuration={`00:${30}:00`}
                // slotMinTime={store.appStore.bounds?.startDate || "07:00:00"}
                // slotMaxTime={store.appStore.bounds?.endDate || "23:59:59"}
                dateClick={(params) => {

                    if (store.pageContext && store.patientId) {
                        store.addTmpEvent(params.resource.id, params.date)
                    }
                    if (store.isRulesPage) {
                        store.onDateClick(params)
                    }
                    return
                    setTimeout(() => {
                        let resourceId = params.resource.id;
                        if (store.pageContext && store.patientId) {
                            store.pageContext.navigate({
                                to: 'bookVisitPage', args: {
                                    patientId: store.patientId,
                                    newAppointment: {
                                        resourceId,
                                        appointmentDate: params.date
                                    }

                                },
                                inNewTab: params.jsEvent.ctrlKey, focusNewTab: true
                            })
                        }
                    })
                }}
                eventClick={(params) => {
                    let eventId = params.event.id;
                    console.log(`eventId`, eventId);
                    store.eventSel.selectId(eventId)
                    // console.log(`store.meetupEventSel.itemsRef.current111`, store.meetupEventSel.itemsRef.current);
                    // // if(store.eventSel.selectedItem.type == 'meetup-event') store.meetupEventSel.selectId(store.calendarEventsSelSelectedId, true, true)
                    // console.log(`store.meetupEventSel.itemsRef.current222`, store.meetupEventSel.itemsRef.current, eventId, store.meetupEventSel.selectedIds.length);
                    //
                    // if (srvStore) {
                    //     setTimeout(() => {
                    //         srvStore.rulesSel.selectId(eventId, true, true, true, true)
                    //         // srvStore.rulesSel.selectWhere((it) => it.id == params.event.id)
                    //     })
                    // }
                    // if (params.event.extendedProps.tmp) {
                    //     store.removeTmpEvent(eventId)
                    // } else {
                    //     let patientId = params.event.extendedProps.pId;
                    //     if (patientId) props.pageContext.navigate({
                    //         to: 'bookVisitPage', args: {
                    //             patientId: patientId,
                    //             selectedVisitIds: [eventId]
                    //         },
                    //         inNewTab: params.jsEvent.ctrlKey, focusNewTab: true
                    //     })
                    // }

                }}

                resourceLabelDidMount={(params) => {
                    // params.el.style.backgroundColor = params
                    let shaded = laneIndex % 2 == 0
                    laneIndex++
                    if (shaded) params.el.style.backgroundColor = '#E1E8ED'

                }}
                resourceLaneDidMount={(params) => {

                }}
                eventResize={(params) => {
                    let event = params.event;

                    if (store.isRulesPage) {
                        store.onResizeEvent(params)
                    } else {
                        store.updateEventStartEnd(event.id, event.start, event.end)
                    }
                }}
                eventDrop={(params: EventDropArg) => {
                    let event = params.event;
                    console.log('event.extendedProps.type', event.extendedProps.type)
                    // if (store.isRulesPage && event.extendedProps.type == 'block') {
                    //     store.onDropEvent(params)
                    // }
                    //     return
                    store.updateEventStartEnd(event.id, event.start, event.end, (params as any).newResource?.id)
                    store.i++


                }}
                eventDragStart={(params: EventDropArg) => {
                    let event = params.event;
                    if (store.isRulesPage && event.extendedProps.type == 'block') {
                        store.onDragEvent(params)
                        return
                    }
                }}

                locales={[locale_ca]}
                // aspectRatio={3.5}
                editable={true}
                dragScroll={true}
                eventResizableFromStart={true}
                // scrollTime={'06:00:00'}

            />
        </CalendarContainer>
    </Container>
})

