import {C3Schema, C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const contactsFilterSchema: C3SchemaInfo = {
    'contact-filter': {
        local: true,
        fields: {
            'contactType': {
                type: 'contact-type-filter',
                label: 'Tipus de contactes'
            }
        }
    },

    'contact-type-filter': {
        // Contact Type for filters
        localData: [
            {id: 'person', name: 'Persones'},
            {id: 'patient', name: 'Pacients'},
            {id: 'insurance-company', name: 'Asseguradores'},
            {id: 'mutua', name: 'Mutues'},
            {id: 'clinique', name: 'Clíniques'},
            {id: 'hospital', name: 'Hospitals'},
        ],
        fields: {
            id: {},
            name: {},
        }
    }
}
