import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {InsCompanyFVStore} from "../../files-views/InsCompany/InsCompanyFVStore";

const Container = styled.div`
    display: grid;
    
`

export interface InsCompanyFilePageContextProps {
    store: InsCompanyFVStore
}

export const InsCompanyFilePageHeader = (props: InsCompanyFilePageContextProps) => {
    return <Container>

    </Container>
}
