import * as _ from 'lodash'
import {IDateFormatProps} from "@blueprintjs/datetime";
import moment from "moment";
import {MRFField} from "react-c4/src/forms/MRF";
import React, {useContext, useRef} from "react";
import {BISApplication} from "../BISApplication";
import {IATSStyleApp} from 'react-c4';
import {AppStore} from "../AppStore";
import numeral from 'numeral'
import {ICellRendererParams, ValueParserParams} from "ag-grid-community";
import {Checkbox} from "@blueprintjs/core";
import {featureMap} from "../auth/features/featureMap";
import {FeatureFilter} from "../auth/features/FeatureFilter";
import {logoImgs} from "../../documents/img/imgExports";


// numeral.register('locale', 'ca-es', {
//     delimiters: {
//         thousands: '.',
//         decimal: ','
//     },
//     abbreviations: {
//         thousand: 'k',
//         million: 'mm',
//         billion: 'b',
//         trillion: 't'
//     },
//     ordinal: function (number) {
//         var b = number % 10;
//         return (b === 1 || b === 3) ? 'er' :
//             (b === 2) ? 'n' :
//                 (b === 7 || b === 0) ? 'mo' :
//                     (b === 8) ? 'vo' :
//                         (b === 9) ? 'no' : 'to';
//     },
//     currency: {
//         symbol: '€'
//     }
// });

numeral.locale('en')

export const isProd = () => process.env.INST_IS_PROD == "true"
export const isDev = () => process.env.INST_IS_DEV == "true"
export const isDemo = () => process.env.INST_IS_DEMO == "true"
let providedLogoMap = {1: 'logoDimat.png', 5: 'logoGuirado.jpg', 8: 'logoEspuche.png'};
export let logoMap = _.mapValues(providedLogoMap, (n) => logoImgs[n])
// export const logoName = 'logoDimat.png'
export let logoName = 'logoGuirado.jpg'
if (isDemo()) logoName = 'logoMedicalOne.png'


export const isAdmin = () => {
    return true;
    let admin = false //((IATSStyleApp.instance?.appStore) as AppStore)?.auth?.loggedInUser?.isAdmin;
    let token = ((IATSStyleApp.instance?.appStore) as AppStore)?.auth?.token
    if ([1, 7, 4, 2, 6, 8, 11].includes(Number(token))) admin = true
    return admin;
}
export const hasFeature = (featureKey, fullRoles = false) => {
    return window['ff'].hasFeature(featureKey, fullRoles)
}
export const dec = () => {
    return (window['ff'] as FeatureFilter).dec
}
console.log(isDev() ? 'Development mode' : '');

export let formatFormalName = (e) => {
    if (e == null) return null;
    return (e.treatment ? e.treatment + ' ' : '') +
        [
            [e.surname1, e.surname2].filter(s => !_.isEmpty(s)).join(' '),
            e.name
        ]
            .filter(s => !_.isEmpty(s)).join(', ')
}
export let formatFullName = (e) => {
    if (e == null) return null;
    return (e.treatment ? e.treatment + ' ' : '') +
        [e.name, e.surname1, e.surname2,].filter(s => !_.isEmpty(s)).join(' ')


}

export let latMap = {
    'null': '-',
    l: 'Esq',
    r: 'Drt',
    c: 'Comp',
}
export let paymentFormMap = {
    'null': '-',
    cash: 'Efectiu',
    cc: 'Targeta',
    transfer: 'Transferència',
}
export let insCompanyPaymentFormMap = {
    chipcard: 'Chipcard',
    manual: 'Manual',
    included: 'Inclòs barem',
}
export let insCompanyPaymentMethodNamesMap = {
    ss: {short: 'SS', long: 'Seguretat social'},
    endOfMonth: {short: 'Fi de mes', long: 'Fi de mes'},
    private: {short: 'Privat', long: 'Privat'},
}

// export let documentTypesMap = {
//     ci: {
//         buttonName: 'CI+Pet',
//             tooltipName: 'Consentiment Informat i Petició'
//     },
//     // pet: {
//     //     buttonName: 'Petició',
//     //     tooltipName: 'Petició metge'
//     // },
//     aut: {
//         buttonName: 'Autorització',
//             tooltipName: 'Autorització mutua'
//     },
//     vol: {
//         buttonName: 'Volant',
//             tooltipName: 'Volant chipcard'
//     },
//     ip: {
//         shortName: 'IP',
//             buttonName: 'Inf. Prev.',
//             tooltipName: 'Informes Previs'
//     },
//     other: {
//         shortName: 'Alt',
//             name: 'Altres',
//             buttonName: 'Altres',
//             tooltipName: 'Altres documents'
//     },
//
//
// }

export let documentTypesMap = {
    ci: {
        buttonName: 'CI',
        tooltipName: 'Consentiment Informat i Petició'
    },
    pet: {
        buttonName: 'Petició',
        tooltipName: 'Petició metge'
    },
    aut: {
        buttonName: 'Autorització',
        tooltipName: 'Autorització mutua'
    },
    // vol: {
    //     buttonName: 'Volant',
    //         tooltipName: 'Volant chipcard'
    // },
    ip: {
        shortName: 'IP',
        buttonName: 'Inf. Prev.',
        tooltipName: 'Informes Previs'
    },
    lab: {
        shortName: 'Lab',
        name: 'Laboratori',
        buttonName: 'Laboratori',
        tooltipName: 'Laboratori'
    },
    other: {
        shortName: 'Alt',
        name: 'Altres',
        buttonName: 'Altres',
        tooltipName: 'Altres documents'
    },


}

export let nextAnimationFrame = async () => new Promise((resolve, reject) => requestAnimationFrame(resolve))
export let nextTimeout = async (ms?) => new Promise((resolve, reject) => setTimeout(resolve, ms))

function getMomentFormatter(format: string): IDateFormatProps {
    // note that locale argument comes from locale prop and may be undefined
    return {
        formatDate: (date: any, locale) => {
            if (date == null || date == '') return ''
            let moment1 = moment(date);
            if (!moment1.isValid()) return '';
            return moment1.format(format);
        },
        parseDate: (str, locale) => {
            if (str == '') return null
            return moment(str, format).toDate();
        },
        placeholder: format,
    }
};

export let bindWithSave = (field: MRFField, save: (field: MRFField, e: any) => any) => {
    let bind = field.bind();
    return {
        ...bind, onChange: (e) => {
            bind.onChange(e);
            save(field, e)
        }
    }
}

export let useAS = (save: (field: MRFField, e: any) => any, interval = 900) => useRef(_.debounce(save, interval, {trailing: true}))
export let bindWithAS = (field: MRFField, as) => {
    return bindWithSave(field, (field, e) => as.current(field, e))
}


export let useAppStore = () => {
    return useContext(IATSStyleApp.instance.appStoreContext as React.Context<AppStore>)
}

export let stdNumericColumn = () => {
    return {
        valueFormatter: params => {
            if (params.value == null) return null
            return numeral(params.value).format('0.00')
        },
        type: ['numericColumn', 'rightAligned'],
        width: 70
    }
}
export let stdIntNumericColumn = () => {
    return {
        valueFormatter: params => {
            if (params.value == null) return null
            return numeral(params.value).format('0')
        },
        type: ['numericColumn', 'rightAligned'],
        width: 70
    }
}
export let stdDateColumn = (nullBlank = false) => {
    return {
        valueFormatter({value}) {
            if (value === undefined) return ''
            if ([null, ''].includes(value)) return nullBlank ? '' : '(Sense data)'
            return moment(value).format('DD/MM/YYYY');
        },
        valueParser: (params: ValueParserParams) => {
            let moment1 = moment(params.newValue, 'DD/MM/YYYY');
            moment(params.oldValue).startOf('date').add(moment1.date()).add(moment1.date())
            return moment1.toDate()
        },
        type: ['rightAligned'],
        width: 90
    }
}
export let stdHourColumn = () => {
    return {
        valueFormatter({value}) {
            if (value == null) return '--'
            return moment(value).format('HH:mm');
        },
        valueParser: (params: ValueParserParams) => {
            let moment1 = moment(params.newValue, 'HH:mm');
            moment(params.oldValue).startOf('day').add(moment1.hours()).add(moment1.seconds())
            return moment1.toDate()
        },
        type: ['rightAligned'],
        width: 90
    }
}
export let stdBoolColumn = (editable?: (params) => any) => {
    return {
        cellRendererFramework: (params: ICellRendererParams) => {
            let disabled = editable ? editable(params) == false : false
            return <Checkbox disabled={disabled} defaultChecked={params.value} onChange={(e) => {
                let value = e.currentTarget.checked;
                params.setValue(value === true)
                // params.node.setDataValue('active', value === true)
            }}/>
        },
        editable: false,
    }
}
export let stdCurrencyFormat = (number) => {
    return numeral(number).format('0,0.00')
}

export let processOrder = [
    'confirmationDate',
    'arrivalDate',
    'enterReceptionDate',
    'enterWaitingRoomDate',
    'summonDate',
    'enterRoomDate',
    'exitRoomDate',
    'exitReceptionDate',
    'exitDate',
    '__end',
];

// export let visitStatus = {
//     arrived: {
//         name: 'Arribat',
//         color: '#106BA3'
//     },
//     inWaitingRoom: {
//         name: `En sala d'espera`,
//         color: '#DB2C6F'
//     },
//     summoned: {
//         name: `Cridat`,
//         color: '#C22762'
//     },
//     inMachine: {
//         name: 'En màquina',
//         color: '#8F398F'
//     },
//     outOfVisit: {
//         name: 'Fora visita',
//         color: '#00998C'
//     },
//
//
// }
// export let specialStatus = {
//     cancelled: {
//         name: 'Cancelada',
//         color: '#990000',
//         icon: 'disable'
//     },
//     reminded: {
//         name: 'Recordat',
//         color: '#d9ae3f',
//         icon: 'phone'
//     },
//     noShow: {
//         name: 'No Vingut',
//         color: '#990000',
//         icon: 'blocked-person'
//     },
//     reported: {
//         name: 'Informat',
//         color: '#009903',
//         icon: 'blocked-person'
//     }
// }
export let visitStatus = {
    arrived: {
        name: 'Arribat',
        color: '#106BA3'
    },
    inWaitingRoom: {
        name: `En sala d'espera`,
        color: '#b52cdb'
    },
    summoned: {
        name: `Cridat`,
        color: '#C22762'
    },
    inMachine: {
        name: 'En màquina',
        color: '#5fb5cb'
    },
    outOfVisit: {
        name: 'Fora visita',
        color: '#007c74'
    },


}
export let specialStatus = {
    cancelled: {
        name: 'Cancelada',
        color: '#efd604',
        icon: 'disable'
    },
    reminded: {
        name: 'Recordat',
        color: '#e59125',
        icon: 'phone'
    },
    noShow: {
        name: 'No Vingut',
        color: '#d6cbaa',
        icon: 'blocked-person'
    },
    reported: {
        name: 'Informat',
        color: '#009903',
        icon: 'blocked-person'
    }
}

export let getVisitStatusInfo = (name: number, {cancelledAt, noShow, reminded, reported}) => {
    if (cancelledAt) return specialStatus.cancelled
    if (noShow) return specialStatus.noShow
    if (reported) return specialStatus.reported
    let processOrderIndexes = Object.assign({}, ..._.entries(processOrder).map(([k, v]) => ({[v]: k})))
    let status = null
    let idx = (name) => processOrderIndexes[name]
    let after = (moreThanName) => idx(name) >= idx(moreThanName)
    if (after('arrivalDate')) {
        status = 'arrived'
    }
    if (after('enterWaitingRoomDate')) {
        status = 'inWaitingRoom'
    }
    if (after('summonDate')) {
        status = 'summoned'
    }
    if (after('enterRoomDate')) {
        status = 'inMachine'
    }
    if (after('exitRoomDate')) {
        status = 'outOfVisit'
    }
    if (status == null) {
        if (reminded) return specialStatus.reminded
    }
    return visitStatus[status] || {}
}


export let compressedIdFormat = (ids: string[]) => {
    if(ids.length < 3){

    }
}


