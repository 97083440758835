import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {useSVVStore} from "../../../../StandardVisitView";
import {bindWithAS, useAS} from "../../../../../../application/utils/utils";
import {DateInputControl} from "react-c4";

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  align-items: start;
`
const BottomSectionContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 4px;

`
const RowContainer = styled.div`
  display: grid;
  justify-content: start;
  grid-gap: 4px;
  grid-auto-flow: column;
`
const PatientAltInvoicingContainer = styled.div`
  //display: flex;
  //grid-auto-flow: column;
`
const LeftRowContainer = styled.div`
  display: grid;
  justify-content: stretch;
  justify-self: end;
  grid-gap: 4px;
`
const ConfirmButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-flow: column;
  grid-gap: 4px;
  //width: 200px;
`
const SplitContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
`

export interface SVVPIPInvoiceEditorProps {

}

export const SVVPIPInvoiceEditor = observer((props: SVVPIPInvoiceEditorProps) => {
    let store = useSVVStore()
    let invoiceForm = store.privateInvoiceForm;
    let as = useAS((field, e) => {
        let fieldName = field.path;
        let body = {id: invoiceForm.$('id').value};
        _.set(body, fieldName, invoiceForm.$(fieldName).value)
        let promise = store.savePrivateInvoice(body, false);
    })
    let as2 = useAS((field, e) => {
        let fieldName = field.name;
        let patientAltInvoicingField = invoiceForm.$('patientAltInvoicing');
        let $id = patientAltInvoicingField.$('id');
        let body = {id: $id.value};
        _.set(body, fieldName, patientAltInvoicingField.$(fieldName).value)
        let promise = store.savePrivateInvoiceAltFields(body, false);
        promise.then((res: any) => {
            $id.set(res.id)
        })
    })
    if (!store.privateInvoicesSel.selectedId) return null
    let showForm = ['true', true].includes(invoiceForm.$('usingPatientAltInvoicing').value);
    let closed = !store.isInvoiceOpen;
    return <Container>
        <RowContainer>
            {/*<select {...invoiceField.$('org')}>*/}
            {/*    {store.allOrgs.map((it) => {*/}
            {/*        return <option value={it.id}>*/}
            {/*            {it.name}*/}
            {/*        </option>*/}
            {/*    })}*/}
            {/*</select>*/}
            {/*<select disabled={closed} value={1}>*/}
            {/*    <option value={1}>DIMAT</option>*/}
            {/*</select>*/}
            <select {...bindWithAS(invoiceForm.$('usingPatientAltInvoicing'), as)} disabled={closed}>
                <option value={'false'}>Pacient</option>
                <option value={'true'}>Altre</option>
            </select>
            <DateInputControl field={invoiceForm.$('invoiceDate')} as={as} locked={closed}/>
            {/*{JSON.stringify(invoiceForm.$('usingPatientAltInvoicing').value)}*/}
        </RowContainer>
        {showForm && <PatientAltInvoicingContainer>
          <input {...bindWithAS(invoiceForm.$('patientAltInvoicing.name'), as2)} disabled={closed} placeholder={'Nom'}/>
          <input {...bindWithAS(invoiceForm.$('patientAltInvoicing.surname1'), as2)} disabled={closed}
                 placeholder={'Cognom1'}/>
          <input {...bindWithAS(invoiceForm.$('patientAltInvoicing.surname2'), as2)} disabled={closed}
                 placeholder={'Cognom2'}/>
          <input {...bindWithAS(invoiceForm.$('patientAltInvoicing.nif'), as2)} disabled={closed}
                 placeholder={'NIF / CIF'}/>
          <input {...bindWithAS(invoiceForm.$('patientAltInvoicing.address'), as2)} disabled={closed}
                 placeholder={'Adreça'}/>
          <input {...bindWithAS(invoiceForm.$('patientAltInvoicing.city'), as2)} disabled={closed}
                 placeholder={'Població'}/>
          <input {...bindWithAS(invoiceForm.$('patientAltInvoicing.postalCode'), as2)} disabled={closed}
                 placeholder={'CP'}/>
        </PatientAltInvoicingContainer>}
        <SplitContainer>
            <textarea {...bindWithAS(invoiceForm.$('notes'), as)} placeholder={'Comentari factura'}/>
            <textarea {...bindWithAS(invoiceForm.$('comment'), as)} placeholder={'Comentari intern'}/>
        </SplitContainer>
        <BottomSectionContainer>
            {/*<label>Mostra a factura</label>*/}
            {/*<ButtonGroup>*/}
            {/*    <Button active>Noms de proves</Button>*/}
            {/*    <Button>Noms de barems</Button>*/}
            {/*</ButtonGroup>*/}
            <div/>
            <div/>
            <ConfirmButtonsContainer>
                {store.isInvoiceOpen && <Button
                  icon={'trash'} onClick={async () => {
                    await store.deletePrivateInvoice()
                    await store.closePrivateInvoicingMode()
                }}> Esborrar temporal
                </Button>}
                <Button
                    intent={store.isInvoiceOpen ? 'primary' : 'none'}
                    disabled={!store.canInvoiceBeClosed}
                    icon={'document'} onClick={() => {
                    store.closePrivateInvoice()
                }}>{store.isInvoiceOpen ?
                    (store.privateInvoiceRes?.total == 0 ? 'Fra. amb valor 0' : 'Crear factura') :
                    `${store.privateInvoiceRes?.invoiceNumberText}`}
                </Button>
                {store.canInvoiceBeClosed && <Button
                  intent={store.isInvoiceOpen ? 'primary' : 'none'}
                  icon={'print'}
                  disabled={closed}
                  onClick={async () => {
                      await store.closePrivateInvoice()
                      await store.printInvoice()
                  }}
                >
                  Crear + Imprimir
                </Button>}
                {!store.isInvoiceOpen && <Button
                  icon={'print'}
                  onClick={() => {
                      // store.closePrivateInvoice()
                      store.printInvoice()
                  }}
                >
                  Imprimir
                </Button>}

            </ConfirmButtonsContainer>
        </BottomSectionContainer>
    </Container>
})
