import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {ExtCenterFVStore} from "../../files-views/ExtCenterFileView/ExtCenterFVStore";
import {Button, ButtonGroup} from "@blueprintjs/core";
import {observer} from 'mobx-react'
import {isAdmin, isDev} from "../../application/utils/utils";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
`

export interface ExtCenterFilePageContextProps {
    store: ExtCenterFVStore
}
const ButtonContainer = styled.div`
  padding-left: 10px
`

export const ExtCenterFilePageHeader = observer((props: ExtCenterFilePageContextProps) => {
    let store = props.store;
    if (store === null) return null;
    return <Container>
        <ButtonGroup>
            {store.orgsSel.items?.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={()=>store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>
        <ButtonContainer>
            <Button icon={'refresh'} onClick={() => {
                store.refresh()
            }}/>
        </ButtonContainer>
    </Container>
})
