import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import Splitter from "m-react-splitters";
import {useSRVStore} from "../ScheduleRulesView";
import {ScheduleView} from "../../ScheduleView/ScheduleView";
import {SRVRulesGrid} from "./SRVRulesGrid";
import {SRVLeftPanel} from "./SRVLeftPanel";
import {SchedulePageSubheader} from "../../../pages/SchedulePage/SchedulePageSubheader";

const Container = styled.div`
    display: grid;
    height: 100%;
`
const SCVContainer = styled.div`
    height: 100%;
    //transform: scale(0.8);
    //   box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.36);
       background-color: white;
`

export interface SRVMainProps {
    pageContext
}

export const SRVMain = observer((props: SRVMainProps) => {
    let store = useSRVStore();
    return <Container>
        <Splitter
            position="vertical"
            primaryPaneMinWidth={0}
            primaryPaneWidth={'400px'}
            postPoned={false}>
            <SRVLeftPanel/>
            <SCVContainer>
                <SchedulePageSubheader store={store.scvStore}/>
                <ScheduleView onStoreReady={(scvStore) => store.setSCVStore(scvStore)} pageContext={props.pageContext}/>
            </SCVContainer>

        </Splitter>
    </Container>
})
