import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {SVVAttachDocumentsHeader} from "./SVVAttachDocumentsHeader";
import {SVVAttachDocumentsList} from "./SVVAttachDocumentsList";
import {useSVVStore} from "../../StandardVisitView";
import {SVVAttachDocumentsLargePreview} from "./SVVAttachDocumentsLargePreview/SVVAttachDocumentsLargePreview";
// import {useScroll} from 'react-use';

const Container = styled.div`
    display: grid;
    grid-gap: 4px;
    grid-template-rows: auto 1fr;
    grid-auto-flow: column;
    //width: 550px;
    
`
const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    overflow: hidden;
`
const ListScrollContainer = styled.div`
    overflow-y: scroll;
`


export interface SVVAttachDocumentsPanelProps {

}

export const SVVAttachDocumentsPanel = observer((props: SVVAttachDocumentsPanelProps) => {
    let store = useSVVStore()
    let prevW = 119
    let btnsW = 94
    let descW = 180
    // const scrollRef = React.useRef(null);
    // const {x, y} = useScroll(scrollRef);

    return <Container
        // styles={{width: (prevW + btnsW + descW) * 2}}
    >
        <SVVAttachDocumentsHeader/>
        {/*<div>x: {x}</div>*/}
        {/*<div>y: {y}</div>*/}
        <BodyContainer>

            <ListScrollContainer
                // ref={scrollRef}
            >

                <SVVAttachDocumentsList/>
            </ListScrollContainer>
            {(store.isAttachDocumentsModeLargePreview && store.documentsSel.selectedItem) && <SVVAttachDocumentsLargePreview/>}
        </BodyContainer>

    </Container>
})
