import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import { DetailLayout } from "react-c4";
import { DetailLayoutProps } from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/detail-layout/DetailLayout";

const HeaderContainer = styled.div`
    display: grid;
    place-items: center;
    background-color: #adbfcd;
    padding: 2px;
    font-size: 17px;
`

export interface ProcessTabLayoutProps extends DetailLayoutProps{
    title
    children
    buttonsFragment
}

export const ProcessTabLayout = observer((props: ProcessTabLayoutProps) => {
    return <DetailLayout renderHeader={() => <HeaderContainer>
        {props.title} T-CBCT-1 EP: 40958345
    </HeaderContainer>}  {...props}/>
})
