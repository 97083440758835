import React, {useContext, useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Page} from "react-c4";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {SCVStore} from "../../components/ScheduleView/SCVStore";
import {page} from "react-navigation-plane";
import {ResourceFileView} from "../../files-views/ResourceFileView/ResourceFileView";
import {RFVStore} from "../../files-views/ResourceFileView/RFVStore";
import {ResourceFilePageHeader} from "./ResourceFilePageHeader";


const Container = styled.div`
    display: grid;
`



export interface SchedulePageProps {

}

export const ResourceFilePage = page(observer((props: SchedulePageProps) => {
    let [store, setStore] = useState(null as RFVStore);
    return <Page
        title={'Màquines'}
        subtitle={store?.resourcesSel?.selectedItem?.name ||'Manteniment de màquines'}
        icon={'calendar'}
        attributes={[
            {
                name: 'Recursos',
                value: store?.resourcesRes?.length,
                unit: 'màquines'
            },
            (store?.resourcesSel?.selectedItem?.name && {
                name: `Total ${store?.resourcesSel?.selectedItem?.name}`,
                value: store?.assignedProductsRes?.length,
                unit: 'proves'
            }), {
                name: `Total proves`,
                value: store?.allProductsSel?.items?.length,
                unit: 'proves'
            },
        ]}
        renderCustomHeaderArea={() => {
            return <ResourceFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            return <Container>
                <ResourceFileView
                    onStoreReady={(s) => {
                        setStore(s);
                    }}
                    {...pageContext.args}
                    pageContext={pageContext}
                />
            </Container>
        }}
    />
}))
