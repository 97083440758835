import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {autorun, computed, observable, reaction, toJS} from "mobx";
import MobxReactForm from "mobx-react-form";
import {ColDef, GridApi, ICellRendererParams, ValueFormatterParams} from "ag-grid-community";
import chroma from "chroma-js";
import * as _ from 'lodash'
import {stdDateColumn} from "../../application/utils/utils";


export class ProdFVStore {
    appStore: AppStore

    pageContext

    form: MRF
    peGridApi: GridApi

    refMap = {}

    @observable
    showInactive = false



    // @observable.shallow
    // orgsRes: any[]
    // orgsSel: C3Selection
    constructor(private args) {

    }

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        // this.orgsRes = this.appStore.orgs || [];
        this.eventsSel = new C3Selection({
            items: this.eventsRes,
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                await this.fetchEventsMGMeetupEvents()
                if (this.allMGMeetupEventRes != null)
                    await this.fetchMeetupEventsForSelMeetupGroup()
            }
        })
        this.allMeetupGroupsTabsSel = new C3Selection({
            items: this.allMeetupGroupsTabsRes,
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                await this.fetchMeetupEventsForSelMeetupGroup()
                await this.fetchEventsMGMeetupEvents()
                this.peGridApi.ensureColumnVisible(`rs.${this.allMeetupGroupsTabsSel.selectedId}`)
                if (this.peGridApi && this.allMeetupGroupsTabsSel.selectedId) {
                }
                if (this.allMeetupGroupsTabsSel.selectedId) this.focusMGTab(this.allMeetupGroupsTabsSel.selectedId)
            }

        })

        this.allMGMeetupEventsSel = new C3Selection({
            items: this.allMeetupGroupsTabsRes,
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                // await this.fetchAllRates()
            }

        })
        this.eventMGMeetupEventsSel = new C3Selection({
            items: this.allMeetupGroupsTabsRes,
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                // await this.fetchAllRates()
            }
        })

        // this.orgsSel = new C3Selection(({
        //     items: this.orgsRes,
        //     onSelectionChanged: () => {
        //         this.form.$('orgs').set(this.orgsSel.selectedItem)
        //     }
        // }))
        this.form = new MobxReactForm({
            fields: [
                'rateSheetSearch',
                'orgs'
            ],
            // initials,
            // defaults,
            values: {
                rateSheetSearch: '',
                orgs: []
            }
            // labels,
            // placeholders,
            // rules,
            // extra,
            // hooks
        })
        await this.fetchMeetupGroups()
        this.allMeetupGroupsTabsSel.selectIndex(0, true, true, false, false)
        await this.fetchMeetupEventsForSelMeetupGroup()

        reaction(() => this.filterFromToday, () => {
            this.fetchEvents()
            this.fetchMeetupEventsForSelMeetupGroup()
        })

        autorun(async () => {
            let inactive = this.showInactive;
            await this.fetchEvents()

        })

        // autorun(async () => {
        //     this.orgsRes = this.appStore.orgs;
        //     this.orgsSel.itemsRef.current = this.orgsRes;
        //     this.orgsSel.selectedIds = [this.orgsRes[0].id];
        // })
        autorun(async () => {
            let inactive = this.showInactive;
            await this.fetchEvents()

        })
        autorun(async () => {
            // this.form.$('orgs').set(this.orgsSel.selectedItem)
            await this.fetchEvents()
           if(this.args.eventId)
               this.eventsSel.selectId(this.args.eventId, true, true, false, false)
            else
               this.eventsSel.selectIndex(0, true, true, false, false)
            await this.fetchEventsMGMeetupEvents()
        }, {delay: 5})

        autorun(async () => {
            // this.form.$('orgs').set(this.orgsSel.selectedItem);
            await this.fetchMeetupGroups()
            this.allMeetupGroupsTabsSel.selectIndex(0, true, true, false, false)
            await this.fetchMeetupEventsForSelMeetupGroup()
        }, {delay: 5})
    }

    @observable.shallow
    eventsRes

    eventsSel: C3Selection

    @observable
    filterFromToday = true

    async fetchEvents() {

        return await this.fetchEventMeetupEventCountMatrix();
        // this.productsRes = await C3.instance.client.fetcher.fetch('/product', {})
        // this.productsSel.itemsRef.current = this.productsRes;
        // return this.productsRes
    }

    async fetchEventMeetupEventCountMatrix() {
        // let orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        // if (orgs === '') return
        this.eventsRes = await C3.instance.client.fetcher.fetch('/meetup-group/event-meetup-event-count-matrix', {
            query: [`inactive=${this.showInactive.toString()}`, `filterFromToday=${this.filterFromToday}`].filter(v => v != null).join('&'),

        })
        this.eventsSel.itemsRef.current = this.eventsRes;

        // Set coldefs
        this.setPEGridColDefs()
        return this.eventsRes
    }

    setPEGridColDefs() {
        let maxColors = 7;
        let backgroundColor = chroma.scale(['lightgreen', '008ae5'])
            .domain([0, 0.25, 1]).colors(maxColors).map(c => chroma(c).desaturate(0.8).brighten(1.2))
        // chroma.scale(['#fafa6e','#2A4858'])
        //    .mode('lch').colors(maxColors)
        let rsColDefs = []
        rsColDefs = this.allMeetupGroupsTabsRes.map(a => {
            return {
                field: `rs.${a.id}`,
                headerName: a.name,
                headerTooltip: a.name,
                width: 96,
                type: ['numericColumn', 'rightAligned'],
                valueFormatter: ({value}) => {
                    return value
                },
                cellStyle: function (params: ICellRendererParams) {
                    let color;

                    return {
                        fontWeight: 'bold',


                        backgroundColor: backgroundColor[_.clamp(params.value, 0, maxColors - 1)]
                    }

                }
            } as ColDef
        })
        this.peGridApi.setColumnDefs([
            {
                field: '',
                headerName: '',
                width: 30,
                checkboxSelection: true,
                // cellRenderer: 'productNameCR',
                pinned: true,
                editable: true
            },
            {
                field: 'id',
                headerName: 'Id',
                pinned: true,
                width: 50,
                // suppressFilter: true,
            },
            {
                field: 'startDate',
                headerName: 'Data',
                width: 30,
                pinned: true,
                filter: 'agTextColumnFilter',
                ...stdDateColumn(),
            },
            {
                field: 'name',
                headerName: 'Nom',
                width: 255,
                pinned: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                cellStyle: (params) => {
                    let inactive = params.data.isActive === false;
                    return {
                        'font-weight': 'bold',
                        ...(inactive && {
                            textDecoration: 'line-through',
                            color: 'gray',
                            'font-weight': 'normal'
                        }),
                    }

                }
            },
            ...rsColDefs
        ])
        this.peGridApi.redrawRows()

    }

    @observable
    isMatrixFullScreen: boolean = false

    openSelection(productId, rsId) {
        this.eventsSel.selectId(productId)
        this.allMeetupGroupsTabsSel.selectId(rsId)
        this.isMatrixFullScreen = false
    }

    // async createProduct() {
    //     await C3.instance.client.fetcher.fetch('/product', {
    //         method: 'POST',
    //         body: {
    //             name: 'nova prova',
    //             orgs: [this.orgsSel.selectedId]
    //         }
    //     })
    //     await this.fetchEvents()
    // }

    async refresh() {
        this.fetchEvents()
        this.fetchMeetupGroups()
    }

    // async deleteProduct(productId) {
    //     await C3.instance.client.fetcher.fetch(`/product/${productId}`, {
    //         method: 'DELETE',
    //     })
    //     await this.fetchEvents()
    // }

    // async deleteSelectedProduct() {
    //     let selectedId = this.eventsSel.selectedId;
    //     if (selectedId == null) return
    //     await this.deleteProduct(selectedId)
    // }

    async saveEvent(event) {
        await C3.instance.client.fetcher.fetch(`/event/${event.id}/edit`, {
            method: 'PATCH',
            body: event
        })
    }

    // TABS

    @observable.shallow
    allMeetupGroupsTabsRes

    allMeetupGroupsTabsSel: C3Selection

    async fetchMeetupGroups() {
        // if(!this.orgsSel.selectedId) {
        //     this.allMeetupGroupsTabsRes = [];
        //     this.allMeetupGroupsTabsSel.itemsRef.current = this.allMeetupGroupsTabsRes ;
        // }
        // const orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        this.allMeetupGroupsTabsRes = await C3.instance.client.fetcher.fetch(`/meetup-group/with-meetup-event-count`, {
            // query: [orgs]
        })
        this.allMeetupGroupsTabsSel.itemsRef.current = this.allMeetupGroupsTabsRes;
        return this.allMeetupGroupsTabsRes
    }

    // RIGHT GRID (RateSheet)

    @observable.shallow
    allMGMeetupEventRes

    allMGMeetupEventsSel: C3Selection

    @observable
    filterRes = {auto: true, sameWeek: false, sameDay: false, sameTitle: false}

    async fetchMeetupEventsForSelMeetupGroup() {
        let selectedId = this.allMeetupGroupsTabsSel.selectedId;
        // let orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        if (selectedId == null) {
            this.allMGMeetupEventRes = [];
            this.allMGMeetupEventsSel.itemsRef.current = this.allMGMeetupEventRes;
            return
        }
        let filterObj = {...this.filterRes, eventId: this.eventsSel.selectedId};
        let res = await C3.instance.client.fetcher.fetch(`/meetup-group/${selectedId}/meetup-events`, {
            query: [`filter=${JSON.stringify(filterObj, null, 2)}`, `filterFromToday=${this.filterFromToday}`].join('&')
        })
        // if (!res) return
        Object.assign(this.filterRes, res?.filterRes || {sameWeek: false, sameDay: false, sameTitle: false})
        this.allMGMeetupEventRes = res?.meetupEventRes || [];
        this.allMGMeetupEventsSel.itemsRef.current = this.allMGMeetupEventRes || [];

        return this.allMGMeetupEventRes|| []
    }

    // MIDDLE GRID (Product + RateSheet)

    @observable.shallow
    eventMGMeetupEventRes

    eventMGMeetupEventsSel: C3Selection

    async fetchEventsMGMeetupEvents() {
        // let orgs = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}` : '';
        // if (orgs === '') return
        let meetupGroupId = this.allMeetupGroupsTabsSel.selectedId;
        let eventId = this.eventsSel.selectedId;
        if (meetupGroupId == null || eventId == null) {
            this.eventMGMeetupEventRes = []
            return this.eventMGMeetupEventRes
        }
        ;
        this.eventMGMeetupEventRes = await C3.instance.client.fetcher.fetch(`/meetup-event/mg-event`, { // TODO
            query: [`meetupGroupId=${meetupGroupId}`, `eventId=${eventId}`].filter(v => v != null).join('&'),
        })
        let mappedRes = this.eventMGMeetupEventRes;
        this.eventMGMeetupEventsSel.itemsRef.current = mappedRes;
        // console.log(`this.eventMGMeetupEventsSel.itemsRef.current`, toJS(mappedRes));
        return this.eventMGMeetupEventRes
    }

    async copyEventFromMeetupEvent() {
        let meetupEventIds = toJS(this.allMGMeetupEventsSel.selectedIds);
        console.log(`meetupEventIds`, meetupEventIds);
        if (!meetupEventIds) return
        let res = await C3.instance.client.fetcher.fetch(`/meetup/copy-event`, {
            method: 'POST',
            body: {meetupEventIds}
        })
        return res
    }

    async updateMeetupEventsForSelectedGroup() {
        let groupId = this.allMeetupGroupsTabsSel.selectedId
        let res = await C3.instance.client.fetcher.fetch(`/meetup-ingester/update-meetup-events/${groupId}`, {})
        console.log(`groupId, res`, groupId, res);
        await this.fetchMeetupGroups()
        await this.fetchMeetupEventsForSelMeetupGroup()
        return res
    }



    async updateAssociations(assign = true) {
        let eventId = this.eventsSel.selectedId;
        let sourceSel = this.eventMGMeetupEventsSel;
        // let orgId = [{id: this.orgsSel.selectedId}];
        let targetSel = this.allMGMeetupEventsSel;
        if (assign) {
            sourceSel = this.allMGMeetupEventsSel;
            targetSel = this.eventMGMeetupEventsSel;
        }
        let meetupEventIds = sourceSel.selectedIds;
        if (eventId == null || meetupEventIds.length == 0) return
        await C3.instance.client.fetcher.fetch(`/meetup-event/assign-many`, { // TODO
            method: 'POST',
            body: {
                meetupEventIds: meetupEventIds,
                eventId: eventId,
                assign: assign
            }
        })
        await this.fetchEventsMGMeetupEvents()
        setTimeout(() => {
            sourceSel.clearSelection()
            targetSel.selectIds(meetupEventIds, true, true, true, false)
            this.eventsSel.selectId(eventId, true, true)
            setTimeout(() => {
                this.fetchEventMeetupEventCountMatrix()
            })
        })
    }

    async addRate() {
        await this.updateAssociations(true)
    }

    async removeRate() {
        await this.updateAssociations(false)
    }

    @computed
    get blockAddOrRemove() {
        let productId = this.eventsSel.selectedId;
        let rateSheetId = this.allMeetupGroupsTabsSel.selectedId;

        return productId == null || rateSheetId == null
    }

    @computed
    get canAddRate() {
        if (this.blockAddOrRemove) return false;
        let sourceSel = this.allMGMeetupEventsSel;
        let targetSel = this.eventMGMeetupEventsSel;
        if (sourceSel.selectedIds.length == 0) return false;
        return true
    }

    @computed
    get canRemoveRate() {
        if (this.blockAddOrRemove) return false;

        let sourceSel = this.eventMGMeetupEventsSel;
        let targetSel = this.allMGMeetupEventsSel;
        if (sourceSel.selectedIds.length == 0) return false;

        return true
    }

    focusMGTab(rsId) {
        let refMapElement: HTMLElement = this.refMap[rsId];
        refMapElement.scrollIntoView({behavior: "smooth", block: 'nearest'})
    }

}
