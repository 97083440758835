import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const smallCounterBoxSchema: C3SchemaInfo = {
    'small-counter-box': {
        fields: {
            id: {},
            name: {},
            visitLinePayments: {
                type: 'visit-line-payment',
                multivalued: true,
            }
        }
    }
}
