import React from "react";
import styled from 'styled-components'
import {REEAllProductsGrid} from "./components/REEAllProductsGrid";
import {REEActiveProductsGrid} from "./components/REEActiveProductsGrid";
import {Button} from "@blueprintjs/core";
import {useRFVStore} from "../../ResourceFileView";
import {observer} from "mobx-react";

const Container = styled.div`
  display: grid;
  grid-gap: 8px;
  height: calc(100vh - 100px);
  padding: 8px;
  grid-template-rows: 1fr auto;
`
const ButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 8px;
  place-content: center;
`


const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1.5fr;
  grid-gap: 4px;
`
const HelperContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  width: fit-content;
  justify-self: center;
`
const SpanContainer = styled.span`
    margin: 0px 5px;
`


export interface ProductResourceEditorProps {

}

export const ResourceProductEditor = observer((props: ProductResourceEditorProps) => {
        let store = useRFVStore()
        return <Container>
            <Body>
                <REEActiveProductsGrid/>
                <ButtonsContainer>
                    <Button
                        disabled={!store.canAddProduct}
                        icon={'double-chevron-left'}
                        onClick={() => store.addProduct()}/>
                    <Button
                        disabled={!store.canRemoveProduct}
                        icon={'double-chevron-right'}
                        onClick={() => store.removeProduct()}/>
                </ButtonsContainer>
                <REEAllProductsGrid/>
            </Body>
            <HelperContainer>
            <SpanContainer>
                T.Entrega igual a 1 per entrega el mateix dia
            </SpanContainer> | <SpanContainer>
                T.Entrega 24 per entrega en un dia</SpanContainer> | <SpanContainer> T.Entrega 48 per entrega en dos dies
            </SpanContainer>
            </HelperContainer>
        </Container>
    }
)
