import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useRateFVStore} from "../RateFileView";
import {MasterLayout} from "react-c4/src/app-shells/IATSStyle/layout/master-detail-layout/master-layout/MasterLayout";
import {C3AgGrid} from "react-c4";
import {CellValueChangedEvent, ICellRendererParams, RowNode} from "ag-grid-community";
import {formatFullName, stdBoolColumn, stdIntNumericColumn, stdNumericColumn} from "../../../application/utils/utils";
import {Checkbox, NonIdealState} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    
`

export interface RateFileViewRateGridProps {

}

export const RateFileViewRateGrid = observer((props: RateFileViewRateGridProps) => {
    let store = useRateFVStore()
    if (store.rateSheetsSel.selectedId == null) return <NonIdealState icon={'document'}
                                                                      title={'Selecciona un full de tarifes'}/>

    return <Container>
        <MasterLayout
            renderHeader={() => {
                return <span
                    style={{fontSize: 18}}>{store.rateSheetsSel.selectedItem?.name} {store?.ratesRes?.length >= 0 && `(${store?.ratesRes?.length} barems)`}</span>
            }}
            actions={[
                {
                    name: 'create',
                    iconName: 'document',
                    callback: () => {
                        store.createRate()
                    }
                },
                {
                    name: 'delete',
                    iconName: 'trash',
                    callback: () => {
                        store.deleteSelectedRate()
                    },
                    confirmation: true,
                    confirmationText: 'Està segur que vol esborrar aquest barem?'
                },
                {
                    name: 'showActives',
                    iconName: 'strikethrough',
                    intent: store.showInactiveRates ? "success" : "none",
                    text: store.showInactiveRates? 'Mostra barems actius' : 'Mostra barems inactius',
                    callback: () => {
                        store.showInactiveRates = !store.showInactiveRates
                    }
                }
            ]}
            body={
                <C3AgGrid
                    columnDefs={[
                        {
                            field: 'code',
                            headerName: 'Codi',
                            editable: true,
                            filter: 'agTextColumnFilter',
                        },
                        {
                            field: 'active',
                            headerName: 'Actiu',
                            ...stdBoolColumn(),
                            width: 40
                        },
                        {
                            field: 'name',
                            headerName: 'Nom',
                            editable: true,
                            width: 350,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'price',
                            headerName: 'Preu',
                            editable: true,
                            ...stdNumericColumn(),
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,

                        },
                        {
                            field: 'description',
                            headerName: 'Descripció',
                            editable: true,
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },
                        {
                            field: 'id',
                            headerName: 'Id',
                            editable: false,
                            width: 60,
                            ...stdIntNumericColumn(),
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                        },

                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({name: data.medName, surname1: data.medSurname1, surname2: data.medSurname1})
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    let orgName = !!store.orgsSel.selectedItem.shortName == true ? store.orgsSel.selectedItem.shortName  : '';
                                    let rate = store.rateSheetsSel.selectedItem?.name.replace(' ', '_')
                                    params.api.exportDataAsExcel({
                                        fileName: `llistat-barems-${rate}-${orgName}`,
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'active') {
                                                return data.active == true ? 'X' : ''
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    rowData={store.ratesRes}
                    selection={store.ratesSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        await store.saveRate({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                        await store.fetchRates(store.rateSheetsSel.selectedId)
                    }
                    }

                />
            }
        />
    </Container>
})
