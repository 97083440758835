import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import {Observer, observer} from 'mobx-react'
import {PatientsPageStore} from "./PatientsPageStore";
import {MasterDetailLayout, MasterLayout, Page, useC3Store, C3} from "react-c4";
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {page} from "react-navigation-plane";
import {PatientOverview} from "../../components/PatientOverview/PatientOverview";
import {NonIdealState} from "@blueprintjs/core";
import {CreatePatientFragment} from "./CreatePatientFragment";
import {isDev} from "../../application/utils/utils";

const Container = styled.div`
    display: grid;
    place-items: stretch;
`

export interface PatientsPageProps {
    location
}


export const PatientsPage = page(observer((props: PatientsPageProps) => {
    let store = useC3Store(() => {
        let storeInst = new PatientsPageStore();
        return storeInst
    })
    if (store == null) return null
    return <Page
        title={'Pacients'}
        subtitle={process.env.INSTANCE_TITLE}
        icon={'people'}
        attributes={[
            {name: 'Total', value: store.statistics?.patientCount, unit: 'pacients'}
        ]}
        renderBody={({pageContext}) => {
            return <MasterDetailLayout
                md={store.md}
                renderMasterView={() => {
                    let columnDefs = C3.instance.schema.getFields('patient')
                        .map(f => {
                            return ({
                                field: f.name,
                                headerName: f.label,
                                ...f,
                            });
                        });
                    return <Observer>
                        {() => {
                            return <MasterLayout
                                md={store.md}
                                actions={[
                                    // Set as defaults
                                    {
                                        name: 'refresh',
                                        iconName: 'refresh',
                                        text: 'Refresca',
                                        callback: () => {
                                            store.md.fetchItems()
                                        }
                                    },
                                    // {
                                    //     name: 'open',
                                    //     iconName: 'document-open',
                                    //     text: 'Obre el perfil',
                                    //     enabled: store.md.exactlyOneSelected,
                                    //     callback: () => {
                                    //         pageContext.navigate({
                                    //             to: 'patient',
                                    //             focusNewTab: true,
                                    //             inNewTab: true,
                                    //             args: {entityId: store.md.selection.selectedId}
                                    //         })
                                    //     }
                                    // },
                                    {
                                        name: 'newBooking',
                                        iconName: 'add',
                                        text: 'Cites i visites',
                                        enabled: store.md.exactlyOneSelected && store.md.selection.selectedId != null,
                                        callback: () => {
                                            pageContext.navigate({
                                                to: 'bookVisitPage', args: {
                                                    patientId: store.md.selection.selectedId
                                                },
                                                inNewTab: false, focusNewTab: true
                                            })
                                        }
                                    }, {
                                        name: 'newPrescription',
                                        iconName: 'label',
                                        text: 'Nueva prescripción',
                                        intent: 'primary',
                                        enabled: store.md.exactlyOneSelected && store.md.selection.selectedId != null,
                                        callback: () => {
                                            pageContext.navigate({
                                                to: 'prescriptionPage', args: {
                                                    patientId: store.md.selection.selectedId,
                                                    prescriptionId: null,
                                                    creating: true
                                                },
                                                inNewTab: false, focusNewTab: true
                                            })
                                        }
                                    },
                                    {
                                        name: 'newBookingUsingCalendar',
                                        iconName: 'calendar',
                                        text: 'Afegir cita per calendari',
                                        // intent: 'primary',
                                        enabled: store.md.exactlyOneSelected && store.md.selection.selectedId != null,
                                        callback: () => {
                                            pageContext.navigate({
                                                to: 'schedule', args: {
                                                    patientId: store.md.form.entityId,
                                                    patientData: store.md.form.data
                                                },
                                                inNewTab: false, focusNewTab: true
                                            })
                                        }
                                    },
                                    {
                                        name: 'markAsDuplicate',
                                        iconName: 'duplicate',
                                        text: !!store.isDuplicated == false ? 'Prémer per marcar pacient com duplicat' : 'Prémer per marcar pacient com a no duplicat',
                                        intent: !!store.isDuplicated == false ? 'none' : 'warning',
                                        enabled: store.md.selection.selectedId != null,
                                        callback: () => {
                                            store.saveSelectedPatient()
                                        }
                                    },
                                    {
                                        name: 'create',
                                        iconName: 'document',
                                        text: 'Crea un pacient',
                                        enabled: store.md.canStartCreating,
                                        callback: () => {
                                            store.createPatient()
                                        }
                                    },
                                    // {
                                    //     name: 'create2',
                                    //     iconName: 'document',
                                    //     text: 'Crea un pacient (nou sistema)',
                                    //     intent: 'primary',
                                    //     enabled: store.md.canStartCreating,
                                    //     callback: () => {
                                    //         store.createPatientNewSystem()
                                    //     }
                                    // },
                                    {
                                        name: 'delete',
                                        iconName: 'trash',
                                        text: 'Esborra',
                                        confirmation: true,
                                        confirmationText: 'Estàs segur que vols esborrar el pacient?',
                                        enabled: store.md.exactlyOneSelected && store.md.selection.selectedId != null,
                                        callback: () => store.deleteSelectedPatient()
                                    },
                                ]}
                            >
                                <C3AgGrid
                                    infinite
                                    // defaultColDef={{
                                    //     // filter: true,
                                    //     // floatingFilter: false
                                    // }}
                                    onGridReady={(e) => store.gridRef.current = e.api}
                                    columnDefs={[
                                        // columnDefs[1],
                                        ...columnDefs
                                            .filter(f => !['id', 'cip', 'status', 'state', 'countryCode', 'insCompany', 'visits', 'documents', 'invoices', 'defaultInsCompany', 'defaultPaymentMethod']
                                                .includes(f.name) && !f.multivalued)
                                            .map(cd => ({
                                                filter: 'agTextColumnFilter',
                                                ...cd,
                                                suppressMenu: true,
                                                floatingFilterComponentParams: {suppressFilterButton:true}
                                            }))
                                    ]}
                                    filter={false}
                                    store={store}
                                    md={store.md}
                                    onRowDoubleClicked={({data, event}) => {
                                        if(!store.md.form.data) return
                                        let e = event as MouseEvent;
                                        if(!e.shiftKey){
                                            pageContext.navigate({
                                                to: 'schedule', args: {
                                                    patientId: store.md.selection.selectedId,
                                                    patientData: store.md.form.data
                                                },
                                                inNewTab: e.ctrlKey, focusNewTab: true
                                            })
                                        }else{
                                            pageContext.navigate({
                                                to: 'bookVisitPage',
                                                focusNewTab: true,
                                                inNewTab: e.ctrlKey,
                                                args: {patientId: data?.id}
                                            })
                                        }


                                    }}
                                    rowSelection={'single'}
                                />
                            </MasterLayout>
                        }}
                    </Observer>

                }}
                renderDetailView={() => {
                    // if(store.isCreatingPatientNewSystem){
                    //     return <CreatePatientFragment store={store}/>
                    // }
                    if(!store.md.exactlyOneSelected && !store.md.form.creating)  {
                        return <NonIdealState title={'Seleccioni o crei un pacient'} icon={'person'}/>
                    }
                    return <div>
                        {/*A{JSON.stringify(store.md.selection.selectedIds)}*/}
                        <PatientOverview md={store.md} featureMode={'patient'} pageContext={pageContext}/>
                    </div>;
                }}
            />
        }}
    />
}))
