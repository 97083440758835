import React, {useState, useEffect, useContext, useRef} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useReactToPrint} from "react-to-print";
import * as _ from "lodash";

const Container = styled.div`
    display: grid;
    
`
const Page = styled.div`
    display: grid;

    @media print {
      width: 58.06mm;
      height: 65.96mm;
    }
    @page {
      size: 58.06mm 209.96mm;
      margin: 0;
    }
    font-size: 14px;
    background-color: white;
    font-family: Verdana;
    
    place-items: center;
    grid-auto-flow: row;
    grid-gap: 16px;
    padding: 16px;
    
    border: 2px dotted black;
`;

const NumberContainer = styled.div`
    font-size: 100px;
`
const LogoContainer = styled.div`
    display: grid;
    place-content: center;
`
const TextContainer = styled.div`
    font-size: 18px;
  display: grid;
  align-items: center;
  grid-auto-flow: row;

`

export interface YourTurnSheetProps {
    data?: {
        turnNumber,
        turnRes
    }
    onPrintReady?: (handle) => null
}

export const YourTurnSheet = observer((props: YourTurnSheetProps) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (props.onPrintReady) props.onPrintReady(handlePrint)
    }, [])
    const data = props.data
    return <Page ref={componentRef}>
        <LogoContainer>
            <span>INSTITUTS GUIRADO</span>
        </LogoContainer>
        <NumberContainer>
            {props.data.turnNumber}
        </NumberContainer>
        <TextContainer>
            <span>
                El seu torn
            </span>
            <span>
                {props.data.turnRes?.displayString}
            </span>

        </TextContainer>
    </Page>
})
