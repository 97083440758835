import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ProductFamiliesFVStore} from "../../files-views/ProductFamiliesFileView/ProductFamiliesFVStore";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
`
const ButtonContainer = styled.div`
  padding-left: 5px
`

export interface ProductFamiliesFilePageHeaderProps {
    store: ProductFamiliesFVStore
}

export const ProductFamiliesFilePageHeader = observer((props: ProductFamiliesFilePageHeaderProps) => {
    let store = props.store
    if (store == null) return null
    return <Container>
        <ButtonContainer>
            <Button icon={'refresh'}
                    onClick={async () => {
                        await store.fetch()
                    }}
            />
        </ButtonContainer>
    </Container>
})
