import {AuthStore} from "../../AuthStore";
import {action, computed, observable} from "mobx";
import {C3, MRF} from "react-c4";
import MobxReactForm from "mobx-react-form";
import moment from "moment";
import {AppStore} from "../../../AppStore";


export class LoginFormStore {
    authStore: AuthStore

    @observable
    tmpPin

    loginForm: MRF

    @observable
    step = 0

    @observable
    loginMethod: 'username' | 'pin' = 'pin'

    @observable
    loadingNextStep = false

    tmpUserRes: any


    constructor(authStore: AuthStore) {
        this.authStore = authStore

        this.loginForm = new MobxReactForm({
            fields: [
                'pin',
                'username',
                'password',
            ],
            hooks: {
                submit: (v) => {
                    console.log('Submit', v);
                }
            }
        })
    }

    @computed
    get canGoBack() {
        return this.step > 0
    }

    @computed
    get canUsePin() {
        return true
    }

    @action
    goBack() {
        this.step = Math.max(0, this.step - 1);

    }

    @action
    async goForward() {
        if (this.loginMethod == 'username') {
            if (this.step == 0) {
                // Verify and fetch user
                let res = await this.fetchTmpUser()
                // Ask password
                if (res?.id) this.step++;

            } else if (this.step == 1) {
                // Login
                await this.authStore.loginWithUsername(this.loginForm.$('username').value, this.loginForm.$('password').value)
            }
        } else if (this.loginMethod == "pin") {
            await this.authStore.loginWithPIN(this.loginForm.$('pin').value)
        }
    }

    async fetchTmpUser() {
        let username = this.loginForm.$('username').value;
        let res = await C3.instance.client.fetcher.fetch(`/auth/tmp-user/${username}`, {auth: false})
        this.tmpUserRes = res
        return res
    }




}
