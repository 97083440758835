import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSVVStore} from "../../../../StandardVisitView";
import { MRFField } from "react-c4/src/forms/MRF";
import {Button} from "@blueprintjs/core";

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-gap: 8px;
    
    
`
const VisitSelectorContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 4px;
    padding: 4px;
    background-color: #BFCCD6;
    border-radius: 3px;
    grid-template-rows: 1fr auto;
    align-items: start;
    
`

export interface SVHChooseOneVisitProps {

}

export const SVHChooseOneVisit = observer((props: SVHChooseOneVisitProps) => {
    let store = useSVVStore()
    let sourceVisitIds = store.visitsSel.selectedIds;
    return <Container>
        {sourceVisitIds.map((visitId) => {
            let visitField: MRFField = store.visitsSel.getFieldById(visitId)
            return <VisitSelectorContainer>
               <div>
                   <div style={{fontSize: '21px', fontWeight: "bold"}}>V{visitId}</div>
                   <div style={{fontSize: '16px', fontWeight: "bold"}}>{visitField.$('resource')?.value?.name}</div>
               </div>
                {store.isMainMode && <Button onClick={() => {
                    store.selectVisitOnly(visitId, true)
                }}>Selecciona</Button>}
            </VisitSelectorContainer>
        })}
    </Container>
})
