import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const visitLineSchema: C3SchemaInfo = {
    'visit-line': {
        fields: {
            id: {},
            visit: {
                type: 'visit',
            },
            resource: {
                type: 'resource',
                label: 'Màquina'
            },
            // TODO Add fields
            refExtCenter: {
                type: 'contact',

            },
            refSpecialty: {
                type: 'specialty',

            },
            refMedical: {
                type: 'contact',

            },
            documents: {
                // type: 'document',

            },
            payments: {
                type: 'visit-line-payment',

            },
            price: {
                label: 'Preu'
            },
            invoiceLine: {
                type: 'invoice-line',

            },
            technician: {
                type: 'contact',

            },
        }
    }
}
