import {C3MasterDetail} from "react-c4";

export class InvoicesPageStore {

    invoicesMD: C3MasterDetail

    init() {
        this.invoicesMD = new C3MasterDetail({
            entityType: 'invoice',

        })
        this.invoicesMD.selection.incomplete = true
    }
}
