import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Button, TextArea} from "@blueprintjs/core";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {DateInput} from "@blueprintjs/datetime";
import {ProcessTabLayout} from "../components/ProcessTabLayout";
import { getMomentFormatter } from "react-c4";
import moment from "moment";
import {FitxaBlancaView} from "../../../../FitxaBlancaView/FitxaBlancaView";

const Container = styled.div`
    display: grid;
    width: 100%;
    //justify-content: start;
    grid-gap: 8px;
    
`
const ButtonsContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    grid-gap: 4px;
`

export interface FitxaBlancaProps {
    store: PatientOverviewStore
}

export const FitxaBlanca = observer((props: FitxaBlancaProps) => {
    useEffect(() => {
        props.store.basicInfoCollapsed = true
        props.store.testsCollapsed = true
    }, [])
    return  <ProcessTabLayout title={'Fitxa Blanca de'} buttonsFragment={<React.Fragment>
        <Button onClick={() => props.store.closeAdditionalTab()}>Tanca</Button>
        <Button onClick={() => null} icon={'eye-open'}>Visualitza</Button>
        <Button onClick={() => null} icon={'print'}>Imprimeix</Button>
        <Button onClick={() => null} icon={'floppy-disk'}>Guardar</Button>
    </React.Fragment>}>
        <Container>
            <FitxaBlancaView form={props.store.md?.form}/>

            {/*<div> Data de la prova <DateInput defaultValue={moment().add(2, 'days').toDate()} {...getMomentFormatter('DD/MM/YYYY')}/></div>*/}
            {/*<div> Data d'entrega <DateInput defaultValue={moment().add(2, 'days').toDate()} {...getMomentFormatter('DD/MM/YYYY')}/></div>*/}
            {/*<div> Comentaris adminstratius <TextArea fill={true}/></div>*/}
        </Container>
    </ProcessTabLayout>
})
