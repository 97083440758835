import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {FFVStore} from "./FFVStore";
import {useC3Store} from "react-c4";
import {FFVMain} from "./FFVMain/FFVMain";

const Container = styled.div`
    display: grid;
    height: 100%;
`


export const FFVContext = React.createContext(null as FFVStore)
export const useFFVStore = () => useContext(FFVContext)

export interface FeatureFileViewProps {
    pageContext?
    onStoreReady: (s: FFVStore) => any
}

export const FeatureFileView = observer((props: FeatureFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new FFVStore()
        storeInst.init();
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <FFVContext.Provider value={store}>
        <Container>
            <FFVMain/>
        </Container>
    </FFVContext.Provider>
})
