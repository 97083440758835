import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Invoice, InvoiceProps} from "../Invoice/Invoice";
import {formatFormalName, stdNumericColumn} from "../../application/utils/utils";
import moment from "moment";

const Container = styled.div`
    display: grid;
    
`

export interface InsCompanyInvoiceProps {
    invoice
    onPrintReady
    isPrinting
}

export const InsCompanyInvoice = observer((props: InsCompanyInvoiceProps) => {
    let showAutNumber = (props.invoice.invoiceLines || []).some(a => _.trim(a.authorizationNumber))
    return <Invoice
        isPrinting={props.isPrinting}
        onPrintReady={props.onPrintReady}

        // organizationData={{
        //     company:props.data.organization.name,
        //     companyNif: props.data.organization.cif,
        //     address: props.data.organization.address,
        //     cp: props.data.organization.cif + " "+ props.data.organization.city,
        //     phone: props.data.organization.phone,
        //     mail: props.data.organization.email,
        //     mercantileRegistry: props.data.organization.mercantileRegistry,
        // }}
        // patientData={{
        //     patientName: props.data.patient.name + " " + props.data.patient.surname + " " + props.data.patient.surname2,
        //     patientPolicy: props.data.patient.patientInsCompanies.mutuaNumber,
        //     patientAddress: props.data.patient.address,
        //     patientCp: props.data.patient.postalCode,
        //     patientCity: props.data.patient.city,
        //     patientNif: props.data.patient.nif,
        //     patientCode: "CLPES"+ props.data.patient.id,
        //     patientMail: props.data.patient.email,
        //     patientPhone: props.data.patient.phone,
        // }}
        // invoiceData={{
        //     invoiceNumber: (props.data.invoice.isPrivate?"DIMPRIV":"DIMM") +(new Date(props.data.invoice.invoiceDate).getFullYear().toString().substr(-2))+ props.data.invoice.invoiceNumber,
        //     invoiceDate: props.data.invoice.invoiceDate,
        //     invoicePeriod: "desde "+ getearliest_invoiceline_date(props.data.invoice) +" hasta " + getlatest_invoiceline_date(props.data.invoice),
        //     invoiceCoin: "EUR",
        // }}
        // insCompanyData={{
        //     receiverCompany: props.data.contact.name,
        //     receiverCp: props.data.contact.postalCode,
        //     receiverAddress: props.data.contact.address,
        //     receiverCity: props.data.contact.city,
        //     receiverCif: props.data.contact.nif,
        //     receiverClientCode: "CLDES"+ props.data.contact.nif,
        //     receiverMail: props.data.contact.email,
        //     receiverPhone: props.data.contact.phone,
        // }}
        notes={'??'}
        case={'??'}
        // accountNumber={props.data.bankAccount.iban}
        lineCountMult={showAutNumber ? 0.85 : 1}
        columnDefs={[
            {
                field: 'patient',
                headerName: 'Pacient',
                width: 250,
                valueFormatter: ({data}) => formatFormalName(data)
            },
            (showAutNumber ? {
                field: 'authorizationNumber',
                headerName: 'Autorit.',
                width: 150,
            } : null),
            {
                field: 'date',
                headerName: 'Data',
                width: 90,
                valueFormatter({value, data}) {
                    if (data.text == 'IVA 21%') {
                        return ''
                    }
                    if (value == null) return '(Sense data)'
                    return moment(value).format('DD/MM/YYYY');
                }
            },
            {
                field: 'rate',
                headerName: 'Concepte',
                width: 280,
                valueFormatter({value, data}) {
                    if (data.text == 'IVA 21%') {
                        return data.text
                    }
                    return value;
                }
            },
            {
                field: 'price',
                headerName: 'Total', // €/u
                width: 62,
                ...stdNumericColumn()
            },
            // {
            //     field: 'quantity',
            //     headerName: 'Q',
            //     width: 40,
            //     valueFormatter: ({data}) => '1'
            // }, {
            //     field: 'price',
            //     headerName: 'Total',
            //     width: 75,
            //     ...stdNumericColumn()
            // },
        ].filter(a => a)}
        // invoice={{
        //     lines: [
        //         {
        //             patient: {
        //                 name: 'José',
        //                 surname1: 'Pérez',
        //                 surname2: 'Giménez'
        //             },
        //             date: new Date(),
        //             text: 'AA',
        //         }, {
        //             date: new Date(),
        //             text: 'BB',
        //             quantity: 2,
        //             total: 450
        //         }, {
        //             date: new Date(),
        //             text: 'XCC',
        //             quantity: 25,
        //             total: 243
        //         }
        //     ],
        //     total: 1000
        // }}
        invoice={props.invoice}
        type={'ins-company'}
    />
})
