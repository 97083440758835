import {IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {autorun, observable} from "mobx";
import {SCVStore} from "../ScheduleView/SCVStore";
import moment from "moment";
import {nextAnimationFrame} from "../../application/utils/utils";

export class ARVStore {
    appStore: AppStore
    pageContext

    @observable.shallow
    scvStore: SCVStore

    @observable
    viewMode: 'svv' | 'scv' = 'svv'

    async init() {
        this.appStore = IATSStyleApp.instance.appStore

    }

    setSCVStore(store: SCVStore) {
        this.scvStore = store
        // console.log(`store`, store);
        this.scvStore.isRulesPage = true
        this.scvStore.arvStore = this


        this.scvStore.fetch()
    }

}
