import React, {useState, useEffect, useContext} from "react";
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {PatientOverviewStore} from "./PatientOverviewStore";
import {Button, Tag} from "@blueprintjs/core";
import moment from "moment";
import styled, {css} from 'styled-components'
import {MRF} from "react-c4";


const Container = styled.div`
    display: grid;
    
`


const HeaderContainer = styled.div`
    background-color: #bfccd6;
    
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    grid-gap: 4px;
    padding: 4px;
    align-items: center;
    font-size: 20px;
    
    ${p => p.isLockedIn && css`
      background-color: rgba(15, 153, 96, 0.15);
      color: #0d8050;
    `}
    
`
const NameContainer = styled.div`
   display: grid;
   grid-gap: 4px;
   grid-auto-flow: column;
   grid-template-columns: auto auto 1fr;
`
const InnerNameContainer = styled.div`
    text-transform: uppercase;
`
const HeaderRightContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0px;
    overflow: hidden;
    font-size: 13px;
`
const HeaderButtonsContainer = styled.div`
    display: contents;
`
const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    align-content: start;
    grid-gap: 8px;
    grid-row-gap: 6px;
`
const FooterContainer = styled.div`
    display: grid;
    justify-content: end;
`

export interface PatientOverviewHeaderProps {
    store: PatientOverviewStore
    pageContext
    externalStore?: any
}

export const PatientOverviewHeader = observer((props: PatientOverviewHeaderProps) => {
    let store = props.store;
    let form = store.form;
    const creating = form.isCreating
    let patientForm: MRF = form.form;
    const locked = store.form.isLocked;
    return <HeaderContainer isLockedIn={props.externalStore?.isLockedIn}
                            onClick={() => store.basicInfoCollapsed = !store.basicInfoCollapsed}>
        <NameContainer creating={creating}>
            {creating && <Tag large>Nou pacient</Tag>}
            {(!creating && patientForm.$('id').value == '') && <span>Selecciona o crea un pacient</span>}
            <InnerNameContainer>
                {patientForm.$('name').value} {patientForm.$('surname1').value} {patientForm.$('surname2').value}
            </InnerNameContainer>
            <div/>
        </NameContainer>
        {store.basicInfoCollapsed && <HeaderRightContainer>
            {patientForm.$('historyNumber').value && <div>{patientForm.$('historyNumber').value}</div>}
          <div>{patientForm.$('birthDate').value && moment(patientForm.$('birthDate').value).format('DD/MM/YYYY')}</div>
        </HeaderRightContainer>}
        <HeaderButtonsContainer onClick={e => e.stopPropagation()}>
            <Button icon={store.basicInfoCollapsed ? 'double-chevron-down' : 'double-chevron-up'}
                    onClick={() => store.basicInfoCollapsed = !store.basicInfoCollapsed}/>
            {/*<Button icon={'document-open'} onClick={() => {*/}
            {/*    if(props.pageContext) props.pageContext.navigate({*/}
            {/*        to: 'patient',*/}
            {/*        focusNewTab: true,*/}
            {/*        inNewTab: true,*/}
            {/*        args: {entityId: form.entityId}*/}
            {/*    })*/}
            {/*}}/>*/}
            <Button icon={locked ? 'lock' : 'unlock'} onClick={() => {
                // return store.form.isLocked = !locked;

                if (locked) {
                    store.form.isLocked = false
                } else {
                    store.form.save().then((res) => {
                        if (store.onSavePatient) store.onSavePatient(res)
                        return store.fetchPatientInsCompanies();
                    });
                    store.form.isLocked = true
                }


            }}/>
        </HeaderButtonsContainer>
    </HeaderContainer>
})
