import React from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout, Transfer} from "react-c4";
import {CellValueChangedEvent} from "ag-grid-community";
import {useExtCenterFVStore} from "./ExtCenterFileView";
import {isDev, stdBoolColumn} from "../../application/utils/utils";
import {ECReferrersFV} from './components/ECReferrersFV'
import {ExtCenterEditorFV} from './components/ExtCenterEditorFV'

const Container = styled.div`
  display: grid;
  height: calc(100vh - 100px);
  grid-gap: 8px;
  padding: 8px;
  grid-template-columns: 1fr 2fr;
`
const GridTitle = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
  
`
const GridTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
`
const GridContainer = styled.div`
  display: grid;

`

export interface ExtCenterFVMainProps {

}

export const ExtCenterFVMain = observer((props: ExtCenterFVMainProps) => {
    let store = useExtCenterFVStore()
    return <Container>
        <ExtCenterEditorFV/>
        <ECReferrersFV/>
    </Container>
})
