import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {DateInputControl, Field, SelectControl} from "react-c4/src/index";
import moment from "moment";
import {bindWithAS, formatFullName, isDev, stdCurrencyFormat, useAS} from "../../../../application/utils/utils";
import {Button, Icon, InputGroup, Popover} from "@blueprintjs/core";
import {useICIStore} from "../../InsCompanyInvoicingView";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column: span 3;
`

const ButtonContainer = styled.div`
  width: fit-content;
`

const PopoverContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding: 16px;
  grid-gap: 16px;
`
const NotesPopoverContainer = styled.div`
    display: grid;
    padding: 12px;
    grid-template-rows: auto auto;
    grid-gap: 8px;
`

const LeftContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  align-items: center;
`
const RightContainer = styled.div`
  display: grid;
  justify-content: end;
`

const PopoverButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-auto-flow: column;
`
const InvoiceSearchContainer = styled.div`
  display: grid;
`

const InvoiceCounterSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: auto 0.25fr 2fr auto;
`

export interface ICIInsCompanyBodySecondRowProps {
    as
}

class DataInput extends React.Component {
    render() {
        return null;
    }
}

export const ICIInsCompanyBodySecondRow = observer((props: ICIInsCompanyBodySecondRowProps) => {
    let store = useICIStore();
    let invoice = store.invoiceRes;
    let invoiceForm = store.invoiceForm
    let as = useAS((field, e) => {
        let fieldName = field.path;
        let body = {id: invoiceForm.$('id').value};
        _.set(body, fieldName, invoiceForm.$(fieldName).value)
        let promise = store.savePrivateInvoice(body, false);
    })
    return <Container>
        <LeftContainer>
            <DateInputControl field={invoiceForm.$('invoiceDate')} as={props.as} locked={store.dateLocked}/>
            {store.invoiceStatus == 2 &&
            <span>(facturada {moment(invoiceForm.$('invoiceDate').value).fromNow()})</span>}

            {store.invoiceStatus > 0 && <Button
              disabled={store.visitLinesSel.selectedIds.length == 0}
              onClick={() => {
                  store.removeSelectedLinesFromInvoice()
              }}>
              Treure línies
            </Button>}
            {<Popover
                isOpen={store.addLinesToInvoice}
                content={<PopoverContainer>
                    <div>
                        <div>
                            Indiqui la factura a la qual vol afegir les línies:
                        </div>
                        <InvoiceCounterSelectorContainer>
                            <select name={'invoiceCounter'}
                                    onChange={(event) => {
                                        event.preventDefault()
                                        store.filterForm.$('invoiceCounter').set(event.target.value)
                                    }}>
                                {store.invoiceCounterRes.map((invoiceCounter) => {
                                    return <option value={invoiceCounter.id}>{invoiceCounter.name}</option>
                                })}
                            </select>
                            <InputGroup
                                value={store.filterForm.$('invoiceYear').value}
                                onChange={(event) => {
                                    event.preventDefault()
                                    store.filterForm.$('invoiceYear').set(event.target.value)
                                }}
                                onKeyUp={(event) => {
                                    event.preventDefault()
                                    if (event.key == 'Enter') {
                                        store.findInvoice()
                                    }
                                }}
                                placeholder={'Any factura'}/>
                            <InputGroup
                                onChange={(event) => {
                                    event.preventDefault()
                                    store.filterForm.$('invoiceNumber').set(event.target.value)
                                }}
                                onKeyUp={(event) => {
                                    event.preventDefault()
                                    if (event.key == 'Enter') {
                                        store.findInvoice()
                                    }
                                }}
                                placeholder={'Número de la factura'}
                            >
                            </InputGroup>*
                        </InvoiceCounterSelectorContainer>
                        <span>* Si es una prefactura escriure amb * inclós.</span>
                    </div>
                    {!store.invoiceSearchCheck &&
                    <span style={{justifySelf: 'center'}}>Prémer Enter per buscar factura</span>}
                    {store.invoiceSearchCheck && !!store.invoiceSearchResult?.id == true &&
                    <InvoiceSearchContainer>
                      <div><strong>Total factura abans d'afegir les
                        línies:</strong> {Number.parseFloat(store.invoiceSearchResult?.total).toFixed(2)} €
                      </div>
                      <div><strong>Data
                        de la
                        factura:</strong> {moment(store.invoiceSearchResult?.invoiceDate).format('DD/MM/YYYY')}
                      </div>
                        {store.invoiceSearchResult?.periodStartDate && store.invoiceSearchResult?.periodEndDate &&
                        <div>
                          <strong>Període:</strong> {moment(store.invoiceSearchResult?.periodStartDate).format('DD/MM/YYYY')}-{moment(store.invoiceSearchResult?.periodEndDate).format('DD/MM/YYYY')}
                        </div>}
                    </InvoiceSearchContainer>}
                    {store.invoiceSearchCheck && !!store.invoiceSearchResult?.id == false &&
                    <span>No s'ha trobat cap factura amb aquest número de factura.</span>}
                    <PopoverButtonsContainer>
                        <Button icon={'cross'} onClick={() => {
                            store.resetPopOver()
                        }}>Cancel·la</Button>
                        <Button
                            disabled={!store.canAddLines}
                            icon={'git-merge'} intent={'warning'} onClick={async () => {
                            //await store.mergeSelectedPatients( true)
                            await store.assignLinesToInvoice();
                            store.addLinesToInvoice = false
                        }}>Assignar Linies ({store.visitLinesSel.selectedIds.length}) a la factura seleccionada</Button>
                    </PopoverButtonsContainer>
                </PopoverContainer>}
            >
                <Button
                    onClick={() => {
                        store.isAssigningLinesToInvoice = !store.isAssigningLinesToInvoice
                        if (store.isAssigningLinesToInvoice) {
                            store.addLinesToInvoice = true;
                        } else {
                            store.assigningLinesToInvoiceItem = null
                        }
                    }}>
                    Assignar línies a factura
                </Button>
            </Popover>}
            {store.invoiceStatus > 0 && <Popover
                isOpen={store.editingNotes}
                content={<NotesPopoverContainer>
                    <label>Notes:</label>
                    <textarea
                        style={{width: 142 * 2, height: 100, margin: 1}}
                        {...bindWithAS(invoiceForm.$('notes'), as)} placeholder={'Notes a factura'}/>
                </NotesPopoverContainer>}
            >
                <Button
                    icon={'edit'}
                    active={store.editingNotes}
                    onClick={() => {
                        store.editingNotes = !store.editingNotes
                        store.refresh()
                    }}>
                    Editar notes
                </Button>
            </Popover>}
            {isDev() && store.invoiceStatus == 0 && store.assigningLinesToInvoiceItem &&
            <Button icon={'tick'}
                    intent={'warning'}
                    onClick={async () => {
                        await store.finishAddingLinesToInvoice()
                        store.isAssigningLinesToInvoice = false
                        store.assigningLinesToInvoiceItem = null

                    }}
            >Afegir </Button>}
            {store.canAddIva && <ButtonContainer>
              <Button disabled={!store.invoicesSel.selectedId} onClick={() => {
                  store.addIVALine()
              }}>Afegir IVA</Button>
            </ButtonContainer>}
            {store.invoiceStatus == 0 &&
            <Button active={store.byPatientInvoicing} icon={store.byPatientInvoicing ? 'tick' : null} onClick={() => {
                store.byPatientInvoicing = !store.byPatientInvoicing
            }}>Per pacient</Button>}
            {store.invoiceStatus == 0 && store.visitLinesSel.selectedItems.length > 0 &&
           <div>Total seleccionat: {stdCurrencyFormat(_.sumBy(store.visitLinesSel.selectedItems, (it) => Number(it.price)))} €</div>}
        </LeftContainer>
        <RightContainer>
            {store.invoiceStatus == 2 &&
            <Popover
              isOpen={store.popoverDateLockerCheck}
              content={<PopoverContainer>
                  <InputGroup {...store.filterForm.$('editCode').bind()} leftIcon={'lock'} type={'password'}
                              placeholder={`Codi secret d'edició`}/>
                  <PopoverButtonsContainer>
                      <Button icon={'cross'} onClick={() => {
                          store.popoverDateLockerCheck = false
                          store.filterForm.$('editCode').set('')
                      }}>Cancel·la</Button>
                      <Button icon={'tick'} disabled={store.filterForm.$('editCode').value != '777'}
                              intent={'warning'} onClick={async () => {
                          if (true) {
                              store.dateEditorLocked = true
                              store.popoverDateLockerCheck = false
                              store.filterForm.$('editCode').set('')
                          }
                      }}>Desbloquejar edició</Button>
                  </PopoverButtonsContainer>
              </PopoverContainer>}
            >
              <Button
                icon={store.dateEditorLocked ? 'unlock' : 'lock'}
                intent={'none'}
                onClick={() => {
                    if (store.dateEditorLocked) {
                        store.dateEditorLocked = false;
                    } else {
                        store.dateEditorLocked = false;
                        store.popoverDateLockerCheck = !store.popoverDateLockerCheck;
                    }
                }}
              />
            </Popover>}
        </RightContainer>
    </Container>
})
