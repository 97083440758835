import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const episodeSchema: C3SchemaInfo = {
    'episode': {
        fields: {
            id: {},
            name: {},
            // TODO Add fields
        }
    }
}
