import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {ProductFamiliesFVStore} from "../../files-views/ProductFamiliesFileView/ProductFamiliesFVStore";
import {ProductFamiliesFileView} from "../../files-views/ProductFamiliesFileView/ProductFamiliesFileView";
import {ProductFamiliesFilePageHeader} from "./ProductFamiliesFilePageHeader";


const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface ProductFamiliesFilePageProps {
     pageContext
}

export const ProductFamiliesFilePage = page(observer((props: ProductFamiliesFilePageProps) => {
    let [store, setStore] = useState(null as ProductFamiliesFVStore);

    return <Page
        title={'Famílies de producte'}
        // This page
        icon={'document'}
        attributes={[
            {
                name: 'Famílies',
                value: null,
                unit: 'famílies'
            }
        ]}
        renderCustomHeaderArea={() => {
            return <ProductFamiliesFilePageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <ProductFamiliesFileView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
