import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useIEVStore} from "../InvoiceEditorView";

const Container = styled.div`
    display: grid;
    
`

export interface IEVMainProps {
    pageContext
}

export const IEVMain = observer((props: IEVMainProps) => {
    let store = useIEVStore();
    return <Container>

    </Container>
})
