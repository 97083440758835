import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {RateFVStore} from "./RateFVStore";
import {useC3Store} from "react-c4";
import {RateFVMain} from "./RateFVMain";
import {AnchorButton, Button, Classes, Dialog, FileInput, Tooltip} from "@blueprintjs/core";
import numeral from 'numeral'


import {clampValue} from "@blueprintjs/core/lib/esnext/components/forms/numericInputUtils";
import {ExcelImportDialog} from "../../application/utils/excel-import/ExcelImportDialog";

const Container = styled.div`
  display: grid;

`


export const RateFVContext = React.createContext(null as RateFVStore)
export const useRateFVStore = () => useContext(RateFVContext)

export interface RateFileViewProps {
    pageContext?
    onStoreReady: (s: RateFVStore) => any
}

export const RateFileView = observer((props: RateFileViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new RateFVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <RateFVContext.Provider value={store}>
        <Container>
            <RateFVMain pageContext={props.pageContext}/>
            <ExcelImportDialog isOpen={store.excelDialogOpen} onClose={() => store.excelDialogOpen = false}
                               processResult={async (name, json) => {
                                   await store.processExcelImport(name, json)
                               }}
                               formatString={`(Codi) / Nom / Preu / (Descripció)`}
            />
        </Container>
    </RateFVContext.Provider>
})
