import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4";
import {AppStore} from "../../application/AppStore";
import {autorun, computed, observable} from "mobx";
import {C3Transfer} from "react-c4/src/components/Transfer/C3Transfer";
import MobxReactForm from "mobx-react-form";

export class ExtCenterFVStore {

    appStore: AppStore

    transfer: C3Transfer

    filterForm: MRF

    @observable.shallow
    assignedMedicalsRes

    assignedMedicalsSel: C3Selection

    @observable.shallow
    extCenterRes

    extCenterSel: C3Selection

    @observable.shallow
    orgsRes: any[]
    orgsSel: C3Selection


    @observable
    showInactiveMedicals = false

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.orgsRes = this.appStore.orgs || [];

        this.filterForm = new MobxReactForm({
            fields: [
                'orgs'
            ],
            values: {
                orgs: []
            }
        })

        this.extCenterSel = new C3Selection({
            onSelectionChanged: async (selectedIds: any[], unSelectedIds: any[], isUser) => {
                // await this.fetchAssignedMedicals()

            }
        })
        this.allMedicalsSel = new C3Selection({})
        this.assignedMedicalsSel = new C3Selection({})

        this.orgsSel = new C3Selection(({
            items: this.orgsRes
        }))

        this.transfer = new C3Transfer({
            mainSel: this.extCenterSel,
            assignedSel: this.assignedMedicalsSel,
            allSel: this.allMedicalsSel,
            fetchAssigned: async () => {
                return await this.fetchAssignedMedicals()
            },
            assignSubmitter: async (medicalIds, extCenterId, assign) => {
                await C3.instance.client.fetcher.fetch(`/referrer-matrix/ext-center/assign-many`, {
                    method: 'POST',
                    body: {
                        medicalIds: medicalIds,
                        extCenterId: extCenterId,
                        assign
                    }
                })
            }
        })
        autorun( async ()=>{
            this.orgsRes = this.appStore.orgs;
            if (!this.orgsRes) return
            this.orgsSel.itemsRef.current = this.orgsRes;

            this.orgsSel.selectedIds = [this.orgsRes[0].id];
        })
        autorun ( async ()=> {
            this.filterForm.$('orgs').set(this.orgsSel.selectedItem);
            this.fetch();
            this.fetchAllMedicals()
        })

        await this.fetch()
        await this.fetchAllMedicals()
    }

    async refresh() {
        await this.fetch();
        await this.fetchAllMedicals()
        await this.fetchAssignedMedicals()
    }


    async fetch() {
        const query = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}`: '';
        if (query === '') return
        this.extCenterRes = await C3.instance.client.fetcher.fetch(`/contact/ext-center`, {
            query: [query]
        })
        this.extCenterSel.itemsRef.current = this.extCenterRes;
        return this.extCenterRes
    }

    async createExtCenter() {
        const orgsId = [{id: this.orgsSel.selectedId}]
        await C3.instance.client.fetcher.fetch('/contact/ext-center', {
            method: 'POST',
            body: {name: 'nou centre', orgs:orgsId, isActive: true}
        })
        await this.fetch()
    }

    async deleteExtCenter(extCenterId) {
        await C3.instance.client.fetcher.fetch(`/contact/ext-center/${extCenterId}`, {
            method: 'DELETE',
        })
        await this.fetch()
    }

    async deleteSelectedExtCenter() {
        let selectedId = this.extCenterSel.selectedId;
        console.log(`selectedId`, selectedId);
        console.log(`this.extCenterSel`, this.extCenterSel);
        if (selectedId == null) return
        await this.deleteExtCenter(selectedId)
    }

    async saveExtCenter(extCenter) {
        await C3.instance.client.fetcher.fetch(`/contact/ext-center/${extCenter.id}`, {
            method: 'PATCH',
            body: extCenter
        })
    }


    @observable.shallow
    allMedicalsRes

    allMedicalsSel: C3Selection


    async fetchAllMedicals() {
        const query = this.orgsSel.selectedId ? `orgs=${this.orgsSel.selectedId}`: '';
        if (query === '') return
        this.allMedicalsRes = await C3.instance.client.fetcher.fetch(`/svv/ref-medicals`, {
            query: [query]
        })
        this.allMedicalsSel.itemsRef.current = this.allMedicalsRes;
        return this.allMedicalsRes
    }


    async fetchAssignedMedicals() {
        let extCenterId = this.extCenterSel.selectedId;
        this.assignedMedicalsRes = await C3.instance.client.fetcher.fetch(`/referrer-matrix/ext-center/${extCenterId}/medicals`, {
            query: [`inactive=${this.showInactiveMedicals.toString()}`].filter(v => v).join('&')
        })
        this.assignedMedicalsSel.itemsRef.current = this.assignedMedicalsRes;
        return this.assignedMedicalsRes
    }

}
