import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {useSVVStore} from "../../StandardVisitView";
import {Button, Tag} from "@blueprintjs/core";
import {isDev} from "../../../../application/utils/utils";
import {SVVVisitFooterDocumentsButtons} from "./SVVVisitFooterDocumentsButtons";
import moment from 'moment'

const Container = styled.div`
    display: grid;
    //background: #5c7080;
    //min-height: 100px;
    align-content: start;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto 1fr;
    grid-gap: 8px;
`
const AttachDocumentsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr);
    align-content: start;
    grid-auto-flow: column;
    grid-gap: 4px;
`
const PrintingContainer = styled.div`
    display: grid;
    align-content: start;
    grid-auto-flow: column;
    grid-gap: 4px;
`
const PriceTotalsContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(2, auto);
    align-content: start;
    grid-gap: 4px;
`
const OtherActionsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-content: start;
    grid-gap: 4px;
`

export interface SVVVisitFooterProps {

}

export const SVVVisitFooter = observer((props: SVVVisitFooterProps) => {
    let store = useSVVStore()
    let visit = store.getLocalVisit(store.singleSelVisitId())
    let getPrintButton = (printKeys, name) => {
        let onClick = async (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
            if (store.visitsSel.selectedId == null) return null
            let shiftKey = e.shiftKey;
            // appointment.printings = appointment.printings.filter(s => s != name)
            await Promise.all([store.refetchVisit(store.visitsSel.selectedId), store.refetchVisitLinesForVisits([store.visitsSel.selectedId])])
            await store.printQueue(printKeys, shiftKey)
        };
        if (!!store.patientTurnNumber== true && printKeys == 'yourTurn') {
            return <Tag minimal large intent={'success'} icon={'tick-circle'} rightIcon={'print'}

                        onClick={onClick}>{name}</Tag>
        } else if (!store.isPrintPending(printKeys)){
            return <Tag minimal large intent={'success'} icon={'tick-circle'} rightIcon={'print'}

                        onClick={onClick}>{name}</Tag>
        }
        if ( printKeys == 'yourTurn') {
            return <Button
                disabled={!store.isMainMode || store.visitsSel.selectedId == null || !visit?.confirmationDate || !store.canPrintTurn}
                rightIcon={'print'}
                onClick={onClick}>{name}</Button>
        } else {
            return <Button
                disabled={!store.isMainMode || store.visitsSel.selectedId == null || !visit?.confirmationDate}
                rightIcon={'print'}
                onClick={onClick}>{name}</Button>
        }
    }
    // if (visit == null) return <NonIdealState title={'Selecciona o crea una visita'} icon={'document'}/>
    let isDraft = visit?.isDraft;
    let isVisit = visit?.isVisit;
    let visitText = isVisit ? 'visita' : 'cita'
    return <Container>
        <AttachDocumentsContainer>
            <React.Fragment>
              <SVVVisitFooterDocumentsButtons visit={visit}/>
            </React.Fragment>
        </AttachDocumentsContainer>
        <div/>

        <PrintingContainer>
            {false && isDev() && <Button
              onClick={() => {
                  store.clearSelectedVisit()
              }}>
              Netejar visita
            </Button>}
            {store.visitsSel.selField && (!isDraft || store.visitsSel.selField?.$('cancelledAt')?.value) && <Button
              icon={store.visitsSel.selField.$('cancelledAt').value ? 'reset' : 'disable'}
              active={store.visitsSel.selField.$('cancelledAt').value}
              disabled={!store.visitsSel.selectedId}
              onClick={() => {
                  let cancelledAt = store.visitsSel.selField.$('cancelledAt').value;
                  store.cancelVisit(store.visitsSel.selectedId, !cancelledAt)
              }}
            >{!isDraft && store.visitsSel.selField.$('cancelledAt').value ? `Restaurar ${visitText}` : `Cancel·lar ${visitText}`}</Button>}
            {!isDraft && store.visitsSel.selField && <Button
              icon={store.visitsSel.selField.$('noShow').value ? 'tick' : null}
              active={store.visitsSel.selField.$('noShow').value}
              disabled={!store.visitsSel.selectedId}
              onClick={() => {
                  let cancelledAt = store.visitsSel.selField.$('noShow').value;
                  store.noShowVisit(store.visitsSel.selectedId, !cancelledAt)
              }}
            >{'No vingut'}</Button>}

            {!isDraft && store.visitsSel.selField && <Button
              icon={'phone'}
                // active={store.visitsSel.selField.$('remindedAt').value}
              disabled={!store.visitsSel.selectedId}
              active={store.visitsSel.selField.$('remindedAt').value}
              onClick={() => {
                  store.remindVisit(store.visitsSel.selectedId, !store.visitsSel.selField.$('remindedAt').value)
              }}>
              Recordatori
            </Button>}
            <Button
                active={store.featNewRefererEntry}
                onClick={() => store.featNewRefererEntry = !store.featNewRefererEntry}>
                Nou sistema referents
            </Button>

            {/*<Button icon={'print'}>Torn</Button>*/}
            {/*{store.printItemsPending.join(', ')}*/}
            {getPrintButton(['visitLabel'], 'Etiqueta')}
            {getPrintButton(['fitxaBlanca'], 'F. Blanc')}
            {getPrintButton(['yourTurn'], 'Torn')}
            {getPrintButton(['medicalReceipt'], 'Justificant')}
            {/*{getPrintButton(['fitxaBlanca', 'visitLabel'], 'Imp. Tots')}*/}
            <Button active={store.allRatesMode} onClick={() => store.allRatesMode = !store.allRatesMode}>Tots els
                barems</Button>
        </PrintingContainer>
        {/*<PriceTotalsContainer>*/}
        {/*    <input value={300}/>*/}
        {/*    <input value={300}/>*/}

        {/*</PriceTotalsContainer>*/}
        {/*<OtherActionsContainer>*/}
        {/*    <Button>Facturar privat</Button>*/}
        {/*    <Button>Devolució</Button>*/}
        {/*</OtherActionsContainer>*/}

    </Container>
})
