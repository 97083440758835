import React, {useContext, useEffect} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useC3Store} from "react-c4";
import {MEVStore} from "./MEVStore";
import {MEVMain} from "./MEVMain/MEVMain";

const Container = styled.div`
  display: grid;
  height: 100%;

`


export const MEVContext = React.createContext(null as MEVStore)
export const useMEVStore = () => useContext(MEVContext)

export interface BasicStatisticsViewProps {
    pageContext?
    onStoreReady: (s: MEVStore) => any
}

export const MeetupEventsView = observer((props: BasicStatisticsViewProps) => {
    let store = useC3Store(() => {
        let storeInst = new MEVStore()
        return storeInst
    })
    useEffect(() => {
        if (store) props.onStoreReady(store)
    }, [store])
    if (store == null) return null
    return <MEVContext.Provider value={store}>
        <Container>
            <MEVMain pageContext={props.pageContext}/>
        </Container>
    </MEVContext.Provider>
})
