import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {InputGroup, TextArea} from "@blueprintjs/core";
import {useSVVStore} from "../../../../StandardVisitView/StandardVisitView";
import {bindWithAS, bindWithSave, useAS} from "../../../../../application/utils/utils";

const Container = styled.div`
    display: grid;
    height: 250px;
    padding-right: 36px;
    
`

export interface AdditionalFieldsTabProps {
    store: PatientOverviewStore
}

export const AdditionalFieldsTab = observer((props: AdditionalFieldsTabProps) => {
    let store = props.store
    let as = useAS((field, e) => {
        let fieldName = field.name;
        store.savePatient({id: store.form.entityId, [fieldName]: store.form.$(fieldName).value})
    }, 800)
    return <Container>
        <label style={{height: 120}}>
            Comentaris
            <TextArea fill={true}  {...bindWithAS(store.form.$('comments'), as)} placeholder={'Comentaris'}/>
        </label>
        <label style={{height: 120}}>
            Al·lèrgies
            <TextArea fill={true}  {...bindWithAS(store.form.$('allergies'), as)} placeholder={'Al·lèrgies'}/>
        </label>
    </Container>
})
