import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {InvoicePageProps} from "./InvoicePage";
import moment from "moment";
import {useAS} from "../../application/utils/utils";
import {MRFField} from "react-c4/src/forms/MRF";
import {CellValueChangedEvent} from "ag-grid-community";

const InvoiceGridContainer = styled.div`
    grid-area: InvoiceGrid;
    display: grid;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    font-family: Verdana;
    align-content: start;
    grid-gap: 1px;
    row-gap: 2px;
    grid-auto-flow: row;

`;

const ColHeaderContainer = styled.div`
    background-color: rgb(0,100,100);
     @media print {
       border: 1px solid gray;
     }
    color: white;
    text-align: center;
    text-transform: uppercase;
    padding: 2px;
`
const TextContainer = styled.div`
    overflow: auto;
    word-wrap: break-word;
    word-break: break-word;
`
const RightTextContainer = styled.div`
    justify-self: right;

`;
const CenteredText = styled.div`
    justify-self: center;

`;
const CellContainer = styled.div`
    display: grid;
    place-items: stretch;
    padding-left: 4px;
    padding-right: 4px;
    cursor: default;
    :hover {
        background-color: #ced8de;  
    }
`

export interface InvoiceGridProps {
    props: InvoicePageProps
    cellEditorSubmitterAS?: (e: CellValueChangedEvent) => any
}

function IsNumeric(val) {
    return parseFloat(val) == val;
}

export const InvoiceGrid = observer(({props, cellEditorSubmitterAS}: InvoiceGridProps) => {
    let as = useAS((field: MRFField, e: CellValueChangedEvent) => {
        cellEditorSubmitterAS(e)
    })
    let gtc = props.columnDefs
        .map(cd => `${cd.width}fr`).join(' ')
    return <InvoiceGridContainer style={{gridTemplateColumns: gtc, fontSize: 10}}>
        <React.Fragment>
            {props.columnDefs.map((cd, i) => {
                return <ColHeaderContainer key={i}>{cd.headerName}</ColHeaderContainer>
            })}
        </React.Fragment>
        {_.flatten(props.lines.map(((item, i) => {
            return props.columnDefs.map((cd, i2) => {
                let value = item[cd.field];
                let Comp = TextContainer
                if (IsNumeric(value)) Comp = RightTextContainer
                if (_.isDate(value)) {
                    Comp = CenteredText
                    cd.valueFormatter = cd.valueFormatter ?? (({value}) => moment(value).format('DD/MM/YYYY'))
                }
                let formattedValue = value
                if (cd.valueFormatter)
                    formattedValue = (cd as any).valueFormatter({value, data: item, colDef: cd, context: props} as any)
                return <CellContainer>
                    <Comp
                        contentEditable={cd.editable}
                        suppressContentEditableWarning={true}
                        onInput={(e) => {
                            let newValue = e.currentTarget.innerText;
                            let event = {
                                colDef: cd,
                                event: e,
                                data: item,
                                newValue: newValue,
                                value: value,
                                rowIndex: i,
                                oldValue: value
                            } as CellValueChangedEvent;
                            as.current(null, event)
                        }}
                        key={i * props.columnDefs.length + i2}>{formattedValue}</Comp>
                </CellContainer>
            })
            // return (<React.Fragment>
            //     {showPatientName && <TextContainer>{item.patientName}</TextContainer>}
            //     <TextContainer>{item.auth}</TextContainer>
            //     <TextContainer>{item.date}</TextContainer>
            //     <TextContainer>{item.desc}</TextContainer>
            //     <RightTextContainer>{item.unit}</RightTextContainer>
            //     <RightTextContainer>{item.price}</RightTextContainer>
            // </React.Fragment>);
        })))}
    </InvoiceGridContainer>
})
