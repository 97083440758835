import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {SVVPrivateInvoicePreviewHeader} from "./SVVPrivateInvoicePreviewHeader";
import {useSVVStore} from "../../../StandardVisitView";
import {Invoice} from "../../../../../documents/Invoice/Invoice";
import {PrivateInvoice} from "../../../../../documents/invoice-variants/PrivateInvoice";
import {InsCompanyInvoice} from "../../../../../documents/invoice-variants/InsCompanyInvoice";
import ErrorBoundary from "react-c4/src/app-shells/IATSStyle/page-templates/ErrorBoundary";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: 4px;
`
const ViewerContainer = styled.div`
  //margin: 1px ;
  //overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-scrolling: touch;
  @media screen and (max-width: 1000px) {
    overflow-x: scroll;
  }

`
const SheetContainer = styled.div`
  //padding: -50px;
  margin-left: -20px;
  margin-right: -40px;
`;

export interface SVVPrivateInvoicePreviewProps {

}

export const SVVPrivateInvoicePreview = observer((props: SVVPrivateInvoicePreviewProps) => {
    let store = useSVVStore()
    let padding = `${((store.invoicePreviewZoom / 100) - 1) * 50}%`;
    if (!store.isPrivateInvoicingMode) return null
    let compDocMarginPx = 20
    let mLeft = compDocMarginPx * -1
    let mTop = compDocMarginPx * -1.5
    let scrollbarWidth = 17;
    let mRight = (compDocMarginPx * -1 * 2) + (scrollbarWidth * 2 / 3)
    return <Container>
        <SVVPrivateInvoicePreviewHeader/>
        <ViewerContainer>
            <SheetContainer style={{
                transform: `scale(${store.invoicePreviewZoom / 100})`,
                paddingTop: padding,
                marginLeft: mLeft,
                marginRight: mRight,
                marginTop: mTop
            }}>
                <ErrorBoundary>
                    <PrivateInvoice isPrinting={store.isPrinting} invoice={store.privateInvoiceRes as any} invoiceForm={store.privateInvoiceForm}
                                    onPrintReady={(h) => store.printHandle = h}/>
                </ErrorBoundary>
            </SheetContainer>
        </ViewerContainer>
    </Container>
})
