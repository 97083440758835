import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ProcessTabLayoutProps, ProcessTabLayout} from "./ProcessTabLayout";
import {Button} from "@blueprintjs/core";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {Field, SelectControl, DateInputControl} from "react-c4";
import * as _ from 'lodash'

const Container = styled.div`
    display: grid;
    
`
const AttachButtonContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
`
const FieldsContainer = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, auto);
`

export interface AttachmentProcessTabLayoutProps extends Partial<ProcessTabLayoutProps> {
    store: PatientOverviewStore
    documentType: string

}

export const AttachmentProcessTabLayout = observer((props: AttachmentProcessTabLayoutProps) => {
    let [adj, setAdj] = useState(false)
    return <ProcessTabLayout title={`${props.documentType} de`} buttonsFragment={<React.Fragment>
        <Button onClick={() => props.store.closeAdditionalTab()}>Sortir</Button>
        <Button onClick={() => props.store.closeAdditionalTab()}>Acceptar</Button>
    </React.Fragment>}>
        <Container>
            <AttachButtonContainer>
                <Button icon={!adj ? 'paperclip' : null} disabled={adj} rightIcon={adj ? 'tick-circle' : null}
                        onClick={() => setAdj(true)}>Adjuntar {_.lowerFirst(props.documentType)}</Button>
                {adj && <Button icon={'eye-open'}/>}
                {adj && <Button icon={'trash'} onClick={() => setAdj(false)}/>}
            </AttachButtonContainer>
            <Field name={'date'} c={DateInputControl}/>
            <Field name={'mutua'} c={SelectControl}/>
            <FieldsContainer>
                <Field name={'externalCenter'} c={SelectControl}/>
                <Field name={'referral'} c={SelectControl} props={{renderOption: 'surname1'}}/>
            </FieldsContainer>
        </Container>
    </ProcessTabLayout>
})
