import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useMPPStore} from "../PrescriptionView";

const Container = styled.div`
    display: grid;
    
`

export interface PrescriptionDataViewProps {

}

export const PrescriptionDataView = observer((props: PrescriptionDataViewProps) => {
    let store = useMPPStore()
    // TODO Recreate prescription data view here
    return <Container>

    </Container>
})