import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {C3AgGrid, MasterLayout} from "react-c4/src/index";
import moment from "moment";
import {formatFullName, stdBoolColumn} from "../../../../application/utils/utils";
import {CellValueChangedEvent, RowNode} from "ag-grid-community";
import {useMEVStore} from "../../MeetupEventsView";

const Container = styled.div`
  display: grid;
  height: 100%;

`

export interface MEVMasterProps {

}

export const MEVMasterGrid = observer((props: MEVMasterProps) => {
    let store = useMEVStore()
    let editableCheck = true
    return <Container>
        <MasterLayout
            renderHeader={() => null}
            actions={[
                {
                    name: 'refresh',
                    iconName: 'refresh',
                    text: 'Refrescar grup',
                    callback: () => {
                        store.fetchMeetupGroups()
                    }
                },
                // {
                //     name: 'new',
                //     iconName: 'plus',
                //     text: 'Afegir grup',
                //     callback: () => {
                //         // store.patientInsCompaniesCreating = !store.patientInsCompaniesCreating
                //     }
                // },
                {
                    name: 'pull',
                    iconName: 'import',
                    text: 'Importar grup de Meetup',
                    callback: () => {
                        store.updateMeetupEventsForSelectedGroup()
                    }
                },
                // {
                //     name: 'remove',
                //     iconName: 'trash',
                //     text: 'Treure grup',
                //     callback: () => {
                //         // store.removePatientInsCompany(store.patientInsCompaniesSel.selectedId)
                //     }
                // }
            ]}
            body={
                <C3AgGrid
                    columnDefs={[
                        {
                            field: '',
                            headerName: '',
                            width: 30,
                            checkboxSelection: true,
                            // suppressFilter: true,
                        },
                        {
                            field: 'name',
                            headerName: 'Name',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'urlName',
                            headerName: 'Url Name',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        },
                        {
                            field: 'lastFetchedAt',
                            headerName: 'Last fetched at',
                            filter: 'agTextColumnFilter',
                            floatingFilter: true,
                            width: 95,
                        }


                    ]}
                    processCellCallback={(params) => {
                        let data = (params.node as RowNode).data
                        return formatFullName({
                            name: data.medName,
                            surname1: data.medSurname1,
                            surname2: data.medSurname1
                        })
                    }}
                    getContextMenuItems={(params) => {
                        // let fileName = store.fr
                        var result = [
                            {
                                // custom item
                                name: 'Exporta a Excel',

                                action: function () {
                                    params.api.exportDataAsExcel({
                                        fileName: `llistat-pacients-duplicats`,
                                        processCellCallback: (params) => {
                                            let data = (params.node as RowNode).data
                                            if (params.column.getColId() == 'createdAt') {
                                                return moment(data.createdAt).format('DD/MM/YYYY')
                                            }
                                            return params.value
                                        }
                                    })
                                },
                            }
                        ]
                        return result
                    }}
                    // rowData={store.meetupGroupsRes}
                    selection={store.meetupGroupsSel}
                    cellEditorSubmitter={async (e: CellValueChangedEvent) => {
                        await store.saveMeetupGroup({
                            id: e.data.id,
                            [e.colDef.field]: e.newValue
                        })
                    }}
                    rowSelection={'multiple'}

                />
            }
        />
    </Container>
})