import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Icon} from "@blueprintjs/core";
import {SVHTasksBarTaskIndicator} from "./SVHTasksBarTaskIndicator";

const Container = styled.div`
    display: grid;

    grid-gap: 4px;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    padding: 4px;
    border-radius: 3px;
    background-color: #e1eef7;
    padding: 6px;
    
    
`
const TitleContainer = styled.div`
    display: grid;
    font-size: 15px;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 4px;
    
`
const IndicatorsContainer = styled.div`
    display: flex;
    grid-gap: 4px;
    flex-direction: row;
    justify-content: start;
    
    
`
const IconContainer = styled.div`
    grid-row: span 2;
    display: grid;
    place-items: center;
    color: #30404d;
`

export interface SVHTasksBarTaskProps {
    task
}

export const SVHTasksBarTask = observer((props: SVHTasksBarTaskProps) => {
    let task = props.task
    let icon: any = 'circle' // || 'warning';
    return <Container>
        {/*<IconContainer>*/}
        {/*    /!*<Icon icon={icon} iconSize={45}/>*!/*/}
        {/*</IconContainer>*/}
        <TitleContainer>
            {task.name}
        </TitleContainer>
        {task.indicators && <IndicatorsContainer>
            {task.indicators
                .map(ind => <SVHTasksBarTaskIndicator inidicator={ind}/>)}
        </IndicatorsContainer>}
    </Container>
})
