import {FeatureFilter} from "./FeatureFilter";
import {computed} from "mobx";
import {isDev} from "../../utils/utils";

export class FeatureDecisions {
    ff: FeatureFilter

    //set Add settings object here

    constructor(ff: FeatureFilter) {
        this.ff = ff;
    }

    canSeePage(pageName) {
        // return true
        return this.ff.hasFeature(pageName)
    }


    showFilesButton() {
        // return true
        let hasFeatureFilesButton = this.ff.hasFeature('filesButton');
        // console.log(`hasFeatureFilesButton`, hasFeatureFilesButton);
        return hasFeatureFilesButton
    }

    showTrayAlerts() {
        return true
    }

    showTrayContrastAlerts() {
        return this.ff.hasFeature('contrastAlerts')
    }

    headsUpContrastAlerts() {
        return this.ff.hasFeature('contrastAlerts')
    }

    showTrayOtherAlerts() {
        return true
    }

    useNewPerLineDeliveryDate() {
        return true
    }

    rscLinkedByDefault() {
        // Activar a DIMAT per config o similar
        return false // TODO set linked to true later when associations are made
    }

    canUseDangerousFeatures(){
        return this.ff.hasFeature('dangerousFeatures')
    }

    canEditLockedInvoiceLines(){
        return this.ff.hasFeature('editLockedInvoiceLines')
    }


    logSSE() {
        return false
    }

    logSSEEvents() {
        return true
    }

    // Can also decide features based on static and user config
}
