import React, {forwardRef, useImperativeHandle} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid, MasterLayout} from "react-c4";
import {useSVVStore} from "../../../StandardVisitView";
import {AgGridColumnProps} from "ag-grid-react";
import {SVVLinesGridVisitCR} from "./components/SVVLinesGridVisitCR";
import {Button, Icon, Popover} from "@blueprintjs/core";
import * as _ from 'lodash'
import {
    CellDoubleClickedEvent,
    CellValueChangedEvent,
    ColDef,
    ICellRendererParams,
    IRichCellEditorParams, ITooltipParams,
    ValueFormatterParams, ValueGetterParams
} from "ag-grid-community";
//import 'ag-grid-autocomplete-editor/main.css';
import {getPaymentsCells} from "./payments";
import {dec, formatFormalName, isDev, latMap, stdDateColumn} from "../../../../../application/utils/utils";
import {invoiceNumberCell} from "../../SVVPivateInvoicing/utils/invoiceNumberCell";
import moment from "moment";
import {GridButton} from "../../../../../application/utils/components/GridButton";
import {SVVLinesGridReferrerCE} from "./components/SVVLinesGridReferrerCE";
import {SVVLinesGridDeliveryCE} from "./components/SVVLinesGridDeliveryCE";
import {DateTimeCE} from "../../../../../application/utils/components/DateTimeCE";
import {SVVLinesGridDeliveryCR} from "./components/SVVLinesGridDeliveryCR";
import {SelectCE} from "../../../../../application/utils/components/SelectCE";

const Container = styled.div`
  display: grid;

`
const ToolbarContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-auto-flow: column;
`

const ConfirmTasksWarnContainer = styled.div`
  padding: 8px;
  display: grid;
  height: 100%;
  //align-content: start;
  place-content: start;
  place-items: start;
`
const PopoverContainer = styled.div`
  display: grid;
  padding: 15px;
  border-radius: 15px;
`

const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  justify-content: space-around;
`
const SpanContainer = styled.span`
    font-size: 16px;
  padding: 10px;
`

export interface SVVLinesGridProps {
    divRef?
}

export const SVVLinesGrid = observer((props: SVVLinesGridProps) => {
    let store = useSVVStore()
    // if(!store.initialized) return <Spinner size={150}/>;
    // console.log(`store.visitLinesMD`, store.visitLinesMD);
    // let visits = store.form.$('visits').value
    // let totalResources = store.genMD.form.options['totalResources'].itemsRef?.current;
    // let resourceStrings = totalResources?.map(t => t.name);
    let errors = store.errorsToConfirmVisit(store.singleSelVisitId());

    function createShowCellRenderer(): any {
        function ShowCellRenderer() {
        }

        ShowCellRenderer.prototype.init = function (params) {
            var cellBlank = !params.value;
            if (cellBlank) {
                return null;
            }
            this.ui = document.createElement('div');
            this.ui.innerHTML =
                '<div class="show-name">' +
                'RX-3' +
                '' +
                '</div>' +
                '<div class="show-presenter">' +
                'AAAAAA' +
                '</div>';
        };
        ShowCellRenderer.prototype.getGui = function () {
            return this.ui;
        };
        return ShowCellRenderer;
    }

    // @ts-ignore
    let paymentsCells = getPaymentsCells(store, 'payment.active.');
    let randomAttachmentCR = (params) => {
        return null
        if (Math.random() > 0.2) return true
        return <Icon icon={'paperclip'}/>
    };
    let statuses = {
        unassigned: {
            label: 'Pend. Assignar',
            color: '#78d1d6'
        },
        assigned: {
            label: 'Assignat',
            color: '#F49025',
            // icon: 'user'
        },
        reported: {
            label: 'Informat',
            color: '#42BF57',
            icon: 'document'
        },
        rejected: {
            label: 'Rebutjat',
            color: '#F3441C',
            icon: 'rotate-document'
        },
    }
    let columnDefs: (AgGridColumnProps | any)[] = [
        {
            field: 'visit',
            headerName: 'Visita',
            width: 100,
            cellRenderer: 'showCellRendererFr',
            cellRendererParams: {
                store
            },
            editable: false,
            rowSpan: function (params) {
                let visitId = params.data.visit?.id;
                if (visitId == null) return 0;
                let visit = store.getLocalVisit(visitId)
                return params.data.index == 1 ? visit?.visitLines?.length : 1
            },
            pinned: 'left',
            resizable: false
        },
        {
            field: '',
            headerName: '',
            width: 30,
            checkboxSelection: true, //({data}) => store.visitLinesSel.isSelectable(data.id) ,
            suppressFilter: true,
            editable: false,
            pinned: 'left'
        },
        // {
        //     field: 'date',
        //     headerName: 'Data',
        //     valueFormatter: ({value}) => {
        //         if (!_.isDate(value)) return '--';
        //         return moment(value).format('DD/MM/YY')
        //     },
        //     width: 80
        //
        // },
        // {
        //     field: 'date',
        //     headerName: 'Hora',
        //     valueFormatter: ({value}) => {
        //         if (!_.isDate(value)) return '--';
        //         return moment(value).format('HH:mm')
        //     },
        //     width: 60
        //
        // },
        {
            field: 'product',
            headerName: 'Prova',
            cellEditorFramework: SelectCE,
            cellEditorParams: (params: IRichCellEditorParams) => {
                let visitId = params.data.visit.id;
                let visit = store.getLocalVisit(visitId);
                let resourceId = visit.resource?.id;
                let resourceProductMapElement = store.resourceProductMap[resourceId];
                return {
                    values: resourceProductMapElement || [],
                    formatValue: (params) => {
                        return params?.name
                    },
                }
            },
            // TODO Add CR
            cellRendererFramework: observer(forwardRef((params: ICellRendererParams, ref) => {
                useImperativeHandle(ref, () => {
                    return {
                        // refresh: (params) => {
                        //     return true;
                        // }
                    };
                })
                let visitLineId = params.data.id


                return <div style={{
                    display: 'grid', gridTemplateColumns: '1fr', gridAutoFlow: 'column',
                    columnGap: 4, alignItems: "center"
                }}>
                    <span style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>{params.valueFormatted}</span>
                    {params.valueFormatted && (params.value?.preparation || true) &&
                    <Popover content={<div style={{padding: 8, minWidth: 300}}>
                        <div style={{fontWeight: "bold"}}>PREPARACIÓ {params.valueFormatted}:</div>
                        <div style={{minHeight: 60}}>{params.value.preparation}</div>
                    </div>}>
                      <span style={{color: 'gray'}}>
                      <Icon icon={'label'}/>
                    </span>
                    </Popover>}
                </div>
            })),
            valueFormatter: (params: ValueFormatterParams) => {
                return params.value?.name
            },
            // valueSetter: (params: ValueSetterParams) => {
            //     console.log(`params.newValue`, params.newValue);
            //     params.node.setDataValue('duration', params.newValue.duration)
            //     params.node.setDataValue('product', params.newValue)
            // },


            width: 212,
            pinned: 'left',
            editable: ({data}) => !data.invoiceNumber

        },
        // {
        //     field: 'quantity',
        //     headerName: 'Q.',
        //     width: 50,
        //     valueGetter: () => 1,
        // },

        {
            field: 'duration',
            headerName: 'min',
            width: 60,
            valueFormatter: (params: ValueFormatterParams) => {
                let value = params?.value;
                return value ? `${value}'` : '';
            }
        },

        {
            field: 'refExtCenter',
            headerName: 'Centre referent',
            width: 120,
            resizable: true,

            cellEditor: !store.featNewRefererEntry ? 'agRichSelectCellEditor' : 'referrerCellRendererFr',
            // cellEditor: 'referrerCellRendererFr',
            cellEditorParams: (params: IRichCellEditorParams) => {
                return {
                    values: store.allExtCenters || [],
                    formatValue: (params) => {
                        return params?.name
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return params.value?.name
            },
            // valueSetter: (params: ValueSetterParams) => {
            //     console.log(`params.newValue`, params.newValue);
            //     params.node.setDataValue('refExtCenter', {id: params.newValue.id, name: params.newValue.name})
            //     params.node.setDataValue('refMedical', null)
            //     return true
            //     return params.newValue == params.oldValue
            // },
        }, {
            field: 'refMedical',
            headerName: 'Referent',
            cellEditor: !store.featNewRefererEntry ? 'agRichSelectCellEditor' : 'referrerCellRendererFr',
            // cellEditor: 'referrerCellRendererFr',
            width: 120,
            resizable: true,
            cellEditorParams: (params: IRichCellEditorParams) => {
                let visitId = params.data.visit.id;
                let visit = store.getLocalVisit(visitId);
                let refExtCenterId = params.data.refExtCenter?.id;
                let refExtCenterToRefMedicalMapElement = store.refExtCenterToRefMedicalMap[refExtCenterId];
                return {
                    values: refExtCenterToRefMedicalMapElement || [],
                    formatValue: (params) => {
                        let e = params;
                        return formatFormalName(e)
                    },
                }
            },
            cellRendererFramework: observer(forwardRef((params: ICellRendererParams, ref) => {
                useImperativeHandle(ref, () => {
                    return {
                        // refresh: (params) => {
                        //     return true;
                        // }
                    };
                })
                let visitLineId = params.data.id

                return <div style={{
                    display: 'grid', gridTemplateColumns: '1fr', gridAutoFlow: 'column',
                    columnGap: 4, alignItems: "center"
                }}>
                    <span style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>{params.valueFormatted}</span>
                    {params.valueFormatted && params.value?.protocol &&
                    <Popover content={<div style={{padding: 8, minWidth: 300}}>
                        <div style={{fontWeight: "bold"}}>PROTOCOL {params.valueFormatted}:</div>
                        <div style={{minHeight: 60}}>
                            <div>
                                {params.value.protocol}
                            </div>
                            <div style={{color: 'gray'}}>
                                {params.value.comment}
                            </div>
                        </div>
                    </div>}>
                      <span style={{color: 'gray'}}
                            onClick={() => store.refetchVisitLinesRateOptions(visitLineId)}
                      >
                      <Icon icon={'label'}/>
                    </span>
                    </Popover>}
                </div>
            })),
            valueFormatter: (params: ValueFormatterParams) => {
                let e = params?.value;
                return formatFormalName(e)
            },
            processCellCallback: (params) => {
                let e = params?.value;
                return formatFormalName(e)
            },


        },
        ...(dec().useNewPerLineDeliveryDate() ? [{
            headerName: 'E. Prevista',
            field: 'deliveryDate',
            cellEditor: 'deliveryCellEditorFr',
            resizable: true,
            ...stdDateColumn(true),
        } as ColDef,
            {
                headerName: 'D. Entregat',
                field: 'deliveredDate',
                cellEditor: 'deliveryCellEditorFr',
                cellRenderer: 'deliveryCellRendererFr',
                resizable: true,
                ...stdDateColumn(true),
            } as ColDef] : []),
        {
            field: 'invoiceNumber',
            headerName: 'N. Factura',
            ...invoiceNumberCell('invoiceNumber', true),
            width: 74,
            editable: false,
            onCellDoubleClicked: (e: CellDoubleClickedEvent) => {
                store.goToInvoice(e.data.invoiceId)
            }
        },
        // {
        //     field: 'refMedical',
        //     headerName: 'Referent 2',
        //     cellEditor: AutocompleteSelectCellEditor,
        //     width: 94,
        //     cellEditorParams: (params: IRichCellEditorParams) => {
        //         let visitId = params.data.visit.id;
        //         let visit = store.getLocalVisit(visitId);
        //         let refExtCenterId = params.data.refExtCenter?.id;
        //         let refExtCenterToRefMedicalMapElement = store.refExtCenterToRefMedicalMap[refExtCenterId];
        //         console.log(`refExtCenterId, refExtCenterToRefMedicalMapElement`, visit, refExtCenterId,
        // refExtCenterToRefMedicalMapElement); return { selectData: refExtCenterToRefMedicalMapElement.map(e =>
        // ({value: e.id, label: e.name})) || [], formatValue: (params) => { return params?.fullName || params?.name },
        //  } }, valueSetter: (params: ValueSetterParams) => { let e = params.newValue
        // params.node.setDataValue('refMedical', {id: e.value, name: e.label}) return true }, valueFormatter: (params:
        // ValueFormatterParams) => { return params.value?.fullName || params.value?.name }, },

        // {
        //     field: 'paymentMethodOverview',
        //     headerName: 'M. Pagament',
        //     valueGetter: ({data}) => {
        //         // let $payments: MRFField = store.visitLinesSel.getFieldById(data.id).$('payments');
        //         // let map = $payments.map(p => p.value.amount);
        //         // return _.sum(map)
        //     },
        //     // Opens in other grid
        //     // Displays M. Pagament + Status
        //     width: 94
        // },
        ...paymentsCells.columns,

        {
            headerName: 'Informe',
            field: 'teleraStatus',
            cellRendererFramework: (params: ICellRendererParams) => {
                if (params.value == null) return null

                let status = statuses[params.value]
                if (status == null) return params.value
                // if (params.value == 'unassigned') return 'No assignat'
                // if (params.value == 'assigned') return 'No assignat'
                return <div
                    style={{display: "grid", gridTemplateColumns: 'auto 1fr', gap: 4, alignItems: "center"}}>
                    {status.icon &&
                    <Icon
                      icon={status.icon}
                      color={status.icon}
                      onClick={() => {
                          store.getDocumentFromWorklist(params.data.id)
                      }}
                    />}
                    <span style={{
                        // fontWeight: 'bold',
                        // color: status.color
                    }}>{status.label}</span>
                </div>
            },
            tooltipValueGetter: (params: ITooltipParams) => {
                let status = statuses[params.value]
                if (status == null) return null
                let dateStr = moment(params.data.teleraStatusDate).format('DD/MM')
                let timeStr = moment(params.data.teleraStatusDate).format('HH:mm')
                return `${status.label} el ${dateStr} a les ${timeStr}`
            },
            editable: false,
            width: 120
        } as ColDef,
        // {
        //     headerName: 'CI',
        //     name: 'report',
        //     cellRendererFramework: randomAttachmentCR,
        //
        //     width: 100
        // }, {
        //     headerName: 'Petició',
        //     name: 'report',
        //     cellRendererFramework: randomAttachmentCR,
        //
        //     width: 100
        // }, {
        //     headerName: 'Autorització',
        //     cellRendererFramework: randomAttachmentCR,
        //
        //     name: 'report',
        //     width: 100
        // }, {
        //     headerName: 'Volant',
        //     cellRendererFramework: randomAttachmentCR,
        //
        //     name: 'report',
        //     width: 100
        // },
        {
            field: 'worklistDate',
            headerName: 'Worklist',
            cellRendererFramework: (params: ICellRendererParams) => {
                return <div
                    style={{
                        display: "grid", gridTemplateColumns: 'auto 1fr', gap: 4, alignItems: "center",
                        cursor: "pointer"
                    }}
                    onClick={() => {
                        store.sendVisitLineToWorklist(params.data.id)
                    }}
                >
                    <Icon icon={params.value ? 'refresh' : 'arrow-right'} color={'#8cb6de'}/>
                    {params.value ? moment(params.value).format('DD/MM/YY HH:mm') : 'Envia'}
                </div>
            },

            editable: false,

            width: 130
        },
        {
            field: 'laterality',
            headerName: 'Lat',
            // Clip / combo
            width: 60,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params: IRichCellEditorParams) => {
                let visitId = params.data.visit.id;
                let visit = store.getLocalVisit(visitId);
                let resourceId = visit.resource?.id;
                let resourceProductMapElement = store.resourceProductMap[resourceId];

                return {
                    values: [
                        'null',
                        'l',
                        'r',
                        'c'
                    ],
                    // values: [
                    //     {id: null, name: '-'},
                    //     {id: 'l', name: 'Esq'},
                    //     {id: 'r', name: 'Drt'},
                    // ],
                    formatValue: (params) => {
                        if (_.isEmpty(params)) return '-'
                        // console.log(`params, params?.name`, params, params?.name);
                        return latMap[params]
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                if (_.isEmpty(params.value)) return '-'
                return latMap[params.value]
            },

        },
        {
            field: 'requestNumber',
            headerName: 'N. Petició',
            width: 94
        },
        // {
        //     field: 'businessLine',
        //     headerName: 'L. Negoci',
        //     cellEditor: 'agRichSelectCellEditor',
        //     cellEditorParams: (params: IRichCellEditorParams) => {
        //         let visitId = params.data.visit.id;
        //         let visit = store.getLocalVisit(visitId);
        //         let resourceId = visit.resource?.id;
        //         return {
        //             values: store.businessLinesDS.items || [],
        //             formatValue: (params) => {
        //                 return params?.name
        //             },
        //         }
        //     },
        //     valueFormatter: (params: ValueFormatterParams) => {
        //         return params.value?.name
        //     },
        //     width: 94
        // },
        {
            headerName: 'Tècnic',
            cellRendererFramework: randomAttachmentCR,

            name: 'technician',
            width: 100,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: (params: IRichCellEditorParams) => {
                return {
                    values: store.allTechnicians || [],
                    formatValue: (params) => {
                        return formatFormalName(params)
                    },
                }
            },
            valueFormatter: (params: ValueFormatterParams) => {
                return formatFormalName(params)
            },
        },
    ].filter(cd => {
        if (store.mainGridCollapsed)
            return !["referral", "referralCenter", "businessLine", "paymentMethodOverview"].includes(cd.field)
        return true
    });
    // @ts-ignore
    // let visitLines = store.form.$('visitLines').value;
    // let visitLines = store.svvRes?.visitLines;
    // if(store.isPrivateInvoicingMode) visitLines = visitLines.filter(visitLines => vl.)
    let grid = <C3AgGrid
        // disableStaticMarkup={true}
        // rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.DeepValueCheck}
        // md={store.visitLinesMD}
        onGridReady={({api}) => store.vlGridApi = api}
        selection={store.visitLinesSel}
        // asyncSelectionIn
        // noRowData
        floatingFilter={false}
        defaultColDef={{editable: true, suppressMenu: true, resizable: true}}
        columnDefs={columnDefs}
        components={{showCellRenderer: createShowCellRenderer()}}
        frameworkComponents={{
            showCellRendererFr: SVVLinesGridVisitCR,
            referrerCellRendererFr: SVVLinesGridReferrerCE,
            deliveryCellEditorFr: SVVLinesGridDeliveryCE,
            deliveryCellRendererFr: SVVLinesGridDeliveryCR,
            dateTimeCE: DateTimeCE,
        }}
        suppressRowClickSelection={true}
        // rowData={store.initialized ? visitLines : null}
        getRowNodeId={(data) => data.id}
        rowMultiSelectWithClick={true}
        deltaRowDataMode={true}
        rowBuffer={22}
        // domLayout={'autoHeight'}
        // colResizeDefault={true}
        suppressScrollOnNewData={true}
        suppressRowTransform={true}
        debounceVerticalScrollbar={true}
        // suppressAutoSize={true}
        cellEditorSubmitter={async (e: CellValueChangedEvent) => {
            // await new Promise((resolve, reject) => setTimeout(resolve, 1000))
            // await new Promise(r => setTimeout(r, 1000));
            // On update
            let visitLineId = e.data.id;
            let fieldName = e.colDef.field;
            let refetch = false;
            let refetchVisit = false;
            let refetchVisitLinesRateOptions = false;
            let refetchInvoice = false;
            if (store.featNewRefererEntry && (fieldName == 'refExtCenter' || fieldName == 'refMedical')) {
                // Saved by dialog
                return
            }
            if (fieldName == 'refExtCenter' && !store.featNewRefererEntry) {
                e.node.setDataValue('refMedical', null)
            }
            // if (fieldName == 'product') e.node.setDataValue('duration', e.newValue?.duration)
            let ceslResult = paymentsCells.cellEditorSubmitterLogic(e);
            refetch ||= ceslResult.refetch;
            refetchVisitLinesRateOptions ||= ceslResult.refetchVisitLinesRateOptions;

            let base = {id: visitLineId, visit: {id: e.data.visit.id}};
            _.set(base, fieldName, e.newValue)
            if (ceslResult.savePrice) _.set(base, 'payment.active.price', e.newValue?.price)

            let newVar: any;
            let refetchFields = ['duration', 'product', 'rate']
            let refetchVisitFields = ['duration', 'product']
            refetch ||= refetchFields.includes(fieldName);
            refetchVisit ||= refetchVisitFields.includes(fieldName);
            let refetchVisitLinesRateOptionsFields = ['product']
            refetchVisitLinesRateOptions ||= refetchVisitLinesRateOptionsFields.includes(fieldName);
            if (store.isPrivateInvoicingMode) {
                refetchInvoice = true;
                // setTimeout(, 300)
            }
            if (e.newValue) newVar = await store.saveVisitLine(base, refetch);
            if (refetchVisit) await store.refetchVisit(e.data.visit.id)
            let actions = []
            // if (refetch) actions.push(() => store.refetchVisitLine(visitLineId))
            if (refetchVisitLinesRateOptions) actions.push(() => store.refetchVisitLinesRateOptions(visitLineId))
            if (refetchInvoice) actions.push(() => store.fetchInvoiceIntoForm())
            await Promise.all(actions.map(a => a()))
            return newVar
        }}
        // onSelectionChanged={({api}) => {
        //     api.getSelectedNodes().forEach(sn => store.visitLinesSelKey = sn.rowIndex)
        // }}
    />;
    // if (!store.initialized) return <div/>;
    return <MasterLayout
        divRef={props.divRef}
        md={store.visitLinesMD}
        // actions={[
        //     {
        //         name: 'collapse',
        //         iconName: 'double-chevron-left',
        //         text: 'Colapsar',
        //         callback: () => {
        //             store.mainGridCollapsed = !store.mainGridCollapsed
        //         }
        //     },
        //     {
        //         name: 'new',
        //         iconName: 'plus',
        //         text: 'Afegir línia',
        //         callback: (e) => {
        //             // store.form.$('visitLines').onAdd(e)
        //             store.addVisitLine()
        //
        //         }
        //     },
        //     {
        //         name: 'delete',
        //         iconName: 'trash',
        //         text: 'Eliminar línia',
        //         callback: (e) => {
        //             store.visitLinesSel.selFields.forEach(sf => sf.onDel(e))
        //             // store.$selVisitLine?.onDel(e)
        //         }
        //     }, {
        //         name: 'collapse',
        //         iconName: 'refresh',
        //         text: 'Recalcula visites',
        //         callback: () => {
        //             store.recalcVisits()
        //         }
        //     },
        //     {
        //         name: 'new',
        //         iconName: 'plus',
        //         text: 'Crear visita',
        //         callback: (e) => {
        //             // store.form.$('visitLines').onAdd(e)
        //             store.addVisitLine()
        //
        //         }
        //     },
        //
        // ]}

        renderHeader={() => {
            // let amount = 2;
            // let moment1 = moment(store.visitsSel.selectedItem?.appointmentDate);
            // if (moment1.weekday() == 3) amount = 4
            // if (moment1.weekday() == 4) amount = 4
            // let dateS = moment1.add(amount, 'days').format('DD/MM/YYYY');
            let visitField = store.visitsSel.selField;
            let dateS = visitField && moment(visitField.$('deliveryDate').value).format('DD/MM/YYYY')
            let visit = store.getLocalVisit(store.singleSelVisitId())
            // if (visit == null) return <NonIdealState title={'Selecciona o crea una visita'} icon={'document'}/>
            let isDraft = visit?.isDraft;
            let isVisit = visit?.isVisit;
            let documentsWarning = !isDraft && !visit?.documents;

            return <ToolbarContainer>
                <Button
                    rightIcon={'plus'}
                    intent={'primary'}
                    disabled={!store.isMainMode}
                    onClick={() => store.addVisit()}>Cita</Button>

                <Popover
                    // style={{width:"100%"}}
                    fill={true}
                    onClose={() => store.closingDeleteVisitPromptHandler(false)}
                    isOpen={store.isDeleteVisitPopoverOpen}
                    content=
                        {<PopoverContainer>
                            <SpanContainer>Està segur que vol esborrar la
                                cita <strong>{visit?.id}</strong>?</SpanContainer>
                            <ButtonContainer>
                                <Button
                                    intent={'warning'}
                                    icon={'trash'}
                                    onClick={() => {
                                        store.removeSelVisit()
                                        store.closingDeleteVisitPromptHandler(false)
                                    }}>Esborrar</Button>
                                <Button
                                    icon={'cross'}
                                    onClick={() => {
                                        store.closingDeleteVisitPromptHandler(false)

                                    }}>Cancel·lar</Button>
                            </ButtonContainer>
                        </PopoverContainer>}>
                    <Button
                        style={{width: "100%"}}
                        rightIcon={'trash'}
                        intent={'primary'}
                        disabled={!store.visitsSel.selectedId || !store.isMainMode}
                        onClick={() => {
                            store.closingDeleteVisitPromptHandler(true)
                        }}>Cita</Button>
                </Popover>
                <Button
                    rightIcon={'plus'}
                    disabled={!store.visitsSel.selectedId || !store.isMainMode}
                    onClick={() => store.addVisitLine()}>Línia</Button>
                <Button
                    rightIcon={'trash'}
                    disabled={(!store.visitLinesSel.selectedId) || store.visitLinesSel.selectedItem?.index == 1 || !store.isMainMode}
                    onClick={() => store.removeSelVisitLine()}>Línia</Button>
                {isDev() && <Button
                    rightIcon={'duplicate'}
                    disabled={(!store.visitLinesSel.selectedId) || !store.isMainMode}
                    onClick={() => store.duplicateSelVisitLine()}>Línia</Button>}

                {errors.length == 0 ?
                    (store.canConfirmSelectedVisit && <Button icon={'tick-circle'}
                                                              disabled={!store.canConfirmSelectedVisit}
                                                              intent={store.canConfirmSelectedVisit ? 'warning' : 'none'}
                                                              onClick={() => {
                                                                  store.confirmVisit(store.singleSelVisitId())
                                                              }}>Confirmar cita temp.</Button>)
                    :
                    <Popover
                        target={<Button
                            intent={'none'}
                            // disabled={store.canConfirmVisit(store.singleSelVisitId)}
                            // icon={'tick-circle'}
                        >{errors.length} pendents</Button>}
                        content={<ConfirmTasksWarnContainer>
                            <strong>Abans de confirmar</strong>
                            <ol>
                                {errors.map((err, i) => {
                                    return <li key={i}>{err}</li>
                                })}
                            </ol>
                        </ConfirmTasksWarnContainer>}
                    />
                }
                {store.selectedOrgIds.length > 1 && 'Alerta: IRM i GE'}
                {(!store.isPrivateInvoicingMode && store.potentialTotalLinesToAddToInvoice > 0) && <Button
                  icon={'document'}
                  disabled={!store.canEnterPrivateInvoicing || store.isPrivateInvoicingMode || !store.isMainMode}
                  loading={store.initializingPrivateInvoiceMode}
                  onClick={() => {
                      if (!store.isPrivateInvoicingMode) {
                          store.isPrivateInvoicingModeMasterVisible = false
                          let promise = store.initPrivateInvoicingMode(false);
                          promise.then(() => {
                              let promise2 = store.createPrivateInvoice(true);
                          })
                      } else {
                          setTimeout(() => store.closePrivateInvoicingMode())
                      }

                  }}>
                  Facturar {(store.potentialLinesToAddToInvoice > 0 || true) ?
                    `${store.potentialLinesToAddToInvoice}/${store.potentialTotalLinesToAddToInvoice}` :
                    `(${store.potentialTotalLinesToAddToInvoice})`}
                </Button>}
                <Button
                    icon={!store.isPrivateInvoicingMode ? 'document' : 'cross'}
                    loading={store.initializingPrivateInvoiceMode}
                    disabled={!store.isMainMode && !store.isPrivateInvoicingMode}
                    onClick={() => {
                        if (!store.isPrivateInvoicingMode) {
                            store.isPrivateInvoicingModeMasterVisible = true
                            let promise = store.initPrivateInvoicingMode(false);
                            promise.then(() => store.privateInvoicesSel.selectIndex(0))

                        } else {
                            setTimeout(() => {
                                store.closePrivateInvoicingMode()
                            })
                        }
                    }}>
                    {!store.isPrivateInvoicingMode ? 'Veure factures' : 'Sortir facturació'}
                </Button>

                {/*<Button onClick={() => {*/}

                {/*}}>H. Factures</Button>*/}
                {/*<Button onClick={() => {*/}

                {/*}}>Anul·lar</Button>*/}
                {store.canAttachDocuments && <Button
                  loading={store.isAttachDocumentsModeLoading}
                  icon={'paperclip'}
                  disabled={!store.isMainMode}
                  intent={documentsWarning ? 'warning' : 'none'}
                  onClick={() => {
                      store.isAttachDocumentsModeReadonly = false
                      if (!store.isAttachDocumentsMode)
                          store.initAttachDocuments()
                      else
                          store.exitAttachDocuments()
                  }
                  }>OBLIGATORI: Documents</Button>}
                {/*<Button*/}
                {/*    loading={store.isAttachDocumentsModeLoading}*/}
                {/*    icon={'paperclip'}*/}
                {/*    disabled={!store.isMainMode}*/}
                {/*    onClick={() => {*/}
                {/*        store.isAttachDocumentsModeReadonly = true*/}
                {/*        if (!store.isAttachDocumentsMode)*/}
                {/*            store.initAttachDocuments()*/}
                {/*        else*/}
                {/*            store.exitAttachDocuments()*/}
                {/*    }*/}
                {/*    }>Veure</Button>*/}
                {/*<Button*/}
                {/*    icon={'reset'}*/}
                {/*    onClick={() => {*/}

                {/*    }}>Reprogramar</Button>*/}
                {!dec().useNewPerLineDeliveryDate() && <Button
                  disabled={!store.isMainMode || !store.visitsSel.selectedId}
                  icon={'calendar'}
                  intent={!store.visitsSel.selectedItem?.isDraft && !store.visitsSel.selectedItem?.deliveryDate ? 'warning' : 'none'}
                  onClick={() => store.isDeliveryMode = !store.isDeliveryMode}>
                  Entrega {store.visitsSel.selectedItem?.deliveryDate && `(${dateS})`}
                </Button>}
                <Button
                    icon={'refresh'}
                    loading={store.loadingIndicator}
                    onClick={() => store.refetch()}/>
                {/*<Button*/}
                {/*    icon={store.mainGridCollapsed ? 'double-chevron-right' : 'double-chevron-left'}*/}
                {/*    onClick={() => store.mainGridCollapsed = !store.mainGridCollapsed}*/}
                {/*/>*/}
            </ToolbarContainer>
        }}

        body={grid}
    />
})
