import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Button} from "@blueprintjs/core";
import {AutomaticInvoicesPageStore} from "../AutomaticInvoicesPageStore";

const Container = styled.div`
    display: grid;
    justify-content: end;
    
`

export interface AIPFooterProps {
    store: AutomaticInvoicesPageStore
}

export const AIPFooter = observer((props: AIPFooterProps) => {
    let store = props.store
    return <Container>
        <div>Valor total: {store.aipRes?.total}</div>
        <Button>Facturar</Button>
    </Container>
})
