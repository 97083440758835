import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Icon} from "@blueprintjs/core";
import {useRSCStore} from "../../../RSCMain";
import {toJS} from "mobx";
import * as _ from 'lodash'
const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  cursor: default;

  > .selectArrow {
    visibility: hidden;
    color: #8a9ba8;
  }

  :hover {
    > .selectArrow {
      visibility: visible;
    }

    background-color: #95dfe5;

  }
`

export interface RSCEntryDisplayProps {
    text
    item
    type: 'medical' | 'extCenter'
}

export const RSCEntryDisplay = observer((props: RSCEntryDisplayProps) => {
    let store = useRSCStore();

    return <Container
        onClick={async () => {
            store.selectEntry(props.type, props.item);
            if (store.isCreatingAssociation) {
                // await store.createNewLink();
                if (!props.item.medicalId) props.item.medicalId = store.form.$('medical.setId').value;
                if (!props.item.extCenterId) props.item.extCenterId = store.form.$('extCenter.setId').value;
            } else if (!store.isLinked) {
                props.item.medicalId = props.type === 'medical' ? store.form.$('medical.setId').value : null;
                props.item.extCenterId = props.type === 'medical' ? null : store.form.$('extCenter.setId').value;
            }


            await store.submit(props.item)

        }}
    >
        {props.text}
        <span/>
        <div
            onClick={async (e) => {
                let typeIdKey = `${props.type}Id`;

                if (props.item['id']) {
                    store.selectEntry(props.type, props.item)
                    if (store.isCreatingAssociation) {
                        if (!props.item.medicalId) props.item.medicalId = store.form.$('medical.setId').value;
                        if (!props.item.extCenterId) props.item.extCenterId = store.form.$('extCenter.setId').value;
                       // await store.createNewLink();
                       await store.submit(props.item);
                    }
                } else if (props.item?.[typeIdKey]) {
                    let entry = {
                        id: props.item[typeIdKey],
                        name: props.type == 'extCenter' ? props.item.extCenterName : '',
                    };
                    if( props.type == 'medical')
                        Object.assign(entry, _.pick(props.item, ['treatment', 'name', 'surname1', 'surname2']))
                    store.selectEntry(props.type, entry)
                } else {
                    console.log('No item id', typeIdKey, toJS(props.item))
                }

                e.stopPropagation()
            }}
            className={'selectArrow'}>
            <Icon icon={'arrow-top-left'}/>
        </div>
    </Container>
})
