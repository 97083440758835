import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useBSVStore} from "../BasicStatisticsView";
import {AppUsageTrend} from "./components/AppUsageTrend";

const Container = styled.div`
  display: grid;

`

export interface BSVMainProps {
    pageContext
}

export const BSVMain = observer((props: BSVMainProps) => {
    let store = useBSVStore()
    return <Container>
        <div>
            {store?.user?.name}
        </div>
        <div>
            <AppUsageTrend/>
        </div>
    </Container>
})
