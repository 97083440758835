import { C3, MRF } from "react-c4";
import {observable} from "mobx";
import {GridApi} from "ag-grid-community";
import MobxReactForm from "mobx-react-form";
import moment from "moment";


export class AutomaticInvoicesPageStore {
    aipInvalidated = false

    @observable
    aipRes: any

    aipGridApi: GridApi

    form: MRF

    filterForm: MRF

    @observable
    insCompanies: any[]

    init(){
        this.form = new MobxReactForm({})
        this.filterForm = new MobxReactForm({
            fields: ['dateFrom', 'dateTo', 'insCompany', 'isChipcard']
        })
        this.filterForm.update({
            dateFrom: moment().subtract(1, 'month').toDate(),
            dateTo: moment().subtract(0, 'month').toDate(),
            isChipcard: true
        })
        this.refetch()
        C3.instance.client.fetcher.fetch('/patient-overview/ins-companies-options', {})
            .then(r => this.insCompanies = r)
    }

    async fetch(endpoint = '', body = null, method = 'GET') {
        return await C3.instance.client.fetcher.fetch('/aip' + endpoint, {
            method: method,
            query: `patientId=`,
            body
        })
    }

    async post(endpoint, body, refetch = true) {
        let res = await this.fetch(endpoint, body, 'POST');
        if (refetch) await this.refetch()
        return res
    }

    async delete(endpoint, refetch = true) {
        let res = await this.fetch(endpoint, null, 'DELETE');
        if (refetch) await this.refetch()
        return res
    }

    async patch(endpoint, body, refetch = true) {
        if(refetch) this.aipInvalidated = true
        let res = await this.fetch(endpoint, body, 'PATCH');
        if (refetch) await this.refetch()

        return res
    }

    async refetch() {
        this.aipRes = await this.fetch('')
        this.aipInvalidated = false
        let oldValues = this.form.values();
        let valuesMap = (values) => {
            return values.visitLines.map(vl => vl.product)
        }
        this.form.update(this.aipRes)
        let newValues = this.form.values();
        // console.log(`Updated form`, newValues);

        // this.vlGridApi.refreshCells({force: true, suppressFlash: true})
        if(this.aipGridApi)this.aipGridApi.redrawRows()
        // setTimeout(() => )
        // console.log(`this.form.values()`, this.form.value, this.svvRes);
        // await Promise.all([
        //     this.visitsMD.fetchItems(),
        //     this.visitLinesMD.fetchItems()
        // ])
    }
}
