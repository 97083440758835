import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useUserFVStore} from "./UserFileView";
import {C3AgGrid, MasterLayout} from "react-c4";
import {formatFullName, stdBoolColumn, stdIntNumericColumn} from "../../application/utils/utils";
import {Button, InputGroup, Popover, Tooltip} from "@blueprintjs/core";
import {IRichCellEditorParams, RowNode, ValueFormatterParams} from "ag-grid-community";
import Splitter from "m-react-splitters";
import * as _ from "lodash";
import {UserFVDetail} from "./UserFVDetail";

const Container = styled.div`
  display: grid;
  padding: 8px;
  grid-template-columns: 1fr auto;
  height: 100%;
`
const ButtonContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 8px;
  place-content: start;
  padding-left: 5px;
`

const PopoverContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  padding: 16px;
  grid-gap: 16px;

`

const PopoverButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-gap: 8px;
  grid-auto-flow: column;
`
const DetailContainer = styled.div`

`

export interface UserFVMainProps {
    pageContext
}

export const UserFVMain = observer((props: UserFVMainProps) => {
    let store = useUserFVStore()
    let editableCheck = store.editingEnabled

    let lockEnabled = false;
    return <Splitter
        position="vertical"
        primaryPaneMaxWidth="80%"
    >
        <Container>
            <MasterLayout
                renderHeader={() => null}
                body={
                    <C3AgGrid
                        onGridReady={({api}) => store.usersGridApi = api}

                        columnDefs={[
                            {
                                field: 'username',
                                headerName: 'Usuari',
                                editable: editableCheck,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'email',
                                headerName: 'Email',
                                editable: editableCheck,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                                width: 180
                            },
                            {
                                field: 'name',
                                headerName: 'Nom',
                                editable: editableCheck,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'surname1',
                                headerName: 'Cognom 1',
                                editable: editableCheck,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'surname2',
                                headerName: 'Cognom 2',
                                editable: editableCheck,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'comment',
                                headerName: 'Comentari',
                                editable: editableCheck,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'pinNumber',
                                headerName: 'Pin',
                                editable: editableCheck,
                                filter: 'agTextColumnFilter',
                                floatingFilter: true,
                            },
                            {
                                field: 'org',
                                headerName: 'Organització',
                                editable: editableCheck,
                                cellEditor: 'agRichSelectCellEditor',
                                width: 125,
                                cellEditorParams: (params: IRichCellEditorParams) => {
                                    return {
                                        values: store.orgsRes,
                                        formatValue: (params) => {
                                            return params?.shortName
                                        },
                                    }
                                },
                                valueFormatter: (params: ValueFormatterParams) => {
                                    if (_.isEmpty(params.value)) return '-'
                                    return params?.value.shortName
                                },
                            },
                            // {
                            //     field: 'password',
                            //     headerName: 'Contrasenya',
                            //     editable: true,
                            //     filter: 'agTextColumnFilter',
                            //     floatingFilter: true,
                            // },
                            {
                                field: 'isActive',
                                headerName: 'Actiu',
                                ...stdBoolColumn(),
                                width: 55
                            },
                            {
                                field: 'id',
                                headerName: 'Id',
                                editable: false,
                                width: 60,
                                ...stdIntNumericColumn(),
                            },
                        ]}
                        processCellCallback={(params) => {
                            let data = (params.node as RowNode).data
                            return formatFullName({
                                name: data.medName,
                                surname1: data.medSurname1,
                                surname2: data.medSurname1
                            })
                        }}
                        getContextMenuItems={(params) => {
                            // let fileName = store.fr
                            var result = [
                                {
                                    // custom item
                                    name: 'Exporta a Excel',

                                    action: function () {
                                        params.api.exportDataAsExcel({
                                            fileName: `llistat-usuaris`,
                                            processCellCallback: (params) => {
                                                let data = (params.node as RowNode).data
                                                if (params.column.getColId() == 'org') {
                                                    return data.org.shortName;
                                                }
                                                if (params.column.getColId() == 'isActive') {
                                                    return data.isActive == true ? 'X' : '';
                                                }
                                                return params.value
                                            }
                                        })
                                    },
                                }
                            ]
                            return result
                        }}
                        rowData={store.usersRes}
                        selection={store.usersSel}
                        getRowNodeId={data => data.id}
                        cellEditorSubmitter={async (e) => {
                            await store.saveUser({
                                id: e.data.id,
                                [e.colDef.field]: e.newValue
                            })
                        }
                        }
                    />
                }
            />
            <ButtonContainer>
                <Tooltip content={`Donar d'alta usuari`}>
                    <Button icon={'document'}
                            disabled={!store.editingEnabled}
                            onClick={() => store.createUser()}
                    />
                </Tooltip>

                <Tooltip content={`Reestablir PIN`}>
                    <Button icon={'key'}
                            disabled={!store.editingEnabled || !store.usersSel.selectedItem}
                            onClick={() => store.resetPinForSelectedUser()}
                    />
                </Tooltip>

                <Tooltip content={`Reestablir agendes per defecte`}>
                    <Button icon={'reset'}
                            disabled={!store.editingEnabled || !store.usersSel.selectedItem}
                            onClick={() => store.resetCustomResourceGroups()}
                    />
                </Tooltip>


                <Button icon={'trash'}
                        disabled={!store.editingEnabled || !store.usersSel.selectedItem}
                        onClick={() => store.deleteSelectedUser()}
                />

                {lockEnabled && <Popover
                    isOpen={store.resourcePopoverCheck}
                    content={<PopoverContainer>
                        <InputGroup {...store.filterForm.$('resourceEditCode').bind()} leftIcon={'lock'}
                                    type={'password'}
                                    placeholder={`Codi secret de borrat`}/>
                        <PopoverButtonsContainer>
                            <Button icon={'cross'} onClick={() => {
                                store.resourcePopoverCheck = false
                                store.filterForm.$('resourceEditCode').set('')
                            }}>Cancel·la</Button>
                            <Button
                                icon={'tick'} disabled={store.filterForm.$('resourceEditCode').value != '7778'}
                                intent={'warning'} onClick={async () => {
                                if (true) {
                                    store.editingEnabled = true
                                    store.filterForm.$('resourceEditCode').set('')
                                    store.resourcePopoverCheck = false
                                    await store.fetch()

                                }

                            }}>Desbloquejar Borrar</Button>
                        </PopoverButtonsContainer>
                    </PopoverContainer>}
                >
                    <Button
                        icon={store.editingEnabled ? 'unlock' : 'lock'}
                        intent={'none'}
                        onClick={() => {
                            if (store.editingEnabled) {
                                store.editingEnabled = false;
                            } else {
                                store.editingEnabled = false;
                                store.resourcePopoverCheck = !store.resourcePopoverCheck;
                            }
                        }}
                    />
                </Popover>}
            </ButtonContainer>
        </Container>
        <UserFVDetail/>
    </Splitter>
})
