import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {formatFormalName} from "../../application/utils/utils";
import {SchedulePageHeader} from "../SchedulePage/SchedulePageHeader";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {Page} from "react-c4";
import {ALVStore} from "../../components/AppointmentListView/ALVStore";
import {AppointmentListPageHeader} from "./AppointmentListPageHeader";
import {AppointmentListView} from "../../components/AppointmentListView/AppointmentListView";

const Container = styled.div`
  display: grid;
  height: 100%;

`

export interface AppointmentListPageProps {

}

export const AppointmentListPage = page(observer((props: AppointmentListPageProps) => {
    let [store, setStore] = useState(null as ALVStore);

    return <Page
        title={'Llistat de cites'}
        subtitle={''}

        icon={'document'}
        attributes={[

        ]}
        renderCustomHeaderArea={() => {
            return <AppointmentListPageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <AppointmentListView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
