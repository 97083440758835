import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {C3AgGrid} from "react-c4/src/controls/master/C3AgGrid";
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import moment from "moment";
import * as _ from 'lodash'
import { MasterLayout } from "react-c4";
import {ValueFormatterParams} from "ag-grid-community";
const Container = styled.div`
    display: grid;
    height: 300px;
`

export interface OverviewTestsTabProps {
    store: PatientOverviewStore
    pageContext
}

export const OverviewAppointmentsTab = observer((props: OverviewTestsTabProps) => {
    let id: any = props.store.form.entityId;
    return  <Container>
        <MasterLayout md={props.store.visitsMD}
                      renderHeader={() => null}
                      actions={[
                          // {
                          //     name: 'open',
                          //     iconName: 'document-open',
                          //     text: 'Anar a cita',
                          // },

                      ]}>
            <C3AgGrid
                // md={props.store.visitsMD}
                rowData={props.store.appointmentsList}
                defaultColDef={{sortable: true}}
                floatingFilter={false}
                columnDefs={[
                    {
                        headerName: 'N. Cita',
                        field: 'visitNumber',
                        width: 100,
                        valueFormatter: (params) => {
                            return (params.data?.isDraft ? 'T' : 'C') + params?.value
                        }
                    },
                    {
                        headerName: 'Data',
                        field: 'appointmentDate',
                        width: 100,
                        valueFormatter: (params: ValueFormatterParams) => {
                            let value = params.value;
                            if(value == null) return '(Sense data)'
                            let moment1 = moment(value);
                            return moment1.format('DD/MM/YYYY')
                        }
                    },
                    {
                        headerName: 'Hora',
                        field: 'appointmentDate',
                        width: 100,
                        valueFormatter: (params: ValueFormatterParams) => {
                            let value = params.value;
                            if(value == null) return '(Sense hora)'
                            return moment(params.value).format('HH:mm')
                        }
                    },
                    {
                        headerName: 'Màquina',
                        field: 'resource.name',
                        width: 100,

                    },
                ]}
                onRowDoubleClicked={({data}) => {
                    props.pageContext.navigate({
                        to: 'bookVisitPage', args: {
                            patientId: props.store.form.entityId,
                            selectedVisitIds: [data.id]
                        },
                        inNewTab: false, focusNewTab: true
                    })
                }}
            />

        </MasterLayout>
    </Container>
})
