import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {formatFormalName, isDev} from "../../application/utils/utils";
import {SchedulePageHeader} from "../SchedulePage/SchedulePageHeader";
import {ScheduleView} from "../../components/ScheduleView/ScheduleView";
import {Page} from "react-c4";
import {ULEStore} from "../../components/UserLogEntriesView/ULEStore";
import {UserLogEntriesPageHeader} from "./UserLogEntriesPageHeader";
import {UserLogEntriesView} from "../../components/UserLogEntriesView/UserLogEntriesView";

const Container = styled.div`
  display: grid;
  height: 100%;

`

export interface UserLogEntriesPageProps {

}

export const UserLogEntriesPage = page(observer((props: UserLogEntriesPageProps) => {
    let [store, setStore] = useState(null as ULEStore);

    return <Page
        title={`Registre d'activitat`}
        // subtitle={isDev() && 'ULEStore'}
        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <UserLogEntriesPageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <UserLogEntriesView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                    {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))
