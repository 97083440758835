import React, {useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {page} from "react-navigation-plane";
import {Page} from "react-c4";
import {ICIStore} from "../../components/InsCompanyInvoicingView/ICIStore";
import {InsCompanyInvoicingPageHeader} from "./InsCompanyInvoicingPageHeader";
import {InsCompanyInvoicingView} from "../../components/InsCompanyInvoicingView/InsCompanyInvoicingView";

const Container = styled.div`
  display: grid;
  height: 100%;
`

export interface InsCompanyInvoicingPageProps {

}

export const InsCompanyInvoicingPage = page(observer((props: InsCompanyInvoicingPageProps) => {
    let [store, setStore] = useState(null as ICIStore);

    return <Page
        title={'Facturació Mútues'}
        subtitle={store?.insCompaniesSel?.selectedItem?.name || 'Facturació mensual mútues'}
        icon={'document'}
        attributes={[
            // {
            //     name: 'Pacient',
            //     value: formatFormalName(store?.patientData),
            // }
        ]}
        renderCustomHeaderArea={() => {
            return <InsCompanyInvoicingPageHeader store={store}/>
        }}
        renderBody={({pageContext}) => {
            // let patientId = pageContext.args.patientId;
            // if (patientId == null) return <NonIdealState title={'Selecciona un pacient'} icon={'person'}/>
            return <Container>
                <InsCompanyInvoicingView onStoreReady={(s) => {
                    s.pageContext = pageContext
                    setStore(s);
                }}
                                        {...pageContext.args} pageContext={pageContext}/>
            </Container>
        }}
    />
}))

