import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {DateInput} from "@blueprintjs/datetime";
import {Button, TextArea} from "@blueprintjs/core";
import {DateInputControl, getMomentFormatter, LayoutBoundary} from "react-c4";
import {useSVVStore} from "../../StandardVisitView";
import moment from "moment";
import {bindWithAS, useAS} from "../../../../application/utils/utils";

const OuterContainer = styled.div`
  width: 240px;

`
const Container = styled.div`
  display: grid;
  align-content: start;
  grid-gap: 4px;
  width: 240px;
`
const ButtonsContainer = styled.div`
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  grid-gap: 4px;
`

export interface SVVDeliveryProps {

}

export const SVVDelivery = observer((props: SVVDeliveryProps) => {
    let store = useSVVStore()
    let amount = 2;
    let moment1 = moment(store.visitsSel.selectedItem?.appointmentDate);
    if (moment1.weekday() == 3) amount = 4
    if (moment1.weekday() == 4) amount = 4
    let dateS = moment1.add(amount, 'days');

    let visitField = store.visitsSel.selField;

    let as = useAS((field, e) => {
        let fieldName = field.name;
        store.saveVisit({id: visitField.$('id').value, [fieldName]: visitField.$(fieldName).value}, false)
    })
    if (!visitField) return null;
    return <OuterContainer>
        <LayoutBoundary>
            <Container>
                <h3>Entrega</h3>
                {/*<DateInput defaultValue={dateS.toDate()} {...getMomentFormatter('DD/MM/YYYY')}/>*/}
                <DateInputControl fill field={visitField.$('deliveryDate')} as={as} {...getMomentFormatter('DD/MM/YYYY')}/>
                {/*<DateInput defaultValue={dateS.toDate()} {...getMomentFormatter('DD/MM/YYYY')}/>*/}
                <TextArea fill={true} style={{height: 140}} {...bindWithAS(visitField.$('deliveryComments'), as)} placeholder={'Comentari entrega'}/>
                <ButtonsContainer>
                    <Button icon={'cross'} onClick={() => store.isDeliveryMode = false}>Tanca</Button>
                    {/*<Button icon={'tick'}>Confirma</Button>*/}
                </ButtonsContainer>
                <div>
                    Nota: Les dates i comentaris d'entrega passaran a ser PER PROVA en una propera versió.
                </div>
            </Container>
        </LayoutBoundary>
    </OuterContainer>
})
