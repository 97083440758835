import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {Invoice, InvoiceProps} from "../Invoice/Invoice";
import {formatFormalName, stdNumericColumn} from "../../application/utils/utils";
import moment from "moment";

const Container = styled.div`
    display: grid;
    
`

export interface ManualInvoiceProps {
    invoice
    onPrintReady
    isPrinting
}

export const ManualInvoice = observer((props: ManualInvoiceProps) => {
    let getearliest_invoiceline_date = (invoice) => {
        let dates = invoice.lines.map(a => {
            return a.date
        })
        return new Date(Math.min.apply(null, dates))

    }
    let getlatest_invoiceline_date = (invoice) => {
        let dates = invoice.lines.map(a => {
            return a.date
        })
        return new Date(Math.max.apply(null, dates))

    }
    return <Invoice
        isPrinting={props.isPrinting}
        onPrintReady={props.onPrintReady}
        notes={'??'}
        case={'??'}
        // accountNumber={props.data.bankAccount.iban}
        columnDefs={[
            // {
            //     field: 'date',
            //     headerName: 'Data',
            //     width: 90,
            //     valueFormatter({value}) {
            //         if (value == null) return '(Sense data)'
            //         return moment(value).format('DD/MM/YYYY');
            //     }
            // },
            {
                field: 'text',
                headerName: 'Concepte',
                width: 280
            },
            // {
            //     field: 'text',
            //     headerName: 'Autorit.',
            //     width: 250
            // },
            {
                field: 'price',
                headerName: 'Total', // €/u
                width: 62,
                ...stdNumericColumn()
            },
            // {
            //     field: 'quantity',
            //     headerName: 'Q',
            //     width: 40,
            //     valueFormatter: ({data}) => '1'
            // }, {
            //     field: 'price',
            //     headerName: 'Total',
            //     width: 75,
            //     ...stdNumericColumn()
            // },
        ]}
        // invoice={{
        //     lines: [
        //         {
        //             patient: {
        //                 name: 'José',
        //                 surname1: 'Pérez',
        //                 surname2: 'Giménez'
        //             },
        //             date: new Date(),
        //             text: 'AA',
        //         }, {
        //             date: new Date(),
        //             text: 'BB',
        //             quantity: 2,
        //             total: 450
        //         }, {
        //             date: new Date(),
        //             text: 'XCC',
        //             quantity: 25,
        //             total: 243
        //         }
        //     ],
        //     total: 1000
        // }}
        invoice={props.invoice}
        type={'ins-company'}
    />
})
