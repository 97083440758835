import {C4View} from "react-c4";
import {IconName} from "@blueprintjs/core";
import {dec} from "./utils";

export interface FileViewInfo {
    page?: string
    view?: typeof C4View.constructor
    name: string
    icon: IconName
    args?: { [arg: string]: any }

    header?
    body?
    store?
}

export interface TypeGroupInfo {
    name: string
    icon: IconName
    files: {
        primary: {
            [fileName: string]: FileViewInfo
        },
        secondary: {
            [fileName: string]: FileViewInfo
        }
    }
}

export interface FilesInfo {
    [groupName: string]: TypeGroupInfo
}

export let filesInfo: () => FilesInfo = () => {
    return {
        ...(dec().canSeePage('productFilePage') && {
            products: {
                name: 'Productes',
                icon: 'document',

                files: {
                    primary: {
                        products: {
                            name: 'Proves (i barems assignats)',
                            icon: 'document',
                            view: ProductView,
                            page: 'productFilePage'
                        },
                    },
                    secondary: {
                        productFamiliesFilePage: {
                            name: 'Famílies de proves',
                            icon: 'document',
                            view: ProductFamilyView,
                            page: 'productFamiliesFilePage'
                        },
                        // productSubfamilies: {
                        //     name: 'Subfamílies de proves',
                        //     icon: 'document',
                        //     view: ProductSubfamilyView
                        // },
                        // businessLines: {
                        //     name: 'Línies de negoci',
                        //     icon: 'document',
                        //     view: BusinessLineView
                        // },
                    }

                }
            }
        }),
        ...(dec().canSeePage('resourcesPage') && {
            resources: {
                name: 'Recursos',
                icon: 'new-grid-item',
                files: {
                    primary: {
                        resources: {
                            name: 'Màquines (i proves assignades)',
                            icon: 'new-grid-item',
                            view: ResourcesView,
                            page: 'resourcesPage',
                        }
                    },
                    secondary: {
                        // resourceTypes: {
                        //     name: 'Tipus de màquines',
                        //     icon: 'new-grid-item',
                        //     view: ResourceTypesView
                        // }
                    }
                }
            }
        }),
        // invoicing: {
        //     name: 'Facturació',
        //     icon: 'document',
        //     files: {
        //         primary: {},
        //         secondary: {}
        //     }
        // },
        ...(dec().canSeePage('ratesFilePage') && {
            rates: {
                name: 'Barems',
                icon: 'document',
                files: {
                    primary: {
                        rateSheets: {
                            name: 'Manteniment de barems',
                            icon: 'document',
                            view: RateSheetsView,
                            page: 'ratesFilePage',
                        },
                    },
                    secondary: {
                        // rates: {
                        //     name: 'Totes les tarifes',
                        //     icon: 'document',
                        //     view: RatesView,
                        // },
                    }
                }
            }
        }),
        // organizations: {
        //     name: 'Organitzacions',
        //     icon: 'office',
        //     files: {
        //         primary: {
        //             // organizations: {
        //             //     name: 'Organitzacions',
        //             //     icon: 'office',
        //             //     view: OrganizationsView,
        //             //     page: 'orgFilePage'
        //             // },
        //         },
        //         secondary: {
        //             // facilities: {
        //             //     name: 'Edificis',
        //             //     icon: 'office',
        //             //     view: FacilitiesView,
        //             // },
        //             // watingRooms: {
        //             //     name: 'Sales d\'espera',
        //             //     icon: 'office',
        //             //     view: WaitingRoomsView
        //             // },
        //         }
        //     }
        // },
        ...(dec().canSeePage('referrersPage') && {
            referrals: {
                name: 'Referents',
                icon: 'person',
                files: {
                    primary: {
                        referrals: {
                            name: 'Revisió de referents',
                            icon: 'person',
                            page: 'referrersPage'
                            // Center + Doctor, type column
                        },

                    },
                    secondary: {
                        // externalCentres: {
                        //     name: 'Centres referents',
                        //     icon: 'office',
                        //     page: 'extCenterFilePage'
                        // },
                        // specialties: {
                        //     name: 'Especailitats',
                        //     icon: 'document',
                        // },
                        // salesmen: {
                        //     name: 'Comercials',
                        //     icon: 'person',
                        // },
                        // protocols: {
                        //     name: 'Protocols',
                        //     icon: 'label',
                        // },
                    }
                }
            }
        }),
        ...(dec().canSeePage('extCenterFilePage') && {
            referrals2: {
                name: 'Centres Referents',
                icon: 'office',
                files: {
                    primary: {
                        referrals: {
                            name: 'Assignació Referidor a Centre',
                            icon: 'office',
                            page: 'extCenterFilePage'
                            // Center + Doctor, type column
                        },

                    },
                    secondary: {}
                }
            }
        }),
        ...(dec().canSeePage('contactsPage') && {
            contacts: {
                name: 'Mútues',
                icon: 'id-number',
                files: {
                    primary: {
                        referrals: {
                            name: 'Mútues',
                            icon: 'id-number',
                            page: 'contactsPage'
                        },

                    },
                    secondary: {}
                }
            }
        }),
        ...(dec().canSeePage('businessLinePage') && {
            businessLines: {
                name: 'Línies de negoci',
                icon: 'list',
                files: {
                    primary: {
                        businessLines: {
                            name: 'Línies de negoci',
                            icon: 'list',
                            page: 'businessLinePage'
                        },

                    },
                    secondary: {}
                }
            }
        }),
        ...(dec().canSeePage('orgFilePage') && {
            orgs: {
                name: 'Organitzacions',
                icon: 'office',
                files: {
                    primary: {
                        businessLines: {
                            name: 'Organitzacions',
                            icon: 'office',
                            page: 'orgFilePage'
                        },

                    },
                    secondary: {}
                }
            },
        }),

        ...(dec().canSeePage('scheduleRulesPage') && {
            scheduleRules: {
                name: 'Calendari',
                icon: 'calendar',
                files: {
                    primary: {
                        referrals: {
                            name: 'Bloqueig de calendari',
                            icon: 'calendar',
                            page: 'scheduleRulesPage'
                        },

                    },
                    secondary: {}
                }
            },
        }),
        ...(dec().canSeePage('usersPage') && {
            usersPage: {
                name: 'Usuaris',
                icon: 'person',
                files: {
                    primary: {
                        users: {
                            name: 'Usuaris',
                            icon: 'person',
                            page: 'usersPage'
                        },

                    },
                    secondary: {}
                }
            },
        }),
        ...(dec().canSeePage('userLogEntriesPage') && {
            adminPages: {
                name: 'Administració',
                icon: 'console',
                files: {
                    primary: {
                        referrals: {
                            name: 'Registre d\'activitat',
                            icon: 'console',
                            page: 'userLogEntriesPage'
                        },

                    },
                    secondary: {}
                }
            }
        }),
        ...(dec().canSeePage('patientFusionPage') && {
            patientPages: {
                name: 'Fusió de pacients',
                icon: 'person',
                files: {
                    primary: {
                        patientFusion: {
                            name: 'Fusió de pacients',
                            icon: 'person',
                            page: 'patientFusionPage'
                        },

                    },
                    secondary: {}
                }
            }
        }),
        // referrals3: {
        //     name: 'Referents',
        //     icon: 'person',
        //     files: {
        //         primary: {
        //             specialties: {
        //                 name: 'Especialitats',
        //                 icon: 'document',
        //             },
        //
        //         },
        //         secondary: {
        //
        //         }
        //     }
        // },
        // referrals4: {
        //     name: 'Assignació referents centre',
        //     icon: 'person',
        //     files: {
        //         primary: {
        //             specialties: {
        //                 name: 'Assignació referents centre',
        //                 icon: 'document',
        //             },
        //
        //         },
        //         secondary: {
        //
        //         }
        //     }
        // },

        // insuranceCompanies: {
        //     name: 'Asseguradores',
        //     icon: 'office',
        //     files: {
        //         primary: {
        //             insuranceCompanies: {
        //                 name: 'Asseguradores',
        //                 icon: 'office',
        //                 view: ContactsView,
        //                 // args: { type: 'ins-company' },
        //                 page: 'ratesFilePage'
        //             },
        //
        //         },
        //         secondary: {
        //             rateSheets: {
        //                 name: 'Rel. Fulls de tarifes',
        //                 icon: 'document',
        //                 view: RateSheetsView,
        //             },
        //         }
        //     }
        // },
        // patients: {
        //     name: 'Pacients',
        //     icon: 'person',
        //     files: {
        //         primary: {
        //             patients: {
        //                 name: 'Pacients',
        //                 icon: 'person',
        //                 view: PatientsView,
        //                 page: 'ratesFilePage'
        //
        //             },
        //         },
        //         secondary: {}
        //     }
        // },
    }

}
