import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {CFVStore} from "../../files-views/ContactFileView/CFVStore";
import {Button, ButtonGroup, Icon} from "@blueprintjs/core";
import {dec, isDev} from "../../application/utils/utils";

const Container = styled.div`
    display: grid;
  grid-auto-flow: column;
    
`
const HideButtonContainer = styled.div`
    display: none
`

const Label = styled.div`
  font-size: 20px;
    margin: 5px;
    margin-bottom: 10px;
`

export interface ContactFilePageHeaderProps {
    store: CFVStore
}
const ButtonContainer = styled.div`
  padding-left: 10px
`

const SpanContainer = styled.div`
  padding-left: 15px;
  //color: red;
  font-weight: bold;
`

export const ContactFilePageHeader = observer((props: ContactFilePageHeaderProps) => {
    let store = props.store;
    if (store === null) return null;
    return <Container>
        <HideButtonContainer>
            <ButtonGroup>
                {store.contactTypeSel.items?.map(it => {
                    let selected = store.contactTypeSel.isSelected((it.id));
                    return <Button
                      active={selected}
                      onClick={()=>store.contactTypeSel.selectId(it.id)}>
                        {it.shortName}
                    </Button>
                })}
            </ButtonGroup>
        </HideButtonContainer>
        <Label>{store.contactTypeSel.selectedItem.name}</Label>
        <ButtonGroup>
            {store.orgsSel.items?.map(it => {
                let selected = store.orgsSel.isSelected((it.id));
                return <Button
                    active={selected}
                    onClick={()=>store.orgsSel.selectId(it.id)}
                >
                    {it.shortName}
                </Button>
            })}
        </ButtonGroup>
        <ButtonContainer>
            <Button icon={'refresh'} onClick={() => {
                store.fetch()
            }}/>
        </ButtonContainer>
        {<Button icon={'upload'} disabled={!isDev() || !dec().canUseDangerousFeatures()} onClick={() => {
            store.excelImportClick()
        }}>Importa Excel (experimental)</Button>}
        <SpanContainer>Per editar, crear o esborrar una Mútua desbloquejar el candau.
            {/*(els camps <Icon icon={'tick-circle'}/> per poder-los editar s'ha d'editar un altre camp abans)*/}
        </SpanContainer>
    </Container>
})
