import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {DetailHeader} from "./DetailHeader";
import {EventFeatureEditor} from "./EventFeatureEditor/EventFeatureEditor";

const Container = styled.div`
    display: grid;
    
`

export interface FeatureEditorDetailProps {

}

export const FeatureEditorDetail = observer((props: FeatureEditorDetailProps) => {
    return <Container>
        <DetailHeader type={'event'}/>
        <EventFeatureEditor/>
    </Container>
})
