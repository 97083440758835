import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {PatientOverviewStore} from "../../../PatientOverviewStore";
import {ProcessTabLayout} from "../components/ProcessTabLayout";
import {Button, InputGroup, Radio, RadioGroup, Tag, TextArea} from "@blueprintjs/core";
import * as _ from "lodash";
import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";

const Container = styled.div`
    display: grid;
    grid-gap: 32px;
`
const AttachButtonContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
`

const OtherInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 22px auto auto auto;
    grid-gap: 4px;
`
const DateContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    justify-content: start;
    align-items: center;
`

export interface EntregaTabProps {
    store: PatientOverviewStore

}

export const EntregaTab = observer((props: EntregaTabProps) => {
    let [adj, setAdj] = useState(false)
    let [r1, setR1] = useState('toPatient');
    let [r2, setR2] = useState('byHand');
    let [r3, setR3] = useState('finished');
    useEffect(() => {
        props.store.basicInfoCollapsed = true
    }, [])
    return <ProcessTabLayout title={'Entrega de resultats'} buttonsFragment={<React.Fragment>
        <Button onClick={() => props.store.closeAdditionalTab()}>Sortir</Button>
        <Button icon={'eye-open'}>Previsualitza</Button>
        <Button onClick={() => null} icon={'floppy-disk'}>Guardar</Button>
    </React.Fragment>}>
        <Container>
            <DateContainer>
                <div>Entrega realitzada el:</div> <InputGroup defaultValue={moment(new Date()).format('DD/MM/YYYY')}/>  <Tag minimal>Persona que entrega: {'José Pérez'}</Tag>
            </DateContainer>

            <AttachButtonContainer>
                <Button icon={!adj ? 'paperclip' : null} disabled={adj} rightIcon={adj ? 'tick-circle' : null} onClick={() => setAdj(true)}>{!adj ? 'Adjuntar comprovant signat' : 'Comprovant'}</Button>
                {adj && <Button icon={'trash'} onClick={() => setAdj(false)}/>}
            </AttachButtonContainer>
            <RadioGroup
                onChange={(v) => setR1((v.target as any).value)}
                selectedValue={r1}
            >
                <Radio label={'Entregat al mateix pacient'} value="toPatient"/>
                <Radio label="Entregat a un altre" value="toOther" />
                {r1 === 'toOther' && <OtherInfoContainer>
                    <div/>
                    <InputGroup placeholder={'Nom'}/>
                    <InputGroup placeholder={'Cognoms'}/>
                    <InputGroup placeholder={'DNI'}/>
                </OtherInfoContainer>}
            </RadioGroup>
            <RadioGroup
                onChange={(v) => setR2((v.target as any).value)}
                selectedValue={r2}
            >
                <Radio label="Entregat a mà" value="byHand"  />
                <Radio label="Entregat via telemàtica" value="byNetwork" />
                {r2 === 'byNetwork' && <TextArea style={{marginLeft: 22}} fill placeholder={'Comentaris'}/>}
            </RadioGroup>
            <RadioGroup
                onChange={(v) => setR3((v.target as any).value)}
                selectedValue={r3}
            >
                <Radio label="Entrega finalitzada" value="finished"  />
                <Radio label="Entrega pendent per falta d'informació" value="card" />
                <Radio label="Entrega pendent altres" value="card" />
            </RadioGroup>
        </Container>
    </ProcessTabLayout>
})
