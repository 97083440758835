import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {useDCSStore} from "../../DailyCheckoutSheetView";
import * as _ from 'lodash'

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  font-size: 18px;
  padding-right: 8px;
`

export interface DCSFooterProps {

}

export const DCSFooter = observer((props: DCSFooterProps) => {
    let store = useDCSStore()

    return <Container>
        <div>

        </div>
        <div/>
        <div>
        </div>
    </Container>
})
