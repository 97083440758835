import {C3SchemaInfo} from "react-c4/src/schema/C3Schema";

export const visitSchema: C3SchemaInfo = {
    'visit': {
        fields: {
            id: {},
            name: {},
            // TODO Add fields
            tmpProducts: {
                type: 'product',
                multivalued: true
            }
        }
    }
}
