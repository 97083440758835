import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {formatFormalName, useAppStore} from "../../../../../utils/utils";
import {Button, ButtonGroup, Tag, Tooltip} from "@blueprintjs/core";
import * as _ from 'lodash'
import moment from "moment";
import {useInterval, useUpdate} from "react-use";

const Container = styled.div`
    display: grid;
    padding: 4px;
    border-radius: 3px;
    
`
const PatientNameContainer = styled.div`
  font-size: 14px;
  //font-family: "Lucida Sans",serif;
  font-family: "Lucida Sans", serif;
  //font-style: italic;
  color: #137cbd;
  text-transform: uppercase;
`
const FirstLineContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(1, auto) 1fr auto;
    justify-items: start;
    grid-gap:  4px;
    align-items: start;
`
const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`
const InfoContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
`
const AppointmentTimeContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 4px;
    align-items: start;
`
const ActionsContainer = styled.div`
    display: grid;
    align-self: end;
    padding-top: 4px;
`

export interface TAContrastAlertsTabItemProps {
    item
}

export const TAContrastAlertsTabItem = observer((props: TAContrastAlertsTabItemProps) => {
    let appStore = useAppStore()
    let it = props.item
    let usedExitRoomDate = moment(it.enterRoomDate).add(it.duration, 'minutes');
    if (it.exitRoomDate) usedExitRoomDate = moment(it.exitRoomDate)
    let update = useUpdate()
    useInterval(() => {
        update()
    }, 1000)
    return <Container>
        <FirstLineContainer>
            <PatientNameContainer>
                {formatFormalName(it)}
            </PatientNameContainer>

            <div/>
            {it.exitRoomDate && <Tag intent={'warning'}>Fora visita</Tag>}
        </FirstLineContainer>
        <BodyContainer>
            <InfoContainer>
                <span>{_.capitalize(it.contrast)}</span>
                <Tooltip content={'Horari planificat'}>
                    <AppointmentTimeContainer>
                        <span>{moment(it.appointmentDate).format('H:mm')}</span>{' - '}<span
                        style={{fontWeight: it.enterRoomDate ? 'normal' : 'bold'}}>{moment(it.appointmentDate).add(it.duration, 'minutes').format('H:mm')}</span>
                        {/*<span>(aprox d'aquí 4 minuts)</span>*/}
                    </AppointmentTimeContainer>
                </Tooltip>
                {it.enterRoomDate && <AppointmentTimeContainer>
                  <span>{moment(it.enterRoomDate).format('H:mm')}</span>{' - '}<span
                  style={{fontWeight: 'bold'}}>{usedExitRoomDate.format('H:mm')}</span>
                  <span>({!it.exitRoomDate ? 'aprox ' : ''}{moment(usedExitRoomDate).fromNow()})</span>
                </AppointmentTimeContainer>}
            </InfoContainer>
            <ActionsContainer>
                <ButtonGroup>
                    <Tooltip content={'Marcar com a NO contrast'}>
                        <Button minimal small icon={'cross'} onClick={async () => {
                            await appStore.saveVisit({
                                id: it.visitId,
                                contrast: null,
                            })
                        }}/>
                    </Tooltip>
                    <Tooltip content={'Anar a la visita'}>
                        <Button minimal small icon={'arrow-right'} onClick={async () => {
                            if (appStore.navContext) {
                                appStore.navContext.navigate({
                                    to: 'bookVisitPage', args: {
                                        patientId: it.patientId,
                                        selectedVisitIds: [it.visitId]
                                    }
                                })
                            }
                        }}/>
                    </Tooltip>

                </ButtonGroup>
            </ActionsContainer>
        </BodyContainer>

    </Container>
})
