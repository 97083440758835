import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {ARVStore} from "../../components/AppointmentsReviewView/ARVStore";

const Container = styled.div`
    display: grid;
    
`

export interface AppointmentsReviewPageHeaderProps {
    store: ARVStore
}

export const AppointmentsReviewPageHeader = observer((props: AppointmentsReviewPageHeaderProps) => {
    let store = props.store;
    if (store == null) return null

    return <Container>

    </Container>
})
