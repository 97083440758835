import React, {useContext, useState} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {Page} from "react-c4";
import {page} from "react-navigation-plane";
import {RefFVStore} from "../../files-views/ReferrerFileView/RefFVStore";
import {ReferrerFileView} from "../../files-views/ReferrerFileView/ReferrerFileView";
import {ReferrerPageHeader} from './ReferrerFilePageHeader'
import {ProductFilePageHeader} from "../ProductFilePage/ProductFilePageHeader";


const Container = styled.div`
    display: grid;
    
`



export interface SchedulePageProps {

}

export const ReferrerFilePage = page(observer((props: SchedulePageProps) => {
    let [store, setStore] = useState(null as RefFVStore);

    return <Page
        title={'Referidors'}
        subtitle={'Revisió de referidors'}
        icon={'calendar'}
        attributes={[
            {
                name: 'Referidors',
                value: store?.pendingReferrersRes?.length,
            }
        ]}
        renderCustomHeaderArea={()=>{
        return <ReferrerPageHeader  store={store}/>}
        }

        renderBody={({pageContext}) => {
            return <Container>
                <ReferrerFileView
                    onStoreReady={(s) => {
                        setStore(s);
                    }}
                    {...pageContext.args}
                    pageContext={pageContext}
                />
            </Container>
        }}
    />
}))
