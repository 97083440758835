import {C3, IATSStyleApp} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {observable} from "mobx";
import moment from "moment";

export class BSVStore {
    appStore: AppStore
    pageContext

    @observable
    eventCount

    @observable
    user


    @observable.shallow
    data

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        await this.fetch()
    }

    async fetch() {
        this.eventCount = await C3.instance.client.fetcher.fetch('/event/stats', {})
        this.user = await C3.instance.client.fetcher.fetch('/auth/me', {})
        this.data = await C3.instance.client.fetcher.fetch('/event/stats/usage', {
            query: [`g=week`].join('&'),
        })
        // this.data = this.data.map(s => {
        //     s.data = s.data?.map(dr => ({...dr, x: moment(dr.x).toDate()})) || []
        //     return s
        // })
    }
}
