import {C3, C3Selection, IATSStyleApp, MRF} from "react-c4"
import {AppStore} from "../../application/AppStore";
import {observable, reaction, toJS} from "mobx";
import MobxReactForm from "mobx-react-form";
import moment from "moment";

export class MEVStore {
    appStore: AppStore
    pageContext

    meetupGroupsSel: C3Selection

    meetupEventsSel: C3Selection

    @observable
    urlNameForm: MRF

    @observable
    filterFromToday = true

    async init() {
        this.appStore = IATSStyleApp.instance.appStore
        this.meetupGroupsSel = new C3Selection({
            onSelectionChanged: () => {
                this.fetchMeetupEvents()
            }
        })
        this.meetupEventsSel = new C3Selection({})
        this.urlNameForm = new MobxReactForm({
            fields: [
                'urlName',
            ],
        })

        await this.fetchMeetupGroups()

        reaction(() => this.filterFromToday, () => {
            this.fetchMeetupEvents()
        })
    }

    async fetchMeetupGroups() {
        let res = await C3.instance.client.fetcher.fetch('/meetup/meetup-groups', {})
        this.meetupGroupsSel.itemsRef.current = res
        return res
    }

    async fetchMeetupEvents() {
        let selectedGroupIds = this.meetupGroupsSel.selectedIds;
        let res = await C3.instance.client.fetcher.fetch(`/meetup/meetup-events/${selectedGroupIds}`, {
            query: [
                `filterFromToday=${this.filterFromToday}`
            ].join('&'),
        })
        console.log(`Event res`, res, selectedGroupIds);
        this.meetupEventsSel.itemsRef.current = res.map((me) => ({...me, id: me.meetupId}))
        return res
    }

    async createMeetupGroup() {
        let res = await C3.instance.client.fetcher.fetch('/meetup/meetup-group', {
            method: 'POST',
            body: this.urlNameForm.values()
        })
        await this.updateMeetupEventsForSelectedGroup()
        await this.fetchMeetupGroups()
        return res
    }

    async updateMeetupEventsForSelectedGroup() {
        let groupId = this.meetupGroupsSel.selectedId
        let res = await C3.instance.client.fetcher.fetch(`/meetup-ingester/update-meetup-events/${groupId}`, {})
        console.log(`groupId, res`, groupId, res);
        await this.fetchMeetupGroups()
        await this.fetchMeetupEvents()
        return res
    }

    async saveMeetupGroup(body) {

    }

    async copyEventFromMeetupEvent() {
        let meetupEventIds = toJS(this.meetupEventsSel.selectedIds);
        console.log(`meetupEventIds`, meetupEventIds);
        if (!meetupEventIds) return
        let res = await C3.instance.client.fetcher.fetch(`/meetup/copy-event`, {
            method: 'POST',
            body: {meetupEventIds}
        })
    }

    async fetchMeetupRSVPsForSelectedEvents() {
        let res = await C3.instance.client.fetcher.fetch(`/event/sync-meetup-attendees`, {
            query: [`meetupEventIds=${this.meetupEventsSel.selectedIds}`].join('&'),
        });
        await this.fetchMeetupEvents()
        return res
    }
}
