import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import {MPEStore} from "../../components/MeetupPushEventsView/MPEStore";

const Container = styled.div`
    display: grid;
    
`

export interface MeetupPushEventsPageHeaderProps {
    store: MPEStore
}

export const MeetupPushEventsPageHeader = observer((props: MeetupPushEventsPageHeaderProps) => {
    let store = props.store
    return <Container>

    </Container>
})