import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {observer} from 'mobx-react'
import * as _ from "lodash";
import {AutomaticInvoicesPageStore} from "../AutomaticInvoicesPageStore";
import { C3AgGrid } from "react-c4";

const Container = styled.div`
    display: grid;
    
`

export interface AIPGridProps {
    store: AutomaticInvoicesPageStore
}

export const AIPGrid = observer((props: AIPGridProps) => {
    let store = props.store
    return <Container>
        <C3AgGrid
            onGridReady={({api}) => store.aipGridApi = api}
            columnDefs={[
                {
                    field: '',
                    headerName: 'Pacient',

                },
                {
                    field: '',
                    headerName: 'Proves',

                },
                {
                    field: '',
                    headerName: 'Data',
                },
                {
                    field: '',
                    headerName: 'Chipcard',
                },
                {
                    field: '',
                    headerName: 'Import',
                },
            ]}
        />
    </Container>
})
