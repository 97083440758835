import React, {useState, useEffect, useContext} from "react";
import styled from 'styled-components'
import {PREHeader} from "./components/PREHeader";
import {PREAllRatesGrid} from "./components/PREAllRatesGrid";
import {PREActiveRatesGrid} from "./components/PREActiveRatesGrid";
import {Button} from "@blueprintjs/core";
import {useProdFVStore} from "../../ProdFV";
import {observable} from "mobx";
import {observer} from "mobx-react";

const Container = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
    grid-gap: 8px;
`
const ButtonsContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 8px;
    place-content: center;
`


const Body = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 4px;
`

export interface ProductRatesEditorProps {

}

export const ProductRatesEditor = observer((props: ProductRatesEditorProps) => {
        let store = useProdFVStore()
        return <Container style={store.isMatrixFullScreen ? {display: 'none'}: {}}>
            <PREHeader/>
            <Body>
                <PREActiveRatesGrid/>
                <ButtonsContainer>
                    <Button
                        disabled={!store.canAddRate}
                        icon={'double-chevron-left'}
                        onClick={() => store.addRate()}/>
                    <Button
                        disabled={!store.canRemoveRate}
                        icon={'double-chevron-right'} onClick={() => store.removeRate()}/>
                </ButtonsContainer>
                <PREAllRatesGrid/>
            </Body>
        </Container>
    }
)
